import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  commanLabel,
  getCreditType,
  imagePath,
  PCRequestType,
  routesConstant,
  statusType,
} from '../../../constants/comman.constant';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import {
  certificateType,
  plasticCreditRequestStatus,
} from '../../../shared/remake-green-utils';
import { useTranslation } from 'react-i18next';
import { store } from '../../../app/store';
import {
  PCRequestStatus,
  PCRequestStatusRemake,
} from '../../../shared/remake-green-utils/common/constants/enum';
import {
  dateFormatWithTime,
  datePickerDateFormat,
} from '../../../constants/date';
import { EnumHelper } from '../../../shared/remake-green-utils/common/utils/enum.helper';
import CertificatePopup from '../../../components/certificatePopup';
import { useReCreatePdfMutation } from '../../../app/api/services/PCRequestManagement.service';

const RemakePlasticCreditRequestManagement = () => {
  const { t: translate } = useTranslation();
  const { requestType } = useParams();
  const state = store.getState();
  const [openCertificate, setOpenCertificate] = useState(false);
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [certificateList, setCertificateList] = useState<any>([]);
  const [reCreatePdf] = useReCreatePdfMutation();
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });
  const [value, setValue] = useState({
    page: 1,
    limit: 10,
    start_date: '',
    end_date: '',
    status:
      requestType === PCRequestType.PENDING
        ? [PCRequestStatus.Awating]
        : requestType === PCRequestType.COMPLETED
        ? [
            PCRequestStatus.Completed,
            PCRequestStatus.Closed,
            PCRequestStatus.Partial,
          ]
        : requestType == PCRequestType.REQUESTED
        ? [
            PCRequestStatus.Awating,
            PCRequestStatus.Approved,
            PCRequestStatus.Closed,
            PCRequestStatus.Partial,
          ]
        : [],
  });
  const columnHelper = createColumnHelper<any>();
  const statusContainer = (status: PCRequestStatus) => {
    switch (status) {
      case PCRequestStatus.Awating:
        return (
          <div className='status-data status-awaiting'>
            <div className='tag-icon round-fa-icon'>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Awaiting.svg`}
                alt='cc'
              />
              &nbsp;&nbsp;&nbsp;
              <span>
                {EnumHelper.GetEnumDescription(
                  PCRequestStatusRemake,
                  PCRequestStatusRemake.Awating
                )}
              </span>
            </div>
          </div>
        );

      case PCRequestStatus.Approved:
        return (
          <div className='status-data status-approved'>
            <div className='tag-icon round-fa-icon'>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Approved.svg`}
                alt='cc'
              />
              &nbsp;&nbsp;&nbsp;
              <span>
                {EnumHelper.GetEnumDescription(
                  PCRequestStatusRemake,
                  PCRequestStatusRemake.Approved
                )}
              </span>
            </div>
          </div>
        );

      case PCRequestStatus.Rejected:
        return (
          <div className='status-data reject'>
            <div className='tag-icon round-fa-icon'>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Reject.svg`}
                alt='cc'
              />
              &nbsp;&nbsp;&nbsp;
              <span>
                {EnumHelper.GetEnumDescription(
                  PCRequestStatusRemake,
                  PCRequestStatusRemake.Rejected
                )}
              </span>
            </div>
          </div>
        );

      case PCRequestStatus.Completed:
        return (
          <div className='status-data'>
            <div className='tag-icon'>
              <i className='fa-solid fa-circle-check' aria-hidden='true'></i>
              &nbsp;&nbsp;&nbsp;
              <span>
                {EnumHelper.GetEnumDescription(
                  PCRequestStatusRemake,
                  PCRequestStatusRemake.Completed
                )}
              </span>
            </div>
          </div>
        );

      case PCRequestStatus.Partial:
        return (
          <div className='status-data'>
            <div className='tag-icon'>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Partial.svg`}
                alt='cc'
              />
              &nbsp;&nbsp;&nbsp;
              <span>
                {EnumHelper.GetEnumDescription(
                  PCRequestStatusRemake,
                  PCRequestStatusRemake.Partial
                )}
              </span>
            </div>
          </div>
        );

      case PCRequestStatus.Closed:
        return (
          <div className='status-data closed'>
            <div className='tag-icon'>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Closed.svg`}
                alt='cc'
                height={20}
                width={20}
              />
              &nbsp;&nbsp;&nbsp;
              <span>
                {EnumHelper.GetEnumDescription(
                  PCRequestStatusRemake,
                  PCRequestStatusRemake.Closed
                )}
              </span>
            </div>
          </div>
        );

      default:
        return (
          <div className='status-data status-purple'>
            <div className='tag-icon round-fa-icon'>
              <i className='fa fa-hourglass-half ' aria-hidden='true'></i>
              <span>-</span>
            </div>
          </div>
        );
    }
  };

  const getFilteredHistory = (info: any) => {
    return info.plastic_credit_request_details_history.filter((inf: any) => {
        return inf?.link_transaction_certificate_detail?.length;
    })
  };

  const columns = [
    columnHelper.accessor('-', {
      header: () => translate('') + '',
      enableSorting: false,
      cell: (props: any) => {
        const id = props.row.original.id;
        const status = props.row.original.plastic_credit_request_status.id;
        const requestDetailsBatch =
          props.row.original.plastic_credit_request_details_history?.filter(
            (data: any) => {
              return data.link_transaction_certificate_detail.length > 0;
            }
          );
        return (
          <React.Fragment>
            {(status == PCRequestStatus.Completed ||
              status == PCRequestStatus.Closed ||
              status == PCRequestStatus.Partial) &&
              requestDetailsBatch.length > 0 && (
                <a
                  href='javascript:void(0);'
                  className='description-btn'
                  id={`desc-btn-${id}`}
                  onClick={() => {
                    let data = document.getElementById(`desc-content-${id}`);
                    if (data?.classList.contains('active')) {
                      data.classList.remove('active');
                    } else {
                      data && data?.classList?.add('active');
                    }
                    let btnData = document.getElementById(`desc-btn-${id}`);
                    if (btnData?.classList.contains('btn-active')) {
                      btnData.classList.remove('btn-active');
                    } else {
                      btnData && btnData?.classList?.add('btn-active');
                    }
                    let rowActiveData = document.getElementById(
                      `transaction-row-${id}`
                    );
                    if (rowActiveData?.classList.contains('active-row')) {
                      rowActiveData.classList.remove('active-row');
                    } else {
                      rowActiveData &&
                        rowActiveData?.classList?.add('active-row');
                    }
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/down-arrow-svg.svg`}
                  />
                </a>
              )}
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor((u) => u.plastic_credit_type.name, {
      id: translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.CREDIT_REQUEST') + '',
    }),
    columnHelper.accessor(
      (u) =>
        `${JSON.parse(u.pc_request_details).quantity} ${
          JSON.parse(u.pc_request_details).quantityUnit
        }`,
      {
        id: translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.QUANTITY_UNIT') + '',
      }
    ),
    columnHelper.accessor(
      (u) => `${moment(u.created_on).format(dateFormatWithTime)}`,
      {
        id: translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.TIMESTAMP') + '',
      }
    ),
    columnHelper.accessor('-', {
      header: () =>
        translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.APPROVAL_STATUS') + '',
      enableSorting: false,
      cell: (props: any) => {
        return (
          <React.Fragment>
            {statusContainer(
              props?.cell?.row?.original?.plastic_credit_request_status?.id
            )}
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor('-', {
      header: () => translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.ACTION') + '',
      enableSorting: false,
      cell: (props: any) => {
        let id = props?.row?.original?.id;
        let filterTransHistory = getFilteredHistory(props?.row?.original);
        let isCompleted =
          props?.row?.original?.plastic_credit_request_status?.id ===
            plasticCreditRequestStatus.COMPLETED ||
          props?.row?.original?.plastic_credit_request_status?.id ===
            plasticCreditRequestStatus.PARTIAL ||
          props?.row?.original?.plastic_credit_request_status?.id ===
            plasticCreditRequestStatus.CLOSED
            ? true
            : false;
        return (
          <React.Fragment>
            <div className='icon-action-main'>
              <div
                className={`icon-view icon ${
                  !isCompleted ? 'disable-icon-data' : ''
                }`}
              >
                <div
                  onClick={async () => {
                    if (isCompleted) {
                      if (filterTransHistory?.length > 1 ||
                        (filterTransHistory.length === 1 && (filterTransHistory[0]?.link_transaction_certificate_detail.length > 1))
                        ) {
                          let ids = filterTransHistory[0]?.link_transaction_certificate_detail.map( (item:any) => item.id);

                          const response = await reCreatePdf({
                            linkTransactionCertificateDetailIds: ids,
                            pcId: id,
                          }).unwrap();
                      
                          if (response.success) {

                        if (filterTransHistory[0]?.link_transaction_certificate_detail[0]?.certificate_url) {
                          window.open(
                            process.env.REACT_APP_BASE_URL_API +
                              routesConstant.plasticCreditDownload +
                              id
                          );
                          toast.success(
                            translate(
                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CERTIFICATE_DOWNLOADED'
                            )
                          );
                        } else {
                          toast.warning(
                            translate(
                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CERTIFICTE_DOWLOAD_WAITING'
                            )
                          );
                          setTimeout(() => {
                            getData(false);
                          }, 1000);
                        }
                      } else {
                        toast.error(response.data.message);
                      }

                      } else if (filterTransHistory.length === 1) {
                        const response = await reCreatePdf({
                          linkTransactionCertificateDetailIds: [Number(filterTransHistory[0]?.link_transaction_certificate_detail[0]?.id)],
                          pcId: id,
                        }).unwrap();
                    
                        if (response.success) {
                       // const tempUrl = filterTransHistory[0]?.link_transaction_certificate_detail[0]?.certificate_url;
                        if (response?.data?.url) {
                          let path =
                            apiUrl.prepareFile + response?.data?.url;
                          const fr = await helper.getData(path);
                          const res = await fr.response;
                          if (res.data) {
                            window.open(
                              process.env.REACT_APP_BASE_URL_API +
                                apiUrl.downloadFile +
                                response.data.url
                            );
                            toast.success(
                              translate(
                                'PLASTIC_CREDIT_MANAGEMENT.TITLES.CERTIFICATE_DOWNLOADED'
                              )
                            );
                          } else {
                            toast.error(res.message);
                          }
                        } else {
                          toast.warning(
                            translate(
                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CERTIFICTE_DOWLOAD_WAITING'
                            )
                          );
                          setTimeout(() => {
                            getData(false);
                          }, 1000);
                        }
                      } else {
                        toast.error(response.data.message);
                      }
                      }
                    }
                  }}
                >
                  <Link to={`#`} title='Download'>
                    <img
                      src={`${process.env.PUBLIC_URL + imagePath.downloadPath}`}
                      alt='#'
                    />
                  </Link>
                </div>
              </div>
              <div
                className={`icon-view icon ${
                  !isCompleted ? 'disable-icon-data' : ''
                }`}
                data-toggle='modal'
                onClick={() => {
                  if (isCompleted) {
                    const filteredHistory = (getFilteredHistory(props?.row?.original) || []);
                    if (
                      (filteredHistory.length > 1) || (filteredHistory.length === 1 && (filteredHistory[0]?.link_transaction_certificate_detail?.length > 1))
                    ) {
                      setSelectedTransaction(props?.row?.original);
                      setOpenCertificate(true);
                    } else {
                      let data =
                      filteredHistory.length ? filteredHistory[0].link_transaction_certificate_detail[0]?.linked_transaction_detail: {};
                      if (data?.transaction_id) {
                        window.open(
                          `${
                            routesConstant.plasticUserCreditCertification +
                            window.btoa(data?.transaction_id)
                          }`
                        );
                      }
                    }
                  }
                }}
              >
                <Link to='#' title='Open'>
                  <img
                    src={process.env.PUBLIC_URL + imagePath.sharePath}
                    alt=''
                  />
                </Link>
              </div>
            </div>
          </React.Fragment>
        );
      },
    }),
  ];

  const getPagination = () => {
    let items = [];
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item
          activeLabel=''
          key={number}
          active={number === page}
          onClick={(e) => {
            e.preventDefault();
            setPage(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: certificateList,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const getData = async (reset: boolean) => {
    setIsLoading(true);
    const parms = {
      page: reset ? 1 : page,
      limit: commanLabel.pageLimit,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),
      status: reset ? JSON.stringify([]) : JSON.stringify(value.status),
      user_id: state?.auth?.user?.id,
      credit_type: 0,
    };
    let path = apiUrl.getCertificateList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setCertificateList(res.data.allTransaction);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalRecords(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  const onChange = (e: any) => {
    setValue({
      ...value,
      [e.target.name]: [Number(e.target.value)],
    });
  };

  useEffect(() => {
    getData(false);
  }, [page, pageLimit]);

  const handeleReset = () => {
    setValue({
      page: 1,
      limit: 10,
      start_date: '',
      end_date: '',
      status: [],
    });
    setPage(1);
    setDateRange([null, null]);
    getData(true);
  };

  return (
    <div className='body-color'>
      {openCertificate && selectedTransaction !== null && (
        <CertificatePopup
          selectedTransaction={selectedTransaction}
          handleClose={setOpenCertificate}
        />
      )}
      <div className='body-content-main pc-request'>
        <div className='container'>
          <nav aria-label='breadcrumb' className='breadcrumb-main'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='/'>
                  {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.HOME')}
                </Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                <Link
                  to={routesConstant.plasticCreditManagementList + value.status}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  {translate(
                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_CREDIT_MANAGEMENT'
                  )}
                </Link>
              </li>
            </ol>
          </nav>
          <div className='section-title-main'>
            <div className='title'>
              {translate(
                'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_CREDIT_MANAGEMENT'
              )}
            </div>

            <div className='form-o-submit'>
              <a
                className='btn btn-primary'
                href={
                  routesConstant.remakeCreditRequest +
                  window.btoa(certificateType.COLLECTION.toString())
                }
              >
                {translate(
                  'PLASTIC_CREDIT_MANAGEMENT.TITLES.GENERATE_CREDIT_REQUEST'
                )}
              </a>
            </div>
          </div>
          <div className='transaction-table listuser-table management-screens'>
            <div className='tab-content' id=''>
              <div
                className='tab-pane fade show active'
                id='pc-request1'
                role='tabpanel'
                aria-labelledby='pc-request1-tab'
              >
                <div className='listing-transaction'>
                  <Form>
                    <div className='top-filters three-type'>
                      <div className='date-item-picker item-in'>
                        <div className='date-picker-main'>
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            value={startDate ? startDate : ''}
                            className='form-control'
                            placeholderText={
                              translate(
                                'PLASTIC_CREDIT_MANAGEMENT.TITLES.SELECT_DATE_RANGE'
                              ) + ''
                            }
                            dateFormat={datePickerDateFormat}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            onChange={(update: any) => {
                              setDateRange(update);
                            }}
                          />
                        </div>
                      </div>

                      <div className='type-select item-in'>
                        <select
                          id='status'
                          name='status'
                          className='select-layout form-select'
                          value={
                            value && value.status.length > 0
                              ? value.status[0]
                              : ''
                          }
                          onChange={(e) => {
                            setValue({
                              ...value,
                              status: e.target.value
                                ? [Number(e.target.value)]
                                : [],
                            });
                          }}
                        >
                          <option value={''}>
                            {translate(
                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.STATUS'
                            )}
                          </option>
                          {statusType?.length > 0 &&
                            statusType?.map((item: any, index: number) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className='search-button item-in'>
                        <Button
                          href='#'
                          title='Search'
                          className='btn btn-primary btn-outline-primary btn-sm btn-icon'
                          onClick={(e) => {
                            e.preventDefault();
                            setPage(1);
                            getData(false);
                          }}
                        >
                          <i className='fa fa-search' aria-hidden='true'></i>
                        </Button>

                        <Button
                          href='#'
                          title='Reset'
                          type='reset'
                          className='btn btn-primary btn-outline-primary btn-sm btn-icon'
                          onClick={(e) => {
                            e.preventDefault();
                            handeleReset();
                          }}
                        >
                          <i className='fa fa-refresh' aria-hidden='true'></i>
                        </Button>
                      </div>
                    </div>
                  </Form>

                  <div className='listing-content'>
                    <div className='table-list'>
                      <table>
                        <thead>
                          {table
                            .getHeaderGroups()
                            .map((headerGroup: any, index: any) => (
                              <tr key={index}>
                                {headerGroup.headers.map(
                                  (header: any, headerIndex: any) => {
                                    return (
                                      <th
                                        key={headerIndex}
                                        colSpan={header.colSpan}
                                      >
                                        {header.isPlaceholder ? null : (
                                          <div
                                            {...{
                                              className:
                                                header.column.getCanSort()
                                                  ? 'cursor-pointer select-none'
                                                  : '',
                                              onClick:
                                                header.column.getToggleSortingHandler(),
                                            }}
                                          >
                                            {flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                            )}
                                            {{
                                              asc: ' 🔼',
                                              desc: ' 🔽',
                                            }[
                                              header.column.getIsSorted() as string
                                            ] ?? null}
                                          </div>
                                        )}
                                      </th>
                                    );
                                  }
                                )}
                              </tr>
                            ))}
                        </thead>
                        {certificateList.length > 0 ? (
                          <tbody>
                            {table
                              .getRowModel()
                              .rows.map((row: any, index: any) => {
                                return (
                                  <>
                                    <tr
                                      key={index}
                                      style={{ cursor: 'pointer' }}
                                      id={`transaction-row-${row.original.id}`}
                                    >
                                      {row
                                        .getVisibleCells()
                                        .map((cell: any, cellIndex: any) => {
                                          return (
                                            <td key={cellIndex}>
                                              {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                              )}
                                            </td>
                                          );
                                        })}
                                    </tr>
                                    <tr
                                      id={`desc-content-${row.original.id}`}
                                      className='description-row'
                                    >
                                      <td
                                        colSpan={9}
                                        className='description-td'
                                      >
                                        <div className='description-content'>
                                          <div className='description-content-inner'>
                                            {row?.original?.plastic_credit_request_details_history
                                              ?.filter((data: any) => {
                                                return (
                                                  data.link_transaction_certificate_detail
                                                    .length > 0
                                                );
                                              })
                                              .map(
                                                (data: any, index: number) => {
                                                  return (
                                                    <div
                                                      className='main-desc-area'
                                                      style={{
                                                        marginBottom: '20px',
                                                      }}
                                                    >
                                                      <div className='title-area'>
                                                        <div className='row'>
                                                          <div className='col-lg-8'>
                                                            <h5>
                                                              {translate(
                                                                'PLASTIC_CREDIT_MANAGEMENT.TITLES.ALLOCATED_TRANSACTIONS'
                                                              )}{' '}
                                                              -{' '}
                                                              {translate(
                                                                'PLASTIC_CREDIT_MANAGEMENT.TITLES.BATCH'
                                                              )}{' '}
                                                              {index + 1}
                                                            </h5>
                                                          </div>
                                                          <div className='col-lg-4 d-flex justify-content-end'>
                                                            <p>
                                                              {translate(
                                                                'PLASTIC_CREDIT_MANAGEMENT.TITLES.DATE'
                                                              )}
                                                              :{' '}
                                                              {moment(
                                                                data?.created_on
                                                              ).format(
                                                                'DD/MM/YYYY'
                                                              )}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='main-table-section'>
                                                        <div className='table-certificate'>
                                                          <div className='listing-content'>
                                                            <div className='table-list'>
                                                              <table>
                                                                <thead>
                                                                  <tr>
                                                                    <td></td>
                                                                    <td>
                                                                      <div className='title'>
                                                                        {translate(
                                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.TRANSACTION_ID'
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                    <td>
                                                                      <div className='title'>
                                                                        {translate(
                                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_WASTE_TYPE'
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                    <td>
                                                                      <div className='title'>
                                                                        {translate(
                                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.QTY'
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                    <td>
                                                                      <div className='title'>
                                                                        {translate(
                                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.TYPE_OF_CREDITS'
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                    <td>
                                                                      <div className='title'>
                                                                        {translate(
                                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.TOTAL_CREDIT'
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                    <td>
                                                                      <div className='title'>
                                                                        {translate(
                                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.SELLER'
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                    <td>
                                                                      <div className='title'>
                                                                        {translate(
                                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.BUYER'
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {(data?.link_transaction_certificate_detail || []).map(
                                                                    (
                                                                      u: any,
                                                                      i: number
                                                                    ) => {
                                                                      return (
                                                                        <tr>
                                                                          <td>
                                                                            <Link
                                                                              target='_blank'
                                                                              to={`/admin/transaction/plasticCreditCertification/${window.btoa(
                                                                                u?.linked_transaction_detail?.transaction_id
                                                                              )}&${window.btoa(
                                                                                u?.pc_id
                                                                              )}`}
                                                                              title={
                                                                                translate(
                                                                                  'PLASTIC_CREDIT_MANAGEMENT.TITLES.VIEW_CERTIFICATE'
                                                                                ) ||
                                                                                ''
                                                                              }
                                                                            >
                                                                              <img
                                                                                src='/assets/images/certificate-icon.svg'
                                                                                alt='invite user'
                                                                              />
                                                                            </Link>
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              u?.linked_transaction_detail?.transaction_id
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              u
                                                                              ?.linked_transaction_detail?.material_type
                                                                                ?.name
                                                                            }
                                                                            &nbsp;
                                                                            -
                                                                            &nbsp;
                                                                            {
                                                                              u
                                                                              ?.linked_transaction_detail?.material_name_type
                                                                                ?.name
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                               u?.assigned_pc
                                                                            }
                                                                            &nbsp;
                                                                            {
                                                                              "Kg"
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {getCreditType(
                                                                              u?.linked_transaction_detail?.credit_type
                                                                            )}
                                                                            &nbsp;
                                                                            Credit
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              (u?.assigned_pc || 0)/1000
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              u?.linked_transaction_detail?.seller
                                                                                ?.name
                                                                            }
                                                                          </td>
                                                                          <td>
                                                                            {
                                                                              u?.linked_transaction_detail?.buyer
                                                                                ?.name
                                                                            }
                                                                          </td>
                                                                        </tr>
                                                                      );
                                                                    }
                                                                  )}
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {data?.status_remark && (
                                                        <div className='remake-textarea'>
                                                          <div className='row mt-2'>
                                                            <div className='col-lg-12'>
                                                              <h5>Remarks</h5>
                                                              <textarea
                                                                className='form-control'
                                                                value={
                                                                  data?.status_remark
                                                                }
                                                                disabled
                                                              ></textarea>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        ) : (
                          <>
                            <tbody>
                              <tr>
                                <td colSpan={7}>
                                  {isLoading && (
                                    <h3 className='center-message-item'>
                                      {translate('COMMONFIELDS.LOADING')}
                                    </h3>
                                  )}
                                  {certificateList.length === 0 &&
                                    !isLoading && (
                                      <h3 className='center-message-item'>
                                        {translate(
                                          'COMMONFIELDS.NO_RECORD_FOUND'
                                        )}
                                      </h3>
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </>
                        )}
                        {certificateList.length > 0 && (
                          <tfoot className='footer-table'>
                            <tr>
                              <td colSpan={3}>
                                <div className='left-item text-left'>
                                  {translate('COMMONFIELDS.TOTAL_RECORDS')}:{' '}
                                  {certificateList.length +
                                    commanLabel.pageLimit * (page - 1)}{' '}
                                  / {totalRecords}
                                </div>
                              </td>
                              <td colSpan={3}>
                                <div className='right-item'>
                                  {certificateList.length > 0 &&
                                    pageCount > 1 && (
                                      <div className='items-center pagination-main text-center'>
                                        <button
                                          className='border rounded p-1'
                                          onClick={() => {
                                            table.previousPage();
                                            setPage(page - 1);
                                          }}
                                          disabled={!table.getCanPreviousPage()}
                                        >
                                          <i
                                            className='fa fa-angle-left'
                                            aria-hidden='true'
                                          ></i>
                                        </button>
                                        <Pagination size='sm'>
                                          {getPagination()}
                                        </Pagination>
                                        <button
                                          className='border rounded p-1'
                                          onClick={() => {
                                            table.nextPage();
                                            setPage(page + 1);
                                          }}
                                          disabled={!table.getCanNextPage()}
                                        >
                                          <i
                                            className='fa fa-angle-right'
                                            aria-hidden='true'
                                          ></i>
                                        </button>
                                      </div>
                                    )}
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemakePlasticCreditRequestManagement;
