import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { apiUrl } from '../../app/api/services/apiUrl';
import { helper } from '../../app/api/api.helper';
import DatePicker from 'react-datepicker';
import { isValidFile, socialWelfareTab } from '../../shared/remake-green-utils';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { commanValidation } from '../../constants/comman.validation.constant';
import { useCreateActivityMutation } from '../../app/api/services/socialWelfare';
import { createActivityFromEventValidator } from '../../pages/admin/socialWelfare/activityFromEventValidation';
import moment from 'moment';

export interface ConfirmationData {
  handleCancel: any;
  handleSubmit: any;
  message: string;
  title: string;
  cancelText?: string;
  submitText?: string;
  hideCancelButton?: boolean;
}

const AddActivity = (props: ConfirmationData) => {
  const { id } = useParams();

  const [show, setShow] = useState(true);
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isSubmitted, setIsSubmited] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [reclaimerName, setReclaimerName] = useState(null);
  const [documentFiles, setDocumentFiles] = useState<any>([]);
  const [addActivity] = useCreateActivityMutation();
  const [reclaimerId, setReclaimerId] = useState(0);

  const [collectionCenterName, setCollectionCneterName] = useState<any>(null);
  const [collectionCenter, setCollectionCenter] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState<any>([]);
  const [reclaimerList, setReclaimerList] = useState([]);
  const [reclaimerDetails, setReclaimerDetails] = useState({
    csr_collection_center_id: null,
    csr_collection_center_name: '',
    reclaimerName: '',
    gender: '',
    contactNumber: '',
    girls: 0,
    boys: 0,
    capacityPerDay: '',
    smallScrapDealer: '',
    address: '',
    aadhaarNo: '',
    aadhaarPhoto: '',
    birthDate: new Date(),
    childrens: '',
  });

  const [activityDetails, setActivityDetails] = useState({
    activity_photo: '',
    activity_date: new Date(),
    materialIds: null,
    otherMaterial: '',
    isOtherMaterial: false,
  });

  const [errors, setErrors] = useState<any>({
    reclaimerName: '',
    gender: '',
    childrens: '',
    aadhaarNo: '',
    aadhaarPhoto: '',
    //   eventName: '',
    //   eventDate: '',
    //   poc: '',
    //   dataProvider: '',
    //   organiser: '',
    //   location: '',
    activityPhoto: '',
    otherMaterial: '',
  });

  const handleBirthDate = (date: any) => {
    setReclaimerDetails({ ...reclaimerDetails, birthDate: date });
  };

  const handleActivityDate = (date: any) => {
    setActivityDetails({ ...activityDetails, activity_date: date });
  };

  const handleReclaimerDetails = (e: any) => {
    setReclaimerDetails({
      ...reclaimerDetails,
      [e.target.name]: e.target.value,
    });
  };

  const getReclaimerByAadhaar = async (aadhaarNo: string) => {
    let path = apiUrl.getReclaimerByAadhaar + aadhaarNo;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      const newArray =
        temArray &&
        temArray.map((item: any) => {
          return {
            value: item.id,
            label: item.aadhaar_no + ' - ' + item.name,
          };
        });

      newArray && setReclaimerList(newArray);
    } else {
      toast.error(res.message);
    }
  };

  const handleCollectionCenter = (option: any) => {
    if (option !== null) {
      setReclaimerDetails({
        ...reclaimerDetails,
        csr_collection_center_name: option.label,
        csr_collection_center_id:
          option.value === option.label ? 0 : option.value,
      });
      setCollectionCneterName(option);
    } else {
      setReclaimerDetails({
        ...reclaimerDetails,
        csr_collection_center_name: '',
        csr_collection_center_id: null,
      });
    }
  };

  const getCollectionCenterDetails = async () => {
    let path = apiUrl.getCollectionCenter;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      const newArray =
        temArray &&
        temArray.map((item: any) => {
          return { value: item.id, label: item.name };
        });
      newArray && setCollectionCenter(newArray);
    } else {
      toast.error(res.message);
    }
  };

  const handleFileUploder = async (file: any, name: string) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', '');

    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res: any = await fr.response;
    if (res.success) {
      toast.success(res.message);
      await getFileUploaderData(res?.data?.data, name);
    } else {
      toast.error(res.message);
    }
  };

  const getFileUploaderData = async (dat: any, name: string) => {
    const tDoc: any = documentFiles || [];
    tDoc.push(dat);

    setDocumentFiles([...tDoc]);
    name == 'aadhaar_image'
      ? setReclaimerDetails({
          ...reclaimerDetails,
          aadhaarPhoto: dat.document_path,
        })
      : setActivityDetails({
          ...activityDetails,
          activity_photo: dat.document_path,
        });
  };

  const uploadDocument = async (e: any) => {
    let files = e?.target?.files || [];
    if (files[0] > commanValidation.maxFileLength) {
      toast.error(translate('ERRORS.INVALID.MAXIMUM_DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidFile(files[0].type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    await handleFileUploder(files[0], e.target.name);
  };

  const createActivity = async () => {
    let data = {
      reclaimer_details: {
        ...reclaimerDetails,
        date_of_birth: moment(reclaimerDetails.birthDate).format('YYYY-MM-DD'),
        girls: +reclaimerDetails.girls,
        boys: +reclaimerDetails.boys,
      },
      activity_date: moment(activityDetails.activity_date).format('YYYY-MM-DD'),
      activity_photo: activityDetails.activity_photo,
      materialIds: selectedMaterial,
      csr_event_id: id,
      csr_reclaimer_id: reclaimerId,
      otherMaterial: activityDetails.otherMaterial,
    };

    // const fr: any = await helper.formPost(data, path);
    const res = await addActivity(data).unwrap();
    if (res.success) {
      // navigate(routesConstant.socialWelfare, {
      //   state: socialWelfareTab.ACTIVITIES,
      // });
      //   setShow(false);
      handleOk();
      toast.success('Activity Created Successfully !');
    } else {
      toast.error(res.message);
    }
  };

  const getCSRMaterials = async () => {
    let path = apiUrl.getCSRMaterials;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      setMaterialList(temArray);
    } else {
      toast.error(res.message);
    }
  };

  const handleReclaimerName = (option: any) => {
    if (option !== null) {
      setReclaimerDetails({
        ...reclaimerDetails,
        aadhaarNo: option.label.split('-')[0].trim(),
      });
      if (option.value === option.label) {
        // navigate(routesConstant.addActivity, {
        //   state: option.value,
        // });
        setReclaimerId(0);
      } else {
        getReclaimerDetails(option.value);
        setReclaimerId(option.value);

        // navigate(routesConstant.addActivity + `/${option.value}`);
      }

      //    setReclaimerList(collectionCenterData);
      setReclaimerName(option);
    }
  };

  const getReclaimerDetails = async (id: string) => {
    let path = apiUrl.getReclaimerForActivity + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setReclaimerDetails({
        reclaimerName: res?.data?.name,
        gender: res?.data?.gender,
        address: res?.data?.address,
        aadhaarNo: res?.data?.aadhaar_no,
        csr_collection_center_id: res?.data?.csr_collection_center_id,
        csr_collection_center_name: res?.data?.csr_collection_center?.name,
        contactNumber: res?.data?.contact_number,
        aadhaarPhoto: res?.data?.aadhaar_photo,
        childrens: res?.data?.childrens,
        girls: res?.data?.girls,
        boys: res?.data?.boys,
        capacityPerDay: res?.data?.capacity_per_day,
        smallScrapDealer: res?.data?.small_scrap_dealer,
        birthDate: new Date(res?.data?.birth_date),
      });
      setCollectionCneterName({
        label: res?.data?.csr_collection_center?.name,
        value: res?.data?.csr_collection_center_id,
      });
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    getCollectionCenterDetails();
    //  getEventList();
    getCSRMaterials();
  }, []);

  useEffect(() => {
    if (reclaimerDetails.aadhaarNo) {
      getReclaimerByAadhaar(reclaimerDetails.aadhaarNo);
    }
  }, [reclaimerDetails.aadhaarNo]);

  const handleClose = () => {
    setShow(false);
    props.handleCancel();
  };
  const handleOk = () => {
    setShow(false);
    props.handleSubmit();
  };

  const validateForm = (
    reclaimerValue = reclaimerDetails,
    //  eventValue = eventDetails,
    activityValue = activityDetails,
    formSubmit = false
  ) => {
    let errs: any = {
      ...createActivityFromEventValidator(
        errors,
        reclaimerValue,
        activityValue
      ),
    };
    setErrors(errs);
    // errs = { ...createEventValidator(errors, eventValue) };
    // setErrors(errs);
    const isFormValid = Object.keys(errs).every(
      (err) => errs[err].length === 0
    );
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      createActivity();
      //   setSubmitPopup(false);
      //   setSubmitPopup(true);
    }
  };

  return (
    <>
      <Modal
        className='modal small-popup big-popup'
        show={show}
        onHide={handleClose}
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header> */}
        {/* <Modal.Body> */}
        <div className='body-content-main pc-request'>
          <div className='container' style={{ padding: 0 }}>
            <div className='transaction-table listuser-table csr-addreclaimer add-acitivty'>
              <div className='panel-head-title'>
                <div className='left-content'>
                  <div className='title-normal'>New Activity Details</div>
                </div>
                <div className='right-content'>
                  <div className='form-submit-buttons auto-width-button'>
                    <button
                      type='button'
                      className='btn btn-sm discard'
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Discard
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm save-entry ms-1 ms-sm-3 ms-md-3 ms-lg-3 ms-xl-3'
                      onClick={() => {
                        setIsSubmited(true);
                        validateForm(reclaimerDetails, activityDetails, true);
                      }}
                    >
                      Save Entry
                    </button>
                  </div>
                </div>
              </div>
              <div className='display-component main-profile-head'>
                <div className='activity-main'>
                  <div className='activity-box'>
                    <div className='row d-flex align-items-center'>
                      <div className='col-md-6'>
                        <div className='left-area'>
                          <div className='title'>Profile Details</div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='right-area text-end'>
                          <p>
                            {/* <span>Date of entry:</span> 30/1/2023 02:30 PM */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='panel-body'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <Form.Label>Aadhaar Card No.</Form.Label>
                          <CreatableSelect
                            isClearable
                            value={reclaimerName}
                            options={reclaimerList}
                            onChange={handleReclaimerName}
                            onInputChange={(e) => {
                              e.length >= 4 &&
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  aadhaarNo: e,
                                });
                            }}
                            placeholder={'Search By Addhaar Number'}
                          />

                          <Form.Control
                            tabIndex={-1}
                            type='text'
                            autoComplete='off'
                            value={[]}
                            required
                            style={{ opacity: 0, height: 0, display: 'none' }}
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group required required'>
                          <Form.Label className='form-label'>
                            Reclaimer Name
                          </Form.Label>
                          <input
                            type='text'
                            placeholder='Enter Reclaimer name'
                            className='form-control form-control'
                            value={reclaimerDetails.reclaimerName}
                            name='reclaimerName'
                            onChange={handleReclaimerDetails}
                            disabled={reclaimerId !== 0}
                          />
                          <span className='error'>{errors.reclaimerName}</span>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group required required'>
                          <Form.Label className='form-label'>Gender</Form.Label>
                          <select
                            name='gender'
                            className='form-control form-select'
                            onChange={handleReclaimerDetails}
                            disabled={reclaimerId !== 0}
                            value={reclaimerDetails.gender.toLocaleLowerCase()}
                          >
                            <option value=''>Select Gender</option>
                            <option value='male'>Male</option>
                            <option value='female'>Female</option>
                          </select>
                          <span className='error'>{errors.gender}</span>
                        </div>
                      </div>

                      <div className='col-md-4'>
                        <div className='form-group'>
                          <Form.Label className='form-label'>
                            Contact Number
                          </Form.Label>
                          <input
                            type='text'
                            name='contactNumber'
                            placeholder='Enter Contact Number'
                            className='form-control form-control'
                            value={reclaimerDetails.contactNumber}
                            disabled={reclaimerId !== 0}
                            onChange={handleReclaimerDetails}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group group-i-input required'>
                          <Form.Label className='form-label'>
                            Childrens
                          </Form.Label>
                          <div className='input-group'>
                            <div className='input-right input-left-right'>
                              <select
                                name='boys'
                                className='form-control form-select'
                                value={reclaimerDetails.boys}
                                disabled={reclaimerId !== 0}
                                // onChange={handleReclaimerDetails}
                                onChange={(e) => {
                                  setReclaimerDetails({
                                    ...reclaimerDetails,
                                    boys: +e.target.value,
                                    childrens:
                                      reclaimerDetails.childrens == 'No' ||
                                      reclaimerDetails.childrens == 'NA'
                                        ? e.target.value + ''
                                        : +reclaimerDetails.childrens +
                                          +e.target.value +
                                          '',
                                  });
                                }}
                              >
                                {[...Array(11)].map((x, i) => {
                                  return <option value={i}>{i}</option>;
                                })}
                              </select>
                            </div>
                            <div className='input-left input-left-right'>
                              <input
                                type='number'
                                className='form-control'
                                name='boy'
                                placeholder='Boy'
                              />
                              <span className='error'></span>
                            </div>
                            <div className='input-right input-left-right'>
                              <select
                                name='girls'
                                className='form-control form-select'
                                value={reclaimerDetails.girls}
                                disabled={reclaimerId !== 0}
                                // onChange={handleReclaimerDetails}
                                onChange={(e) => {
                                  setReclaimerDetails({
                                    ...reclaimerDetails,
                                    girls: +e.target.value,
                                    childrens:
                                      reclaimerDetails.childrens == 'No' ||
                                      reclaimerDetails.childrens == 'NA'
                                        ? e.target.value + ''
                                        : +reclaimerDetails.childrens +
                                          +e.target.value +
                                          '',
                                  });
                                }}
                              >
                                {[...Array(11)].map((x, i) => {
                                  return <option value={i}>{i}</option>;
                                })}
                              </select>
                            </div>
                            <div className='input-left input-left-right'>
                              <input
                                type='number'
                                className='form-control'
                                name='girl'
                                placeholder='Girl'
                              />
                              <span className='error'></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <Form.Label className='form-label'>
                            Capacity Per Day (Kg)
                          </Form.Label>
                          <input
                            type='text'
                            placeholder='Enter Capacity per day'
                            className='form-control form-control'
                            disabled={reclaimerId !== 0}
                            name='capacityPerDay'
                            value={reclaimerDetails.capacityPerDay}
                            onChange={handleReclaimerDetails}
                          />
                          <span className='error'></span>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group '>
                          <Form.Label className='form-label'>
                            Small Scrap Dealer
                          </Form.Label>
                          <input
                            type='text'
                            placeholder='Enter Small scrap dealer'
                            className='form-control form-control'
                            disabled={reclaimerId !== 0}
                            name='smallScrapDealer'
                            value={reclaimerDetails.smallScrapDealer}
                            onChange={handleReclaimerDetails}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='radio-buttons no-req'>
                          <div
                            className='form-check'
                            onClick={() => {
                              if (!reclaimerId) {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  childrens: 'No',
                                  boys: 0,
                                  girls: 0,
                                });
                              }
                            }}
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              name='any_quanty_return'
                              id='yes-icon'
                              value='true'
                              checked={reclaimerDetails.childrens === 'No'}
                              data-gtm-form-interact-field-id='0'
                            />
                            <Form.Label
                              className='form-check-label'
                              htmlFor='yes-icon'
                            >
                              No
                            </Form.Label>
                          </div>
                          <div
                            className='form-check'
                            onClick={() => {
                              if (!reclaimerId) {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  childrens: 'NA',
                                  boys: 0,
                                  girls: 0,
                                });
                              }
                            }}
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              name='any_quanty_return'
                              id='no-icon'
                              value='false'
                              data-gtm-form-interact-field-id='1'
                              checked={reclaimerDetails.childrens === 'NA'}
                            />
                            <Form.Label
                              className='form-check-label'
                              htmlFor='no-icon'
                            >
                              N/A
                            </Form.Label>
                          </div>
                        </div>
                        <span className='error'>{errors.childrens}</span>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <Form.Label className='form-label'>
                            Collection Centre
                          </Form.Label>

                          <CreatableSelect
                            isClearable
                            value={collectionCenterName}
                            onChange={handleCollectionCenter}
                            options={collectionCenter}
                            placeholder='Select Collection Center'
                            isDisabled={reclaimerId !== 0}
                          />
                        </div>
                      </div>
                      <div className='col-md-8'>
                        <div className='text-area-content'>
                          <div className='form-group group-i-input'>
                            <Form.Label className='form-label'>
                              Address
                            </Form.Label>
                            <textarea
                              placeholder='Address'
                              name='address'
                              className='form-control'
                              value={reclaimerDetails.address}
                              onChange={handleReclaimerDetails}
                              disabled={reclaimerId !== 0}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='row'> */}
                    {/* <div className='col-md-4'>
                    <div className='form-group required'>
                      <Form.Label className='form-label'>
                        Aadhaar Card Number
                      </Form.Label>
                      <input
                        type='text'
                        placeholder='Enter Aadhaar Number'
                        className='form-control form-control'
                        name='aadhaarNo'
                        value={reclaimerDetails.aadhaarNo}
                        disabled={reclaimerId !== 0}
                        onChange={handleReclaimerDetails}
                      />
                      <span className='error'>{errors.aadhaarNo}</span>
                    </div>
                  </div> */}
                    <div className='row mt-3'>
                      {/* </div> */}
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <div className='mb-3'>
                            <Form.Label
                              htmlFor='formFile'
                              className='form-label'
                            >
                              Aadhaar Card Photo
                            </Form.Label>
                            {reclaimerId ? (
                              <>
                                <div
                                  className='upload-icons'
                                  style={{
                                    top: '0px',
                                    justifyContent: 'flex-start',
                                    marginTop: '10px',
                                  }}
                                >
                                  <i
                                    className='fa fa-eye ms-2 me-2'
                                    aria-hidden='true'
                                    onClick={() =>
                                      window.open(reclaimerDetails.aadhaarPhoto)
                                    }
                                  ></i>
                                </div>
                              </>
                            ) : (
                              <>
                                <Form.Control
                                  className='file-input'
                                  type='file'
                                  onChange={uploadDocument}
                                  name='aadhaar_image'
                                  id='aadhaar_image'
                                  accept='.jpg, .jpeg, .png'
                                  disabled={reclaimerId !== 0}
                                />
                                {reclaimerDetails.aadhaarPhoto && (
                                  <div className='upload-icons'>
                                    <i
                                      className='fa-solid fa-trash-can'
                                      onClick={() => {
                                        setReclaimerDetails({
                                          ...reclaimerDetails,
                                          aadhaarPhoto: '',
                                        });
                                      }}
                                    ></i>
                                    <i
                                      className='fa fa-eye ms-2 me-2'
                                      aria-hidden='true'
                                      onClick={() => {
                                        window.open(
                                          reclaimerDetails.aadhaarPhoto
                                        );
                                      }}
                                    ></i>
                                  </div>
                                )}
                                {reclaimerDetails.aadhaarPhoto && (
                                  <p className='upload-note text-end'>
                                    File successfully uploaded
                                    <svg
                                      width='800px'
                                      height='800px'
                                      viewBox='0 0 1024 1024'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        fill='#18A675'
                                        d='M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z'
                                      />
                                    </svg>
                                  </p>
                                )}
                              </>
                            )}
                          </div>

                          <span className='error'>{errors.aadhaarPhoto}</span>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group group-i-input required'>
                          <Form.Label className='form-label'>
                            Birthdate
                          </Form.Label>
                          <div className='date-item-picker'>
                            <div className='date-picker-main'>
                              <DatePicker
                                selected={reclaimerDetails.birthDate}
                                className='form-control'
                                placeholderText={
                                  translate(
                                    'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                  ) + ''
                                }
                                dateFormat='dd/MM/yyyy'
                                onChange={handleBirthDate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                required
                                disabled={reclaimerId !== 0}
                              />
                              {/* {
                            <span className='error'>
                              {translate(errors.loadingSlipDetails.date)}
                            </span>
                          } */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='activity-box'>
                <div className='row d-flex align-items-center'>
                  <div className='col-md-12'>
                    <div className='left-area'>
                      <div className='title'>Event Details</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='form-group required'>
                      <Form.Label className='form-label'>Event Name</Form.Label>
                      <CreatableSelect
                        isClearable
                        value={eventName}
                        onChange={handleEvent}
                        options={eventList}
                        placeholder='Select Event'
                      />
                      <span className='error'>{errors.eventName}</span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group date-input'>
                      <Form.Label className='form-label'>
                        Date of Event
                      </Form.Label>
                      <div className='date-item-picker'>
                        <div className='date-picker-main'>
                          <DatePicker
                            selected={eventDetails.eventDate}
                            className='form-control'
                            placeholderText={
                              translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                              ) + ''
                            }
                            dateFormat='dd/MM/yyyy'
                            onChange={handleEventDate}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            required
                            disabled={eventId !== 0 ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group required required'>
                      <Form.Label className='form-label'>Organiser</Form.Label>
                      <input
                        type='text'
                        placeholder='Enter Organiser Name'
                        className='form-control form-control'
                        name='organiser'
                        value={eventDetails.organiser}
                        onChange={handleEventDetails}
                        disabled={eventId !== 0 ? true : false}
                      />
                      <span className='error'>{errors.organiser}</span>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <div className='form-group required required'>
                      <Form.Label className='form-label'>
                        Name of POC (On-Ground)
                      </Form.Label>
                      <input
                        type='text'
                        className='form-control form-control'
                        placeholder='Enter POC Name'
                        name='poc'
                        value={eventDetails.poc}
                        onChange={handleEventDetails}
                        disabled={eventId !== 0 ? true : false}
                      />
                      <span className='error'>{errors.poc}</span>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group required'>
                      <Form.Label className='form-label'>
                        Is POC a data provider too?
                      </Form.Label>

                      <div className='radio-buttons no-req'>
                        <div
                          className='form-check'
                          onClick={() => {
                            eventId == 0 &&
                              setEventDetails({
                                ...eventDetails,
                                isPOCDataProvider: true,
                              });
                          }}
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            name='isPOCDataProvider'
                            id='yes-icon'
                            value={'true'}
                            checked={eventDetails.isPOCDataProvider === true}
                          />
                          <Form.Label
                            className='form-check-label'
                            htmlFor='yes-icon'
                          >
                            {translate('TRANSACTION_REGISTRY_PAGE.TITLES.YES')}
                          </Form.Label>
                        </div>
                        <div
                          className='form-check'
                          onClick={() =>
                            eventId == 0 &&
                            setEventDetails({
                              ...eventDetails,
                              isPOCDataProvider: false,
                            })
                          }
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            name='isPOCDataProvider'
                            id='no-icon'
                            value={'false'}
                            checked={eventDetails.isPOCDataProvider === false}
                          />
                          <Form.Label
                            className='form-check-label'
                            htmlFor='no-icon'
                          >
                            {translate('TRANSACTION_REGISTRY_PAGE.TITLES.NO')}
                          </Form.Label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {eventDetails.isPOCDataProvider === false && (
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>
                          Name of Data Provider
                        </Form.Label>
                        <input
                          type='text'
                          className='form-control form-control'
                          placeholder='Enter Data Provider'
                          name='dataProvider'
                          value={eventDetails.dataProvider}
                          onChange={handleEventDetails}
                          disabled={eventId !== 0 ? true : false}
                        />
                        <span className='error'>{errors.dataProvider}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='row'>
                  <div className='col-md-4'>
                    <div className='form-group required required'>
                      <Form.Label className='form-label'>Location</Form.Label>
                      <input
                        type='text'
                        placeholder='Enter Location'
                        className='form-control form-control'
                        value={eventDetails.location}
                        name='location'
                        onChange={handleEventDetails}
                        disabled={eventId !== 0 ? true : false}
                      />
                      <span className='error'>{errors.location}</span>
                    </div>
                  </div>
                </div>
              </div> */}
                  <div className='activity-box'>
                    <div className='row d-flex align-items-center'>
                      <div className='col-md-12'>
                        <div className='left-area'>
                          <div className='title'>Activity Details</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='panel-body'>
                    <div className='row'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>
                          Material Provided
                        </Form.Label>
                      </div>
                      {materialList.length > 0 &&
                        materialList.map((data: any, i: any) => {
                          const id = data?.id;
                          return (
                            <div className='col-2 col-sm-6 col-md-4 col-lg-3 col-xl-2'>
                              <div className='form-check ps-0'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id={`defaultCheck${id}`}
                                  checked={selectedMaterial.includes(id)}
                                  value=''
                                  onClick={() => {
                                    if (selectedMaterial.length === 0) {
                                      setSelectedMaterial([id]);
                                    } else if (selectedMaterial.includes(id)) {
                                      let newArray = selectedMaterial;
                                      newArray = newArray.filter(
                                        (u: any) => u !== id
                                      );
                                      setSelectedMaterial(newArray);
                                    } else {
                                      setSelectedMaterial([
                                        ...selectedMaterial,
                                        id,
                                      ]);
                                    }
                                  }}
                                />
                                <Form.Label
                                  className='form-check-label'
                                  htmlFor={`defaultCheck${id}`}
                                >
                                  {data.name}
                                </Form.Label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className='row mt-4'>
                      <div className='col-2 col-sm-6 col-md-4 col-lg-3 col-xl-2'>
                        <div className='form-check ps-0'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='isOther'
                            value=''
                            checked={activityDetails.isOtherMaterial}
                            onClick={() => {
                              setActivityDetails({
                                ...activityDetails,
                                isOtherMaterial:
                                  !activityDetails.isOtherMaterial,
                              });
                            }}
                          />
                          <Form.Label
                            className='form-check-label'
                            htmlFor='isOther'
                          >
                            Other...
                          </Form.Label>
                        </div>
                      </div>

                      {activityDetails.isOtherMaterial && (
                        <div className='col-md-3'>
                          <div className='form-group '>
                            <input
                              type='text'
                              className='form-control form-control'
                              placeholder='Enter Material Name'
                              name='otherMaterial'
                              value={activityDetails.otherMaterial}
                              onChange={(e) =>
                                setActivityDetails({
                                  ...activityDetails,
                                  otherMaterial: e.target.value,
                                })
                              }
                            />
                            <span className='error'>
                              {errors.otherMaterial}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='row mt-4'>
                      <div className='col-md-4'>
                        <div className='form-group group-i-input required'>
                          <Form.Label className='form-label'>
                            Date of Activity
                          </Form.Label>
                          <div className='date-item-picker'>
                            <div className='date-picker-main'>
                              <DatePicker
                                selected={activityDetails.activity_date}
                                className='form-control'
                                placeholderText={
                                  translate(
                                    'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                  ) + ''
                                }
                                dateFormat='dd/MM/yyyy'
                                onChange={handleActivityDate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                required
                              />
                              {/* {
                            <span className='error'>
                              {translate(errors.loadingSlipDetails.date)}
                            </span>
                          } */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <div className='mb-3'>
                            <Form.Label
                              htmlFor='formFile'
                              className='form-label'
                            >
                              Activity Photo
                            </Form.Label>
                            <Form.Control
                              className='file-input'
                              type='file'
                              onChange={uploadDocument}
                              name='activity_image'
                              id='activity_image'
                              accept='.jpg, .jpeg, .png'
                            />
                            {activityDetails.activity_photo && (
                              <div className='upload-icons'>
                                <i
                                  className='fa-solid fa-trash-can'
                                  onClick={() => {
                                    setActivityDetails({
                                      ...activityDetails,
                                      activity_photo: '',
                                    });
                                  }}
                                ></i>
                                <i
                                  className='fa fa-eye ms-2 me-2'
                                  onClick={() => {
                                    window.open(activityDetails.activity_photo);
                                  }}
                                  aria-hidden='true'
                                ></i>
                              </div>
                            )}
                            {activityDetails.activity_photo && (
                              <p className='upload-note text-end'>
                                File successfully uploaded
                                <svg
                                  width='800px'
                                  height='800px'
                                  viewBox='0 0 1024 1024'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fill='#18A675'
                                    d='M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z'
                                  />
                                </svg>
                              </p>
                            )}
                          </div>
                          <span className='error'>{errors.activityPhoto}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <h6>{props.message}</h6> */}
        {/* </Modal.Body> */}
        {/* <Modal.Footer>
          {!props.hideCancelButton && (
            <Button
              onClick={handleClose}
              variant='primary'
              type='button'
              className='btn btn-secondary btn-danger'
            >
              {props.cancelText ? props.cancelText : 'Cancel'}
            </Button>
          )}
          <Button onClick={handleOk} className='btn btn-primary'>
            {props.submitText ? props.submitText : 'Submit'}
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};
export default AddActivity;
