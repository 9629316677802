import React, { useState } from "react";

export const Faq = () => {
  const [remakeQus1, setRemakeQus1] = useState(true);
  const [remakeQus2, setRemakeQus2] = useState(false);
  const [remakeQus3, setRemakeQus3] = useState(false);
  const [remakeQus4, setRemakeQus4] = useState(false);
  const [remakeQus5, setRemakeQus5] = useState(false);
  const [remakeQus6, setRemakeQus6] = useState(false);
  const [remakeQus7, setRemakeQus7] = useState(false);
  const [remakeQus8, setRemakeQus8] = useState(false);

  const [plasticQus1, setPlasticQus1] = useState(true);
  const [plasticQus2, setPlasticQus2] = useState(false);
  const [plasticQus3, setPlasticQus3] = useState(false);
  const [plasticQus4, setPlasticQus4] = useState(false);
  const [plasticQus5, setPlasticQus5] = useState(false);
  const [plasticQus6, setPlasticQus6] = useState(false);
  const [plasticQus7, setPlasticQus7] = useState(false);

  const [partnerQus1, setPartnerQus1] = useState(true);
  const [partnerQus2, setPartnerQus2] = useState(false);
  const [partnerQus3, setPartnerQus3] = useState(false);
  const [partnerQus4, setPartnerQus4] = useState(false);
  return (
    <div className="body-content-space pb-0">
      <div className="container">
        <div className="title-description-content">
          <h2 className="text-center title-main">
            Frequently Asked Questions (FAQs)
          </h2>
          <div className="faq-list">
            <div className="faq-content">
              <div className="faq-main-title">ReMake Program</div>
              <ul className="listing">
                <li className={remakeQus1 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setRemakeQus1(!remakeQus1);
                    }}
                  >
                    What is ReMake Program?
                  </div>
                  <div className="answer">
                    ReMake Plastic Credit Program is an ESG initiative backed by
                    a technology-enabled financing mechanism to substantially
                    accelerate the collection and segregation of waste in
                    developing economies. It is a transparent and trustworthy
                    marketplace for consumers and recyclers to directly fund
                    waste collection, thereby enabling financing and social
                    upliftment of frontline benefactors - Reclaimers.
                  </div>
                </li>
                <li className={remakeQus2 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setRemakeQus2(!remakeQus2);
                    }}
                  >
                    Which materials would be covered under the scope of the
                    ReMake Program?
                  </div>
                  <div className="answer">
                    All materials including but not limited to plastic, steel,
                    aluminium, paper, rubber, etc. where waste generation takes
                    place would be covered under the scope of the ReMake
                    Program. However, in the initial phase, credits would be
                    offered only for plastics.
                  </div>
                </li>
                <li className={remakeQus3 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setRemakeQus3(!remakeQus3);
                    }}
                  >
                    What is the problem that ReMake platform proposes to
                    address?
                  </div>
                  <div className="answer">
                    <p>
                      Around 3 billion people lack access to waste management
                      system. More than 1 million tonnes of plastic waste is
                      generated daily. Less than 10% of this is recycled. Rest
                      all end up in landfills and oceans, also entering the food
                      chain as microplastics. Recently, the global plastic
                      recycling rate started declining after the Chinese ban on
                      plastic waste imports and after restrictions on plastic
                      waste trade by Basel Convention.
                    </p>
                    <p>
                      A minimum quantity of segregated plastic waste is needed
                      as feedstock to have an economically feasible recycling
                      operation. Therefore, imports of plastic waste were a
                      significant source of feedstock for the recycling industry
                      in developing countries, as these countries lack
                      infrastructure for the collection and segregation of
                      waste. We chose to take on this problem to reverse the
                      declining rate of plastic recycling by creating
                      infrastructure for the collection and segregation of waste
                      and bringing dignity to the profession of waste collection
                      and management.
                    </p>
                  </div>
                </li>
                <li className={remakeQus4 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setRemakeQus4(!remakeQus4);
                    }}
                  >
                    How will the ReMake platform effectively address the problem
                    described above?
                  </div>
                  <div className="answer">
                    <p>
                      ReMake platform enables the generation of Plastic Credits
                      that are certification and substantiation of the
                      transaction carried out towards the collection and
                      recycling of plastic waste. The credits are verifiable,
                      measurable, and tradeable.
                    </p>
                    <p>
                      <strong>
                        The mechanism developed combines technological prowess,
                        financial ingenuity, and Social Value creation
                        capabilities:
                      </strong>
                    </p>
                    <p>
                      <strong>Technological prowess:</strong> Elements like
                      Blockchain, and Machine Learning ensures end-to-end
                      traceability, transparency, smooth compliance, and user
                      experience. Also, it would help take the efforts to
                      untapped platforms like e-commerce and metaverse.
                    </p>
                    <p>
                      <strong>Financial ingenuity:</strong> Involves valorizing
                      and monetizing the efforts taken towards waste management,
                      making it possible for the larger community to participate
                      without directly being applied.
                    </p>
                    <p>
                      <strong>Social Value:</strong> Both technology and
                      financial mechanism would ensure that the right
                      benefactors are rewarded economically and uplifted
                      socially.
                    </p>
                  </div>
                </li>
                <li className={remakeQus5 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setRemakeQus5(!remakeQus5);
                    }}
                  >
                    How is technology at the core of ReMake’s proposed solution?
                  </div>
                  <div className="answer">
                    <p>
                      ReMake’s main functionality is to authenticate, valorize,
                      monetize plastic waste collection &amp; recycling efforts,
                      enabled through blockchain. The transactions registered in
                      its digital application involve features like geo-tagging,
                      optical scanning, and AI tools to facilitate not just a
                      zero-trust model but also convenience and scalability
                      globally.
                    </p>
                    <p>
                      It would involve creating waste and effort-maker data
                      capture from the origin point to the endpoint, making it
                      possible to issue staking components as a reward.
                    </p>
                    <p>
                      To further enhance platform offerings, fungible and
                      non-fungible tokens would also be issued, which can be
                      traded in popular crypto platforms and the NFT
                      marketplace.
                    </p>
                    <p>
                      The program also involves setting up recycling parks on
                      metaverse to improve waste management through Web 3.0.
                    </p>
                    <p>
                      All existing solutions deployed thus far by private or
                      governmental entities are primarily traditional in the
                      institutional mechanisms involved. All those initiatives
                      could easily be embedded in this new solution and achieve
                      milestones more rapidly.
                    </p>
                  </div>
                </li>
                <li className={remakeQus6 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setRemakeQus6(!remakeQus6);
                    }}
                  >
                    What other approaches have been tried in the past? What is
                    the insight or innovation that differentiates your project,
                    and how is it better than what already exists?
                  </div>
                  <div className="answer">
                    <p>
                      There have been many initiatives from enterprises and
                      regulators, the primary one being Extended Producer
                      Responsibility (EPR) regulations which have not been
                      successful in developing countries. Private and
                      public-funded projects like PETCO in South Africa have
                      been limited to a geographical area or product.
                    </p>
                    <p>
                      ReMake Plastic Credit Program has been designed on the
                      zero-trust system model. All transactions on the platform
                      to generate ReMake Plastic Credits are registered on
                      Blockchain, supported with authenticated documents, and
                      backed by features like geo-tagging and verification
                      strings to provide end-to-end traceability. This enables a
                      trustworthy ecosystem of collectors, balers, grinders, and
                      brands.
                    </p>
                    <p>
                      Additionally, what provides an unmatchable edge is that
                      Gemcorp (Promoter of the Program), through its parent
                      company Gemini, can implement Europe’s best practices when
                      it comes to Waste Management and have an existing
                      on-ground network, infrastructure &amp; expertise able to
                      scale up through its accessibility-inducing mechanism, and
                      global presence.
                    </p>
                  </div>
                </li>
                <li className={remakeQus7 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setRemakeQus7(!remakeQus7);
                    }}
                  >
                    Apart from enabling Circular Economy, what would the other
                    impacts of the ReMake Program be (Climate &amp; Social)?
                  </div>
                  <div className="answer">
                    <p>
                      <strong>Climate:</strong> Using recycled plastic material,
                      in contrast to using virgin plastic, would help save at
                      least 1 – 1.5 Kg GHG emissions per kg of plastic
                      consumption (Source: Ellen Macarthur Foundation Report –{" "}
                      <a href="https://ellenmacarthurfoundation.org/articles/building-a-world-free-from-waste-andpollution">
                        (https://ellenmacarthurfoundation.org/articles/building-a-world-free-from-waste-andpollution)
                      </a>
                      .
                    </p>
                    <p>
                      <strong>Social:</strong> By ensuring the reward reaches
                      the actual and right benefactors, ReMake would facilitate
                      the economical and social upliftment of frontline workers
                      (waste reclaimers), small waste collectors, and
                      aggregators. It would also help ensure that the recycling
                      sector across developing countries conduct business with
                      standard procedures and that no child or forced labor is
                      engaged anywhere.
                    </p>
                  </div>
                </li>
                <li className={remakeQus8 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setRemakeQus8(!remakeQus8);
                    }}
                  >
                    How will any revenue that your project earns would be
                    utilized?
                  </div>
                  <div className="answer">
                    <ul>
                      <li>For rewarding the Value Chain partners involved</li>
                      <li>
                        For Scaling-up waste collection physical and digital
                        infrastructure in developing countries to: <br />-
                        Collect, segregate and recycle more waste, thus reducing
                        plastic pollution and carbon emissions
                      </li>
                    </ul>
                    Uplift additional underprivileged reclaimers in developing
                    countries by ensuring proper identity, minimum wages, and no
                    child labor in the unorganized sector
                  </div>
                </li>
              </ul>
            </div>
            <div className="faq-content">
              <div className="faq-main-title">ReMake Plastic Credit</div>
              <ul className="listing">
                <li className={plasticQus1 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus1(!plasticQus1);
                    }}
                  >
                    What is ReMake Plastic Credit?
                  </div>
                  <div className="answer">
                    ReMake Plastic Credits are certification and substantiation
                    of the transaction carried out among participating
                    Value-chain level partners, enabling the collection and
                    recycling of plastic waste scrap, which is verifiable,
                    measurable, and tradeable.
                  </div>
                </li>
                <li className={plasticQus2 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus2(!plasticQus2);
                    }}
                  >
                    Who are the Value-chain level partners?
                  </div>
                  <div className="answer">
                    A Value-chain level partner could be any participating
                    reclaimer, collector, aggregator, processor, recycler, or
                    manufacturer who enables the collection and recycling of
                    plastic waste scrap and generate ReMake plastic credits,
                    after following the pre-defined ReMake standards.
                  </div>
                </li>
                <li className={plasticQus3 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus3(!plasticQus3);
                    }}
                  >
                    Who are the other stakeholders involved?
                  </div>
                  <div className="answer">
                    Producers, brands, consumers, or financial investors,
                    purchasing or trading ReMake credits for enabling the
                    collection &amp; recycling of plastic credits or offsetting
                    their plastic production/consumption.
                  </div>
                </li>
                <li className={plasticQus4 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus4(!plasticQus4);
                    }}
                  >
                    How are the Remake Plastic Credits generated verifiable?
                  </div>
                  <div className="answer">
                    ReMake Plastic Credit Program has been designed on the
                    zero-trust system model. All the transactions carried out on
                    the platform to generate ReMake Plastic Credits are
                    registered on Blockchain, supported with authenticated
                    documents, and backed by features like geo-tagging IPFS, and
                    strings of verification to provide end-to-end traceability.
                  </div>
                </li>
                <li className={plasticQus5 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus5(!plasticQus5);
                    }}
                  >
                    How many types of ReMake Plastic Credits are available?
                  </div>
                  <div className="answer">
                    <strong>
                      ReMake Plastic Credits offer three distinct types of
                      credits –
                    </strong>
                    <ul>
                      <li>
                        <strong> ReMake Collection Credit –</strong> Represents
                        only the collection enablement of waste material
                      </li>
                      <li>
                        <strong>ReMake Recycling Credit –</strong> Represents
                        only the recycling enablement of waste material
                      </li>
                      <li>
                        <strong>ReMake Circular Credit –</strong> Represents
                        both the collection &amp; recycling enablement of waste
                        material
                      </li>
                    </ul>
                  </div>
                </li>
                <li className={plasticQus6 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus6(!plasticQus6);
                    }}
                  >
                    What value does 1 Credit of each type of ReMake Credit
                    represent?
                  </div>
                  <div className="answer">
                    1 Credit of ReMake Collection, Recycling, and Circular,
                    represents 1 ton (1000 KGs) of waste Collection, Recycling,
                    and Collection + Recycling, enablement respectively.
                  </div>
                </li>
                <li className={plasticQus7 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus7(!plasticQus7);
                    }}
                  >
                    Does the purchase of ReMake credits constitute to offsetting
                    the purchaser’s plastic footprint?
                  </div>
                  <div className="answer">
                    Purchase of any ReMake Credits merely represents ownership
                    of the Credits (units purchased). The owned Credits can
                    further be used to either hold, forward, or offset (retire).
                    In order to offset a unit of plastic, the purchaser needs to
                    use the ‘Offset’ option provided on the ReMake platform, and
                    retire that particular unit of ReMake Credits.
                  </div>
                </li>
              </ul>
            </div>
            <div className="faq-content">
              <div className="faq-main-title">For Value Chain Partners</div>
              <ul className="listing">
                <li className={plasticQus1 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus1(!plasticQus1);
                    }}
                  >
                    Is the credit created when the physical transaction has been
                    done (weighbridge data) or is this based on the financial
                    transaction (payment of the invoice) (or should both be
                    fulfilled)?
                  </div>
                  <div className="answer">
                    The credit is created after the physical transaction is
                    done.
                  </div>
                </li>
                <li className={plasticQus2 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus2(!plasticQus2);
                    }}
                  >
                    What is considered as “recycled”? Is this the transformation
                    into pellets by the recycler, or the sale of pellets to a
                    converter?
                  </div>
                  <div className="answer">
                    Currently, it’s at the transformation to pellets, though the
                    plan is to go to the manufacturing of the final product.
                  </div>
                </li>
                <li className={plasticQus3 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus3(!plasticQus3);
                    }}
                  >
                    Would there be physical and data audits?
                  </div>
                  <div className="answer">
                    Yes, to ensure that we along with our Value-chain Partners
                    meet the highest ethical standards, industry best standards,
                    and regulations and avoid double accounting. There would be
                    periodical physical, system, and data audits through reputed
                    independent third-parties.
                  </div>
                </li>
                <li className={plasticQus4 ? "show-answer" : ""}>
                  <div
                    className="question"
                    onClick={(e) => {
                      setPlasticQus4(!plasticQus4);
                    }}
                  >
                    In some cases, from a bale of plastic waste, the recycler
                    would only recycle the film, and other fractions (straps,
                    colored film) are sold to another specialized recycler. How
                    will the platform handle this in the system?
                  </div>
                  <div className="answer">
                    A plastic credit is currently tagged with weight and not
                    with the consignment. A plastic credit is realized only
                    after the entire transaction is complete – so the final
                    weight of a particular product is the one on which the
                    credit is issued. Currently, this is the system being
                    followed. Going forward, we are going to go one level down
                    for the units which will break batches from the consignment
                    and tag to traceability – we are trying to do this with some
                    of our metal shipments as we speak.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
