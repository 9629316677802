import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

import {
  creditTypeLabel,
  getCreditType,
} from '../../../constants/comman.constant';
import moment from 'moment';

const TransactionLinked = (props: any) => {
  const { t: translate } = useTranslation();
  const [batchData, setBatchData] = useState<any>([]);
  useEffect(() => {
    if (props.batchData.length > 0) {
      setBatchData(
        props?.batchData
          ?.filter((data: any) => {
            return data?.link_transaction_certificate_detail?.length > 0;
          })
          .reverse()
      );
    }
  }, [props.batchData]);
  return (
    <>
      <div className='panel-box'>
        <div className='panel-title title-grey'>
          <div className='left'>
            <div className='title'>
              {translate(
                'PLASTIC_CREDIT_MANAGEMENT.TITLES.TRANSACTION_DETAILS'
              )}
            </div>
          </div>
        </div>
        <div className='panel-data transation-details'>
          <div className='transaction-data'>
            {batchData?.map((data: any, index: any) => {
              return (
                <div className='collapsible-accordion'>
                  <div className='collapsible-item'>
                    <input type='checkbox' id={`ac${index + 1}`} />
                    <label
                      className='collapsible-item-label row'
                      htmlFor={`ac${index + 1}`}
                    >
                      <div className='col-lg-8'>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/down-arrow-svg.svg`}
                        />
                        <p className='title'>
                          {translate(
                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.ALLOCATED_TRANSACTIONS'
                          )}{' '}
                          -{' '}
                          {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.BATCH')}{' '}
                          {index + 1}
                        </p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='date'>
                          {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.DATE')}:{' '}
                          {moment(data.created_on).format('DD/MM/YYYY')}
                        </p>
                      </div>
                    </label>

                    <div className='collapsible-item-content'>
                      <div className='transaction-data-table'>
                        <div className='table-list'>
                          <table>
                            <thead>
                              <tr>
                                <th></th>
                                <th>
                                  <div className='title'>
                                    {translate(
                                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.TRANSACTION_ID'
                                    )}
                                  </div>
                                </th>
                                <th>
                                  <div className='title'>
                                    {translate(
                                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_WASTE_TYPE'
                                    )}
                                  </div>
                                </th>
                                <th>
                                  <div className='title'>
                                    {translate(
                                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.QTY'
                                    )}
                                  </div>
                                </th>
                                <th>
                                  <div className='title'>
                                    {translate(
                                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.TYPE_OF_CREDITS'
                                    )}
                                  </div>
                                </th>
                                <th>
                                  <div className='title'>
                                    {translate(
                                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.TOTAL_CREDIT'
                                    )}
                                  </div>
                                </th>
                                <th>
                                  <div className='title'>
                                    {translate(
                                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.SELLER'
                                    )}
                                  </div>
                                </th>
                                <th>
                                  <div className='title'>
                                    {translate(
                                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.BUYER'
                                    )}
                                  </div>
                                </th>
                                <th>
                                <div className='title'>
                                       {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.DOCUMENT_CODE')}
                                 </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.link_transaction_certificate_detail?.map(
                                (u: any, i: any) => {
                                  return (
                                    <tr>
                                      <td>
                                        <Link
                                          target='_blank'
                                          to={`/admin/transaction/plasticCreditCertification/${window.btoa(
                                            u?.linked_transaction_detail?.transaction_id
                                          )}&${window.btoa(u?.pc_id)}`}
                                          title={
                                            translate(
                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.VIEW_CERTIFICATE'
                                            ) || ''
                                          }
                                        >
                                          <img
                                            src='/assets/images/certificate-icon.svg'
                                            alt='invite user'
                                          />
                                        </Link>
                                      </td>
                                      <td>{u?.linked_transaction_detail?.transaction_id}</td>
                                      <td>
                                        {u?.linked_transaction_detail?.material_type?.name}
                                        &nbsp; - &nbsp;
                                        {u?.linked_transaction_detail?.material_name_type?.name}
                                      </td>
                                      <td>
                                        {u?.linked_transaction_detail?.unloaded_quantity}
                                        &nbsp;
                                        {u?.linked_transaction_detail?.unloaded_quantity_unit}
                                      </td>
                                      <td>
                                        {getCreditType(u?.linked_transaction_detail?.credit_type)} Credit
                                      </td>
                                      <td> {u?.linked_transaction_detail?.credit_generated}</td>
                                      <td> {u?.linked_transaction_detail?.seller?.name}</td>
                                      <td>{u?.linked_transaction_detail?.buyer?.name}</td>
                                      <td>{u?.document_code}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                        {data.status_remark && (
                          <div className='remake-textarea'>
                            <div className='row mt-2'>
                              <div className='col-lg-12'>
                                <h5>Remarks</h5>
                                <textarea
                                  className='form-control'
                                  value={data?.status_remark}
                                  disabled
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionLinked;
