import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Form } from 'react-bootstrap';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import ConfirmationPopup from '../../../components/confirmationPopup';
import { createEventValidator } from './eventValidator';
import moment from 'moment';
import { commanValidation } from '../../../constants/comman.validation.constant';
import {
  useCreateEventMutation,
  useUpdateEventMutation,
} from '../../../app/api/services/socialWelfare';
import { routesConstant } from '../../../constants/comman.constant';
import { socialWelfareTab } from '../../../shared/remake-green-utils';

const AddEvent = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [data, setData] = useState<any>(null);
  const [addEvent] = useCreateEventMutation();
  const [editEvent] = useUpdateEventMutation();
  const [eventData, setEventData] = useState({
    eventName: '',
    eventDate: new Date(),
    isPOCDataProvider: false,
    dataProvider: '',
    organiser: '',
    location: '',
    poc: '',
  });
  const [validated, setValidated] = useState(false);
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not

  const [submitPopup, setSubmitPopup] = useState(false);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [errors, setErrors] = useState<any>({
    eventName: '',
    eventDate: '',
    poc: '',
    dataProvider: '',
    organiser: '',
    location: '',
  });

  const getEventDetails = async (id: string) => {
    let path = apiUrl.getEvent + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setData(res?.data);
      setEventData({
        eventName: res?.data.event.name,
        eventDate: new Date(res?.data.event.event_date),
        isPOCDataProvider: res?.data.event.is_on_ground_poc_data_provider,
        dataProvider: res?.data?.event?.data_provider,
        organiser: res?.data.event.organizer,
        location: res?.data.event.location,
        poc: res?.data?.event?.on_ground_poc,
      });
    } else {
      toast.error(res.message);
    }
  };

  const createEvent = async () => {
    let data = {
      name: eventData.eventName,
      location: eventData.location,
      onground_poc: eventData.poc,
      organizer: eventData.organiser,
      data_provider: eventData.dataProvider,
      is_on_ground_poc_data_provider: eventData.isPOCDataProvider,
      event_date: moment(eventData.eventDate).format('YYYY-MM-DD'),
    };

    const res = await addEvent(data).unwrap();
    if (res.success) {
      navigate(routesConstant.socialWelfare, { state: socialWelfareTab.EVENT });
      toast.success('Event Created Successfully !');
    } else {
      toast.error(res.message);
    }
  };
  const updateEvent = async () => {
    let data = {
      event_id: id,
      name: eventData.eventName,
      location: eventData.location,
      onground_poc: eventData.poc,
      organizer: eventData.organiser,
      data_provider: eventData.dataProvider,
      is_on_ground_poc_data_provider: eventData.isPOCDataProvider,
      event_date: moment(eventData.eventDate).format('YYYY-MM-DD'),
    };

    const res = await editEvent(data).unwrap();
    if (res.success) {
      navigate(routesConstant.socialWelfare, { state: socialWelfareTab.EVENT });
      toast.success('Event Updated Successfully !');
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    if (id) {
      getEventDetails(id);
    }
  }, [id]);

  const handleEventDate = (date: any) => {
    setEventData({ ...eventData, eventDate: date });
  };

  const onEventHandle = (e: any) => {
    setEventData({ ...eventData, [e.target.name]: e.target.value });
  };

  const validateForm = (eventValue = eventData, formSubmit = false) => {
    const errs: any = createEventValidator(errors, eventValue);
    setErrors(errs);
    const isFormValid = Object.keys(errs).every(
      (err) => errs[err].length === 0
    );
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      setSubmitPopup(true);
    }
  };

  return (
    <>
      {discardPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          message={`${translate('CSR_MODULE.TITLES.DISCARD_TEXT')}`}
          handleCancel={() => setDiscardPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          handleSubmit={() => {
            if (id) {
              setEventData({
                eventName: data.event.name,
                eventDate: new Date(data.event.event_date),
                isPOCDataProvider: data.event.is_on_ground_poc_data_provider,
                dataProvider: data?.event?.data_provider,
                organiser: data.event.organizer,
                location: data.event.location,
                poc: data?.event?.on_ground_poc,
              });
            } else
              setEventData({
                eventName: '',
                eventDate: new Date(),
                isPOCDataProvider: false,
                dataProvider: '',
                organiser: '',
                location: '',
                poc: '',
              });
            setDiscardPopup(false);
          }}
        />
      )}

      {submitPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          message={`${translate('CSR_MODULE.TITLES.SUBMIT_MESSAGE')}`}
          handleCancel={() => setSubmitPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.PROCEED')}`}
          handleSubmit={() => {
            if (id) {
              updateEvent();
            } else createEvent();
            setSubmitPopup(false);
          }}
        />
      )}
      <main>
        <div className='profile-page admin-profile-changes csr-addreclaimer'>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    {translate('CSR_MODULE.TITLES.HOME')}
                  </a>
                </li>
                <li className='breadcrumb-item'>
                  <a
                    onClick={() => {
                      navigate('/admin/social-welfare', {
                        state: socialWelfareTab.EVENT,
                      });
                    }}
                  >
                    {translate('CSR_MODULE.TITLES.SOCIAL_WELFARE')}
                  </a>
                </li>
                <li className='breadcrumb-item active'>{translate('CSR_MODULE.TITLES.ADD_EVENT')}</li>
              </ol>
            </nav>
            <div className='section-title-main'>
              <div className='title'>{translate('CSR_MODULE.TITLES.ADD_EVENT')}</div>
              {/* <div className='form-o-submit'>
                <a className='export-btn' href='/admin/generateCertificate'>
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='21'
                    height='15.273'
                    viewBox='0 0 21 15.273'
                  >
                    <path
                      id='Path_export'
                      data-name='Path / export'
                      d='M22,11.636,18.182,7.818v2.864H9.591v1.909h8.591v2.864M1,17.364V5.909A1.909,1.909,0,0,1,2.909,4H14.364a1.909,1.909,0,0,1,1.909,1.909V8.773H14.364V5.909H2.909V17.364H14.364V14.5h1.909v2.864a1.909,1.909,0,0,1-1.909,1.909H2.909A1.909,1.909,0,0,1,1,17.364Z'
                      transform='translate(-1 -4)'
                    />
                  </svg>{' '}
                  Export
                </a>
              </div> */}
            </div>
            <div className='display-combine-component'>
              <div className='display-component main-profile-head'>
                <div className='display-head'>
                  <div className='title'>{translate('CSR_MODULE.TITLES.EVENT_DETAILS')}</div>
                  <div className='form-submit-buttons auto-width-button'>
                    <button
                      type='button'
                      className='btn btn-primary btn-outline-primary btn-sm btn btn-primary'
                      onClick={() => setDiscardPopup(true)}
                    >
                      {translate('CSR_MODULE.TITLES.DISCARD')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm btn btn-primary ms-3'
                      onClick={() => {
                        setIsSubmited(true);
                        validateForm(eventData, true);
                      }}
                    >
                      {translate('CSR_MODULE.TITLES.SAVE_DETAILS')}
                    </button>
                  </div>
                </div>
                <Form
                  className='display-body transaction-registry-form'
                  // id="transactionregistryform"
                  noValidate
                  validated={validated}
                >
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.EVENT_NAME')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_EVENT_NAME')}`}
                          className='form-control'
                          name='eventName'
                          value={eventData.eventName}
                          maxLength={commanValidation.maxLength}
                          onChange={onEventHandle}
                          required
                        />
                        {<span className='error'>{errors.eventName}</span>}
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group date-input'>
                        <label className='form-label'>{translate('CSR_MODULE.TITLES.DATE_OF_EVENT')}</label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selected={eventData.eventDate}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleEventDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              required
                              // disabled={id ? true : false}
                            />
                            {/* {
                            <span className='error'>
                              {translate(errors.loadingSlipDetails.date)}
                            </span>
                          } */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.ORGANISER')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='organiser'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_ORGANISER_NAME')}`}
                          className='form-control'
                          value={eventData.organiser}
                          maxLength={commanValidation.maxLength}
                          onChange={onEventHandle}
                          required
                        />
                        <span className='error'>{errors.organiser}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.NAME_OF_POC')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='poc'
                          className='form-control form-control'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_POC_NAME')}`}
                          value={eventData.poc}
                          maxLength={commanValidation.maxLength}
                          onChange={onEventHandle}
                          required
                        />
                        {<span className='error'>{errors.poc}</span>}
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <label className='form-label'>
                          {translate('CSR_MODULE.TITLES.IN_A_POC_DATAPROVIDER')}
                        </label>

                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='isPOCDataProvider'
                              id='yes-icon'
                              value={'true'}
                              checked={eventData.isPOCDataProvider === true}
                              onChange={() =>
                                setEventData({
                                  ...eventData,
                                  isPOCDataProvider: true,
                                })
                              }

                              // disabled={id ? true : false}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='yes-icon'
                            >
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.YES'
                              )}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='isPOCDataProvider'
                              id='no-icon'
                              value={'false'}
                              checked={eventData.isPOCDataProvider === false}
                              onChange={() =>
                                setEventData({
                                  ...eventData,
                                  isPOCDataProvider: false,
                                })
                              }
                              // disabled={id ? true : false}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='no-icon'
                            >
                              {translate('TRANSACTION_REGISTRY_PAGE.TITLES.NO')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {eventData.isPOCDataProvider === false && (
                      <div className='col-md-4'>
                        <div className='form-group required required'>
                          <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.NAME_OF_DATAPROVIDER')}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            className='form-control form-control'
                            placeholder='Enter Data Provider'
                            name='dataProvider'
                            maxLength={commanValidation.maxLength}
                            value={eventData.dataProvider}
                            onChange={onEventHandle}
                            required
                          />
                          {<span className='error'>{errors.dataProvider}</span>}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>{translate('CSR_MODULE.TITLES.LOCATION')}</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Location'
                          maxLength={commanValidation.maxLength}
                          className='form-control form-control'
                          name='location'
                          value={eventData.location}
                          onChange={onEventHandle}
                          required
                        />
                        {<span className='error'>{errors.location}</span>}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddEvent;
