import {
  commonInput,
  emailPatern,
  mobilePatern,
  commanValidation,
  aadhaarNumber,
} from '../../../constants/comman.validation.constant';
import {
  validationMessage,
  RequiredError,
} from '../../../constants/validation.message.constant';
import { commanLabel } from '../../../constants/comman.constant';

export const createDomainValidator = (errors: any, domainInfo: any) => {
  let tempErrors = { ...errors };
  if (domainInfo.url) {
    if (!commonInput.test(domainInfo.url)) {
      tempErrors = {
        ...tempErrors,
        reclaimerName: validationMessage.invalidURL,
      };
    } else {
      tempErrors = { ...tempErrors, url: '' };
    }
  } else tempErrors = { ...tempErrors, url: RequiredError.domainUrl };

  if (domainInfo.email !== '') {
    if (domainInfo.email) {
      if (!emailPatern.test(domainInfo.email)) {
        tempErrors = {
          ...tempErrors,
          email: validationMessage.emailRequired,
        };
      } else {
        tempErrors = { ...tempErrors, email: '' };
      }
    } else
      tempErrors = {
        ...tempErrors,
        email: RequiredError.referenceValue,
      };
  }

  return tempErrors;
};
