import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { isEmptyDeep } from "../../../../utils/helper/common";
import {
  commanLabel,
  imagePath,
  routesConstant
} from "../../../../constants/comman.constant";
import { valueChainPartnerValidator } from './valueChainPartnerValidator';
import { useTranslation } from "react-i18next";
import { useValueChainPartnerMutation } from "../../../../app/api/services/transaction.service";
import { apiUrl } from '../../../../app/api/services/apiUrl';
import { helper } from '../../../../app/api/api.helper';
import { commanValidation } from "../../../../constants/comman.validation.constant";
import { store } from "../../../../app/store";
import { useAppDispatch } from "../../../../app/hooks";
import { authLogout } from "../../../../app/store/slices/auth.slice";
import { UserProfileStatus } from "../../../../shared/remake-green-utils";

export const ValueChainPartner = () => {
  const { t: translate } = useTranslation();
  const state = store.getState();
  const dispatch = useAppDispatch();
  const [valueChainPartner] = useValueChainPartnerMutation();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const user_id = state?.auth?.user?.value_chain_request_id;
  const user_profile_status = state?.auth?.user?.status;
  const [formData, setFormData] = useState({

    contact_number: "",
    email: "",
    password: "",
    name: "",
    jobtitle: "",
    company_name: "",
    address: "",
    country_id: "", // India location selected by default
    state_id: "",
    city_id: "",
    zip_code: "",
    org_email: "",
    org_telephone: "",
    website: "",
    billing_company_name: "",
    billing_address: "",
    billing_country_id: "", // India location selected by default
    billing_state_id: "",
    billing_city_id: "",
    billing_zip_code: "",
    billing_org_email: "",
    billing_org_telephone: "",
    id:"",
   

  });
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not 

  const [errors, setErrors] = useState<any>({
    name: "",
    contact_number: "",
    email: "",
    password:"",
    company_name: "",
    description: "",
    country_id:"",
    state_id:"",
    city_id:"",
    zip_code:"",
    address:""
  });
  const [countries, setCountries] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const validateForm = (formValues = formData, formSubmit = false) => {
    const errs: any = valueChainPartnerValidator(errors, formValues);
    setErrors(errs);
    const isFormValid = isEmptyDeep(errs);
    if (formSubmit && isFormValid) {
      onsubmit();
    }
  }
  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if(user_id) {
      fetchProfile();
    }
    
}, []);
const fetchProfile = async (refresh = false) => {

    let path = apiUrl.getValueChainProfile;
    const param = {
        user_id,
    };
    const fr = await helper.getData(path, param);
    const res = await fr.response;
    if (res.success) {

      getStates(
        res?.data?.userDetail?.countries?.id
          ? res?.data?.userDetail?.countries?.id
          : 0
      );
      getCities(
        res?.data?.userDetail?.states?.id
          ? res?.data?.userDetail?.states?.id
          : 0
      );


      setFormData({
        ...formData,
       email: res?.data?.userDetail?.email,
       contact_number: res?.data?.userDetail?.contact_number,
       password: "",
       name: res?.data?.userDetail?.name,
      jobtitle:res?.data?.userDetail?.jobtitle,
      company_name: res?.data?.userDetail?.company_name,
      address: res?.data?.userDetail?.address,
      country_id: res?.data?.userDetail?.countries?.id,
      zip_code: res?.data?.userDetail?.zip_code,
      state_id: res?.data?.userDetail?.states?.id,
      city_id: res?.data?.userDetail?.cities?.id,
      org_email: res?.data?.userDetail?.org_email,
      org_telephone: res?.data?.userDetail?.org_telephone,
      website: res?.data?.userDetail?.website,
      billing_company_name: res?.data?.userDetail?.billing_company_name,
      billing_country_id: res?.data?.userDetail?.billing_countries?.id, // India location selected by default
      billing_state_id: res?.data?.userDetail?.billing_states?.id,
      billing_city_id: res?.data?.userDetail?.billing_cities?.id,
      billing_address: res?.data?.userDetail?.billing_address,
      billing_zip_code: res?.data?.userDetail?.billing_zip_code,
      billing_org_email: res?.data?.userDetail?.billing_org_email,
      billing_org_telephone: res?.data?.userDetail?.billing_org_telephone,
      id:user_id
      });
    
    } else {

        toast.error(res.message);
    }
};



  const onsubmit = () => {
    valueChainPartner(formData)
      .then((res: any) => {
        if (res?.data?.success && res?.data?.statusCode === 201) {
          toast.success(res?.data?.data);
          dispatch(authLogout());
          window.location.href = routesConstant.default;
        } else {
          toast.error(translate('TOAST.ERRORS.SOMETHING_WENT_WRONG'));
        }
      })
      .catch((err: any) => {
        toast.error(translate('TOAST.ERRORS.SOMETHING_WENT_WRONG'));
      });
  };

  const handleChange = (e: any) => {
    if (isSubmitted) {
      validateForm({ ...formData, [e.target.name]: e.target.value });
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }
  const getCountries = async () => {
    let path = apiUrl.getCountries;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let countries = res?.data && res?.data?.countries;
      setCountries(countries);
    } else {
      toast.error(res.message);
    }
  };

  const getStates = async (CountryId: any) => {
    let path = apiUrl.getStates + CountryId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let states = res?.data && res?.data?.states;
      setStates(states);
    } else {
      toast.error(res.message);
    }
  };

  const getCities = async (stateId: any) => {
    let path = apiUrl.getCities + stateId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let cities = res?.data && res?.data?.cities;
      setCity(cities);
    } else {
      toast.error(res.message);
    }
  };
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  
  useEffect(() => {
    if(isChecked) {
      setFormData({
        ...formData,
        ['billing_company_name']: formData.company_name,
        ['billing_address']: formData.address,
        ['billing_country_id']: formData.country_id,
        ['billing_state_id']: formData.state_id,
        ['billing_city_id']: formData.city_id,
        ['billing_zip_code']: formData.zip_code,
        ['billing_org_email']: formData.org_email,
        ['billing_org_telephone']: formData.org_telephone,
      });
    } else {
      setFormData({
        ...formData,
        ['billing_company_name']: '',
        ['billing_address']: '',
        ['billing_country_id']: '',
        ['billing_state_id']: '',
        ['billing_city_id']: '',
        ['billing_zip_code']: '',
        ['billing_org_email']: '',
        ['billing_org_telephone']: '',
      });
    }
  }, [isChecked]);
  return (
    <div className="body-content-space pb-0">
      <div className="container">
        <div className="image-grid-data">
        <Row className='d-flex align-items-center'>
                <Col md={7} sm={12}>
                 
                </Col>
                <Col className='d-flex justify-content-md-end' md={5} sm={12}>
                  {user_profile_status ===
                    UserProfileStatus.PENDING && (
                    <div className='status-action'>
                      <span className='status-box status-box-yellow'></span>
                      {translate('PROFILE.TITLES.PENDING_FOR_APPROVAL')}
                      <i
                        className='fa fa-info-circle tooltip-icon-data'
                        title='Request is pending from admin side'
                      />
                    </div>
                  )}
                  {user_profile_status ===
                    UserProfileStatus.REJECTED && (
                    <>
                      <div className='status-action'>
                        <span className='status-box status-box-red'></span>
                        {translate('PROFILE.TITLES.REJECTED')}
                       
                      </div>
                    </>
                  )}
                </Col>
              </Row>
          <div className="row">
            <div className="col-md-12 ">
              <div className="panel-data">
                <h3>{translate('VALUECHAIN.TITLES.ENTER_REQUIRED_DETAILS')}</h3>
                <div className="my-3">{translate('VALUECHAIN.TITLES.CONTACT_YOU_SOON')} </div>
                <div className="row">
                
                <div className="my-3">{translate('VALUECHAIN.TITLES.LOGIN_DETAIL')}</div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.EMAIL_ADDRESS')}</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_EMAIL_ADDRESS')}`}
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      {<span className="error">{translate(errors.email)}</span>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.CONTACT_NUMBER')}</Form.Label>
                      <div className="cell-item">
                        <span className="prefix">
                          {commanLabel.countryCode}
                        </span>
                        <input
                          type={"number"}
                          placeholder={`${translate('VALUECHAIN.TITLES.ENTER_CONTACT_NUMBER')}`}
                          className="form-control form-control"
                          name="contact_number"
                          value={formData.contact_number}
                          onChange={handleChange}
                          required
                        />
                        {<span className="error">{translate(errors.contact_number)}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.PASSWORD')}</Form.Label>
                      <input
                        type="password"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_PASSWORD')}`}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {<span className="error">{translate(errors.password)}</span>}
                    </div>
                  </div>
                  <div className="my-3">{translate('VALUECHAIN.TITLES.PERSONAL_DETAIL')}</div>
                  {/* Personal Details */}
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.NAME')}</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_NAME')}`}
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {<span className="error">{translate(errors.name)}</span>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <Form.Label>{translate('VALUECHAIN.TITLES.JOB_TITLE')}</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_JOB_TITLE')}`}
                        name="jobtitle"
                        value={formData.jobtitle}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {/* {<span className="error">{translate(errors.jobtitle)}</span>} */}
                    </div>
                  </div>
                  <div className="col-md-4"></div>

                  {/* Organization Detail */}
                  <div className="my-3">{translate('VALUECHAIN.TITLES.ORGANIZATION_DETAIL')}</div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.COMPANY_NAME')}</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_COMPANY_NAME')}`}
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {<span className="error">{translate(errors.company_name)}</span>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.ADDRESS')}</Form.Label>
                      <input
                        type="text"
                        className="form-control required"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ADDRESS')}`}
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {<span className="error">{translate(errors.address)}</span>}
                    </div>
                  </div>

                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.COUNTRY')}
                      </label>
                      <Form.Select
                        name='country_id'
                        className='form-control select-data form-select'
                        value={formData.country_id}
                        onChange={(e) => {
                          handleChange(e);
                          getStates(e.target.value);
                        }}
                        required
                      >
                        <option value=''>
                          {translate('COMMONFIELDS.SELECT_COUNTRY')}
                        </option>
                        {countries?.length > 0 &&
                          countries?.map((u: any, i: any) => {
                            return (
                              <option key={i} value={u.id}>
                                {u.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      {
                        <span className='error'>
                          {translate(errors.country_id)}
                        </span>
                      }
                    </div>
                  </div>
                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.STATE')}
                      </label>
                      <Form.Select
                        name='state_id'
                        className='form-control select-data form-select'
                        value={formData.state_id}
                        onChange={(e) => {
                          handleChange(e);
                          getCities(e.target.value);
                        }}
                        required
                        disabled={formData.country_id ? false : true}
                      >
                        <option value=''>
                          {translate('COMMONFIELDS.SELECT_STATE')}
                        </option>
                        {states?.length > 0 &&
                          states?.map((u: any, i: any) => {
                            return (
                              <option key={i} value={u.id}>
                                {u.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      {<span className='error'>{translate(errors.state_id)}</span>}
                    </div>
                  </div>

                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.CITY')}
                      </label>
                      <Form.Select
                        name='city_id'
                        className='form-control select-data form-select'
                        value={formData.city_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        disabled={formData.state_id ? false : true}
                        required
                      >
                        <option value=''>
                          {translate('COMMONFIELDS.SELECT_CITY')}
                        </option>
                        {city?.length > 0 &&
                          city?.map((u: any, i: any) => {
                            return (
                              <option key={i} value={u.id}>
                                {u.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      {<span className='error'>{translate(errors.city_id)}</span>}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group required'>
                      <label>{translate('COMMONFIELDS.ZIP_CODE')}</label>
                      <Form.Control
                        name='zip_code'
                        placeholder={translate('COMMONFIELDS.ENTER_ZIP_CODE') + ''}
                        type='text'
                        className='form-control form-control'
                        maxLength={commanValidation.maxZipCode}
                        value={formData.zip_code}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                      />
                      {<span className='error'>{translate(errors.zip_code)}</span>}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group'>
                      <label>{translate('VALUECHAIN.TITLES.ORGANIZATION_EMAIL')}</label>
                      <Form.Control
                        name='org_email'
                        placeholder={translate('VALUECHAIN.TITLES.ENTER_ORGANIZATION_EMAIL') + ''}
                        type='text'
                        className='form-control form-control'
                        value={formData.org_email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group'>
                      <label>{translate('VALUECHAIN.TITLES.ORGANIZATION_TELEPHONE')}</label>
                      <Form.Control
                        name='org_telephone'
                        placeholder={translate('VALUECHAIN.TITLES.ENTER_ORGANIZATION_TELEPHONE') + ''}
                        type='text'
                        className='form-control form-control'
                        value={formData.org_telephone}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group'>
                      <label>{translate('VALUECHAIN.TITLES.WEBSITE')}</label>
                      <Form.Control
                        name='website'
                        placeholder={translate('VALUECHAIN.TITLES.WEBSITE') + ''}
                        type='text'
                        className='form-control form-control'
                        value={formData.website}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                {/* billing */}
                <div className="my-3">{translate('VALUECHAIN.TITLES.BILLING_DETAIL')}</div>
                <div className='note mb-2'>
                  <div className='form-check p'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='same_as_above'
                      id='same_as_above'
                      checked={isChecked}
                      onChange={handleOnChange}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='same_as_above'
                    >
                      {translate('VALUECHAIN.TITLES.SAMEASABOVE')}&nbsp;
                    </label>
                   
                   
                  </div>
                </div>


                <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.COMPANY_NAME')}</Form.Label>
                      <input
                        type="text"
                        className="form-control required"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_COMPANY_NAME')}`}
                        name="billing_company_name"
                        value={formData.billing_company_name}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {/* {<span className="error">{translate(errors.billing_company_name)}</span>} */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.ADDRESS')}</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ADDRESS')}`}
                        name="billing_address"
                        value={formData.billing_address}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {/* {<span className="error">{translate(errors.billing_address)}</span>} */}
                    </div>
                  </div>

                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.COUNTRY')}
                      </label>
                      <Form.Select
                        name='billing_country_id'
                        className='form-control select-data form-select'
                        value={formData.billing_country_id}
                        onChange={(e) => {
                          handleChange(e);
                          getStates(e.target.value);
                        }}
                        required
                      >
                        <option value=''>
                          {translate('COMMONFIELDS.SELECT_COUNTRY')}
                        </option>
                        {countries?.length > 0 &&
                          countries?.map((u: any, i: any) => {
                            return (
                              <option key={i} value={u.id}>
                                {u.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      {
                        <span className='error'>
                          {translate(errors.billing_country_id)}
                        </span>
                      }
                    </div>
                  </div>
                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.STATE')}
                      </label>
                      <Form.Select
                        name='billing_state_id'
                        className='form-control select-data form-select'
                        value={formData.billing_state_id}
                        onChange={(e) => {
                          handleChange(e);
                          getCities(e.target.value);
                        }}
                        required
                        disabled={formData.billing_country_id ? false : true}
                      >
                        <option value=''>
                          {translate('COMMONFIELDS.SELECT_STATE')}
                        </option>
                        {states?.length > 0 &&
                          states?.map((u: any, i: any) => {
                            return (
                              <option key={i} value={u.id}>
                                {u.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      {/* {<span className='error'>{translate(errors.billing_state_id)}</span>} */}
                    </div>
                  </div>

                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.CITY')}
                      </label>
                      <Form.Select
                        name='billing_city_id'
                        className='form-control select-data form-select'
                        value={formData.billing_city_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        disabled={formData.billing_state_id ? false : true}
                        required
                      >
                        <option value=''>
                          {translate('COMMONFIELDS.SELECT_CITY')}
                        </option>
                        {city?.length > 0 &&
                          city?.map((u: any, i: any) => {
                            return (
                              <option key={i} value={u.id}>
                                {u.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      {/* {<span className='error'>{translate(errors.billing_city_id)}</span>} */}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group required'>
                      <label>{translate('COMMONFIELDS.ZIP_CODE')}</label>
                      <Form.Control
                        name='billing_zip_code'
                        placeholder={translate('COMMONFIELDS.ENTER_ZIP_CODE') + ''}
                        type='text'
                        className='form-control form-control'
                        maxLength={commanValidation.maxZipCode}
                        value={formData.billing_zip_code}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                      />
                      {/* {<span className='error'>{translate(errors.billing_zip_code)}</span>} */}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group required'>
                      <label>{translate('VALUECHAIN.TITLES.ORGANIZATION_EMAIL')}</label>
                      <Form.Control
                        name='billing_org_email'
                        placeholder={translate('VALUECHAIN.TITLES.ENTER_ORGANIZATION_EMAIL') + ''}
                        type='text'
                        className='form-control form-control'
                        value={formData.billing_org_email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group required'>
                      <label>{translate('VALUECHAIN.TITLES.ORGANIZATION_TELEPHONE')}</label>
                      <Form.Control
                        name='billing_org_telephone'
                        placeholder={translate('VALUECHAIN.TITLES.ENTER_ORGANIZATION_TELEPHONE') + ''}
                        type='text'
                        className='form-control form-control'
                        value={formData.billing_org_telephone}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <Button
                        onClick={() => {
                          setIsSubmited(true);
                          validateForm(formData, true);
                        }}
                        className="btn btn-primary"
                      >
                        {translate('BUTTONS.SUBMIT')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-5">
              <div className="right-image">
                <img src={imagePath.recycleLogoPath} alt={""} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>

  )
}