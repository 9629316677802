import { type } from 'os';
import { plasticCreditApi } from '..';
import { ServiceResponseInterface } from '../../types/serverResponse';
import { apiUrl } from './apiUrl';

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({
    transaction: builder.query({
      query: (partnerId) =>
        `transaction-managment/transaction/listByValueChainPartner?partnerId=${partnerId}`,
    }),

    getLinkedTransactions: builder.query({
      query: ({ page, limit, creditType, forGenerateCertificate }) =>
        `/transaction-managment/transaction/linkedTransactionList?page=${page}&limit=${limit}&creditType=${creditType}&isForGenerateCertificate=${forGenerateCertificate}`,
    }),

    getCertificateList: builder.query({
      query: ({
        page,
        limit,
        start_date,
        end_date,
        status,
        type_of_material,
        region,
        creditType,
      }) =>
        `/plastic-credit-request-management/plastic-credit/list?page=${page}&limit=${limit}&start_date=${start_date}&end_date=${end_date}&status=${status}&type_of_material=${type_of_material}&region=${region}&credit_type=${creditType}`,
    }),

    getCountries: builder.query({
      query: (data) => `/get-settings/setting/getCountries`,
    }),

    getSettings: builder.query({
      query: (data) => `/get-settings/settings/list`,
    }),

    getMaterialCategory: builder.query({
      query: (id) => `get-settings/setting/getMaterialSubtypeById?Id=${id}`,
    }),

    linkTransaction: builder.mutation<any, any>({
      query(body) {
        return {
          url: `transaction-managment/transaction/createLinkedTransactions`,
          method: 'POST',
          body,
        };
      },
    }),

    generateCertificate: builder.mutation<any, any>({
      query(body) {
        return {
          url: `/plastic-credit-request-management/plastic-credit/create`,
          method: 'POST',
          body,
        };
      },
    }),

    updateCertificate: builder.mutation<any, any>({
      query(body) {
        return {
          url: `/plastic-credit-request-management/plastic-credit/request/update`,
          method: 'POST',
          body,
        };
      },
    }),
    sendEmail: builder.mutation<any, any>({
      query(id) {
        return {
          url: `/plastic-credit-request-management/plastic-credit/send-certificate?credit_id=${id}`,
          method: 'POST',
        };
      },
    }),
    valueChainPartner: builder.mutation<any, any>({
      query(body) {
        return {
          url: apiUrl.createValueChainPartner,
          method: 'POST',
          body,
        };
      },
    }),

    contactUs: builder.mutation<any, any>({
      query(body) {
        return {
          url: apiUrl.contactUs,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useTransactionQuery,
  useGetLinkedTransactionsQuery,
  useGetMaterialCategoryQuery,
  useGetCertificateListQuery,
  useGetSettingsQuery,
  useGetCountriesQuery,
  useLinkTransactionMutation,
  useUpdateCertificateMutation,
  useGenerateCertificateMutation,
  useSendEmailMutation,
  useValueChainPartnerMutation,
  useContactUsMutation,
} = extendedApi;
