import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CSRActivityList from "../plasticCreditCertificate/plasticCreditCertificate/csrActivityList";

export interface ConfirmationData {
    handleCancel: any;
    handleSubmit: any;
    title: string;
    cancelText?: string;
    submitText?: string;
    hideCancelButton?: boolean;
    data: any
}

const ViewActivity = (props: ConfirmationData) => {
    const [show, setShow] = useState(true);
    const handleClose = () => {
        setShow(false);
        props.handleCancel();
    };
    const handleOk = () => {
        setShow(false);
        props.handleSubmit();
    };
    
    useEffect(() => {
      console.log(props.data)  
      }, []);
    
    return (
        <>
            <Modal className="modal fade small-popup reclaimer_popup" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="usermanagement-modal pb-3 pt-4">
                <CSRActivityList data={props.data} hideHeader={true} />
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ViewActivity;
