import { commanLabel } from '../../../constants/comman.constant';
import {
  commanValidation,
  commonAddress,
  commonInput,
  emailPatern,
  mobilePatern,
} from '../../../constants/comman.validation.constant';

export const transactionRequestValidator = (errors: any, userInfo: any) => {
  let tempErrors = { ...errors };

  const getExpressionError = (
    value: any,
    expPattern: any,
    invalidErr: any,
    requiredErr: any
  ) => {
    return value
      ? !new RegExp(expPattern).test(value)
        ? invalidErr
        : ''
      : requiredErr;
  };

  tempErrors = {
    ...tempErrors,
    onBehalfName: getExpressionError(
      userInfo.onBehalfName,
      commonInput,
      'ERRORS.INVALID.NAME',
      'ERRORS.REQUIRED.NAME'
    ),
    materialName: getExpressionError(
      userInfo.materialName,
      commonInput,
      'ERRORS.INVALID.SUB_TYPE',
      'ERRORS.REQUIRED.SUB_TYPE'
    ),
    buyerName: getExpressionError(
      userInfo.buyerName,
      commonInput,
      'ERRORS.INVALID.BUYER_NAME',
      'ERRORS.REQUIRED.BUYER_NAME'
    ),
    buyer_mobile_no: getExpressionError(
      userInfo.buyer_mobile_no,
      mobilePatern,
      'ERRORS.INVALID.BUYER_MOBILE',
      ''
    ),
    seller_mobile_no: getExpressionError(
      userInfo.seller_mobile_no,
      mobilePatern,
      'ERRORS.INVALID.SELLER_MOBILE',
      ''
    ),
    buyer_email_id: getExpressionError(
      userInfo.buyer_email_id,
      emailPatern,
      'ERRORS.INVALID.EMAIL',
      ''
    ),
    seller_email_id: getExpressionError(
      userInfo.seller_email_id,
      emailPatern,
      'ERRORS.INVALID.EMAIL',
      ''
    ),
    buyer_address: getExpressionError(
      userInfo.buyer_address,
      commonAddress,
      'ERRORS.INVALID.BUYER_ADDRESS',
      'ERRORS.REQUIRED.BUYER_ADDRESS'
    ),
    sellerName: getExpressionError(
      userInfo.sellerName,
      commonInput,
      'ERRORS.INVALID.SELLER_NAME',
      'ERRORS.REQUIRED.SELLER_NAME'
    ),
    seller_address: getExpressionError(
      userInfo.seller_address,
      commonAddress,
      'ERRORS.INVALID.SELLER_ADDRESS',
      'ERRORS.REQUIRED.SELLER_ADDRESS'
    ),
    reference: getExpressionError(
      userInfo.reference,
      commonAddress,
      'ERRORS.INVALID.REFERENCE',
      'ERRORS.REQUIRED.REFERENCE'
    ),
    // payment_reference: getExpressionError(
    //   userInfo.payment_reference,
    //   commonAddress,
    //   'ERRORS.INVALID.PAYMENT_REFERENCE',
    //   'ERRORS.REQUIRED.PAYMENT_REFERENCE'
    // ),
    // payment_amount: getExpressionError(
    //   userInfo.payment_amount,
    //   commonAddress,
    //   'ERRORS.INVALID.PAYMENT_AMOUNT',
    //   'ERRORS.REQUIRED.PAYMENT_AMOUNT'
    // ),
    // payment_date: userInfo.payment_date ? '' : 'ERRORS.REQUIRED.DATE',
    loadingSlipDetails: {
      ...tempErrors.loadingSlipDetails,
      reference: getExpressionError(
        userInfo.loadingSlipDetails?.reference,
        commonAddress,
        'ERRORS.INVALID.REFERENCE',
        userInfo.isInwardTransfer ? '' : 'ERRORS.REQUIRED.REFERENCE'
      ),
    },
    unLoadingSlipDetails: {
      ...tempErrors.unLoadingSlipDetails,
      reference: getExpressionError(
        userInfo.unLoadingSlipDetails?.reference,
        commonAddress,
        'ERRORS.INVALID.REFERENCE',
        userInfo.isInwardTransfer ? '' : 'ERRORS.REQUIRED.REFERENCE'
      ),
    },
  };

  if (userInfo.quantity) {
    let minVal =
      userInfo.measurement_types == commanLabel.kgLabel
        ? commanValidation.kgMin
        : commanValidation.tonneMin;
    tempErrors = {
      ...tempErrors,
      quantity:
        userInfo.quantity < minVal ||
        userInfo.quantity > commanValidation.maxQty
          ? 'ERRORS.INVALID.QUANTITY'
          : '',
    };
  } else
    tempErrors = {
      ...tempErrors,
      quantity: userInfo.isInwardTransfer ? '' : 'ERRORS.REQUIRED.QUANTITY',
    };

  if (userInfo.loadingSlipDetails?.quantity) {
    const fieldValue = userInfo.loadingSlipDetails?.quantity;
    let minVal =
      userInfo.loadingSlipDetails.measurement_types == commanLabel.kgLabel
        ? commanValidation.kgMin
        : commanValidation.tonneMin;
    tempErrors = {
      ...tempErrors,
      loadingSlipDetails: {
        ...tempErrors.loadingSlipDetails,
        quantity:
          fieldValue < minVal || fieldValue > commanValidation.maxQty
            ? 'ERRORS.INVALID.QUANTITY'
            : '',
      },
    };
  } else
    tempErrors = {
      ...tempErrors,
      loadingSlipDetails: {
        ...tempErrors.loadingSlipDetails,
        quantity: userInfo.isInwardTransfer ? '' : 'ERRORS.REQUIRED.QUANTITY',
      },
    };

  if (userInfo.unLoadingSlipDetails?.quantity) {
    const fieldValue = userInfo.unLoadingSlipDetails?.quantity;
    let minVal =
      userInfo.unLoadingSlipDetails.measurement_types == commanLabel.kgLabel
        ? commanValidation.kgMin
        : commanValidation.tonneMin;
    tempErrors = {
      ...tempErrors,
      unLoadingSlipDetails: {
        ...tempErrors.unLoadingSlipDetails,
        quantity:
          fieldValue < minVal || fieldValue > commanValidation.maxQty
            ? 'ERRORS.INVALID.QUANTITY'
            : '',
      },
    };
  } else
    tempErrors = {
      ...tempErrors,
      unLoadingSlipDetails: {
        ...tempErrors.unLoadingSlipDetails,
        quantity: 'ERRORS.REQUIRED.QUANTITY',
      },
    };

  if (userInfo.ewayLorryReceipt?.quantity) {
    let minVal =
      userInfo.ewayLorryReceipt.measurement_types === commanLabel.kgLabel
        ? commanValidation.kgMin
        : commanValidation.tonneMin;

    const fieldValue = userInfo.ewayLorryReceipt?.quantity;
    tempErrors = {
      ...tempErrors,
      ewayLorryReceipt: {
        ...tempErrors.ewayLorryReceipt,
        quantity:
          fieldValue < minVal || fieldValue > commanValidation.maxQty
            ? 'ERRORS.INVALID.QUANTITY'
            : '',
      },
    };
  } else
    tempErrors = {
      ...tempErrors,
      ewayLorryReceipt: { ...tempErrors.ewayLorryReceipt, quantity: '' },
    };

  tempErrors = {
    ...tempErrors,
    ewayLorryReceipt: {
      ...tempErrors.ewayLorryReceipt,
      transport_name: getExpressionError(
        userInfo.ewayLorryReceipt?.transport_name,
        commonInput,
        'ERRORS.INVALID.NAME',
        ''
      ),
      transport_address: getExpressionError(
        userInfo.ewayLorryReceipt?.transport_address,
        commonAddress,
        'ERRORS.INVALID.ADDRESS',
        ''
      ),
      reference: getExpressionError(
        userInfo.ewayLorryReceipt?.reference,
        commonAddress,
        'ERRORS.INVALID.REFERENCE',
        ''
      ),
    },
    recyclingCertificate: {
      ...tempErrors.recyclingCertificate,
      reference: getExpressionError(
        userInfo.recyclingCertificate?.reference,
        commonAddress,
        'ERRORS.INVALID.REFERENCE',
        ''
      ),
    },
  };

  if (userInfo.debitNote?.any_quanty_return === 'true') {
    let minVal =
      userInfo.debitNote.measurement_types === commanLabel.kgLabel
        ? commanValidation.kgMin
        : commanValidation.tonneMin;
    if (userInfo.debitNote.quantity_return) {
      if (
        userInfo.debitNote.quantity_return < minVal ||
        userInfo.debitNote.quantity_return > commanValidation.maxQty
      ) {
        tempErrors = {
          ...tempErrors,
          debitNote: {
            ...tempErrors.debitNote,
            quantity_return: 'ERRORS.INVALID.QUANTITY',
          },
        };
      } else {
        tempErrors = {
          ...tempErrors,
          debitNote: { ...tempErrors.debitNote, quantity_return: '' },
        };
      }
    } else
      tempErrors = {
        ...tempErrors,
        debitNote: {
          ...tempErrors.debitNote,
          quantity_return: userInfo.isInwardTransfer
            ? ''
            : 'ERRORS.REQUIRED.QUANTITY',
        },
      };
  } else {
    tempErrors = {
      ...tempErrors,
      debitNote: { ...tempErrors.debitNote, quantity_return: '' },
    };
  }

  if (userInfo.goodNote?.quantity) {
    let minVal =
      userInfo.goodNote.measurement_types === commanLabel.kgLabel
        ? commanValidation.kgMin
        : commanValidation.tonneMin;

    const fieldValue = userInfo.goodNote?.quantity;
    tempErrors = {
      ...tempErrors,
      goodNote: {
        ...tempErrors.goodNote,
        quantity:
          fieldValue < minVal || fieldValue > commanValidation.maxQty
            ? 'ERRORS.INVALID.QUANTITY'
            : '',
      },
    };
  } else
    tempErrors = {
      ...tempErrors,
      goodNote: { ...tempErrors.goodNote, quantity: '' },
    };
  tempErrors = {
    ...tempErrors,
    goodNote: {
      ...tempErrors.goodNote,
      reference: getExpressionError(
        userInfo.goodNote?.reference,
        commonAddress,
        'ERRORS.INVALID.REFERENCE',
        ''
      ),
    },
    otherDetails: {
      ...tempErrors.otherDetails,
      po_referece_number_or_link: getExpressionError(
        userInfo.otherDetails?.po_referece_number_or_link,
        commonAddress,
        'ERRORS.INVALID.REFERENCE_LINK',
        ''
      ),
    },
  };

  if (userInfo.nextDetails?.isNext == 'true') {
    const next_details_unloading_quantity_value =
      userInfo.nextDetails?.unloading_quantity;
    tempErrors = {
      ...tempErrors,
      nextDetails: {
        ...tempErrors.nextDetails,
        seller_buyer_name: getExpressionError(
          userInfo.nextDetails?.seller_buyer_name,
          commonInput,
          'ERRORS.INVALID.NAME',
          ''
        ),
        PO_SO_reference_number: getExpressionError(
          userInfo.nextDetails?.PO_SO_reference_number,
          commonAddress,
          'ERRORS.INVALID.PO_REFERENCE_NUMBER',
          ''
        ),
        seller_invoice_reference: getExpressionError(
          userInfo.nextDetails?.seller_invoice_reference,
          commonAddress,
          'ERRORS.INVALID.SELLER_INVOICE',
          ''
        ),
        unloading_quantity: next_details_unloading_quantity_value
          ? next_details_unloading_quantity_value < commanValidation.kgMin ||
            next_details_unloading_quantity_value > commanValidation.maxQty
            ? 'ERRORS.INVALID.QUANTITY'
            : ''
          : '',
        seller_invoice_date: userInfo.nextDetails?.seller_invoice_date
          ? ''
          : '',
      },
    };
  } else {
    tempErrors = {
      ...tempErrors,
      nextDetails: {
        ...tempErrors.nextDetails,
        seller_buyer_name: '',
        PO_SO_reference_number: '',
        seller_invoice_reference: '',
        seller_invoice_date: '',
        waste_type: '',
        unloading_quantity: '',
      },
    };
  }

  if (userInfo.previousDetails?.isPrev == 'true') {
    const unloading_quantity_value =
      userInfo.previousDetails?.unloading_quantity;
    tempErrors = {
      ...tempErrors,
      previousDetails: {
        ...tempErrors.previousDetails,
        seller_buyer_name: getExpressionError(
          userInfo.previousDetails?.seller_buyer_name,
          commonInput,
          'ERRORS.INVALID.NAME',
          ''
        ),
        PO_SO_reference_number: getExpressionError(
          userInfo.previousDetails?.PO_SO_reference_number,
          commonAddress,
          'ERRORS.INVALID.PO_REFERENCE_NUMBER',
          ''
        ),
        seller_invoice_reference: getExpressionError(
          userInfo.previousDetails?.seller_invoice_reference,
          commonAddress,
          'ERRORS.INVALID.SELLER_INVOICE',
          ''
        ),
        seller_invoice_date: userInfo.previousDetails?.seller_invoice_date
          ? ''
          : '',
        unloading_quantity: unloading_quantity_value
          ? unloading_quantity_value < commanValidation.kgMin ||
            unloading_quantity_value > commanValidation.maxQty
            ? 'ERRORS.INVALID.QUANTITY'
            : ''
          : '',
      },
    };
  } else {
    tempErrors = {
      ...tempErrors,
      previousDetails: {
        ...tempErrors.previousDetails,
        seller_buyer_name: '',
        PO_SO_reference_number: '',
        seller_invoice_reference: '',
        seller_invoice_date: '',
        waste_type: '',
        unloading_quantity: '',
      },
    };
  }

  tempErrors = {
    ...tempErrors,
    category: userInfo.category ? '' : 'ERRORS.REQUIRED.CATEGORY',
    utility: userInfo.utility ? '' : 'ERRORS.REQUIRED.UTILITY',
    material_type: userInfo.material_type
      ? ''
      : 'ERRORS.REQUIRED.MATERIAL_TYPE',
    hsn_code: userInfo.hsn_code ? '' : 'ERRORS.REQUIRED.HSN_CODE',
    waste_description: userInfo.waste_description
      ? ''
      : 'ERRORS.REQUIRED.WASTE_DESCRIPTION',
    buyer_category: userInfo.buyer_category
      ? ''
      : 'ERRORS.REQUIRED.BUYER_CATEGORY',
    seller_category: userInfo.seller_category
      ? ''
      : 'ERRORS.REQUIRED.SELLER_CATEGORY',
    date: userInfo.date ? '' : 'ERRORS.REQUIRED.DATE',
    loadingSlipDetails: {
      ...tempErrors.loadingSlipDetails,
      date: userInfo.loadingSlipDetails?.date
        ? ''
        : userInfo.isInwardTransfer
        ? ''
        : 'ERRORS.REQUIRED.DATE',
    },
    unLoadingSlipDetails: {
      ...tempErrors.unLoadingSlipDetails,
      date: userInfo.unLoadingSlipDetails?.date
        ? ''
        : userInfo.isInwardTransfer
        ? ''
        : 'ERRORS.REQUIRED.DATE',
    },
    ewayLorryReceipt: {
      ...tempErrors.ewayLorryReceipt,
      carrier_registration_no: userInfo.ewayLorryReceipt
        ?.carrier_registration_no
        ? ''
        : '',
      date: userInfo.ewayLorryReceipt?.date ? '' : '',
    },
    otherDetails: {
      ...tempErrors.otherDetails,
      buyer_main_address: userInfo.otherDetails?.buyer_main_address
        ? ''
        : 'ERRORS.REQUIRED.BUYER_MAIN_ADDRESS',
      seller_main_address: userInfo.otherDetails?.seller_main_address
        ? ''
        : 'ERRORS.REQUIRED.SELLER_MAIN_ADDRESS',
    },
  };
  let additional_documents_errors = [];
  for (let i = 0; i < userInfo.additionalDocuments.length; i++) {
    let item = userInfo.additionalDocuments[i];
    let errors = {
      reference: getExpressionError(
        item?.reference,
        commonAddress,
        'ERRORS.INVALID.REFERENCE',
        ''
      ),
      label: getExpressionError(
        item?.label,
        commonInput,
        'ERRORS.INVALID.LABEL',
        ''
      ),
      document: '',
    };
    if (item.document && !item.label) {
      errors = { ...errors, label: 'ERRORS.REQUIRED.LABEL' };
    }
    if (!item.document && item.label) {
      errors = { ...errors, document: 'ERRORS.REQUIRED.DOCUMENT' };
    }
    additional_documents_errors.push(errors);
  }
  tempErrors.additionalDocuments = additional_documents_errors;
  return tempErrors;
};
