import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ComponentFrame from './componentFrame';
import ProductDetails from './productDetails';
import RequestedDetails from './requestedDetails';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { toast } from 'react-toastify';
import ConfirmationPopup from '../../../components/confirmationPopup';
import { PCRequestValidator } from './pcRequestValidator';
import { calculateCreditPoint } from '../../../utils/helper/common';

import { useUpdatePCRequestStatusMutation } from '../../../app/api/services/PCRequestManagement.service';
import { plasticCreditRequestStatus } from '../../../shared/remake-green-utils';
import moment from 'moment';
import { getUserType, imagePath } from '../../../constants/comman.constant';
import ActionTakenComponent from './actionTaken';
import TransactionLinked from './transactionLinkedComponent';
import GenerateCertificate from './generateCertificateComponent';
type PCRequestData = {
  panelTitle: string;
  endpoint: string;
  id: string;
  pcStatus: number;
  requestDetails: any;
  pcrType: number;
  isGuestUser: boolean;
};

const PlasticCreditRequestDetails = (props: PCRequestData) => {
  const requestDetails = props?.requestDetails?.pcRequestDetails;
  const address = props?.requestDetails?.address;
  const pcUserProfileDetails = props?.requestDetails?.pcUserProfileDetails;
  const errorJson = {
    reason: '',
  };
  const { t: translate } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();

  const [data, setData] = useState<any>(null);
  const [value, setValue] = useState<any>({
    reason: '',
  });

  const requestDetailsBatch =
    requestDetails?.plastic_credit_request_details_history?.filter(
      (data: any) => {
        return data.link_transaction_certificate_detail.length > 0;
      }
    );
  const [submitPopup, setSubmitPopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [updatePCRequestStatus] = useUpdatePCRequestStatusMutation();
  const [errors, setErrors] = useState<any>(errorJson);
  const [productDetails, setProductDetails] = useState({
    materialSubType: '',
    materialType: '',
    Region: '',
    quantity: 0,
    quantityUnit: '',
    requestDate: '',
    totalCredit: 0,
    currency: '',
  });

  const [generateCerificateDetails, setgenerateCerificateDetails] = useState({
    regionId: null,
    materialTypeId: null,
    categoryTypeId: null,
    quantity: '',
    quantityUnit: '',
    userType: 0,
    userName: '',
    userEmail: '',
    pcrId: '',
    fullfilledQuantity: 0,
  });

  const [actionTakenDetails, setActionTakenDetails] = useState({
    reason: '',
    status: props.pcStatus,
    approvedBy: '',
    date: '',
  });
  const [requestedDetails, setRequestedDetails] = useState({
    requestedBy: '',
    requestDate: '',
    role: '',
    state: '',
    city: '',
    zipCode: '',
    country: '',
    companyContactNumber: '',
    companyEmail: '',
    companyIdNumber: '',
    companyName: '',
    GSTNumber: '',
    billingAddress: '',
    userName: '',
    userEmail: '',
    userContactNumber: '',
    referralCode: '',
    currency: '',
    designation: '',
    userType: 0,
  });
  const updateStatusRequest = async (status: any, data = value) => {
    if (value.reason) {
      const response = await updatePCRequestStatus({
        reason_of_action: value.reason,
        status: status,
        pc_request_id: id,
      }).unwrap();

      if (response.success) {
        toast.success(response.data.message);
        navigate('/admin/plasticCreditRequest', {
          state: {
            pcrType: props.pcrType,
            isGuestUser: props.isGuestUser,
          },
        });
      } else {
        toast.error(response.data.message);
      }
    }
  };

  const validateForm = (individualInfo = value, formSubmit = false) => {
    const errs: any = PCRequestValidator(errors, individualInfo);
    setErrors(errs);
    return Object.keys(errs).every((err) => errs[err].length === 0);
  };

  const getPCDetails = async (id: string) => {
    let path = apiUrl.getPlasticCreditDetailsByID + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setData(res?.data);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    if (requestDetails) {
      setProductDetails({
        materialSubType: requestDetails?.sub_category?.name,
        materialType: requestDetails?.category?.name,
        Region: requestDetails?.region?.name,
        quantity: JSON.parse(requestDetails?.pc_request_details).quantity,
        quantityUnit: JSON.parse(requestDetails?.pc_request_details)
          .quantityUnit,
        requestDate: moment(requestDetails?.created_on).format('DD/MM/YYYY'),
        totalCredit: calculateCreditPoint({
          unit: JSON.parse(requestDetails?.pc_request_details).quantityUnit,
          quantity: JSON.parse(requestDetails?.pc_request_details).quantity,
        }),
        currency: pcUserProfileDetails?.preferred_currency,
      });

      setgenerateCerificateDetails({
        regionId: requestDetails?.region?.id,
        materialTypeId: requestDetails?.category?.id,
        categoryTypeId: requestDetails?.sub_category?.id,
        quantity: JSON.parse(requestDetails?.pc_request_details).quantity,
        quantityUnit: JSON.parse(requestDetails?.pc_request_details)
          .quantityUnit,
        userType: requestDetails?.pc_user?.user_type,
        userName: pcUserProfileDetails?.user_name,
        userEmail: requestDetails?.pc_user?.email,
        pcrId: props.id,
        fullfilledQuantity: requestDetails?.fulfilled_qty
          ? +requestDetails?.fulfilled_qty
          : 0,
      });

      setRequestedDetails({
        requestedBy: pcUserProfileDetails?.user_name,
        requestDate: moment(requestDetails?.created_on).format('DD/MM/YYYY'),
        role: requestDetails?.pc_user
          ? `${getUserType(requestDetails?.pc_user?.user_type)} User`
          : `${getUserType(pcUserProfileDetails?.user?.user_type_id)} User`,
        state: address?.state?.name,
        city: address?.city?.name,
        zipCode: address?.zipCode,
        country: address?.country?.name,
        companyContactNumber: pcUserProfileDetails?.contact_number,
        companyEmail: pcUserProfileDetails?.user?.email,
        companyIdNumber:
          pcUserProfileDetails?.user_company?.company_identification_no,
        companyName: pcUserProfileDetails?.user_company?.company_name,
        GSTNumber: pcUserProfileDetails?.user_company?.gst_vat_no,
        billingAddress: address?.address,
        userName: pcUserProfileDetails?.user_name,
        userEmail: pcUserProfileDetails?.user?.email,
        userContactNumber: pcUserProfileDetails?.contact_number,
        referralCode: pcUserProfileDetails
          ? pcUserProfileDetails?.referral_code
          : requestDetails?.pc_user?.referral_code,
        currency: pcUserProfileDetails?.preferred_currency,
        designation: pcUserProfileDetails?.designation,
        userType: requestDetails.pc_user
          ? requestDetails?.pc_user?.user_type
          : pcUserProfileDetails?.user?.user_type_id,
      });

      setActionTakenDetails({
        reason: requestDetails?.reason_of_action,
        status: props.pcStatus,
        approvedBy:
          requestDetails?.plastic_credit_request_details_history[0]
            .action_by_user?.email,
        date: moment(
          requestDetails?.plastic_credit_request_details_history[0].created_on
        ).format('DD/MM/YYYY'),
      });
      setValue({ reason: requestDetails?.reason_of_action });
    }
  }, [props]);

  useEffect(() => {
    // id && getPCDetails(id);
  }, []);

  return (
    <>
      {submitPopup === true && (
        <ConfirmationPopup
          title={translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.SUBMIT')}
          message={translate(
            'PLASTIC_CREDIT_MANAGEMENT.TITLES.APPROVE_REQUEST_MESSAGE'
          )}
          cancelText={
            translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.CANCEL') || 'Cancel'
          }
          submitText={
            translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.PROCEED') || 'Proceed'
          }
          handleCancel={() => setSubmitPopup(false)}
          handleSubmit={() => {
            updateStatusRequest(plasticCreditRequestStatus.APPROVED);
          }}
        />
      )}

      {rejectPopup === true && (
        <ConfirmationPopup
          title={translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.SUBMIT')}
          message={translate(
            'PLASTIC_CREDIT_MANAGEMENT.TITLES.REJECT_REQUEST_MESSAGE'
          )}
          cancelText={
            translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.CANCEL') || 'Cancel'
          }
          submitText={
            translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.PROCEED') || 'Proceed'
          }
          handleCancel={() => setRejectPopup(false)}
          handleSubmit={() => {
            updateStatusRequest(plasticCreditRequestStatus.REJECTED);
          }}
        />
      )}
      <div className='container'>
        <div className='body-content-main'>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/'>
                    {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.HOME')}
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link
                    to='/admin/plasticCreditRequest'
                    state={{
                      pcrType: props.pcrType,
                      isGuestUser: props.isGuestUser,
                    }}
                  >
                    {translate(
                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_CREDIT_REQUEST_MANAGEMENT'
                    )}
                  </Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  {props.endpoint}
                </li>
              </ol>
            </nav>

            {props.pcStatus === plasticCreditRequestStatus.APPROVED ||
            props.pcStatus === plasticCreditRequestStatus.PARTIAL ? (
              <GenerateCertificate
                details={generateCerificateDetails}
                isGuestUser={props.isGuestUser}
                pcrType={props.pcrType}
                pcStatus={props.pcStatus}
              >
                <ActionTakenComponent data={actionTakenDetails} />
                {props.pcStatus === plasticCreditRequestStatus.PARTIAL && (
                  <TransactionLinked
                    // data={requestDetails?.linked_transaction_detail}
                    batchData={
                      requestDetails?.plastic_credit_request_details_history
                    }
                    pc_request_details={requestDetails?.pc_request_details}
                  />
                )}
                <ProductDetails data={productDetails} />
                <RequestedDetails data={requestedDetails} />
              </GenerateCertificate>
            ) : (
              <ComponentFrame
                creditType={requestDetails?.plastic_credit_type?.name}
                title={props.panelTitle}
                pcrStatus={props.pcStatus}
              >
                {props.pcStatus !== plasticCreditRequestStatus.COMPLETED &&
                  props.pcStatus !== plasticCreditRequestStatus.APPROVED && (
                    <>
                      <div className='action-panel pt-4 ps-4 pe-4 pb-0'>
                        <div className='action-panel-head d-flex justify-content-between'>
                          <div className='left'>
                            {props.pcStatus ===
                            plasticCreditRequestStatus.REQUESTED ? (
                              <h3 className='action-title'>
                                {translate(
                                  'PLASTIC_CREDIT_MANAGEMENT.TITLES.REVIEW_AND_TAKE_ACTION'
                                )}
                              </h3>
                            ) : (
                              <h3 className='action-title'>
                                {translate(
                                  'PLASTIC_CREDIT_MANAGEMENT.TITLES.ACTION_TAKEN'
                                )}
                                :{' '}
                                {props.pcStatus ===
                                  plasticCreditRequestStatus.APPROVED && (
                                  <span style={{ color: '#18A675' }}>
                                    {translate(
                                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.APPROVED'
                                    )}
                                  </span>
                                )}{' '}
                                {props.pcStatus ===
                                  plasticCreditRequestStatus.REJECTED && (
                                  <span style={{ color: '#df4545' }}>
                                    {translate(
                                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.REJECTED'
                                    )}
                                  </span>
                                )}
                                {props.pcStatus ===
                                  plasticCreditRequestStatus.CLOSED && (
                                  <span style={{ color: '#df4545' }}>
                                    {translate(
                                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.CLOSED'
                                    )}
                                  </span>
                                )}
                              </h3>
                            )}
                          </div>

                          <div className='right'>
                            {props.pcStatus ===
                            plasticCreditRequestStatus.REQUESTED ? (
                              <div className='group-details'>
                                <a
                                  className='btn btn-img-icon'
                                  onClick={() => {
                                    if (validateForm(value, true)) {
                                      setSubmitPopup(true);
                                    }
                                  }}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/check-button-icon.svg`}
                                    alt='img1'
                                  />
                                  {translate('PROFILE.TITLES.APPROVE')}{' '}
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                  className='btn btn-img-icon red_button'
                                  onClick={() => {
                                    if (validateForm(value, true)) {
                                      setRejectPopup(true);
                                    }
                                  }}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/close-circle-svgrepo-com.svg`}
                                    alt='img1'
                                  />{' '}
                                  {translate('PROFILE.TITLES.REJECT')}
                                </a>
                              </div>
                            ) : (
                              <div>
                                {props.pcStatus ===
                                  plasticCreditRequestStatus.REJECTED && (
                                  <>
                                    <span>
                                      {translate(
                                        'PLASTIC_CREDIT_MANAGEMENT.TITLES.REJECTED_BY'
                                      )}{' '}
                                      : &nbsp;
                                      {
                                        requestDetails
                                          ?.plastic_credit_request_details_history[0]
                                          .action_by_user.email
                                      }
                                    </span>
                                    <span className='ms-4'>
                                      {translate(
                                        'PLASTIC_CREDIT_MANAGEMENT.TITLES.REJECTED_ON'
                                      )}{' '}
                                      :{' '}
                                      {moment(
                                        requestDetails
                                          ?.plastic_credit_request_details_history[0]
                                          .created_on
                                      ).format('DD/MM/YYYY')}
                                    </span>
                                  </>
                                )}
                                {props.pcStatus ===
                                  plasticCreditRequestStatus.APPROVED && (
                                  <>
                                    <span>
                                      {translate(
                                        'PLASTIC_CREDIT_MANAGEMENT.TITLES.APPROVED_BY'
                                      )}{' '}
                                      :
                                      {
                                        requestDetails
                                          ?.plastic_credit_request_details_history[0]
                                          .action_by_user.email
                                      }
                                    </span>
                                    <span className='ms-4'>
                                      {translate(
                                        'PLASTIC_CREDIT_MANAGEMENT.TITLES.APPROVED_ON'
                                      )}{' '}
                                      :
                                      {moment(
                                        requestDetails
                                          ?.plastic_credit_request_details_history[0]
                                          .created_on
                                      ).format('DD/MM/YYYY')}
                                    </span>
                                  </>
                                )}
                              </div>
                            )}
                            {/* </div> */}
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      <div className='panel-data'>
                        {props.pcStatus !== plasticCreditRequestStatus.CLOSED &&
                          (props.pcStatus ==
                            plasticCreditRequestStatus.REQUESTED ||
                            (props.pcStatus !==
                              plasticCreditRequestStatus.REQUESTED &&
                              value.reason)) && (
                            <div className='form-group required input-textarea-border'>
                              <label className='form-label label-info'>
                                {translate(
                                  'PLASTIC_CREDIT_MANAGEMENT.TITLES.REASON_OF_ACTION'
                                )}
                              </label>

                              <textarea
                                className='form-control'
                                rows={4}
                                name='reason'
                                value={value.reason}
                                disabled={
                                  props.pcStatus !==
                                  plasticCreditRequestStatus.REQUESTED
                                }
                                onChange={(e) =>
                                  setValue({ reason: e.target.value })
                                }
                                maxLength={commanValidation.maxLengthReason}
                              ></textarea>

                              {
                                <span className='error'>
                                  {translate(errors.reason)}
                                </span>
                              }
                            </div>
                          )}
                      </div>
                    </>
                  )}

                {/* {props.pcStatus === plasticCreditRequestStatus.COMPLETED && (
                  <div className='panel-data'>
                    <div className='row'>
                      <div className='certificate-item'>
                        <ul>
                          {props.requestDetails.linked_transaction_detail
                            .length > 0 &&
                            props.requestDetails.linked_transaction_detail.map(
                              (u: any, i: number) => {
                                return (
                                  <li>
                                    <Link
                                      target='_blank'
                                      to={`/admin/transaction/plasticCreditCertification/${window.btoa(
                                        u.transaction_id
                                      )}`}
                                    >
                                      {translate(
                                        'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_CREDIT_CERTIFICATE'
                                      )}{' '}
                                      {i + 1}
                                      <img
                                        src={imagePath.sharePath}
                                        alt='share'
                                      />
                                    </Link>
                                  </li>
                                );
                              }
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )} */}
                {(props.pcStatus === plasticCreditRequestStatus.COMPLETED ||
                  props.pcStatus === plasticCreditRequestStatus.CLOSED) &&
                  requestDetailsBatch.length > 0 && (
                    <TransactionLinked
                      // data={requestDetails?.linked_transaction_detail}
                      batchData={
                        requestDetails?.plastic_credit_request_details_history
                      }
                      pc_request_details={requestDetails?.pc_request_details}
                    />
                  )}
                {/* </div> */}
                {(props.pcStatus === plasticCreditRequestStatus.COMPLETED ||
                  props.pcStatus === plasticCreditRequestStatus.APPROVED) && (
                  <ActionTakenComponent data={actionTakenDetails} />
                )}

                <ProductDetails data={productDetails} />
                <RequestedDetails data={requestedDetails} />
              </ComponentFrame>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlasticCreditRequestDetails;
