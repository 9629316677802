import React, { HTMLProps, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { commanLabel, routesConstant } from '../../../constants/comman.constant';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import Pagination from 'react-bootstrap/Pagination';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { certificateCategories } from '../../../shared/remake-green-utils/common';
import { validationMessage } from '../../../constants/validation.message.constant';
import ExportPopup from '../socialWelfare/exportPopup';
import { useLinkedExportMutation } from '../../../app/api/services/createTransaction.service';
import { userType } from '../../../constants/user.constant';
import { RootState } from '../../../app/types/store';
import { store } from '../../../app/store';

export const TransactionLinkedList = () => {
  const { t: translate } = useTranslation();
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [creditTypeId, setCreditTypeId] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const columnHelper = createColumnHelper<any>();
  const [rowSelection, setRowSelection] = React.useState({});
  const [exportPopup, setExportPopup] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [exportAsExcel] = useLinkedExportMutation();
  const navigate = useNavigate();
  const state: RootState = store.getState();
  const columns = [
    columnHelper.accessor('-', {
      id: 'select',
      enableSorting: false,

      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className='px-1'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor('-', {
      header: () => translate('') + '',
      enableSorting: false,
      cell: (props: any) => {
        const id = props.row.original.id;

        return (
          <React.Fragment>
            {/* {props.row.original.csr_activity.length > 0 && ( */}
            <a
              href='javascript:void(0);'
              className='description-btn'
              id={`desc-btn-${id}`}
              onClick={() => {
                let data = document.getElementById(`desc-content-${id}`);
                if (data?.classList.contains('active')) {
                  data.classList.remove('active');
                } else {
                  data && data?.classList?.add('active');
                }
                let btnData = document.getElementById(`desc-btn-${id}`);
                if (btnData?.classList.contains('btn-active')) {
                  btnData.classList.remove('btn-active');
                } else {
                  btnData && btnData?.classList?.add('btn-active');
                }
                let rowActiveData = document.getElementById(
                  `transaction-row-${id}`
                );
                if (rowActiveData?.classList.contains('active-row')) {
                  rowActiveData.classList.remove('active-row');
                } else {
                  rowActiveData && rowActiveData?.classList?.add('active-row');
                }
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/down-arrow-svg.svg`}
              />
            </a>
            {/* )} */}
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor('transaction_id', {
      header: () => 'ID',
    }),
    columnHelper.accessor((row) => `${row?.seller && row?.seller?.name}`, {
      id: 'Seller',
    }),
    columnHelper.accessor((row) => `${row?.buyer && row?.buyer?.name}`, {
      id: 'Buyer',
    }),
    columnHelper.accessor(
      (row) => `${row?.material_type && row?.material_type?.name}`,
      {
        id: 'Material Type',
      }
    ),

    columnHelper.accessor(
      (row) =>
        `${
          row?.associated_project && row?.associated_project?.name
            ? row?.associated_project && row?.associated_project?.name
            : 'Undefined'
        }`,
      {
        id: `${translate(
          'TRANSACTION_REGISTRY_PAGE.TITLES.ASSOCIATED_PROJECT'
        )}`,
      }
    ),
    columnHelper.accessor('plastic_credit_type.name', {
      header: () => 'Credit Type',
    }),
    columnHelper.accessor(
      (row) => `${row.unloaded_quantity} ${row.unloaded_quantity_unit}`,
      {
        id: 'Unloaded Qty.',
      }
    ),
    columnHelper.accessor((row) => `${row.remaining_quantity || 0} Kg`, {
      id: 'Remaining Qty.',
    }),
    columnHelper.accessor('-', {
      header: () => 'PC Generated',
      enableSorting: false,
      cell: (props: any) => {
        let isGenerated = props?.row?.original?.is_pc_generated;
        return (
          <React.Fragment>
            <div className='icon-status'>
              {isGenerated ? (
                <div className='icon-view icon'>
                  <Link to={'#'} title='Completed'>
                    <i className='fa fa-check' aria-hidden='true'></i>
                  </Link>
                </div>
              ) : (
                <div className='icon-view icon'>
                  <Link to={'#'} title='Awaiting'>
                    {' '}
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/pending-status.png`}
                      alt='#'
                    />
                  </Link>
                </div>
              )}
            </div>
          </React.Fragment>
        );
      },
    }),
    columnHelper.accessor(
      (row: any) => `${moment(row.created_on).format(' DD/MM/YYYY hh:mm A ')}`,
      {
        id: 'Date & Time of Linked Transaction',
      }
    ),
  ];

  const getData = async (reset: boolean) => {
    setIsLoading(true);
    const parms: any = {
      page: reset ? '' : page,
      limit: pageLimit,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),
      creditType: reset ? '' : creditTypeId,
    };
    if(userType.VALUE_CHAIN_PARTNER == state.auth?.user?.userTypeId) {
      parms['partnerId'] = state.auth?.user?.value_chain_id;
    }
    let path = apiUrl.getTrasactionLinkedList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setTransactionData(res.data.allTransaction);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  const getPagination = () => {
    let items = [];
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item
          activeLabel=''
          key={number}
          active={number === page}
          onClick={(e) => {
            e.preventDefault();
            setPage(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  useEffect(() => {
    getData(false);
  }, [page, pageLimit]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: transactionData,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    debugTable: true,
  });

  const handeleReset = () => {
    setCreditTypeId('');
    setPage(1);
    setDateRange([null, null]);
    getData(true);
  };
  function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type='checkbox'
        ref={ref}
        className={className + ' cursor-pointer'}
        {...rest}
      />
    );
  }

  const getExportedExcel = async () => {
    const rowKeys = Object.keys(rowSelection);
    const actKeys: any = [];
    rowKeys.forEach((aa: string) => {
      if (rowSelection[aa as keyof typeof rowSelection]) {
        const actRow: any = transactionData[aa as keyof typeof rowSelection];
        actKeys.push(actRow?.id);
      }
    });
    const res = await exportAsExcel({
      ids: actKeys,
    }).unwrap();
    if (res.success) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
        res.data?.file;
      a.download = res.data.name;
      a.click();
    }
  };
  const handleClose = () => {
    setExportPopup(false);
  };
  return (
    <React.Fragment>
      <div className='tab-content'>
        <div
          className='tab-pane fade show active'
          id='registry2'
          role='tabpanel'
          aria-labelledby='registry2-tab'
        >
          <div className='listing-transaction'>
            <div className='top-filters linktr-set'>
              {/* <div className="left-data"> */}
              <div className='date-item-picker item-in'>
                <div className='date-picker-main'>
                  <ReactDatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    className='form-control'
                    placeholderText='Select Date Range'
                    dateFormat='dd/MM/yyyy'
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    onChange={(update: any) => {
                      setDateRange(update);
                    }}
                  />
                </div>
              </div>
              <div className='type-select item-in'>
                <select
                  className='select-layout'
                  name='creditTypeId'
                  value={creditTypeId}
                  onChange={(e) => {
                    setCreditTypeId(e.target.value);
                  }}
                >
                  <option value={''}>Select Credit Type</option>
                  {certificateCategories &&
                    _.map(certificateCategories, (value: any, key: any) => {
                      return (
                        <option key={key} value={key}>
                          {value.title}
                        </option>
                      );
                    })}
                </select>
              </div>
              {/* </div> */}

              <div className='right-data item-in'>
                <div className='search-button'>
                  <Button
                    className='btn  btn-sm btn-outline-primary btn-icon'
                    type='button'
                    title='Search'
                    onClick={(e) => {
                      e.preventDefault();
                      getData(false);
                    }}
                  >
                    <i className='fa fa-search' aria-hidden='true'></i>
                  </Button>
                  <Button
                    className='btn  btn-sm btn-primary btn-icon'
                    type='button'
                    title='Reset'
                    onClick={(e) => {
                      e.preventDefault();
                      handeleReset();
                    }}
                  >
                    <i className='fa fa-refresh' aria-hidden='true'></i>
                  </Button>

                  <div
                    className='btn  btn-sm btn-outline-primary btn-icon'
                    onClick={(e) => {
                      e.preventDefault();
                      if (Object.keys(rowSelection).length !== 0) {
                        setOpen(true);
                      } else {
                        toast.error(
                          translate('ERRORS.INVALID.INVALID_SELECTION')
                        );
                      }
                    }}
                  >
                    <i className='fa fa-file-export' aria-hidden='true'></i>
                  </div>
                  <Button
                    className='btn  btn-sm btn-primary btn-icon'
                    type='button'
                    title='Upload Document'
                    onClick={(e) => {
                      navigate('/admin/transaction/upload-documents');
                    }}
                  >
                    <i className='fa fa-upload' aria-hidden='true'></i>
                  </Button>
                </div>
              </div>
            </div>
            <div className={`listing-content`}>
              <div className='table-list'>
                <table>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup: any) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header: any) => {
                          return (
                            <th key={header.id} colSpan={header.colSpan}>
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none'
                                      : '',
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ??
                                    null}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  {transactionData.length > 0 ? (
                    <tbody>
                      {table.getRowModel().rows.map((row: any) => {
                        return (
                          <>
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell: any) => {
                                return (
                                  <td key={cell.id}>
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                            {
                              <tr
                                id={`desc-content-${row.original.id}`}
                                className='description-row'
                              >
                                {/* <>
                                  <td colSpan={10} className='description-td'>
                                    <div
                                      className='description-content'
                                      style={{
                                        height: document
                                          .getElementById(
                                            `desc-content-${row.original.id}`
                                          )
                                          ?.classList.contains('active')
                                          ? '250px'
                                          : '',
                                      }}
                                    >
                                      <div className='description-content-inner'>
                                        <div
                                          className='main-desc-area'
                                          style={{
                                            marginBottom: '20px',
                                          }}
                                        >
                                          <div className='main-table-section'>
                                            <div className='table-certificate'>
                                              <div className='listing-content'>
                                                <div className='table-list'>
                                                  <table>
                                                    <thead>
                                                      <tr>
                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.TRANSACTION_ID'
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_WASTE_TYPE'
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.QTY'
                                                            )}
                                                          </div>
                                                        </td>

                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.SELLER'
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.BUYER'
                                                            )}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {row?.original?.transaction_registery.map(
                                                        (
                                                          data: any,
                                                          index: number
                                                        ) => {
                                                          return (
                                                            <tr>
                                                              <td>
                                                                {
                                                                  data?.transaction_id
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  row?.original
                                                                    ?.material_type
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  data?.details
                                                                    ?.unloading_weight_slip_details
                                                                    ?.quantity
                                                                }
                                                                &nbsp;
                                                                {
                                                                  data?.details
                                                                    ?.unloading_weight_slip_details
                                                                    ?.measurement_types
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  data?.seller
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  data?.buyer
                                                                    ?.name
                                                                }
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </> */}

                                <>
                                  <td colSpan={12} className='description-td'>
                                    <div
                                      className='description-content'
                                      style={{
                                        height: document
                                          .getElementById(
                                            `desc-content-${row.original.id}`
                                          )
                                          ?.classList.contains('active')
                                          ? row.original.csr_activity.length > 0
                                            ? '350px'
                                            : '200px'
                                          : '',
                                      }}
                                    >
                                      <div className='description-content-inner'>
                                        <div
                                          className='main-desc-area'
                                          style={{
                                            marginBottom: '20px',
                                          }}
                                        >
                                          <div className='title-area'>
                                            <div
                                              className='col-lg-8'
                                              style={{ textAlign: 'left' }}
                                            >
                                              <h5>Transaction Details </h5>
                                            </div>
                                          </div>
                                          <div className='main-table-section'>
                                            <div className='table-certificate'>
                                              <div className='listing-content'>
                                                <div className='table-list'>
                                                  <table>
                                                    <thead>
                                                      <tr>
                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.TRANSACTION_ID'
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_WASTE_TYPE'
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_SUB_TYPE'
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.QTY'
                                                            )}
                                                          </div>
                                                        </td>

                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.SELLER'
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.BUYER'
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='title'>
                                                            Invoice Date
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='title'>
                                                            Verified
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className='title'>
                                                            {translate(
                                                              'TRANSACTION_REGISTRY_PAGE.TITLES.LOT_BATCH_PREFERENCE'
                                                            )}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {row?.original?.transaction_registery.map(
                                                        (
                                                          data: any,
                                                          index: number
                                                        ) => {
                                                          return (
                                                            <tr
                                                              style={{
                                                                textAlign:
                                                                  'center',
                                                              }}
                                                            >
                                                              <td>
                                                                {
                                                                  data?.transaction_id
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  row?.original
                                                                    ?.material_type
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  data
                                                                    ?.material_name_type_data
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  data?.details
                                                                    ?.unloading_weight_slip_details
                                                                    ?.quantity
                                                                }
                                                                &nbsp;
                                                                {
                                                                  data?.details
                                                                    ?.unloading_weight_slip_details
                                                                    ?.measurement_types
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  data?.seller
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  data?.buyer
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {moment(
                                                                  data?.details
                                                                    ?.buyer_and_seller_details
                                                                    ?.date
                                                                    ? data
                                                                        ?.details
                                                                        ?.buyer_and_seller_details
                                                                        ?.date
                                                                    : '-'
                                                                ).format(
                                                                  'DD/MM/YYYY'
                                                                )}
                                                              </td>
                                                              <td>
                                                                <div className='icon-status'>
                                                                  {data?.is_verified ? (
                                                                    <div className='icon-view icon'>
                                                                      <Link
                                                                        to={'#'}
                                                                        title='Completed'
                                                                      >
                                                                        <i
                                                                          className='fa fa-check'
                                                                          aria-hidden='true'
                                                                        ></i>
                                                                      </Link>
                                                                    </div>
                                                                  ) : (
                                                                    <div className='icon-view icon'>
                                                                      <Link
                                                                        to={`/admin/transaction/update-transaction-registry/${data?.id}`}
                                                                        title='Verify/Update'
                                                                      >
                                                                        {' '}
                                                                        <img
                                                                          src={`${process.env.PUBLIC_URL}/assets/images/pending-status.png`}
                                                                          alt='#'
                                                                        />
                                                                      </Link>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td>
                                                                {data?.details
                                                                  ?.basic_details
                                                                  ?.lot_batch_preference ||
                                                                  '-'}
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {row?.original?.csr_activity.length >
                                          0 && (
                                          <div
                                            className='main-desc-area'
                                            style={{
                                              marginBottom: '20px',
                                            }}
                                          >
                                            <div className='title-area'>
                                              <div
                                                className='col-lg-8'
                                                style={{ textAlign: 'left' }}
                                              >
                                                <h5>CSR Activity Details </h5>
                                              </div>
                                            </div>
                                            <div className='main-table-section'>
                                              <div className='table-certificate'>
                                                <div className='listing-content'>
                                                  <div className='table-list'>
                                                    <table>
                                                      <thead>
                                                        <tr>
                                                          <td>
                                                            <div className='title'>
                                                              {translate(
                                                                'CSR_MODULE.TITLES.ACTIVITY_ID'
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className='title'>
                                                              {translate(
                                                                'CSR_MODULE.TITLES.EVENT_ID'
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className='title'>
                                                              {translate(
                                                                'CSR_MODULE.TITLES.EVENT_NAME'
                                                              )}
                                                            </div>
                                                          </td>

                                                          <td>
                                                            <div className='title'>
                                                              {translate(
                                                                'CSR_MODULE.TITLES.RECLAIMER_ID'
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className='title'>
                                                              {translate(
                                                                'CSR_MODULE.TITLES.RECLAIMER_NAME'
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className='title'>
                                                              {translate(
                                                                'CSR_MODULE.TITLES.ACTIVITY_DATE'
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className='title'>
                                                              {translate(
                                                                'CSR_MODULE.TITLES.ORGANISER'
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className='title'>
                                                              {translate(
                                                                'CSR_MODULE.TITLES.MATERIAL_PROVIDED'
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div className='title'>
                                                              {translate(
                                                                'CSR_MODULE.TITLES.ACTIVITY_PHOTO'
                                                              )}
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {row?.original?.csr_activity.map(
                                                          (
                                                            data: any,
                                                            index: number
                                                          ) => {
                                                            return (
                                                              <tr
                                                                style={{
                                                                  textAlign:
                                                                    'center',
                                                                }}
                                                              >
                                                                <td>
                                                                  {
                                                                    data?.activity_id
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    data
                                                                      ?.csr_event
                                                                      ?.event_id
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    data
                                                                      ?.csr_event
                                                                      ?.name
                                                                  }
                                                                </td>

                                                                <td>
                                                                  {
                                                                    data
                                                                      ?.csr_reclaimer
                                                                      ?.reclaimer_id
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    data
                                                                      ?.csr_reclaimer
                                                                      ?.name
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {moment(
                                                                    data?.activity_date
                                                                  ).format(
                                                                    'DD/MM/YYYY'
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {
                                                                    data
                                                                      ?.csr_event
                                                                      ?.organizer
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {data?.csr_material?.map(
                                                                    (
                                                                      data: any,
                                                                      i: any
                                                                    ) => {
                                                                      return (
                                                                        <span>
                                                                          {
                                                                            data.name
                                                                          }
                                                                          {i +
                                                                            1 <
                                                                            data
                                                                              ?.csr_material
                                                                              ?.length &&
                                                                            ', '}
                                                                        </span>
                                                                      );
                                                                    }
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {data?.activity_photo && (
                                                                    <div className='icon-action'>
                                                                      <img
                                                                        style={{
                                                                          cursor:
                                                                            'pointer',
                                                                        }}
                                                                        src={`${process.env.PUBLIC_URL}/assets/images/eye-icon-green.svg`}
                                                                        alt='#'
                                                                        onClick={() =>
                                                                          window.open(
                                                                            data?.activity_photo
                                                                          )
                                                                        }
                                                                      />
                                                                    </div>
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {/* {row?.original?.csr_activity.length > 0 && (
                                      <div
                                        className='description-content'
                                        style={{
                                          height: document
                                            .getElementById(
                                              `desc-content-${row.original.id}`
                                            )
                                            ?.classList.contains('active')
                                            ? '250px'
                                            : '',
                                        }}
                                      >
                                        <div className='description-content-inner'>
                                          
                                        </div>
                                      </div>
                                    )} */}
                                  </td>
                                </>
                              </tr>
                            }
                          </>
                        );
                      })}
                    </tbody>
                  ) : (
                    <>
                      <tbody>
                        <tr>
                          <td colSpan={11}>
                            {isLoading && (
                              <h3 className='center-message-item'>
                                Loading...
                              </h3>
                            )}
                            {transactionData.length === 0 && !isLoading && (
                              <h3 className='center-message-item'>
                                {
                                  validationMessage.noRecordFoundLinkedTransaction
                                }
                              </h3>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}
                </table>

                <div className='h-2 mt-2' />
              </div>
            </div>
            {transactionData.length > 0 && pageCount > 1 && (
              <div className='items-center pagination-main gap-2 text-center'>
                <button
                  className='border rounded p-1'
                  onClick={() => {
                    table.previousPage();
                    setPage(page - 1);
                  }}
                  disabled={!table.getCanPreviousPage()}
                >
                  {'<'}
                </button>
                <Pagination size='sm'>{getPagination()}</Pagination>
                <button
                  className='border rounded p-1'
                  onClick={() => {
                    table.nextPage();
                    setPage(page + 1);
                  }}
                  disabled={!table.getCanNextPage()}
                >
                  {'>'}
                </button>
                {/* <span className="flex items-center gap-1">
                  <div>Page</div>
                  <strong>
                    {table.getState().pagination.pageIndex + 1} of{' '}
                    {table.getPageCount()}
                  </strong>
                </span>
                <span className="flex items-center gap-1">
                  | Go to page:
                  <input
                    type="number"
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      table.setPageIndex(page)
                    }}
                    className="border p-1 rounded w-16"
                  />
                </span>
                <select
                  value={table.getState().pagination.pageSize}
                  onChange={e => {
                    table.setPageSize(Number(e.target.value))
                    setPageLimit(Number(e.target.value))
                  }}
                >
                  {[20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select> */}
              </div>
            )}
          </div>
        </div>
        {open && (
          <ExportPopup
            title={`${translate('CSR_MODULE.TITLES.EXPORT')}`}
            handleCancel={() => setOpen(false)}
            cancelText='Cancel'
            submitText='OK'
            hideCancelButton={true}
            hidePdf={true}
            handleSubmit={(e: any, addBlur: any) => {
              setExportPopup(false);
              setOpen(false);
              if (e === 'excel') {
                getExportedExcel();
              }
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};
