import React, { useState, useEffect } from "react";
import RemakeUserList from "./remakeUserList";
import ValueChainPartnerList from "./valuechainPartnerList";
import { useTranslation } from "react-i18next";

const UserManagement = () => {
  const { t: translate } = useTranslation();

  const [selectedTableData, setSelectedTableData] = useState(false);

  return (
    <div className="container">
      <div className="body-content-main user-management">
        <div className="container">
          <nav aria-label="breadcrumb" className="breadcrumb-main">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">{translate("USER_MANAGEMENT.TITLES.HOME")}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {translate("USER_MANAGEMENT.TITLES.USER_MANAGEMENT")}
              </li>
            </ol>
          </nav>

          <div className="section-title-main">
            <div className="title">
              {translate("USER_MANAGEMENT.TITLES.USER_MANAGEMENT")}
            </div>
          </div>

          <div className="transaction-table listuser-table">
            <div className="panel-head-title">
              <div className="left-content">
                <div className="title-normal">
                  {translate("USER_MANAGEMENT.TITLES.USER_MANAGEMENT_TEXT")}
                </div>
              </div>

              <div className="right-content">
                <div className="toggle-button big">
                  <div className="form-group-switch checked">
                    <span className={!selectedTableData ? "right" : "left"}>
                      {translate("USER_MANAGEMENT.TITLES.REMAKE_USER")}
                    </span>
                    <label className="switch ">
                      <input
                        type="checkbox"
                        checked={selectedTableData}
                        onChange={(e) => setSelectedTableData(e.target.checked)}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className={selectedTableData ? "right" : "left"}>
                      {translate("USER_MANAGEMENT.TITLES.VALUE_CHAIN_PARTNER")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {selectedTableData ? <ValueChainPartnerList /> : <RemakeUserList />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
