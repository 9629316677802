import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { helper } from "../../../../app/api/api.helper";
import { apiUrl } from "../../../../app/api/services/apiUrl";
import { commanLabel } from "../../../../constants/comman.constant";
import { getFileFormat, getImage } from "../../../../utils/files/commanFilefunction";

export const RemakeCertificates = (prop:any) => {
    const [isCertifications, setIsCertifications] = useState(false)
    const [page] = useState(commanLabel.page)
    const [pageLimit] = useState(commanLabel.pageLimit)
    const [remakeCertificateType] = useState(commanLabel.remakeCertificateType)
    const [remakeData, setRemakeData] = useState([]);
    const cusrRemake = [
        {
            "id": 615,
            "created_on": "2023-07-18T08:32:36.926Z",
            "document_path": "https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1691762490875-Gemini-corporation-nv-1.1.jpg",
            "document_name": "Gemini-ISO-9001-2015.jpg",
            "document_type": "image/jpeg"
        },
        {
            "id": 614,
            "created_on": "2023-07-18T08:31:37.333Z",
            "document_path": "https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1691762275880-GEMINI-CORPORATION-NV-1.14k.jpg",
            "document_name": "Gemini-ISO-14001-2015.jpg",
            "document_type": "image/jpeg"
        },
        {
            "id": 5,
            "created_on": "2022-11-04T07:05:15.585Z",
            "document_path": "https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1691762760487-Gemini - niwo_page-0001.jpg",
            "document_name": "Gemini-NIWO-Registration.png",
            "document_type": "image/jpeg"
        },
        {
            "id": 3,
            "created_on": "2022-11-04T07:05:15.585Z",
            "document_path": "https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1691762881975-OVAM.png",
            "document_name": "Gemini-OVAM-Registration.png",
            "document_type": "image/png"
        },
        {
            "id": 4,
            "created_on": "2022-11-04T07:05:15.585Z",
            "document_path": "https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1691763075449-Gemini-Dun & Bradstreet-Rating-1.jpg",
            "document_name": "Gemini-Dun & Bradstreet-Rating.jpg",
            "document_type": "image/jpeg"
        },
        {
            "id": 647,
            "created_on": "2022-11-04T07:05:15.585Z",
            "document_path": "https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1693208737881-Metplast-Trading License.jpg",
            "document_name": "Metplast-Trading License.jpg",
            "document_type": "image/jpeg"
        },
        {
            "id": 648,
            "created_on": "2022-11-04T07:05:15.585Z",
            "document_path": "https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1693208861175-Great Granules-Import Permission.jpg",
            "document_name": "Great Granules-Import Permission.jpg",
            "document_type": "image/jpeg"
        },
        {
            "id": 4,
            "created_on": "2022-11-04T07:05:15.585Z",
            "document_path": "https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1693208931467-Great Granules-Consent to Operate.jpg",
            "document_name": "Great-Granules-Consent to Operate.jpg",
            "document_type": "image/jpeg"
        },
        {
            "id": 650,
            "created_on": "2022-11-04T07:05:15.585Z",
            "document_path": "https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1693478786453-I.T.B. - NIWO VIHB number.jpg",
            "document_name": "I.T.B. - NIWO VIHB number.jpg",
            "document_type": "image/jpeg"
        },
        {
            "id": 651,
            "created_on": "2022-11-04T07:05:15.585Z",
            "document_path": "https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1693478893396-I.T.B. - Ovam Registration.jpg",
            "document_name": "I.T.B. - Ovam Registration.jpg",
            "document_type": "image/jpeg"
        }
       
    ]
    const getData = async () => {
        const parms = {
            page: page,
            limit: pageLimit,
            owner_type: remakeCertificateType,
        }
        let path = apiUrl.getRemakeCertificate;
        const fr = await helper.getData(path, parms);
        const res = await fr.response;
        if (res.success) {
            setRemakeData(res.data.files);
        } else {
            toast.error(res.message)
        }
    }

    useEffect(() => {
        console.log(prop.id)
        getData()
    }, [])
  

    return (
        <React.Fragment>
            {remakeData.length > 0 && <div className={`product-details ${isCertifications ? 'toggle' : ''}`} >
                <h3 className="product-title">
                    <div className="title-data">Certifications and Standards</div>
                    <i className="fa-solid fa-circle-chevron-up toggle-data" onClick={(e) => { setIsCertifications(!isCertifications) }} />
                </h3>
                <div className="table-wrap" style={{ display: isCertifications ? 'none' : 'block' }}>
                    {/* <table cellSpacing={0} border={1}>
                        
                        <tbody>
                            <tr>
                                
                                <td>
                                    <div className="certificates">
                                        <ul>{remakeData.length > 0 && remakeData.map((item: any, key) => {
                                            return (
                                                <li key={key}>
                                                    <a href={item?.document_path} target="_blank" rel="noopener noreferrer" >
                                                        <div className="img-item">
                                                            <img src={getFileFormat(item?.document_type) === "PDF" ?
                                                                getImage(item?.document_name) : item?.document_path} alt={""} />
                                                        </div>
                                                        <div className="subtitle filename-tooltip" title={item?.document_name}>[ {item?.document_name} ]</div>
                                                        {item?.document_name === 'OBP.CERT-REC-Gemcorp Recycling and Technologies Private Limited-2022.pdf' &&
                                                            <div className="subtitle filename-tooltip" title={item?.document_name}>[OBP Recycling]</div>
                                                        }
                                                        {item?.document_name === 'OBP.CERT-COL-Gemcorp Recycling and Technologies Private Limited-2022.pdf' &&
                                                            <div className="subtitle filename-tooltip" title={item?.document_name}>[OBP Collection]</div>
                                                        }
                                                    </a>
                                                </li>
                                            )
                                        })}
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}

                </div>
                <div className="certificate-box">
                    {prop.id !== 'TE5LMDAwMDc5' &&
                <div className="row mb-3">{remakeData.length > 0 && remakeData.map((item: any, key) => {
                                            return (
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-2 mb-3">
                                                <a href={item?.document_path} target="_blank" rel="noopener noreferrer" >
                                                        <div className="img-item">
                                                            <img  src={getFileFormat(item?.document_type) === "PDF" ?
                                                                getImage(item?.document_name) : item?.document_path} alt={""} />
                                                        </div>
                                                        <div className="subtitle filename-tooltip mt-2 text-center" title={item?.document_name}>[ {item?.document_name} ]</div>
                                                        
                                                    </a>
                                                    </div>
                                            )
                                        })}
                    
                    </div>
                        }
                        { prop.id ==='TE5LMDAwMDc5' &&
                        <div className="row mb-3">{cusrRemake.length > 0 && cusrRemake.map((item: any, key) => {
                            return (
                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-2 mb-3">
                                <a href={item?.document_path} target="_blank" rel="noopener noreferrer" >
                                        <div className="img-item">
                                            <img  src={getFileFormat(item?.document_type) === "PDF" ?
                                                getImage(item?.document_name) : item?.document_path} alt={""} />
                                        </div>
                                        <div className="subtitle filename-tooltip mt-2 text-center" title={item?.document_name}>[ {item?.document_name} ]</div>
                                        
                                    </a>
                                    </div>
                            )
                        })}
    
    </div>

                        }
                </div>
                                    

            </div>}
        </React.Fragment>
    );
};
