import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import {
  commanLabel,
  privacyPDF,
  termsPDF,
  routesConstant,
  imagePath,
} from '../../../constants/comman.constant';
import { validationMessage } from '../../../constants/validation.message.constant';
import { isEmptyJSON } from '../../../utils/helper/common';
import { userRoleValidator } from './userRoleValidator';
import { errorJson, userDetails, UserRoleProps } from './userRoleProps';
import ConfirmationPopup from '../../../components/confirmationPopup';
import { commanValidation } from '../../../constants/comman.validation.constant';
import {
  UserProfileStatus,
  UserType,
} from '../../../shared/remake-green-utils/common/constants';
import { useRegisterMutation } from '../../../app/api/services/authentication.service';
import { useTranslation } from 'react-i18next';
import {
  getFileFormat,
  getFormatBytes,
} from '../../../utils/files/commanFilefunction';
import { useAppDispatch } from '../../../app/hooks';
import { authLogout } from '../../../app/store/slices/auth.slice';
import { store } from '../../../app/store';
import { isValidFile } from '../../../shared/remake-green-utils';
import { useDeleteFileMutation } from "../../../app/api/services/commonAPI";
export const UserRole = (props: UserRoleProps) => {
  const tempDocument: any[] = [];
  const { isRole } = props;
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const state = store.getState();
  const user_id = state?.auth?.user?.id;
  const [validated] = useState(false);
  const [register] = useRegisterMutation();
  const [deleteFile] = useDeleteFileMutation();
  const dispatch = useAppDispatch();
  const [userDetail, setuserDetail] = useState<any>(userDetails);
  const [countries, setCountries] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [preferredCurrency, setPreferredCurrency] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not
  const [errors, setErrors] = useState<any>(errorJson);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [fileId, setFileId] = useState(0);
  const [fileName, setFileName] = useState(0);
  const handleChange = (e: any) => {
    if (isSubmitted) {
      validateForm({ ...userDetail, [e.target.name]: e.target.value });
    }
    setuserDetail({
      ...userDetail,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const getSettingData = async () => {
    let path = apiUrl.getAllSetting;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let allSetting = res?.data && res?.data?.jsonData;
      setPreferredCurrency(allSetting?.Preferred_Currency);
    } else {
      toast.error(res.message);
    }
  };

  const getCountries = async () => {
    let path = apiUrl.getCountries;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let countries = res?.data && res?.data?.countries;
      setCountries(countries);
    } else {
      toast.error(res.message);
    }
  };

  const getStates = async (CountryId: any) => {
    let path = apiUrl.getStates + CountryId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let states = res?.data && res?.data?.states;
      setStates(states);
    } else {
      toast.error(res.message);
    }
  };

  const getCities = async (stateId: any) => {
    let path = apiUrl.getCities + stateId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let cities = res?.data && res?.data?.cities;
      setCity(cities);
    } else {
      toast.error(res.message);
    }
  };

  const validateForm = (userInfo = userDetail, formSubmit = false) => {
    const errs: any = userRoleValidator(errors, userInfo, isRole);
    setErrors(errs);
    const isFormValid = isEmptyJSON(errs);
    if (formSubmit && isFormValid) {
      setSubmitPopup(true);
    }
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
    }
  };

  const handleSelectedFile = async (e: any) => {
    let files = Array.prototype.slice.call(e.target.files);
    if (documents.length + files.length > commanValidation.maxFileLength) {
      toast.error(translate('ERRORS.INVALID.MAXIMUM_DOCUMENT_VALIDATION'));
      return;
    }
    await Promise.all(
      files.map(async (file: any, index: any) => {
        if (file.size >= commanValidation.maxFileSize) {
          toast.error(translate('ERRORS.INVALID.DOCUMENT_VALIDATION'));
          return;
        }
        if (!isValidFile(file.type)) {
          toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
          return;
        }
        await handleFileUploder(file, index, files.length);
      })
    );
    setDocuments([...documents, ...tempDocument]);
  };
  const handleFileUploder = async (file: any, index: any, fileLength: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', '');
    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res = await fr.response;
    if (res.success) {
      if (fileLength === index + 1) {
        toast.success(res.message);
      }
      await getFileUploaderData(res?.data?.data);
    } else {
      toast.error(res.message);
    }
  };

  const getFileUploaderData = async (data: any) => {
    tempDocument.push(data);
  };

  const deleteDocumentsData = async () => {
    let docArray = [...documents];
    let tempDocument = docArray.filter(val => {
        return fileId !== val.id
    })
    setDocuments(tempDocument)

    try {
        const postJson = {
            Id: fileId,
            filename:fileName
          };
        const response = await deleteFile(postJson).unwrap();
        if (response.success) {
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
    } catch (err: any) {
        toast.error(err?.data?.data?.message);
    }
}

  const logout = () => {
    dispatch(authLogout());
    navigate(routesConstant.login);
  };

  const onSubmit = async (e: any) => {
    let documentArray = documents.map((val: any) => {
      return val.id;
    });
    let userId = localStorage.getItem('userId');
    const postJson = {
      ...userDetail,
      user_type: isRole ? UserType.INDIVIDUAL : UserType.COMPANY,
      company_name: isRole ? '' : userDetail.company_name,
      company_identification_number: isRole
        ? ''
        : userDetail.company_identification_number,
      gst_number: isRole ? '' : userDetail.gst_number,
      country_id: parseInt(userDetail.country_id),
      state_id: parseInt(userDetail.state_id),
      city_id: parseInt(userDetail.city_id),
      documentIds: documentArray,
      userId: Number(userId),
      designation: userDetail.designation ? userDetail.designation : '',
    };
    try {
      const response = await register(postJson).unwrap();
      if (response.success) {
        toast.success(translate('REGISTER.TITLES.REQUEST_APPROVAL'));
        logout();
      } else {
        toast.error(response.message);
      }
    } catch (err: any) {
      toast.error(err?.data?.data?.message);
    }
  };

  const getEmailData = () => {
    let email =
      localStorage.getItem('verifyEmail') &&
      localStorage.getItem('verifyEmail');
    if (email) {
      setEmail(email);
    }
  };

  const getRemakeDataByUserId = async () => {
    let path = apiUrl.getUserProfile;
    const param = {
      user_id,
      page: commanLabel.page,
      limit: commanLabel.pageLimit,
    };
    const fr = await helper.getData(path, param);
    const res = await fr.response;
    if (res.success) {
      const remakeData = res?.data?.userDetail;
      setDocuments([...documents, ...res?.data?.files]);
      getUpdateData(remakeData);
    } else {
      toast.error(res.message);
    }
  };

  const getUpdateData = async (remakeData: any) => {
    if (user_id) {
      getStates(
        remakeData?.user_profile?.address?.country?.id
          ? remakeData?.user_profile?.address?.country?.id
          : 0
      );
      getCities(
        remakeData?.user_profile?.address?.states?.id
          ? remakeData?.user_profile?.address?.states?.id
          : 0
      );
      setEmail(remakeData?.email);
      setuserDetail({
        ...userDetail,
        user_name: remakeData?.user_profile?.user_name,
        user_type: remakeData?.user_type_id,
        secondary_email: remakeData?.user_profile?.alternate_email
          ? remakeData?.user_profile?.alternate_email
          : '',
        contact_number: remakeData?.user_profile?.contact_number,
        referal_code: remakeData?.user_profile?.referral_code,
        country_id: remakeData?.user_profile?.address?.country?.id,
        state_id: remakeData?.user_profile?.address?.states?.id,
        city_id: remakeData?.user_profile?.address?.cities?.id,
        zip_code: remakeData?.user_profile?.address?.zip_code,
        designation: remakeData?.user_profile?.designation,
        billing_address: remakeData?.user_profile?.address?.address,
        company_name: remakeData?.user_profile?.user_company?.company_name,
        company_identification_number:
          remakeData?.user_profile?.user_company?.company_identification_no,
        gst_number: remakeData?.user_profile?.user_company?.gst_vat_no,
        preferred_currency: remakeData?.user_profile?.preferred_currency
          ? remakeData?.user_profile?.preferred_currency
          : commanLabel.currency,
        documentIds: remakeData?.user_profile?.document_ids,
        userId: remakeData?.id,
      });
    }
  };

  useEffect(() => {
    getSettingData();
    getCountries();
    getEmailData();
    getRemakeDataByUserId();
  }, []);

  const onDisCard = () => {
    setuserDetail(userDetails);
    setErrors(errorJson);
  };

  const popupCenter = (url: any, title: any, w: any, h: any) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow: any = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    newWindow.focus();
  };
  const openPopup = (url: any, title: any) => {
    popupCenter(url, title, 900, 500);
  };
  return (
    <React.Fragment>
      <div className='tab-content'>
        <div
          className='tab-pane fade show active'
          id='companyuser'
          role='tabpanel'
          aria-labelledby='companyuser-tab'
        >
          <Form
            className='register-form'
            id='userform'
            noValidate
            validated={validated}
          >
            {!isRole && (
              <div className='row'>
                <div className='col-md-4 col-sm-6 '>
                  <div className='form-group required'>
                    <label className='form-label label-info'>
                      {translate('REGISTER.TITLES.COMPANY_NAME')}
                      <i
                        className='fa fa-info-circle tooltip-icon-data'
                        title='Would be registered for credit generation'
                      />
                    </label>
                    <Form.Control
                      name='company_name'
                      placeholder={
                        translate('REGISTER.TITLES.ENTER_COMPANY_NAME') + ''
                      }
                      type='text'
                      className='form-control form-control'
                      value={userDetail.company_name}
                      maxLength={commanValidation.maxLength}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      required
                    />
                    {
                      <span className='error'>
                        {translate(errors.company_name)}
                      </span>
                    }
                  </div>
                </div>
                <div className='col-md-4 col-sm-12 '>
                  <div className='form-group required'>
                    <label>
                      {translate(
                        'REGISTER.TITLES.COMPANY_IDENTIFICATION_NUMBER'
                      )}
                    </label>
                    <Form.Control
                      name='company_identification_number'
                      placeholder={
                        translate(
                          'REGISTER.TITLES.ENTER_COMPANY_IDENTIFICATION_NUMBER'
                        ) + ''
                      }
                      type='text'
                      className='form-control form-control'
                      value={userDetail.company_identification_number}
                      maxLength={commanValidation.maxLength}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      required
                    />
                    {
                      <span className='error'>
                        {translate(errors.company_identification_number)}
                      </span>
                    }
                  </div>
                </div>
                <div className='col-md-4 col-sm-6 '>
                  <div className='form-group required'>
                    <label>{translate('REGISTER.TITLES.GSTNUMBER')}</label>
                    <Form.Control
                      name='gst_number'
                      placeholder={
                        translate('REGISTER.TITLES.ENTER_GSTNUMBER') + ''
                      }
                      type='text'
                      className='form-control form-control'
                      value={userDetail.gst_number}
                      maxLength={commanValidation.maxLength}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      required
                    />
                    {
                      <span className='error'>
                        {translate(errors.gst_number)}
                      </span>
                    }
                  </div>
                </div>
              </div>
            )}
            <div className='row'>
              <div className='col-md-4 col-sm-6 '>
                <div className='form-group required'>
                  <label className='form-label label-info'>
                    {translate('COMMONFIELDS.EMAIL')}
                    <i
                      className='fa fa-info-circle tooltip-icon-data'
                      title='Would be registered for credit generation'
                    />
                  </label>
                  <input
                    name='email'
                    type='email'
                    className='form-control form-control'
                    value={email}
                    maxLength={commanValidation.maxLength}
                    minLength={commanValidation.minLengthEmail}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    disabled={true}
                  />
                </div>
              </div>
              <div className='col-md-4 col-sm-12 '>
                <div className='form-group required'>
                  <label>{translate('REGISTER.TITLES.USERNAME')}</label>
                  <Form.Control
                    name='user_name'
                    placeholder={
                      translate('REGISTER.TITLES.ENTER_USERNAME') + ''
                    }
                    type='text'
                    className='form-control form-control'
                    value={userDetail.user_name}
                    maxLength={commanValidation.maxLength}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                  />
                  {<span className='error'>{translate(errors.user_name)}</span>}
                </div>
              </div>
              <div className='col-md-4 col-sm-6 '>
                <div className='form-group required'>
                  <label className='form-label'>
                    {translate('COMMONFIELDS.CONTACT_NUMBER')}
                  </label>
                  <div className='cell-item'>
                    <span className='prefix'>{commanLabel.countryCode}</span>
                    <input
                      type={'number'}
                      placeholder={
                        translate('COMMONFIELDS.ENTER_CONTACT_NUMBER') + ''
                      }
                      className='form-control form-control'
                      name='contact_number'
                      value={userDetail.contact_number}
                      pattern={commanValidation.numberPattern}
                      max={commanValidation.mobileMaxLength}
                      min={commanValidation.mobileMinLength}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      required
                    />
                    {
                      <span className='error'>
                        {translate(errors.contact_number)}
                      </span>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='row '>
              <div className='col-md-4 col-sm-12 combine-col '>
                <div className='form-group'>
                  <Form.Label>
                    {translate('COMMONFIELDS.SECONDARY_EMAIL')}
                  </Form.Label>
                  <input
                    name='secondary_email'
                    placeholder={
                      translate('COMMONFIELDS.ENTER_SECONDARY_EMAIL') + ''
                    }
                    type='email'
                    className='form-control form-control'
                    value={userDetail.secondary_email}
                    maxLength={commanValidation.maxLength}
                    minLength={commanValidation.minLengthEmail}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    // required
                  />
                  {
                    <span className='error'>
                      {translate(errors.secondary_email)}
                    </span>
                  }
                </div>
                <div className='form-group'>
                  <label>{translate('COMMONFIELDS.REFERRAL_CODE')}</label>
                  <Form.Control
                    name='referal_code'
                    placeholder={
                      translate('COMMONFIELDS.ENTER_REFERRAL_CODE') + ''
                    }
                    type='text'
                    className='form-control form-control'
                    value={userDetail.referal_code}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div>
              <div className='col-md-8  col-sm-12'>
                <div className='form-group required'>
                  <label className='form-label'>
                    {translate('COMMONFIELDS.BILLING_ADDRESS')}
                  </label>
                  <textarea
                    name='billing_address'
                    className='form-control'
                    placeholder={
                      translate('COMMONFIELDS.ENTER_BILLING_ADDRESS') + ''
                    }
                    rows={6}
                    value={userDetail.billing_address}
                    maxLength={commanValidation.maxLengthAddress}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                  />
                  {
                    <span className='error'>
                      {translate(errors.billing_address)}
                    </span>
                  }
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4  col-sm-6'>
                <div className='form-group required'>
                  <label className='form-label'>
                    {translate('COMMONFIELDS.COUNTRY')}
                  </label>
                  <Form.Select
                    name='country_id'
                    className='form-control select-data form-select'
                    value={userDetail.country_id}
                    onChange={(e) => {
                      handleChange(e);
                      getStates(e.target.value);
                    }}
                    required
                  >
                    <option value=''>
                      {translate('COMMONFIELDS.SELECT_COUNTRY')}
                    </option>
                    {countries?.length > 0 &&
                      countries?.map((u: any, i: any) => {
                        return (
                          <option key={i} value={u.id}>
                            {u.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                  {
                    <span className='error'>
                      {translate(errors.country_id)}
                    </span>
                  }
                </div>
              </div>
              <div className='col-md-4  col-sm-6'>
                <div className='form-group required'>
                  <label className='form-label'>
                    {translate('COMMONFIELDS.STATE')}
                  </label>
                  <Form.Select
                    name='state_id'
                    className='form-control select-data form-select'
                    value={userDetail.state_id}
                    onChange={(e) => {
                      handleChange(e);
                      getCities(e.target.value);
                    }}
                    required
                    disabled={userDetail.country_id ? false : true}
                  >
                    <option value=''>
                      {translate('COMMONFIELDS.SELECT_STATE')}
                    </option>
                    {states?.length > 0 &&
                      states?.map((u: any, i: any) => {
                        return (
                          <option key={i} value={u.id}>
                            {u.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                  {<span className='error'>{translate(errors.state_id)}</span>}
                </div>
              </div>
              <div className='col-md-4  col-sm-6'>
                <div className='form-group required'>
                  <label className='form-label'>
                    {translate('COMMONFIELDS.CITY')}
                  </label>
                  <Form.Select
                    name='city_id'
                    className='form-control select-data form-select'
                    value={userDetail.city_id}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    disabled={userDetail.state_id ? false : true}
                    required
                  >
                    <option value=''>
                      {translate('COMMONFIELDS.SELECT_CITY')}
                    </option>
                    {city?.length > 0 &&
                      city?.map((u: any, i: any) => {
                        return (
                          <option key={i} value={u.id}>
                            {u.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                  {<span className='error'>{translate(errors.city_id)}</span>}
                </div>
              </div>
              <div className='col-md-4 col-sm-6'>
                <div className='form-group required'>
                  <label>{translate('COMMONFIELDS.ZIP_CODE')}</label>
                  <Form.Control
                    name='zip_code'
                    placeholder={translate('COMMONFIELDS.ENTER_ZIP_CODE') + ''}
                    type='text'
                    className='form-control form-control'
                    maxLength={commanValidation.maxZipCode}
                    value={userDetail.zip_code}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                  />
                  {<span className='error'>{translate(errors.zip_code)}</span>}
                </div>
              </div>
              <div className='col-md-4 col-sm-6'>
                <div className='form-group'>
                  <label>{translate('COMMONFIELDS.DESIGNATION')}</label>
                  <Form.Control
                    name='designation'
                    placeholder={
                      translate('COMMONFIELDS.ENTER_DESIGNATION') + ''
                    }
                    type='text'
                    maxLength={commanValidation.maxLength}
                    className='form-control form-control'
                    value={userDetail.designation}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div>
              <div className='col-md-4  col-sm-6'>
                <div className='form-group required'>
                  <label className='form-label'>
                    {translate('COMMONFIELDS.PREFERRED_CURRENCY')}
                  </label>
                  <Form.Select
                    className='form-control'
                    name='preferred_currency'
                    value={userDetail.preferred_currency}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    {preferredCurrency.length > 0 &&
                      preferredCurrency?.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className='attach-document'>
              <div className='font-base'>
                {translate('REGISTER.TITLES.ATTACH_DOCUMENTS')}
              </div>
              <div className='file-drop-area text-center border-design'>
                <span className='file-msg'>
                  {translate('REGISTER.TITLES.DRAG')} &amp;{' '}
                  {translate('REGISTER.TITLES.DROP_OR')}{' '}
                  <span>{translate('REGISTER.TITLES.BROWSE')}</span>
                  <br />
                  <p className='note'>
                    {translate('REGISTER.TITLES.SUPPORTED_FORMATS')}{' '}
                    {translate('REGISTER.TITLES.DOCUMENT_FORMATS')}
                  </p>
                </span>
                <input
                  className='file-input'
                  title=''
                  type='file'
                  id={'document'}
                  onChange={handleSelectedFile}
                  accept='.jpg, .jpeg, .png, .pdf, .docx, .doc, .dot, .dotx, .xlsx, .xlsm, .xls, .xlsm'
                  multiple
                />
              </div>
            </div>
            <div className='documentlist'>
              <ul>
                {documents.length > 0 &&
                  documents.map((item: any, index: any) => {
                    return (
                      <li key={index}>
                        <div className='item-document'>
                          <div className='left'>
                            <div className='icon-img'>
                              {getFileFormat(item.document_type) ===
                                commanLabel.pdf && (
                                <img src={imagePath.pdfPath} alt='' />
                              )}
                              {getFileFormat(item.document_type) ===
                                commanLabel.png && (
                                <img src={imagePath.jpgPath} alt='' />
                              )}
                              {getFileFormat(item.document_type) ===
                                commanLabel.jpg && (
                                <img src={imagePath.jpgPath} alt='' />
                              )}
                              {getFileFormat(item.document_type) ===
                                commanLabel.jpeg && (
                                <img src={imagePath.jpgPath} alt='' />
                              )}
                              {getFileFormat(item.document_type) ===
                                commanLabel.excel && (
                                <img
                                  className='file-logo'
                                  src={imagePath.excelPath}
                                  alt=''
                                />
                              )}
                              {getFileFormat(item.document_type) ===
                                commanLabel.docs && (
                                <img
                                  className='file-logo'
                                  src={imagePath.docsLogo}
                                  alt=''
                                />
                              )}
                            </div>
                            <div className='content'>
                              <div className='title'>{item.document_name}</div>
                              <div className='size'>
                                {getFormatBytes(item.size)}
                              </div>
                            </div>
                          </div>
                          <div className='right'>
                            <div
                              className='delete-icon'
                              onClick={(e) => {
                                e.preventDefault();
                                setFileName(item.document_path.split('/').pop())
                                setFileId(item.id);
                                setIsOpen(true);
                              }}
                            >
                              <img src={imagePath.deletePath} alt='' />
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            {state.auth.user?.status !== UserProfileStatus.APPROVED && (
              <div className='submit-button-content'>
                <div className='note'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='accepted_terms'
                      id='accepted_terms'
                      value={userDetail.accepted_terms}
                      checked={isChecked}
                      onChange={handleOnChange}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='accepted_terms'
                    >
                      {translate('REGISTER.TITLES.TERMSLABEL1')}&nbsp;
                    </label>
                    <a
                      href='javascript:void(0)'
                         onClick={() => {
                                 openPopup(termsPDF,'terms')
                                }}
                        rel='noopener noreferrer'
                      >
                        {translate('REGISTER.TITLES.TERMSLABEL2')}
                      </a>{' '}
                      {translate('REGISTER.TITLES.AND')}{' '}
                      <a
                        href='javascript:void(0)'
                        onClick={() => {
                          openPopup(privacyPDF,'terms')
                         }}
                        rel='noopener noreferrer'
                      >
                        {translate('REGISTER.TITLES.PRIVACY_POLICY')}
                      </a>
                    {
                      <span className='error'>
                        {translate(errors.accepted_terms)}
                      </span>
                    }
                  </div>
                </div>
                <div className='buttons'>
                  <Button
                    disabled={isChecked ? false : true}
                    className='btn btn-primary'
                    type='button'
                    onClick={() => {
                      setIsSubmited(true);
                      validateForm({ ...userDetail }, true);
                    }}
                  >
                    {state.auth.user?.status === UserProfileStatus.REJECTED
                      ? translate('BUTTONS.SUBMIT')
                      : translate('BUTTONS.REGISTER')}
                  </Button>
                  <div className='reset-link'>
                    <Link
                      to={'#'}
                      type='button'
                      onClick={(e) => {
                        e.preventDefault();
                        setDiscardPopup(true);
                      }}
                    >
                      {translate('BUTTONS.RESET')}
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Form>
        </div>
        {discardPopup && (
          <ConfirmationPopup
            title={commanLabel.discard}
            message={validationMessage.discardMessage}
            handleCancel={() => setDiscardPopup(false)}
            cancelText={commanLabel.cancel}
            submitText={commanLabel.discard}
            handleSubmit={() => {
              onDisCard();
              setDiscardPopup(false);
            }}
          />
        )}
        {submitPopup && (
          <ConfirmationPopup
            title={commanLabel.register}
            message={validationMessage.registerMessage}
            handleCancel={() => setSubmitPopup(false)}
            cancelText={commanLabel.cancel}
            submitText={commanLabel.register}
            handleSubmit={(e: any) => {
              onSubmit(e);
              setSubmitPopup(false);
            }}
          />
        )}
      </div>
      {isOpen && (
        <ConfirmationPopup
          title='Delete'
          message='Are you sure you want to Delete?'
          handleCancel={() => setIsOpen(false)}
          cancelText='Cancel'
          submitText='Delete'
          handleSubmit={() => {
            deleteDocumentsData();
            setIsOpen(false);
          }}
        />
      )}
    </React.Fragment>
  );
};
