export const validationMessage = {
  mandatoryMessage: 'Please fill all the mandatory',
  buyerMinKgMessage: 'Min. Qty Allowed - 1000 Kg',
  buyerMinTonneMessage: 'Min. Qty Allowed - 1 Tonne',
  invalidName: 'Please enter valid name.',
  invalidAddress: 'Please enter valid Address.',
  nameRequired: 'Please enter name',
  subTypeRequired: 'Select material sub type.',
  invalidSubType: 'Please Enter valid sub type',

  invalidBuyerName: 'Please enter valid buyer name.',
  buyerNameRequired: 'Please enter buyer name',
  invalidBuyerAddress: 'Please enter valid buyer Address.',
  buyerAddressRequired: 'Please enter buyer Address',

  invalidSellerName: 'Please enter valid seller name.',
  sellerNameRequired: 'Please enter seller name',
  invalidSellerAddress: 'Please enter valid seller Address.',
  sellerAddressRequired: 'Please enter seller Address',

  referenceRequired: 'Please enter reference.',
  invalidreference: 'Please enter valid reference.',

  referenceNumberRequired: 'Enter Reference number/link.',
  invalidReferenceNumber: 'Enter valid Reference number/link.',
  invalidPOReferenceNumber: 'Please enter valid PO/SO Reference Number.',
  invalidSellerInvoice: 'Please enter valid Seller Invoice Reference.',
  minKgMessage: 'Min. Qty Allowed - 1 Kg',
  minKgMessage_1000: 'Min. Qty Allowed - 1000 Kg',
  documentMessage:
    ' You can only upload 1 file. Max file size 5MB. File format .jpg, .jpeg, .png, .pdf',
  maxFile: 'Max file size 5MB allowed',
  material: 'Material Category is Mismatched.',
  transaction:
    'Current transaction seller details are not matched with previous transaction buyer details.',
  initialSeller: 'Initial Transaction Seller Category is not matched.',
  latBuyer: 'Last Buyer Category is not matched.',
  transactionMismatch: "Transaction linking doesn't matching",
  linkTransactionSuccess: 'Transaction linking successful.',
  noRecordFoundUnlinkedTransaction: 'No record found for unlinked transactions',
  noRecordFoundLinkedTransaction: 'No record found for linked transactions',
  noRecordFoundLinking: 'No record found for linking',
  noRecordFound: 'No Record Found.',

  submitMessage: 'Are you sure you want to Submit ?',
  discardMessage: 'Are you sure you want to Discard?',
  registerMessage: 'Are you sure you want to Register ?',

  emailRequired: ' Enter valid email.',
  companyNameRequired: 'Enter company name.',
  companyIdentificationNumberRequired: 'Enter company identification number.',
  GSTVATRequired: ' Enter GST/VAT number.',
  contactRequired: 'Enter valid Contact Number.',
  billingAddressRequired: 'Enter billing address.',
  materialTypeRequired: 'Select material type.',
  regionRequired: 'Select region.',
  totalCreditRequired: 'Enter total credit.',
  quantityRequired: 'Enter quantity.',

  invalidEventName: 'Enter valid event name.',
  invalidEventDate: 'Select proper event date.',
  invalidPOCName: 'Enter valid POC name.',
  invalidLocationName: 'Enter valid location.',
  invalidOrganiseName: 'Enter valid Organiser name.',
  invalidDataProviderName: 'Enter valid Data provider name.',
  invalidURL: 'Enter valid URL.',
  invalidReclaimerName: 'Enter valid reclaimer name.',
  invalidProfileName: 'Enter valid profile name.',
  invalidAadhaarNumber: 'Enter valid aadhaar number.',
  invalidMaterialName: 'Invalid Material Name.',
  invalidDescribe: 'Invalid Describe.',
  invalidImpactDescription: 'Invalid Impact Description',
  invalidReferenceOf: 'Invalid Reference Value',
  ENTER_FULL_NAME: 'Enter full name',
  SELECT_USER_TYPE: 'Select user type',
  ENTER_A_VALID_EMAIL_ADDRESS: 'Invalid email address',
  ENTER_A_VALID_MOBILE: 'Enter valid Mobile No.',
  INVALID_PASSRD:
    'Password should contains atleast 8 charaters and containing uppercase,lowercase and numbers',
  SELECT_MATERIAL_TYPE: ' Select material type.',
  SELECT_MATERIAL_SUB_TYPE: 'Select material sub type.',
  SELECT_REGION: 'Select region',
  ENTER_QUANTITY: 'Enter quantity',
  invalidUserName: 'Enter valid user name',
  tonInvalid: 'For Ton unit, enter quantity equal to or greater than 1.',
  kgInvalid: 'For Kg unit, enter quantity equal to or greater than 1000.',
  QUANTITY_NOT_MATCHING: 'Quantity is not matching',
  TOTAL_QUANTITY_NOT_MATCHING:
    'Single / Combined transaction(s) total quantity is not matching with actual required quantity.',
  TRANSACTION_REQUIRED: 'Transaction Required !',
  ATLEASE_ONE_TRANSACTION: 'Please Select Atleast one Transaction.',
};

export const RequiredError = {
  credit_email: 'Enter credit owner email address.',
  email: 'Enter email address',
  valid_email: 'Enter a valid email address.',
  mobile: 'Enter contact number.',
  mobile_no_valid: 'Enter valid contact number.',
  company_name: 'Enter valid company name.',
  company_identification_number: 'Enter company identification number.',
  gst_number: 'Enter GST/VAT number.',
  billing_address: 'Enter billing address.',
  material_type_id: 'Select material type.',
  material_name_type_id: 'Select material sub type.',
  region_id: 'Select region.',
  quantity: 'Enter quantity.',
  totalCredit: 'Enter total credit.',
  materia_quantity: 'Enter material total quantity.',
  name_required: 'Please enter name.',
  invalid_name: 'Please enter valid name.',
  category: 'Select transaction category.',
  utility: 'Select utility from the list.',
  sub_type_required: 'Select material sub type.',
  invalid_sub_type: 'Please Enter valid sub type.',
  hsn_code: 'Enter HSN Code.',
  buyer_category: 'Select buyer category.',
  buyer_mobile_no: 'Enter buyer Contact Number.',
  buyer_mobile_no_valid: 'Enter valid buyer Contact Number.',
  seller_mobile_no: 'Enter seller contact number.',
  seller_mobile_no_valid: 'Enter valid seller contact number.',
  seller_category: 'Select seller Category.',
  date: 'Select date.',
  carrier_registration_no: 'Enter carrier registration number.',
  buyer_main_address: 'Locate buyer location in map.',
  seller_main_address: 'Locate seller location in map.',
  quantity_validation: 'Enter valid quantity.',
  total_credit_validation: 'Enter valid total credit.',
  userName: 'Enter full name of credit owner.',
  materialTypeId: 'Select material type.',
  categoryTypeId: 'Select material category.',
  regionId: 'Select region.',
  state_id: 'Select state',
  city_id: 'Select city',
  zip_code: 'Enter zipCode.',
  otp_no_valid: 'Enter valid otp.',
  otp: 'Enter otp.',
  confirm_password_no_valid: 'Enter valid confirm password.',
  confirm_password: 'Enter confirm password.',
  password_no_valid: 'Enter valid password.',
  password: 'Enter password.',
  accepted_terms: ' Checked terms and conditions',
  eventName: 'Enter  event name.',
  eventDate: 'Select proper event date.',
  POCName: 'Enter POC name.',
  locationName: 'Enter location.',
  organiseName: 'Enter Organiser name.',
  dataProviderName: 'Enter Data provider name.',

  reclaimerName: 'Enter Reclaimer name.',
  profileName: 'Enter Name.',
  gender: 'Please select gender.',
  aadhaarNumber: 'Please enter aadhaar number.',
  aadhaarPhoto: 'Please select aadhaar photo.',
  birthDate: 'Please select birth date.',
  childrens: 'Please enter childrens details.',
  activityPhoto: 'Please select activity photo.',
  materialName: 'Please enter Material name',
  impactDescription: 'Please enter impact description',
  describe: 'Please enter describe',
  referenceValue: 'Please enter reference value',
  eventCategory: 'Please enter event category',
  activityCategory: 'Please enter activity category',
  domainUrl: 'Please enter domain url.',
};

export const Declarations = {
  Label1: '1. No child labour involved.',
  Label2: '2. Meeting defined ReMake standards.',
  Label3: '3. EPR credit not claimed and will not be claimed.',
};

export const CreditRequestLable = {
  Label1:
    ' *You will receive a confirmation email and your credit request will be sent to the Admin.',
  Label2:
    '*You will receive a proforma invoice, and upon receipt of the payment, the requested plastic credit certificate will be shared with you.',
};
