import React from "react"

export const SdgGoals = () => {
    return (

        <div className="body-content-space pb-0">
            <div className="container">
                <div className="title-description-content">
                    <h2 className="text-center title-main">SDG Goals</h2>
                    <div className="listing-goals">
                        <ul>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_01-min.svg`} alt="img1" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            No Poverty -
                                        </span>
                                        <p className="quote">"End poverty in all its forms everywhere"</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 1
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 1.4:
                                        </span> By 2030, ensure that all men and women, in particular the poor and the vulnerable, have equal rights to economic resources, as well as access to basic services, ownership and control over land and other forms of property, inheritance, natural resources, appropriate new technology, and financial services, including microfinance.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span> All the value chain partners are required to comply with standards set by ReMake. Elements like fair wages, formal transactions, and government identification of workers and reclaimers. This results in bringing the workers and reclaimers into the formal waste management sector and uplifting them socially. ReMake also enables the alleviation of poverty for frontline waste management workers through its technological prowess and financial ingenuity. It identifies the reclaimers and collectors involved in a transaction and directly transfers the benefit received from the sale of credit to their respective digital wallets.
                                    </p>
                                </div>
                            </li>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_03-min.svg`} alt="img1" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            Good Health and Well-Being -
                                        </span>
                                        <p className="quote"> "Ensure healthy lives and promote well-being for all at all ages"</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 3
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 3.9:
                                        </span>By 2030, substantially reduce the number of deaths and illnesses from hazardous chemicals and air, water and soil pollution and contamination.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span>In 2020, the first-ever field study to explore how the presence of microplastics can affect soil fauna was published in the&nbsp;Proceedings of the Royal Society. The paper notes that terrestrial microplastic pollution has led to the decrease of species that live below the surface, such as mites, larvae and other tiny creatures that maintain the fertility of the land. Chlorinated&nbsp;plastic&nbsp;can release&nbsp;harmful&nbsp;chemicals into the surrounding&nbsp;soil, which can then seep into groundwater or other surrounding water sources, and also the ecosystem. With the goal of scaling the collection and recycling of plastic waste, ReMake aims to bring about the circularity required and keep the plastic waste away from ending up in landfills and waterbeds.
                                    </p>
                                </div>
                            </li>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_04-min.svg`} alt="img1" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            Quality Education -
                                        </span>
                                        <p className="quote">  "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all"</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 4
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 4.5:
                                        </span>By 2030, eliminate gender disparities in education and ensure equal access to all levels of education and vocational training for the vulnerable, including persons with disabilities, indigenous peoples and children in vulnerable situations.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span>Child Labour in the waste management sector of developing countries is a prevalent challenge and are vulnerable to a harsh work environment.
                                        <br />ReMake Program, through its standard (with stringent no child labor policy), periodical physical audit/inspection, and reward incentives for the value chain partners, is taking steps to ensure that no child worker is there in the value chain, and has the opportunity to access education.
                                    </p>
                                </div>
                            </li>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_06-min.svg`} alt="img1" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            Clean Water And Sanitation -
                                        </span>
                                        <p className="quote">"Ensure availability and sustainable management of water and sanitation for all"</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 6
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 6.3:
                                        </span>By 2030, improve water quality by reducing pollution, eliminating dumping and minimizing release of hazardous chemicals and materials, halving the proportion of untreated wastewater and substantially increasing recycling and safe reuse globally.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span>ReMake Program, with equal focus and concerns over land-based and water-bound plastic wastes, acts as a platform to incentivize the collection and recycling of it, and bring about the circularity required and keep the hazardous waste away from ending up being dumped in landfills that result in the leaching of toxins into ground and surface water resources.
                                    </p>
                                </div>
                            </li>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_08-min.svg`} alt="img1" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            Decent Work And Economic Growth -
                                        </span>
                                        <p className="quote">“Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all”</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 8
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 8.2:
                                        </span> Achieve higher levels of economic productivity through diversification, technological upgrading and innovation, including through a focus on high-value added and labour-intensive sectors.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Target 8.3:
                                        </span>Promote development-oriented policies that support productive activities, decent job creation, entrepreneurship, creativity and innovation, and encourage the formalization and growth of micro-, small- and medium-sized enterprises, including through access to financial services.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Target 8.7:
                                        </span> Take immediate and effective measures to eradicate forced labour, end modern slavery and human trafficking and secure the prohibition and elimination of the worst forms of child labour, including recruitment and use of child soldiers, and by 2025 end child labour in all its forms.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Target 8.8:
                                        </span>Protect labour rights and promote safe and secure working environments for all workers, including migrant workers, in particular women migrants, and those in precarious employment.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Target 8.10:
                                        </span>Strengthen the capacity of domestic financial institutions to encourage and expand access to banking, insurance and financial services for all.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span>The waste management sector in developing countries remains highly labour intensive and informal. This led to not just the gap in collection infra, but also put the labor wokforce outside the purview of minimum health and workplace standards. With profound focus of socially uplifting the sector workforce, ReMake is highly invested in ensuring the promotion and implementation of best workplace standards, technological upgradation, and safe working environment. It works towards the formal recognition of the waste reclaimers by enabling creation of their government identification card, banking access, digital wallet for direct benefit transfer, etc.
                                    </p>
                                </div>
                            </li>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_11-min.svg`} alt="img1" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            Sustainable Cities and Communities -
                                        </span>
                                        <p className="quote">“Make cities and human settlements inclusive, safe, resilient, and sustainable”</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 11
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 11.6:
                                        </span>By 2030, reduce the adverse per capita environmental impact of cities, including by paying special attention to air quality and municipal and other waste management.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span>Nearly all the Urban Local Bodies (ULBs) and residents of major cities in India face the challenge of plastic waste management at their centers. The present network and infrastructure cannot cope with the volumes of waste generated by an increasing urban population, and this impacts the environment and public health. Also, the wastes generated at urban centers have an impact on other ecosystems as well, for instance, an estimated&nbsp;60% of plastic marine debris&nbsp;derives from&nbsp;urban centers.<br /> Expanding robust collection, segregation, and recycling infrastructure is one of the end-goal that ReMake hopes to achieve through its incentivizing financial and technological ingenuity.
                                    </p>
                                </div>
                            </li>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_12-min.svg`} alt="img1" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            Responsible Consumption And Production -
                                        </span>
                                        <p className="quote">“Ensure sustainable consumption and production patterns”</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 12
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 12.2:
                                        </span> By 2030, achieve the sustainable management and efficient use of natural resources.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Target 12.5:
                                        </span>  By 2030, substantially reduce waste generation through prevention, reduction, recycling and reuse.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span>Circular economy is the key to sustainable development. With the goal of scaling the collection and recycling of plastic waste, ReMake aims to bring about the circularity required at the hour and transition from the take-make-waste model of production and consumption.
                                    </p>
                                </div>
                            </li>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_13-min.svg`} alt="img1" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            Climate Action -
                                        </span>
                                        <p className="quote">"Take urgent action to combat&nbsp;climate change&nbsp;and its impacts by regulating emissions and promoting developments in renewable energy"</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 13
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 13.3:
                                        </span> Improve education, awareness-raising and human and institutional capacity on climate change mitigation, adaptation, impact reduction and early warning.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span>According to a report by the <a href="#">World Economic Forum</a>, recycling 1 tonne could reduce emissions by 1.1–3.0 tonnes of CO2e compared to producing the same tonne of plastics from virgin fossil feedstock. Strengthening the recycling capacity, ReMake Program has positive impacts not just with respect to waste management but also climate.
                                    </p>
                                </div>
                            </li>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_14-min.svg`} alt="img1" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            Life Below Water -
                                        </span>
                                        <p className="quote"> "Conserve and sustainably use the oceans, seas and marine resources for&nbsp;sustainable development"</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 14
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 14.1:
                                        </span>  By 2025, prevent and significantly reduce marine pollution of all kinds, in particular from land-based activities, including marine debris and nutrient pollution.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span>Plastic debris is&nbsp;currently the most abundant type of litter in the ocean, making up 80% of all marine debris found from surface waters to deep-sea sediments.
                                        <br />
                                        The majority of plastic ocean debris comes from rapidly growing cities and towns along rivers and coastal areas in the developing world — where waste management systems, infrastructure, and governments struggle to keep pace with growing populations and increasing amounts of waste. ReMake aims to stop plastic pollution at the source is the most effective way to address this challenge.
                                    </p>
                                </div>
                            </li>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_15-min.svg`} alt="img1" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            Life On Land -
                                        </span>
                                        <p className="quote"> "Protect, restore and promote sustainable use of terrestrial&nbsp;ecosystems, sustainably manage forests, combat desertification, and halt and reverse land&nbsp;degradation&nbsp;and halt&nbsp;biodiversity loss"</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 15
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 15.3:
                                        </span>   By 2030, combat desertification, restore degraded land and soil, including land affected by desertification, drought and floods, and strive to achieve a land degradation-neutral world.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span>Several of dumped unmanaged plastic wastes break down into particles smaller than 5mm in size, known as microplastics, before disintegrating further into nanoparticles, which are less than 0.1 micrometre in size. These can then leech into the soil and groundwater systems, leading to land and water deterioration.<br /> Conserving land and life on it, by getting rid of the ever-growing plastic waste is one of the primary objectives of ReMake.
                                    </p>
                                </div>
                            </li>
                            <li className="item">
                                <div className="top-content">
                                    <div className="img-item">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/E_PRINT_17-min.svg`} alt="img17" />
                                    </div>
                                    <div className="img-description">
                                        <span className="goal-text">
                                            Partnerships For The Goals -
                                        </span>
                                        <p className="quote"> "Strengthen the means of implementation and revitalize the global partnership for sustainable development"</p>
                                    </div>
                                    <div className="goal-title">
                                        Goal 17
                                    </div>
                                </div>
                                <div className="bottom-content">
                                    <p>
                                        <span className="title">
                                            Target 17.16:
                                        </span>   Enhance the Global Partnership for Sustainable Development, complemented by multi-stakeholder partnerships that mobilize and share knowledge, expertise, technology and financial resources, to support the achievement of the Sustainable Development Goals in all countries, in particular developing countries.
                                    </p>
                                    <p>
                                        <span className="title">
                                            Achieving:
                                        </span>ReMake endeavors to include a large array of stakeholders, including transnational financial investors, who would incentivize waste management activities and contribute to the social upliftment of reclaimers in developing economies.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>




    )
}