import {
  BoolenType,
  ColorType,
  commanLabel,
} from '../../../constants/comman.constant';
import { onBehalfRole } from '../../../shared/remake-green-utils/common';
// basic Details fields
export const basicDetails = {
  role: onBehalfRole.SELLER,
  category: '',
  utility: '',
  material_type: '',
  material_color: ColorType.Natural,
  hsn_code: '',
  waste_description: '',
  is_ocean_bound: 'false',
  lot_batch_preference: '',
};

// buyer And Seller Details Details fields
export const buyerAndSellerDetails = {
  buyer_category: '',
  seller_category: '',
  date: new Date(),
  quantity: '',
  measurement_types: commanLabel.kgLabel,
  invoice: '',
  invoice_id: '',
  invoice_file_name: '',
  reference: '',
  buyer_mobile_no: '',
  buyer_email_id: '',
  buyer_address: '',
  seller_mobile_no: '',
  seller_email_id: '',
  seller_address: '',
  payment_reference: '',
  payment_amount: '',
  payment_date: new Date(),
};

// Loading Weight Slip Details Details fields
export const loadingWeightSlipDetails = {
  quantity: '',
  measurement_types: commanLabel.kgLabel,
  reference: '',
  date: new Date(),
  loading_weight_slip: '',
  loading_weight_slip_id: '',
  loading_weight_slip_file_name: '',
};

// UnLoading Weight Slip Details fields
export const unloadingweightSlipDetails = {
  quantity: '',
  measurement_types: commanLabel.kgLabel,
  reference: '',
  date: new Date(),
  unloading_weight_slip: '',
  unloading_weight_slip_id: '',
  unloading_weight_slip_file_name: '',
};

// eway Or Lorry Receipt fields
export const ewayOrLorryReceipt = {
  quantity: '',
  measurement_types: commanLabel.kgLabel,
  reference: '',
  date: new Date(),
  carrier_registration_no: '',
  transport_name: '',
  transport_address: '',
  eway_or_lorry_reciept: '',
  eway_or_lorry_reciept_id: '',
  eway_or_lorry_reciept_file_name: '',
};

// DebitNote For Buyer fields
export const debitNoteForBuyer = {
  any_quanty_return: BoolenType.False,
  quantity_return: '',
  measurement_types: commanLabel.kgLabel,
  debit_note: '',
  debit_note_id: '',
  debit_note_file_name: '',
};

// Good Recived Note fields
export const goodRecivedNote = {
  reference: '',
  GRN: '',
  GRN_ID: '',
  GRN__file_name: '',
  quantity: '',
  measurement_types: commanLabel.kgLabel,
};

// Good transfer details
export const goodTransferDetails = {
  material_transferred: '',
  material_form: '',
  packaging_form: '',
};

// Output Material details
export const outputMaterialDetails = {
  material_description: '',
  material_quantity: '',
};

// Recycling Certificate fields
export const recyclingCertificateInfo = {
  reference: '',
  recycling_certificate_document: '',
  recycling_certificate_document_id: '',
  recycling_certificate_document_file_name: '',
};

// Other Details fields
export const otherData = {
  po_referece_number_or_link: '',
  buyer_location: '',
  seller_location: '',
  buyer_main_address: '',
  seller_main_address: '',
};

// Next Details fields
export const NexStageTransactionInfo = {
  isNext: BoolenType.False,
  seller_buyer_name: '',
  PO_SO_reference_number: '',
  seller_invoice_reference: '',
  seller_invoice_date: new Date(),
  waste_type: '',
  unloading_quantity: '',
};
// Previous Details fields
export const PreviousStageTransactionInfo = {
  isPrev: BoolenType.False,
  seller_buyer_name: '',
  PO_SO_reference_number: '',
  seller_invoice_reference: '',
  seller_invoice_date: new Date(),
  waste_type: '',
  unloading_quantity: '',
};

// Good Recived Note fields
export const additionalDocumentInfo = {
  reference: '',
  label: '',
  document: '',
  document_id: '',
  document_file_name: '',
};

export const errorJson = {
  onBehalfName: '',
  category: '',
  utility: '',
  material_type: '',
  materialName: '',
  hsn_code: '',
  waste_description: '',
  buyerName: '',
  buyer_category: '',
  buyer_mobile_no: '',
  seller_mobile_no: '',
  buyer_email_id: '',
  seller_email_id: '',
  buyer_address: '',
  sellerName: '',
  seller_address: '',
  seller_category: '',
  date: '',
  quantity: '',
  reference: '',
  payment_reference: '',
  payment_amount: '',
  payment_date: new Date(),
  loadingSlipDetails: {
    quantity: '',
    reference: '',
    date: '',
  },
  unLoadingSlipDetails: {
    quantity: '',
    reference: '',
    date: '',
  },
  ewayLorryReceipt: {
    quantity: '',
    reference: '',
    date: '',
    carrier_registration_no: '',
    transport_name: '',
    transport_address: '',
  },
  debitNote: {
    quantity_return: '',
  },
  goodNote: {
    reference: '',
    quantity: '',
  },
  goodDetails: {
    material_transferred: '',
    material_form: '',
    packaging_form: '',
  },
  outputDetails: {
    material_description: '',
    material_quantity: '',
  },
  otherDetails: {
    po_referece_number_or_link: '',
    buyer_main_address: '',
    seller_main_address: '',
  },
  nextDetails: {
    seller_buyer_name: '',
    PO_SO_reference_number: '',
    seller_invoice_reference: '',
    seller_invoice_date: '',
    waste_type: '',
    unloading_quantity: '',
  },
  previousDetails: {
    seller_buyer_name: '',
    PO_SO_reference_number: '',
    seller_invoice_reference: '',
    seller_invoice_date: '',
    waste_type: '',
    unloading_quantity: '',
  },
  additionalDocuments: [],
  recyclingCertificate: {
    reference: '',
  },
};
