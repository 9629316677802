import { commanLabel } from "../../../constants/comman.constant";
import {
    commanValidation,
} from "../../../constants/comman.validation.constant";

export const remakeUserCreditRequestValidator = (errors: any, userInfo: any) => {
    let tempErrors = { ...errors };
    tempErrors = { ...tempErrors, material_type_id: userInfo.material_type_id ? '' : 'ERRORS.REQUIRED.MATERIAL_TYPE' }
    tempErrors = { ...tempErrors, material_name_type_id: userInfo.material_name_type_id ? '' : 'ERRORS.REQUIRED.SUB_TYPE' }
    tempErrors = { ...tempErrors, region_id: userInfo.region_id ? '' : 'ERRORS.REQUIRED.REGION' }
    if (userInfo.quantity || userInfo.credit_value) {
        let minVal = userInfo.quantity_unit === commanLabel.kgLabel
            ? commanValidation.buyerMinKg
            : commanValidation.buyerMinTonne;
        let value = userInfo.quantity;
        tempErrors = {
            ...tempErrors,
            credit_value: (value < minVal || value > commanValidation.maxQty) ? 'ERRORS.INVALID.TOTAL_CREDIT' : '',
            quantity: (value < minVal || value > commanValidation.maxQty) ? 'ERRORS.INVALID.QUANTITY' : ''
        }
    } else tempErrors = {
        ...tempErrors,
        credit_value: (userInfo.quantity || userInfo.credit_value) ? '' : 'ERRORS.REQUIRED.TOTAL_CREDIT',
        quantity: (userInfo.quantity || userInfo.credit_value) ? '' : 'ERRORS.REQUIRED.QUANTITY'
    }

    return tempErrors;
}