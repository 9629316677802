import { useState, useEffect } from 'react';
import marker1 from '../../svg/1.svg';
import marker2 from '../../svg/2.svg';
import marker3 from '../../svg/3.svg';
import marker4 from '../../svg/4.svg';
import marker5 from '../../svg/5.svg';
import marker6 from '../../svg/6.svg';
import marker7 from '../../svg/7.svg';
import marker8 from '../../svg/8.svg';
import marker9 from '../../svg/9.svg';
import marker10 from '../../svg/10.svg';
import marker11 from '../../svg/11.svg';
import marker12 from '../../svg/12.svg';
import marker13 from '../../svg/13.svg';
import marker14 from '../../svg/14.svg';
import marker15 from '../../svg/15.svg';
import marker16 from '../../svg/16.svg';
import marker17 from '../../svg/17.svg';
import marker18 from '../../svg/18.svg';
import marker19 from '../../svg/19.svg';
import marker20 from '../../svg/20.svg';

import { GoogleMap, DirectionsRenderer, MarkerF } from '@react-google-maps/api';

export interface MapData {
  origin: { lat: number; lng: number };
  destination: { lat: number; lng: number };
  waypoints: any;
  center: { lat: number; lng: number };
  wayPoint: any;
}

function InitMap(props: MapData) {
  console.log('props:::', props);
  const { origin, destination, waypoints, center, wayPoint } = props;
  const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    scaleControl: false,
    scrollwheel: false,
  };
  const [directionsResponse, setDirectionsResponse] = useState<any>(null);
  useEffect(() => {
    calculateRoute();
  }, []);
  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();

    const result = await directionsService.route({
      origin: origin,
      destination: destination,
      waypoints: waypoints,
      travelMode: google.maps.TravelMode.WALKING,
    });

    setDirectionsResponse(result);
  }

  const markerIcon = (index: number) => {
    if (index === 1) {
      return marker1;
    } else if (index === 2) {
      return marker2;
    } else if (index === 3) {
      return marker3;
    } else if (index === 4) {
      return marker4;
    } else if (index === 5) {
      return marker5;
    } else if (index === 6) {
      return marker6;
    } else if (index === 7) {
      return marker7;
    } else if (index === 8) {
      return marker8;
    } else if (index === 9) {
      return marker9;
    } else if (index === 10) {
      return marker10;
    } else if (index === 11) {
      return marker11;
    } else if (index === 12) {
      return marker12;
    } else if (index === 13) {
      return marker13;
    } else if (index === 14) {
      return marker14;
    } else if (index === 15) {
      return marker15;
    } else if (index === 16) {
      return marker16;
    } else if (index === 17) {
      return marker17;
    } else if (index === 18) {
      return marker18;
    } else if (index === 19) {
      return marker19;
    } else if (index === 20) {
      return marker20;
    } else {
      return marker1;
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          visibility: '-moz-initial',
        }}
      >
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '70vh' }}
          zoom={3}
          options={defaultMapOptions}
          center={center}
          id='map'
        >
          {directionsResponse && (
            <DirectionsRenderer
              options={{
                suppressMarkers: true,
              }}
              directions={directionsResponse}
            />
          )}

          <MarkerF
            position={origin}
            icon={{
              url: markerIcon(1),
              anchor: new google.maps.Point(10, 30),
            }}
            // icon="../../svg/5.svg"
          />
          {/* <OriginIcon /> */}
          <MarkerF
            position={destination}
            icon={{
              url: markerIcon(wayPoint.length + 2),
              anchor: new google.maps.Point(10, 30),
            }}
          />
          {wayPoint &&
            wayPoint.length > 0 &&
            wayPoint.map((u: any, i: number) => {
              return (
                <MarkerF
                  key={i}
                  position={u}
                  icon={{
                    url: markerIcon(i + 2) || '',
                    anchor: new google.maps.Point(10, 30),
                  }}
                />
              );
            })}
        </GoogleMap>
      </div>
    </>
  );
}
export default InitMap;
