import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { store } from '../../../app/store';
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { createColumnHelper, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import ViewHistory from './viewHitory';
import ConfirmationPopup from '../../../components/confirmationPopup';
import { useUpdateUserDatabaseStatusMutation } from '../../../app/api/services/userProfile.service';
import { UserProfileStatus } from '../../../shared/remake-green-utils';
import { useParams } from "react-router";
import { validationMessage } from '../../../constants/validation.message.constant';
import { getFileFormat } from '../../../utils/files/commanFilefunction';
import { commanLabel, imagePath } from '../../../constants/comman.constant';
const ViewProfileDatabase = () => {
  const { t: translate } = useTranslation();
  const state = store.getState();
  const [isLoading, setIsLoading] = useState(false);
  const [itemList, setItemList] = useState([]);
  const { user_id } = useParams();
  const columnHelper = createColumnHelper<any>();
  const [selectedTransaction, setSelectedTransaction] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [updateUserDatabaseStatus] = useUpdateUserDatabaseStatusMutation();
  const [value, setValue] = useState({
    regionId: null,
    transactionIds: [] as any,
  });
  
  const columns = [
    columnHelper.accessor("field_name", {
      header: () => `${translate('PROFILE.TITLES.FIELD_NAME')}`,
      cell: (props: any) => {
        const { id } = props?.row?.original;
        return (
          <React.Fragment>
            <div
              className="form-check"
              onClick={() => {
                if (value.transactionIds.length === 0) {
                  setValue({
                    ...value,
                    transactionIds: [id],
                  });
                  setSelectedTransaction([props.row.original]);
                } else {
                  if (value.transactionIds.includes(id)) {
                    let newArray = value.transactionIds.filter(
                      (data: number) => {
                        return data !== id;
                      }
                    );

                    let newArray1 = selectedTransaction.filter((data: any) => {
                      return data.id !== id;
                    });

                    setValue({
                      ...value,
                      transactionIds: newArray,
                    });

                    setSelectedTransaction([...newArray1]);
                  } else {
                    setValue({
                      ...value,
                      transactionIds: [...value.transactionIds, id],
                    });

                    setSelectedTransaction([
                      ...selectedTransaction,
                      props.row.original,
                    ]);
                  }
                }
              }}
            >
              <input
                className="form-check-input"
                type="checkbox"
                id="tr-id-1"
                onChange={() => { }}
                checked={value.transactionIds.includes(id)}
              />
              <label className="form-check-label">
                {displayFn(props?.row?.original.field_name) }
              </label>
            </div>
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor("old_value", {
      header: () => `${translate('PROFILE.TITLES.OLD_VALUE')}`,
      cell: (props: any) => {
        return (
          <React.Fragment>
            {props?.row?.original?.old_value.includes('corpb') &&
              <div className='file-type-container'>
                <div>
                  {getFileFormat(props?.row?.original?.old_value.split('/').pop().split('.').pop()) ===
                    commanLabel.pdf && (
                      <a href={props?.row?.original?.old_value} target={'_blank'}> <img className='file-logo' src={imagePath.pdfPath} alt='' /></a>
                    )}
                  {getFileFormat(props?.row?.original?.old_value.split('/').pop().split('.').pop()) ===
                    commanLabel.png && (
                      <a href={props?.row?.original?.old_value} target={'_blank'}> <img className='file-logo' src={imagePath.jpgPath} alt='' /></a>
                    )}
                  {getFileFormat(props?.row?.original?.old_value.split('/').pop().split('.').pop()) ===
                    commanLabel.jpg && (
                      <a href={props?.row?.original?.old_value} target={'_blank'}><img className='file-logo' src={imagePath.jpgPath} alt='' /></a>
                    )}
                  {getFileFormat(props?.row?.original?.old_value.split('/').pop().split('.').pop()) ===
                    commanLabel.jpeg && (
                      <a href={props?.row?.original?.old_value} target={'_blank'}> <img className='file-logo' src={imagePath.jpgPath} alt='' /></a>
                    )}
                  {getFileFormat(props?.row?.original?.old_value.split('/').pop().split('.').pop()) ===
                    commanLabel.excel && (
                      <a href={props?.row?.original?.old_value} target={'_blank'}><img className='file-logo' src={imagePath.excelPath} alt='' /></a>
                    )}
                  {getFileFormat(props?.row?.original?.old_value.split('/').pop().split('.').pop()) ===
                    commanLabel.docs && (
                      <a href={props?.row?.original?.old_value} target={'_blank'}><img className='file-logo' src={imagePath.docsLogo} alt='' /></a>
                    )}
                </div>
              </div>
            }
            {!props?.row?.original?.old_value.includes('corpb') &&
              <>
                {!props?.row?.original?.new_value.includes('corpb') &&
                  props?.row?.original?.old_value
                }
                {props?.row?.original?.new_value.includes('corpb') &&
                  <>-</>
                }
              </>
            }
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor("new_value", {
      header: () => `${translate('PROFILE.TITLES.NEW_VALUE')}`,
      cell: (props: any) => {
        return (
          <React.Fragment>
            {props?.row?.original?.new_value.includes('corpb') &&
              <div className='file-type-container'>
                <div>
                  {getFileFormat(props?.row?.original?.new_value.split('/').pop().split('.').pop()) ===
                    commanLabel.pdf && (
                      <a href={props?.row?.original?.new_value} target={'_blank'}><img className='file-logo' src={imagePath.pdfPath} alt='' /></a>
                    )}
                  {getFileFormat(props?.row?.original?.new_value.split('/').pop().split('.').pop()) ===
                    commanLabel.png && (
                      <a href={props?.row?.original?.new_value} target={'_blank'}><img className='file-logo' src={imagePath.jpgPath} alt='' /></a>
                    )}
                  {getFileFormat(props?.row?.original?.new_value.split('/').pop().split('.').pop()) ===
                    commanLabel.jpg && (
                      <a href={props?.row?.original?.new_value} target={'_blank'}> <img className='file-logo' src={imagePath.jpgPath} alt='' /></a>
                    )}
                  {getFileFormat(props?.row?.original?.new_value.split('/').pop().split('.').pop()) ===
                    commanLabel.jpeg && (
                      <a href={props?.row?.original?.new_value} target={'_blank'}> <img className='file-logo' src={imagePath.jpgPath} alt='' /></a>
                    )}
                  {getFileFormat(props?.row?.original?.new_value.split('/').pop().split('.').pop()) ===
                    commanLabel.excel && (
                      <a href={props?.row?.original?.new_value} target={'_blank'}><img className='file-logo' src={imagePath.excelPath} alt='' /></a>
                    )}
                  {getFileFormat(props?.row?.original?.new_value.split('/').pop().split('.').pop()) ===
                    commanLabel.docs && (
                      <a href={props?.row?.original?.new_value} target={'_blank'}> <img className='file-logo' src={imagePath.docsLogo} alt='' /></a>
                    )}
                </div>
              </div>
            }
            {!props?.row?.original?.new_value.includes('corpb') &&
              <>
                {!props?.row?.original?.old_value.includes('corpb') &&
                  props?.row?.original?.new_value
                }
                {props?.row?.original?.old_value.includes('corpb') &&
                  <>-</>
                }
              </>
            }
          </React.Fragment>
        );
      },
    }),


  ];
  useEffect(() => {
    getData();
  }, []);
  const displayFn = (item: string) => {
    if (item) {
        item = item.replace(/_/g, ' ');
    } else {
        item = '-';
    }
    return item.charAt(0).toUpperCase() + item.substring(1).toLowerCase();
  }
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });
  const getData = async () => {
    setIsLoading(true);
    const parms = {
      user_id: user_id
    };
    let path = apiUrl.userDatabasePendingList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setIsLoading(false);
      let transaction = res.data.selectList;
      setData([...transaction]);
    } else {
      toast.error(res.message);
      setIsLoading(false);
    }

  };
  const updateStatusRequest = async (status: any) => {
    const response = await updateUserDatabaseStatus({
      fieldIDS: value.transactionIds,
      status: status,
      user_id: user_id,
    }).unwrap();
    if (response.success) {
      toast.success(response.message);
      setSubmitPopup(false);
      setRejectPopup(false);
      setValue({
        regionId: null,
        transactionIds: [] as any,
      })
      getData();
    } else {
      toast.error(response.message);
    }
  };
  return (
    <React.Fragment>
      <main>
        <section className="profile-page admin-profile-changes">
          <div className="container">
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/'>
                    {translate('CREDIT_REQUEST_PAGE.TITLES.HOME')}
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/admin/userManagement'>
                    {translate('USER_MANAGEMENT.TITLES.USER_MANAGEMENT')}
                  </Link>
                </li>
                <li className='breadcrumb-item active'>
                  {translate('PROFILE.TITLES.VIEW_USER_PROFILE')}
                </li>
              </ol>
            </nav>
            <div className="page-top-section">
              <div className="page-header mb-4">
                <h2>{translate('PROFILE.TITLES.USERNAME_PROFILE')}</h2>
              </div>
            </div>
            <div className="display-combine-component">
              <div className="display-component main-profile-head">
                <div className="display-head">
                  <div className="title">
                    {translate('PROFILE.TITLES.PROFILE_CHANGES')}
                  </div>
                  <div className="button-group btn-sm">
                    <button className="btn btn-img-icon btn-md approved-btn" disabled={value.transactionIds.length === 0 ? true : false} onClick={() => setSubmitPopup(true)}><i className="fa-regular fa-circle-check fa-xl me-1"></i> {translate('PROFILE.TITLES.APPROVE')} </button>
                    <button className="btn btn-img-icon btn-md  reject-btn" disabled={value.transactionIds.length === 0 ? true : false} onClick={() => setRejectPopup(true)}><i className="fa-regular fa-circle-xmark fa-xl me-1"></i>{translate('PROFILE.TITLES.REJECTED')} </button>
                  </div>
                </div>

                <div className="display-body ps-0 pe-0">
                  <div className="table-data table-responsive">
                    <table className="table">
                      <>
                        {table.getHeaderGroups().map((headerGroup: any) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header: any) => {
                              return (
                                <th key={header.id} colSpan={header.colSpan}>
                                  {header.isPlaceholder ? null : (
                                    <div
                                      {...{
                                        className: header.column.getCanSort()
                                          ? "cursor-pointer select-none"
                                          : "",
                                        onClick:
                                          header.column.getToggleSortingHandler(),
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                    </div>
                                  )}
                                </th>
                              );
                            })}
                          </tr>
                        ))}
                      </>
                      {data?.length > 0 ? (
                        <tbody>
                          {table.getRowModel().rows.map((row: any) => {
                            return (
                              <tr key={row.id}>
                                {row.getVisibleCells().map((cell: any) => {
                                  return (
                                    <td key={cell.id}>
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>) : (
                        <>
                          <tbody>
                            <tr>
                              <td colSpan={9}>
                                {isLoading && (
                                  <h3 className="center-message-item">
                                    {translate('USER_MANAGEMENT.TITLES.LOADING')}
                                  </h3>
                                )}
                                {data?.length === 0 && !isLoading && (
                                  <h3 className="center-message-item">
                                    {validationMessage.noRecordFound}
                                  </h3>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </>
                      )}
                    </table>
                  </div>

                </div>
              </div>

              <ViewHistory DataFromMain={data}></ViewHistory>
            </div>
          </div>
        </section>
      </main>
      {submitPopup === true && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to approve profile?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setSubmitPopup(false)}
          handleSubmit={() => {
            updateStatusRequest(UserProfileStatus.APPROVED);

          }}
        />
      )}
      {rejectPopup === true && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to Reject Profile?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setRejectPopup(false)}
          handleSubmit={() => {
            updateStatusRequest(UserProfileStatus.REJECTED);
          }}
        />
      )}
    </React.Fragment>
  );

};
export default ViewProfileDatabase;