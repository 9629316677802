import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { imagePath } from "../../../constants/comman.constant";
export const PasswordUpdate = () => {
    const { t: translate } = useTranslation();

    return (
        <>
            <div className="body-content-space pb-0">
                <section className="form">
                    <div className="container">
                        <div className="form-data">
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <form className="image-content-page">
                                        <h2 className="title-main"><span>{translate("FORGOT.TITLES.PASSWORD")} </span> {translate("FORGOT.TITLES.UPDATED")}</h2>
                                        <p className="description">
                                        {translate("FORGOT.TITLES.YOUR_PASSWORD_SUCCESSFULLY")}
                                        </p>
                                        <div className="submit-button">
                                            <Link to="/admin/login" className="btn btn-primary">{translate("BUTTONS.LOGIN")}</Link>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-7  offset-md-1 right-img-form">
                                    <div className="right">
                                        <img src={imagePath.formImgPath} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
};
