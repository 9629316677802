import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const TRANSACTION_SETTING_ACTION = "transaction_setting";

export const transactioSetting = createAsyncThunk(
  TRANSACTION_SETTING_ACTION,
  async (data: any, thunkAPI) => {
    try {
      console.log(data);
      return {data};
    } catch (error: any) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  isFetching: false,
  settingList: {},
};
const settingSlice = createSlice({
  name: "setting",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(transactioSetting.pending, (state) => {
      state.settingList = {};
      state.isFetching = true;
    });
    builder.addCase(transactioSetting.fulfilled, (state, { payload }) => {
      console.log("fulfilled");
      state.settingList = payload.data;
      state.isFetching = false;
      console.log(state);
    });
    builder.addCase(transactioSetting.rejected, (state, action) => {
      state.settingList = {};
      state.isFetching = false;
    });
  },
});
const { reducer } = settingSlice;
export default reducer;
