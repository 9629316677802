import { commanLabel } from "../../../constants/comman.constant";
import { UserType } from "../../../shared/remake-green-utils/common/constants";

// User Basic Details fields
export const userDetails = {
  credit_type: "",
  material_type_id: "",
  material_name_type_id: "",
  region_id: commanLabel.defaultRegionId, // India location selected by default
  quantity: "",
  quantity_unit: commanLabel.tonneLabel,
  credit_value: "",
  preferred_currency: commanLabel.currency,
};

export const errorJson = {
  material_type_id: "",
  material_name_type_id: "",
  region_id: "",
  credit_value: "",
  quantity: "",
};
