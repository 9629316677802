import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { UserProfileStatus } from '../../../../shared/remake-green-utils/common';
import ActionDialog from '../actionDialog';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserStatusMutation } from '../../../../app/api/services/userProfile.service';
import { userType } from '../../../../constants/user.constant';
import { store } from '../../../../app/store';
import ConfirmationPopup from '../../../../components/confirmationPopup';
import { ViewUserProfileDetailValidator } from './ViewUserProfileDetailValidator';
import { commanValidation } from '../../../../constants/comman.validation.constant';
const errorJson = {
  email: '',
  company: '',
  company_identification_number: '',
  gst_vat_number: '',
  user_name: '',
  contact_number: '',
  alternate_email: '',
  preferred_currency: '',
  countryId: '',
  stateId: '',
  cities: '',
  zip_code: '',
  profile_image: '',
};

const ViewUserProfileDetail = ({
  profileDetail,
  updateCompanyProfile,
  updateEditMode,
}: any) => {
  const { t: translate } = useTranslation();
  const {
    user_name,
    contact_number,
    designation,
    preferred_currency,
    referral_code,
    address,
    alternate_email,
    user_company,
    profile_image,
    document_ids,
    user_profile_history_user_profile_history_user_idTouser,
    status,
    action_taken_on,
    rejection_reason,
  }: any = profileDetail?.user_profile || {};
  const [actionPopup, setActionPopup] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [approvePopup, setApprovePopup] = useState({
    isOpen: false,
    reason: null,
  });
  const [updateUserStatus] = useUpdateUserStatusMutation();
  const navigate = useNavigate();
  const state = store.getState();

  const defaultUserDetail: any = {
    email: profileDetail?.email,
    company: user_company?.company_name,
    company_identification_number: user_company?.company_identification_no,
    gst_vat_number: user_company?.gst_vat_no,
    user_name,
    contact_number,
    alternate_email,
    designation,
    preferred_currency,
    company_id: user_company?.id,
    address_id: address?.id,
    billingAddress: address?.address,
    countryId: address?.country?.name,
    stateId: address?.states?.name,
    cities: address?.cities?.name,
    zip_code: address?.zip_code,
    referral_code,
    profile_image,
    document_ids,
    details: [],
    status: status,
    action_taken_on: action_taken_on,
    rejection_reason:
      profileDetail?.user_profile?.status === UserProfileStatus.PENDING
        ? ''
        : rejection_reason,
  };
  const [profileInfo, setProfileInfo] = useState({ ...defaultUserDetail });
  useEffect(() => {
    setProfileInfo({ ...defaultUserDetail });
  }, [profileDetail]);
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not

  const [errors, setErrors] = useState<any>(errorJson);
  const handleChange = (e: any) => {
    setProfileInfo({
      ...profileInfo,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = (individualInfo = profileInfo, formSubmit = false) => {
    const errs: any = ViewUserProfileDetailValidator(errors, individualInfo);
    setErrors(errs);
    return Object.keys(errs).every((err) => errs[err].length === 0);
  };

  const updateStatusRequest = async (
    status: any,
    updateStatus = profileInfo
  ) => {
    if (validateForm(updateStatus, true)) {
      const response = await updateUserStatus({
        reason: updateStatus.rejection_reason,
        status: status,
        user_id: profileDetail?.id,
      }).unwrap();
      if (response.success) {
        toast.success(response.message);
        navigate('/admin/userManagement', {
          state: status,
        });
      } else {
        toast.error(response.message);
      }
    }
  };

  return (
    <React.Fragment>
      {submitPopup === true && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to approve profile?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setSubmitPopup(false)}
          handleSubmit={() => {
            updateStatusRequest(UserProfileStatus.APPROVED);
          }}
        />
      )}

      {rejectPopup === true && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to Reject Profile?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setRejectPopup(false)}
          handleSubmit={() => {
            updateStatusRequest(UserProfileStatus.REJECTED);
          }}
        />
      )}
      {approvePopup.isOpen === true && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to approve profile?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setApprovePopup({ isOpen: false, reason: null })}
          handleSubmit={() => {
            setProfileInfo({
              ...profileInfo,
              ['rejection_reason']: approvePopup.reason,
            });
            updateStatusRequest(UserProfileStatus.APPROVED, {
              ...profileInfo,
              ['rejection_reason']: approvePopup.reason,
            });
            setActionPopup(false);
          }}
        />
      )}
      <div className='display-combine-component profile-detail-only'>
        <div className='display-component main-profile-head'>
          <div className='display-head bg-head'>
            <div className='title'>{profileInfo?.user_name}</div>
            {profileInfo.status === UserProfileStatus.PENDING && (
              <div className='status-action'>
                <span className='status-box status-box-yellow'></span>
                {translate('PROFILE.TITLES.PENDING_FOR_APPROVAL')}
              </div>
            )}
            {profileInfo.status === UserProfileStatus.APPROVED && (
              <div className='status-action'>
                <span className='status-box'></span>
                {translate('PROFILE.TITLES.APPROVED')}
              </div>
            )}
            {profileInfo.status === UserProfileStatus.REJECTED && (
              <>
                <div className='status-action'>
                  <a
                    href='#'
                    className='btn btn-img-icon btn-border-green'
                    onClick={(e: any) => {
                      setActionPopup(true);
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/close-circle-svgrepo-com.svg`}
                      alt='img1'
                    />{' '}
                    {translate('PROFILE.TITLES.APPROVE')}
                  </a>
                  <span className='status-box status-box-red'></span>
                  {translate('PROFILE.TITLES.REJECTED')}
                </div>
              </>
            )}
          </div>
          <div className='display-body'>
            <div className='row mb-10'>
              <div className='col-lg-3 col-md-12'>
                <div className='company-logo'>
                  <div className='item-logo'>
                    <div className='icon-data'>
                      <img
                        src={
                          profileInfo.profile_image
                            ? profileInfo.profile_image
                            : profileDetail?.user_type_id === userType.COMPANY
                            ? `${process.env.PUBLIC_URL}/assets/images/company.svg`
                            : `${process.env.PUBLIC_URL}/assets/images/User_Pic.svg`
                        }
                        className='profile-img'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-9 col-md-12'>
                <div className='action-panel'>
                  <div className='action-panel-head'>
                    <div className='left'>
                      {profileInfo.status === UserProfileStatus.APPROVED && (
                        <h3 className='action-title'>
                          {translate('PROFILE.TITLES.ACTION_TAKEN')}:{' '}
                          <span>{translate('PROFILE.TITLES.APPROVED')}</span>
                        </h3>
                      )}
                      {profileInfo.status === UserProfileStatus.PENDING && (
                        <h3 className='action-title'>
                          {translate('PROFILE.TITLES.REVIEW_AND_TAKE_ACTION')}
                        </h3>
                      )}
                      {profileInfo.status === UserProfileStatus.REJECTED && (
                        <h3 className='action-title'>
                          {translate('PROFILE.TITLES.ACTION_TAKEN')}:{' '}
                          <span>{translate('PROFILE.TITLES.REJECTED')}</span>
                        </h3>
                      )}
                    </div>
                    <div className='right'>
                      <div className='details'>
                        {profileInfo.status === UserProfileStatus.APPROVED && (
                          <>
                            <p>
                              {' '}
                              {translate('PROFILE.TITLES.APPROVED_BY')}:
                              <span> {state.auth.user?.email}</span>
                            </p>
                            <p>
                              {translate('PROFILE.TITLES.APPROVED_ON')}:{' '}
                              <span>
                                {moment(profileInfo.action_taken_on).format(
                                  'DD/MM/YYYY hh:mm A'
                                )}
                              </span>
                            </p>
                          </>
                        )}
                        {profileInfo.status === UserProfileStatus.PENDING && (
                          <>
                            <a
                              href='#'
                              className='btn btn-img-icon'
                              onClick={() => setSubmitPopup(true)}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/check-button-icon.svg`}
                                alt='img1'
                              />
                              {translate('PROFILE.TITLES.APPROVE')}{' '}
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a
                              href='#'
                              className='btn btn-img-icon red_button'
                              onClick={() => setRejectPopup(true)}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/close-circle-svgrepo-com.svg`}
                                alt='img1'
                              />{' '}
                              {translate('PROFILE.TITLES.REJECT')}
                            </a>
                          </>
                        )}
                        {profileInfo.status === UserProfileStatus.REJECTED && (
                          <>
                            <p>
                              {translate('PROFILE.TITLES.REJECTED_BY')}:
                              <span> {state.auth.user?.email}</span>
                            </p>
                            <p>
                              {translate('PROFILE.TITLES.REJECTED_ON')}:{' '}
                              <span>
                                {moment(profileInfo.action_taken_on).format(
                                  'DD/MM/YYYY hh:mm A'
                                )}
                              </span>
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='action-panel-body'>
                    <div className='form-group required'>
                      <label className='form-label label-info '>
                        {translate('PROFILE.TITLES.REASON_OF_ACTION')}
                      </label>

                      <textarea
                        className={`form-control ${
                          profileInfo.status === UserProfileStatus.APPROVED ||
                          profileInfo.status === UserProfileStatus.REJECTED
                            ? 'selected'
                            : ''
                        }`}
                        placeholder=''
                        name='rejection_reason'
                        maxLength={commanValidation.maxLengthReason}
                        rows={4}
                        disabled={
                          profileInfo.status === UserProfileStatus.APPROVED ||
                          profileInfo.status === UserProfileStatus.REJECTED
                        }
                        value={profileInfo.rejection_reason}
                        onChange={handleChange}
                      />
                    </div>
                    {
                      <span className='error'>
                        {translate(errors.rejection_reason)}
                      </span>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-4  col-md-6'>
                <div className='form-group required'>
                  <label className='form-label label-info '>
                    {translate('PROFILE.TITLES.USER_ROLE')}
                    <i
                      className='fa fa-info-circle tooltip-icon-data'
                      title='Would be registered for credit generation'
                    />
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    id='role'
                    name='email'
                    placeholder=''
                    value={
                      profileDetail?.user_type_id === userType.COMPANY
                        ? 'Company'
                        : 'Individual'
                    }
                    disabled
                    onChange={handleChange}
                  />
                  {<span className='error'>{translate(errors.email)}</span>}
                </div>
              </div>

              {profileDetail?.user_type_id === userType.COMPANY && (
                <>
                  <div className='col-lg-4  col-md-6'>
                    <div className='form-group required pending'>
                      <label className='form-label label-info '>
                        {translate('PROFILE.TITLES.COMPANY_NAME')}
                        <i
                          className='fa fa-info-circle tooltip-icon-data'
                          title='Would be registered for credit generation'
                        />
                      </label>
                      <input
                        type='text'
                        className={`form-control selected`}
                        id='company'
                        name='company'
                        placeholder=''
                        value={profileInfo.company}
                        onChange={handleChange}
                      />
                      {
                        <span className='error'>
                          {translate(errors.company)}
                        </span>
                      }
                    </div>
                  </div>

                  <div className='col-lg-4  col-md-6'>
                    <div className='form-group required pending'>
                      <label className='form-label'>
                        {translate(
                          'PROFILE.TITLES.COMPANY_IDENTIFICATION_NUMBER'
                        )}
                      </label>
                      <input
                        type='text'
                        className={`form-control selected`}
                        id='company_identification_number'
                        name='company_identification_number'
                        placeholder=''
                        value={profileInfo.company_identification_number}
                        onChange={handleChange}
                      />

                      {
                        <span className='error'>
                          {translate(errors.company_identification_number)}
                        </span>
                      }
                    </div>
                  </div>
                  <div className='col-lg-4  col-md-6'>
                    <div className='form-group required pending'>
                      <label className='form-label'>
                        {translate('PROFILE.TITLES.GST_VAT_NUMBER')}
                      </label>
                      <input
                        type='text'
                        className={`form-control selected`}
                        id='gst_vat_number'
                        name='gst_vat_number'
                        placeholder=''
                        value={profileInfo.gst_vat_number}
                        onChange={handleChange}
                      />

                      {
                        <span className='error'>
                          {translate(errors.gst_vat_number)}
                        </span>
                      }
                    </div>
                  </div>
                </>
              )}
              <div className='col-lg-4  col-md-6'>
                <div className='form-group required'>
                  <label className='form-label label-info '>
                    {profileDetail?.user_type_id === userType.COMPANY
                      ? translate('PROFILE.TITLES.COMPANY_EMAIL')
                      : translate('PROFILE.TITLES.EMAIL')}
                    <i
                      className='fa fa-info-circle tooltip-icon-data'
                      title='Would be registered for credit generation'
                    />
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    id='email'
                    name='email'
                    placeholder=''
                    value={profileInfo.email}
                    disabled
                    onChange={handleChange}
                  />
                  {<span className='error'>{translate(errors.email)}</span>}
                </div>
              </div>

              <div className='col-lg-4 col-md-6'>
                <div className='form-group required pending'>
                  <label>
                    {profileDetail?.user_type_id === userType.COMPANY
                      ? translate('PROFILE.TITLES.COMPANY_CONTACT_NUMBER')
                      : translate('PROFILE.TITLES.PHONE_NUMBER')}
                  </label>
                  <div className='cell-item'>
                    <span className='prefix'>+91</span>
                    <input
                      type='tel'
                      className={`form-control selected`}
                      name='contact_number'
                      onChange={handleChange}
                      placeholder=''
                      value={profileInfo.contact_number}
                    />

                    {
                      <span className='error'>
                        {translate(errors.contact_number)}
                      </span>
                    }
                  </div>
                </div>
              </div>
              <div className='col-lg-8  col-md-8'>
                <div className='form-group pending'>
                  <label className='form-label label-info '>
                    {translate('PROFILE.TITLES.BILLING_ADDRESS')}
                    <i
                      className='fa fa-info-circle tooltip-icon-data'
                      title='To be used only for Invoice purposes'
                    />
                  </label>

                  <textarea
                    className={`form-control selected`}
                    placeholder='Billing Address'
                    name='billingAddress'
                    maxLength={commanValidation.maxLengthAddress}
                    rows={2}
                    value={profileInfo.billingAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='col-lg-4  col-md-6'>
                <div className='form-group required pending'>
                  <label className='form-label'>
                    {translate('PROFILE.TITLES.COUNTRY')}
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    name='countryId'
                    placeholder=''
                    value={profileInfo.countryId}
                    onChange={handleChange}
                  />

                  {<span className='error'>{translate(errors.countryId)}</span>}
                </div>
              </div>

              <div className='col-lg-4  col-md-6'>
                <div className='form-group required pending'>
                  <label className='form-label'>
                    {translate('COMMONFIELDS.STATE')}
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    name='stateId'
                    placeholder=''
                    value={profileInfo.stateId}
                    onChange={handleChange}
                  />

                  {<span className='error'>{translate(errors.stateId)}</span>}
                </div>
              </div>

              <div className='col-lg-4  col-md-6'>
                <div className='form-group required pending'>
                  <label className='form-label'>
                    {translate('COMMONFIELDS.CITY')}
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    name='cities'
                    placeholder=''
                    value={profileInfo.cities}
                    onChange={handleChange}
                  />
                  {<span className='error'>{translate(errors.cities)}</span>}
                </div>
              </div>

              <div className='col-lg-4 col-md-6 '>
                <div className='form-group required pending'>
                  <label className='form-label'>
                    {translate('PROFILE.TITLES.ZIP_CODE')}
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    name='zip_code'
                    placeholder=''
                    value={profileInfo.zip_code}
                    onChange={handleChange}
                  />

                  {<span className='error'>{translate(errors.zip_code)}</span>}
                </div>
              </div>

              <div className='col-lg-4 col-md-6 '>
                <div className='form-group required pending'>
                  <label className='form-label'>
                    {translate('PROFILE.TITLES.USERNAME')}
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    id='user_name'
                    name='user_name'
                    placeholder=''
                    value={profileInfo.user_name}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />

                  {<span className='error'>{translate(errors.user_name)}</span>}
                </div>
              </div>

              <div className='col-lg-4 col-md-6 '>
                <div className='form-group required pending'>
                  <label className='form-label'>
                    {translate('PROFILE.TITLES.EMAIL_ID')}
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    name='alternate_email'
                    placeholder=''
                    value={profileInfo.alternate_email}
                    onChange={handleChange}
                  />

                  {
                    <span className='error'>
                      {translate(errors.alternate_email)}
                    </span>
                  }
                </div>
              </div>

              <div className='col-lg-4 col-md-6 '>
                <div className='form-group pending'>
                  <label className='form-label'>
                    {translate('PROFILE.TITLES.DESIGNATION')}
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    id='designation'
                    name='designation'
                    placeholder=''
                    value={profileInfo.designation}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='col-lg-4  col-md-6'>
                <div className='form-group required pending'>
                  <label className='form-label'>
                    {translate('PROFILE.TITLES.PREFERRED_CURRENCY')}
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    id='preferred_currency'
                    name='preferred_currency'
                    placeholder=''
                    value={
                      profileInfo?.preferred_currency
                        ? profileInfo?.preferred_currency.toUpperCase()
                        : ''
                    }
                    onChange={handleChange}
                  />
                  {
                    <span className='error'>
                      {translate(errors.preferred_currency)}
                    </span>
                  }
                </div>
              </div>

              <div className='col-lg-4 col-md-6 '>
                <div className='form-group pending'>
                  <label className='form-label'>
                    {translate('PROFILE.TITLES.REERRAL_CODE')}
                  </label>
                  <input
                    type='text'
                    className={`form-control selected`}
                    id='referral_code'
                    name='referral_code'
                    placeholder=''
                    value={profileInfo.referral_code}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {actionPopup ? (
        <ActionDialog
          onProceed={(resion: any) => {
            setApprovePopup({ isOpen: true, reason: resion });
          }}
          onCancel={() => {
            setActionPopup(false);
          }}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  );
};
export default ViewUserProfileDetail;
