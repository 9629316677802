import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { routesConstant } from '../../../constants/comman.constant';
import { GoogleMap, MarkerF, Polygon } from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import axios from 'axios';

const addressTypes = [
  'locality',
  'administrative_area_level_2',
  'administrative_area_level_1',
  'postal_code',
  'country',
] as const;

export type AddressType = (typeof addressTypes)[number];

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
export interface LocationPickerData {
  title: string;
  setMarkerLocation: any;
  markerLocation: any;
  show: boolean;
  setShow: any;
  locationText: string;
  setLocationText: any;
}
export interface ConfirmationData {
  handleCancel: any;
  handleSubmit: any;
  title: string;
  cancelText?: string;
  submitText?: string;
  hideCancelButton?: boolean;
  eventDetails?: any;
  setEventDetails?: any;
  preview?: boolean;
}

const ConfirmPopup = (props: ConfirmationData) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [markerLocation, setmarkerLocation] = useState({ lat: 0, lng: 0 });
  const [selectedCoordinates, setSelectedCoordinates] = useState<any>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ requestOptions: {}, debounce: 300 });
  const { t: translate } = useTranslation();
  const handleClose = () => {
    setShow(false);
    props.handleCancel();
  };
  const handleOk = () => {
    setShow(false);
    props.handleSubmit();
  };
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (props.preview) {
      setSelectedCoordinates(props.eventDetails.geo_coordinates);
      setmarkerLocation(props.eventDetails.geo_coordinates[0]);
    }
  }, [props.preview]);

  const handleSelect = (suggestion: any) => {
    // console.log(suggestion);

    setValue(suggestion.description, false);
    // props.setLocationText(suggestion.description);
    // Get latitude and longitude via utility functions
    getGeocode({ address: suggestion.description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setmarkerLocation({ lat: lat, lng: lng });
        clearSuggestions();
        setValue('');
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  const renderSuggestions = () => {
    return (
      <div
        style={{ maxHeight: '250px', minHeight: '200px', overflowY: 'auto' }}
      >
        {data.map((suggestion, index) => {
          const {
            structured_formatting: { main_text, secondary_text },
          } = suggestion;
          return (
            <div
              key={index}
              onClick={() => handleSelect(suggestion)}
              style={{
                // display: "flex",
                // flexDirection: "row",
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginTop: '2%',
                paddingBottom: '3%',
                borderBottom: '1px solid #c4c4c4',
                // fontWeight: 300,
                fontSize: '0.75rem',
                height: 'auto',
                padding: '10px 10px ',
              }}
            >
              <span>{main_text}</span>
              <br />
              <span style={{ fontWeight: 300, opacity: 300 }}>
                {secondary_text}
              </span>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <>
      <Modal
        className='modal small-popup entry-popup'
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='usermanagement-modal pb-3 pt-4'>
          {props.preview == false && !showPreview ? (
            <>
              {markerLocation.lat === 0 ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      border: '1px solid #9a9a9a',
                      borderRadius: '20px',
                      height: '2.675rem',
                      marginTop: '1rem',
                      marginBottom: '2%',
                    }}
                  >
                    <input
                      type='text'
                      value={value}
                      onChange={handleInput}
                      disabled={!ready}
                      placeholder='Search Location'
                      onFocus={() => setIsSearch(true)}
                      ref={(input) => input && input.focus()}
                      style={{
                        width: '70%',
                        border: 'transparent',
                        borderRadius: '20px',
                        outline: 'transparent',
                        fontSize: '1rem',
                        fontWeight: '400',
                        paddingLeft: '20px',
                      }}
                    />
                  </div>
                  <div>{renderSuggestions()}</div>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '50vh' }}
                    center={markerLocation}
                    zoom={15}
                  >
                    <MarkerF
                      position={markerLocation}
                      onDragEnd={(e) => {
                        e.latLng &&
                          setmarkerLocation({
                            lat: e.latLng?.lat(),
                            lng: e.latLng?.lng(),
                          });
                      }}
                      draggable
                    />
                  </GoogleMap>
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '50vh' }}
                center={markerLocation}
                zoom={5}
              >
                <Polygon paths={selectedCoordinates} />

                {/* <Polygon paths={selectedCoordinates} /> */}
                {selectedCoordinates.map((u: any, i: any) => {
                  return (
                    <>
                      <MarkerF
                        position={u}
                        // onDragEnd={(e) => {
                        //   e.latLng &&
                        //     setmarkerLocation({
                        //       lat: e.latLng?.lat(),
                        //       lng: e.latLng?.lng(),
                        //     });
                        // }}
                        // draggable
                      />
                    </>
                  );
                })}

                {/* <MarkerF
                  position={markerLocation}
                  onDragEnd={(e) => {
                    e.latLng &&
                      setmarkerLocation({
                        lat: e.latLng?.lat(),
                        lng: e.latLng?.lng(),
                      });
                  }}
                  draggable
                /> */}
              </GoogleMap>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className='pb-4'>
          {!props.preview && !showPreview && markerLocation.lat !== 0 && (
            <>
              <div className='row ms-0 me-0'>
                <div
                  className='col-12 ps-0 pe-0'
                  onClick={() => {
                    setSelectedCoordinates([
                      ...selectedCoordinates,
                      { lat: markerLocation.lat, lng: markerLocation.lng },
                    ]);
                    setmarkerLocation({ lat: 0, lng: 0 });
                  }}
                >
                  <a
                    role='button'
                    // href='/admin/social-welfare/add-activity'
                    className='w-100 btn btn-primary btn-sm btn btn-primary entry-btn'
                  >
                    {translate('CSR_MODULE.TITLES.ADD_ANOTHER_LOCATION')}
                    {/* <i className='fa fa-plus-circle fa-lg ms-2'></i> */}
                  </a>
                </div>
              </div>
              <div className='row ms-0 me-0'>
                <div className='col-12 ps-0 pe-0'>
                  <a
                    role='button'
                    // onClick={() => navigate(routesConstant.addCSREvent)}
                    // href='/admin/social-welfare/add-activity'
                    className='w-100 btn btn-primary btn-sm btn btn-primary entry-btn'
                  >
                    {translate('CSR_MODULE.TITLES.RESELECT_LOCATION')}
                    {/* <i className='fa fa-plus-circle fa-lg ms-2'></i> */}
                  </a>
                </div>
              </div>
              <div className='row ms-0 me-0'>
                <div
                  className='col-12 ps-0 pe-0'
                  onClick={() => {
                    setSelectedCoordinates([
                      ...selectedCoordinates,
                      { lat: markerLocation.lat, lng: markerLocation.lng },
                    ]);
                    setmarkerLocation({ lat: 0, lng: 0 });
                    setShowPreview(true);
                  }}
                >
                  <a
                    role='button'
                    // href='/admin/social-welfare/add-activity'
                    className='w-100 btn btn-primary btn-sm btn btn-primary entry-btn'
                  >
                    {translate('CSR_MODULE.TITLES.PROCEED')}
                    {/* <i className='fa fa-plus-circle fa-lg ms-2'></i> */}
                  </a>
                </div>
              </div>
            </>
          )}

          {/* {showPreview && (
            <div className='row ms-0 me-0'>
              <div
                className='col-12 ps-0 pe-0'
                onClick={() => {
                  handleClose();
                }}
              >
                <a
                  role='button'
                  className='w-100 btn btn-primary btn-sm btn btn-primary entry-btn'
                >
                  {translate('CSR_MODULE.TITLES.PROCEED')}
                </a>
              </div>
            </div>
          )} */}

          {props.preview ||
            (showPreview && (
              <div className='row ms-0 me-0'>
                <div
                  className='col-12 ps-0 pe-0'
                  onClick={() => {
                    if (props.preview) {
                      handleClose();
                    } else if (!showPreview) {
                      setSelectedCoordinates([
                        ...selectedCoordinates,
                        { lat: markerLocation.lat, lng: markerLocation.lng },
                      ]);
                      setmarkerLocation({ lat: 0, lng: 0 });
                      setShowPreview(true);
                    } else {
                      props.setEventDetails({
                        ...props.eventDetails,
                        geo_coordinates: selectedCoordinates,
                      });
                      handleClose();
                    }
                  }}
                >
                  <a
                    role='button'
                    // href='/admin/social-welfare/add-activity'
                    className='w-100 btn btn-primary btn-sm btn btn-primary entry-btn'
                  >
                    {props.preview
                      ? 'OK'
                      : !showPreview
                      ? 'Preview'
                      : translate('CSR_MODULE.TITLES.PROCEED')}
                    {/* <i className='fa fa-plus-circle fa-lg ms-2'></i> */}
                  </a>
                </div>
              </div>
            ))}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ConfirmPopup;
