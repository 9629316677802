import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { toast } from 'react-toastify';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { routesConstant } from '../../../constants/comman.constant';

export interface ConfirmationData {
  handleCancel: any;
  handleSubmit: any;
  title: string;
  cancelText?: string;
  submitText?: string;
  hideCancelButton?: boolean;
}

const ConfirmPopup = (props: ConfirmationData) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [reclaimerName, setReclaimerName] = useState(null);
  const [reclaimerList, setReclaimerList] = useState([]);
  const [aadhaarNo, setAadhaarNo] = useState('');
  const { t: translate } = useTranslation();
  const handleClose = () => {
    setShow(false);
    props.handleCancel();
  };
  const handleOk = () => {
    setShow(false);
    props.handleSubmit();
  };

  const getReclaimerByAadhaar = async (aadhaarNo: string) => {
    let path = apiUrl.getReclaimerByAadhaar + aadhaarNo;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      const newArray =
        temArray &&
        temArray.map((item: any) => {
          return { value: item.id, label: item.aadhaar_no + ' - ' + item.name };
        });

      newArray && setReclaimerList(newArray);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    if (aadhaarNo) {
      getReclaimerByAadhaar(aadhaarNo);
    }
  }, [aadhaarNo]);

  const handleReclaimerName = (option: any) => {
    if (option !== null) {
      if (option.value === option.label) {
        navigate(routesConstant.addActivity, {
          state: option.value,
        });
      } else {
        navigate(routesConstant.addActivity + `/${option.value}`);
      }

      //    setReclaimerList(collectionCenterData);
      setReclaimerName(option);
    }
  };
  return (
    <>
      <Modal
        className='modal small-popup entry-popup'
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='usermanagement-modal pb-3 pt-4'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-12'>
              <div className='form-group required mb-1'>
                <Form.Label>{translate('CSR_MODULE.TITLES.AADHAR_CARD_NO')}</Form.Label>
                <CreatableSelect
                  isClearable
                  value={reclaimerName}
                  options={reclaimerList}
                  onChange={handleReclaimerName}
                  onInputChange={(e) => {
                    e.length >= 4 && setAadhaarNo(e);
                  }}
                  placeholder={`${translate('CSR_MODULE.TITLES.SEARCH_BY_AADHAR_NUMBER')}`}
                />

                <Form.Control
                  tabIndex={-1}
                  type='text'
                  autoComplete='off'
                  value={[]}
                  required
                  style={{ opacity: 0, height: 0, display: 'none' }}
                  onChange={() => {}}
                />
              </div>
              {/* <div className="error-msg text-end">No registered entry found</div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='pb-4'>
          <div className='row ms-0 me-0'>
            <div className='col-12 ps-0 pe-0'>
              <a
                role='button'
                onClick={() => navigate(routesConstant.addActivity)}
                // href='/admin/social-welfare/add-activity'
                className='w-100 btn btn-primary btn-sm btn btn-primary entry-btn'
              >
                {translate('CSR_MODULE.TITLES.NEW_ENTRY')}
                <i className='fa fa-plus-circle fa-lg ms-2'></i>
              </a>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ConfirmPopup;
