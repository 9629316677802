import { category } from "./category.constant";

export const certificateType = {
  COLLECTION: "Collection",
  RECYCLING: "Recycle",
  CIRCULAR: "Circular",
};
export const certificateCategories = {
  [certificateType.COLLECTION]: {
    start: [category.COLLECTOR, category.RECLAIMER],
    end: [category.PROCESSOR],
  },
  [certificateType.RECYCLING]: {
    start: [category.COLLECTOR, category.RECLAIMER, category.PROCESSOR],
    end: [category.RECYCLER],
  },
  [certificateType.CIRCULAR]: {
    start: [category.COLLECTOR, category.RECLAIMER],
    end: [category.FIRSTLEVELCONSUMER, category.SECONDLEVELCONSUMER],
  },
};

export const CertificateType = {
  COLLECTION: 1,
  RECYCLING: 2,
  CIRCULAR: 3,
};

export const CertificateTypes = [
  {
    title: certificateType.COLLECTION,
    value: CertificateType.COLLECTION,
  },
  {
    title: certificateType.RECYCLING,
    value: CertificateType.RECYCLING,
  },
  {
    title: certificateType.CIRCULAR,
    value: CertificateType.CIRCULAR,
  },
];