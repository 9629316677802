import React, { useState, useEffect } from 'react';
import GetGeoCoordinates from './getGeoCoordinatesPopup';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import CreatableSelect from 'react-select/creatable';
import {
  isValidFile,
  socialWelfareTab,
} from '../../../shared/remake-green-utils';
import ConfirmationPopup from '../../../components/confirmationPopup';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { createEventValidator } from './eventValidator';
import { useCreateCSREventMutation } from '../../../app/api/services/csrActivity';
import { routesConstant } from '../../../constants/comman.constant';
const AddEvent = () => {
  const { id } = useParams();

  const { t: translate } = useTranslation();
  // const [addentryPopup, setEntryPopup] = useState(false);
  const [eventCategoryId, setEventCategoryId] = useState('');
  const [eventCategoryData, setEventCategoryData] = useState([]);
  const [addentryPopup, setEntryPopup] = useState({
    preview: false,
    isShow: false,
  });
  const [participatingOrganisations, setParticipatingOrganisations] =
    useState<any>(['']);
  const [countries, setCountries] = useState<any>([]);
  const [documentFiles, setDocumentFiles] = useState<any>([]);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not
  const navigate = useNavigate();
  const [addEvent] = useCreateCSREventMutation();
  const [eventDetails, setEventDetails] = useState({
    name: '',
    start_date: new Date(),
    end_date: new Date(),
    organizer: '',
    participating_organisations: [],
    geo_coordinates: [],
    event_photo: '',
    sub_category: '',
    institute_involved: '',
    region: '',
  });

  const [errors, setErrors] = useState<any>({
    eventCategory: '',
    activityCategory: '',
    eventName: '',
    organizer: '',
    region: '',
  });

  const handleEventDetails = (e: any) => {
    setEventDetails({
      ...eventDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleCoordinatesPopup = (preview: boolean, isShow: boolean) => {
    setEntryPopup({ preview: preview, isShow: isShow });
  };

  const handleStartDate = (date: any) => {
    setEventDetails({ ...eventDetails, start_date: date });
  };
  const handleEndDate = (date: any) => {
    setEventDetails({ ...eventDetails, end_date: date });
  };

  const validateForm = (eventValue = eventDetails, formSubmit = false) => {
    const errs: any = createEventValidator(errors, eventValue);
    setErrors(errs);
    const isFormValid = Object.keys(errs).every(
      (err) => errs[err].length === 0
    );
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      setSubmitPopup(true);
    }
  };

  const getRegions = async () => {
    let path = apiUrl.getRegions;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let regions = res?.data && res?.data?.countries;
      setCountries(regions);
    } else {
      toast.error(res.message);
    }
  };

  const getEventCategoryDetails = async () => {
    let path = apiUrl.getEventCategory;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setEventCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const getFileUploaderData = async (dat: any, name: string) => {
    const tDoc: any = documentFiles || [];
    tDoc.push(dat);

    setDocumentFiles([...tDoc]);
    setEventDetails({
      ...eventDetails,
      event_photo: dat.document_path,
    });
  };

  const handleFileUploder = async (file: any, name: string) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', '');

    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res: any = await fr.response;
    if (res.success) {
      toast.success(res.message);
      await getFileUploaderData(res?.data?.data, name);
    } else {
      toast.error(res.message);
    }
  };

  const handleParticipatingOrg = (index: number, event: any) => {
    let data = [...participatingOrganisations];
    data[index] = event.target.value;
    setParticipatingOrganisations(data);
  };

  const uploadDocument = async (e: any) => {
    let files = e?.target?.files || [];
    if (files[0] > commanValidation.maxFileLength) {
      toast.error(translate('ERRORS.INVALID.MAXIMUM_DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidFile(files[0].type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    await handleFileUploder(files[0], e.target.name);
  };

  const createEvent = async () => {
    let data = {
      categoryId: 1,
      name: eventDetails.name,
      start_date: new Date(eventDetails.start_date),
      end_date: new Date(eventDetails.end_date),
      organizer: eventDetails.organizer,
      participating_organisations: eventDetails.participating_organisations,
      geo_coordinates: eventDetails.geo_coordinates,
      event_photo: eventDetails.event_photo,
      sub_category: eventDetails.sub_category,
      institute_involved: eventDetails.institute_involved,
      regionId: eventDetails.region,
    };

    // const fr: any = await helper.formPost(data, path);
    const res = await addEvent(data).unwrap();
    if (res.success) {
      navigate(routesConstant.CSRActivity, {
        state: socialWelfareTab.EVENT,
      });
      toast.success('Event Created Successfully.');
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    getEventCategoryDetails();
    getRegions();
  }, []);

  return (
    <>
      {addentryPopup.isShow && (
        <GetGeoCoordinates
          title={`${translate('CSR_MODULE.TITLES.GEO_COORDINATES')}`}
          handleCancel={() => handleCoordinatesPopup(false, false)}
          cancelText='Cancel'
          submitText='OK'
          preview={addentryPopup.preview}
          hideCancelButton={true}
          eventDetails={eventDetails}
          setEventDetails={setEventDetails}
          handleSubmit={() => {
            handleCoordinatesPopup(false, false);
          }}
        />
      )}
      {discardPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          message={`${translate('CSR_MODULE.TITLES.DISCARD_TEXT')}`}
          handleCancel={() => setDiscardPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          handleSubmit={() => {
            if (id) {
            } else
              setEventDetails({
                name: '',
                start_date: new Date(),
                end_date: new Date(),
                organizer: '',
                participating_organisations: [],
                geo_coordinates: [],
                event_photo: '',
                sub_category: '',
                institute_involved: '',
                region: '',
              });

            // setSelectedCollectionCenter({
            //   id: null,
            //   name: '',
            // });

            // setCollectionCneterName(null);
            setDocumentFiles([]);
            setDiscardPopup(false);
          }}
        />
      )}

      {submitPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          message={`${translate('CSR_MODULE.TITLES.SUBMIT_MESSAGE')}`}
          handleCancel={() => setSubmitPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.PROCEED')}`}
          handleSubmit={() => {
            // id ? updateReclaimer() :
            createEvent();
            setSubmitPopup(false);
          }}
        />
      )}
      <main>
        <div className='profile-page admin-profile-changes csr-addreclaimer csr-add-entry '>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    {translate('CSR_MODULE.TITLES.HOME')}
                  </a>
                </li>
                <li className='breadcrumb-item'>
                  <a
                    onClick={() => {
                      navigate('/admin/csr-activity', {
                        state: socialWelfareTab.EVENT,
                      });
                    }}
                  >
                    CSR Activity
                  </a>
                </li>
                <li className='breadcrumb-item active'>
                  {translate('CSR_MODULE.TITLES.ADD_EVENT')}
                </li>
              </ol>
            </nav>
            <div className='section-title-main'>
              <div className='title'>
                {translate('CSR_MODULE.TITLES.ADD_EVENT')}
              </div>
            </div>
            <div className='display-combine-component'>
              <div className='display-component main-profile-head'>
                <div className='display-head'>
                  <div className='title'>
                    {translate('CSR_MODULE.TITLES.EVENT_DETAILS')}
                  </div>
                  <div className='form-submit-buttons auto-width-button'>
                    <button
                      type='button'
                      className='btn btn-primary btn-outline-primary btn-sm btn btn-primary'
                      onClick={() => setDiscardPopup(true)}
                    >
                      {translate('CSR_MODULE.TITLES.DISCARD')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm btn btn-primary ms-3'
                      onClick={() => {
                        setIsSubmited(true);
                        validateForm(eventDetails, true);
                        // validateForm(reclaimerDetails, true);
                      }}
                    >
                      {translate('CSR_MODULE.TITLES.SAVE_PROFILE')}
                    </button>
                  </div>
                </div>

                <div className='display-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.EVENT_NAME')}
                        </Form.Label>
                        <input
                          type='text'
                          placeholder='Event name'
                          className='form-control'
                          name='name'
                          value={eventDetails.name}
                          onChange={handleEventDetails}
                        />
                        <span className='error'>{errors.eventName}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Category
                        </label>
                        <select
                          name='gender'
                          className='form-control form-select'
                          onChange={(e: any) =>
                            setEventCategoryId(e.target.value)
                          }
                          value={eventCategoryId}
                        >
                          <option value=''>Select Category</option>
                          {eventCategoryData &&
                            eventCategoryData.length > 0 &&
                            eventCategoryData.map(
                              (eventCategory: any, index: number) => {
                                return (
                                  <option value={eventCategory.id}>
                                    {eventCategory?.name}
                                  </option>
                                );
                              }
                            )}
                        </select>
                        <span className='error'>{errors.eventCategory}</span>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Organiser
                        </label>
                        <input
                          type='text'
                          placeholder='Name of Organiser'
                          className='form-control'
                          name='organizer'
                          value={eventDetails.organizer}
                          onChange={handleEventDetails}
                        />
                        <span className='error'>{errors.organizer}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group date-input'>
                        <label className='form-label form-label'>
                          Start Date
                        </label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            {/* <div className='react-datepicker-wrapper'>
                              <div className='react-datepicker__input-container'>
                                <span
                                  role='alert'
                                  aria-live='polite'
                                  className='react-datepicker__aria-live'
                                ></span>
                                <input
                                  type='text'
                                  placeholder='Select Date'
                                  className='form-control'
                                />
                              </div>
                            </div> */}
                            <div className='date-item-picker'>
                              <div className='date-picker-main'>
                                <DatePicker
                                  selected={eventDetails.start_date}
                                  className='form-control'
                                  placeholderText={
                                    translate(
                                      'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                    ) + ''
                                  }
                                  dateFormat='dd/MM/yyyy'
                                  onChange={handleStartDate}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode='select'
                                  required
                                />
                                {/* {
                            <span className='error'>
                              {translate(errors.loadingSlipDetails.date)}
                            </span>
                          } */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group date-input'>
                        <label className='form-label form-label'>
                          End Date
                        </label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            {/* <div className='react-datepicker-wrapper'>
                              <div className='react-datepicker__input-container'>
                                <span
                                  role='alert'
                                  aria-live='polite'
                                  className='react-datepicker__aria-live'
                                ></span>
                                <input
                                  type='text'
                                  placeholder='Select Date'
                                  className='form-control'
                                />
                              </div>
                            </div> */}
                            <DatePicker
                              selected={eventDetails.end_date}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleEndDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Institution Involved
                        </label>
                        <input
                          type='text'
                          placeholder='Name Of Institution'
                          className='form-control'
                          onChange={handleEventDetails}
                          name='institute_involved'
                          value={eventDetails.institute_involved}
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>Region</label>
                        <Form.Select
                          className='form-control select-data'
                          name='regionId'
                          onChange={(e) => {
                            setEventDetails({
                              ...eventDetails,
                              region: e.target.value,
                            });
                          }}
                          value={eventDetails.region}
                          required
                        >
                          <option value=''>Select Region</option>
                          {countries?.length > 0 &&
                            countries?.map((u: any, i: any) => {
                              return (
                                <option key={i} value={u.id}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </Form.Select>

                        <span className='error'>{errors.region}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <div className='mb-3 upload-section'>
                          <label
                            className='form-label form-label'
                            htmlFor='formFile'
                          >
                            Event Photo/Logo
                          </label>
                          <input
                            name='event_image'
                            accept='.jpg, .jpeg, .png'
                            type='file'
                            id='event_image'
                            onChange={uploadDocument}
                            className='file-input form-control'
                          />

                          <img
                            src='/assets/images/upload-svg.svg'
                            className='upload-btn'
                          ></img>

                          {eventDetails.event_photo && (
                            <div
                              className='upload-icons'
                              style={{ left: '50px' }}
                            >
                              <i
                                className='fa-solid fa-trash-can'
                                onClick={() => {
                                  setEventDetails({
                                    ...eventDetails,
                                    event_photo: '',
                                  });
                                }}
                              ></i>

                              <i
                                className='fa fa-eye ms-2 me-2'
                                aria-hidden='true'
                                onClick={() => {
                                  window.open(eventDetails.event_photo);
                                }}
                              ></i>
                            </div>
                          )}
                        </div>
                        <span className='error'></span>
                      </div>
                    </div>
                    <div className='col-md-4'></div>
                  </div>
                  {/* <div className='row'>
                    <div className='col-md-8'>
                      <div className='row'>
                        <div className='col-md-11'>
                          <div className='form-group required'>
                            <label className='form-label form-label'>
                              Participating Organizations
                            </label>

                            {participatingOrganisations.map(
                              (u: any, i: any) => {
                                return (
                                  <div className='col-md-4'>
                                    {i === 0 && (
                                      <label className='form-label'></label>
                                    )}
                                    <input
                                      style={{ marginTop: '10px' }}
                                      type='text'
                                      value={u}
                                      placeholder=''
                                      onChange={(e) => {
                                        handleParticipatingOrg(i, e);
                                      }}
                                      className='form-control'
                                    />

                                    {i + 1 ==
                                      participatingOrganisations.length && (
                                      <div className='col-md-1'>
                                        <button
                                          type='button'
                                          className='btn-add'
                                          onClick={() =>
                                            setParticipatingOrganisations([
                                              ...participatingOrganisations,
                                              '',
                                            ])
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className='row'>
                    <div className='col-md-8'>
                      <div className='row'>
                        {participatingOrganisations &&
                          participatingOrganisations.map((u: any, i: any) => {
                            return (
                              <>
                                <div className='col-md-11'>
                                  <div className='form-group required required'>
                                    {i == 0 && (
                                      <label className='form-label form-label'>
                                        Participating Organizations
                                      </label>
                                    )}
                                    <input
                                      style={{ marginTop: '10px' }}
                                      type='text'
                                      value={u}
                                      placeholder=''
                                      onChange={(e) => {
                                        handleParticipatingOrg(i, e);
                                      }}
                                      className='form-control'
                                    />
                                    <span className='error'></span>
                                  </div>
                                </div>
                                {participatingOrganisations.length == i + 1 && (
                                  <div className='col-md-1 mt-4'>
                                    {/* {i == 0 && (
                                      <label className='form-label form-label'></label>
                                    )} */}
                                    <button
                                      type='button'
                                      className='btn-add'
                                      onClick={() =>
                                        setParticipatingOrganisations([
                                          ...participatingOrganisations,
                                          '',
                                        ])
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <div>
                          <label className='form-label form-label'>
                            Geo-Cordinates
                          </label>
                        </div>
                        <div className='row'>
                          <div className='col-md-6 mt-4'>
                            <button
                              type='button'
                              className='search-map'
                              onClick={() =>
                                handleCoordinatesPopup(false, true)
                              }
                            >
                              Select on Map
                            </button>
                          </div>

                          {eventDetails.geo_coordinates.length > 0 && (
                            <div className='col-md-6 mt-4'>
                              <button
                                type='button'
                                className='search-map'
                                onClick={() =>
                                  handleCoordinatesPopup(true, true)
                                }
                              >
                                View on Map
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddEvent;
