import React, { useState, useEffect, HTMLProps } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { commanLabel } from '../../../constants/comman.constant';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { validationMessage } from '../../../constants/validation.message.constant';
import moment from 'moment';

const AttachCSRActivity = (props: any) => {
  const { selectedActivities, setSelectedActivities } = props;
  const { t: translate } = useTranslation();
  const columnHelper = createColumnHelper<any>();

  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [activityDateRange, setActivityDateRange] = useState([null, null]);
  const [entryDateRange, setEntryDateRange] = useState([null, null]);
  const [activityStartDate, activityEndDate] = activityDateRange;
  const [materialId, setMaterialId] = useState('');

  const [entryStartDate, entryEndDate] = entryDateRange;
  const [collectionCenterData, setCollectionCenterData] = useState([]);
  const [collectionCenterId, setCollectionCenterId] = useState('');
  const [gender, setGender] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [materialList, setMaterialList] = useState([]);
  const [reclaimerCategoryData, setReclaimerCategoryData] = useState([]);
  const [reclaimerCategoryId, setReclaimerCategoryId] = useState('');
  const [eventCategoryData, setEventCategoryData] = useState([]);
  const [activityCategoryData, setActivityCategoryData] = useState([]);
  const [eventCategoryId, setEventCategoryId] = useState('');
  const [activityCategoryId, setActivityCategoryId] = useState('');
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});

  function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type='checkbox'
        ref={ref}
        className={className + ' cursor-pointer'}
        {...rest}
      />
    );
  }
  const columns = [
    columnHelper.accessor('-', {
      // header: () => '-',
      enableSorting: false,
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      // cell: (props: any) => {
      //   let id = props?.row?.original?.id;
      //   return (
      //     <React.Fragment>
      //       <div className='form-check ps-0'>
      //         <input
      //           className='form-check-input'
      //           type='checkbox'
      //           value=''
      //           id={`defaultCheck${id}`}
      //           checked={selectedActivities.includes(id)}
      //           onClick={() => {
      //             if (selectedActivities.length === 0) {
      //               setSelectedActivities([id]);
      //             } else if (selectedActivities.includes(id)) {
      //               let newArray = selectedActivities;
      //               newArray = newArray.filter((u: any) => u !== id);
      //               setSelectedActivities(newArray);
      //             } else {
      //               setSelectedActivities([...selectedActivities, id]);
      //             }
      //           }}
      //         />
      //         <label
      //           className='form-check-label'
      //           htmlFor={`defaultCheck${id}`}
      //         ></label>
      //       </div>
      //     </React.Fragment>
      //   );
      // },
      cell: ({ row }) => (
        <div className='px-1'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor((row) => `${row?.activity_id}`, {
      id: `${translate('CSR_MODULE.TITLES.ACTIVITY_ID')}`,
      cell: (props: any) => {
        return (
          <>
            {props?.row?.original?.is_bulk_upload && (
              <div
                className='bulk-icons me-2'
                data-bs-toggle='tooltip'
                data-bs-placement='bottom'
                // title={props?.row?.original?.bulk_upload_id}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 24 24'
                >
                  <g
                    id='collection-upload-svgrepo-com'
                    transform='translate(-2 -3)'
                  >
                    <path
                      id='Path_8273'
                      data-name='Path 8273'
                      d='M7.5,16.485V11.759H9.395V14.59H19.5V11.759h1.895v4.726Z'
                      transform='translate(1.447 2.305)'
                      fill='#18a675'
                    />
                    <path
                      id='Path_8274'
                      data-name='Path 8274'
                      d='M11.27,11.2l1.591-1.591v3.974h1.895V9.609L16.346,11.2l1.34-1.34L13.808,5.982,9.93,9.861Z'
                      transform='translate(2.087 0.785)'
                      fill='#18a675'
                    />
                    <path
                      id='Path_8275'
                      data-name='Path 8275'
                      d='M5,3H25.211V23.211H5ZM6.895,4.895H23.316V21.316H6.895Z'
                      transform='translate(0.789 0)'
                      fill='#18a675'
                      fill-rule='evenodd'
                    />
                    <path
                      id='Path_8276'
                      data-name='Path 8276'
                      d='M2,6V26.211H22.211V24.316H3.895V6Z'
                      transform='translate(0 0.789)'
                      fill='#18a675'
                    />
                  </g>
                </svg>
              </div>
            )}
            {props?.row?.original?.activity_id}
          </>
        );
      },
    }),
    columnHelper.accessor(
      (row) => `${row?.other_csr_event && row?.other_csr_event?.name}`,
      {
        id: `${translate('CSR_MODULE.TITLES.EVENT_NAME')}`,
      }
    ),
    columnHelper.accessor(
      (row) => `${row?.other_csr_event && row?.other_csr_event?.cst_event_id}`,
      {
        id: `${translate('CSR_MODULE.TITLES.EVENT_ID')}`,
      }
    ),
    // columnHelper.accessor(
    //   (row) =>
    //     moment(`${row?.entry_date ? row?.entry_date : '-'}`).format(
    //       'DD/MM/YYYY'
    //     ),
    //   {
    //     id: `${translate('CSR_MODULE.TITLES.DATE_OF_ENTRY')}`,
    //   }
    // ),
    columnHelper.accessor(
      (row) =>
        moment(`${row?.activity_date ? row?.activity_date : '-'}`).format(
          'DD/MM/YYYY'
        ),
      {
        id: `${translate('CSR_MODULE.TITLES.DATE_OF_ACTIVITY')}`,
        enableSorting: false,
      }
    ),
    columnHelper.accessor(
      (row) =>
        `${row?.csr_reclaimer_profile && row?.csr_reclaimer_profile?.name}`,
      {
        id: `${translate('CSR_MODULE.TITLES.BENEFICIARY_NAME')}`,
      }
    ),

    columnHelper.accessor(
      (row) =>
        `${
          (row?.csr_reclaimer_profile &&
            row?.csr_reclaimer_profile?.gender == 'male') ||
          row?.csr_reclaimer_profile?.gender == 'Male'
            ? 'M'
            : (row?.csr_reclaimer_profile &&
                row?.csr_reclaimer_profile?.gender == 'female') ||
              row?.csr_reclaimer_profile?.gender == 'Female'
            ? 'F'
            : '-'
        }`,
      {
        id: `${translate('CSR_MODULE.TITLES.GENDER')}`,
      }
    ),
    // columnHelper.accessor('Material Provided', {
    //   cell: (props: any) => {
    //     let id = props?.row?.original?.id;
    //     return (
    //       <React.Fragment>
    //         {props?.row?.original?.csr_material
    //           ?.reduce((final = '', obj: any) => {
    //             return final + obj?.name + ', ';
    //           }, '')
    //           .slice(0, -2) || '-'}
    //       </React.Fragment>
    //     );
    //   },
    // }),
    columnHelper.accessor(
      (row: any) =>
        `${row?.other_csr_event && row?.other_csr_event?.event_category?.name}`,
      {
        id: `${translate('CSR_MODULE.TITLES.EVENT_CATEGORY')}`,
      }
    ),
    columnHelper.accessor(
      (row: any) => `${row?.csr_sub_category && row?.csr_sub_category?.name}`,
      {
        id: `${translate('CSR_MODULE.TITLES.ACTIVITY_CATEGORY')}`,
      }
    ),

    columnHelper.accessor('-', {
      header: () => `${translate('CSR_MODULE.TITLES.ACTIVITY_PHOTO')}`,
      enableSorting: false,
      cell: (props: any) => {
        let id = props?.row?.original?.id;
        return (
          <React.Fragment>
            {props?.row?.original?.activity_photo && (
              <div className='icon-action'>
                <img
                  style={{ cursor: 'pointer' }}
                  src={`${process.env.PUBLIC_URL}/assets/images/eye-icon-green.svg`}
                  alt='#'
                  onClick={() =>
                    window.open(props?.row?.original?.activity_photo)
                  }
                />
              </div>
            )}
          </React.Fragment>
        );
      },
    }),
  ];

  const getReclaimerCategoryDetails = async () => {
    let path = apiUrl.getReclaimerCategory;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setReclaimerCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const getActivityCategoryDetails = async (eventCategoryId: string) => {
    let path = apiUrl.getActivityCategory + eventCategoryId;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setActivityCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const getEventCategoryDetails = async () => {
    let path = apiUrl.getEventCategory;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setEventCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const getData = async (reset: boolean) => {
    setIsLoading(true);
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      // is_linked: false,
      entry_start_date: reset
        ? ''
        : entryDateRange[0] === null
        ? ''
        : getStartDateFilter(entryDateRange[0]),
      entry_end_date: reset
        ? ''
        : entryDateRange[1] === null
        ? ''
        : getEndDateFilter(entryDateRange[1]),
      activity_start_date: reset
        ? ''
        : activityDateRange[0] === null
        ? ''
        : getStartDateFilter(activityDateRange[0]),
      activity_end_date: reset
        ? ''
        : activityDateRange[1] === null
        ? ''
        : getEndDateFilter(activityDateRange[1]),
      search_text: reset ? '' : searchValue,
      gender: reset ? '' : gender,
      is_linked: false,

      // material_id: materialId,
      reclaimer_category_id: reset ? '' : reclaimerCategoryId,
      activity_category_id: reset ? '' : eventCategoryId,
      activity_sub_category_id: reset ? '' : activityCategoryId,
    };
    let path = apiUrl.getCSRActivityList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setActivityData(res.data.data);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData(false);
    getEventCategoryDetails();
    // getCollectionCenterDetails();
    // getCSRMaterials();
  }, [page, pageLimit]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    const rowKeys = Object.keys(rowSelection);
    const actKeys: any = [];
    rowKeys.forEach((aa: string) => {
      if (rowSelection[aa as keyof typeof rowSelection]) {
        const actRow: any = activityData[aa as keyof typeof rowSelection];
        actKeys.push(+actRow?.id);
      }
    });

    setSelectedActivities(actKeys);
  }, [rowSelection]);
  const table = useReactTable({
    data: activityData,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const performSearch = (e: any) => {
    e.preventDefault();
    getData(false);
  };

  const handeleReset = () => {
    setPage(1);
    setEntryDateRange([null, null]);
    setActivityDateRange([null, null]);
    // setCollectionCenterId('');
    setSearchValue('');
    setGender('');
    getData(true);
    setReclaimerCategoryId('');
    setEventCategoryId('');
    setActivityCategoryId('');
  };

  return (
    <div className=''>
      <div className='transaction-table listuser-table'>
        <div className='transaction-table management-screen'>
          <div className='tab-content'>
            <div className='listing-transaction'>
              <form className=''>
                <div className='top-filters'>
                  <div className='date-item-picker item-in'>
                    <div className='date-picker-main'>
                      <div className='react-datepicker-wrapper'>
                        <div className='react-datepicker__input-container '>
                          <DatePicker
                            selectsRange={true}
                            startDate={activityStartDate}
                            endDate={activityEndDate}
                            value={activityStartDate ? activityStartDate : ''}
                            className='form-control'
                            placeholderText='Select Activity Date'
                            dateFormat={commanLabel.dateFormat}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            onChange={(update: any) => {
                              setActivityDateRange(update);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='type-select item-in'>
                    <select
                      id=''
                      name='type_of_material'
                      className='select-layout'
                      value={reclaimerCategoryId}
                      onChange={(e: any) =>
                        setReclaimerCategoryId(e.target.value)
                      }
                    >
                      <option value=''>
                        {translate('CSR_MODULE.TITLES.USER_CATEGORY')}
                      </option>
                      {reclaimerCategoryData &&
                        reclaimerCategoryData.length > 0 &&
                        reclaimerCategoryData.map(
                          (userCategory: any, index: number) => {
                            return (
                              <option value={userCategory.id}>
                                {userCategory?.name}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </div>
                  <div className='type-select item-in'>
                    <select
                      id=''
                      name='type_of_material'
                      className='select-layout'
                      value={eventCategoryId}
                      onChange={async (e: any) => {
                        setEventCategoryId(e.target.value);
                        await getActivityCategoryDetails(e.target.value);
                      }}
                    >
                      <option value=''>
                        {translate('CSR_MODULE.TITLES.EVENT_CATEGORY')}
                      </option>
                      {eventCategoryData &&
                        eventCategoryData.length > 0 &&
                        eventCategoryData.map(
                          (eventCategory: any, index: number) => {
                            return (
                              <option value={eventCategory.id}>
                                {eventCategory?.name}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </div>
                  <div className='type-select item-in'>
                    <select
                      id=''
                      name='type_of_material'
                      className='select-layout'
                      value={activityCategoryId}
                      onChange={(e: any) =>
                        setActivityCategoryId(e.target.value)
                      }
                    >
                      <option value=''>
                        {translate('CSR_MODULE.TITLES.ACTIVITY_CATEGORY')}
                      </option>
                      {activityCategoryData &&
                        activityCategoryData.length > 0 &&
                        activityCategoryData.map(
                          (activtyCategory: any, index: number) => {
                            return (
                              <option value={activtyCategory.id}>
                                {activtyCategory?.name}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </div>
                  <div className='type-select item-in'>
                    <div className='item input-min-width'>
                      <div className='input-search-item form-group'>
                        <label className='searchicon'>
                          <i className='fa fa-search'></i>
                        </label>
                        <input
                          className='form-control'
                          id='searchicon'
                          placeholder='Search'
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <div className='info-icons text-end'>
                          <a
                            href='#'
                            data-bs-toggle='tooltip'
                            data-bs-placement='bottom'
                            title='Enter Aadhaar Card Number / Reclaimer Id / Reclaimer Name / Event Name / Mobile Number / Location'
                          >
                            <i
                              className='fa fa-info-circle'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='search-button item-in'>
                    <Button
                      role='button'
                      onClick={performSearch}
                      title='Search'
                      className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                    >
                      <i className='fa fa-search' aria-hidden='true'></i>
                    </Button>
                    <Button
                      role='button'
                      onClick={handeleReset}
                      title='Reset'
                      type='reset'
                      className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                    >
                      <i className='fa fa-refresh' aria-hidden='true'></i>
                    </Button>
                  </div>
                </div>
              </form>
              <div className='csr-btn-area mt-4 mb-4 row me-0 ms-0 me-sm-0 ms-sm-0 ms-md-0 me-md-0 me-lg-3 ms-lg-3 me-xl-3 ms-xl-3'>
                <div className='col-md-4 d-flex jusitfy-content-center'></div>
              </div>
              <div className='listing-content'>
                <div className='table-list'>
                  <table>
                    <thead>
                      {table.getHeaderGroups().map((headerGroup: any) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header: any) => {
                            return (
                              <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                  <div
                                    {...{
                                      className: header.column.getCanSort()
                                        ? 'cursor-pointer select-none'
                                        : '',
                                      onClick:
                                        header.column.getToggleSortingHandler(),
                                    }}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: ' 🔼',
                                      desc: ' 🔽',
                                    }[header.column.getIsSorted() as string] ??
                                      null}
                                  </div>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>

                    {activityData?.length > 0 ? (
                      <tbody>
                        {table.getRowModel().rows.map((row: any) => {
                          return (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell: any) => {
                                return (
                                  <td key={cell.id}>
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <>
                        <tbody>
                          <tr>
                            <td colSpan={9}>
                              {isLoading && (
                                <h3 className='center-message-item'>
                                  Loading...
                                </h3>
                              )}
                              {activityData?.length === 0 && !isLoading && (
                                <h3 className='center-message-item'>
                                  {validationMessage.noRecordFound}
                                </h3>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </>
                    )}
                  </table>
                </div>

                {activityData?.length > 0 && pageCount > 1 && (
                  <div className='items-center pagination-main gap-2 text-center'>
                    <PaginationControl
                      page={page}
                      between={4}
                      total={totalCount}
                      limit={pageLimit}
                      changePage={(page) => {
                        setPage(page);
                      }}
                      ellipsis={2}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachCSRActivity;
