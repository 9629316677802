export const fileDetails = {
    fileName: '',
    totalCount: '',
    duplicateCount: '',
    startDate: '',
    endDate: '',
    materialType: '',
    subMaterialType: '',
    remarks: '',
    errorCount: '',
    file: '',
    fileSize: ''
}