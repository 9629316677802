import React, { useState } from "react";

export interface CirculatoryParticipant {
    CirculatoryParticipantData: any
}
export const CirculatoryParticipants = (props: CirculatoryParticipant) => {
    const { CirculatoryParticipantData } = props
    const [isCirculatory, setIsCirculatory] = useState(false);
    return (
        <React.Fragment>
            <div className={`product-details ${isCirculatory ? 'toggle' : ''}`} >
                <h3 className="product-title">
                    <div className="title-data">Circulatory Participants</div>
                    <i className="fa-solid fa-circle-chevron-up toggle-data" onClick={(e) => { setIsCirculatory(!isCirculatory) }} />
                </h3>
                <div className="table-wrap" style={{ display: isCirculatory ? 'none' : 'block' }}>
                    <table cellSpacing={0} border={1}>
                        <tbody>
                            {CirculatoryParticipantData.length > 0 && CirculatoryParticipantData.map((item: any, key: any) => {
                                return (
                                    <tr key={key}>
                                        <td>{item?.category}</td>
                                        <td>{item?.nameAddressContact?.name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

