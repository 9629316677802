import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ViewHistory from '../viewHitory';

const UserProfileLog = () => {
  const { t: translate } = useTranslation();
  useEffect(() => {
    document.body.classList.add('body-color');
    return () => {
      document.body.classList.remove('body-color')
    };
  }, [])
  return (
    <React.Fragment>
      <main>
        <section className="profile-page profile-log">
          <div className="container">
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/'>
                    {translate('PROFILE.TITLES.HOME')}
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/remake-user/profile'>
                    {translate('PROFILE.TITLES.MY_PROFILE')}
                  </Link>
                </li>
                <li className='breadcrumb-item active'>
                  {translate('PROFILE.TITLES.PROFILE_LOGS')}
                </li>
              </ol>
            </nav>
            <div className="display-combine-component">
              <div className="display-component main-profile-head">
                <div className="display-head">
                  <div className="title">
                    {translate('PROFILE.TITLES.PAST_HISTORY')}
                  </div>
                  <div className="button-group btn-sm">
                    <Link className="btn  btn-md" to='/remake-user/profile'>
                      {translate('PROFILE.TITLES.MY_PROFILE')}
                    </Link>
                  </div>
                </div>
                <ViewHistory DataFromMain={null} fromUser={true}></ViewHistory>
              </div>
            </div>
          </div>
        </section>
      </main>

    </React.Fragment>
  );

};
export default UserProfileLog;