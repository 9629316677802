import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { PaginationState } from '@tanstack/react-table';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import moment from 'moment';
import {
  commanLabel,
  getAge,
  routesConstant,
} from '../../../constants/comman.constant';
import { socialWelfareTab } from '../../../shared/remake-green-utils';
import { Button } from 'react-bootstrap';
import { validationMessage } from '../../../constants/validation.message.constant';
import { useExportReclaimerPdfMutation } from '../../../app/api/services/socialWelfare';
import AddActivity from '../../../components/addActivityPopup/addActivityFromReclaimer';
import GetGeoCoordinates from './getGeoCoordinatesPopup';

const ViewEvent = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const [data, setData] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchValue, setSearchValue] = useState('');
  const [exportReclaimerPdf] = useExportReclaimerPdfMutation();
  const [addentryPopup, setEntryPopup] = useState({
    preview: false,
    isShow: false,
  });
  const [eventDetails, setEventDetails] = useState({
    name: '',
    start_date: new Date(),
    end_date: new Date(),
    organizer: '',
    participating_organisations: [],
    geo_coordinates: [],
    event_photo: '',
    sub_category: '',
    institute_involved: '',
    region: '',
  });

  const handleCoordinatesPopup = (preview: boolean, isShow: boolean) => {
    setEntryPopup({ preview: preview, isShow: isShow });
  };

  const getEventDetails = async (reset: boolean, id: string) => {
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      // is_linked: false,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),

      search_text: reset ? '' : searchValue,
    };
    let path = apiUrl.getCSREventForCSRActivity + id;
    const fr: any = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setData(res?.data);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
  };

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    if (id) {
      getEventDetails(false, id);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setEventDetails({
        name: data?.name,
        start_date: new Date(data?.start_date),
        end_date: new Date(data?.end_date),
        organizer: data?.organizer,
        participating_organisations: data?.participating_organisations,
        geo_coordinates: JSON.parse(data?.geo_coordinates),
        event_photo: data?.event_photo,
        sub_category: data?.event_category?.name,
        institute_involved: data?.institution_involved,
        region: data?.region?.name,
      });
    }
  }, [data]);

  const performSearch = (e: any) => {
    e.preventDefault();
    id && getEventDetails(false, id);
  };

  const handeleReset = () => {
    setPage(1);
    setDateRange([null, null]);
    setSearchValue('');
    id && getEventDetails(true, id);
  };

  const getExportedPdf = async () => {
    if (data?.activity.length > 0) {
      const res: any = await exportReclaimerPdf({
        reclaimer_id: Number(id),
      }).unwrap();
      if (res.success) {
        let path = apiUrl.prepareFile + res.data.certificateUrl;
        const fr: any = await helper.getData(path);
        const resData = await fr.response;
        if (resData.data) {
          window.open(
            process.env.REACT_APP_BASE_URL_API +
              apiUrl.downloadFile +
              res.data.certificateUrl
          );
          toast.success('Certificate Downloaded');
        } else {
          toast.error(res.message);
        }
      }
    } else {
      toast.warning('There is no activity to download');
    }
  };

  return (
    <>
      {/* {submitPopup && (
        <AddActivity
          title='Add Activity'
          message='Are you sure you want to Discard?'
          handleCancel={() => setSubmitPopup(!submitPopup)}
          cancelText='Cancel'
          submitText='Discard'
          handleSubmit={() => {
            if (id) {
              getEventDetails(false, id);
              setSubmitPopup(false);
            }
          }}
        />
      )} */}

      {addentryPopup.isShow && (
        <GetGeoCoordinates
          title={`${translate('CSR_MODULE.TITLES.GEO_COORDINATES')}`}
          handleCancel={() => handleCoordinatesPopup(false, false)}
          cancelText='Cancel'
          submitText='OK'
          preview={addentryPopup.preview}
          hideCancelButton={true}
          eventDetails={eventDetails}
          setEventDetails={setEventDetails}
          handleSubmit={() => {
            handleCoordinatesPopup(false, false);
          }}
        />
      )}
      <main>
        <div className='profile-page admin-profile-changes csr-addreclaimer csr-add-entry'>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a
                    // href='/'
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className='breadcrumb-item'>
                  <a
                    // href=''
                    onClick={() => {
                      navigate('/admin/csr-activity', {
                        state: socialWelfareTab.EVENT,
                      });
                    }}
                  >
                    CSR Activity
                  </a>
                </li>
                <li className='breadcrumb-item active'>View Event</li>
              </ol>
            </nav>
            <div className='section-title-main'>
              <div className='title'>View Event</div>
            </div>
            <div className='display-combine-component'>
              <div className='display-component main-profile-head'>
                <div className='display-head'>
                  <div className='title'>Event Details</div>
                  {/* <div className='form-submit-buttons auto-width-button'>
                  
                    <button
                      type='button'
                      onClick={() =>
                        navigate(`${routesConstant.editReclaimer}${id}`)
                      }
                      className='btn btn-primary btn-sm btn btn-primary ms-3'
                    >
                      Edit Profile
                    </button>
                  </div> */}
                </div>
                <div className='display-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <label className='form-label'>
                          {translate('CSR_MODULE.TITLES.EVENT_NAME')}
                        </label>
                        <input
                          className='form-control'
                          value={eventDetails.name}
                          disabled
                        />{' '}
                        <span className='error'></span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Category
                        </label>
                        <select
                          name='gender'
                          className='form-control form-select'
                          // value={eventCategoryId}
                          disabled
                        >
                          <option value=''>{eventDetails.sub_category}</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Organiser
                        </label>
                        <input
                          value={eventDetails.organizer}
                          className='form-control'
                          disabled
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group date-input'>
                        <label className='form-label form-label'>
                          Start Date
                        </label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <div className='date-item-picker'>
                              <div className='date-picker-main'>
                                <input
                                  type='text'
                                  value={moment(eventDetails.start_date).format(
                                    'DD-MM-YYYY'
                                  )}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group date-input'>
                        <label className='form-label form-label'>
                          End Date
                        </label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <input
                              type='text'
                              value={moment(eventDetails.end_date).format(
                                'DD-MM-YYYY'
                              )}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Institution Involved
                        </label>
                        <input
                          type='text'
                          placeholder='Name Of Institution'
                          className='form-control'
                          value={eventDetails.institute_involved}
                          disabled
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>Region</label>
                        <Form.Select
                          className='form-control select-data'
                          name='regionId'
                          disabled
                          required
                        >
                          <option value=''>{eventDetails.region}</option>
                        </Form.Select>
                      </div>
                    </div>
                    {eventDetails.event_photo && (
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <div className='mb-3'>
                            <label htmlFor='formFile' className='form-label'>
                              Event Photo/Logo
                            </label>
                            <div
                              className='upload-icons'
                              style={{
                                top: '0px',
                                justifyContent: 'flex-start',
                                marginTop: '10px',
                              }}
                            >
                              <i
                                className='fa fa-eye ms-2 me-2'
                                aria-hidden='true'
                                onClick={() =>
                                  window.open(eventDetails.event_photo)
                                }
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className='col-md-4'></div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <div>
                          <label className='form-label form-label'>
                            Geo-Cordinates
                          </label>
                        </div>
                        <div className='row'>
                          {eventDetails.geo_coordinates.length > 0 && (
                            <div className='col-md-6 mt-4'>
                              <button
                                type='button'
                                className='search-map'
                                onClick={() =>
                                  handleCoordinatesPopup(true, true)
                                }
                              >
                                View on Map
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data?.other_csr_activity?.length > 0 && (
                <div
                  className='display-component table-data-main'
                  style={{ marginTop: 20, borderRadius: 20 }}
                >
                  <div
                    className='row header-area d-flex align-items-center'
                    style={{ border: 'none' }}
                  >
                    <div className='col-lg-3'>
                      <div className='title'>
                        {translate('CSR_MODULE.TITLES.ACTIVITY_LIST')}
                      </div>
                    </div>
                  </div>
                  {
                    <div
                      className='display-body ps-4 pe-4'
                      style={{ borderTop: '2px solid #3E9AB0' }}
                    >
                      <div className=' transaction-table listuser-table '>
                        <div className='tab-content'>
                          <div className='listing-transaction'>
                            <div className='profile-history-main'>
                              {data &&
                              data.other_csr_activity &&
                              data.other_csr_activity.length > 0 ? (
                                <div>
                                  {data.other_csr_activity.map(
                                    (u: any, i: any) => {
                                      return (
                                        <div
                                          className='profile-log-inner'
                                          style={{ marginTop: '10px' }}
                                        >
                                          <div className='log-header'>
                                            Activity Date :{' '}
                                            {moment(u.activity_date).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </div>
                                          <div className='table-responsive pt-4'>
                                            <table className='table'>
                                              <tbody>
                                                <tr>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.BENEFICIARY_NAME'
                                                    )}
                                                  </th>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.BENEFICIARY_ID'
                                                    )}
                                                  </th>
                                                  <th>Birth Date</th>
                                                  <th>Beneficiary Type</th>
                                                  <th>Beneficiary Category</th>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    {
                                                      u.csr_reclaimer_profile
                                                        .name
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      u.csr_reclaimer_profile
                                                        .reclaimer_id
                                                    }
                                                  </td>
                                                  <td>
                                                    {moment(
                                                      u.csr_reclaimer_profile
                                                        .birth_date
                                                    ).format('DD/MM/YYYY')}{' '}
                                                  </td>
                                                  <td>
                                                    {
                                                      u.csr_reclaimer_profile
                                                        ?.reclaimer_user_type
                                                        ?.name
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      u?.csr_reclaimer_profile
                                                        ?.reclaimer_category
                                                        ?.name
                                                    }
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className='table-responsive pb-4'>
                                            <table className='table'>
                                              <tbody>
                                                <tr>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.ACTIVITY_ID'
                                                    )}
                                                  </th>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.ACTIVITY_DATE'
                                                    )}
                                                  </th>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.ACTIVITY_PHOTO'
                                                    )}
                                                  </th>
                                                  <th>Activity Category</th>
                                                </tr>
                                                <tr>
                                                  <td>{u.activity_id}</td>
                                                  <td>
                                                    {moment(
                                                      u.activity_date
                                                    ).format('DD/MM/YYYY')}
                                                  </td>
                                                  <td>
                                                    {u.activity_photo ? (
                                                      <a
                                                        onClick={() =>
                                                          window.open(
                                                            u?.activity_photo
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className='fa fa-eye ms-2 me-2'
                                                          aria-hidden='true'
                                                        ></i>
                                                      </a>
                                                    ) : (
                                                      '-'
                                                    )}
                                                  </td>
                                                  <td>
                                                    {u?.csr_sub_category?.name}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {isLoading && (
                                    <h3 className='center-message-item'>
                                      {translate('CSR_MODULE.TITLES.LOADING')}
                                    </h3>
                                  )}
                                  {data?.activity?.length === 0 &&
                                    !isLoading && (
                                      <h3 className='center-message-item'>
                                        {validationMessage.noRecordFound}
                                      </h3>
                                    )}
                                </div>
                              )}
                            </div>
                            {/* <div className='listing-content'>
                        <div className='table-footer'>
                          <div className='d-flex justify-content-between d-flex align-items-center'>
                            <div className='left-item text-left'>
                              Total Records : 1 / 1
                            </div>
                            <div className='right-item'>
                              <div className='items-center pagination-main gap-2 text-center'>
                                <button className='border rounded p-1'>
                                  &lt;
                                </button>
                                <ul className='pagination pagination-sm'>
                                  <li className='page-item active'>
                                    <span className='page-link'>1</span>
                                  </li>
                                </ul>
                                <button className='border rounded p-1'>
                                  &gt;
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                            {data?.other_csr_activity?.length > 0 && (
                              <div className='items-center pagination-main gap-2 text-center'>
                                <PaginationControl
                                  page={page}
                                  between={4}
                                  total={totalCount}
                                  limit={pageLimit}
                                  changePage={(page) => {
                                    setPage(page);
                                  }}
                                  ellipsis={2}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewEvent;
