import { commanLabel } from "../../../constants/comman.constant";
import {
  commanValidation,
  commonInput,
  emailPatern,
  mobilePatern,
} from "../../../constants/comman.validation.constant";
import {
  RequiredError,
  validationMessage,
} from "../../../constants/validation.message.constant";

export const creditRequestValidator = (errors: any, userInfo: any) => {
  let tempErrors = { ...errors };

  if (userInfo.name) {
    tempErrors = {
      ...tempErrors,
      name: !new RegExp(commonInput).test(userInfo.name)
        ? RequiredError.invalid_name
        : "",
    };
  } else tempErrors = { ...tempErrors, name: RequiredError.name_required };

  if (userInfo.email) {
    tempErrors = {
      ...tempErrors,
      email: !new RegExp(emailPatern).test(userInfo.email)
        ? RequiredError.valid_email
        : "",
    };
  } else tempErrors = { ...tempErrors, email: RequiredError.email };

  if (userInfo.secondary_email) {
    tempErrors = {
      ...tempErrors,
      secondary_email: !new RegExp(emailPatern).test(userInfo.secondary_email)
        ? RequiredError.valid_email
        : "",
    };
  }
  // else tempErrors = { ...tempErrors, secondary_email: RequiredError.email }

  if (userInfo.mobile_no) {
    tempErrors = {
      ...tempErrors,
      mobile_no: !new RegExp(mobilePatern).test(userInfo.mobile_no)
        ? RequiredError.mobile_no_valid
        : "",
    };
  } else tempErrors = { ...tempErrors, mobile_no: RequiredError.mobile };

  if (userInfo.company_name) {
    tempErrors = {
      ...tempErrors,
      company_name: !new RegExp(commonInput).test(userInfo.company_name)
        ? RequiredError.company_name
        : "",
    };
  } else
    tempErrors = {
      ...tempErrors,
      company_name: validationMessage.companyNameRequired,
    };
  tempErrors = {
    ...tempErrors,
    company_identification_number: userInfo.company_identification_number
      ? ""
      : RequiredError.company_identification_number,
  };
  tempErrors = {
    ...tempErrors,
    gst_number: userInfo.gst_number ? "" : RequiredError.gst_number,
  };
  if (userInfo.roleType) {
    tempErrors = {
      ...tempErrors,
      company_name: "",
      company_identification_number: "",
      gst_number: "",
    };
  }
  tempErrors = {
    ...tempErrors,
    billing_address: userInfo.billing_address
      ? ""
      : RequiredError.billing_address,
  };
  tempErrors = {
    ...tempErrors,
    material_type_id: userInfo.material_type_id
      ? ""
      : RequiredError.material_type_id,
  };
  tempErrors = {
    ...tempErrors,
    material_name_type_id: userInfo.material_name_type_id
      ? ""
      : RequiredError.material_name_type_id,
  };
  tempErrors = {
    ...tempErrors,
    region_id: userInfo.region_id ? "" : "ERRORS.REQUIRED.REGION_ID",
  };
  tempErrors = {
    ...tempErrors,
    country_id: userInfo.country_id ? "" : "ERRORS.REQUIRED.COUNTRY_ID",
  };
  tempErrors = {
    ...tempErrors,
    state_id: userInfo.state_id ? "" : "ERRORS.REQUIRED.STATE_ID",
  };
  tempErrors = {
    ...tempErrors,
    city_id: userInfo.city_id ? "" : "ERRORS.REQUIRED.CITY_ID",
  };
  //tempErrors = { ...tempErrors, quantity: (userInfo.quantity || userInfo.credit_value) ? '' : RequiredError.quantity }
  if (userInfo.quantity || userInfo.credit_value) {
    let minVal =
      userInfo.quantity_unit === commanLabel.kgLabel
        ? commanValidation.buyerMinKg
        : commanValidation.buyerMinTonne;
    let value = userInfo.quantity;
    tempErrors = {
      ...tempErrors,
      credit_value:
        value < minVal || value > commanValidation.maxQty
          ? RequiredError.total_credit_validation
          : "",
      quantity:
        value < minVal || value > commanValidation.maxQty
          ? RequiredError.quantity_validation
          : "",
    };
  } else
    tempErrors = {
      ...tempErrors,
      credit_value:
        userInfo.quantity || userInfo.credit_value
          ? ""
          : RequiredError.totalCredit,
      quantity:
        userInfo.quantity || userInfo.credit_value
          ? ""
          : RequiredError.quantity,
    };

  return tempErrors;
};
