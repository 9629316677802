import React from "react";
import { useTranslation } from "react-i18next";
import { Login } from "../../../shared/login";
import { useParams } from "react-router";

export const DocumentLogin = ({
    onLogin
}:{
    onLogin: ()=>void
}) => {   
  const { t: translate } = useTranslation();
  const { id } = useParams();
  const transactionId = id?.split('&')[0] || '';
  const pcId = id?.split('&')[1] || '';
  return (
    <React.Fragment>
        
      <div className={`product-details-panel bg-title-layout`}>
        
        <h3 className="product-title">
          <div className="title-data">{translate("CERTIFICATE_PAGE.TITLES.DOCUMENT_TITLE")}</div>
          <div className="filter-menu">
            
          </div> 
          <div className="right-icon-layout">
          <span>{translate("CERTIFICATE_PAGE.TITLES.DOCUMENT_SUBTITLE_BEFORE_LOGIN")}</span>           
          </div>       
        </h3>
        <div
          className="table-panel"                 
        >
         <Login 
         isLoginFromDocument={true}
         pcId={pcId}
         transactionId={transactionId}
         onLogin={()=>{
            console.log("Document login");
            onLogin();
         }}
         ></Login>
        </div>
      </div>     
    </React.Fragment>
  );
};
