import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
export interface UploadData {
  handleCancel: any;
  handleSubmit: any;
  title: string;
  handleSelectedFile: any;
  onDownloadSample: any;
}

const UploadPopup = (props: UploadData) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    props.handleCancel();
  };
  const { t: translate } = useTranslation();
 
  return (
    <>
      <Modal
        className='modal small-popup entry-popup'
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='usermanagement-modal pb-3 pt-4'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-12'>
              <div className='form-group required mb-1'>
              <div className='attach-document'>
                    <div className='file-drop-area text-center border-design'>
                      <span className='file-msg'>
                        {translate('REGISTER.TITLES.DRAG')} &amp;{' '}
                        {translate('REGISTER.TITLES.DROP_OR')}{' '}
                        <span>{translate('REGISTER.TITLES.BROWSE')}</span>
                        <br />
                        <p className='note'>
                          {translate('REGISTER.TITLES.SUPPORTED_FORMATS')}{' '}
                          {translate(
                            'REGISTER.TITLES.DOCUMENT_FORMATS_BULK_UPLOAD'
                          )}
                        </p>
                      </span>
                      <input
                        className='file-input'
                        type='file'
                        name='excel_import'
                        id='excel_import'
                        accept='.xlsx'
                        onChange={(e) => {
                            props.handleSelectedFile(e);
                            handleClose();
                        }}
                      />
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='pb-4'>
          <div className='row ms-0 me-0'>
            <div className='col-12 ps-0 pe-0'>
              <a
                role='button'
                onClick={() => props.onDownloadSample()}
                className='w-100 btn btn-primary btn-sm btn btn-primary entry-btn'
              >
                {translate('CSR_MODULE.TITLES.DOWNLOAD_SAMPLE')}
              </a>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UploadPopup;