import {
  commanValidation,
  commonInput,
  emailPatern,
  mobilePatern,
} from '../../../../constants/comman.validation.constant';

export const companyProfileValidator = (
  errors: any,
  companyProfileInfo: any
) => {
  let tempErrors = { ...errors };
  const getExpressionError = (
    value: any,
    expPattern: any,
    invalidErr: any,
    requiredErr?: any
  ) => {
    return value
      ? !new RegExp(expPattern).test(value)
        ? invalidErr
        : ''
      : requiredErr
      ? requiredErr
      : '';
  };
  tempErrors = {
    ...tempErrors,
    // profile_image: companyProfileInfo?.profile_image ? '' : 'ERRORS.REQUIRED.PROFILE_IMAGE',
    company: companyProfileInfo.company_name ? '' : 'ERRORS.REQUIRED.COMPANY',
    company_identification_number: companyProfileInfo.company_identification_number
      ? ''
      : 'ERRORS.REQUIRED.COMPANY_IDENTIFICATION_NUMBER',
    gst_vat_number: companyProfileInfo.gst_vat_number
      ? ''
      : 'ERRORS.REQUIRED.GST_NUMBER',
    user_name: getExpressionError(
      companyProfileInfo.user_name,
      commonInput,
      'ERRORS.INVALID.USERNAME',
      'ERRORS.REQUIRED.USERNAME'
    ),
    contact_number: getExpressionError(
      companyProfileInfo.contact_number,
      mobilePatern,
      'ERRORS.INVALID.CONTACT_NO',
      'ERRORS.REQUIRED.CONTACT_NO'
    ),
    email: getExpressionError(
      companyProfileInfo.email,
      emailPatern,
      'ERRORS.INVALID.EMAIL_ADDRESS',
      'ERRORS.REQUIRED.EMAIL'
    ),
    alternate_email: getExpressionError(
      companyProfileInfo.alternate_email,
      emailPatern,
      'ERRORS.INVALID.EMAIL_ADDRESS'
    ),
    preferred_currency: companyProfileInfo.preferred_currency
      ? ''
      : 'ERRORS.REQUIRED.CURRENCY',
    countryId: companyProfileInfo.countryId ? '' : 'ERRORS.REQUIRED.COUNTRY',
    stateId: companyProfileInfo.stateId ? '' : 'ERRORS.REQUIRED.STATE',
    cities: companyProfileInfo.cities ? '' : 'ERRORS.REQUIRED.CITY',
    zip_code: companyProfileInfo.zip_code ? '' : 'ERRORS.REQUIRED.ZIP_CODE',
  };
  return tempErrors;
};
