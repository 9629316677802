import React from 'react';
import { useTranslation } from 'react-i18next';

const ComponentFrame = ({ children, title, pcrStatus, creditType }: any) => {
  const { t: translate } = useTranslation();

  return (
    <div className='panel-main big-panel panel-generate'>
      <h2 className='panel-title-main'>{title}</h2>
      <div className='panel-box-main'>
        <div className='panel-box'>
          <div className='panel-title'>
            <div className='left'>
              <div className='title'>{title}</div>
            </div>
            <div className='right'>
              <div className='type-detail'>
                <div className='title'>
                  {translate('HOMEPAGE.TITLES.CREDIT_TYPE')}
                </div>
                <div className='description'>{creditType}</div>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ComponentFrame;
