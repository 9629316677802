import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { apiUrl } from '../../app/api/services/apiUrl';
import { helper } from '../../app/api/api.helper';
import DatePicker from 'react-datepicker';
import { isValidFile, socialWelfareTab } from '../../shared/remake-green-utils';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { commanValidation } from '../../constants/comman.validation.constant';
import { useCreateActivityMutation } from '../../app/api/services/socialWelfare';
import { createActivityFromReclaimerValidator } from '../../pages/admin/socialWelfare/activityFromReclaimerValidation';
import moment from 'moment';

export interface ConfirmationData {
  handleCancel: any;
  handleSubmit: any;
  message: string;
  title: string;
  cancelText?: string;
  submitText?: string;
  hideCancelButton?: boolean;
}

const AddActivity = (props: ConfirmationData) => {
  const { id } = useParams();

  const [show, setShow] = useState(true);
  const { t: translate } = useTranslation();
  const [isSubmitted, setIsSubmited] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [eventList, setEventList] = useState([]);

  const [documentFiles, setDocumentFiles] = useState<any>([]);
  const [addActivity] = useCreateActivityMutation();
  const [eventId, setEventId] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState<any>([]);
  const [eventName, setEventName] = useState<any>(null);
  const [eventDetails, setEventDetails] = useState({
    eventName: '',
    eventDate: new Date(),
    isPOCDataProvider: false,
    dataProvider: '',
    organiser: '',
    location: '',
    poc: '',
  });

  const [activityDetails, setActivityDetails] = useState({
    activity_photo: '',
    activity_date: new Date(),
    materialIds: null,
    otherMaterial: '',
    isOtherMaterial: false,
  });

  const [errors, setErrors] = useState<any>({
    eventName: '',
    eventDate: '',
    poc: '',
    dataProvider: '',
    organiser: '',
    location: '',
    otherMaterial: '',
  });

  const handleEventDetails = (e: any) => {
    setEventDetails({
      ...eventDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleActivityDate = (date: any) => {
    setActivityDetails({ ...activityDetails, activity_date: date });
  };

  const handleFileUploder = async (file: any, name: string) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', '');

    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res: any = await fr.response;
    if (res.success) {
      toast.success(res.message);
      await getFileUploaderData(res?.data?.data, name);
    } else {
      toast.error(res.message);
    }
  };

  const getFileUploaderData = async (dat: any, name: string) => {
    const tDoc: any = documentFiles || [];
    tDoc.push(dat);

    setDocumentFiles([...tDoc]);
    setActivityDetails({
      ...activityDetails,
      activity_photo: dat.document_path,
    });
  };

  const uploadDocument = async (e: any) => {
    let files = e?.target?.files || [];
    if (files[0] > commanValidation.maxFileLength) {
      toast.error(translate('ERRORS.INVALID.MAXIMUM_DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidFile(files[0].type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    await handleFileUploder(files[0], e.target.name);
  };

  const handleEventDate = (date: any) => {
    setEventDetails({ ...eventDetails, eventDate: date });
  };

  const createActivity = async () => {
    let data = {
      event_details: {
        ...eventDetails,
        event_date: moment(eventDetails.eventDate).format('YYYY-MM-DD'),
      },
      activity_date: moment(activityDetails.activity_date).format('YYYY-MM-DD'),
      activity_photo: activityDetails.activity_photo,
      materialIds: selectedMaterial,
      csr_event_id: eventId,
      csr_reclaimer_id: id,
      otherMaterial: activityDetails.otherMaterial,
    };

    // const fr: any = await helper.formPost(data, path);
    const res = await addActivity(data).unwrap();
    if (res.success) {
      // navigate(routesConstant.socialWelfare, {
      //   state: socialWelfareTab.ACTIVITIES,
      // });
      //   setShow(false);
      handleOk();
      toast.success('Activity Created Successfully !');
    } else {
      toast.error(res.message);
    }
  };

  const getCSRMaterials = async () => {
    let path = apiUrl.getCSRMaterials;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      setMaterialList(temArray);
    } else {
      toast.error(res.message);
    }
  };

  const getEventDetails = async (id: string) => {
    let path = apiUrl.getEventForActivity + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setEventDetails({
        eventName: res?.data?.name,
        location: res?.data?.location,
        poc: res?.data?.on_ground_poc,
        organiser: res?.data?.organizer,
        dataProvider: res?.data?.data_provider,
        isPOCDataProvider: res?.data?.is_on_ground_poc_data_provider,
        eventDate: new Date(res?.data?.event_date),
      });

      setEventName({
        label: res?.data?.event_id + ' ' + res?.data?.name,
        value: res?.data?.id,
      });
    } else {
      toast.error(res.message);
    }
  };

  const handleEvent = (option: any) => {
    if (option !== null) {
      let event_id = option.value === option.label ? 0 : option.value;
      setEventId(event_id);
      option.value !== option.label && getEventDetails(option.value);
      setEventName(option);
      setEventDetails({ ...eventDetails, eventName: option.label });
    } else {
      setEventName(null);
      setEventId(0);
      setEventDetails({
        eventName: '',
        location: '',
        poc: '',
        organiser: '',
        dataProvider: '',
        isPOCDataProvider: false,
        eventDate: new Date(),
      });
    }
  };

  const getEventList = async () => {
    let path = apiUrl.getEventList;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      const newArray =
        temArray &&
        temArray.map((item: any) => {
          return { value: item.id, label: item.event_id + ' - ' + item.name };
        });
      newArray && setEventList(newArray);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    getEventList();
    getCSRMaterials();
  }, []);

  const handleClose = () => {
    setShow(false);
    props.handleCancel();
  };
  const handleOk = () => {
    setShow(false);
    props.handleSubmit();
  };

  const validateForm = (
    eventValue = eventDetails,
    activityValue = activityDetails,
    formSubmit = false
  ) => {
    let errs: any = {
      ...createActivityFromReclaimerValidator(
        errors,
        eventValue,
        activityValue
      ),
    };
    setErrors(errs);
    // errs = { ...createEventValidator(errors, eventValue) };
    // setErrors(errs);
    const isFormValid = Object.keys(errs).every(
      (err) => errs[err].length === 0
    );
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      createActivity();
      //   setSubmitPopup(false);
      //   setSubmitPopup(true);
    }
  };

  return (
    <>
      <Modal
        className='modal small-popup big-popup'
        show={show}
        onHide={handleClose}
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header> */}
        {/* <Modal.Body> */}
        <div className='body-content-main pc-request'>
          <div className='container' style={{ padding: 0 }}>
            <div className='transaction-table listuser-table csr-addreclaimer add-acitivty'>
              <div className='panel-head-title'>
                <div className='left-content'>
                  <div className='title-normal'>New Activity Details</div>
                </div>
                <div className='right-content'>
                  <div className='form-submit-buttons auto-width-button'>
                    <button
                      type='button'
                      className='btn btn-sm discard'
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Discard
                    </button>
                    <button
                      type='button'
                      className='btn btn-sm save-entry ms-1 ms-sm-3 ms-md-3 ms-lg-3 ms-xl-3'
                      onClick={() => {
                        setIsSubmited(true);
                        validateForm(eventDetails, activityDetails, true);
                      }}
                    >
                      Save Entry
                    </button>
                  </div>
                </div>
              </div>
              <div className='display-component main-profile-head'>
                <div className='activity-main'>
                  <div className='activity-box'>
                    <div className='row d-flex align-items-center'>
                      <div className='col-md-12'>
                        <div className='left-area'>
                          <div className='title'>Event Details</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='panel-body'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group required'>
                          <Form.Label className='form-label'>
                            Event Name
                          </Form.Label>
                          <CreatableSelect
                            isClearable
                            value={eventName}
                            onChange={handleEvent}
                            options={eventList}
                            placeholder='Select Event'
                          />
                          <span className='error'>{errors.eventName}</span>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group date-input'>
                          <Form.Label className='form-label'>
                            Date of Event
                          </Form.Label>
                          <div className='date-item-picker'>
                            <div className='date-picker-main'>
                              <DatePicker
                                selected={eventDetails.eventDate}
                                className='form-control'
                                placeholderText={
                                  translate(
                                    'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                  ) + ''
                                }
                                dateFormat='dd/MM/yyyy'
                                onChange={handleEventDate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                required
                                disabled={eventId !== 0 ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group required required'>
                          <Form.Label className='form-label'>
                            Organiser
                          </Form.Label>
                          <input
                            type='text'
                            placeholder='Enter Organiser Name'
                            className='form-control form-control'
                            name='organiser'
                            value={eventDetails.organiser}
                            onChange={handleEventDetails}
                            disabled={eventId !== 0 ? true : false}
                          />
                          <span className='error'>{errors.organiser}</span>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group required required'>
                          <Form.Label className='form-label'>
                            Name of POC (On-Ground)
                          </Form.Label>
                          <input
                            type='text'
                            className='form-control form-control'
                            placeholder='Enter POC Name'
                            name='poc'
                            value={eventDetails.poc}
                            onChange={handleEventDetails}
                            disabled={eventId !== 0 ? true : false}
                          />
                          <span className='error'>{errors.poc}</span>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group required'>
                          <Form.Label className='form-label'>
                            Is POC a data provider too?
                          </Form.Label>

                          <div className='radio-buttons no-req'>
                            <div
                              className='form-check'
                              onClick={() => {
                                eventId == 0 &&
                                  setEventDetails({
                                    ...eventDetails,
                                    isPOCDataProvider: true,
                                  });
                              }}
                            >
                              <input
                                className='form-check-input'
                                type='radio'
                                name='isPOCDataProvider'
                                id='yes-icon'
                                value={'true'}
                                checked={
                                  eventDetails.isPOCDataProvider === true
                                }
                              />
                              <Form.Label
                                className='form-check-label'
                                htmlFor='yes-icon'
                              >
                                {translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.YES'
                                )}
                              </Form.Label>
                            </div>
                            <div
                              className='form-check'
                              onClick={() =>
                                eventId == 0 &&
                                setEventDetails({
                                  ...eventDetails,
                                  isPOCDataProvider: false,
                                })
                              }
                            >
                              <input
                                className='form-check-input'
                                type='radio'
                                name='isPOCDataProvider'
                                id='no-icon'
                                value={'false'}
                                checked={
                                  eventDetails.isPOCDataProvider === false
                                }
                              />
                              <Form.Label
                                className='form-check-label'
                                htmlFor='no-icon'
                              >
                                {translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.NO'
                                )}
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {eventDetails.isPOCDataProvider === false && (
                        <div className='col-md-4'>
                          <div className='form-group required required'>
                            <Form.Label className='form-label'>
                              Name of Data Provider
                            </Form.Label>
                            <input
                              type='text'
                              className='form-control form-control'
                              placeholder='Enter Data Provider'
                              name='dataProvider'
                              value={eventDetails.dataProvider}
                              onChange={handleEventDetails}
                              disabled={eventId !== 0 ? true : false}
                            />
                            <span className='error'>{errors.dataProvider}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group required required'>
                          <Form.Label className='form-label'>
                            Location
                          </Form.Label>
                          <input
                            type='text'
                            placeholder='Enter Location'
                            className='form-control form-control'
                            value={eventDetails.location}
                            name='location'
                            onChange={handleEventDetails}
                            disabled={eventId !== 0 ? true : false}
                          />
                          <span className='error'>{errors.location}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='activity-box'>
                    <div className='row d-flex align-items-center'>
                      <div className='col-md-12'>
                        <div className='left-area'>
                          <div className='title'>Activity Details</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='panel-body'>
                    <div className='row'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>
                          Material Provided
                        </Form.Label>
                      </div>
                      {materialList.length > 0 &&
                        materialList.map((data: any, i: any) => {
                          const id = data?.id;
                          return (
                            <div className='col-2 col-sm-6 col-md-4 col-lg-3 col-xl-2'>
                              <div className='form-check ps-0'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id={`defaultCheck${id}`}
                                  checked={selectedMaterial.includes(id)}
                                  value=''
                                  onClick={() => {
                                    if (selectedMaterial.length === 0) {
                                      setSelectedMaterial([id]);
                                    } else if (selectedMaterial.includes(id)) {
                                      let newArray = selectedMaterial;
                                      newArray = newArray.filter(
                                        (u: any) => u !== id
                                      );
                                      setSelectedMaterial(newArray);
                                    } else {
                                      setSelectedMaterial([
                                        ...selectedMaterial,
                                        id,
                                      ]);
                                    }
                                  }}
                                />
                                <Form.Label
                                  className='form-check-label'
                                  htmlFor={`defaultCheck${id}`}
                                >
                                  {data.name}
                                </Form.Label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className='row mt-4'>
                      <div className='col-2 col-sm-6 col-md-4 col-lg-3 col-xl-2'>
                        <div className='form-check ps-0'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='isOther'
                            value=''
                            checked={activityDetails.isOtherMaterial}
                            onClick={() => {
                              setActivityDetails({
                                ...activityDetails,
                                isOtherMaterial:
                                  !activityDetails.isOtherMaterial,
                              });
                            }}
                          />
                          <Form.Label
                            className='form-check-label'
                            htmlFor='isOther'
                          >
                            Other...
                          </Form.Label>
                        </div>
                      </div>

                      {activityDetails.isOtherMaterial && (
                        <div className='col-md-3'>
                          <div className='form-group '>
                            <input
                              type='text'
                              className='form-control form-control'
                              placeholder='Enter Material Name'
                              name='otherMaterial'
                              value={activityDetails.otherMaterial}
                              onChange={(e) =>
                                setActivityDetails({
                                  ...activityDetails,
                                  otherMaterial: e.target.value,
                                })
                              }
                            />
                            <span className='error'>
                              {errors.otherMaterial}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='row mt-4'>
                      <div className='col-md-4'>
                        <div className='form-group group-i-input required'>
                          <Form.Label className='form-label'>
                            Date of Activity
                          </Form.Label>
                          <div className='date-item-picker'>
                            <div className='date-picker-main'>
                              <DatePicker
                                selected={activityDetails.activity_date}
                                className='form-control'
                                placeholderText={
                                  translate(
                                    'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                  ) + ''
                                }
                                dateFormat='dd/MM/yyyy'
                                onChange={handleActivityDate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group '>
                          <div className='mb-3'>
                            <Form.Label
                              htmlFor='formFile'
                              className='form-label'
                            >
                              Activity Photo
                            </Form.Label>
                            <Form.Control
                              className='file-input'
                              type='file'
                              onChange={uploadDocument}
                              name='activity_image'
                              id='activity_image'
                              accept='.jpg, .jpeg, .png'
                            />
                            {activityDetails.activity_photo && (
                              <div className='upload-icons'>
                                <i
                                  className='fa-solid fa-trash-can'
                                  onClick={() => {
                                    setActivityDetails({
                                      ...activityDetails,
                                      activity_photo: '',
                                    });
                                  }}
                                ></i>
                                <i
                                  className='fa fa-eye ms-2 me-2'
                                  onClick={() => {
                                    window.open(activityDetails.activity_photo);
                                  }}
                                  aria-hidden='true'
                                ></i>
                              </div>
                            )}
                            {activityDetails.activity_photo && (
                              <p className='upload-note text-end'>
                                File successfully uploaded
                                <svg
                                  width='800px'
                                  height='800px'
                                  viewBox='0 0 1024 1024'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fill='#18A675'
                                    d='M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z'
                                  />
                                </svg>
                              </p>
                            )}
                          </div>
                          {/* <span className='error'>{errors.activityPhoto}</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AddActivity;
