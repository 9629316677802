import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const ActionDialog = ({ onCancel, onProceed }: any) => {
  const { t: translate } = useTranslation();
  const [value, setValue] = useState<any>({ rejection_reason: '' })
  const handleChange = (e: any) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  return <>
    <Modal className="modal fade small-popup form-field-pop" show={true} onHide={onCancel}>
    <Modal.Header closeButton>
          <Modal.Title>{translate('PROFILE.TITLES.APPROVE_REQUEST')}</Modal.Title>
        </Modal.Header>
      <Modal.Body>
        <div className="content-data">
                <div className="form-group required content-textarea">
                  <label className="form-label label-info ">{translate('PROFILE.TITLES.REASON_OF_ACTION')}
                  </label>
                  <textarea
                    className={`form-control `}
                    placeholder=""
                    name="rejection_reason"
                    rows={4}
                    value={value.rejection_reason}
                    onChange={handleChange}
                  />
                </div>
                <div className="button-group-item">
                  <button  className="btn btn-img-icon" disabled={value.rejection_reason === '' ? true : false} onClick={() => onProceed(value.rejection_reason)}><img src={`${process.env.PUBLIC_URL}/assets/images/check-button-icon.svg`} alt="img1" /> {translate('PROFILE.TITLES.APPROVE')} </button>
                </div>
              </div>
      </Modal.Body>
    </Modal>
  </>
};
export default ActionDialog;
