import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "./customFetchBase";

export const plasticCreditApi = createApi({
  reducerPath: "plasticCredit",
  //update baseUrl to be read from env
  baseQuery: customFetchBase,
  endpoints: (builder) => ({        
  }),
});

