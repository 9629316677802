import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { toast } from 'react-toastify';
import { UserProfileStatus } from '../../../shared/remake-green-utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { store } from '../../../app/store';
import moment from 'moment';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { Form } from 'react-bootstrap';
import { commanLabel } from '../../../constants/comman.constant';
import { useApproveOrRejectValueProfileMutation } from '../../../app/api/services/valueChainPartner.service';
import ConfirmationPopup from '../../../components/confirmationPopup';
import ActionDialog from '../viewProfile/actionDialog';


const ViewValueChainProfileDetails = () => {
    const { user_id } = useParams();
    const [profileInfo, setProfileInfo] = useState<any>({});
    const { t: translate } = useTranslation();
    const [submitPopup, setSubmitPopup] = useState(false);
    const state = store.getState();
    const [rejectPopup, setRejectPopup] = useState(false);
    const [approveOrRejectProfile] = useApproveOrRejectValueProfileMutation();
  const [approvePopup, setApprovePopup] = useState({
    isOpen: false,
    reason: null,
  });
  const navigate = useNavigate();
  const [actionPopup, setActionPopup] = useState(false);
  const [errors, setErrors] = useState<any>({
    name: "",
    contact_number: "",
    email: "",
    password:"",
    company_name: "",
    description: "",
    country_id:"",
    state_id:"",
    city_id:"",
    zip_code:"",
    address:""
  });
    useEffect(() => {
        fetchProfile();
    }, []);
    const fetchProfile = async (refresh = false) => {

        let path = apiUrl.getValueChainProfile;
        const param = {
            user_id,
        };
        const fr = await helper.getData(path, param);
        const res = await fr.response;
        if (res.success) {
            setProfileInfo(res.data.userDetail);
        } else {

            toast.error(res.message);
        }
    };
    const handleChange = (e: any) => {
        setProfileInfo({
          ...profileInfo,
          [e.target.name]: e.target.value,
        });
      };
      const updateStatusRequest = async (
        status: any,
        updateStatus = profileInfo
      ) => {
      
          const response = await approveOrRejectProfile({
            reason: updateStatus.rejection_reason,
            status: status,
            id: profileInfo?.id,
          }).unwrap();
          if (response.success) {
            toast.success(response.message);
            navigate('/admin/userManagement', {
              state: status,
            });
          } else {
            toast.error(response.message);
          }
        
      };
    return (
        <React.Fragment>
          {submitPopup === true && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to approve profile?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setSubmitPopup(false)}
          handleSubmit={() => {
            updateStatusRequest(UserProfileStatus.APPROVED);
          }}
        />
      )}

      {rejectPopup === true && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to Reject Profile?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setRejectPopup(false)}
          handleSubmit={() => {
            updateStatusRequest(UserProfileStatus.REJECTED);
          }}
        />
      )}
      {approvePopup.isOpen === true && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to approve profile?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setApprovePopup({ isOpen: false, reason: null })}
          handleSubmit={() => {
            setProfileInfo({
              ...profileInfo,
              ['rejection_reason']: approvePopup.reason,
            });
            updateStatusRequest(UserProfileStatus.APPROVED, {
              ...profileInfo,
              ['rejection_reason']: approvePopup.reason,
            });
            setActionPopup(false);
          }}
        />
      )}
            <main>
                <section className='profile-page '>
                    <div className='container'>
                        <nav aria-label='breadcrumb' className='breadcrumb-main'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to='/'>
                                        {translate('CREDIT_REQUEST_PAGE.TITLES.HOME')}
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link to='/admin/userManagement'>
                                        {translate('USER_MANAGEMENT.TITLES.USER_MANAGEMENT')}
                                    </Link>
                                </li>
                                <li className='breadcrumb-item active'>
                                    {translate('PROFILE.TITLES.VIEW_USER_PROFILE')}
                                </li>
                            </ol>
                        </nav>
                        <div className='display-combine-component'>
                            <div className='display-component main-profile-head'>
                                <div className='display-combine-component profile-detail-only'>
                                    <div className='display-component main-profile-head'>
                                        <div className='display-head bg-head'>
                                            <div className='title'>{profileInfo?.name}</div>
                                            {profileInfo.status === UserProfileStatus.PENDING && (
                                                <div className='status-action'>
                                                    <span className='status-box status-box-yellow'></span>
                                                    {translate('PROFILE.TITLES.PENDING_FOR_APPROVAL')}
                                                </div>
                                            )}
                                            {profileInfo.status === UserProfileStatus.APPROVED && (
                                                <div className='status-action'>
                                                    <span className='status-box'></span>
                                                    {translate('PROFILE.TITLES.APPROVED')}
                                                </div>
                                            )}
                                            {profileInfo.status === UserProfileStatus.REJECTED && (
                                                <>
                                                    <div className='status-action'>
                                                        <a
                                                            href='#'
                                                            className='btn btn-img-icon btn-border-green'
                                                            onClick={(e: any) => {
                                                                //   setActionPopup(true);
                                                            }}
                                                        >
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/close-circle-svgrepo-com.svg`}
                                                                alt='img1'
                                                            />{' '}
                                                            {translate('PROFILE.TITLES.APPROVE')}
                                                        </a>
                                                        <span className='status-box status-box-red'></span>
                                                        {translate('PROFILE.TITLES.REJECTED')}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className='display-body'>
                                        <div className='row mb-10'>
              <div className='col-lg-3 col-md-12'>
                <div className='company-logo'>
                  <div className='item-logo'>
                    <div className='icon-data'>
                      <img
                        src={
                          profileInfo.profile_image
                            ? profileInfo.profile_image
                            : `${process.env.PUBLIC_URL}/assets/images/User_Pic.svg`
                        }
                        className='profile-img'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-9 col-md-12'>
                <div className='action-panel'>
                  <div className='action-panel-head'>
                    <div className='left'>
                      {profileInfo.status === UserProfileStatus.APPROVED && (
                        <h3 className='action-title'>
                          {translate('PROFILE.TITLES.ACTION_TAKEN')}:{' '}
                          <span>{translate('PROFILE.TITLES.APPROVED')}</span>
                        </h3>
                      )}
                      {profileInfo.status === UserProfileStatus.PENDING && (
                        <h3 className='action-title'>
                          {translate('PROFILE.TITLES.REVIEW_AND_TAKE_ACTION')}
                        </h3>
                      )}
                      {profileInfo.status === UserProfileStatus.REJECTED && (
                        <h3 className='action-title'>
                          {translate('PROFILE.TITLES.ACTION_TAKEN')}:{' '}
                          <span>{translate('PROFILE.TITLES.REJECTED')}</span>
                        </h3>
                      )}
                    </div>
                    <div className='right'>
                      <div className='details'>
                        {profileInfo.status === UserProfileStatus.APPROVED && (
                          <>
                            <p>
                              {' '}
                              {translate('PROFILE.TITLES.APPROVED_BY')}:
                              <span> {state.auth.user?.email}</span>
                            </p>
                            <p>
                              {translate('PROFILE.TITLES.APPROVED_ON')}:{' '}
                              <span>
                                {moment(profileInfo.action_taken_on).format(
                                  'DD/MM/YYYY hh:mm A'
                                )}
                              </span>
                            </p>
                          </>
                        )}
                        {profileInfo.status === UserProfileStatus.PENDING && (
                          <>
                            <a
                              href='#'
                              className='btn btn-img-icon'
                              onClick={() => setSubmitPopup(true)}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/check-button-icon.svg`}
                                alt='img1'
                              />
                              {translate('PROFILE.TITLES.APPROVE')}{' '}
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a
                              href='#'
                              className='btn btn-img-icon red_button'
                              onClick={() => setRejectPopup(true)}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/close-circle-svgrepo-com.svg`}
                                alt='img1'
                              />{' '}
                              {translate('PROFILE.TITLES.REJECT')}
                            </a>
                          </>
                        )}
                        {profileInfo.status === UserProfileStatus.REJECTED && (
                          <>
                            <p>
                              {translate('PROFILE.TITLES.REJECTED_BY')}:
                              <span> {state.auth.user?.email}</span>
                            </p>
                            <p>
                              {translate('PROFILE.TITLES.REJECTED_ON')}:{' '}
                              <span>
                                {moment(profileInfo.action_taken_on).format(
                                  'DD/MM/YYYY hh:mm A'
                                )}
                              </span>
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='action-panel-body'>
                    <div className='form-group required'>
                      <label className='form-label label-info '>
                        {translate('PROFILE.TITLES.REASON_OF_ACTION')}
                      </label>

                      <textarea
                        className={`form-control ${
                          profileInfo.status === UserProfileStatus.APPROVED ||
                          profileInfo.status === UserProfileStatus.REJECTED
                            ? 'selected'
                            : ''
                        }`}
                        placeholder=''
                        name='rejection_reason'
                        maxLength={commanValidation.maxLengthReason}
                        rows={4}
                        disabled={
                          profileInfo.status === UserProfileStatus.APPROVED ||
                          profileInfo.status === UserProfileStatus.REJECTED
                        }
                        value={profileInfo.rejection_reason}
                        onChange={handleChange}
                      />
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-12 ">
              <div className="panel-data">
                
                <div className="row">
                
                <div className="my-3">{translate('VALUECHAIN.TITLES.LOGIN_DETAIL')}</div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.EMAIL_ADDRESS')}</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_EMAIL_ADDRESS')}`}
                        name="email"
                        value={profileInfo.email}
                        onChange={handleChange}
                        required
                      />
                      
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.CONTACT_NUMBER')}</Form.Label>
                      <div className="cell-item">
                        <span className="prefix">
                          {commanLabel.countryCode}
                        </span>
                        <input
                          type={"number"}
                          placeholder={`${translate('VALUECHAIN.TITLES.ENTER_CONTACT_NUMBER')}`}
                          className="form-control form-control"
                          name="contact_number"
                          value={profileInfo.contact_number}
                          onChange={handleChange}
                          required
                        />
                       
                      </div>
                    </div>
                  </div>
                  
                  <div className="my-3">{translate('VALUECHAIN.TITLES.PERSONAL_DETAIL')}</div>
                  {/* Personal Details */}
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.NAME')}</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_NAME')}`}
                        name="name"
                        value={profileInfo.name}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {<span className="error">{translate(errors.name)}</span>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <Form.Label>{translate('VALUECHAIN.TITLES.JOB_TITLE')}</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_JOB_TITLE')}`}
                        name="jobtitle"
                        value={profileInfo.jobtitle}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {/* {<span className="error">{translate(errors.jobtitle)}</span>} */}
                    </div>
                  </div>
                  <div className="col-md-4"></div>

                  {/* Organization Detail */}
                  <div className="my-3">{translate('VALUECHAIN.TITLES.ORGANIZATION_DETAIL')}</div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.COMPANY_NAME')}</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_COMPANY_NAME')}`}
                        name="company_name"
                        value={profileInfo.company_name}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {<span className="error">{translate(errors.company_name)}</span>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.ADDRESS')}</Form.Label>
                      <input
                        type="text"
                        className="form-control required"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ADDRESS')}`}
                        name="address"
                        value={profileInfo.address}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {<span className="error">{translate(errors.address)}</span>}
                    </div>
                  </div>

                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.COUNTRY')}
                      </label>
                      <input
                            type='text'
                            className={`form-control selected`}
                            name='countryId'
                            placeholder=''
                            value={profileInfo?.countries?.name}
                            onChange={handleChange}
                  />
                      {
                        <span className='error'>
                          {translate(errors.country_id)}
                        </span>
                      }
                    </div>
                  </div>
                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.STATE')}
                      </label>
                      <input
                            type='text'
                            className={`form-control selected`}
                            name='state_id'
                            placeholder=''
                            value={profileInfo?.states?.name}
                            onChange={handleChange}
                  />
                      {<span className='error'>{translate(errors.state_id)}</span>}
                    </div>
                  </div>

                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.CITY')}
                      </label>
                      <input
                            type='text'
                            className={`form-control selected`}
                            name='city_id'
                            placeholder=''
                            value={profileInfo?.cities?.name}
                            onChange={handleChange}
                  />
                      {<span className='error'>{translate(errors.city_id)}</span>}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group required'>
                      <label>{translate('COMMONFIELDS.ZIP_CODE')}</label>
                      <Form.Control
                        name='zip_code'
                        placeholder={translate('COMMONFIELDS.ENTER_ZIP_CODE') + ''}
                        type='text'
                        className='form-control form-control'
                        maxLength={commanValidation.maxZipCode}
                        value={profileInfo.zip_code}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                      />
                      {<span className='error'>{translate(errors.zip_code)}</span>}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group'>
                      <label>{translate('VALUECHAIN.TITLES.ORGANIZATION_EMAIL')}</label>
                      <Form.Control
                        name='org_email'
                        placeholder={translate('VALUECHAIN.TITLES.ENTER_ORGANIZATION_EMAIL') + ''}
                        type='text'
                        className='form-control form-control'
                        value={profileInfo.org_email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group'>
                      <label>{translate('VALUECHAIN.TITLES.ORGANIZATION_TELEPHONE')}</label>
                      <Form.Control
                        name='org_telephone'
                        placeholder={translate('VALUECHAIN.TITLES.ENTER_ORGANIZATION_TELEPHONE') + ''}
                        type='text'
                        className='form-control form-control'
                        value={profileInfo.org_telephone}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group'>
                      <label>{translate('VALUECHAIN.TITLES.WEBSITE')}</label>
                      <Form.Control
                        name='website'
                        placeholder={translate('VALUECHAIN.TITLES.WEBSITE') + ''}
                        type='text'
                        className='form-control form-control'
                        value={profileInfo.website}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
              

                  <div className="my-3">{translate('VALUECHAIN.TITLES.BILLING_DETAIL')}</div>

                <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.COMPANY_NAME')}</Form.Label>
                      <input
                        type="text"
                        className="form-control required"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ENTER_COMPANY_NAME')}`}
                        name="billing_company_name"
                        value={profileInfo.billing_company_name}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {/* {<span className="error">{translate(errors.billing_company_name)}</span>} */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group required">
                      <Form.Label>{translate('VALUECHAIN.TITLES.ADDRESS')}</Form.Label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder={`${translate('VALUECHAIN.TITLES.ADDRESS')}`}
                        name="billing_address"
                        value={profileInfo.billing_address}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                      {/* {<span className="error">{translate(errors.billing_address)}</span>} */}
                    </div>
                  </div>

                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.COUNTRY')}
                      </label>
                      <input
                            type='text'
                            className={`form-control selected`}
                            name='billing_country_id'
                            placeholder=''
                            value={profileInfo?.billing_countries?.name}
                            onChange={handleChange}
                  />
                      {
                        <span className='error'>
                          {translate(errors.billing_country_id)}
                        </span>
                      }
                    </div>
                  </div>
                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.STATE')}
                      </label>
                      <input
                            type='text'
                            className={`form-control selected`}
                            name='billing_state_id'
                            placeholder=''
                            value={profileInfo?.billing_states?.name}
                            onChange={handleChange}
                  />
                      {/* {<span className='error'>{translate(errors.billing_state_id)}</span>} */}
                    </div>
                  </div>

                  <div className='col-md-4  col-sm-6'>
                    <div className='form-group required'>
                      <label className='form-label'>
                        {translate('COMMONFIELDS.CITY')}
                      </label>
                      <input
                            type='text'
                            className={`form-control selected`}
                            name='billing_city_id'
                            placeholder=''
                            value={profileInfo?.billing_cities?.name}
                            onChange={handleChange}
                  />
                      {/* {<span className='error'>{translate(errors.billing_city_id)}</span>} */}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group required'>
                      <label>{translate('COMMONFIELDS.ZIP_CODE')}</label>
                      <Form.Control
                        name='billing_zip_code'
                        placeholder={translate('COMMONFIELDS.ENTER_ZIP_CODE') + ''}
                        type='text'
                        className='form-control form-control'
                        maxLength={commanValidation.maxZipCode}
                        value={profileInfo.billing_zip_code}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                      />
                      {/* {<span className='error'>{translate(errors.billing_zip_code)}</span>} */}
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group required'>
                      <label>{translate('VALUECHAIN.TITLES.ORGANIZATION_EMAIL')}</label>
                      <Form.Control
                        name='billing_org_email'
                        placeholder={translate('VALUECHAIN.TITLES.ENTER_ORGANIZATION_EMAIL') + ''}
                        type='text'
                        className='form-control form-control'
                        value={profileInfo.billing_org_email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6'>
                    <div className='form-group required'>
                      <label>{translate('VALUECHAIN.TITLES.ORGANIZATION_TELEPHONE')}</label>
                      <Form.Control
                        name='billing_org_telephone'
                        placeholder={translate('VALUECHAIN.TITLES.ENTER_ORGANIZATION_TELEPHONE') + ''}
                        type='text'
                        className='form-control form-control'
                        value={profileInfo.billing_org_telephone}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>

              
                </div>
              </div>
            </div>
          
          </div>




           






                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {actionPopup ? (
        <ActionDialog
          onProceed={(resion: any) => {
            setApprovePopup({ isOpen: true, reason: resion });
          }}
          onCancel={() => {
            setActionPopup(false);
          }}
        />
      ) : (
        ''
      )}
        </React.Fragment>
    );
};
export default ViewValueChainProfileDetails;
