import {
  commonInput,
  emailPatern,
  mobilePatern,
  commanValidation,
  aadhaarNumber,
} from '../../../constants/comman.validation.constant';
import {
  validationMessage,
  RequiredError,
} from '../../../constants/validation.message.constant';
import { commanLabel } from '../../../constants/comman.constant';

export const createReclaimerValidator = (errors: any, reclaimerInfo: any) => {
  let tempErrors = { ...errors };

  if (reclaimerInfo.reclaimerName) {
    if (!commonInput.test(reclaimerInfo.reclaimerName)) {
      tempErrors = {
        ...tempErrors,
        reclaimerName: validationMessage.invalidReclaimerName,
      };
    } else {
      tempErrors = { ...tempErrors, reclaimerName: '' };
    }
  } else
    tempErrors = { ...tempErrors, reclaimerName: RequiredError.reclaimerName };

  if (reclaimerInfo.gender === '') {
    tempErrors = { ...tempErrors, gender: RequiredError.gender };
  } else tempErrors = { ...tempErrors, gender: '' };

  if (reclaimerInfo.childrens === '') {
    tempErrors = {
      ...tempErrors,
      childrens: RequiredError.childrens,
    };
  } else tempErrors = { ...tempErrors, childrens: '' };

  // if (reclaimerInfo.aadhaarPhoto === '') {
  //   tempErrors = {
  //     ...tempErrors,
  //     aadhaarPhoto: RequiredError.aadhaarPhoto,
  //   };
  // } else tempErrors = { ...tempErrors, aadhaarPhoto: '' };
  if (reclaimerInfo.aadhaarNo) {
    if (!aadhaarNumber.test(reclaimerInfo.aadhaarNo)) {
      tempErrors = {
        ...tempErrors,
        aadhaarNo: validationMessage.invalidAadhaarNumber,
      };
    } else {
      tempErrors = { ...tempErrors, aadhaarNo: '' };
    }
  } else tempErrors = { ...tempErrors, aadhaarNo: RequiredError.aadhaarNumber };

  return tempErrors;
};
