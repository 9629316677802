import { plasticCreditApi } from '..';
import { ServiceResponseInterface } from '../../types/serverResponse';
import { apiUrl } from './apiUrl';

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({
    createCSRActivity: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.createCSRActivity,
        method: 'POST',
        body: data,
      }),
    }),

    createCSRReclaimer: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.createCSRReclaimer,
        method: 'POST',
        body: data,
      }),
    }),

    createCSREvent: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.createCSREvent,
        method: 'POST',
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCSRActivityMutation,
  useCreateCSRReclaimerMutation,
  useCreateCSREventMutation,
} = extendedApi;
