import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routesConstant } from '../../../constants/comman.constant';
import ExportPopup from './exportPopup';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { commanLabel } from '../../../constants/comman.constant';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { validationMessage } from '../../../constants/validation.message.constant';

const EventList = () => {
  const { t: translate } = useTranslation();
  const columnHelper = createColumnHelper<any>();
  const navigate = useNavigate();
  const [exportPopup, setExportPopup] = useState(false);
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState([]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [eventDateRange, setEventDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [eventStartDate, eventEndDate] = eventDateRange;
  const [searchValue, setSearchValue] = useState('');
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = [
    // columnHelper.accessor('-', {
    //   header: () => '-',
    //   enableSorting: false,
    //   cell: (props: any) => {
    //     let id = props?.row?.original?.id;
    //     return (
    //       <React.Fragment>
    //         <div className='form-check ps-0'>
    //           <input
    //             className='form-check-input'
    //             type='checkbox'
    //             value=''
    //             id='defaultCheck1'
    //           />
    //           <label
    //             className='form-check-label'
    //             htmlFor='defaultCheck1'
    //           ></label>
    //         </div>
    //       </React.Fragment>
    //     );
    //   },
    // }),
    columnHelper.accessor((row) => `${row?.event_id}`, {
      id: `${translate('CSR_MODULE.TITLES.EVENT_ID')}`,
      cell: (props: any) => {
        return (
          <>
            {props?.row?.original?.is_bulk_upload && (
              <div
                className='bulk-icons me-2'
                data-bs-toggle='tooltip'
                data-bs-placement='bottom'
                title={props?.row?.original?.bulk_upload_id}
              ></div>
            )}
            {props?.row?.original?.event_id}
          </>
        );
      },
    }),
    columnHelper.accessor((row) => `${row && row?.name}`, {
      id: `${translate('CSR_MODULE.TITLES.EVENT_NAME')}`,
    }),
    // columnHelper.accessor((row) => `${getAge(row.birth_date)}`, {
    //   id: 'Age',
    // }),

    columnHelper.accessor(
      (row: any) =>
        moment(`${row?.event_date ? row?.event_date : '-'}`).format(
          'DD/MM/YYYY'
        ),
      {
        id: `${translate('CSR_MODULE.TITLES.DATE_OF_EVENT')}`
      }
    ),

    columnHelper.accessor((row: any) => `${row && row?.location}`, {
      id: `${translate('CSR_MODULE.TITLES.LOCATION')}`
    }),

    columnHelper.accessor((row: any) => `${row && row?.organizer}`, {
      id: `${translate('CSR_MODULE.TITLES.ORGANISER')}`
    }),

    columnHelper.accessor((row: any) => `${row && row?.on_ground_poc}`, {
      id: `${translate('CSR_MODULE.TITLES.POC')}`
    }),
  ];

  const getData = async (reset: boolean) => {
    setIsLoading(true);
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      // is_linked: false,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),

      event_start_date: reset
        ? ''
        : eventDateRange[0] === null
        ? ''
        : getStartDateFilter(eventDateRange[0]),
      event_end_date: reset
        ? ''
        : eventDateRange[1] === null
        ? ''
        : getEndDateFilter(eventDateRange[1]),

      search_text: reset ? '' : searchValue,
      // material_type_id: reset ? '' : materialTypeId,
      // material_name_type_id: reset ? '' : subCategoryId,
      // utility_id: reset ? '' : utilityId,
    };
    let path = apiUrl.getEvents;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setEventData(res.data.data);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData(false);
  }, [page, pageLimit]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: eventData,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const performSearch = (e: any) => {
    e.preventDefault();
    getData(false);
  };

  const handeleReset = () => {
    setPage(1);
    setDateRange([null, null]);
    setEventDateRange([null, null]);
    setSearchValue('');
    getData(true);
  };

  return (
    <>
      {exportPopup && (
        <ExportPopup
          title={`${translate('CSR_MODULE.TITLES.EXPORT')}`}
          handleCancel={() => setExportPopup(false)}
          cancelText='Cancel'
          submitText='OK'
          hideCancelButton={true}
          handleSubmit={() => {
            setExportPopup(false);
          }}
        />
      )}
      <div>
        <div
          className='tab-pane fade show active'
          role='tabpanel'
          aria-labelledby='pc-request1-tab'
        >
          <div className='listing-transaction'>
            <form className=''>
              <div className='top-filters'>
                <div className='date-item-picker item-in'>
                  <div className='date-picker-main'>
                    <div className='react-datepicker-wrapper'>
                      <div className='react-datepicker__input-container '>
                        <DatePicker
                          selectsRange={true}
                          startDate={eventStartDate}
                          endDate={eventEndDate}
                          value={eventStartDate ? eventStartDate : ''}
                          className='form-control'
                          placeholderText={`${translate('CSR_MODULE.TITLES.SELECT_EVENT_DATE')}`}
                          dateFormat={commanLabel.dateFormat}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          onChange={(update: any) => {
                            setEventDateRange(update);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='date-item-picker item-in'>
                  <div className='date-picker-main'>
                    <div className='react-datepicker-wrapper'>
                      <div className='react-datepicker__input-container '>
                        <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          value={startDate ? startDate : ''}
                          className='form-control'
                          placeholderText={`${translate('CSR_MODULE.TITLES.SELECT_ENTRY_DATE')}`}
                          dateFormat={commanLabel.dateFormat}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          onChange={(update: any) => {
                            setDateRange(update);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='date-item-picker item-in'>
                  <div className='date-picker-main'>
                    <div className='react-datepicker-wrapper'>
                      <div className='react-datepicker__input-container '>
                        <span
                          role='alert'
                          aria-live='polite'
                          className='react-datepicker__aria-live'
                        ></span>
                        <input
                          type='text'
                          placeholder='Select Date Range'
                          className='form-control'
                          value=''
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className='type-select item-in'>
                  <select id='' name='status' className='select-layout '>
                    <option value=''>Status</option>
                    <option value='1'>Requested</option>
                    <option value='2'>Approved</option>
                    <option value='3'>Rejected</option>
                    <option value='4'>Completed</option>
                    <option value='5'>Partial</option>
                    <option value='6'>Closed</option>
                  </select>
                </div> */}
                {/* <div className='type-select item-in'>
                  <select
                    id=''
                    name='type_of_material'
                    className='select-layout'
                  >
                    <option>Type of Material</option>
                    <option>Plastic</option>
                    <option>Aluminium</option>
                  </select>
                </div> */}
                {/* <div className='type-select item-in'>
                  <select
                    id=''
                    name='sub_type_of_material'
                    className='select-layout'
                  >
                    <option value=''>Material Sub Type</option>
                  </select>
                </div> */}
                {/* <div className='type-select item-in'>
                  <select id='' name='region' className='select-layout'>
                    <option value=''>Region</option>
                    <option value='3'>Afghanistan</option>
                    <option value='6'>Albania</option>
                    <option value='62'>Algeria</option>
                    <option value='11'>American Samoa</option>
                    <option value='1'>Andorra</option>
                  </select>
                </div> */}
                <div className='type-select item-in'>
                  <div className='item input-min-width'>
                    <div className='input-search-item form-group'>
                      <label className='searchicon'>
                        <i className='fa fa-search'></i>
                      </label>
                      <input
                        className='form-control'
                        id='searchicon'
                        placeholder='Search'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <div className='info-icons text-end'>
                        <a
                          href='#'
                          data-bs-toggle='tooltip'
                          data-bs-placement='bottom'
                          title={`${translate('CSR_MODULE.TITLES.EVENT_SEARCH_TEXT')}`}
                        >
                          <i
                            className='fa fa-info-circle'
                            aria-hidden='true'
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='search-button item-in'>
                  <Button
                    role='button'
                    onClick={performSearch}
                    title={`${translate('CSR_MODULE.TITLES.SEARCH')}`}
                    className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                  >
                    <i className='fa fa-search' aria-hidden='true'></i>
                  </Button>
                  <Button
                    role='button'
                    onClick={handeleReset}
                    title={`${translate('CSR_MODULE.TITLES.RESET')}`}
                    type='reset'
                    className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                  >
                    <i className='fa fa-refresh' aria-hidden='true'></i>
                  </Button>
                </div>
              </div>
            </form>
            <div className='csr-btn-area mt-4 mb-4 row me-0 ms-0 me-sm-0 ms-sm-0 me-md-0 ms-md-0 me-lg-3 ms-lg-3 me-xl-3 ms-xl-3'>
              <div className='col-md-4 d-flex jusitfy-content-center'>
                {/* <div className='type-select item-in'>
                  <select id='' name='region' className='upload-select'>
                    <option value=''>Regular Upload</option>
                    <option value='3'>Afghanistan</option>
                    <option value='6'>Albania</option>
                  </select>
                  {/* <i className="fa fa-chevron-down" aria-hidden="true"></i> */}
                {/* </div> */}
              </div>
              <div className='col-md-8 d-flex justify-content-end mt-5 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0'>
                <div className='form-submit-buttons auto-width-button'>
                  <Link to={routesConstant.addEvent}>
                    <a
                      role='button'
                      href='#'
                      title={`${translate('CSR_MODULE.TITLES.SEARCH')}`}
                      className='btn btn-primary btn-outline-primary btn-md btn btn-primary me-4'
                    >
                      <i className='fa fa-calendar me-1' aria-hidden='true'></i>{' '}
                      {translate('CSR_MODULE.TITLES.ADD_EVENT')}
                    </a>
                  </Link>
                  {/* <a
                    className='export-btn'
                    onClick={() => {
                      setExportPopup(true);
                    }}
                  >
                    {' '}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='21'
                      height='15.273'
                      viewBox='0 0 21 15.273'
                    >
                      <path
                        id='Path_export'
                        data-name='Path / export'
                        d='M22,11.636,18.182,7.818v2.864H9.591v1.909h8.591v2.864M1,17.364V5.909A1.909,1.909,0,0,1,2.909,4H14.364a1.909,1.909,0,0,1,1.909,1.909V8.773H14.364V5.909H2.909V17.364H14.364V14.5h1.909v2.864a1.909,1.909,0,0,1-1.909,1.909H2.909A1.909,1.909,0,0,1,1,17.364Z'
                        transform='translate(-1 -4)'
                      />
                    </svg>{' '}
                    Export
                  </a> */}
                </div>
              </div>
            </div>
            <div className='listing-content'>
              <div className='table-list'>
                <table>
                  {/* <thead>
                    <tr>
                      <th>
                        <div className='form-check ps-0'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='defaultCheck1'
                          />
                          <label
                            className='form-check-label'
                            htmlFor='defaultCheck1'
                          ></label>
                        </div>
                      </th>
                      <th>
                        <div className='cursor-pointer select-none'>
                          Event Name
                        </div>
                      </th>
                      <th>
                        <div className='cursor-pointer select-none'>
                          Event ID
                        </div>
                      </th>
                      <th>
                        <div className='cursor-pointer select-none'>
                          Location
                        </div>
                      </th>
                      <th>
                        <div className='cursor-pointer select-none'>
                          Organiser
                        </div>
                      </th>
                      <th>
                        <div className=''>POC</div>
                      </th>
                      <th>
                        <div className=''>Action</div>
                      </th>
                    </tr>
                  </thead> */}
                  <thead>
                    {table.getHeaderGroups().map((headerGroup: any) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header: any) => {
                          return (
                            <th key={header.id} colSpan={header.colSpan}>
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none'
                                      : '',
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ??
                                    null}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  {/* <tbody>
                    <tr>
                      <td>
                        <div className='form-check ps-0'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='defaultCheck2'
                          />
                          <label
                            className='form-check-label'
                            htmlFor='defaultCheck2'
                          ></label>
                        </div>
                      </td>
                      <td>Company</td>
                      <td>User11</td>
                      <td>10 Tonne - Plastic PET (India)</td>
                      <td>HEF Activity Group</td>
                      <td>Ghanshyam Das</td>
                      <td>
                        <div className='icon-action'>
                          <div className='icon-view icon'>
                            <a title='View/Update' href='#'>
                              <img src='/assets/images/file-edit.svg' alt='#' />
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='form-check ps-0'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='defaultCheck3'
                          />
                          <label
                            className='form-check-label'
                            htmlFor='defaultCheck3'
                          ></label>
                        </div>
                      </td>
                      <td>Company</td>
                      <td>User11</td>
                      <td>10 Tonne - Plastic PET (India)</td>
                      <td>HEF Activity Group</td>
                      <td>Ghanshyam Das</td>
                      <td>
                        <div className='icon-action'>
                          <div className='icon-view icon'>
                            <a title='View/Update' href='#'>
                              <img src='/assets/images/file-edit.svg' alt='#' />
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  {eventData?.length > 0 ? (
                    <tbody>
                      {table.getRowModel().rows.map((row: any) => {
                        return (
                          <tr
                            key={row.id}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              navigate(
                                `${routesConstant.viewEvent}${row.original.id}`
                              );
                            }}
                          >
                            {row.getVisibleCells().map((cell: any) => {
                              return (
                                <td key={cell.id}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <>
                      <tbody>
                        <tr>
                          <td colSpan={9}>
                            {isLoading && (
                              <h3 className='center-message-item'>
                                {translate('CSR_MODULE.TITLES.LOADING')}
                              </h3>
                            )}
                            {eventData?.length === 0 && !isLoading && (
                              <h3 className='center-message-item'>
                                {
                                  validationMessage.noRecordFoundUnlinkedTransaction
                                }
                              </h3>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}
                </table>
              </div>
              {eventData?.length > 0 && pageCount > 1 && (
                <div className='items-center pagination-main gap-2 text-center'>
                  <PaginationControl
                    page={page}
                    between={4}
                    total={totalCount}
                    limit={pageLimit}
                    changePage={(page) => {
                      setPage(page);
                    }}
                    ellipsis={2}
                  />
                </div>
              )}
              {/* <div className='items-center pagination-main gap-2 text-center'>
                <button className='border rounded p-1'>&lt;</button>
                <ul className='pagination pagination-sm'>
                  <li className='page-item active'>
                    <span className='page-link'>1</span>
                  </li>
                  <li className='page-item'>
                    <a className='page-link' role='button' href='#'>
                      2
                    </a>
                  </li>
                  <li className='page-item'>
                    <a className='page-link' role='button' href='#'>
                      3
                    </a>
                  </li>
                </ul>
                <button className='border rounded p-1'>&gt;</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventList;
