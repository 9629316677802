import { Routes, Route, Navigate } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Home } from '../pages/shared/home';
import { Layout } from '../pages/shared/layouts';
import { adminRoutes } from './adminRoutes';
import { userRoutes } from './userRoutes';
import PlasticCerditCertification from '../pages/admin/plasticCreditCertificate/plasticCreditCertificate';
import PlasticCerditCertificationMoreInfo from '../pages/admin/plasticCreditCertificate/plasticCerditCertificateMoreInfo';
import { Aboutus } from '../pages/shared/static/aboutUs';
import { Faq } from '../pages/shared/static/faq';
import { SdgGoals } from '../pages/shared/static/sdgGoals';
import { CertificateLayout } from '../pages/shared/layouts/cetificateLayout';
import Page404 from '../pages/shared/static/page404';
import ComingSoon from '../pages/shared/static/comingSoon';
import CreditRequest from '../pages/shared/creditRequest';
import { ValueChainPartner } from '../pages/shared/static/valueChainPartner';
import { ForgotPassword } from '../pages/shared/forgot';
import { GeneratePassword } from '../pages/shared/generatePassword';
import { PasswordUpdate } from '../pages/shared/generatePassword/passwordUpdate';
import ScrollToTop from './scrollToTop';
import { Login } from '../pages/shared/login';
import { LoginLayout } from '../pages/shared/layouts/loginLayout';
import { SignUpVerifyOtp } from '../pages/user/signupPassword';
import { Signup } from '../pages/user/registration/signup';
import ContactUs from '../pages/shared/contactUs';
import PublicMap from '../components/publicMap';

export const MainRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/credit-request/:id' element={<CreditRequest />} />
        <Route path='/' element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='/contact-us' element={<ContactUs />} />

          <Route path='/about-us' element={<Aboutus />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/sdg-goal' element={<SdgGoals />} />
          <Route path='/page-404' element={<Page404 />} />
          <Route path='/coming-soon' element={<ComingSoon />} />
          <Route path='/value-chain-partner' element={<ValueChainPartner />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/generate-password' element={<GeneratePassword />} />
          <Route path='/password-update' element={<PasswordUpdate />} />
        </Route>
        <Route path='/' element={<LoginLayout />}>
          <Route path='/login' element={<Login  pcId={''} transactionId={''} />} />
          <Route path='/verify-signup-otp' element={<SignUpVerifyOtp />} />
          <Route path='/signup' element={<Signup />} />
        </Route>
        <Route path='/' element={<CertificateLayout />}>
          <Route
            path='/transaction/plasticCreditCertification/:id'
            element={<PlasticCerditCertification />}
          />
          <Route
            path='/transaction/plasticCreditCertificationMoreInfo/:id'
            element={<PlasticCerditCertificationMoreInfo />}
          />
        </Route>
        {userRoutes}
        {adminRoutes}
        <Route path='/loadmap' element={<PublicMap />} />
        <Route path='*' element={<Navigate to='/page-404' />} />
      </Routes>
    </BrowserRouter>
  );
};
