import { Outlet } from "react-router";
import { store } from "../../../app/store";
import { Footer } from "./footer";
import Header from "./header";
import { useNavigate } from "react-router";

export const LoginLayout = () => {    
    return (    
        <>
        <Header/> 
        <main>
        <Outlet/> 
        </main>  
        <Footer/>    
        </>         
    );
}