import _ from "lodash";
import { useEffect, useState } from "react";
import { NotificationMessage } from "../../shared/remake-green-utils/notification/notification.message";
import { notificationClient } from "../../utils/helper/notification"

export const NotificationPopover = () => {
    const [notificationList, setNotificationList] = useState<string[]>([]);
    useEffect(() => {
        notificationClient.on("connected", (message) => {
            console.log(message);
            notificationClient.subscribeToEvent("notification", (data: NotificationMessage) => {
                // console.log(data);
                notificationList.push(data.Data);
                console.log(notificationList);
                setNotificationList(notificationList);
            });
        });
    }, [notificationList])
    console.log(notificationList);
    return (
        <>
            <ul style={{ display: "none" }}>
                {
                    _.forEach(notificationList, (notification) => {
                        <li>
                            {notification}
                        </li>
                    })
                }
            </ul>
        </>);
}