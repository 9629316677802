import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import moment from 'moment';
import { useUpdatePCRequestStatusMutation } from '../../../app/api/services/PCRequestManagement.service';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { useUpdateCertificateMutation } from '../../../app/api/services/transaction.service';
import { useNavigate, useParams, Link } from 'react-router-dom';
import ConfirmationPopup from '../../../components/confirmationPopup';
import {
  commanLabel,
  quantityUnitConstants,
  VolumeType,
  getCreditType,
} from '../../../constants/comman.constant';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { measurementTypes } from '../../../constants/comman.constant';
import { validationMessage } from '../../../constants/validation.message.constant';
import {
  certificateCategories,
  convertQuantityToKg,
  plasticCreditRequestStatus,
} from '../../../shared/remake-green-utils/common';
import { generateCertificateValidator } from '../generateCertificate/generateCertificateValidator';
import { useTranslation } from 'react-i18next';

const GenerateCertificateComponent = ({
  children,
  details,
  isGuestUser,
  pcrType,
  pcStatus,
}: any) => {
  const { t: translate } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();
  const [selectedTransaction, setSelectedTransaction] = useState<any>([]);
  const [value, setValue] = useState({
    quantity: details?.quantity,
    quantityUnit: details?.quantityUnit,
    userName: details?.userName,

    creditType: 0,
    transactionIds: [] as any,
    remark: '',
    pcr_id: 0,
  });

  const [selectedPage, setSelectedPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [materialType, setMaterialType] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [fullfilledQuanitity, setFullfilledQuantity] = useState(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [countries, setCountries] = useState<any>([]);
  const [closeRequestPopup, setCloseRequestPopup] = useState(false);

  const [errorData, setErrorData] = useState({ title: '', message: '' });
  const [errors, setErrors] = useState<any>({
    regionId: null,
    materialTypeId: null,
    categoryTypeId: null,
    quantity: '',
    userName: '',
    userEmail: '',
    transactionIds: '',
  });
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not

  const [updateCertificate] = useUpdateCertificateMutation();
  const columnHelper = createColumnHelper<any>();
  const [updatePCRequestStatus] = useUpdatePCRequestStatusMutation();

  useEffect(() => {
    if (details.fullfilledQuantity) {
      setFullfilledQuantity(details.fullfilledQuantity);
    }
  }, [details]);

  const columns = [
    columnHelper.accessor('-', {
      header: () => translate('') + '',
      enableSorting: false,
      cell: (props: any) => {
        const id = props.row.original.id;
        return (
          <React.Fragment>
            {props?.row?.original?.link_transaction_certificate_detail.length >
            0 ? (
              <a
                href='javascript:void(0);'
                className='description-btn'
                id={`desc-btn-${id}`}
                onClick={() => {
                  let data = document.getElementById(`desc-content-${id}`);
                  if (data?.classList.contains('active')) {
                    data.classList.remove('active');
                  } else {
                    data && data?.classList?.add('active');
                  }
                  let btnData = document.getElementById(`desc-btn-${id}`);
                  if (btnData?.classList.contains('btn-active')) {
                    btnData.classList.remove('btn-active');
                  } else {
                    btnData && btnData?.classList?.add('btn-active');
                  }
                  let rowActiveData = document.getElementById(
                    `transaction-row-${id}`
                  );
                  if (rowActiveData?.classList.contains('active-row')) {
                    rowActiveData.classList.remove('active-row');
                  } else {
                    rowActiveData &&
                      rowActiveData?.classList?.add('active-row');
                  }
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/down-arrow-svg.svg`}
                />
              </a>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor('-', {
      header: () => '',
      enableSorting: false,
      cell: (props: any) => {
        const { id } = props?.row?.original;
        return (
          <React.Fragment>
            <div
              className='form-check'
              onClick={() => {
                if (value.transactionIds.length === 0) {
                  setValue({
                    ...value,
                    transactionIds: [id],
                  });
                  setSelectedTransaction([props.row.original]);
                } else {
                  if (value.transactionIds.includes(id)) {
                    let newArray = value.transactionIds.filter(
                      (data: number) => {
                        return data !== id;
                      }
                    );

                    let newArray1 = selectedTransaction.filter((data: any) => {
                      return data.id !== id;
                    });

                    setValue({
                      ...value,
                      transactionIds: newArray,
                    });

                    setSelectedTransaction([...newArray1]);
                  } else {
                    setValue({
                      ...value,
                      transactionIds: [...value.transactionIds, id],
                    });

                    setSelectedTransaction([
                      ...selectedTransaction,
                      props.row.original,
                    ]);
                  }
                }
              }}
            >
              <input
                className='form-check-input'
                type='checkbox'
                id='tr-id-1'
                onChange={() => {}}
                checked={value.transactionIds.includes(id)}
              />
              <label className='form-check-label'></label>
            </div>
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor('transaction_id', {
      header: () =>
        translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.TRANSACTION_ID'),
    }),

    columnHelper.accessor('material_type.name', {
      header: () => translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.MATERIAL_TYPE'),
    }),

    columnHelper.accessor('material_name_type.name', {
      header: () =>
        translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.MATERIAL_SUB_TYPE'),
    }),

    columnHelper.accessor(
      (row) => `${row.unloaded_quantity} ${row.unloaded_quantity_unit}`,
      {
        id: 'Qty.',
      }
    ),

    columnHelper.accessor('credit_generated', {
      header: () => translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.TOTAL_CREDIT'),
    }),

    columnHelper.accessor('plastic_credit_type.name', {
      header: () =>
        translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.TYPE_OF_CREDITS'),
    }),

    columnHelper.accessor((row) => `${row.remaining_quantity} Kg`, {
      id: 'Remaining Qty.',
    }),

    columnHelper.accessor((row) => `${row?.buyer && row?.buyer?.name}`, {
      id: translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.BUYER') || 'Buyer',
    }),

    columnHelper.accessor((row) => `${row?.seller && row?.seller?.name}`, {
      id: translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.SELLER') || 'Seller',
    }),
  ];

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const table = useReactTable({
    data: data,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const getTransactionList = async () => {
    setLoading(true);
    let path = apiUrl.getTrasactionLinkedList;
    const param = {
      creditType: value.creditType,
      isForGenerateCertificate: true,
      page: page,
      limit: pageLimit,
      materialTypeId: details?.materialTypeId,
    };
    const fr = await helper.getData(path, param);
    const res = await fr.response;
    if (res.success) {
      setLoading(false);
      let transaction = res?.data?.allTransaction;
      setData([...data, ...transaction]);
      setDataCount(res?.data?.total);
      setPageCount(res?.data?.total && Math.ceil(res.data.total / pageLimit));
    } else {
      setLoading(false);
      toast.error(res.message);
    }
  };

  const fetchMoreData = () => {
    setPage((page) => page + 1);
  };

  const onChange = (e: any) => {
    if (isSubmitted) {
      validateForm({ ...value, [e.target.name]: e.target.value });
    }
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (value.creditType) {
      getTransactionList();
    }
  }, [page]);

  useEffect(() => {
    if (value.creditType) {
      getTransactionList();
    }
  }, [value.creditType]);

  useEffect(() => {
    if (details) {
      setValue({
        quantity: details?.quantity,
        quantityUnit: details?.quantityUnit,
        userName: details?.userName,
        creditType: 0,
        transactionIds: [] as any,
        remark: '',
        pcr_id: details?.pcrId,
      });
    }
  }, [details]);

  const selectedTransactionQuantity = useMemo(() => {
    let count = 0;
    selectedTransaction.map((u: any, i: any) => {
      if (u.unloaded_quantity_unit.toUpperCase() === 'KG') {
        count = +u.unloaded_quantity + count;
      } else if (
        u.unloaded_quantity_unit.toUpperCase() === quantityUnitConstants.TON ||
        u.unloaded_quantity_unit.toUpperCase() === quantityUnitConstants.TONNE
      ) {
        count = +u.unloaded_quantity * 1000 + count;
      }
    });
    console.log(count);
    return (
      count +
      convertQuantityToKg({
        quantity: fullfilledQuanitity,
        unit: VolumeType.TON,
      })
    );
  }, [selectedTransaction]);

  const selectedQunatityKg = useMemo(() => {
    let count = 0;
    if (value.quantityUnit.toUpperCase() == 'KG') {
      count = parseFloat(value.quantity);
    } else if (
      value.quantityUnit.toUpperCase() == quantityUnitConstants.TON ||
      value.quantityUnit.toUpperCase() == quantityUnitConstants.TONNE
    ) {
      count = parseFloat(value.quantity) * 1000;
    }

    return count || 0;
  }, [value.quantity, value.quantityUnit]);

  const validateForm = (certiValue = value, formSubmit = false) => {
    if (formSubmit) {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    if (value.transactionIds.length === 0) {
      setErrorData({
        title: validationMessage.TRANSACTION_REQUIRED,
        message: validationMessage.ATLEASE_ONE_TRANSACTION,
      });
      setErrorPopup(true);
    } else {
      setSubmitPopup(true);
    }
  };

  const updateStatusRequest = async (status: any, data = value) => {
    const response = await updatePCRequestStatus({
      status: status,
      pc_request_id: id,
    }).unwrap();

    if (response.success) {
      toast.success(response.data.message);
      navigate('/admin/plasticCreditRequest', {
        state: {
          pcrType: pcrType,
          isGuestUser: isGuestUser,
        },
      });
    } else {
      toast.error(response.data.message);
    }
  };
  return (
    <>
      {discardPopup && (
        <ConfirmationPopup
          title='Discard'
          message='Are you sure you want to Discard?'
          handleCancel={() => setDiscardPopup(false)}
          cancelText='Cancel'
          submitText='Discard'
          handleSubmit={() => {
            setDiscardPopup(false);
          }}
        />
      )}

      {errorPopup && (
        <ConfirmationPopup
          title={errorData.title}
          message={errorData.message}
          handleCancel={() => setErrorPopup(false)}
          cancelText='Cancel'
          submitText='OK'
          hideCancelButton={true}
          handleSubmit={() => {
            setErrorPopup(false);
            // errorData.title === "Success" &&
            //   navigate("/admin/plasticCreditRequest");
          }}
        />
      )}

      {closeRequestPopup === true && (
        <ConfirmationPopup
          title={translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.SUBMIT')}
          message={translate(
            'PLASTIC_CREDIT_MANAGEMENT.TITLES.CLOSE_REQUEST_MESSAGE'
          )}
          cancelText={
            translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.CANCEL') || 'Cancel'
          }
          submitText={
            translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.PROCEED') || 'Proceed'
          }
          handleCancel={() => setCloseRequestPopup(false)}
          handleSubmit={() => {
            updateStatusRequest(plasticCreditRequestStatus.CLOSED);
          }}
        />
      )}

      {submitPopup && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to Proceed?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setSubmitPopup(false)}
          handleSubmit={() => {
            updateCertificate(value)
              .then((res: any) => {
                if (res?.data?.success && res?.data?.statusCode === 201) {
                  toast.success(res?.data?.message);
                  navigate('/admin/plasticCreditRequest', {
                    state: {
                      pcrType: value.creditType,
                      isGuestUser: isGuestUser,
                    },
                  });
                } else {
                  setErrorData({
                    title: 'Transaction Failed',
                    message: `Something went wrong! Please check the entered details for this transaction.`,
                  });
                  setErrorPopup(true);
                }
              })
              .catch((err: any) => {
                setErrorData({
                  title: err?.messageCode,
                  message: 'Something went wrong.',
                });
                setErrorPopup(true);
              });
            setSubmitPopup(false);
          }}
        />
      )}
      <div className=''>
        <div className='pc-request panel-main big-panel panel-generate'>
          <h2 className='panel-title-main'>
            {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.LINKED_TRANSACTIONS')}
          </h2>

          <Form
            className='transaction-registry-form'
            // id="transactionregistryform"
            noValidate
            validated={validated}
          >
            <div className='transaction-table panel-box-main'>
              <div className='panel-box table-link'>
                <div className='panel-title'>
                  <div className='title'>
                    {' '}
                    {translate(
                      'PLASTIC_CREDIT_MANAGEMENT.TITLES.LINKED_TRANSACTIONS'
                    )}
                  </div>
                  {/* {pcStatus === plasticCreditRequestStatus.PARTIAL && (
                    <a
                      // href='#'
                      className='btn btn-img-icon btn-border-green'
                      onClick={(e: any) => {
                        setCloseRequestPopup(true);
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/close-circle-svgrepo-com.svg`}
                        alt='img1'
                      />{' '}
                      {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.CLOSE')}
                    </a>
                  )} */}
                  <div className='selection-type'>
                    <div className='form-group-main'>
                      <Form.Label>
                        {translate(
                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.SELECT_CREDIT_TYPE'
                        )}
                      </Form.Label>
                      <Form.Select
                        name='creditType'
                        className='form-control'
                        onChange={(e) => {
                          setData([]);
                          setPage(1);
                          setDataCount(0);
                          setValue({
                            ...value,
                            [e.target.name]: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value={0}>Select Credit Type</option>
                        {_.map(
                          certificateCategories,
                          (value: any, key: any) => {
                            return (
                              <option
                                key={key}
                                value={key}
                              >{`${value.title}`}</option>
                            );
                          }
                        )}
                      </Form.Select>
                    </div>
                  </div>
                </div>

                {pcStatus === plasticCreditRequestStatus.PARTIAL && (
                  <div className='panel-title title-grey'>
                    <div className='left'>
                      <div className='title'>
                        {' '}
                        {translate(
                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.LINKED_TRANSACTIONS'
                        )}
                      </div>
                    </div>
                    <div className='right'>
                      <div className='group-details'>
                        <a
                          className='btn btn-img-icon'
                          onClick={(e: any) => {
                            setCloseRequestPopup(true);
                          }}
                        >
                          <img
                            src='/assets/images/green-close-circle.svg'
                            alt='img1'
                          />
                          Close Request
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                <div className='panel-data'>
                  <div className='table-certificate'>
                    <div className='listing-content'>
                      <InfiniteScroll
                        dataLength={page * 10}
                        next={fetchMoreData}
                        hasMore={dataCount > data.length}
                        loader={
                          <p className='text-center'>
                            <b>Loading...</b>
                          </p>
                        }
                        height={value.creditType ? 400 : 200}
                        className='table-list'
                      >
                        <table>
                          <tbody>
                            {table.getHeaderGroups().map((headerGroup: any) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header: any) => {
                                  return (
                                    <td
                                      key={header.id}
                                      colSpan={header.colSpan}
                                    >
                                      {header.isPlaceholder ? null : (
                                        <div
                                          {...{
                                            className:
                                              header.column.getCanSort()
                                                ? 'cursor-pointer select-none'
                                                : '',
                                            onClick:
                                              header.column.getToggleSortingHandler(),
                                          }}
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: (
                                              <i
                                                className='fa fa-sort-desc'
                                                aria-hidden='true'
                                              ></i>
                                            ),
                                            desc: (
                                              <i
                                                className='fa fa-sort-asc'
                                                aria-hidden='true'
                                              ></i>
                                            ),
                                          }[
                                            header.column.getIsSorted() as string
                                          ] ?? (
                                            <i
                                              className='fa fa-sort'
                                              aria-hidden='true'
                                            ></i>
                                          )}
                                        </div>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            ))}

                            <>
                              {table.getRowModel().rows.map((row: any) => {
                                // console.log('row', row);
                                return (
                                  <>
                                    <tr
                                      key={row.id}
                                      style={{ cursor: 'pointer' }}
                                      id={`transaction-row-${row.original.id}`}
                                    >
                                      {row
                                        .getVisibleCells()
                                        .map((cell: any) => {
                                          return (
                                            <td key={cell.id}>
                                              {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                              )}
                                            </td>
                                          );
                                        })}
                                    </tr>
                                    {
                                      <tr
                                        id={`desc-content-${row.original.id}`}
                                        className='description-row'
                                      >
                                        <td
                                          colSpan={11}
                                          className='description-td'
                                        >
                                          <div className='description-content'>
                                            <div className='description-content-inner'>
                                              <div
                                                className='main-desc-area'
                                                style={{
                                                  marginBottom: '20px',
                                                }}
                                              >
                                                <div className='title-area'>
                                                  <div
                                                    className='row'
                                                    style={{ display: 'flex' }}
                                                  >
                                                    <div className='col-lg-8 d-flex justify-content-start'>
                                                      <h5>
                                                        {translate(
                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.ALLOCATED_TRANSACTIONS'
                                                        )}{' '}
                                                      </h5>
                                                    </div>
                                                    <div className='col-lg-4 d-flex justify-content-end'>
                                                      <p>
                                                        {translate(
                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.DATE'
                                                        )}
                                                        :{' '}
                                                        {moment(
                                                          data?.created_on
                                                        ).format('DD/MM/YYYY')}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className='main-table-section'>
                                                  <div className='table-certificate'>
                                                    <div className='listing-content'>
                                                      <div className='table-list'>
                                                        <table>
                                                          <thead>
                                                            <tr>
                                                              <td></td>
                                                              <td>
                                                                <div className='title'>
                                                                  PC Request Id
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className='title'>
                                                                  Requested From
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className='title'>
                                                                  {translate(
                                                                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_WASTE_TYPE'
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className='title'>
                                                                  {translate(
                                                                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.ASSIGNED_QTY'
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className='title'>
                                                                  {translate(
                                                                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.TYPE_OF_CREDITS'
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className='title'>
                                                                  {translate(
                                                                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.TOTAL_CREDIT'
                                                                  )}
                                                                </div>
                                                              </td>
                                                              {/* <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.SELLER'
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.BUYER'
                                                                          )}
                                                                        </div>
                                                                      </td> */}
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {row?.original?.link_transaction_certificate_detail.map(
                                                              (data: any) => {
                                                                console.log(
                                                                  'data',
                                                                  data
                                                                );
                                                                return (
                                                                  <tr>
                                                                    <td>
                                                                      <Link
                                                                        target='_blank'
                                                                        to={`/admin/transaction/plasticCreditCertification/${window.btoa(
                                                                          data
                                                                            ?.linked_transaction_detail
                                                                            ?.transaction_id
                                                                        )}&${window.btoa(
                                                                          data?.pc_id
                                                                        )}`}
                                                                        title={
                                                                          translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.VIEW_CERTIFICATE'
                                                                          ) ||
                                                                          ''
                                                                        }
                                                                      >
                                                                        <img
                                                                          src='/assets/images/certificate-icon.svg'
                                                                          alt='invite user'
                                                                        />
                                                                      </Link>
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        data?.pc_id
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {data
                                                                        ?.plastic_credit_request_details
                                                                        ?.pc_user
                                                                        ? data
                                                                            ?.plastic_credit_request_details
                                                                            ?.pc_user
                                                                            .name
                                                                        : data
                                                                            ?.plastic_credit_request_details
                                                                            ?.user
                                                                            ?.user_profile
                                                                            ?.user_name}
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        data
                                                                          ?.linked_transaction_detail
                                                                          ?.material_type
                                                                          ?.name
                                                                      }
                                                                      &nbsp; -
                                                                      &nbsp;
                                                                      {
                                                                        data
                                                                          ?.linked_transaction_detail
                                                                          ?.material_name_type
                                                                          ?.name
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        data?.assigned_pc
                                                                      }
                                                                      &nbsp;
                                                                      {'Kg'}
                                                                    </td>
                                                                    <td>
                                                                      {getCreditType(
                                                                        data
                                                                          ?.linked_transaction_detail
                                                                          ?.credit_type
                                                                      )}
                                                                      &nbsp;
                                                                      Credit
                                                                    </td>
                                                                    <td>
                                                                      {(data?.assigned_pc ||
                                                                        0) /
                                                                        1000}
                                                                    </td>
                                                                    {/* <td>
                                                                        {
                                                                          data?.linked_transaction_detail?.seller
                                                                            ?.name
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          data?.linked_transaction_detail?.buyer
                                                                            ?.name
                                                                        }
                                                                      </td> */}
                                                                  </tr>
                                                                );
                                                              }
                                                            )}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    }
                                  </>
                                );
                              })}
                            </>
                          </tbody>
                        </table>

                        <>
                          {loading && (
                            <h3 className='center-message-item'>Loading...</h3>
                          )}
                          {!loading && data && data.length === 0 && (
                            <h3 className='center-message-item'>
                              {value.creditType === 0
                                ? 'Please Select credit type.'
                                : 'No Data Found in Selected Credit Type.'}
                            </h3>
                          )}
                        </>
                      </InfiniteScroll>
                    </div>
                  </div>
                  <div className='row d-flex align-items-center'>
                    <div className='col-lg-9 pe-lg-0'>
                      <div className='text-area-content'>
                        <div className='form-group  group-i-input'>
                          <Form.Label>
                            {translate(
                              'PLASTIC_CREDIT_MANAGEMENT.TITLES.REMARKS'
                            )}
                          </Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={4}
                            placeholder={
                              translate(
                                'PLASTIC_CREDIT_MANAGEMENT.TITLES.ENTER_REMARKS'
                              ) || 'Enter Remarks'
                            }
                            name='remark'
                            onChange={onChange}
                            value={value.remark}
                            maxLength={commanValidation.maxLengthAddress}
                          ></Form.Control>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-3'>
                      <div className='form-submit-buttons auto-width-button pt-lg-2 ps-lg-0'>
                        <Button
                          className='btn btn-primary btn-md w-100 me-lg-0'
                          type='button'
                          onClick={() => {
                            setIsSubmited(true);
                            validateForm(value, true);
                          }}
                        >
                          {translate(
                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.GENERATE_CERTIFICATE'
                          )}
                        </Button>
                        <Button
                          className='btn btn-primary btn-outline-primary btn-md  mt-2 w-100'
                          onClick={() => setDiscardPopup(true)}
                        >
                          {translate(
                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.DISCARD'
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {children}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default GenerateCertificateComponent;
