import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';

import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { store } from '../../../app/store';
import { RootState } from '../../../app/types/store';
import { IUserCreditStats } from '../../../shared/remake-green-utils/common/interfaces/IUserCreditStats';
import {
  PCRequestType,
  routesConstant,
} from '../../../constants/comman.constant';
import { certificateType } from '../../../shared/remake-green-utils';

export const Dashboard = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [creditStats, setCreditStats] = useState<IUserCreditStats>();
  const state: RootState = store.getState();
  const getData = async () => {
    let path = apiUrl.getUserCreditStats;
    const fr = await helper.getData(path, null);
    const res = await fr.response;
    if (res.success) {
      console.log('res::::', res);
      setCreditStats(res.data);
    } else {
      toast.error(res.message);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className='dasboard-section body-content-space pb-0'>
      <nav aria-label='breadcrumb' className='breadcrumb-main'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <Link to='/'>
              {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.HOME')}
            </Link>
          </li>
          <li className='breadcrumb-item active' aria-current='page'>
            Dashboard
          </li>
        </ol>
      </nav>

      {/* dashbaord-title */}
      <div className='row mb-3'>
        <div className='col-md-12'>
          <h3 className='title-mid dashboard-title'>
            {translate('HOMEPAGE.TITLES.WELCOME')}, {state.auth.user?.userName}
          </h3>
        </div>
      </div>

      {/* new-dashboard-section */}
      <div className='row'>
        <div className='col-lg-8'>
          <div className='row value-item-section me-lg-4'>
            <div
              className='col-sm-6 col-md-6 col-lg-6'
              onClick={() =>
                navigate(
                  routesConstant.plasticCreditManagementList +
                    PCRequestType.COMPLETED
                )
              }
            >
              <a className='value-item'>
                <div className='left-content'>
                  <h4 className='title-label'>
                    {' '}
                    {translate('HOMEPAGE.TITLES.AVAILABLE_CREDITS')}
                  </h4>
                  <p className='valuedata'>
                    {creditStats?.totalAvailableCredits.toFixed(3)}
                  </p>
                </div>

                <div className='right-img-data'>
                  <div className='img-icon'>
                    <img
                      style={{ cursor: 'pointer' }}
                      // onClick={() =>
                      //   navigate(
                      //     routesConstant.plasticCreditManagementList +
                      //       PCRequestType.PENDING
                      //   )
                      // }
                      src={`${process.env.PUBLIC_URL}/assets/images/credit-data-icon.svg`}
                      alt='img1'
                    />
                  </div>
                </div>
              </a>
            </div>
            <div
              className='col-sm-6 col-md-6 col-lg-6'
              onClick={() =>
                navigate(
                  routesConstant.plasticCreditManagementList +
                    PCRequestType.REQUESTED
                )
              }
            >
              <a className='value-item'>
                <div className='left-content'>
                  <h4 className='title-label'>Requested Credits</h4>
                  <p className='valuedata'>
                    {creditStats?.totalRequestedCredits.toFixed(2)}
                  </p>
                </div>

                <div className='right-img-data'>
                  <div className='img-icon'>
                    <img
                      style={{ cursor: 'pointer' }}
                      // onClick={() =>
                      //   navigate(
                      //     routesConstant.plasticCreditManagementList +
                      //       PCRequestType.REQUESTED
                      //   )
                      // }
                      src={`${process.env.PUBLIC_URL}/assets/images/Requested-credit.svg`}
                      alt='img1'
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div
            className='row value-item-section mt-4 mb-4 me-lg-4'
            onClick={() =>
              navigate(
                routesConstant.plasticCreditManagementList +
                  PCRequestType.PENDING
              )
            }
          >
            <div className='col-sm-4 col-md-4 col-lg-4'>
              <a className='value-item'>
                <div className='left-content'>
                  <h4 className='title-label'>
                    {translate('HOMEPAGE.TITLES.PENDING_REQUESTS')}
                  </h4>
                  <p className='valuedata'>
                    {creditStats?.totalPendingCredits}
                  </p>
                </div>

                <div className='right-img-data'>
                  <div className='img-small-icon'>
                    <img
                      style={{ cursor: 'pointer' }}
                      // onClick={() =>
                      //   navigate(
                      //     routesConstant.plasticCreditManagementList +
                      //       PCRequestType.REQUESTED
                      //   )
                      // }
                      src={`${process.env.PUBLIC_URL}/assets/images/Pending-Request.svg`}
                      alt='img1'
                    />
                  </div>
                </div>
              </a>
            </div>
            <div className='col-sm-4 col-md-4 col-lg-4'>
              <a className='value-item'>
                <div className='left-content'>
                  <h4 className='title-label'>
                    {' '}
                    {translate('HOMEPAGE.TITLES.RETIRED_CREDIT')}
                  </h4>
                  <p className='valuedata'>
                    {creditStats?.totalRetiredCredits}
                  </p>
                </div>

                <div className='right-img-data'>
                  <div className='img-small-icon'>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/Retired-credit.svg`}
                      alt='img1'
                    />
                  </div>
                </div>
              </a>
            </div>
            <div className='col-sm-4 col-md-4 col-lg-4'>
              <a href='#' className='value-item'>
                <div className='left-content'>
                  <h4 className='title-label'>
                    {' '}
                    {translate('HOMEPAGE.TITLES.TRANSFERED_CREDIT')}
                  </h4>
                  <p className='valuedata'>
                    {creditStats?.totalTransferedCredits}
                  </p>
                </div>

                <div className='right-img-data'>
                  <div className='img-small-icon'>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/open-transfer.svg`}
                      alt='img1'
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className='col-lg-4'>
          <div className='row request-section'>
            <div
              className='col-sm-6 col-md-6 col-lg-12'
              onClick={() =>
                navigate(
                  routesConstant.remakeCreditRequest +
                    window.btoa(certificateType.COLLECTION.toString())
                )
              }
            >
              <a className='value-item'>
                <div className='left-content'>
                  <h4 className='title-label'>Generate Credit Request</h4>
                </div>
                <div className='right-img-data'>
                  <div className='icon-area'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                    >
                      <path
                        id='Path_8282'
                        data-name='Path 8282'
                        d='M32.115,4l-15.8,15.8.017,8.988,8.969-.015L41.1,12.985v27A2.116,2.116,0,0,1,38.979,42.1H5.116A2.116,2.116,0,0,1,3,39.984V6.121A2.116,2.116,0,0,1,5.116,4Zm7.89-1.9L43,5.1,23.546,24.551l-2.988.006,0-3Z'
                        transform='translate(-3 -2.1)'
                      />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
            <div
              className='col-sm-6 col-md-6 col-lg-12 mt-lg-4 mt-4 mt-sm-0 mt-md-0'
              onClick={() =>
                navigate(routesConstant.plasticCreditManagementList)
              }
            >
              <a className='value-item'>
                <div className='left-content'>
                  <h4 className='title-label'>Plastic Credit Management</h4>
                </div>

                <div className='right-img-data'>
                  <div className='icon-area'>
                    <svg
                      id='Plastic_Credit'
                      data-name='Plastic Credit'
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='34.783'
                      viewBox='0 0 40 34.783'
                    >
                      <g
                        id='Group_13793'
                        data-name='Group 13793'
                        transform='translate(0 0)'
                      >
                        <g id='earth-eco-environment-plastic-pollution-svgrepo-com'>
                          <path
                            id='Path_5524'
                            data-name='Path 5524'
                            d='M15.193,18.563a1.807,1.807,0,0,0-.441,1.888,1.847,1.847,0,0,0,2.33-2.33A1.807,1.807,0,0,0,15.193,18.563Z'
                            transform='translate(-2.52 2.868)'
                          />
                          <path
                            id='Path_5525'
                            data-name='Path 5525'
                            d='M29.348,1H27.029a3.478,3.478,0,0,0-3.478,3.478v7.072a47.3,47.3,0,0,0-11.594,0V4.478A3.478,3.478,0,0,0,8.478,1H6.159A1.159,1.159,0,0,0,5,2.159V29.986a5.8,5.8,0,0,0,5.8,5.8H24.71a5.8,5.8,0,0,0,5.8-5.8V2.159A1.159,1.159,0,0,0,29.348,1ZM21.232,25.765a5.357,5.357,0,0,1-5.461,1.357l-1.1,1.1a1.16,1.16,0,1,1-1.635-1.646l1.09-1.09a5.461,5.461,0,0,1,7.629-6.493,1.159,1.159,0,0,1,.51.5A5.391,5.391,0,0,1,21.232,25.765Z'
                            transform='translate(-5 -1)'
                          />
                        </g>
                        <rect
                          id='Rectangle_5271'
                          data-name='Rectangle 5271'
                          width='11.179'
                          height='10.521'
                          transform='translate(7.164 17.391)'
                        />
                      </g>
                      <circle
                        id='Ellipse_165'
                        data-name='Ellipse 165'
                        cx='12.174'
                        cy='12.174'
                        r='12.174'
                        transform='translate(15.652 10.435)'
                        fill='#fff'
                      />
                      <g
                        id='settings-svgrepo-com'
                        transform='translate(17.873 12.531)'
                      >
                        <g
                          id='Group_13799'
                          data-name='Group 13799'
                          transform='translate(0 0)'
                        >
                          <path
                            id='Path_6901'
                            data-name='Path 6901'
                            d='M20.408,8.2H17.445a7.289,7.289,0,0,0-.424-1.02l2.1-2.1a.555.555,0,0,0,0-.785l-2.45-2.45a.555.555,0,0,0-.785,0l-2.1,2.1a7.288,7.288,0,0,0-1.019-.424V.557A.555.555,0,0,0,12.214,0H8.749a.555.555,0,0,0-.555.555V3.519a7.286,7.286,0,0,0-1.02.424l-2.1-2.1a.555.555,0,0,0-.785,0L1.845,4.3a.555.555,0,0,0,0,.785l2.1,2.1A7.283,7.283,0,0,0,3.517,8.2H.555A.555.555,0,0,0,0,8.751v3.464a.555.555,0,0,0,.555.555H3.517a7.291,7.291,0,0,0,.424,1.02l-2.1,2.1a.555.555,0,0,0,0,.785l2.45,2.45a.555.555,0,0,0,.785,0l2.1-2.1a7.281,7.281,0,0,0,1.02.424V20.41a.555.555,0,0,0,.555.555h3.464a.555.555,0,0,0,.555-.555V17.447a7.283,7.283,0,0,0,1.019-.424l2.1,2.1a.555.555,0,0,0,.785,0l2.45-2.45a.555.555,0,0,0,0-.785l-2.1-2.1a7.288,7.288,0,0,0,.424-1.019h2.962a.555.555,0,0,0,.555-.555V8.751A.555.555,0,0,0,20.408,8.2Zm-9.927,5.049a2.762,2.762,0,1,1,2.762-2.762A2.762,2.762,0,0,1,10.481,13.245Z'
                            transform='translate(0 -0.002)'
                            fill='#18a675'
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='welcome-section'>
        <div className='welcome-in row'>
          <div className='col-md-4 left'>
            <h3 className='title-mid'>
              {translate('HOMEPAGE.TITLES.WELCOME')},{' '}
              {state.auth.user?.userName}
            </h3>
          </div>
          <div className='col-md-7 right'>
            <div className='credit-details'>
              <div className='left-content'>
                <h4 className='title-label'>
                  {' '}
                  {translate('HOMEPAGE.TITLES.AVAILABLE_CREDITS')}
                </h4>
                <p className='valuedata'>
                  {creditStats?.totalAvailableCredits}
                </p>
              </div>

              <div className='right-img-data'>
                <div className='img-icon'>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/credit-data-icon.svg`}
                    alt='img1'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(
                        routesConstant.plasticCreditManagementList +
                        PCRequestType.COMPLETED
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row value-item-section'>
        <div className='col-md-4'>
          <div className='value-item'>
            <div className='left-content'>
              <h4 className='title-label'>
                {translate('HOMEPAGE.TITLES.PENDING_REQUESTS')}
              </h4>
              <p className='valuedata'>{creditStats?.totalPendingCredits}</p>
            </div>

            <div className='right-img-data'>
              <div className='img-icon'>
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(
                      routesConstant.plasticCreditManagementList +
                        PCRequestType.PENDING
                    )
                  }
                  src={`${process.env.PUBLIC_URL}/assets/images/credit-data-icon.svg`}
                  alt='img1'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='value-item'>
            <div className='left-content'>
              <h4 className='title-label'>
                {' '}
                {translate('HOMEPAGE.TITLES.RETIRED_CREDIT')}
              </h4>
              <p className='valuedata'>{creditStats?.totalRetiredCredits}</p>
            </div>

            <div className='right-img-data'>
              <div className='img-icon'>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/credit-data-icon.svg`}
                  alt='img1'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='value-item'>
            <div className='left-content'>
              <h4 className='title-label'>
                {' '}
                {translate('HOMEPAGE.TITLES.TRANSFERED_CREDIT')}
              </h4>
              <p className='valuedata'>{creditStats?.totalTransferedCredits}</p>
            </div>

            <div className='right-img-data'>
              <div className='img-icon'>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/credit-data-icon.svg`}
                  alt='img1'
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
