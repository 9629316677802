import {
  commonInput,
  emailPatern,
  mobilePatern,
  commonAddress,
} from '../../../constants/comman.validation.constant';

export const contactUsValidator = (errors: any, formInfos: any) => {
  let tempErrors = { ...errors };
  const getExpressionError = (
    value: any,
    expPattern: any,
    invalidErr: any,
    requiredErr: any
  ) => {
    return value
      ? !new RegExp(expPattern).test(value)
        ? invalidErr
        : ''
      : requiredErr;
  };
  tempErrors = {
    ...tempErrors,
    email: getExpressionError(
      formInfos.email,
      emailPatern,
      'ERRORS.INVALID.EMAIL_ADDRESS',
      'ERRORS.REQUIRED.EMAIL'
    ),
    contact_number:
      formInfos.contact_number &&
      getExpressionError(
        formInfos.contact_number,
        mobilePatern,
        'ERRORS.INVALID.CONTACT_NO',
        'ERRORS.REQUIRED.CONTACT_NO'
      ),
    name: formInfos.name ? '' : 'ERRORS.REQUIRED.FULL_NAME',
  };
  return tempErrors;
};
