import { plasticCreditApi } from '..';
import { apiUrl } from './apiUrl';

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({
    updatePCRequestStatus: builder.mutation<any, any>({
      query(body) {
        return {
          url: apiUrl.pcRequestUpdate,
          method: 'POST',
          body,
        };
      },
    }),
    reCreatePdf: builder.mutation<any, any>({
      query(body) {
        return {
          url: apiUrl.reCreatePdf,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const { useUpdatePCRequestStatusMutation, useReCreatePdfMutation } = extendedApi;
