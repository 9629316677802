import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { imagePath } from "../../../../constants/comman.constant";


const DeleteDialog = ({ onCancel, onProceed }: any) => {
    const { t: translate } = useTranslation();

    return <>
    <Modal className="modal modal-big image-modal" show={true} onHide={onCancel} centered>
      <Modal.Body className="usermanagement-modal">
            <div className="image-head">
                        <img src={imagePath.deletePopIconPath} />
                    </div>

                    <div className="content-data">
                        <div className="title-normal">
                            {translate('PROFILE.TITLES.DELETE_FILE')}
                        </div>

                        <h3 className="note">{translate('PROFILE.TITLES.DELETE_MSG')}</h3>

                        <div className="button-group-item">
                            <a href="#" className="btn btn-md btn-outline-primary" onClick={onCancel}>{translate('BUTTONS.CANCEL')}</a>
                            <a href="#" className="btn btn-md" onClick={onProceed}>{translate('BUTTONS.PROCEED')}</a>

                        </div>
            </div>
      </Modal.Body>
    </Modal>
  </>
};
export default DeleteDialog;
