import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import {
  commanValidation,
  commonInput,
} from '../../../constants/comman.validation.constant';
import { useCreateReMakeUserInquiryMutation } from '../../../app/api/services/createInquiryervice';
import {
  commanLabel,
  measurementTypes,
  materialSubCategory,
} from '../../../constants/comman.constant';
import { errorJson, userDetails } from './remakeUserCreditRequestProps';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmationPopup from '../../../components/confirmationPopup';
import { remakeUserCreditRequestValidator } from './remakeUserCreditRequestValidator';
import { isEmptyJSON } from '../../../utils/helper/common';
import {
  calculateCreditPointToQuantity,
  calculateQuantityToCreditPoint,
  certificateType,
  UserType,
} from '../../../shared/remake-green-utils/common';
import { useTranslation } from 'react-i18next';
import { store } from '../../../app/store';
import { RootState } from '../../../app/types/store';
import { Footer } from '../layouts/footer';
import Header from '../layouts/header';

const RemakeUserCreditRequest = () => {
  const { id } = useParams();
  const { t: translate } = useTranslation();
  const state: RootState = store.getState();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [userDetail, setuserDetail] = useState<any>(userDetails);
  const [materialTypeData, setMaterialTypeData] = useState([]);
  const [materialSubTypeData, setMaterialSubTypeData] = useState([]);
  const [regions, setRegions] = useState<any>([]);
  const [preferredCurrency, setPreferredCurrency] = useState<any>([]);
  const [createReMakeUserInquiry] = useCreateReMakeUserInquiryMutation();
  const [creditValue, setCreditValue] = useState('');
  const [quantity, setQuantity] = useState('');
  const [discardPopup, setDiscardPopup] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not
  const [errors, setErrors] = useState<any>(errorJson);

  const handleChange = (e: any) => {
    if (isSubmitted) {
      validateForm({
        ...userDetail,
        [e.target.name]: e.target.value,
        quantity,
        credit_value: creditValue,
      });
    }
    setuserDetail({
      ...userDetail,
      [e.target.name]: e.target.value,
    });
  };

  const creditPointcalculat = async (
    unit?: string,
    unloadedQuantity?: number
  ) => {
    let creditPoint = calculateQuantityToCreditPoint(unit, unloadedQuantity);
    setCreditValue(creditPoint ? creditPoint.toString() : '');
    if (isSubmitted) {
      validateForm({
        ...userDetail,
        quantity: unloadedQuantity,
        quantity_unit: unit,
      });
    }
  };

  const getSettingData = async () => {
    let path = apiUrl.getAllSetting;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let allSetting = res?.data && res?.data?.jsonData;
      setMaterialTypeData(allSetting?.Material_type);
      setPreferredCurrency(allSetting?.Preferred_Currency);
    } else {
      toast.error(res.message);
    }
  };

  const quantitCalculat = async (unit?: string, creditPoint?: number) => {
    let unloadedQuantity = calculateCreditPointToQuantity(unit, creditPoint);
    setQuantity(unloadedQuantity ? unloadedQuantity?.toString() : '');
    if (isSubmitted) {
      validateForm({
        ...userDetail,
        ['quantity']: unloadedQuantity,
        quantity_unit: unit,
      });
    }
  };

  const getMaterialSubCategory = async (materialId: string) => {
    let path = apiUrl.getMaterialSubCategory + materialId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let subCategory = res?.data?.allSubCategory;
      setMaterialSubTypeData(subCategory);
    } else {
      toast.error(res.message);
    }
  };

  const getRegions = async () => {
    let path = apiUrl.getRegions;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let countries = res?.data && res?.data?.countries;
      setRegions(countries);
    } else {
      toast.error(res.message);
    }
  };

  const validateForm = (userInfo = userDetail, formSubmit = false) => {
    const errs: any = remakeUserCreditRequestValidator(errors, userInfo);
    setErrors(errs);
    const isFormValid = isEmptyJSON(errs);
    if (formSubmit && isFormValid) {
      setSubmitPopup(true);
    }
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
    }
  };

  const onSubmit = async (e: any) => {
    const postJson = {
      ...userDetail,
      credit_type: parseInt(userDetail.credit_type),
      material_type_id: parseInt(userDetail.material_type_id),
      material_name_type_id: parseInt(userDetail.material_name_type_id),
      region_id: parseInt(userDetail.region_id),
      quantity: parseFloat(quantity),
      credit_value: parseFloat(creditValue),
      user_id: state.auth?.user?.id,
    };

    const response = await createReMakeUserInquiry(postJson).unwrap();
    if (response.success) {
      toast.success(response.message);
      navigate('/');
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    getSettingData();
    getRegions();
    if (id) {
      setuserDetail({
        ...userDetail,
        credit_type: window.atob(id),
      });
    }
  }, []);

  const onDisCard = () => {
    setuserDetail(userDetails);
    if (id) {
      setuserDetail({
        ...userDetails,
        credit_type: window.atob(id),
      });
    }
    setErrors(errorJson);
    setCreditValue('');
    setQuantity('');
  };

  return (
    <div className='body-color'>
      <Header />
      <div className='container body-content-main'>
        <nav aria-label='breadcrumb' className='breadcrumb-main'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <Link to='/'>{translate('CREDIT_REQUEST_PAGE.TITLES.HOME')}</Link>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              {translate('CREDIT_REQUEST_PAGE.TITLES.CREDIT_REQUEST')}
            </li>
          </ol>
        </nav>
        <div className='panel-main big-panel request-page'>
          <h2 className='panel-title-main'>
            {translate('CREDIT_REQUEST_PAGE.TITLES.CREDIT_REQUEST')}
          </h2>
          <Form
            className='transaction-registry-form'
            id='userform'
            noValidate
            validated={validated}
          >
            <div className='panel-box-main'>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate('CREDIT_REQUEST_PAGE.TITLES.PRODUCT_DETAILS')}
                </div>
                <div className='panel-data pb-sm-0'>
                  <div className='row'>
                    <div className='col-md-12 oneline-radio small-space'>
                      <div className='form-group required'>
                        <label className='label-single form-label'>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.SELECT_CREDIT_TYPE'
                          )}
                        </label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              id='ex11'
                              name='credit_type'
                              value={certificateType.COLLECTION.toString()}
                              checked={
                                userDetail.credit_type ===
                                certificateType.COLLECTION.toString()
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <label
                              htmlFor='ex11'
                              className='form-check-label form-label'
                            >
                              {translate(
                                'CREDIT_REQUEST_PAGE.TITLES.COLLECTION'
                              )}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              id='ex22'
                              name='credit_type'
                              value={certificateType.CIRCULAR.toString()}
                              checked={
                                userDetail.credit_type ===
                                certificateType.CIRCULAR.toString()
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <label
                              htmlFor='ex22'
                              className='form-check-label form-label'
                            >
                              {translate('CREDIT_REQUEST_PAGE.TITLES.CIRCULAR')}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              id='ex33'
                              name='credit_type'
                              value={certificateType.RECYCLING.toString()}
                              checked={
                                userDetail.credit_type ===
                                certificateType.RECYCLING.toString()
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <label
                              htmlFor='ex33'
                              className='form-check-label form-label'
                            >
                              {translate('CREDIT_REQUEST_PAGE.TITLES.RECYCLE')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.MATERIAL_TYPE'
                          )}
                        </Form.Label>
                        <Form.Select
                          className='form-control'
                          name='material_type_id'
                          value={userDetail.material_type_id}
                          onChange={(e) => {
                            handleChange(e);
                            getMaterialSubCategory(e.target.value);
                          }}
                          required
                        >
                          <option value={''}>
                            {translate(
                              'CREDIT_REQUEST_PAGE.TITLES.SELECT_MATERIAL_TYPE'
                            )}
                          </option>
                          {materialTypeData?.length > 0 &&
                            materialTypeData?.map(
                              (item: any, index: number) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              }
                            )}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.material_type_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.MATERIAL_SUB_TYPE'
                          )}
                        </Form.Label>
                        <Form.Select
                          className='form-control'
                          name='material_name_type_id'
                          value={userDetail.material_name_type_id}
                          disabled={userDetail.material_type_id ? false : true}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        >
                          <option value={''}>
                            {translate(
                              'CREDIT_REQUEST_PAGE.TITLES.SELECT_MATERIAL_SUB_TYPE'
                            )}
                          </option>
                          {materialSubTypeData?.length > 0 &&
                            materialSubTypeData?.map(
                              (item: any, index: number) => {
                                return (
                                  <option
                                    key={index}
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                );
                              }
                            )}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.material_name_type_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate('CREDIT_REQUEST_PAGE.TITLES.REGION')}
                        </Form.Label>
                        <Form.Select
                          name='region_id'
                          className='form-control select-data form-select'
                          value={userDetail.region_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        >
                          <option value=''>
                            {translate(
                              'CREDIT_REQUEST_PAGE.TITLES.SELECT_REGION'
                            )}
                          </option>
                          {regions?.length > 0 &&
                            regions?.map((u: any, i: any) => {
                              return (
                                <option key={i} value={u.id}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.region_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-8  col-sm-12'>
                      <div className='combine-option-text row'>
                        <div className='col-md-6 col-sm-6 left'>
                          <div className='form-group required'>
                            <label className='form-label'>
                              {translate(
                                'CREDIT_REQUEST_PAGE.TITLES.TOTAL_CREDIT'
                              )}
                            </label>
                            <input
                              type='number'
                              className='form-control form-control'
                              placeholder={
                                translate(
                                  'CREDIT_REQUEST_PAGE.TITLES.ENTER_TOTAL_CREDIT'
                                ) + ''
                              }
                              step={commanValidation.tonneMin}
                              min={commanValidation.buyerMinTonne}
                              pattern={commanValidation.numberPattern}
                              max={commanValidation.maxLengthCreditPoint}
                              value={creditValue}
                              onChange={(e) => {
                                setCreditValue(e.target.value);
                                quantitCalculat(
                                  userDetail.quantity_unit,
                                  Number(e.target.value)
                                );
                              }}
                              required
                            />

                            {
                              <span className='error'>
                                {translate(errors.credit_value)}
                              </span>
                            }
                          </div>
                        </div>
                        <div className='between-content'>
                          {translate('CREDIT_REQUEST_PAGE.TITLES.OR')}
                        </div>
                        <div className='col-md-6 col-sm-6 right'>
                          <div className='form-group required group-i-input'>
                            <Form.Label>
                              {translate('CREDIT_REQUEST_PAGE.TITLES.QUANTITY')}
                            </Form.Label>
                            <div className='input-group'>
                              <div className='input-left  input-left-right'>
                                <input
                                  name='quantity'
                                  type='number'
                                  placeholder={
                                    translate(
                                      'CREDIT_REQUEST_PAGE.TITLES.QUANTITY'
                                    ) + ''
                                  }
                                  className='form-control form-control'
                                  value={quantity}
                                  step={commanValidation.tonneMin}
                                  min={
                                    userDetail.quantity_unit ===
                                    commanLabel.kgLabel
                                      ? commanValidation.buyerMinKg
                                      : commanValidation.buyerMinTonne
                                  }
                                  pattern={commanValidation.numberPattern}
                                  max={commanValidation.maxQty}
                                  onChange={async (e) => {
                                    setQuantity(e.target.value);
                                    creditPointcalculat(
                                      userDetail.quantity_unit,
                                      Number(e.target.value)
                                    );
                                  }}
                                  required
                                />

                                {
                                  <span className='error'>
                                    {translate(errors.quantity)}
                                  </span>
                                }
                                <p className='upload-note'>
                                  {userDetail.quantity_unit ===
                                  commanLabel.kgLabel
                                    ? translate(
                                        'VALIDATION_CONSTANTS.BUYER_MIN_KG_MESSAGE'
                                      )
                                    : translate(
                                        'VALIDATION_CONSTANTS.BUYER_MIN_TONNE_MESSAGE'
                                      )}
                                </p>
                              </div>

                              <div className='input-right input-left-right'>
                                <Form.Select
                                  className='form-control'
                                  name='quantity_unit'
                                  value={userDetail.quantity_unit}
                                  onChange={async (e) => {
                                    await handleChange(e);
                                    await quantitCalculat(
                                      e.target.value,
                                      Number(creditValue)
                                    );
                                  }}
                                >
                                  {measurementTypes?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <option key={index} value={item.name}>
                                          {item.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </Form.Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <label className='form-label'>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.PREFERRED_CURRENCY'
                          )}
                        </label>
                        <Form.Select
                          className='form-control'
                          name='preferred_currency'
                          value={userDetail.preferred_currency}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          {preferredCurrency.length > 0 &&
                            preferredCurrency?.map(
                              (item: any, index: number) => {
                                return (
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              }
                            )}
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-submit-buttons'>
                <div className='submit-note'>
                  {translate('CREDIT_REQUEST_PAGE.TITLES.LABEL1')}
                  <br />
                  {translate('CREDIT_REQUEST_PAGE.TITLES.LABEL2')}
                </div>
                <div className='button-main'>
                  <div className='button-group-item'>
                    <Button
                      className='btn btn-primary btn-md'
                      type='button'
                      onClick={() => {
                        setIsSubmited(true);
                        validateForm(
                          {
                            ...userDetail,
                            quantity,
                            credit_value: creditValue,
                          },
                          true
                        );
                      }}
                    >
                      {translate('BUTTONS.SEND_REQUEST')}
                    </Button>
                    <Button
                      className='btn btn-primary btn-outline-primary btn-md'
                      type='button'
                      onClick={(e) => {
                        e.preventDefault();
                        setDiscardPopup(true);
                      }}
                    >
                      {translate('BUTTONS.DISCARD')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          {discardPopup && (
            <ConfirmationPopup
              title={translate('CREDIT_REQUEST_PAGE.TITLES.DISCARD')}
              message={translate('CREDIT_REQUEST_PAGE.TITLES.DISCARD_MESSAGE')}
              handleCancel={() => setDiscardPopup(false)}
              cancelText={translate('CREDIT_REQUEST_PAGE.TITLES.CANCEL') + ''}
              submitText={translate('CREDIT_REQUEST_PAGE.TITLES.SUBMIT') + ''}
              handleSubmit={() => {
                onDisCard();
                setDiscardPopup(false);
              }}
            />
          )}
          {submitPopup && (
            <ConfirmationPopup
              title={translate('CREDIT_REQUEST_PAGE.TITLES.SUBMIT')}
              message={translate('CREDIT_REQUEST_PAGE.TITLES.SUBMIT_MESSAGE')}
              handleCancel={() => setSubmitPopup(false)}
              cancelText={translate('CREDIT_REQUEST_PAGE.TITLES.CANCEL') + ''}
              submitText={translate('CREDIT_REQUEST_PAGE.TITLES.SUBMIT') + ''}
              handleSubmit={(e: any) => {
                onSubmit(e);
                setSubmitPopup(false);
              }}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default RemakeUserCreditRequest;
