import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { PaginationState } from '@tanstack/react-table';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import moment from 'moment';
import {
  commanLabel,
  getAge,
  routesConstant,
} from '../../../constants/comman.constant';
import { socialWelfareTab } from '../../../shared/remake-green-utils';
import { Button } from 'react-bootstrap';
import { validationMessage } from '../../../constants/validation.message.constant';
import { useExportReclaimerPdfMutation } from '../../../app/api/services/socialWelfare';
import AddActivity from '../../../components/addActivityPopup/addActivityFromReclaimer';

const ViewReclaimer = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const [data, setData] = useState<any>(null);

  const [reclaimerName, setReclaimerName] = useState('');
  const [gender, setGender] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [childrens, setChildrens] = useState('');
  const [girls, setGirls] = useState(0);
  const [boys, setBoys] = useState(0);

  const [capacityPerDay, setCapacityPerDay] = useState('');
  const [smallScrapDealer, setSmallScrapDealer] = useState('');
  const [collectionCenter, setCollectionCenter] = useState('');
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);

  const [aadhaarNo, setAadhaarNo] = useState('');
  const [aadhaarPhoto, setAadhaarPhoto] = useState('');
  const [birthDate, setBirthDate] = useState({ day: '', month: '', year: '' });
  const [isEdit, setIsEdit] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchValue, setSearchValue] = useState('');
  const [exportReclaimerPdf] = useExportReclaimerPdfMutation();
  const getReclaimerDetails = async (reset: boolean, id: string) => {
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      // is_linked: false,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),

      search_text: reset ? '' : searchValue,
    };
    let path = apiUrl.getReclaimer + id;
    const fr: any = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setData(res?.data);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
  };

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    if (id) {
      getReclaimerDetails(false, id);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setReclaimerName(data.reclaimer.name);
      setBoys(data.reclaimer.boys);
      setGirls(data.reclaimer.girls);
      setGender(data.reclaimer.gender);
      setContactNumber(data.reclaimer.contact_number);
      setCapacityPerDay(data.reclaimer.capacity_per_day);
      setSmallScrapDealer(data.reclaimer.small_scrap_dealer);
      setBirthDate({
        day: moment(data.reclaimer.birth_date).format('DD'),
        month: moment(data.reclaimer.birth_date).format('MM'),
        year: moment(data.reclaimer.birth_date).format('YYYY'),
      });
      setAddress(data.reclaimer.address);
      setAadhaarNo(data.reclaimer.aadhaar_no);
      setCollectionCenter(data?.reclaimer?.csr_collection_center?.name);
      setChildrens(data.reclaimer.childrens);
      setAadhaarPhoto(data.reclaimer.aadhaar_photo);
    }
  }, [data]);

  const performSearch = (e: any) => {
    e.preventDefault();
    id && getReclaimerDetails(false, id);
  };

  const handeleReset = () => {
    setPage(1);
    setDateRange([null, null]);
    setSearchValue('');
    id && getReclaimerDetails(true, id);
  };

  const getExportedPdf = async () => {
    if (data?.activity.length > 0) {
      const res: any = await exportReclaimerPdf({
        reclaimer_id: Number(id),
      }).unwrap();
      if (res.success) {
        let path = apiUrl.prepareFile + res.data.certificateUrl;
        const fr: any = await helper.getData(path);
        const resData = await fr.response;
        if (resData.data) {
          window.open(
            process.env.REACT_APP_BASE_URL_API +
              apiUrl.downloadFile +
              res.data.certificateUrl
          );
          toast.success('Certificate Downloaded');
        } else {
          toast.error(res.message);
        }
      }
    } else {
      toast.warning('There is no activity to download');
    }
  };
  return (
    <>
      {submitPopup && (
        <AddActivity
          title='Add Activity'
          message='Are you sure you want to Discard?'
          handleCancel={() => setSubmitPopup(!submitPopup)}
          cancelText='Cancel'
          submitText='Discard'
          handleSubmit={() => {
            if (id) {
              getReclaimerDetails(false, id);
              setSubmitPopup(false);
            }
          }}
        />
      )}
      <main>
        <div className='profile-page admin-profile-changes csr-addreclaimer'>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a
                    // href='/'
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className='breadcrumb-item'>
                  <a
                    // href=''
                    onClick={() => {
                      navigate('/admin/social-welfare', {
                        state: socialWelfareTab.RECLAIMER,
                      });
                    }}
                  >
                    Social Welfare
                  </a>
                </li>
                <li className='breadcrumb-item active'>View Reclaimer</li>
              </ol>
            </nav>
            <div className='section-title-main'>
              <div className='title'>View Reclaimer</div>
              <div className='form-o-submit'>
                <a className='export-btn' onClick={getExportedPdf}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='21'
                    height='15.273'
                    viewBox='0 0 21 15.273'
                  >
                    <path
                      id='Path_export'
                      data-name='Path / export'
                      d='M22,11.636,18.182,7.818v2.864H9.591v1.909h8.591v2.864M1,17.364V5.909A1.909,1.909,0,0,1,2.909,4H14.364a1.909,1.909,0,0,1,1.909,1.909V8.773H14.364V5.909H2.909V17.364H14.364V14.5h1.909v2.864a1.909,1.909,0,0,1-1.909,1.909H2.909A1.909,1.909,0,0,1,1,17.364Z'
                      transform='translate(-1 -4)'
                    />
                  </svg>{' '}
                  Export
                </a>
              </div>
            </div>
            <div className='display-combine-component'>
              <div className='display-component main-profile-head'>
                <div className='display-head'>
                  <div className='title'>Profile Details</div>
                  <div className='form-submit-buttons auto-width-button'>
                    {/* <button
                    type='button'
                    className='btn btn-primary btn-outline-primary btn-sm btn btn-primary'
                  >
                    Discard
                  </button> */}
                    <button
                      type='button'
                      onClick={() =>
                        navigate(`${routesConstant.editReclaimer}${id}`)
                      }
                      className='btn btn-primary btn-sm btn btn-primary ms-3'
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
                <div className='display-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label'>Reclaimer Name</label>
                        <input
                          type='text'
                          className='form-control form-control'
                          value={reclaimerName}
                          placeholder='Reclaimer Name '
                          disabled={!isEdit}
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label'>Gender</label>
                        <input
                          type='text'
                          className='form-control form-control'
                          value={gender}
                          placeholder='Gender'
                          disabled={!isEdit}
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label'>Contact Number</label>
                        <input
                          type='text'
                          className='form-control form-control'
                          value={contactNumber ? contactNumber : '-'}
                          placeholder='Contact Number'
                          disabled={!isEdit}
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group group-i-input required'>
                        <label className='form-label'>Childrens</label>
                        <div className='input-group'>
                          <div className='input-right input-left-right'>
                            <select
                              name='measurement_types'
                              className='form-control form-select'
                              // value={boys}
                              disabled
                            >
                              <option value=''>{boys}</option>
                            </select>
                          </div>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              name='quantity'
                              placeholder='Boy'
                            />
                            <span className='error'></span>
                          </div>
                          <div className='input-right input-left-right'>
                            <select
                              name='measurement_types'
                              className='form-control form-select'
                              disabled
                              // value={girls}
                            >
                              <option value=''>{girls}</option>
                            </select>
                          </div>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              name='quantity'
                              placeholder='Girl'
                            />
                            <span className='error'></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label'>
                          Capacity Per Day (Kg)
                        </label>
                        <input
                          type='text'
                          className='form-control form-control'
                          value={capacityPerDay ? capacityPerDay : '-'}
                          placeholder='Capacity Per day'
                          disabled={!isEdit}
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label'>Small Scrap Dealer</label>
                        <input
                          type='text'
                          className='form-control form-control'
                          value={smallScrapDealer ? smallScrapDealer : '-'}
                          placeholder='Small Scrap Dealer'
                          disabled={!isEdit}
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='radio-buttons no-req'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='any_quanty_return'
                            id='yes-icon'
                            value='true'
                            data-gtm-form-interact-field-id='0'
                            checked={childrens === 'No'}
                            disabled
                          />
                          <label
                            className='form-check-label'
                            htmlFor='yes-icon'
                          >
                            No
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='any_quanty_return'
                            id='no-icon'
                            value='false'
                            data-gtm-form-interact-field-id='1'
                            disabled
                            checked={childrens === 'NA'}
                          />
                          <label className='form-check-label' htmlFor='no-icon'>
                            N/A
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label'>Collection Centre</label>
                        <select
                          name='material_type'
                          className='form-control form-select'
                          disabled
                          placeholder='Collection Center'
                          value={collectionCenter}
                        >
                          <option value=''>Collection Center</option>

                          <option value={collectionCenter}>
                            {collectionCenter}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-8'>
                      <div className='text-area-content'>
                        <div className='form-group group-i-input required'>
                          <label className='form-label'>Address</label>
                          <textarea
                            placeholder='Address'
                            name='waste_description'
                            className='form-control'
                            value={address ? address : '-'}
                            disabled={!isEdit}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label'>
                          Aadhaar Card Number
                        </label>
                        <input
                          type='text'
                          className='form-control form-control'
                          value={aadhaarNo}
                          placeholder='Aadhaar Number'
                          disabled={!isEdit}
                        />
                      </div>
                    </div>
                    {aadhaarPhoto && (
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <div className='mb-3'>
                            <label htmlFor='formFile' className='form-label'>
                              Aadhaar Card Photo
                            </label>
                            <div
                              className='upload-icons'
                              style={{
                                top: '0px',
                                justifyContent: 'flex-start',
                                marginTop: '10px',
                              }}
                            >
                              <i
                                className='fa fa-eye ms-2 me-2'
                                aria-hidden='true'
                                onClick={() => window.open(aadhaarPhoto)}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='col-md-4'>
                      <div className='form-group group-i-input required'>
                        <label className='form-label'>Birthdate</label>
                        <div className='input-group'>
                          <div className='input-right input-left-right birthday'>
                            <select
                              name='measurement_types'
                              className='form-control form-select'
                              disabled
                            >
                              <option value=''>{birthDate.day}</option>
                            </select>
                          </div>
                          <div className='input-right input-left-right birthday'>
                            <select
                              name='measurement_types'
                              className='form-control form-select'
                              disabled
                            >
                              <option value=''>{birthDate.month}</option>
                            </select>
                          </div>
                          <div className='input-right input-left-right birthday'>
                            <select
                              name='measurement_types'
                              className='form-control form-select'
                              disabled
                            >
                              <option value=''>{birthDate.year}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='display-component table-data-main'
                style={{ marginTop: 20, borderRadius: 20 }}
              >
                <div
                  className='row header-area d-flex align-items-center'
                  style={{ border: 'none' }}
                >
                  <div className='col-lg-3'>
                    <div className='title'>{translate('CSR_MODULE.TITLES.ACTIVITY_LIST')}</div>
                  </div>
                  <div className='col-lg-9'>
                    <div className='top-filters three-type row d-flex justify-content-end'>
                      <div className='date-item-picker item-in col-lg-4 me-0'>
                        <div className='date-picker-main'>
                          <div className='react-datepicker-wrapper'>
                            <div className='react-datepicker__input-container'>
                              <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                value={startDate ? startDate : ''}
                                className='form-control'
                                placeholderText={`${translate('CSR_MODULE.TITLES.SELECT_ACTIVITY_DATE')}`}
                                dateFormat={commanLabel.dateFormat}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                onChange={(update: any) => {
                                  setDateRange(update);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='item-in col-lg-3 mb-3 mb-sm-3 mb-md-3 mb-lg-0'>
                        <div className='input-search-item form-group mb-0'>
                          <input
                            className='form-control'
                            id='searchicon'
                            placeholder={`${translate('CSR_MODULE.TITLES.SEARCH')}`}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          <i className='fa fa-search'></i>
                        </div>
                      </div>
                      <div className='search-button item-in col-lg-5 mt-1 d-flex justify-content-end'>
                        <Button
                          role='button'
                          onClick={performSearch}
                          title='Search'
                          className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                        >
                          <i className='fa fa-search' aria-hidden='true'></i>
                        </Button>
                        <Button
                          role='button'
                          onClick={handeleReset}
                          title={`${translate('CSR_MODULE.TITLES.RESET')}`}
                          type='reset'
                          className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                        >
                          <i className='fa fa-refresh' aria-hidden='true'></i>
                        </Button>
                        <Button
                          title='Search'
                          className='btn btn-primary btn-sm btn btn-primary ms-3'
                          onClick={
                            () => setSubmitPopup(true)
                            // navigate(`${routesConstant.addReclaimer}`)
                          }
                        >
                          <i className='fa fa-calendar' aria-hidden='true'></i>{' '}
                          {translate('CSR_MODULE.TITLES.ADD_ACTIVITY')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='display-body ps-4 pe-4'
                  style={{ borderTop: '2px solid #3E9AB0' }}
                >
                  <div className=' transaction-table listuser-table '>
                    <div className='tab-content'>
                      <div className='listing-transaction'>
                        <div className='profile-history-main'>
                          {data && data.activity && data.activity.length > 0 ? (
                            <div>
                              {data.activity.map((u: any, i: any) => {
                                return (
                                  <div
                                    className='profile-log-inner'
                                    style={{ marginTop: '10px' }}
                                  >
                                    <div className='log-header'>
                                      {`${translate('CSR_MODULE.TITLES.RESET')}`} :{' '}
                                      {moment(u.activity_date).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </div>
                                    <div className='table-responsive pt-4'>
                                      <table className='table'>
                                        <tbody>
                                          <tr>
                                            <th>{translate('CSR_MODULE.TITLES.EVENT_NAME')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.EVENT_ID')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.DATE_OF_EVENT')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.ORGANISER')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.LOCATION')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.POC')}</th>
                                          </tr>
                                          <tr>
                                            <td>{u.csr_event.name}</td>
                                            <td>{u.csr_event.event_id}</td>
                                            <td>
                                              {moment(
                                                u.csr_event.event_date
                                              ).format('DD/MM/YYYY')}
                                            </td>
                                            <td>{u.csr_event.organizer}</td>
                                            <td>{u.csr_event.location}</td>
                                            <td>{u.csr_event.on_ground_poc}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className='table-responsive pb-4'>
                                      <table className='table'>
                                        <tbody>
                                          <tr>
                                            <th>{translate('CSR_MODULE.TITLES.ACTIVITY_ID')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.ACTIVITY_DATE')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.ACTIVITY_PHOTO')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.MATERIAL_PROVIDED')}</th>
                                          </tr>
                                          <tr>
                                            <td>{u.activity_id}</td>
                                            <td>
                                              {moment(u.activity_date).format(
                                                'DD/MM/YYYY'
                                              )}
                                            </td>
                                            <td>
                                              {u.activity_photo ? (
                                                <a
                                                  onClick={() =>
                                                    window.open(
                                                      u?.activity_photo
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className='fa fa-eye ms-2 me-2'
                                                    aria-hidden='true'
                                                  ></i>
                                                </a>
                                              ) : (
                                                '-'
                                              )}
                                            </td>
                                            <td>
                                              <div>
                                                {u?.csr_material.map(
                                                  (data: any, i: any) => {
                                                    return (
                                                      <span>
                                                        {data.name}
                                                        {i + 1 <
                                                          u?.csr_material
                                                            ?.length && ', '}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {isLoading && (
                                <h3 className='center-message-item'>
                                  {translate('CSR_MODULE.TITLES.LOADING')}
                                </h3>
                              )}
                              {data?.activity?.length === 0 && !isLoading && (
                                <h3 className='center-message-item'>
                                  {validationMessage.noRecordFound}
                                </h3>
                              )}
                            </div>
                          )}
                        </div>
                        {/* <div className='listing-content'>
                        <div className='table-footer'>
                          <div className='d-flex justify-content-between d-flex align-items-center'>
                            <div className='left-item text-left'>
                              Total Records : 1 / 1
                            </div>
                            <div className='right-item'>
                              <div className='items-center pagination-main gap-2 text-center'>
                                <button className='border rounded p-1'>
                                  &lt;
                                </button>
                                <ul className='pagination pagination-sm'>
                                  <li className='page-item active'>
                                    <span className='page-link'>1</span>
                                  </li>
                                </ul>
                                <button className='border rounded p-1'>
                                  &gt;
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                        {data?.activity?.length > 0 && (
                          <div className='items-center pagination-main gap-2 text-center'>
                            <PaginationControl
                              page={page}
                              between={4}
                              total={totalCount}
                              limit={pageLimit}
                              changePage={(page) => {
                                setPage(page);
                              }}
                              ellipsis={2}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewReclaimer;
