import _ from 'lodash';
import {
  certificateCategories,
  certificateType,
} from './constants/certificate.constant';
import {
  creditComponent,
  creditComponents,
} from './constants/credit-component.constant';
import { VolumeType } from './constants/volume-type.constant';
import { ILinkedTransactionInfo } from './interfaces/ILinkedTransactionInfo';
import { ITransactionToLink } from './interfaces/ITransactionToLink';

export const getCertificateTitleByIds = (certficates: certificateType[]) =>
  _.compact(
    _.map(certficates, (value: any) => certificateCategories[value]?.title),
  );
export const checkCertificateType = (
  creditComponentList: creditComponent[],
  typeOfCertificate: certificateType,
) => {
  if (creditComponentList.length > 0)
    return _.every(
      certificateCategories[typeOfCertificate].components,
      (comp: any) => creditComponentList.includes(comp),
    );
  return false;
};
export const checkCreditComponent = ({
  categories,
  creditComp,
}: {
  categories: string[];
  creditComp: creditComponent;
}) =>
  _.some(creditComponents[creditComp], (comp: any) =>
    categories.includes(comp),
  );

export const findCertificateType = (transactions: ITransactionToLink[]) => {
  const categories = _.map(transactions, 'buyer.category');
  const creditComponentsFound: creditComponent[] = [];
  _.map(creditComponents, (_val: any, key: creditComponent) => {
    if (checkCreditComponent({ categories, creditComp: key }))
      creditComponentsFound.push(+key);
  });
  let certificateTypes;
  _.map(certificateCategories, (_val: any, key: certificateType) => {
    if (checkCertificateType(creditComponentsFound, key))
      certificateTypes = +key;
  });
  return certificateTypes;
};
export const calculateCreditPoint = (unloadedQuantity?: volume) =>
  unloadedQuantity?.unit?.toLowerCase() === VolumeType.KG.toLowerCase()
    ? Number((unloadedQuantity?.quantity / 1000).toFixed(3))
    : unloadedQuantity?.quantity;
export const findCertificateTypeOfLinkedTransaction = (
  transactions: ITransactionToLink[],
  transactionIds: number[],
): ILinkedTransactionInfo | null => {
  const certificateTypes = findCertificateType(transactions);
  if (transactionIds.length > 0) {
    const firstTransaction = _.find(
      transactions,
      (trans: any) => trans.id === transactionIds[0],
    );
    // const lastTransaction = _.find(
    //   transactions,
    //   (trans: any) => trans.id === transactionIds[transactions.length - 1],
    // );
    const creditPoint = firstTransaction?.unloadedQuantity
      ? calculateCreditPoint(firstTransaction?.unloadedQuantity)
      : 0;
    const certificateDetail: any = certificateTypes
      ? certificateCategories[certificateTypes]
      : {};
    return {
      certificateType: certificateTypes,
      creditPoint: creditPoint ?? 0,
      buyerCategory: firstTransaction?.buyer.category,
      sellerCategory: firstTransaction?.seller.category,
      materialType: firstTransaction?.materialType,
      materilaNameType: firstTransaction?.materialNameType,
      buyerId: firstTransaction?.buyer?.id,
      sellerId: firstTransaction?.seller?.id,
      unloadedQuantity: firstTransaction?.unloadedQuantity?.quantity,
      unloadedQuantityUnit: firstTransaction?.unloadedQuantity?.unit,
      possibleCertificateTypes: getCertificateTitleByIds(
        certificateDetail?.otherPossibleTypes ?? [],
      ),
      wasteDescription:
        firstTransaction?.details?.basicDetails?.wasteDescription,
      associatedProjectId: firstTransaction?.associatedProject?.id,
    };
  }
  return null;
};
export const validateTransactionsToLink = (
  transactions: ITransactionToLink[],
  transactionIds: number[],
): any => {
  let prevAddedTransaction = _.find(
    transactions,
    (trans: any) => trans.id === transactionIds[0],
  );
  for (let i = 1; i < transactionIds.length; i++) {
    const id: number = transactionIds[i];
    const currentTransaction = _.find(
      transactions,
      (trans: any) => trans.id === id,
    );
    if (
      prevAddedTransaction?.buyer?.id !== currentTransaction?.seller?.id ||
      prevAddedTransaction?.materialType?.id !==
        currentTransaction?.materialType?.id
    )
      return {
        isValidated: false,
        isMaterialMismatched:
          prevAddedTransaction?.materialType?.id !==
          currentTransaction?.materialType?.id,
        isBuyerMismatched:
          prevAddedTransaction?.buyer?.id !== currentTransaction?.seller?.id,
      };
    prevAddedTransaction = currentTransaction;
  }
  return {
    isValidated: true,
  };
};
export const calculateQuantityToCreditPoint = (
  unit?: string,
  quantity?: number,
) => {
  if (unit?.toLowerCase() === VolumeType.KG.toLowerCase() && quantity) {
    return Number((quantity / 1000).toFixed(3));
  } else {
    return quantity;
  }
};

export const calculateCreditPointToQuantity = (
  unit?: string,
  creditPoint?: number,
) => {
  if (unit?.toLowerCase() === VolumeType.KG.toLowerCase() && creditPoint) {
    return Number((creditPoint * 1000).toFixed(3));
  } else {
    return creditPoint;
  }
};
