import { selectRowsFn } from '@tanstack/react-table';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export interface CertificatePopup {
  handleClose: any;
  selectedTransaction: any;
}

const CertificateList = (props: CertificatePopup) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    props.handleClose(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        style={{ padding: '0px !important' }}
        className='small-popup certificate-popup'
      >
        {/* <Modal.Body> */}
        <div
          // className="small-popup certificate-popup"
          id='pc--certificate'
          role='dialog'
          aria-labelledby='pc--certificateLabel'
          // aria-hidden="true"
        >
          <div role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Plastic Credit Certificates</h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={handleClose}
                >
                  <i className='fa fa-times' aria-hidden='true'></i>
                </button>
              </div>
              <div className='modal-body'>
                <div className='head-item'>
                  <div className='left'>Plastic Credit Certificates #</div>
                  <div className='right'>
                    <a href='#/'>*Click to open in new tab</a>
                  </div>
                </div>
                <div className='content-item'>
                  {/* <ul>
                    {props.selectedTransaction.linked_transaction_detail
                      .length > 0 &&
                      props.selectedTransaction.linked_transaction_detail.map(
                        (u: any, i: number) => {
                          return (
                            <li>
                              <Link
                                target='_blank'
                                to={`/admin/transaction/plasticCreditCertification/${window.btoa(
                                  u.transaction_id
                                )}`}
                                onClick={handleClose}
                              >
                                Plastic Credit Certificate {i + 1}
                              </Link>
                            </li>
                          );
                        }
                      )}
                  </ul> */}
                  <div className='popup-table'>
                    <table>
                      <tbody>
                        {props.selectedTransaction.plastic_credit_request_details_history
                          ?.filter((data: any) => {
                            return data.link_transaction_certificate_detail.length > 0;
                          })
                          .map((data: any, index: number) => {
                            return (
                              <tr>
                                <td>Batch {index + 1}</td>
                                <td>
                                  <div className='link-area row'>
                                    {data?.link_transaction_certificate_detail.length >
                                      0 &&
                                      data?.link_transaction_certificate_detail.map(
                                        (transaction: any, index: number) => {
                                          return (
                                            <div
                                              className='col-lg-6'
                                              style={{
                                                width:
                                                  data
                                                    ?.link_transaction_certificate_detail
                                                    .length === 1
                                                    ? '100%'
                                                    : '',
                                              }}
                                            >
                                              <Link
                                                target='_blank'
                                                to={`/admin/transaction/plasticCreditCertification/${window.btoa(
                                                  transaction?.linked_transaction_detail?.transaction_id
                                                )}&${window.btoa(
                                                  props?.selectedTransaction?.id
                                                )}`}
                                                onClick={handleClose}
                                              >
                                                Plastic Credit Certificate{' '}
                                                {index + 1}
                                              </Link>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                  {/* <div className='link-area row'>
                                    <div className='col-lg-6'>
                                      <a href='#'>
                                        Plastic Credit Certificate {index + 1}
                                      </a>
                                    </div>
                                    <div className='col-lg-6'>
                                      <a href='#'>
                                        Plastic Credit Certificate 1
                                      </a>
                                    </div>
                                    <div className='col-lg-6'>
                                      <a href='#'>
                                        Plastic Credit Certificate 1
                                      </a>
                                    </div>
                                    <div className='col-lg-6'>
                                      <a href='#'>
                                        Plastic Credit Certificate 1
                                      </a>
                                    </div>
                                  </div> */}
                                </td>
                              </tr>
                            );
                          })}

                        {/* <tr>
                          <td>Batch2</td>
                          <td>
                            <div className='link-area row'>
                              <div className='col-lg-6'>
                                <a href='#'>Plastic Credit Certificate 1</a>
                              </div>
                              <div className='col-lg-6'>
                                <a href='#'>Plastic Credit Certificate 1</a>
                              </div>
                              <div className='col-lg-6'>
                                <a href='#'>Plastic Credit Certificate 1</a>
                              </div>
                              <div className='col-lg-6'>
                                <a href='#'>Plastic Credit Certificate 1</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Batch3</td>
                          <td>
                            <div className='link-area row'>
                              <div className='col-lg-6'>
                                <a href='#'>Plastic Credit Certificate 1</a>
                              </div>
                              <div className='col-lg-6'>
                                <a href='#'>Plastic Credit Certificate 1</a>
                              </div>
                              <div className='col-lg-6'>
                                <a href='#'>Plastic Credit Certificate 1</a>
                              </div>
                              <div className='col-lg-6'>
                                <a href='#'>Plastic Credit Certificate 1</a>
                              </div>
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Modal.Body> */}
      </Modal>
    </>
  );
};

export default CertificateList;
