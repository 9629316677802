import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  isValidFile,
  socialWelfareTab,
} from '../../../shared/remake-green-utils';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import ConfirmationPopup from '../../../components/confirmationPopup';

import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { useCreateActivityMutation } from '../../../app/api/services/socialWelfare';
import moment from 'moment';
import { routesConstant } from '../../../constants/comman.constant';
import { createActivityValidator } from './activityValidator';

const AddActivity = () => {
  const { user_id } = useParams();
  const { state } = useLocation();

  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isSubmitted, setIsSubmited] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<any>([]);

  const [reclaimerDetails, setReclaimerDetails] = useState({
    csr_collection_center_id: null,
    csr_collection_center_name: '',
    reclaimerName: '',
    gender: '',
    contactNumber: '',
    girls: 0,
    boys: 0,
    capacityPerDay: '',
    smallScrapDealer: '',
    address: '',
    aadhaarNo: state ? state : '',
    aadhaarPhoto: '',
    birthDate: new Date(),
    childrens: '',
  });

  const [activityDetails, setActivityDetails] = useState({
    activity_photo: '',
    activity_date: new Date(),
    materialIds: null,
    otherMaterial: '',
    isOtherMaterial: false,
  });

  const [errors, setErrors] = useState<any>({
    reclaimerName: '',
    gender: '',
    childrens: '',
    aadhaarNo: '',
    aadhaarPhoto: '',
    eventName: '',
    eventDate: '',
    poc: '',
    dataProvider: '',
    organiser: '',
    location: '',
    activityPhoto: '',
    otherMaterial: '',
  });
  const [collectionCenterName, setCollectionCneterName] = useState<any>(null);
  const [eventName, setEventName] = useState<any>(null);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [collectionCenter, setCollectionCenter] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [documentFiles, setDocumentFiles] = useState<any>([]);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [reclaimerId, setReclaimerId] = useState(user_id ? user_id : 0);
  const [eventId, setEventId] = useState(0);
  const [addActivity] = useCreateActivityMutation();
  const [eventDetails, setEventDetails] = useState({
    eventName: '',
    eventDate: new Date(),
    isPOCDataProvider: false,
    dataProvider: '',
    organiser: '',
    location: '',
    poc: '',
  });
  const handleReclaimerDetails = (e: any) => {
    setReclaimerDetails({
      ...reclaimerDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleEventDetails = (e: any) => {
    setEventDetails({
      ...eventDetails,
      [e.target.name]: e.target.value,
    });
  };

  const getReclaimerDetails = async (id: string) => {
    let path = apiUrl.getReclaimerForActivity + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setReclaimerDetails({
        reclaimerName: res?.data?.name,
        gender: res?.data?.gender,
        address: res?.data?.address,
        aadhaarNo: res?.data?.aadhaar_no,
        csr_collection_center_id: res?.data?.csr_collection_center_id,
        csr_collection_center_name: res?.data?.csr_collection_center?.name,
        contactNumber: res?.data?.contact_number,
        aadhaarPhoto: res?.data?.aadhaar_photo,
        childrens: res?.data?.childrens,
        girls: res?.data?.girls,
        boys: res?.data?.boys,
        capacityPerDay: res?.data?.capacity_per_day,
        smallScrapDealer: res?.data?.small_scrap_dealer,
        birthDate: new Date(res?.data?.birth_date),
      });
      setCollectionCneterName({
        label: res?.data?.csr_collection_center?.name,
        value: res?.data?.csr_collection_center_id,
      });
    } else {
      toast.error(res.message);
    }
  };

  const getEventDetails = async (id: string) => {
    let path = apiUrl.getEventForActivity + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setEventDetails({
        eventName: res?.data?.name,
        location: res?.data?.location,
        poc: res?.data?.on_ground_poc,
        organiser: res?.data?.organizer,
        dataProvider: res?.data?.data_provider,
        isPOCDataProvider: res?.data?.is_on_ground_poc_data_provider,
        eventDate: new Date(res?.data?.event_date),
      });

      setEventName({
        label: res?.data?.event_id + ' ' + res?.data?.name,
        value: res?.data?.id,
      });
    } else {
      toast.error(res.message);
    }
  };
  // console.log('errors:::', errors);
  const validateForm = (
    reclaimerValue = reclaimerDetails,
    eventValue = eventDetails,
    activityValue = activityDetails,
    formSubmit = false
  ) => {
    let errs: any = {
      ...createActivityValidator(
        errors,
        eventValue,
        reclaimerValue,
        activityValue
      ),
    };
    setErrors(errs);
    // errs = { ...createEventValidator(errors, eventValue) };
    // setErrors(errs);
    const isFormValid = Object.keys(errs).every(
      (err) => errs[err].length === 0
    );
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      setSubmitPopup(true);
    }
  };

  const handleCollectionCenter = (option: any) => {
    if (option !== null) {
      setReclaimerDetails({
        ...reclaimerDetails,
        csr_collection_center_name: option.label,
        csr_collection_center_id:
          option.value === option.label ? 0 : option.value,
      });
      setCollectionCneterName(option);
    } else {
      setReclaimerDetails({
        ...reclaimerDetails,
        csr_collection_center_name: '',
        csr_collection_center_id: null,
      });
    }
  };

  const handleEvent = (option: any) => {
    if (option !== null) {
      let event_id = option.value === option.label ? 0 : option.value;
      setEventId(event_id);
      option.value !== option.label && getEventDetails(option.value);
      setEventName(option);
      setEventDetails({ ...eventDetails, eventName: option.label });
    } else {
      setEventName(null);
      setEventId(0);
      setEventDetails({
        eventName: '',
        location: '',
        poc: '',
        organiser: '',
        dataProvider: '',
        isPOCDataProvider: false,
        eventDate: new Date(),
      });
    }
  };
  const handleBirthDate = (date: any) => {
    setReclaimerDetails({ ...reclaimerDetails, birthDate: date });
  };

  const handleActivityDate = (date: any) => {
    setActivityDetails({ ...activityDetails, activity_date: date });
  };

  const handleEventDate = (date: any) => {
    setEventDetails({ ...eventDetails, eventDate: date });
  };

  const handleFileUploder = async (file: any, name: string) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', '');

    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res: any = await fr.response;
    if (res.success) {
      toast.success(res.message);
      await getFileUploaderData(res?.data?.data, name);
    } else {
      toast.error(res.message);
    }
  };

  const getFileUploaderData = async (dat: any, name: string) => {
    const tDoc: any = documentFiles || [];
    tDoc.push(dat);

    setDocumentFiles([...tDoc]);
    name == 'aadhaar_image'
      ? setReclaimerDetails({
          ...reclaimerDetails,
          aadhaarPhoto: dat.document_path,
        })
      : setActivityDetails({
          ...activityDetails,
          activity_photo: dat.document_path,
        });
  };

  const createActivity = async () => {
    let data = {
      event_details: {
        ...eventDetails,
        event_date: moment(eventDetails.eventDate).format('YYYY-MM-DD'),
      },
      reclaimer_details: {
        ...reclaimerDetails,
        date_of_birth: moment(reclaimerDetails.birthDate).format('YYYY-MM-DD'),
        girls: +reclaimerDetails.girls,
        boys: +reclaimerDetails.boys,
      },
      activity_date: moment(activityDetails.activity_date).format('YYYY-MM-DD'),
      activity_photo: activityDetails.activity_photo,
      materialIds: selectedMaterial,
      csr_event_id: eventId,
      csr_reclaimer_id: reclaimerId,
      otherMaterial: activityDetails.otherMaterial,
    };

    // const fr: any = await helper.formPost(data, path);
    const res = await addActivity(data).unwrap();
    if (res.success) {
      navigate(routesConstant.socialWelfare, {
        state: socialWelfareTab.ACTIVITIES,
      });
      toast.success('Activity Created Successfully !');
    } else {
      toast.error(res.message);
    }
  };

  const uploadDocument = async (e: any) => {
    let files = e?.target?.files || [];
    if (files[0] > commanValidation.maxFileLength) {
      toast.error(translate('ERRORS.INVALID.MAXIMUM_DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidFile(files[0].type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    await handleFileUploder(files[0], e.target.name);
  };

  const getCollectionCenterDetails = async () => {
    let path = apiUrl.getCollectionCenter;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      const newArray =
        temArray &&
        temArray.map((item: any) => {
          return { value: item.id, label: item.name };
        });
      newArray && setCollectionCenter(newArray);
    } else {
      toast.error(res.message);
    }
  };

  const getCSRMaterials = async () => {
    let path = apiUrl.getCSRMaterials;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      setMaterialList(temArray);
    } else {
      toast.error(res.message);
    }
  };
  const getEventList = async () => {
    let path = apiUrl.getEventList;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      const newArray =
        temArray &&
        temArray.map((item: any) => {
          return { value: item.id, label: item.event_id + ' - ' + item.name };
        });
      newArray && setEventList(newArray);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    getCollectionCenterDetails();
    getEventList();
    getCSRMaterials();
  }, []);

  useEffect(() => {
    if (user_id) {
      getReclaimerDetails(user_id);
    }
  }, [user_id]);
  // console.log('eventDetails:::', eventDetails);
  return (
    <>
      {submitPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.SUBMIT')}`}
          message={`${translate('CSR_MODULE.TITLES.SUBMIT_MESSAGE')}`}
          handleCancel={() => setSubmitPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.PROCEED')}`}
          handleSubmit={() => {
            createActivity();
            setSubmitPopup(false);
          }}
        />
      )}

      {discardPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          message={`${translate('CSR_MODULE.TITLES.DISCARD_TEXT')}`}
          handleCancel={() => setDiscardPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          handleSubmit={() => {
            !user_id &&
              setReclaimerDetails({
                csr_collection_center_id: null,
                csr_collection_center_name: '',
                reclaimerName: '',
                gender: '',
                contactNumber: '',
                girls: 0,
                boys: 0,
                capacityPerDay: '',
                smallScrapDealer: '',
                address: '',
                aadhaarNo: state ? state : '',
                aadhaarPhoto: '',
                birthDate: new Date(),
                childrens: '',
              });
            setEventId(0);

            setEventDetails({
              eventName: '',
              eventDate: new Date(),
              isPOCDataProvider: false,
              dataProvider: '',
              organiser: '',
              location: '',
              poc: '',
            });

            setDocumentFiles([]);
            setActivityDetails({
              activity_photo: '',
              activity_date: new Date(),
              materialIds: null,
              otherMaterial: '',
              isOtherMaterial: false,
            });
            setSelectedMaterial([]);

            setCollectionCneterName(null);
            setEventName(null);
            setDiscardPopup(false);
          }}
        />
      )}
      <div className='body-content-main pc-request'>
        <div className='container'>
          <nav aria-label='breadcrumb' className='breadcrumb-main'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  {translate('CSR_MODULE.TITLES.HOME')}
                </a>
              </li>
              <li
                className='breadcrumb-item'
                aria-current='page'
                onClick={() => {
                  navigate('/admin/social-welfare', {
                    state: socialWelfareTab.ACTIVITIES,
                  });
                }}
              >
                {translate('CSR_MODULE.TITLES.SOCIAL_WELFARE')}
              </li>
              <li className='breadcrumb-item active'>{translate('CSR_MODULE.TITLES.ADD_ACTIVITY')}</li>
            </ol>
          </nav>
          <div className='section-title-main'>
            <div className='title'>{translate('CSR_MODULE.TITLES.ADD_ACTIVITY')}</div>
          </div>
          <div className='transaction-table listuser-table csr-addreclaimer add-acitivty'>
            <div className='panel-head-title'>
              <div className='left-content'>
                <div className='title-normal'>{translate('CSR_MODULE.TITLES.NEW_ACTIVITY_DETAILS')}</div>
              </div>
              <div className='right-content'>
                <div className='form-submit-buttons auto-width-button'>
                  <button
                    type='button'
                    className='btn btn-sm discard'
                    onClick={() => {
                      setDiscardPopup(true);
                    }}
                  >
                    {translate('CSR_MODULE.TITLES.DISCARD')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-sm save-entry ms-1 ms-sm-3 ms-md-3 ms-lg-3 ms-xl-3'
                    onClick={() => {
                      setIsSubmited(true);
                      validateForm(
                        reclaimerDetails,
                        eventDetails,
                        activityDetails,
                        true
                      );
                    }}
                  >
                    {translate('CSR_MODULE.TITLES.SAVE_ENTRY')}
                  </button>
                </div>
              </div>
            </div>
            <div className='display-component main-profile-head'>
              <div className='activity-main'>
                <div className='activity-box'>
                  <div className='row d-flex align-items-center'>
                    <div className='col-md-6'>
                      <div className='left-area'>
                        <div className='title'>{translate('CSR_MODULE.TITLES.PROFILE_DETAILS')}</div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='right-area text-end'>
                        <p>
                          {/* <span>Date of entry:</span> 30/1/2023 02:30 PM */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='panel-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.RECLAIMER_NAME')}
                        </Form.Label>
                        <input
                          type='text'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_RECLAIMER_NAME')}`}
                          className='form-control form-control'
                          value={reclaimerDetails.reclaimerName}
                          name='reclaimerName'
                          onChange={handleReclaimerDetails}
                          disabled={user_id !== undefined}
                        />
                        <span className='error'>{errors.reclaimerName}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>{translate('CSR_MODULE.TITLES.GENDER')}</Form.Label>
                        <select
                          name='gender'
                          className='form-control form-select'
                          onChange={handleReclaimerDetails}
                          disabled={user_id !== undefined}
                          value={reclaimerDetails.gender.toLocaleLowerCase()}
                        >
                          <option value=''>{translate('CSR_MODULE.TITLES.SELECT_GENDER')}</option>
                          <option value='male'>{translate('CSR_MODULE.TITLES.MALE')}</option>
                          <option value='female'>{translate('CSR_MODULE.TITLES.FEMALE')}</option>
                        </select>
                        <span className='error'>{errors.gender}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.CONTACT_NUMBER')}
                        </Form.Label>
                        <input
                          type='text'
                          name='contactNumber'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_CONTACT_NUMBER')}`}
                          className='form-control form-control'
                          value={reclaimerDetails.contactNumber}
                          disabled={user_id !== undefined}
                          onChange={handleReclaimerDetails}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group group-i-input required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.CHILDRENS')}
                        </Form.Label>
                        <div className='input-group'>
                          <div className='input-right input-left-right'>
                            <select
                              name='boys'
                              className='form-control form-select'
                              value={reclaimerDetails.boys}
                              disabled={user_id !== undefined}
                              // onChange={handleReclaimerDetails}
                              onChange={(e) => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  boys: +e.target.value,
                                  childrens:
                                    reclaimerDetails.childrens == 'No' ||
                                    reclaimerDetails.childrens == 'NA'
                                      ? e.target.value + ''
                                      : +reclaimerDetails.childrens +
                                        +e.target.value +
                                        '',
                                });
                              }}
                            >
                              {[...Array(11)].map((x, i) => {
                                return <option value={i}>{i}</option>;
                              })}
                            </select>
                          </div>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              name='boy'
                              placeholder='Boy'
                            />
                            <span className='error'></span>
                          </div>
                          <div className='input-right input-left-right'>
                            <select
                              name='girls'
                              className='form-control form-select'
                              value={reclaimerDetails.girls}
                              disabled={user_id !== undefined}
                              // onChange={handleReclaimerDetails}
                              onChange={(e) => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  girls: +e.target.value,
                                  childrens:
                                    reclaimerDetails.childrens == 'No' ||
                                    reclaimerDetails.childrens == 'NA'
                                      ? e.target.value + ''
                                      : +reclaimerDetails.childrens +
                                        +e.target.value +
                                        '',
                                });
                              }}
                            >
                              {[...Array(11)].map((x, i) => {
                                return <option value={i}>{i}</option>;
                              })}
                            </select>
                          </div>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              name='girl'
                              placeholder={`${translate('CSR_MODULE.TITLES.GIRL')}`}
                            />
                            <span className='error'></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.CAPACITY_PER_KG')}
                        </Form.Label>
                        <input
                          type='text'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_CAPACITY_PER_DAY')}`}
                          className='form-control form-control'
                          disabled={user_id !== undefined}
                          name='capacityPerDay'
                          value={reclaimerDetails.capacityPerDay}
                          onChange={handleReclaimerDetails}
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group '>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.SMALL_SCRAP_DEALER')}
                        </Form.Label>
                        <input
                          type='text'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_SMALL_SCRAP_DEALER')}`}
                          className='form-control form-control'
                          disabled={user_id !== undefined}
                          name='smallScrapDealer'
                          value={reclaimerDetails.smallScrapDealer}
                          onChange={handleReclaimerDetails}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='radio-buttons no-req'>
                        <div
                          className='form-check'
                          onClick={() => {
                            if (!user_id) {
                              setReclaimerDetails({
                                ...reclaimerDetails,
                                childrens: 'No',
                                boys: 0,
                                girls: 0,
                              });
                            }
                          }}
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            name='any_quanty_return'
                            id='yes-icon'
                            value='true'
                            checked={reclaimerDetails.childrens === 'No'}
                            data-gtm-form-interact-field-id='0'
                          />
                          <Form.Label
                            className='form-check-label'
                            htmlFor='yes-icon'
                          >
                            {translate('CSR_MODULE.TITLES.NO')}
                          </Form.Label>
                        </div>
                        <div
                          className='form-check'
                          onClick={() => {
                            if (!user_id) {
                              setReclaimerDetails({
                                ...reclaimerDetails,
                                childrens: 'NA',
                                boys: 0,
                                girls: 0,
                              });
                            }
                          }}
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            name='any_quanty_return'
                            id='no-icon'
                            value='false'
                            data-gtm-form-interact-field-id='1'
                            checked={reclaimerDetails.childrens === 'NA'}
                          />
                          <Form.Label
                            className='form-check-label'
                            htmlFor='no-icon'
                          >
                            {translate('CSR_MODULE.TITLES.NA')}
                          </Form.Label>
                        </div>
                      </div>
                      <span className='error'>{errors.childrens}</span>
                    </div>
                  </div>

                  <div className='row mt-3'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.COLLECTION_CENTER')}
                        </Form.Label>

                        <CreatableSelect
                          isClearable
                          value={collectionCenterName}
                          onChange={handleCollectionCenter}
                          options={collectionCenter}
                          placeholder={`${translate('CSR_MODULE.TITLES.SELECT_COLLECTION_CENTER')}`}
                          isDisabled={user_id !== undefined}
                        />
                      </div>
                    </div>
                    <div className='col-md-8'>
                      <div className='text-area-content'>
                        <div className='form-group group-i-input'>
                          <Form.Label className='form-label'>
                            {translate('CSR_MODULE.TITLES.ADDRESS')}
                          </Form.Label>
                          <textarea
                            placeholder={`${translate('CSR_MODULE.TITLES.ADDRESS')}`}
                            name='address'
                            className='form-control'
                            value={reclaimerDetails.address}
                            onChange={handleReclaimerDetails}
                            disabled={user_id !== undefined}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.AADHAR_CARD_NUMBER')}
                        </Form.Label>
                        <input
                          type='text'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_AADHAR_NUMBER')}`}
                          className='form-control form-control'
                          name='aadhaarNo'
                          value={reclaimerDetails.aadhaarNo}
                          disabled={user_id !== undefined}
                          onChange={handleReclaimerDetails}
                        />
                        <span className='error'>{errors.aadhaarNo}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <div className='mb-3'>
                          <Form.Label htmlFor='formFile' className='form-label'>
                            {translate('CSR_MODULE.TITLES.AADHAR_CARD_PHOTO')}
                          </Form.Label>
                          {user_id ? (
                            <>
                              <div
                                className='upload-icons'
                                style={{
                                  top: '0px',
                                  justifyContent: 'flex-start',
                                  marginTop: '10px',
                                }}
                              >
                                <i
                                  className='fa fa-eye ms-2 me-2'
                                  aria-hidden='true'
                                  onClick={() =>
                                    window.open(reclaimerDetails.aadhaarPhoto)
                                  }
                                ></i>
                              </div>
                            </>
                          ) : (
                            <>
                              <Form.Control
                                className='file-input'
                                type='file'
                                onChange={uploadDocument}
                                name='aadhaar_image'
                                id='aadhaar_image'
                                accept='.jpg, .jpeg, .png'
                                disabled={user_id !== undefined}
                              />
                              {reclaimerDetails.aadhaarPhoto && (
                                <div className='upload-icons'>
                                  <i
                                    className='fa-solid fa-trash-can'
                                    onClick={() => {
                                      setReclaimerDetails({
                                        ...reclaimerDetails,
                                        aadhaarPhoto: '',
                                      });
                                    }}
                                  ></i>
                                  <i
                                    className='fa fa-eye ms-2 me-2'
                                    aria-hidden='true'
                                    onClick={() => {
                                      window.open(
                                        reclaimerDetails.aadhaarPhoto
                                      );
                                    }}
                                  ></i>
                                </div>
                              )}
                              {reclaimerDetails.aadhaarPhoto && (
                                <p className='upload-note text-end'>
                                  {translate('CSR_MODULE.TITLES.FILE_SUCESSFULLY_UPLOADED')}
                                  <svg
                                    width='800px'
                                    height='800px'
                                    viewBox='0 0 1024 1024'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      fill='#18A675'
                                      d='M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z'
                                    />
                                  </svg>
                                </p>
                              )}
                            </>
                          )}
                        </div>

                        <span className='error'>{errors.aadhaarPhoto}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group group-i-input required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.BIRTHDATE')}
                        </Form.Label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selected={reclaimerDetails.birthDate}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleBirthDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              required
                              disabled={user_id !== undefined}
                            />
                            {/* {
                            <span className='error'>
                              {translate(errors.loadingSlipDetails.date)}
                            </span>
                          } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='activity-box'>
                  <div className='row d-flex align-items-center'>
                    <div className='col-md-12'>
                      <div className='left-area'>
                        <div className='title'>{translate('CSR_MODULE.TITLES.EVENT_DETAILS')}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='panel-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.EVENT_NAME')}
                        </Form.Label>
                        <CreatableSelect
                          isClearable
                          value={eventName}
                          onChange={handleEvent}
                          options={eventList}
                          placeholder={`${translate('CSR_MODULE.TITLES.SELECT_EVENT')}`}

                          // isDisabled={user_id !== undefined}
                        />
                        <span className='error'>{errors.eventName}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group date-input'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.DATE_OF_EVENT')}
                        </Form.Label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selected={eventDetails.eventDate}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleEventDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              required
                              disabled={eventId !== 0 ? true : false}
                            />
                            {/* {
                            <span className='error'>
                              {translate(errors.loadingSlipDetails.date)}
                            </span>
                          } */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.ORGANISER')}
                        </Form.Label>
                        <input
                          type='text'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_ORGANISER_NAME')}`}
                          className='form-control form-control'
                          name='organiser'
                          value={eventDetails.organiser}
                          onChange={handleEventDetails}
                          disabled={eventId !== 0 ? true : false}
                        />
                        <span className='error'>{errors.organiser}</span>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.NAME_OF_POC')}
                        </Form.Label>
                        <input
                          type='text'
                          className='form-control form-control'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_POC_NAME')}`}
                          name='poc'
                          value={eventDetails.poc}
                          onChange={handleEventDetails}
                          disabled={eventId !== 0 ? true : false}
                        />
                        <span className='error'>{errors.poc}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.IN_A_POC_DATAPROVIDER')}
                        </Form.Label>

                        <div className='radio-buttons no-req'>
                          <div
                            className='form-check'
                            onClick={() => {
                              eventId == 0 &&
                                setEventDetails({
                                  ...eventDetails,
                                  isPOCDataProvider: true,
                                });
                            }}
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              name='isPOCDataProvider'
                              id='yes-icon'
                              value={'true'}
                              checked={eventDetails.isPOCDataProvider === true}
                              // onChange={() =>
                              //   setEventDetails({
                              //     ...eventDetails,
                              //     isPOCDataProvider: true,
                              //   })
                              // }
                              // disabled={eventId !== 0 ? true : false}

                              // disabled={id ? true : false}
                            />
                            <Form.Label
                              className='form-check-label'
                              htmlFor='yes-icon'
                            >
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.YES'
                              )}
                            </Form.Label>
                          </div>
                          <div
                            className='form-check'
                            onClick={() =>
                              eventId == 0 &&
                              setEventDetails({
                                ...eventDetails,
                                isPOCDataProvider: false,
                              })
                            }
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              name='isPOCDataProvider'
                              id='no-icon'
                              value={'false'}
                              checked={eventDetails.isPOCDataProvider === false}
                              // onChange={() =>
                              //   setEventDetails({
                              //     ...eventDetails,
                              //     isPOCDataProvider: false,
                              //   })
                              // }
                              // disabled={eventId !== 0 ? true : false}

                              // disabled={id ? true : false}
                            />
                            <Form.Label
                              className='form-check-label'
                              htmlFor='no-icon'
                            >
                              {translate('TRANSACTION_REGISTRY_PAGE.TITLES.NO')}
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {eventDetails.isPOCDataProvider === false && (
                      <div className='col-md-4'>
                        <div className='form-group required required'>
                          <Form.Label className='form-label'>
                            {translate('CSR_MODULE.TITLES.NAME_OF_DATAPROVIDER')}
                          </Form.Label>
                          <input
                            type='text'
                            className='form-control form-control'
                            placeholder={`${translate('CSR_MODULE.TITLES.ENTER_DATA_PROVIDER')}`}
                            name='dataProvider'
                            value={eventDetails.dataProvider}
                            onChange={handleEventDetails}
                            disabled={eventId !== 0 ? true : false}
                          />
                          <span className='error'>{errors.dataProvider}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>{translate('CSR_MODULE.TITLES.LOCATION')}</Form.Label>
                        <input
                          type='text'
                          placeholder={`${translate('CSR_MODULE.TITLES.ENTER_LOCATION')}`}
                          className='form-control form-control'
                          value={eventDetails.location}
                          name='location'
                          onChange={handleEventDetails}
                          disabled={eventId !== 0 ? true : false}
                        />
                        <span className='error'>{errors.location}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='activity-box'>
                  <div className='row d-flex align-items-center'>
                    <div className='col-md-12'>
                      <div className='left-area'>
                        <div className='title'>{translate('CSR_MODULE.TITLES.ACTIVIY_DETAILS')}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='panel-body'>
                  <div className='row'>
                    <div className='form-group required required'>
                      <Form.Label className='form-label'>
                        {translate('CSR_MODULE.TITLES.MATERIAL_PROVIDED')}
                      </Form.Label>
                    </div>
                    {materialList.length > 0 &&
                      materialList.map((data: any, i: any) => {
                        const id = data?.id;
                        return (
                          <div className='col-2 col-sm-6 col-md-4 col-lg-3 col-xl-2'>
                            <div className='form-check ps-0'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id={`defaultCheck${id}`}
                                checked={selectedMaterial.includes(id)}
                                value=''
                                onClick={() => {
                                  if (selectedMaterial.length === 0) {
                                    setSelectedMaterial([id]);
                                  } else if (selectedMaterial.includes(id)) {
                                    let newArray = selectedMaterial;
                                    newArray = newArray.filter(
                                      (u: any) => u !== id
                                    );
                                    setSelectedMaterial(newArray);
                                  } else {
                                    setSelectedMaterial([
                                      ...selectedMaterial,
                                      id,
                                    ]);
                                  }
                                }}
                              />
                              <Form.Label
                                className='form-check-label'
                                htmlFor={`defaultCheck${id}`}
                              >
                                {data.name}
                              </Form.Label>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className='row mt-4'>
                    <div className='col-2 col-sm-6 col-md-4 col-lg-3 col-xl-2'>
                      <div className='form-check ps-0'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='isOther'
                          value=''
                          checked={activityDetails.isOtherMaterial}
                          onClick={() => {
                            setActivityDetails({
                              ...activityDetails,
                              isOtherMaterial: !activityDetails.isOtherMaterial,
                            });
                          }}
                        />
                        <Form.Label
                          className='form-check-label'
                          htmlFor='isOther'
                        >
                          {translate('CSR_MODULE.TITLES.OTHER')}
                        </Form.Label>
                      </div>
                    </div>

                    {activityDetails.isOtherMaterial && (
                      <div className='col-md-3'>
                        <div className='form-group '>
                          <input
                            type='text'
                            className='form-control form-control'
                            placeholder={`${translate('CSR_MODULE.TITLES.ENTER_MATERIAL_NAME')}`}
                            name='otherMaterial'
                            value={activityDetails.otherMaterial}
                            onChange={(e) =>
                              setActivityDetails({
                                ...activityDetails,
                                otherMaterial: e.target.value,
                              })
                            }
                          />
                          <span className='error'>{errors.otherMaterial}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row mt-4'>
                    <div className='col-md-4'>
                      <div className='form-group group-i-input required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.DATE_OF_ACTIVITY')}
                        </Form.Label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selected={activityDetails.activity_date}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleActivityDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              required
                            />
                            {/* {
                            <span className='error'>
                              {translate(errors.loadingSlipDetails.date)}
                            </span>
                          } */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <div className='mb-3'>
                          <Form.Label htmlFor='formFile' className='form-label'>
                            {translate('CSR_MODULE.TITLES.ACTIVITY_PHOTO')}
                          </Form.Label>
                          <Form.Control
                            className='file-input'
                            type='file'
                            onChange={uploadDocument}
                            name='activity_image'
                            id='activity_image'
                            accept='.jpg, .jpeg, .png'
                          />
                          {activityDetails.activity_photo && (
                            <div className='upload-icons'>
                              <i
                                className='fa-solid fa-trash-can'
                                onClick={() => {
                                  setActivityDetails({
                                    ...activityDetails,
                                    activity_photo: '',
                                  });
                                }}
                              ></i>
                              <i
                                className='fa fa-eye ms-2 me-2'
                                onClick={() => {
                                  window.open(activityDetails.activity_photo);
                                }}
                                aria-hidden='true'
                              ></i>
                            </div>
                          )}
                          {activityDetails.activity_photo && (
                            <p className='upload-note text-end'>
                              {translate('CSR_MODULE.TITLES.FILE_SUCESSFULLY_UPLOADED')}
                              <svg
                                width='800px'
                                height='800px'
                                viewBox='0 0 1024 1024'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  fill='#18A675'
                                  d='M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z'
                                />
                              </svg>
                            </p>
                          )}
                        </div>
                        <span className='error'>{errors.activityPhoto}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddActivity;
