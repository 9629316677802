import { plasticCreditApi } from '..';
import { ServiceResponseInterface } from '../../types/serverResponse';
import { apiUrl } from './apiUrl';

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({
    createEvent: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.createEvent,
        method: 'POST',
        body: data,
      }),
    }),
    updateEvent: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.updateEvent,
        method: 'POST',
        body: data,
      }),
    }),
    createReclaimer: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.createReclaimer,
        method: 'POST',
        body: data,
      }),
    }),
    updateReclaimer: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.updateReclaimer,
        method: 'POST',
        body: data,
      }),
    }),
    createActivity: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.createActivity,
        method: 'POST',
        body: data,
      }),
    }),
    exportActivity: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.exportActivity,
        method: 'POST',
        body: data,
      }),
    }),
    exportActivityPdf: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.reclamer_pdf,
        method: 'POST',
        body: data,
      }),
    }),
    exportReclaimerPdf: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.peronal_reclamer_pdf,
        method: 'POST',
        body: data,
      }),
    }),
    exportSocialWelfare: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.exportexcelActivity,
        method: 'POST',
        body: data,
      }),
    }),
    exportcsrPdf: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.csr_reclamer_pdf,
        method: 'POST',
        body: data,
      }),
    }),
    exportPersonalPdf: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.csr_personal_pdf,
        method: 'POST',
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateEventMutation,
  useUpdateEventMutation,
  useCreateReclaimerMutation,
  useUpdateReclaimerMutation,
  useCreateActivityMutation,
  useExportActivityMutation,
  useExportActivityPdfMutation,
  useExportReclaimerPdfMutation,
  useExportSocialWelfareMutation,
  useExportcsrPdfMutation,
  useExportPersonalPdfMutation
} = extendedApi;
