import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { useCreateReMakeUserInquiryMutation } from '../../../app/api/services/createInquiryervice';
import { RootState } from '../../../app/types/store';
import { store } from '../../../app/store';
import {
  commanValidation,
  commonInput,
} from '../../../constants/comman.validation.constant';
import { useCreateInquiryMutation } from '../../../app/api/services/createInquiryervice';
import {
  commanLabel,
  measurementTypes,
  materialSubCategory,
  termsPDF,
  privacyPDF,
} from '../../../constants/comman.constant';
import {
  CreditRequestLable,
  validationMessage,
} from '../../../constants/validation.message.constant';
import { errorJson, userDetails } from './creditRequestProps';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ConfirmationPopup from '../../../components/confirmationPopup';
import { creditRequestValidator } from './creditRequestValidator';
import { isEmptyJSON } from '../../../utils/helper/common';
import {
  calculateCreditPointToQuantity,
  calculateQuantityToCreditPoint,
  certificateType,
  UserType,
} from '../../../shared/remake-green-utils/common';
import { useTranslation } from 'react-i18next';
import Header from '../layouts/header';
import { Footer } from '../layouts/footer';
import { userType } from '../../../constants/user.constant';

const CreditRequest = () => {
  const { id } = useParams();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [validated] = useState(false);
  const [userDetail, setuserDetail] = useState<any>(userDetails);
  const [materialTypeData, setMaterialTypeData] = useState([]);
  const [materialSubTypeData, setMaterialSubTypeData] = useState([]);
  const [regions, setRegions] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [preferredCurrency, setPreferredCurrency] = useState<any>([]);
  const [createInquiry] = useCreateInquiryMutation();
  const [roleType, setRoleType] = useState(false);
  const [creditValue, setCreditValue] = useState('');
  const [quantity, setQuantity] = useState('');
  const [discardPopup, setDiscardPopup] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [registeredUser, setRegisteredUser] = useState<any[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<any>(null);
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not
  const [errors, setErrors] = useState<any>(errorJson);
  const [createReMakeUserInquiry] = useCreateReMakeUserInquiryMutation();
  const [formDisabled, setFormDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const state: RootState = store.getState();
  const handleChange = (e: any) => {
    if (isSubmitted) {
      validateForm({
        ...userDetail,
        [e.target.name]: e.target.value,
        quantity,
        credit_value: creditValue,
      });
    }
    setuserDetail({
      ...userDetail,
      [e.target.name]: e.target.value,
    });
  };

  const creditPointcalculat = async (
    unit?: string,
    unloadedQuantity?: number
  ) => {
    let creditPoint = calculateQuantityToCreditPoint(unit, unloadedQuantity);
    setCreditValue(creditPoint ? creditPoint.toString() : '');
    if (isSubmitted) {
      validateForm({
        ...userDetail,
        quantity: unloadedQuantity,
        quantity_unit: unit,
      });
    }
  };

  const onChangeRole = (e: any) => {
    const checkValue = e.target.checked;
    setRoleType(!roleType);
    setuserDetail({
      ...userDetail,
      [e.target.name]: checkValue ? UserType.INDIVIDUAL : UserType.COMPANY,
      company_name: checkValue ? '' : userDetail.company_name,
      company_identification_number: checkValue
        ? ''
        : userDetail.company_identification_number,
      gst_number: checkValue ? '' : userDetail.gst_number,
      roleType: checkValue,
    });
  };

  const getSettingData = async () => {
    let path = apiUrl.getAllSetting;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let allSetting = res?.data && res?.data?.jsonData;
      setMaterialTypeData(allSetting?.Material_type);
      setPreferredCurrency(allSetting?.Preferred_Currency);
    } else {
      toast.error(res.message);
    }
  };

  const quantitCalculat = async (unit?: string, creditPoint?: number) => {
    let unloadedQuantity = calculateCreditPointToQuantity(unit, creditPoint);
    setQuantity(unloadedQuantity ? unloadedQuantity?.toString() : '');
    if (isSubmitted) {
      validateForm({
        ...userDetail,
        ['quantity']: unloadedQuantity,
        quantity_unit: unit,
      });
    }
  };

  const getMaterialSubCategory = async (materialId: string) => {
    let path = apiUrl.getMaterialSubCategory + materialId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let subCategory = res?.data?.allSubCategory;
      setMaterialSubTypeData(subCategory);
    } else {
      toast.error(res.message);
    }
  };

  const getRegions = async () => {
    let path = apiUrl.getRegions;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let regions = res?.data && res?.data?.countries;
      setRegions(regions);
    } else {
      toast.error(res.message);
    }
  };

  const getCountries = async () => {
    let path = apiUrl.getCountries;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let countries = res?.data && res?.data?.countries;
      setCountries(countries);
    } else {
      toast.error(res.message);
    }
  };

  const getStates = async (CountryId: any) => {
    let path = apiUrl.getStates + CountryId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let states = res?.data && res?.data?.states;
      setStates(states);
    } else {
      toast.error(res.message);
    }
  };

  const getCities = async (stateId: any) => {
    let path = apiUrl.getCities + stateId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let cities = res?.data && res?.data?.cities;
      setCity(cities);
    } else {
      toast.error(res.message);
    }
  };

  const validateForm = (userInfo = userDetail, formSubmit = false) => {
    const errs: any = creditRequestValidator(errors, userInfo);
    setErrors(errs);
    const isFormValid = isEmptyJSON(errs);
    if (formSubmit && isFormValid) {
      setSubmitPopup(true);
    }
    if (formSubmit && !isFormValid) {
      toast.error(validationMessage.mandatoryMessage);
    }
  };

  const onSubmit = async (e: any) => {
    if (
      selectedEmail &&
      selectedEmail.isRegisteredUser &&
      selectedEmail?.isRegisteredUser &&
      state.auth.isLoggedIn &&
      state.auth.user?.userTypeId === userType.ADMIN
    ) {
      const postJson = {
        ...userDetail,
        credit_type: parseInt(userDetail.credit_type),
        material_type_id: parseInt(userDetail.material_type_id),
        material_name_type_id: parseInt(userDetail.material_name_type_id),
        region_id: parseInt(userDetail.region_id),
        quantity: parseFloat(quantity),
        credit_value: parseFloat(creditValue),
        user_id: selectedEmail?.value,
      };

      const response = await createReMakeUserInquiry(postJson).unwrap();
      if (response.success) {
        toast.success(response.message);
        navigate('/');
      } else {
        toast.error(response.message);
      }
    } else {
      const postJson = {
        ...userDetail,
        credit_type: parseInt(userDetail.credit_type),
        material_type_id: parseInt(userDetail.material_type_id),
        material_name_type_id: parseInt(userDetail.material_name_type_id),
        region_id: parseInt(userDetail.region_id),
        country_id: parseInt(userDetail.country_id),
        state_id: parseInt(userDetail.state_id),
        city_id: parseInt(userDetail.city_id),
        quantity: parseFloat(quantity),
        credit_value: parseFloat(creditValue),
        requested_by_admin:
          state.auth.isLoggedIn &&
          state.auth.user?.userTypeId === userType.ADMIN
            ? true
            : false,
      };

      // this key I have removed as not required in API but required in FE changes
      delete postJson.roleType;

      try {
        const response = await createInquiry(postJson).unwrap();
        if (response.success) {
          toast.success(response.message);
          navigate('/');
        } else {
          toast.error(response.message);
        }
      } catch (err: any) {
        toast.error(err?.data?.message);
      }
    }
  };

  const getRegisteredUserData = async () => {
    let path = apiUrl.getUserList;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let registeredUserData = res?.data;
      var temArray: any[] = [];
      registeredUserData &&
        registeredUserData.map((item: any) => {
          temArray.push({
            value: item.id,
            label: item.email,
            isRegisteredUser: item.isRegisteredUser,
          });
        });
      setRegisteredUser(temArray || []);
      // valueChainPartnerOption(valueChainPartnerData);
    } else {
      toast.error(res.message);
    }
  };

  const getSelectedUserData = async (data: any) => {
    let path = apiUrl.getUserDetail;
    const params = {
      user_id: data.id,
      isRegisteredUser: data.isRegisteredUser,
    };
    const fr = await helper.getData(path, params);
    const res = await fr.response;
    if (res.success) {
      const response = res?.data?.userDetails;
      setuserDetail({
        ...userDetail,
        user_type: response?.user_type,
        name: response?.name,
        email: response?.email,
        secondary_email: response?.alternate_email,
        country_id: response?.address?.country?.id,
        state_id: response?.address?.state?.id,
        city_id: response?.address?.city?.id,
        zip_code: response?.address?.zip_code,
        billing_address: response?.address?.address,
        mobile_no: response?.contact_number,
        roleType: response?.user_type == userType.INDIVIDUAL ? true : false,
        company_name: response?.company?.company_name,
        company_identification_number:
          response?.company?.company_identification_no,
        gst_number: response?.company?.gst_vat_no,
        preferred_currency: response?.preferred_currency,
      });

      setRoleType(response?.user_type == userType.INDIVIDUAL ? true : false);
      getStates(response?.address?.country?.id);
      getCities(response?.address?.state?.id);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    getRegisteredUserData();
    getSettingData();
    getRegions();
    getCountries();
    if (id) {
      setuserDetail({
        ...userDetail,
        credit_type: window.atob(id),
      });
    }
  }, []);

  const onDisCard = () => {
    setuserDetail(userDetails);
    if (id) {
      setuserDetail({
        ...userDetails,
        credit_type: window.atob(id),
      });
    }
    setErrors(errorJson);
    setRoleType(false);
    setCreditValue('');
    setQuantity('');
  };
  const popupCenter = (url: any, title: any, w: any, h: any) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow: any = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    newWindow.focus();
  };
  const openPopup = (url: any, title: any, e: any) => {
    e.preventDefault();
    popupCenter(url, title, 900, 500);
  };
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const handleEmail = (option: any) => {
    setSelectedEmail(option);
    if (!option) {
      setFormDisabled(false);
      onDisCard();
    }
    if (!option.__isNew__) {
      state.auth.isLoggedIn && setFormDisabled(true);
      getSelectedUserData({
        id: option.value,
        isRegisteredUser: option.isRegisteredUser,
      });
    } else {
      onDisCard();
      setuserDetail({ ...userDetail, email: option.value });
      setFormDisabled(false);
    }
  };

  return (
    <div className='body-color'>
      <Header />
      <div className='container body-content-main'>
        <nav aria-label='breadcrumb' className='breadcrumb-main'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <Link to='/'>{translate('CREDIT_REQUEST_PAGE.TITLES.HOME')}</Link>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              {translate('CREDIT_REQUEST_PAGE.TITLES.CREDIT_REQUEST')}
            </li>
          </ol>
        </nav>
        <div className='panel-main big-panel request-page'>
          <h2 className='panel-title-main'>
            {translate('CREDIT_REQUEST_PAGE.TITLES.CREDIT_REQUEST')}
          </h2>
          <Form
            className='transaction-registry-form'
            id='userform'
            noValidate
            validated={validated}
          >
            <div className='panel-box-main'>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate('CREDIT_REQUEST_PAGE.TITLES.BASIC_DETAILS')}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-12 oneline-radio small-space'>
                      <div className='form-group required'>
                        <label className='label-single form-label'>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.SELECT_CREDIT_TYPE'
                          )}
                        </label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              id='ex11'
                              name='credit_type'
                              value={certificateType.COLLECTION.toString()}
                              checked={
                                userDetail.credit_type ===
                                certificateType.COLLECTION.toString()
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <label
                              htmlFor='ex11'
                              className='form-check-label form-label'
                            >
                              {translate(
                                'CREDIT_REQUEST_PAGE.TITLES.COLLECTION'
                              )}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              id='ex22'
                              name='credit_type'
                              value={certificateType.CIRCULAR.toString()}
                              checked={
                                userDetail.credit_type ===
                                certificateType.CIRCULAR.toString()
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <label
                              htmlFor='ex22'
                              className='form-check-label form-label'
                            >
                              {translate('CREDIT_REQUEST_PAGE.TITLES.CIRCULAR')}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              id='ex33'
                              name='credit_type'
                              value={certificateType.RECYCLING.toString()}
                              checked={
                                userDetail.credit_type ===
                                certificateType.RECYCLING.toString()
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <label
                              htmlFor='ex33'
                              className='form-check-label form-label'
                            >
                              {translate('CREDIT_REQUEST_PAGE.TITLES.RECYCLE')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12 '>
                      <div className='form-group required '>
                        <div className='toggle-button'>
                          <label className='label-single form-label'>
                            {translate('CREDIT_REQUEST_PAGE.TITLES.ROLE_TYPE')}
                          </label>
                          <div
                            className={`form-group-switch ${
                              roleType ? 'checked' : ''
                            }`}
                          >
                            <span className='left'>
                              {translate('CREDIT_REQUEST_PAGE.TITLES.COMPANY')}
                            </span>
                            <label className='switch '>
                              <input
                                type='checkbox'
                                name='user_type'
                                id='user_type'
                                checked={roleType}
                                onChange={onChangeRole}
                                disabled={formDisabled}
                              />
                              <span className='slider round' />
                            </label>
                            <span className='right'>
                              {translate(
                                'CREDIT_REQUEST_PAGE.TITLES.INDIVIDUAL'
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <label className='form-label label-info '>
                          {translate('CREDIT_REQUEST_PAGE.TITLES.USER_NAME')}
                          <i
                            className='fa fa-info-circle tooltip-icon-data'
                            title='Would be registered for credit generation'
                          />
                        </label>
                        <Form.Control
                          name='name'
                          placeholder={
                            translate(
                              'CREDIT_REQUEST_PAGE.TITLES.ENTER_USER_NAME'
                            ) + ''
                          }
                          type='text'
                          className='form-control form-control'
                          value={userDetail.name}
                          maxLength={commanValidation.maxLength}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                          disabled={formDisabled}
                        />
                        {<span className='error'>{errors.name}</span>}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6'>
                      <div className='form-group required'>
                        <label className='form-label label-info '>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.EMAIL_ADDRESS'
                          )}
                          <i
                            className='fa fa-info-circle tooltip-icon-data'
                            title='Would be registered for credit generation'
                          />
                        </label>
                        {/* <input
                          name='email'
                          placeholder={
                            translate(
                              'CREDIT_REQUEST_PAGE.TITLES.ENTER_EMAIL'
                            ) + ''
                          }
                          type='email'
                          className='form-control form-control'
                          value={userDetail.email}
                          maxLength={commanValidation.maxLength}
                          minLength={commanValidation.minLengthEmail}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        /> */}

                        {state.auth.isLoggedIn &&
                        state.auth.user?.userTypeId === userType.ADMIN ? (
                          <CreatableSelect
                            isClearable
                            value={selectedEmail}
                            onChange={(e) => {
                              handleEmail(e);
                            }}
                            options={registeredUser}
                            placeholder={translate(
                              'CREDIT_REQUEST_PAGE.TITLES.ENTER_EMAIL'
                            )}
                          />
                        ) : (
                          <input
                            name='email'
                            placeholder={
                              translate(
                                'CREDIT_REQUEST_PAGE.TITLES.ENTER_EMAIL'
                              ) + ''
                            }
                            type='email'
                            className='form-control form-control'
                            value={userDetail.email}
                            maxLength={commanValidation.maxLength}
                            minLength={commanValidation.minLengthEmail}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                          />
                        )}
                        {<span className='error'>{errors.email}</span>}
                      </div>
                    </div>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group '>
                        <Form.Label>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.SECONDARY_EMAIL'
                          )}
                        </Form.Label>
                        <input
                          name='secondary_email'
                          placeholder={
                            translate(
                              'CREDIT_REQUEST_PAGE.TITLES.ENTER_EMAIL'
                            ) + ''
                          }
                          type='email'
                          className='form-control form-control'
                          value={userDetail.secondary_email}
                          maxLength={commanValidation.maxLength}
                          minLength={commanValidation.minLengthEmail}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={formDisabled}

                          // required
                        />
                        {
                          <span className='error'>
                            {errors.secondary_email}
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                  {!roleType && (
                    <div className='row'>
                      <div className='col-md-4  col-sm-6'>
                        <div className='form-group required'>
                          <label className='form-label label-info '>
                            {translate(
                              'CREDIT_REQUEST_PAGE.TITLES.COMPANY_NAME'
                            )}
                            <i
                              className='fa fa-info-circle tooltip-icon-data'
                              title='Would be registered for credit generation'
                            />
                          </label>
                          <Form.Control
                            name='company_name'
                            placeholder={
                              translate(
                                'CREDIT_REQUEST_PAGE.TITLES.ENTER_COMPANY_NAME'
                              ) + ''
                            }
                            type='text'
                            className='form-control form-control'
                            value={userDetail.company_name}
                            maxLength={commanValidation.maxLength}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            disabled={formDisabled}
                            required
                          />
                          {<span className='error'>{errors.company_name}</span>}
                        </div>
                      </div>
                      <div className='col-md-4  col-sm-6'>
                        <div className='form-group required'>
                          <label className='form-label label-info '>
                            {translate(
                              'CREDIT_REQUEST_PAGE.TITLES.COMPANY_IDENTIFICATION_NUMBER'
                            )}
                            <i
                              className='fa fa-info-circle tooltip-icon-data'
                              title='To be used only for Invoice purposes'
                            />
                          </label>
                          <Form.Control
                            name='company_identification_number'
                            placeholder={
                              translate(
                                'CREDIT_REQUEST_PAGE.TITLES.ENTER_COMPANY_IDENTIFICATION_NUMBER'
                              ) + ''
                            }
                            type='text'
                            className='form-control form-control'
                            value={userDetail.company_identification_number}
                            maxLength={commanValidation.maxLength}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            disabled={formDisabled}
                            required
                          />
                          {
                            <span className='error'>
                              {errors.company_identification_number}
                            </span>
                          }
                        </div>
                      </div>
                      <div className='col-md-4  col-sm-6'>
                        <div className='form-group required'>
                          <label className='form-label label-info '>
                            {translate('CREDIT_REQUEST_PAGE.TITLES.GSTNUMBER')}
                            <i
                              className='fa fa-info-circle tooltip-icon-data'
                              title='To be used only for Invoice purposes'
                            />
                          </label>
                          <Form.Control
                            name='gst_number'
                            placeholder={
                              translate(
                                'CREDIT_REQUEST_PAGE.TITLES.ENTER_GSTNUMBER'
                              ) + ''
                            }
                            type='text'
                            className='form-control form-control'
                            value={userDetail.gst_number}
                            maxLength={commanValidation.maxLength}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            disabled={formDisabled}
                            required
                          />
                          {<span className='error'>{errors.gst_number}</span>}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='row'>
                    <div className='col-md-4 col-sm-12 combine-col '>
                      <div className='form-group required'>
                        <label className='form-label '>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.CONTACT_NUMBER'
                          )}
                        </label>
                        <div className='cell-item'>
                          <span className='prefix'>
                            {commanLabel.countryCode}
                          </span>
                          <input
                            type={'number'}
                            placeholder={
                              translate(
                                'CREDIT_REQUEST_PAGE.TITLES.ENTER_CONTACT_NUMBER'
                              ) + ''
                            }
                            className='form-control form-control'
                            name='mobile_no'
                            value={userDetail.mobile_no}
                            pattern={commanValidation.numberPattern}
                            max={commanValidation.mobileMaxLength}
                            min={commanValidation.mobileMinLength}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            disabled={formDisabled}
                            required
                          />
                          {<span className='error'>{errors.mobile_no}</span>}
                        </div>
                      </div>
                      <div className='form-group required'>
                        <label className='form-label'>
                          {translate('COMMONFIELDS.COUNTRY')}
                        </label>
                        <Form.Select
                          name='country_id'
                          className='form-control select-data form-select'
                          value={userDetail.country_id}
                          onChange={(e) => {
                            handleChange(e);
                            getStates(e.target.value);
                          }}
                          disabled={formDisabled}
                          required
                        >
                          <option value=''>
                            {translate('COMMONFIELDS.SELECT_COUNTRY')}
                          </option>
                          {countries?.length > 0 &&
                            countries?.map((u: any, i: any) => {
                              return (
                                <option key={i} value={u.id}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.country_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-8  col-sm-12'>
                      <div className='form-group required'>
                        <label className='form-label label-info '>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.BILLING_ADDRESS'
                          )}
                          <i
                            className='fa fa-info-circle tooltip-icon-data'
                            title='To be used only for Invoice purposes'
                          />
                        </label>
                        <textarea
                          name='billing_address'
                          className='form-control'
                          placeholder={
                            translate(
                              'CREDIT_REQUEST_PAGE.TITLES.BILLING_ADDRESS'
                            ) + ''
                          }
                          rows={6}
                          value={userDetail.billing_address}
                          maxLength={commanValidation.maxLengthAddress}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={formDisabled}
                          required
                        />
                        {
                          <span className='error'>
                            {errors.billing_address}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <label className='form-label'>
                          {translate('COMMONFIELDS.STATE')}
                        </label>
                        <Form.Select
                          name='state_id'
                          className='form-control select-data form-select'
                          value={userDetail.state_id}
                          onChange={(e) => {
                            handleChange(e);
                            getCities(e.target.value);
                          }}
                          required
                          disabled={
                            (userDetail.country_id ? false : true) ||
                            formDisabled
                          }
                        >
                          <option value=''>
                            {translate('COMMONFIELDS.SELECT_STATE')}
                          </option>
                          {states?.length > 0 &&
                            states?.map((u: any, i: any) => {
                              return (
                                <option key={i} value={u.id}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.state_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <label className='form-label'>
                          {translate('COMMONFIELDS.CITY')}
                        </label>
                        <Form.Select
                          name='city_id'
                          className='form-control select-data form-select'
                          value={userDetail.city_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={
                            (userDetail.state_id ? false : true) || formDisabled
                          }
                          required
                        >
                          <option value=''>
                            {translate('COMMONFIELDS.SELECT_CITY')}
                          </option>
                          {city?.length > 0 &&
                            city?.map((u: any, i: any) => {
                              return (
                                <option key={i} value={u.id}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.city_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6'>
                      <div className='form-group required'>
                        <label>{translate('COMMONFIELDS.ZIP_CODE')}</label>
                        <Form.Control
                          name='zip_code'
                          placeholder={
                            translate('COMMONFIELDS.ENTER_ZIP_CODE') + ''
                          }
                          type='text'
                          className='form-control form-control'
                          maxLength={commanValidation.maxZipCode}
                          value={userDetail.zip_code}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={formDisabled}
                          required
                        />
                        {
                          <span className='error'>
                            {translate(errors.zip_code)}
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate('CREDIT_REQUEST_PAGE.TITLES.PRODUCT_DETAILS')}
                </div>
                <div className='panel-data pb-sm-0'>
                  <div className='row'>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.MATERIAL_TYPE'
                          )}
                        </Form.Label>
                        <Form.Select
                          className='form-control'
                          name='material_type_id'
                          value={userDetail.material_type_id}
                          onChange={(e) => {
                            handleChange(e);
                            getMaterialSubCategory(e.target.value);
                          }}
                          required
                        >
                          <option value={''}>
                            {translate(
                              'CREDIT_REQUEST_PAGE.TITLES.SELECT_MATERIAL_TYPE'
                            )}
                          </option>
                          {materialTypeData?.length > 0 &&
                            materialTypeData?.map(
                              (item: any, index: number) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              }
                            )}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.material_type_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'CREDIT_REQUEST_PAGE.TITLES.MATERIAL_SUB_TYPE'
                          )}
                        </Form.Label>
                        <Form.Select
                          className='form-control'
                          name='material_name_type_id'
                          value={userDetail.material_name_type_id}
                          disabled={userDetail.material_type_id ? false : true}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        >
                          <option value={''}>
                            {translate(
                              'CREDIT_REQUEST_PAGE.TITLES.SELECT_MATERIAL_SUB_TYPE'
                            )}
                          </option>
                          {materialSubTypeData?.length > 0 &&
                            materialSubTypeData?.map(
                              (item: any, index: number) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              }
                            )}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.material_name_type_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <label className='form-label'>
                          {translate('COMMONFIELDS.REGION')}
                        </label>
                        <Form.Select
                          name='region_id'
                          className='form-control select-data form-select'
                          value={userDetail.region_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        >
                          <option value=''>
                            {translate('COMMONFIELDS.SELECT_REGION')}
                          </option>
                          {regions?.length > 0 &&
                            regions?.map((u: any, i: any) => {
                              return (
                                <option key={i} value={u.id}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.region_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-4  col-sm-6'>
                      <div className='form-group required'>
                        <label className='form-label'>Preferred Currency</label>
                        <Form.Select
                          className='form-control'
                          name='preferred_currency'
                          value={userDetail.preferred_currency}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          {preferredCurrency.length > 0 &&
                            preferredCurrency?.map(
                              (item: any, index: number) => {
                                return (
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              }
                            )}
                        </Form.Select>
                      </div>
                    </div>
                    <div className='col-md-8  col-sm-12'>
                      <div className='combine-option-text row'>
                        <div className='col-md-6 col-sm-6 left'>
                          <div className='form-group required'>
                            <label className='form-label'>
                              {translate(
                                'CREDIT_REQUEST_PAGE.TITLES.TOTAL_CREDIT'
                              )}
                            </label>
                            <input
                              type='number'
                              className='form-control form-control'
                              placeholder={
                                translate(
                                  'CREDIT_REQUEST_PAGE.TITLES.ENTER_TOTAL_CREDIT'
                                ) + ''
                              }
                              step={commanValidation.tonneMin}
                              min={commanValidation.buyerMinTonne}
                              pattern={commanValidation.numberPattern}
                              max={commanValidation.maxLengthCreditPoint}
                              value={creditValue}
                              onChange={(e) => {
                                setCreditValue(e.target.value);
                                quantitCalculat(
                                  userDetail.quantity_unit,
                                  Number(e.target.value)
                                );
                              }}
                              required
                            />

                            {
                              <span className='error'>
                                {translate(errors.credit_value)}
                              </span>
                            }
                          </div>
                        </div>
                        <div className='between-content'>
                          {translate('CREDIT_REQUEST_PAGE.TITLES.OR')}
                        </div>
                        <div className='col-md-6 col-sm-6 right'>
                          <div className='form-group required group-i-input'>
                            <Form.Label>
                              {translate('CREDIT_REQUEST_PAGE.TITLES.QUANTITY')}
                            </Form.Label>
                            <div className='input-group'>
                              <div className='input-left  input-left-right'>
                                <input
                                  name='quantity'
                                  type='number'
                                  placeholder={
                                    translate(
                                      'CREDIT_REQUEST_PAGE.TITLES.QUANTITY'
                                    ) + ''
                                  }
                                  className='form-control form-control'
                                  value={quantity}
                                  step={commanValidation.tonneMin}
                                  min={
                                    userDetail.quantity_unit ===
                                    commanLabel.kgLabel
                                      ? commanValidation.buyerMinKg
                                      : commanValidation.buyerMinTonne
                                  }
                                  pattern={commanValidation.numberPattern}
                                  max={commanValidation.maxQty}
                                  onChange={async (e) => {
                                    setQuantity(e.target.value);
                                    creditPointcalculat(
                                      userDetail.quantity_unit,
                                      Number(e.target.value)
                                    );
                                  }}
                                  required
                                />

                                {
                                  <span className='error'>
                                    {translate(errors.quantity)}
                                  </span>
                                }
                                <p className='upload-note'>
                                  {userDetail.quantity_unit ===
                                  commanLabel.kgLabel
                                    ? translate(
                                        'VALIDATION_CONSTANTS.BUYER_MIN_KG_MESSAGE'
                                      )
                                    : translate(
                                        'VALIDATION_CONSTANTS.BUYER_MIN_TONNE_MESSAGE'
                                      )}
                                </p>
                              </div>

                              <div className='input-right input-left-right'>
                                <Form.Select
                                  className='form-control'
                                  name='quantity_unit'
                                  value={userDetail.quantity_unit}
                                  onChange={async (e) => {
                                    await handleChange(e);
                                    await quantitCalculat(
                                      e.target.value,
                                      Number(creditValue)
                                    );
                                  }}
                                >
                                  {measurementTypes?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <option key={index} value={item.name}>
                                          {item.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </Form.Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-submit-buttons'>
                <div className='submit-note'>
                  {translate('CREDIT_REQUEST_PAGE.TITLES.LABEL1')}
                  <br />
                  {translate('CREDIT_REQUEST_PAGE.TITLES.LABEL2')}
                </div>
                <div className='button-main'>
                  <div className='submit-button-content'>
                    <div className='note'>
                      {userType.ADMIN !== state.auth?.user?.userTypeId && (
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            name='accepted_terms'
                            id='accepted_terms'
                            value={userDetail.accepted_terms}
                            checked={isChecked}
                            onChange={handleOnChange}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='accepted_terms'
                          >
                            {translate('REGISTER.TITLES.TERMSLABEL1')}&nbsp;
                          </label>
                          <a
                            className='no-space'
                            href='javascript:void(0)'
                            onClick={(e) => {
                              openPopup(termsPDF, 'terms', e);
                            }}
                            rel='noopener noreferrer'
                          >
                            {translate('REGISTER.TITLES.TERMSLABEL2')}
                          </a>{' '}
                          {translate('REGISTER.TITLES.AND')}{' '}
                          <a
                            href='javascript:void(0)'
                            onClick={(e) => {
                              openPopup(privacyPDF, 'terms', e);
                            }}
                            rel='noopener noreferrer'
                          >
                            {translate('REGISTER.TITLES.PRIVACY_POLICY')}
                          </a>
                          {
                            <span className='error'>
                              {translate(errors.accepted_terms)}
                            </span>
                          }
                        </div>
                      )}
                    </div>

                    <div className='buttons'>
                      <Button
                        className='btn btn-primary btn-md'
                        type='button'
                        disabled={
                          isChecked ||
                          userType.ADMIN === state.auth?.user?.userTypeId
                            ? false
                            : true
                        }
                        onClick={() => {
                          setIsSubmited(true);
                          validateForm(
                            {
                              ...userDetail,
                              quantity,
                              credit_value: creditValue,
                            },
                            true
                          );
                        }}
                      >
                        {translate('BUTTONS.SEND_REQUEST')}
                      </Button>
                      <Button
                        className='btn btn-primary btn-outline-primary btn-md'
                        type='button'
                        onClick={(e) => {
                          e.preventDefault();
                          setDiscardPopup(true);
                        }}
                      >
                        {translate('BUTTONS.DISCARD')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          {discardPopup && (
            <ConfirmationPopup
              title={translate('CREDIT_REQUEST_PAGE.TITLES.DISCARD')}
              message={translate('CREDIT_REQUEST_PAGE.TITLES.DISCARD_MESSAGE')}
              handleCancel={() => setDiscardPopup(false)}
              cancelText={translate('CREDIT_REQUEST_PAGE.TITLES.CANCEL') + ''}
              submitText={translate('CREDIT_REQUEST_PAGE.TITLES.SUBMIT') + ''}
              handleSubmit={() => {
                onDisCard();
                setDiscardPopup(false);
              }}
            />
          )}
          {submitPopup && (
            <ConfirmationPopup
              title={translate('CREDIT_REQUEST_PAGE.TITLES.SUBMIT')}
              message={translate('CREDIT_REQUEST_PAGE.TITLES.SUBMIT_MESSAGE')}
              handleCancel={() => setSubmitPopup(false)}
              cancelText={translate('CREDIT_REQUEST_PAGE.TITLES.CANCEL') + ''}
              submitText={translate('CREDIT_REQUEST_PAGE.TITLES.SUBMIT') + ''}
              handleSubmit={(e: any) => {
                onSubmit(e);
                setSubmitPopup(false);
              }}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CreditRequest;
