import { useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import { errorgeneratePassword, generatePasswords } from './generatePasswordProps';
import { commanValidation, passwordRuleValidation } from '../../../constants/comman.validation.constant';
import { generatePasswordValidator } from './generatePasswordValidator';
import { isEmptyJSON } from '../../../utils/helper/common';
import { useVerifyOtpMutation } from "../../../app/api/services/authentication.service";
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../app/hooks';
import { auth } from '../../../app/store/slices/auth.slice';
import { commanLabel, imagePath, routesConstant } from '../../../constants/comman.constant';

export const GeneratePassword = ({ updatePassword = false }) => {
    const navigate = useNavigate();
    const { t: translate } = useTranslation();
    const appDispatch = useAppDispatch();
    const [validated] = useState(false);
    const [generatePassword, setGeneratePassword] = useState(generatePasswords);
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not 
    const [errors, setErrors] = useState<any>(errorgeneratePassword);
    const [isNum, setIsNum] = useState(false);
    const [isUpper, setIsUpper] = useState(false);
    const [isLower, setIsLower] = useState(false);
    const [isSpecial, setIsSpecial] = useState(false);
    const [isLength, setIsLength] = useState(false);
    const [verifyOtp] = useVerifyOtpMutation();

    const onChange = (e: any) => {
        if (isSubmitted) {
            validateForm({ ...generatePassword, [e.target.name]: e.target.value });
        }
        setGeneratePassword({
            ...generatePassword,
            [e.target.name]: e.target.value,
        });
    };

    const onChangePassword = (e: any) => {
        const passwordValue = e.target.value;
        const lowerCase = passwordRuleValidation.lowerCase;
        const upperCase = passwordRuleValidation.upperCase;
        const number = passwordRuleValidation.number;
        const specialCharacter = passwordRuleValidation.specialCharacter;
        const characterLength = passwordRuleValidation.characterLength;
        const lowerCasePassword = lowerCase.test(passwordValue);
        const upperCasePassword = upperCase.test(passwordValue);
        const numberPassword = number.test(passwordValue);
        const specialCharacterPassword = specialCharacter.test(passwordValue);
        const characterLengthPassword = characterLength.test(passwordValue);
        lowerCasePassword ? setIsLower(true) : setIsLower(false);
        upperCasePassword ? setIsUpper(true) : setIsUpper(false);
        numberPassword ? setIsNum(true) : setIsNum(false);
        specialCharacterPassword ? setIsSpecial(true) : setIsSpecial(false);
        characterLengthPassword ? setIsLength(true) : setIsLength(false);

    };


    const togglePassword = () => {
        if (passwordType === commanLabel.password) {
            setPasswordType(commanLabel.text)
            return;
        }
        setPasswordType(commanLabel.password)
    }
    const toggleConfirmPassword = () => {
        if (confirmPasswordType === commanLabel.password) {
            setConfirmPasswordType(commanLabel.text)
            return;
        }
        setConfirmPasswordType(commanLabel.password)
    }


    const validateForm = (userInfo = generatePassword, formSubmit = false) => {
        const errs: any = generatePasswordValidator(errors, userInfo);
        setErrors(errs);
        const isFormValid = isEmptyJSON(errs);
        if (formSubmit && isFormValid) {
            onSubmit()
        }
        if (formSubmit && !isFormValid) {
            toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
        }
    }

    const onSubmit = async () => {
        const postJson = {
            email: localStorage.getItem('verifyEmail'),
            otp: parseInt(generatePassword.otp),
            password: generatePassword.password
        };
        try {
            const response = await verifyOtp(postJson).unwrap();
            if (response.success) {
                appDispatch(auth(response.data.token));
                localStorage.setItem("userId", response.data?.user?.id);
                toast.success(response.data.message);
                navigate(routesConstant.passwordUpdate);
            } else {
                toast.error(response.message);
            }
        }
        catch (err: any) {
            toast.error(err?.data?.data?.message);

        }
    }

    return (<>
        <div className="body-content-space pb-0">
            <section className="form">
                <div className="container">
                    <div className="form-data form-rule-screen">
                        <div className="row">
                            <div className="col-md-4">
                                <h2 className="title-main">
                                    <span>{updatePassword ? translate("UPDATE_PASSWORD.TITLES.UPDATE") : translate("FORGOT.TITLES.GENERATE")}</span> 
                                    {' '}
                                    {translate("FORGOT.TITLES.PASSWORD")}
                                </h2>
                                <Form className="signup-form" noValidate validated={validated}>
                                    <div className="form-data update-password">
                                        <div className="form-group  required">
                                            <label>{translate("COMMONFIELDS.OTP")}</label>
                                            <input type="number"
                                                className="form-control mypassword"
                                                name="otp"
                                                max={commanValidation.maxOtp}
                                                min={commanValidation.maxOtp}
                                                placeholder={translate("COMMONFIELDS.OTP") + ""}
                                                autoComplete="off"
                                                value={generatePassword.otp}
                                                onChange={onChange}
                                                required />
                                            {<span className="error">{translate(errors.otp)}</span>}
                                        </div>
                                        <div className="form-group  required">
                                            <label >{translate("COMMONFIELDS.NEW_PASSWORD")}</label>
                                            <div className="password-input">
                                                <input type={passwordType}
                                                    className="form-control mypassword"
                                                    name="password"
                                                    placeholder={translate("COMMONFIELDS.NEW_PASSWORD") + ""}
                                                    autoComplete="off"
                                                    aria-label="Password"
                                                    value={generatePassword.password}
                                                    onInput={onChangePassword}
                                                    onChange={onChange} />
                                                {<span className="error">{translate(errors.password)}</span>}
                                                <div className="eye-parent">
                                                    <i className={`fas ${passwordType !== "password" ? "fa-eye-slash eye" : "fa-eye eye"}`} onClick={togglePassword}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group  required mb-40">
                                            <label >{translate("COMMONFIELDS.CONFIRM_PASSWORD")}</label>
                                            <div className="password-input">
                                                <input type={confirmPasswordType}
                                                    className="form-control mypassword"
                                                    name="confirm_password"
                                                    placeholder={translate("COMMONFIELDS.CONFIRM_PASSWORD") + ""}
                                                    autoComplete="off"
                                                    aria-label="Password"
                                                    value={generatePassword.confirm_password}
                                                    onChange={onChange}
                                                    required />
                                                {<span className="error">{translate(errors.confirm_password)}</span>}
                                                <div className="eye-parent">
                                                    <i className={`fas ${confirmPasswordType !== "password" ? "fa-eye-slash eye" : "fa-eye eye"}`} onClick={toggleConfirmPassword}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="button-group submit-button">
                                            <Button type="button" className="btn btn-primary" onClick={() => {
                                                setIsSubmited(true);
                                                validateForm({ ...generatePassword }, true);
                                            }}>{translate("BUTTONS.SUBMIT")}</Button>
                                        </div>
                                    </div>
                                </Form>

                            </div>
                            <div className="col-md-7  offset-md-1  right-img-form">
                                <div className="right">

                                    <Image src={imagePath.formImgPath} />

                                    <div className="password-errors">
                                        <p>{translate("PASSWORDRULES.PASSWORD_RULES")}</p>
                                        <ul>
                                            <li className={isLower ? "true" : "false"}>{translate("PASSWORDRULES.LABEL1")}<div className="icon-data"><i className={isLower ? "fa fa-check" : "fa fa-times"} aria-hidden="true" /></div> </li>
                                            <li className={isUpper ? "true" : "false"}>{translate("PASSWORDRULES.LABEL2")}<div className="icon-data"><i className={isUpper ? "fa fa-check" : "fa fa-times"} aria-hidden="true" /></div></li>
                                            <li className={isNum ? "true" : "false"}>{translate("PASSWORDRULES.LABEL3")}<div className="icon-data"><i className={isNum ? "fa fa-check" : "fa fa-times"} aria-hidden="true" /></div></li>
                                            <li className={isSpecial ? "true" : "false"}>{translate("PASSWORDRULES.LABEL4")}<div className="icon-data"><i className={isSpecial ? "fa fa-check" : "fa fa-times"} aria-hidden="true" /></div></li>
                                            <li className={isLength ? "true" : "false"}>{translate("PASSWORDRULES.LABEL5")}<div className="icon-data"><i className={isLength ? "fa fa-check" : "fa fa-times"} aria-hidden="true" /></div></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
    )
}