import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import moment from 'moment';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { useGenerateCertificateMutation } from '../../../app/api/services/transaction.service';
import { Link, useNavigate } from 'react-router-dom';
import ConfirmationPopup from '../../../components/confirmationPopup';
import {
  commanLabel,
  quantityUnitConstants,
} from '../../../constants/comman.constant';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { measurementTypes } from '../../../constants/comman.constant';
import { validationMessage } from '../../../constants/validation.message.constant';
import { certificateCategories } from '../../../shared/remake-green-utils/common';
import { generateCertificateValidator } from './generateCertificateValidator';
import { useTranslation } from 'react-i18next';
import { disableScrollForTypeNumber } from '../../../utils/utilFunctions';
import { getCreditType } from '../../../constants/comman.constant';
import QuantityComponent from './quantityComponent';

const UserType = [
  {
    title: 'INDIVIDUAL',
    value: 4,
  },
  {
    title: 'COMPANY',
    value: 5,
  },
];
const GenerateCertificate = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [selectedTransaction, setSelectedTransaction] = useState<any>([]);

  const [value, setValue] = useState({
    regionId: null,
    materialTypeId: '',
    categoryTypeId: null,
    quantity: '',
    quantityUnit: quantityUnitConstants.tonne,
    userType: 4,
    userName: '',
    userEmail: '',
    creditType: 0,
    transactionIds: [] as any,
    remark: '',
  });
  const [selectedPage, setSelectedPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [materialType, setMaterialType] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [countries, setCountries] = useState<any>([]);
  const [quantityValidation, setQuantityValidation] = useState('');
  const [materialSubCategory, setMaterialSubCategory] = useState<any>([]);
  const [errorData, setErrorData] = useState({ title: '', message: '' });
  const [fromSelectedTransactions, setFromSelectedTransactions] = useState(0);
  const [errors, setErrors] = useState<any>({
    regionId: null,
    materialTypeId: null,
    categoryTypeId: null,
    quantity: '',
    userName: '',
    userEmail: '',
    transactionIds: '',
    quantityToBeUsed: '',
  });
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not

  const [generateCertificate] = useGenerateCertificateMutation();
  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor('-', {
      header: () => translate('') + '',
      cell: (props: any) => {
        const id = props.row.original.id;
        return (
          <React.Fragment>
            {props?.row?.original?.link_transaction_certificate_detail
              ?.length ? (
              <a
                href='javascript:void(0);'
                className='description-btn'
                id={`desc-btn-${id}`}
                onClick={() => {
                  let data = document.getElementById(`desc-content-${id}`);

                  if (data?.classList.contains('active')) {
                    data.classList.remove('active');
                  } else {
                    data && data?.classList?.add('active');
                  }
                  let btnData = document.getElementById(`desc-btn-${id}`);
                  if (btnData?.classList.contains('btn-active')) {
                    btnData.classList.remove('btn-active');
                  } else {
                    btnData && btnData?.classList?.add('btn-active');
                  }
                  let rowActiveData = document.getElementById(
                    `transaction-row-${id}`
                  );
                  if (rowActiveData?.classList.contains('active-row')) {
                    rowActiveData.classList.remove('active-row');
                  } else {
                    rowActiveData &&
                      rowActiveData?.classList?.add('active-row');
                  }
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/down-arrow-svg.svg`}
                />
              </a>
            ) : (
              ''
            )}
          </React.Fragment>
        );
      },
    }),
    columnHelper.accessor('-', {
      header: () => '',
      cell: (props: any) => {
        const { id } = props?.row?.original;
        // console.log('props.row.original:::', props.row.original);
        return (
          <React.Fragment>
            <div
              className='form-check'
              onClick={() => {
                if (value.transactionIds.length === 0) {
                  setValue({
                    ...value,
                    transactionIds: [id],
                  });
                  setSelectedTransaction([
                    {
                      ...props.row.original,
                      selectedQuantity: 0,
                      quantityError: '',
                    },
                  ]);
                } else {
                  if (value.transactionIds.includes(id)) {
                    let newArray = value.transactionIds.filter(
                      (data: number) => {
                        return data !== id;
                      }
                    );

                    let newArray1 = selectedTransaction.filter((data: any) => {
                      return data.id !== id;
                    });

                    setValue({
                      ...value,
                      transactionIds: newArray,
                    });

                    setSelectedTransaction([...newArray1]);
                  } else {
                    setValue({
                      ...value,
                      transactionIds: [...value.transactionIds, id],
                    });

                    setSelectedTransaction([
                      ...selectedTransaction,
                      { ...props.row.original, selectedQuantity: 0 },
                    ]);
                  }
                }
              }}
            >
              <input
                className='form-check-input'
                type='checkbox'
                id='tr-id-1'
                onChange={() => {}}
                checked={value.transactionIds.includes(id)}
              />
              <label className='form-check-label'></label>
            </div>
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor('transaction_id', {
      header: () => 'Transaction ID',
    }),

    columnHelper.accessor('material_type.name', {
      header: () => 'Material Type',
    }),

    columnHelper.accessor('material_name_type.name', {
      header: () => 'Material Sub Type',
    }),

    columnHelper.accessor(
      (row) => `${row.unloaded_quantity} ${row.unloaded_quantity_unit}`,
      {
        id: ' Qty.',
      }
    ),

    columnHelper.accessor('credit_generated', {
      header: () => 'Total Credits',
    }),

    columnHelper.accessor('plastic_credit_type.name', {
      header: () => 'Types of Credits',
    }),

    columnHelper.accessor((row) => `${row.remaining_quantity} Kg`, {
      id: 'Remaining Qty.',
    }),
    columnHelper.accessor((row) => `${row?.buyer && row?.buyer?.name}`, {
      id: 'Buyer',
    }),

    columnHelper.accessor((row) => `${row?.seller && row?.seller?.name}`, {
      id: 'Seller',
    }),
  ];

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const table = useReactTable({
    data: data,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const getTransactionList = async () => {
    setLoading(true);
    let path = apiUrl.getTrasactionLinkedList;
    const param = {
      creditType: value.creditType,
      isForGenerateCertificate: true,
      page: page,
      limit: pageLimit,
      materialTypeId: value.materialTypeId,
    };
    const fr = await helper.getData(path, param);
    const res = await fr.response;
    if (res.success) {
      setLoading(false);
      let transaction = res?.data?.allTransaction;
      setData([...data, ...transaction]);
      setDataCount(res?.data?.total);
      setPageCount(res?.data?.total && Math.ceil(res.data.total / pageLimit));
    } else {
      setLoading(false);
      toast.error(res.message);
    }
  };

  const getMaterialSubCategory = async (materialId: string) => {
    let path = apiUrl.getMaterialSubCategory + materialId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let subCategory = res?.data?.allSubCategory;
      setMaterialSubCategory(subCategory);
    } else {
      toast.error(res.message);
    }
  };

  const fetchMoreData = () => {
    setPage((page) => page + 1);
  };

  const getSettingData = async () => {
    let path = apiUrl.getAllSetting;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let allSetting = res?.data && res?.data?.jsonData;
      setMaterialType(allSetting?.Material_type);
    } else {
      toast.error(res.message);
    }
  };

  const getRegions = async () => {
    let path = apiUrl.getRegions;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let regions = res?.data && res?.data?.countries;
      setCountries(regions);
    } else {
      toast.error(res.message);
    }
  };

  const onChange = (e: any) => {
    if (isSubmitted) {
      validateForm({ ...value, [e.target.name]: e.target.value });
    }
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getSettingData();
    getRegions();
  }, []);

  useEffect(() => {
    if (value.creditType) {
      getTransactionList();
    }
  }, [page]);

  useEffect(() => {
    if (value.creditType) {
      getTransactionList();
    }
  }, [value.creditType]);

  const selectedTransactionQuantity = useMemo(() => {
    let count = 0;
    selectedTransaction.map((u: any, i: any) => {
      if (u.unloaded_quantity_unit.toUpperCase() === 'KG') {
        count = +u.unloaded_quantity + count;
      } else if (
        u.unloaded_quantity_unit.toUpperCase() === quantityUnitConstants.TON ||
        u.unloaded_quantity_unit.toUpperCase() === quantityUnitConstants.TONNE
      ) {
        count = +u.unloaded_quantity * 1000 + count;
      }
    });
    console.log(count);
    return count;
  }, [selectedTransaction]);

  useEffect(() => {
    if (fromSelectedTransactions === 1) {
      setValue({ ...value, quantity: selectedTransactionQuantity + '' });
    }
  }, [selectedTransactionQuantity]);

  const selectedQunatityKg = useMemo(() => {
    let count = 0;
    if (value.quantityUnit.toUpperCase() == 'KG') {
      count = parseFloat(value.quantity);
    } else if (
      value.quantityUnit.toUpperCase() == quantityUnitConstants.TON ||
      value.quantityUnit.toUpperCase() == quantityUnitConstants.TONNE
    ) {
      count = parseFloat(value.quantity) * 1000;
    }

    return count || 0;
  }, [value.quantity, value.quantityUnit]);

  const validateForm = (certiValue = value, formSubmit = false) => {
    const errs: any = generateCertificateValidator(
      errors,
      certiValue,
      selectedTransaction,
      setSelectedTransaction
    );
    setErrors(errs);
    const isFormValid = Object.keys(errs).every(
      (err) => errs[err].length === 0
    );
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    if (value.transactionIds.length === 0) {
      setErrorData({
        title: validationMessage.TRANSACTION_REQUIRED,
        message: validationMessage.ATLEASE_ONE_TRANSACTION,
      });
      setErrorPopup(true);
    } else {
      setSubmitPopup(true);
    }
  };

  return (
    <>
      {discardPopup && (
        <ConfirmationPopup
          title='Discard'
          message='Are you sure you want to Discard?'
          handleCancel={() => setDiscardPopup(false)}
          cancelText='Cancel'
          submitText='Discard'
          handleSubmit={() => {
            setValue({
              regionId: null,
              materialTypeId: '',
              categoryTypeId: null,
              quantity: '',
              quantityUnit: '',
              userType: 4,
              userName: '',
              userEmail: '',
              creditType: 1,
              transactionIds: [] as any,
              remark: '',
            });
            setDiscardPopup(false);
          }}
        />
      )}

      {errorPopup && (
        <ConfirmationPopup
          title={errorData.title}
          message={errorData.message}
          handleCancel={() => setErrorPopup(false)}
          cancelText='Cancel'
          submitText='OK'
          hideCancelButton={true}
          handleSubmit={() => {
            setErrorPopup(false);
            // errorData.title === "Success" &&
            //   navigate("/admin/plasticCreditRequest");
          }}
        />
      )}

      {submitPopup && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to Proceed?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setSubmitPopup(false)}
          handleSubmit={() => {
            generateCertificate({
              ...value,
              quantityToBeUsed: selectedTransaction.map((u: any, i: any) => {
                return +u.selectedQuantity;
              }),
            })
              .then((res: any) => {
                if (res?.data?.success && res?.data?.statusCode === 201) {
                  toast.success(res?.data?.message);
                  navigate('/admin/plasticCreditRequest');
                } else {
                  setErrorData({
                    title: 'Transaction Failed',
                    message: `Something went wrong! Please check the entered details for this transaction.`,
                  });
                  setErrorPopup(true);
                }
              })
              .catch((err: any) => {
                setErrorData({
                  title: err?.messageCode,
                  message: 'Something went wrong.',
                });
                setErrorPopup(true);
              });
            setSubmitPopup(false);
          }}
        />
      )}
      <div className='container body-content-main pc-request'>
        <div className='panel-main big-panel'>
          <h2 className='panel-title-main'>Generate Certificate</h2>
          <Form
            className='transaction-registry-form'
            // id="transactionregistryform"
            noValidate
            validated={validated}
          >
            <div className='panel-box-main'>
              <div className='panel-box'>
                <div className='panel-title'>User Info</div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-12 oneline-radio'>
                      <div className='form-group required'>
                        <Form.Label className='label-single'>
                          Choose your role
                        </Form.Label>
                        <div className='radio-buttons no-req'>
                          {UserType.map((u, i) => {
                            return (
                              <div
                                key={i}
                                className='form-check'
                                onClick={() => {
                                  setValue({ ...value, userType: u.value });
                                }}
                              >
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='exampleRadios'
                                  id='role-individual'
                                  value='option1'
                                  onChange={() => {}}
                                  checked={value.userType === u.value}
                                />
                                <Form.Label className='form-check-label'>
                                  {u.title}
                                </Form.Label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label>
                          {value.userType === 5
                            ? commanLabel.companyNameLabel
                            : commanLabel.userNameLabel}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          name='userName'
                          placeholder={
                            value.userType === 5
                              ? commanLabel.companyName
                              : commanLabel.userName
                          }
                          value={value.userName}
                          maxLength={commanValidation.maxLength}
                          onChange={onChange}
                          required
                        />
                        {<span className='error'>{errors.userName}</span>}
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label>Email Id</Form.Label>
                        <Form.Control
                          type='email'
                          className='form-control'
                          name='userEmail'
                          onChange={onChange}
                          value={value.userEmail}
                          maxLength={commanValidation.maxLength}
                          placeholder='Enter Email'
                          required
                        />
                        {<span className='error'>{errors.userEmail}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>Product Info</div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label>Material Type</Form.Label>
                        <Form.Select
                          className='form-control'
                          name='materialTypeId'
                          value={value?.materialTypeId}
                          onChange={(e) => {
                            setSelectedTransaction([]);
                            setData([]);
                            if (isSubmitted) {
                              validateForm({
                                ...value,
                                [e.target.name]: e.target.value,
                              });
                            }
                            setValue({
                              ...value,
                              creditType: 0,
                              transactionIds: [],
                              [e.target.name]: parseInt(e.target.value),
                            });

                            getMaterialSubCategory(e.target.value);
                          }}
                          required
                        >
                          <option value=''>Select Material Type</option>

                          {materialType?.length > 0 &&
                            materialType?.map((u: any, i: any) => {
                              return (
                                <option key={u.id} value={u.id}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {<span className='error'>{errors.materialTypeId}</span>}
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label>Material Sub Type</Form.Label>

                        <Form.Select
                          className='form-control'
                          name='categoryTypeId'
                          onChange={(e) => {
                            if (isSubmitted) {
                              validateForm({
                                ...value,
                                [e.target.name]: e.target.value,
                              });
                            }
                            setValue({
                              ...value,
                              [e.target.name]: parseInt(e.target.value),
                            });
                          }}
                          required
                          disabled={!value.materialTypeId}
                        >
                          <option value=''>Select Material Sub Type</option>

                          {materialSubCategory.length > 0 &&
                            materialSubCategory.map((u: any, i: any) => {
                              return (
                                <option key={u.id} value={u.id}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {<span className='error'>{errors.categoryTypeId}</span>}
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label>Region</Form.Label>
                        <Form.Select
                          className='form-control select-data'
                          name='regionId'
                          onChange={(e) => {
                            if (isSubmitted) {
                              validateForm({
                                ...value,
                                [e.target.name]: e.target.value,
                              });
                            }
                            setValue({
                              ...value,
                              [e.target.name]: parseInt(e.target.value),
                            });
                          }}
                          required
                        >
                          <option value=''>Select</option>
                          {countries?.length > 0 &&
                            countries?.map((u: any, i: any) => {
                              return (
                                <option key={i} value={u.id}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {<span className='error'>{errors.regionId}</span>}
                      </div>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                      <div className='form-group required group-i-input'>
                        <Form.Label>Quantity</Form.Label>

                        <div className='input-group'>
                          <div className='input-left  input-left-right'>
                            <Form.Control
                              type='number'
                              className='form-control'
                              placeholder='Quantity'
                              value={value.quantity}
                              name='quantity'
                              // step={commanValidation.tonneMin}
                              // min={
                              //   value.quantityUnit === commanLabel.kgLabel
                              //     ? commanValidation.kgMin
                              //     : commanValidation.tonneMin
                              // }
                              pattern={commanValidation.numberPattern}
                              max={commanValidation.maxQty}
                              onChange={(e) => {
                                if (isSubmitted) {
                                  validateForm({
                                    ...value,
                                    [e.target.name]: e.target.value,
                                  });
                                }
                                setQuantityValidation('');
                                setValue({
                                  ...value,
                                  [e.target.name]: parseFloat(e.target.value),
                                });
                              }}
                              onFocus={disableScrollForTypeNumber}
                              required
                              disabled={fromSelectedTransactions === 1}
                            />
                            {<span className='error'>{errors.quantity}</span>}
                            <p className='upload-note'>
                              {/* {validationMessage.minKgMessage_1000} */}
                            </p>
                          </div>

                          <div className='input-right input-left-right'>
                            <select
                              className='form-control form-select'
                              name='quantityUnit'
                              value={value.quantityUnit}
                              onChange={(e) => {
                                if (isSubmitted) {
                                  validateForm({
                                    ...value,
                                    [e.target.name]: e.target.value,
                                  });
                                }
                                setQuantityValidation('');
                                setValue({
                                  ...value,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                              disabled={fromSelectedTransactions === 1}
                            >
                              {measurementTypes?.map(
                                (item: any, index: number) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      {quantityValidation && (
                        <div
                          className='invalid-feedback'
                          style={{ display: 'block' }}
                        >
                          {quantityValidation}
                        </div>
                      )}

                      <div className='form-check ps-0'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='defaultCheck7'
                          value={0}
                          checked={fromSelectedTransactions === 1}
                          onChange={(e: any) => {
                            if (fromSelectedTransactions === 1) {
                              setFromSelectedTransactions(0);
                              setValue({
                                ...value,
                                quantity: '',
                                quantityUnit: 'Tonne',
                              });
                            } else {
                              setFromSelectedTransactions(1);
                              setValue({
                                ...value,
                                quantity: selectedTransactionQuantity + '',
                                quantityUnit: 'Kg',
                              });
                            }
                          }}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='defaultCheck7'
                        >
                          Calculate quantity from selected transaction.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='transaction-table panel-box table-link'>
                <div className='panel-title'>
                  <div className='title'>Linked Transactions</div>
                  <div className='selection-type'>
                    <div className='form-group-main'>
                      <Form.Label>Select Credit Type</Form.Label>
                      <Form.Select
                        name='creditType'
                        className='form-control'
                        value={value.creditType}
                        onChange={(e) => {
                          setData([]);
                          setPage(1);
                          setDataCount(0);
                          setSelectedTransaction([]);
                          setValue({
                            ...value,
                            transactionIds: [],
                            [e.target.name]: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value={0}>Select Credit Type</option>
                        {_.map(
                          certificateCategories,
                          (value: any, key: any) => {
                            return (
                              <option
                                key={key}
                                value={key}
                              >{`${value.title}`}</option>
                            );
                          }
                        )}
                      </Form.Select>
                    </div>
                  </div>
                </div>
                <div className='panel-data'>
                  <div className='table-certificate'>
                    <div className='listing-content'>
                      <InfiniteScroll
                        dataLength={page * 10}
                        next={fetchMoreData}
                        hasMore={dataCount > data.length}
                        loader={
                          <p style={{ textAlign: 'center' }}>
                            <b>Loading...</b>
                          </p>
                        }
                        height={value.creditType ? 400 : 200}
                        className='table-list'
                      >
                        <table>
                          <tbody>
                            {table.getHeaderGroups().map((headerGroup: any) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header: any) => {
                                  return (
                                    <td
                                      key={header.id}
                                      colSpan={header.colSpan}
                                    >
                                      {header.isPlaceholder ? null : (
                                        <div
                                          {...{
                                            className:
                                              header.column.getCanSort()
                                                ? 'cursor-pointer select-none'
                                                : '',
                                            onClick:
                                              header.column.getToggleSortingHandler(),
                                          }}
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: (
                                              <i
                                                className='fa fa-sort-desc'
                                                aria-hidden='true'
                                              ></i>
                                            ),
                                            desc: (
                                              <i
                                                className='fa fa-sort-asc'
                                                aria-hidden='true'
                                              ></i>
                                            ),
                                          }[
                                            header.column.getIsSorted() as string
                                          ] ?? (
                                            <i
                                              className='fa fa-sort'
                                              aria-hidden='true'
                                            ></i>
                                          )}
                                        </div>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            ))}

                            <>
                              {table.getRowModel().rows.map((row: any) => {
                                return (
                                  <>
                                    <tr
                                      key={row.id}
                                      style={{ cursor: 'pointer' }}
                                      id={`transaction-row-${row.original.id}`}
                                    >
                                      {row
                                        .getVisibleCells()
                                        .map((cell: any) => {
                                          return (
                                            <td key={cell.id}>
                                              {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                              )}
                                            </td>
                                          );
                                        })}
                                    </tr>
                                    {
                                      <tr
                                        id={`desc-content-${row.original.id}`}
                                        className='description-row'
                                      >
                                        <td
                                          colSpan={11}
                                          className='description-td'
                                        >
                                          <div className='description-content'>
                                            <div className='description-content-inner'>
                                              <div
                                                className='main-desc-area'
                                                style={{
                                                  marginBottom: '20px',
                                                }}
                                              >
                                                <div className='title-area'>
                                                  <div className='row'>
                                                    <div
                                                      className='col-lg-8'
                                                      style={{
                                                        textAlign: 'start',
                                                      }}
                                                    >
                                                      <h5>
                                                        {translate(
                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.ALLOCATED_TRANSACTIONS'
                                                        )}{' '}
                                                      </h5>
                                                    </div>
                                                    <div className='col-lg-4 d-flex justify-content-end'>
                                                      <p>
                                                        {translate(
                                                          'PLASTIC_CREDIT_MANAGEMENT.TITLES.DATE'
                                                        )}
                                                        :{' '}
                                                        {moment(
                                                          data?.created_on
                                                        ).format('DD/MM/YYYY')}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className='main-table-section'>
                                                  <div className='table-certificate'>
                                                    <div className='listing-content'>
                                                      <div className='table-list'>
                                                        <table>
                                                          <thead>
                                                            <tr>
                                                              <td></td>
                                                              <td>
                                                                <div className='title'>
                                                                  Pc Request Id
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className='title'>
                                                                  Requested From
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className='title'>
                                                                  {translate(
                                                                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_WASTE_TYPE'
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className='title'>
                                                                  {translate(
                                                                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.ASSIGNED_QTY'
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className='title'>
                                                                  {translate(
                                                                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.TYPE_OF_CREDITS'
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className='title'>
                                                                  {translate(
                                                                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.TOTAL_CREDIT'
                                                                  )}
                                                                </div>
                                                              </td>
                                                              {/* <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.SELLER'
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.BUYER'
                                                                          )}
                                                                        </div>
                                                                      </td> */}
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {row?.original?.link_transaction_certificate_detail.map(
                                                              (data: any) => {
                                                                return (
                                                                  <tr>
                                                                    <td>
                                                                      <Link
                                                                        target='_blank'
                                                                        to={`/admin/transaction/plasticCreditCertification/${window.btoa(
                                                                          data
                                                                            ?.linked_transaction_detail
                                                                            ?.transaction_id
                                                                        )}&${window.btoa(
                                                                          data?.pc_id
                                                                        )}`}
                                                                        title={
                                                                          translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.VIEW_CERTIFICATE'
                                                                          ) ||
                                                                          ''
                                                                        }
                                                                      >
                                                                        <img
                                                                          src='/assets/images/certificate-icon.svg'
                                                                          alt='invite user'
                                                                        />
                                                                      </Link>
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        data?.pc_id
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {data
                                                                        ?.plastic_credit_request_details
                                                                        ?.pc_user
                                                                        ? data
                                                                            ?.plastic_credit_request_details
                                                                            ?.pc_user
                                                                            .name
                                                                        : data
                                                                            ?.plastic_credit_request_details
                                                                            ?.user
                                                                            ?.user_profile
                                                                            ?.user_name}
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        data
                                                                          ?.linked_transaction_detail
                                                                          ?.material_type
                                                                          ?.name
                                                                      }
                                                                      &nbsp; -
                                                                      &nbsp;
                                                                      {
                                                                        data
                                                                          ?.linked_transaction_detail
                                                                          ?.material_name_type
                                                                          ?.name
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        data?.assigned_pc
                                                                      }
                                                                      &nbsp;
                                                                      {'Kg'}
                                                                    </td>
                                                                    <td>
                                                                      {getCreditType(
                                                                        data
                                                                          ?.linked_transaction_detail
                                                                          ?.credit_type
                                                                      )}
                                                                      &nbsp;
                                                                      Credit
                                                                    </td>
                                                                    <td>
                                                                      {(data?.assigned_pc ||
                                                                        0) /
                                                                        1000}
                                                                    </td>
                                                                    {/* <td>
                                                                        {
                                                                          data?.linked_transaction_detail?.seller
                                                                            ?.name
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {
                                                                          data?.linked_transaction_detail?.buyer
                                                                            ?.name
                                                                        }
                                                                      </td> */}
                                                                  </tr>
                                                                );
                                                              }
                                                            )}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    }
                                  </>
                                );
                              })}
                            </>
                          </tbody>
                        </table>

                        <>
                          {loading && (
                            <h3 className='center-message-item'>Loading...</h3>
                          )}
                          {!loading && data && data.length === 0 && (
                            <h3 className='center-message-item'>
                              {value.creditType === 0
                                ? 'Please Select credit type.'
                                : 'No Data Found in Selected Credit Type.'}
                            </h3>
                          )}
                        </>
                      </InfiniteScroll>
                    </div>
                  </div>

                  <div className='text-area-content'>
                    <div className='form-group  group-i-input'>
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        placeholder='Enter Remarks'
                        name='remark'
                        onChange={onChange}
                        value={value.remark}
                        maxLength={commanValidation.maxLengthAddress}
                      ></Form.Control>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='table-responsive pb-4'> */}
              {selectedTransaction.length > 0 && (
                <div className='transaction-table panel-box table-link'>
                  <div className='panel-title'>
                    <div className='title'>Selected Transactions</div>
                  </div>
                  <div className='panel-data'>
                    <div className='table-certificate'>
                      <div className='listing-content'>
                        <div className='table-list'>
                          <table>
                            <tbody>
                              <tr>
                                <td>Transaction ID</td>
                                <td>Material Type</td>
                                <td>Material Sub Type </td>
                                <td>Qty. </td>
                                <td>Remaining Qty.</td>
                                <td>Type of Credit</td>
                                <td>Enter quantity to be used (Kg.)</td>
                              </tr>

                              {selectedTransaction.length > 0 &&
                                selectedTransaction.map(
                                  (trans: any, i: number) => {
                                    return (
                                      <tr>
                                        <td>{trans?.transaction_id}</td>
                                        <td>{trans?.material_type?.name}</td>
                                        <td>
                                          {trans?.material_name_type?.name}
                                        </td>
                                        <td>
                                          {trans?.unloaded_quantity}{' '}
                                          {trans?.unloaded_quantity_unit}
                                        </td>
                                        <td>{trans?.remaining_quantity} Kg</td>
                                        <td>
                                          {trans?.plastic_credit_type?.name}
                                        </td>

                                        <td>
                                          <div className=' '>
                                            {/* <div
                                            className='input-left  input-left-right'
                                            style={
                                              {
                                                // display: 'flex',
                                                // alignItems: 'center',
                                                // justifyContent: 'center',
                                              }
                                            }
                                          >
                                            <Form.Control
                                              type='number'
                                              className='form-control'
                                              placeholder='Quantity to be used'
                                              style={{ textAlign: 'right' }}
                                              value={trans?.selectedQuantity}
                                              name='quantityToBeUsed'
                                              // step={commanValidation.tonneMin}
                                              min={1}
                                              pattern={
                                                commanValidation.numberPattern
                                              }
                                              max={commanValidation.maxQty}
                                              onChange={(e) => {
                                                let newArray = [
                                                  ...selectedTransaction,
                                                ];

                                                newArray[i].selectedQuantity =
                                                  e.target.value;

                                                setSelectedTransaction(
                                                  newArray
                                                );
                                              }}
                                              onFocus={
                                                disableScrollForTypeNumber
                                              }
                                              required
                                              disabled={
                                                fromSelectedTransactions === 1
                                              }
                                            />
                                            {
                                              <span className='error'>
                                                {trans?.quantityError}
                                              </span>
                                            }
                                          </div> */}
                                            <QuantityComponent
                                              data={trans}
                                              selectedTransaction={
                                                selectedTransaction
                                              }
                                              setSelectedTransaction={
                                                setSelectedTransaction
                                              }
                                              i={i}
                                            />
                                          </div>
                                          {/* 
                                        <input
                                          type='text'
                                          placeholder='Enter Quantity to be used'
                                          className='form-control'
                                          name='name'
                                          style={{ textAlign: 'right' }}
                                          value={trans?.selectedQuantity}
                                          // onChange={handleEventDetails}
                                        /> */}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              )}

              <div className='form-submit-buttons auto-width-button'>
                <Button
                  className='btn btn-primary btn-outline-primary btn-md'
                  onClick={() => setDiscardPopup(true)}
                >
                  Discard
                </Button>
                <Button
                  className='btn btn-primary btn-md'
                  type='button'
                  onClick={() => {
                    setIsSubmited(true);
                    validateForm(value, true);
                  }}
                >
                  Generate Certificate
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default GenerateCertificate;
