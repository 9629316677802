export const category = {
  RECLAIMER: 'Reclaimer',
  COLLECTOR: 'Collector',
  SUBAGGREGATOR: 'Sub-Aggregator',
  AGGREGATOR: 'Aggregator',
  PROCESSOR: 'Processor',
  RECYCLER: 'Recycler',
  MANUFACTURER: 'Manufacturer',
  FIRSTLEVELCONSUMER: '1st Level Consumer',
  SECONDLEVELCONSUMER: '2nd Level Consumer',
  VALUE1: 'Value 1',
  VALUE2: 'Value 2',
  PLASTIC: 'Plastic',
  AGGREGATORPROCESSOR: 'Aggregator (Processor)',
  ENDOFLIFE: 'End of life',
  WASTEPROCESSOR: 'Waste Processor',
};
export enum categoryEnum {
  RECLAIMER = 1,
  COLLECTOR = 2,
  SUBAGGREGATOR = 3,
  AGGREGATOR = 4,
  PROCESSOR = 5,
  RECYCLER = 6,
  MANUFACTURER = 7,
  FIRSTLEVELCONSUMER = 8,
  SECONDLEVELCONSUMER = 9,
  AGGREGATORPROCESSOR = 26,
  ENDOFLIFE = 27,
  WASTEPROCESSOR = 28,
}
export const categories = {
  [categoryEnum.RECLAIMER]: category.RECLAIMER,
  [categoryEnum.COLLECTOR]: category.COLLECTOR,
  [categoryEnum.SUBAGGREGATOR]: category.SUBAGGREGATOR,
  [categoryEnum.AGGREGATOR]: category.AGGREGATOR,
  [categoryEnum.PROCESSOR]: category.PROCESSOR,
  [categoryEnum.RECYCLER]: category.RECYCLER,
  [categoryEnum.MANUFACTURER]: category.MANUFACTURER,
  [categoryEnum.FIRSTLEVELCONSUMER]: category.FIRSTLEVELCONSUMER,
  [categoryEnum.SECONDLEVELCONSUMER]: category.SECONDLEVELCONSUMER,
  [categoryEnum.AGGREGATORPROCESSOR]: category.AGGREGATORPROCESSOR,
  [categoryEnum.ENDOFLIFE]: category.ENDOFLIFE,
  [categoryEnum.WASTEPROCESSOR]: category.WASTEPROCESSOR,
};
