import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { individualProfileValidator } from "./individualProfileValidator";
import { helper } from "../../../../app/api/api.helper";
import { apiUrl } from "../../../../app/api/services/apiUrl";
import { commanValidation } from "../../../../constants/comman.validation.constant";
import { imagePath, routesConstant } from "../../../../constants/comman.constant";
import { UserType } from "../../../../shared/remake-green-utils/common/constants";
import { useSendOtpMutation } from "../../../../app/api/services/authentication.service";
import { OtpStatus } from "../../../../shared/remake-green-utils/common/constants/enum";
import { isValidFile } from "../../../../shared/remake-green-utils";
import { userType } from "../../../../constants/user.constant";

const errorJson = {
    email: '',
    user_name: '',
    contact_number: '',
    alternate_email: '',
    preferred_currency: '',
    countryId: '',
    stateId: '',
    cities: '',
    zip_code: '',
    profile_image: ''
};

const IndividualProfile = ({ profileDetail, updateIndividualProfile, updateEditMode } : any) => {
  const { t: translate } = useTranslation();
  const { user_name, contact_number, designation, preferred_currency, referral_code, address, alternate_email, profile_image, user_profile_history_user_profile_history_user_idTouser: profileHistory = [] }: any = profileDetail?.user_profile || {};
  const defaultUserDetail: any = {
    email: profileDetail?.email,
    user_name,
    contact_number,
    alternate_email,
    designation,
    preferred_currency,
    address_id: address?.id,
    billingAddress: address?.address,
    countryId: address?.country?.id.toString(),
    stateId: address?.states?.id.toString(),
    cities: address?.cities?.id.toString(),
    zip_code: address?.zip_code.toString(),
    referral_code,
    profile_image: profile_image?profile_image:'',
    details: profileHistory.length ? profileHistory[0]?.details : []
  };

  const [profileInfo, setProfileInfo] = useState(defaultUserDetail);
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not 
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>(errorJson);
  const [editMode, setEditMode] = useState(false);
  const [regions, setRegions] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [preferredCurrency, setPreferredCurrency] = useState<any>([]);
  const [sendOtp] = useSendOtpMutation();

  useEffect(() => {
    if (profileDetail?.user_profile?.address?.country?.id) {
        getStates(profileDetail?.user_profile?.address?.country?.id);
    }
    if (profileDetail?.user_profile?.address?.states?.id) {
        getCities(profileDetail?.user_profile?.address?.states?.id);
    }
  }, [profileDetail]);
  
  const getRegions = async () => {
    let path = apiUrl.getCountries;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
        let countries = res?.data && res?.data?.countries;
        setRegions(countries);
    } else {
        toast.error(res.message);
    }
  };

  const getCities = async (stateId: any) => {
    let path = apiUrl.getCities + stateId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
        let cities = res?.data && res?.data?.cities;
        setCity(cities);
    } else {
        toast.error(res.message);
    }
  };

  const getStates = async (CountryId: any) => {
    let path = apiUrl.getStates + CountryId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
        let states = res?.data && res?.data?.states;
        setStates(states);
    } else {
        toast.error(res.message);
    }
  };

  const getSettingData = async () => {
    let path = apiUrl.getAllSetting;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
        let allSetting = res?.data && res?.data?.jsonData;
        setPreferredCurrency(allSetting?.Preferred_Currency);
    } else {
        toast.error(res.message);
    }
  };

  useEffect(() => {
    getRegions();
    getSettingData();
  }, []);
  const gotoProfilelogs = () =>{ 
    navigate(routesConstant.remakeUserViewProfileLog+'/'+profileDetail.id)
}
  const getChangeValue = (updatedObj: any) => {
    let newObj: any = {};
    const fieldArr = ["email", "user_name", "contact_number", "alternate_email", 
    "designation", "preferred_currency", "billingAddress", "countryId",
     "stateId", "cities", "zip_code", "referral_code","profile_image"];
    fieldArr.forEach((fieldKey) => {
        if (updatedObj[fieldKey] !== defaultUserDetail[fieldKey]) {
            newObj[fieldKey] = 'true'
        }
    });
    return newObj;
  };
  const getChangeValueWithAllData = (updatedObj: any) => {
    let newObjwithDetails: any = [];
    const fieldArr = ["email", "user_name", "contact_number", "alternate_email", 
    "designation", "preferred_currency", "billingAddress", "countryId",
     "stateId", "cities", "zip_code", "referral_code","profile_image"];
    fieldArr.forEach((fieldKey) => {
        if (updatedObj[fieldKey] !== defaultUserDetail[fieldKey]) {
            newObjwithDetails.push({'field_name':fieldKey,'old_value':defaultUserDetail[fieldKey],'new_value':updatedObj[fieldKey],'section_id':1})
        }
    });
    return newObjwithDetails;
  };

  const validateForm = (individualInfo =  profileInfo, formSubmit = false) => {
    const errs: any =  individualProfileValidator(errors, individualInfo);    
    setErrors(errs);
    const isFormValid =  Object.keys(errs).every((err) => errs[err].length === 0);
    if (formSubmit && isFormValid) {
        updateIndividualProfile({
            user_type: UserType.INDIVIDUAL,
            email: individualInfo?.email,
            address_id: individualInfo?.address_id,
            address: individualInfo?.billingAddress,
            country_id: individualInfo?.countryId,
            state_id: individualInfo?.stateId,
            city_id: individualInfo?.cities,
            zip_code: individualInfo?.zip_code,
            contact_number: individualInfo?.contact_number,
            alternate_email: individualInfo?.alternate_email,
            designation: individualInfo?.designation,
            preferred_currency: individualInfo?.preferred_currency,
            referral_code: individualInfo?.referral_code,
            accepted_terms: false,
            document_ids: [],
            user_name: individualInfo?.user_name,
            profile_image: individualInfo?.profile_image || '',
            details: {...individualInfo.details, ...getChangeValue(individualInfo)},
            history_details:[...getChangeValueWithAllData(individualInfo)]
          });
          setEditMode(false);
    }
}

const handleFileUploder = async (file: any) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("file_type", '');
    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res = await fr.response;
    if (res.success) {
        toast.success(res.message);
        setProfileInfo({...profileInfo, profile_image: res?.data?.data?.document_path });
    } else {
        toast.error(res.message);
    }
  };

  const uploadLogo = async (e: any) => {
    let files = e?.target?.files || [];
    if (files[0] > commanValidation.maxFileLength) {
        toast.error(translate('ERRORS.INVALID.MAXIMUM_DOCUMENT_VALIDATION'));
        return;
    }
    if(!isValidFile(files[0].type)){
        toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
        return;
    }
    await handleFileUploder(files[0]);
  }

  const handleChange = (e: any) => {
    if (isSubmitted) {
      validateForm({...profileInfo, [e.target.name]: e.target.value});
    }
    setProfileInfo({
      ...profileInfo,
      [e.target.name]: e.target.value,
    });
  };

  const onSaveProfile = () => {
    setIsSubmited(true);
    validateForm(profileInfo, true);
  }

    const onDiscard = () => {
        setProfileInfo(defaultUserDetail);
        setErrors(errorJson);
        setEditMode(false);
        updateEditMode(false);
    }

    const onUpdatePassword = async () => {
        const postJson = { email: profileInfo?.email, otp_reason: OtpStatus.RESETOTP };
        try {
          const response = await sendOtp(postJson).unwrap();
          if (response.success) {
            toast.success(response.data.message);
            localStorage.setItem("verifyEmail", profileInfo.email);
            navigate(routesConstant.remakeUserUpdatePassword)
          } else {
            toast.error(response.message);
          }
        }
        catch (err: any) {
          toast.error(err?.data?.data?.message);
        }
    };

  return <React.Fragment>
    <div className="display-head">
                            <div className="title">
                                <span className="active-box"></span>
                                    {translate('PROFILE.TITLES.USERNAME')}
                                    <div className="small-title">
                                        ({`${translate('PROFILE.TITLES.ROLE_TYPE')}`} Individual)
                                    </div>
                            </div>
                            <div className="button-group btn-sm">
                                    <a onClick={onUpdatePassword} className="btn  not-btn btn-md">{translate('PROFILE.TITLES.UPDATE_PASSWORD')}</a>
                                    <a onClick={gotoProfilelogs}  className="btn  btn-md btn-outline-primary"> {translate('PROFILE.TITLES.PROFILE_LOGS')}</a>
                                    {editMode ? 
                                        <>
                                            <a onClick={onDiscard} className="btn  btn-md btn-outline-primary">{translate('BUTTONS.DISCARD')}</a>
                                            <a onClick={onSaveProfile} className="btn  btn-md">
                                             {translate('BUTTONS.SAVE_PROFILE')}
                                            </a>
                                        </> 
                                        : 
                                    <a onClick={() => {
                                        updateEditMode(true);
                                        setEditMode(true)
                                    }} className="btn  btn-md">{translate('PROFILE.TITLES.EDIT_PROFILE')}</a>}
                            </div>
                        </div>
     <div className="display-body">
                            <div className="row mb-10">
                                <div className="col-lg-3 col-md-12">
                                    <div className="company-logo">
                                        <div className="item-logo">

                                            <div className="icon-data">
                                                <img src={
                                                profileInfo.profile_image
                                                    ? profileInfo.profile_image
                                                    : profileDetail?.user_type_id === userType.COMPANY
                                                    ? `${process.env.PUBLIC_URL}/assets/images/company.svg`
                                                    : `${process.env.PUBLIC_URL}/assets/images/User_Pic.svg`
                                                } className="profile-img" />

                                                    {editMode ?  <div className="upload-button">
                                                                    <div className="fileUpload ">
                                                                        <span>
                                                                            <img src={imagePath.editPath} className="edit-icon" />
                                                                                {translate('PROFILE.TITLES.UPLOAD_IMAGE')}
                                                                        </span>
                                                                                 <input type="file" 
                                                                                        accept=".jpg, .jpeg, .png"
                                                                                        onChange={uploadLogo}
                                                                                        className="uploadlogo" />
                                                                    </div>
                                                                 </div> 
                                                    : ''}
                                            </div>
                                            {profileHistory.length && profileHistory[0]?.details['profile_image'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                        </div>
                                    </div>
                                    {<span className="error">{translate(errors.profile_image)}</span>}
                                </div>
                                <div className="col-lg-9 col-md-12">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="form-label label-info ">{translate('PROFILE.TITLES.INDIVIDUAL_EMAIL')}</label>
                                                <input 
                                                    type="text" 
                                                    className={`form-control ${editMode ? '' : 'selected'}`} 
                                                    id="email-id" 
                                                    name="email"
                                                    placeholder=""
                                                    disabled
                                                    value={profileInfo.email}
                                                    onChange={handleChange} 
                                                    />
                                                {<span className="error">{translate(errors.email)}</span>}
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group required pending">
                                                <label className="form-label label-info ">{translate('PROFILE.TITLES.USERNAME')}</label>
                                                <input 
                                                    type="text" 
                                                    className={`form-control ${editMode ? '' : 'selected'}`} 
                                                    id="user-name" 
                                                    name="user_name"
                                                    placeholder="" 
                                                    value={profileInfo.user_name}
                                                    onChange={handleChange} 
                                                 />
                                                 {profileHistory.length && profileHistory[0]?.details['user_name'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                                 
                                                 {<span className="error">{translate(errors.user_name)}</span>}
                                            </div>
                                        </div>



                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group required pending">
                                                <label className="form-label">{translate('PROFILE.TITLES.CONTACT_NUMBER')}</label>
                                                <div className="cell-item">
                                                    <span className="prefix">+91</span>
                                                    <input 
                                                        type="tel" 
                                                        className={`form-control ${editMode ? '' : 'selected'}`} 
                                                        id="contact-number"
                                                        name="contact_number" 
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={profileInfo.contact_number} />
                                                    {profileHistory.length && profileHistory[0]?.details['contact_number'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                                    {<span className="error">{translate(errors.contact_number)}</span>}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group required pending">
                                                <label className="form-label">{translate('PROFILE.TITLES.EMAIL_ID')}</label>
                                                <input 
                                                    type="email" 
                                                    className={`form-control ${editMode ? '' : 'selected'}`} 
                                                    id="email-id"
                                                    name="alternate_email" 
                                                    placeholder=""
                                                    value={profileInfo.alternate_email} 
                                                    onChange={handleChange}
                                                />
                                                {profileHistory.length && profileHistory[0]?.details['alternate_email'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                                {<span className="error">{translate(errors.alternate_email)}</span>}
                                            </div>
                                        </div>


                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group required pending">
                                                <label className="form-label">{translate('PROFILE.TITLES.DESIGNATION')}</label>
                                                <input 
                                                    type="text" 
                                                    className={`form-control ${editMode ? '' : 'selected'}`} 
                                                    id="designation"
                                                    name="designation" 
                                                    placeholder=""
                                                    value={profileInfo.designation}
                                                    onChange={handleChange} />
                                                {profileHistory.length && profileHistory[0]?.details['designation'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                            </div>
                                        </div>


                                        <div className="col-md-6  col-sm-6">
                                            <div className="form-group required pending">
                                                <label className="form-label">{translate('PROFILE.TITLES.PREFERRED_CURRENCY')}</label>
                                                <select 
                                                    name="preferred_currency" 
                                                    required={false}
                                                    onChange={handleChange}
                                                    className={`form-control fix-height form-select ${editMode ? '' : 'selected'}`}>
                                                    {preferredCurrency.length > 0 &&
                                            preferredCurrency?.map(
                                                (item: any, index: number) => {
                                                    return (
                                                        <option key={index} value={item.name}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                }
                                            )}

        
                                                </select>
                                                {profileHistory.length && profileHistory[0]?.details['preferred_currency'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                                {<span className="error">{translate(errors.preferred_currency)}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">

                                <div className="col-lg-4  col-md-6">
                                    <div className="form-group pending">
                                        <label className="form-label label-info ">{translate('PROFILE.TITLES.BILLING_ADDRESS')}
                                        </label>

                                        <textarea 
                                            className={`form-control ${editMode ? '' : 'selected'}`}
                                            placeholder={`${translate('PROFILE.TITLES.BILLING_ADDRESS')}`}
                                            name="billingAddress" 
                                            rows={2}
                                            value={profileInfo.billingAddress}
                                            onChange={handleChange} />
                                        {profileHistory.length && profileHistory[0]?.details['billingAddress'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                    </div>
                                </div>



                                <div className="col-lg-4  col-md-6">
                                    <div className="form-group required pending">
                                        <label className="form-label">{translate('PROFILE.TITLES.COUNTRY')}</label>
                                        <select 
                                            name="countryId"
                                            value={profileInfo.countryId} 
                                            required={false}
                                            onChange={(e) => {
                                                handleChange(e);
                                                getStates(e.target.value)
                                            }}
                                            className={`form-control fix-height form-select ${editMode ? '' : 'selected'}`}
                                            >
                                           <option value="">{translate("COMMONFIELDS.SELECT_COUNTRY")}</option>
                                            {regions?.length > 0 &&
                                                regions?.map((u: any, i: any) => {
                                                    return (
                                                        <option key={i} value={u.id}>
                                                            {u.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                        {profileHistory.length && profileHistory[0]?.details['countryId'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                        {<span className="error">{translate(errors.countryId)}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-4  col-md-6">
                                    <div className="form-group required pending">
                                        <label className="form-label">{translate("COMMONFIELDS.STATE")}</label>
                                        <Form.Select
                                            name="stateId"
                                            className={`form-control fix-height form-select ${editMode ? '' : 'selected'}`}
                                            value={profileInfo.stateId}
                                            onChange={(e) => {
                                                handleChange(e);
                                                getCities(e.target.value)
                                            }}
                                            required
                                            disabled={profileInfo.stateId ? false : true}
                                        >
                                            <option value="">{translate("COMMONFIELDS.SELECT_STATE")}</option>
                                            {states?.length > 0 &&
                                                states?.map((u: any, i: any) => {
                                                    return (
                                                        <option key={i} value={u.id}>
                                                            {u.name}
                                                        </option>
                                                    );
                                                })}
                                        </Form.Select>
                                        {profileHistory.length && profileHistory[0]?.details['stateId'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                        {<span className="error">{translate(errors.stateId)}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-4  col-md-6">
                                    <div className="form-group required pending">
                                            <label className="form-label">{translate("COMMONFIELDS.CITY")}</label>
                                            <Form.Select
                                                name="cities"
                                                className={`form-control fix-height form-select ${editMode ? '' : 'selected'}`}
                                                value={profileInfo.cities}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                disabled={profileInfo.cities ? false : true}
                                                required
                                            >
                                                <option value="">{translate("COMMONFIELDS.SELECT_CITY")}</option>
                                                {city?.length > 0 &&
                                                    city?.map((u: any, i: any) => {
                                                        return (
                                                            <option key={i} value={u.id}>
                                                                {u.name}
                                                            </option>
                                                        );
                                                    })}
                                            </Form.Select>
                                        {profileHistory.length && profileHistory[0]?.details['cities'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                        {<span className="error">{translate(errors.cities)}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 ">
                                    <div className="form-group required pending">
                                        <label className="form-label">{translate('PROFILE.TITLES.ZIP_CODE')}</label>
                                        <input 
                                            type="text" 
                                            className={`form-control ${editMode ? '' : 'selected'}`} 
                                            placeholder=""
                                            name="zip_code" 
                                            required={false}
                                            value={profileInfo.zip_code}
                                            onChange={handleChange} />
                                       {profileHistory.length && profileHistory[0]?.details['zip_code'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                        {<span className="error">{translate(errors.zip_code)}</span>}
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 ">
                                    <div className="form-group pending">
                                        <label className="form-label">{translate('PROFILE.TITLES.REERRAL_CODE')}</label>
                                        <input 
                                            type="text" 
                                            className={`form-control ${editMode ? '' : 'selected'}`} 
                                            placeholder=""
                                            name="referral_code" 
                                            required={false}
                                            value={profileInfo.referral_code}
                                            onChange={handleChange} />
                                        {profileHistory.length && profileHistory[0]?.details['referral_code'] ? <img src={imagePath.reqindPath} title="Pending" className="pending-item pending-icon" /> : '' }
                                    </div>
                                </div>


                            </div>



                        </div>
    </React.Fragment>;  
};
export default IndividualProfile;