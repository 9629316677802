import { category } from './category.constant';

export enum creditComponent {
  V1 = 0,
  V2,
}
export const creditComponents = {
  [creditComponent.V1]: [
    category.AGGREGATORPROCESSOR,
    category.ENDOFLIFE,
    // category.WASTEPROCESSOR,
    category.PROCESSOR,
  ],
  [creditComponent.V2]: [category.RECYCLER],
};
