import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import { helper } from '../../../../app/api/api.helper';
import { apiUrl } from '../../../../app/api/services/apiUrl';
import { commanLabel, imagePath } from '../../../../constants/comman.constant';
import { getEndDateFilter, getStartDateFilter } from '../../../../utils/Date/getDateRange';
import { Button, Pagination } from 'react-bootstrap';
import moment from 'moment';
import { useParams } from "react-router";
import { UserProfileStatus } from '../../../../shared/remake-green-utils/common';
import { validationMessage } from '../../../../constants/validation.message.constant';
import { getFileFormat } from '../../../../utils/files/commanFilefunction';
const ViewHistory = (props:any) => {
    const { t: translate } = useTranslation();
    const [searchedText, setSearchedText] = useState('');
    const { user_id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(commanLabel.page);
    const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
    const [data, setData] = useState<any>([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [pageCount, setPageCount] = useState(0);
    const [totalRecord, setTotalRecord] = useState(0);
    const [value, setValue] = useState({
        regionId: null,
        transactionIds: [] as any,
    });

    useEffect(() => {
        getData(false);
    }, [props.DataFromMain]);
    
    useEffect(() => {
        getData(false);
    }, [page, pageLimit]);

    const getData = async (reset: boolean) => {
        setIsLoading(true);
        const parms = {
            page: reset ? 1 : page,
            limit: pageLimit,
            user_id: user_id,
            start_date: reset
                ? ""
                : dateRange[0] === null
                    ? ""
                    : getStartDateFilter(dateRange[0]),

            searchText: reset ? "" : searchedText,
            end_date: reset
                ? ""
                : dateRange[1] === null
                    ? ""
                    : getEndDateFilter(dateRange[1]),
        };
        let path = apiUrl.getUserDatabaseHistory;
        const fr = await helper.getData(path, parms);
        const res = await fr.response;
        if (res.success) {
            let transaction = res.data.dataSorted;
            setData([...transaction]);
            setIsLoading(false);
            setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
            setTotalRecord(res.data.total)
        } else {
            toast.error(res.message);
            setIsLoading(false);
        }
    };
    const performSearch = (e: any) => {
        e.preventDefault();
        getData(false);
    };
    const handleReset = () => {
        setPage(1);
        setSearchedText('');
        setDateRange([null, null]);
        getData(true);
    };
    const getPagination = () => {
        let items = [];
        for (let number = 1; number <= pageCount; number++) {
            items.push(
                <Pagination.Item
                    activeLabel=""
                    key={number}
                    active={number === page}
                    onClick={(e) => {
                        e.preventDefault();
                        setPage(number);
                    }}
                >
                    {number}
                </Pagination.Item>
            );
        }
        return items;
    };
    const displayFn = (item: string) => {
        if (item) {
            item = item.replace(/_/g, ' ');
        } else {
            item = '-';
        }
        return item.charAt(0).toUpperCase() + item.substring(1).toLowerCase(); 
      }
    return (
        <React.Fragment>
            <div className="display-component table-data-main">
                { !props.fromUser &&
                <div className="row header-area d-flex align-items-center">
                    <div className="col-lg-2">
                        <div className="title">{translate('PROFILE.TITLES.PAST_HISTORY')}</div>
                    </div>
                    <div className="col-lg-10">
                        <div className="top-filters three-type row">
                            <div className="date-item-picker item-in col-lg-5 me-0">
                                <div className="date-picker-main">
                                    <DatePicker
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        value={startDate ? startDate : ''}
                                        className='form-control'
                                        placeholderText='Select Date Range'
                                        dateFormat={commanLabel.dateFormat}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode='select'
                                        onChange={(update: any) => {
                                            setDateRange(update);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="item-in col-lg-4 mb-3 mb-sm-3 mb-md-3 mb-lg-0">
                                <div className="input-search-item form-group mb-0">
                                    <input
                                        className='form-control'
                                        id='searchicon'
                                        placeholder='Search'
                                        value={searchedText}
                                        onChange={(e) => {
                                            setSearchedText(e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                performSearch(e);
                                            }
                                        }}
                                    />
                                    <i className="fa fa-search"></i>
                                </div>
                            </div>
                            <div className="search-button item-in col-lg-3 mt-1">
                                <Button
                                    className='btn  btn-sm btn-outline-primary btn-icon'
                                    type='button'
                                    title='Search'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setPage(1);
                                        page === 1 && getData(false);
                                    }}
                                >
                                    <i className='fa fa-search' aria-hidden='true'></i>
                                </Button>
                                &nbsp;
                                <Button
                                    className='btn  btn-sm  btn-outline-primary btn-icon'
                                    title='Reset'
                                    type='button'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleReset();
                                    }}
                                >
                                    <i className='fa fa-refresh' aria-hidden='true'></i>
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
                }
                <div className="display-body ps-4 pe-4">
                    <div className=" transaction-table listuser-table ">
                        <div className="tab-content">
                            <div className="listing-transaction">
                                {
                                    data && data?.map((item: any, key: any) => (
                                        <div className="profile-history-main">
                                            <div className="profile-log-inner" >
                                                <div className="log-header">
                                                    {translate('PROFILE.TITLES.ACTION_REQUESTED_ON')}: {item?.date}
                                                </div>
                                                <div className="table-responsive pt-4 pb-4">
                                                    <table className="table">
                                                        <tbody>
                                                            {
                                                                item && item?.dateGroup?.map((itemInfo: any, key: any) => (
                                                                    <>
                                                                        <tr>
                                                                            <th>{translate('PROFILE.TITLES.FIELD_NAME')}</th>
                                                                            <th>{translate('PROFILE.TITLES.OLD_VALUE')}</th>
                                                                            <th>{translate('PROFILE.TITLES.NEW_VALUE')}</th>
                                                                            <th>{translate('PROFILE.TITLES.ACTION_REQUESTED_ON')}</th>
                                                                            <th>{translate('PROFILE.TITLES.ACTION_TAKEN_BY')}</th>
                                                                            <th>{translate('PROFILE.TITLES.DATE_OF_ACTION')}</th>
                                                                            <th>{translate('PROFILE.TITLES.STATUS')}</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{displayFn(itemInfo.field_name)}</td>
                                                                            <td>
                                                                            { itemInfo.old_value.includes('corpb') &&
                                                                            <div className='icon-img new-icon-size'>                
                                                                            {getFileFormat(itemInfo.old_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.pdf && (
                                                                                <a href={itemInfo.old_value} target={'_blank'}><img src={imagePath.pdfPath} alt='' /></a>
                                                                            )}
                                                                            {getFileFormat(itemInfo.old_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.png && (
                                                                                    <a href={itemInfo.old_value} target={'_blank'}><img src={imagePath.jpgPath} alt='' /></a>
                                                                            )}
                                                                            {getFileFormat(itemInfo.old_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.jpg && (
                                                                                    <a href={itemInfo.old_value} target={'_blank'}><img src={imagePath.jpgPath} alt='' /></a>
                                                                            )}
                                                                            {getFileFormat(itemInfo.old_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.jpeg && (
                                                                                    <a href={itemInfo.old_value} target={'_blank'}><img src={imagePath.jpgPath} alt='' /></a>
                                                                            )}
                                                                            {getFileFormat(itemInfo.old_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.excel && (
                                                                                    <a href={itemInfo.old_value} target={'_blank'}> <img className='file-logo' src={imagePath.excelPath}  alt=''/></a>
                                                                            )}
                                                                            {getFileFormat(itemInfo.old_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.docs && (
                                                                                    <a href={itemInfo.old_value} target={'_blank'}><img className='file-logo' src={imagePath.docsLogo} alt='' /></a>
                                                                            )}
                                                                            </div>  
                                                                            }
                                                                            {!itemInfo.old_value.includes('corpb') &&
                                                                            <>
                                                                            {!itemInfo.new_value.includes('corpb') &&
                                                                            itemInfo.old_value
                                                                            }
                                                                             {itemInfo.new_value.includes('corpb') &&
                                                                            <>-</>
                                                                            }
                                                                            </>
                                                                            } 
                                                                            </td>
                                                                            <td>
                                                                            { itemInfo.new_value.includes('corpb') &&
                                                                            
                                                                            <div className='icon-img new-icon-size'>                
                                                                            {getFileFormat(itemInfo.new_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.pdf && (
                                                                                <a href={itemInfo.new_value} target={'_blank'}><img src={imagePath.pdfPath} alt='' /></a>
                                                                            )}
                                                                            {getFileFormat(itemInfo.new_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.png && (
                                                                                    <a href={itemInfo.new_value} target={'_blank'}><img src={imagePath.jpgPath} alt='' /></a>
                                                                            )}
                                                                            {getFileFormat(itemInfo.new_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.jpg && (
                                                                                    <a href={itemInfo.new_value} target={'_blank'}><img src={imagePath.jpgPath} alt='' /></a>
                                                                            )}
                                                                            {getFileFormat(itemInfo.new_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.jpeg && (
                                                                                    <a href={itemInfo.new_value} target={'_blank'}><img src={imagePath.jpgPath} alt='' /></a>
                                                                            )}
                                                                            {getFileFormat(itemInfo.new_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.excel && (
                                                                                    <a href={itemInfo.new_value} target={'_blank'}> <img className='file-logo' src={imagePath.excelPath}  alt=''/></a>
                                                                            )}
                                                                            {getFileFormat(itemInfo.new_value.split('/').pop().split('.').pop()) ===
                                                                                commanLabel.docs && (
                                                                                    <a href={itemInfo.new_value} target={'_blank'}><img className='file-logo' src={imagePath.docsLogo} alt='' /></a>
                                                                            )}
                                                                            </div>
                                                                            }
                                                                            {!itemInfo.new_value.includes('corpb') &&
                                                                            <>
                                                                             {!itemInfo.old_value.includes('corpb') &&
                                                                            itemInfo.new_value
                                                                            }
                                                                            {itemInfo.old_value.includes('corpb') &&
                                                                            <>-</>
                                                                            }
                                                                            </>

                                                                           
                                                                            }
                                                                            
                                                                            </td>
                                                                            <td>{itemInfo.created_on ? moment(itemInfo.created_on).format("DD-MM-YYYY hh:mm A") : '-'}</td>
                                                                            <td>{itemInfo?.user_userTouser_profile_database_action_by?.email}</td>
                                                                            <td>{itemInfo.action_taken_on ? moment(itemInfo.action_taken_on).format("DD-MM-YYYY hh:mm A") : '-'}</td>
                                                                            <td><div className={` ${itemInfo.status === UserProfileStatus.APPROVED ? 'status-approved' : 'status-reject'}`} >
                                                                                {itemInfo.status === UserProfileStatus.APPROVED ? `${translate('PROFILE.TITLES.APPROVED')}` : `${translate('PROFILE.TITLES.REJECTED')}`}
                                                                            </div>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {data?.length === 0 &&
                                    <>
                                        {isLoading && (
                                            <h3 className="center-message-item">
                                               {translate('USER_MANAGEMENT.TITLES.LOADING')}
                                            </h3>
                                        )}
                                        {data?.length === 0 && !isLoading && (
                                            <h3 className="center-message-item">
                                                {validationMessage.noRecordFound}
                                            </h3>
                                        )}
                                    </>
                                }
                                <div className="listing-content">
                                    
                                        <div className="table-footer">
                                            <div
                                                className="d-flex justify-content-between d-flex align-items-center">
                                                {data?.length > 0 && (
                                                    <div className="left-item text-left">
                                                       {translate('PROFILE.TITLES.TOTAL_RECORDS')}  :{" "}
                                                        {`${data?.length +
                                                            commanLabel.pageLimit * (page - 1)
                                                            }`}{" "}
                                                        / {`${totalRecord}`}
                                                    </div>
                                                )}
                                                <div className="right-item">
                                                    <div className="items-center pagination-main gap-2 text-center">
                                                    {data?.length > 0 && (
                                                            <button
                                                                className="border rounded p-1"
                                                                onClick={() => {

                                                                    setPage(page - 1);
                                                                }}

                                                            >
                                                                {"<"}
                                                            </button>
                                                        )}
                                                        <Pagination size="sm">{getPagination()}</Pagination>
                                                        {data?.length > 0 && (
                                                            <button
                                                                className="border rounded p-1"
                                                                onClick={() => {

                                                                    setPage(page + 1);
                                                                }}

                                                            >
                                                                {">"}
                                                            </button>
                                                            )}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );

};
export default ViewHistory;