import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { toast } from 'react-toastify';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { routesConstant } from '../../../constants/comman.constant';
import { createDomainValidator } from './domainValidator';
import { useAddDomainMutation } from '../../../app/api/services/domainVerification';

export interface ConfirmationData {
  handleCancel: any;
  handleSubmit: any;
  title: string;
  cancelText?: string;
  submitText?: string;
  hideCancelButton?: boolean;
  setReload?: any;
}

const AddDomain = (props: ConfirmationData) => {
  const [addDomain] = useAddDomainMutation();
  const [show, setShow] = useState(true);
  const [data, setData] = useState({
    url: '',
    email: '',
  });
  const [errors, setErrors] = useState<any>({
    url: '',
    email: '',
  });
  const { t: translate } = useTranslation();
  const handleClose = () => {
    setShow(false);
    props.handleCancel();
  };
  const handleOk = () => {
    setShow(false);
    props.handleSubmit();
  };

  const validateForm = (domainValue = data, formSubmit = false) => {
    const errs: any = createDomainValidator(errors, domainValue);
    setErrors(errs);
    const isFormValid = Object.keys(errs).every(
      (err) => errs[err].length === 0
    );

    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      createDomain();
    }
  };

  const createDomain = async () => {
    let details = {
      email: data.email,
      url: data.url,
    };

    const res = await addDomain(details).unwrap();
    if (res.success) {
      // navigate(routesConstant.domainManagement);
      props.setReload(true);
      handleOk();
      toast.success('Domain Added Successfully !');
    } else {
      toast.error(res.message);
    }
  };

  const handleDomainData = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Modal
        className='modal small-popup entry-popup'
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='usermanagement-modal pb-3 pt-4'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-12 mb-10'>
              <div className='form-group required mb-1'>
                <Form.Label>
                  {translate('DOMAIN_MANAGEMENT.TITLES.ENTER_DOMAIN_URL')}
                </Form.Label>

                <input
                  type='text'
                  className='form-control'
                  autoComplete='off'
                  placeholder={
                    translate('DOMAIN_MANAGEMENT.TITLES.DOMAIN_URL') + ''
                  }
                  name='url'
                  value={data.url}
                  onChange={(e) => {
                    handleDomainData(e);
                  }}
                  // maxLength={commanValidation.hsnMax}
                  // disabled={id ? true : false}
                  required
                />
                {<span className='error'>{translate(errors.url)}</span>}
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 mt-10'>
              <div className='form-group required mb-1'>
                <Form.Label>
                  {translate('DOMAIN_MANAGEMENT.TITLES.ENTER_EMAIL_ID')}
                </Form.Label>

                <input
                  type='text'
                  className='form-control'
                  autoComplete='off'
                  placeholder={
                    translate('DOMAIN_MANAGEMENT.TITLES.EMAIL_ADDRESS') + ''
                  }
                  name='email'
                  value={data.email}
                  onChange={(e) => {
                    handleDomainData(e);
                  }}
                  // maxLength={commanValidation.hsnMax}
                  required
                />
                {<span className='error'>{translate(errors.email)}</span>}
              </div>
              {/* <div className="error-msg text-end">No registered entry found</div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='pb-4'>
          <div className='row ms-0 me-0'>
            <div className='col-12 ps-0 pe-0'>
              <a
                role='button'
                onClick={() => {
                  // setIsSubmited(true);
                  validateForm(data, true);
                }}
                // href='/admin/social-welfare/add-activity'
                className='w-100 btn btn-primary btn-sm btn btn-primary entry-btn'
              >
                {translate('DOMAIN_MANAGEMENT.TITLES.ADD_DOMAIN')}
                <i className='fa fa-plus-circle fa-lg ms-2'></i>
              </a>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddDomain;
