import { Route } from 'react-router-dom';
import Registration from '../../pages/user/registration';
import { Home } from '../../pages/shared/home';
import { ProtectedRoute } from './protectedRoute';
import { Layout } from '../../pages/shared/layouts';
import RemakePlasticCreditRequestManagement from '../../pages/shared/remakePlasticCreditRequest';
import RemakeUserCreditRequest from '../../pages/shared/remakeCreditRequest';
import { routesConstant } from '../../constants/comman.constant';
import UserProfile from '../../pages/admin/profile/index';
import { UpdatePassword } from '../../pages/shared/updatePassword';
import ViewHistory from '../../pages/admin/viewProfileDatabase/viewHitory';
import UserProfileLog from '../../pages/admin/viewProfileDatabase/userProfileLog';

export const userRoutes = (
  <Route path='/'>
    <Route
      path='/remake-user/credit-request/:id'
      element={<RemakeUserCreditRequest />}
    />
    <Route
      path='/remake-user/'
      element={
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      }
    >
      <Route
        path={routesConstant.remakeUserUpdatePassword}
        element={<UpdatePassword />}
      />
      {/* PCR-204 | User Profile Front End */}
      <Route
        path={routesConstant.remakeUserProfile}
        element={<UserProfile />}
      />
      <Route
        path='/remake-user/profile-log/:user_id'
        element={<UserProfileLog />}
      />
      <Route
        path='plastic-credit-management/:requestType'
        element={<RemakePlasticCreditRequestManagement />}
      />
      <Route
        path='plastic-credit-management'
        element={<RemakePlasticCreditRequestManagement />}
      />
      <Route
        path='/remake-user/create-remake-account'
        element={<Registration />}
      />
      <Route path='/remake-user/dashboard' element={<Home />} />
    </Route>
  </Route>
);
