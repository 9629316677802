import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLinkTransactionMutation } from '../../../app/api/services/transaction.service';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import { commanLabel } from '../../../constants/comman.constant';

import ConfirmationPopup from '../../../components/confirmationPopup';
import { useNavigate } from 'react-router-dom';
import CertificateMap from '../../../components/certificateMap';
import { toast } from 'react-toastify';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import html2canvas from 'html2canvas';
import { getTableHeader } from './tableHeader';
import { calculateCreditPoint } from '../../../utils/helper/common';
import { hideLoader, showLoader } from '../../../utils/Loader';
import {
  disableScroll,
  enableScroll,
} from '../../../utils/Scroll/scroll.helper';
import {
  findCertificateTypeOfLinkedTransaction,
  validateTransactionsToLink,
  certificateCategories,
} from '../../../shared/remake-green-utils/common';
import { validationMessage } from '../../../constants/validation.message.constant';
import AttachCSR from './attachCSRComponent';
import AttachCSRActivity from './attactCSRActivityComponent';
const TransectionLinking = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit] = useState(commanLabel.pageLimit);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [selectedPartner, setSelectedPatner] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState<any>([]);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [mapSnapshotURL, setMapSnapshotURL] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [creditType, setCreditType] = useState('');
  const [loading] = useState(false);
  const [validationMessageText, setValidationMessageText] = useState('');
  const [validationPopup, setValidationPopup] = useState(false);
  const [valueChainPartners, setValueChainPartners] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [dataCount, setDataCount] = useState(0);
  const [possibleCreditTypes, setPossibleCreditTypes] = useState<string[]>();
  const [showCSRContainer, setShowCSRContainer] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState<any>([]);
  const [selectedCSRActivities, setSelectedCSRActivities] = useState<any>([]);
  const [selectedTableData, setSelectedTableData] = useState(false);
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: pageLimit,
    });
  const [linkTransaction] = useLinkTransactionMutation();
  const onSelectTransaction = (rowData: any, id: any) => {
    const newSelectedTransactionList =
      [...selectedTransaction, { ...rowData }] ?? [];
    if (newSelectedTransactionList.length > 1) {
      const newSelectedTransactionIds = _.map(newSelectedTransactionList, 'id');
      const validationInfo = validateTransactionsToLink(
        newSelectedTransactionList,
        newSelectedTransactionIds
      );
      if (!validationInfo.isValidated) {
        setValidationPopup(true);
        if (validationInfo.isBuyerMismatched)
          setValidationMessageText(validationMessage.transaction);
        else setValidationMessageText(validationMessage.material);
        return;
      }
    }
    setSelectedTransaction([...newSelectedTransactionList]);
  };
  const selectedTransactionId = useMemo(() => {
    const trans_id = selectedTransaction.map((u: any) => {
      return u.id;
    });
    return trans_id;
  }, [selectedTransaction]);
  const columns = getTableHeader(selectedTransactionId, onSelectTransaction);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const table = useReactTable({
    data: data,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });
  const getTransaction = async (partnerId: string) => {
    let path = apiUrl.getTransactionByValueChain + partnerId;
    const fr = await helper.getData(path, { page: page, limit: pageLimit });
    const res = await fr.response;
    if (res.success) {
      setData([...data, ...res?.data?.allTransaction]);
      setDataCount(res?.data?.total);
      setPageCount(res?.data?.total && Math.ceil(res.data.total / pageLimit));
    } else {
      toast.error(res.message);
    }
  };
  const getData = async () => {
    let path = apiUrl.getValueChainPartners;
    const fr = await helper.getData(path, '');
    const res = await fr.response;
    if (res.success) {
      setValueChainPartners(res.data);
    } else {
      toast.error(res.message);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const transactionJourney = useMemo(() => {
    const data = selectedTransaction.map((u: any) => {
      return u.seller.category;
    });
    return data;
  }, [selectedTransaction]);

  const firstTransaction = _.find(
    selectedTransaction,
    (trans) => trans.id === selectedTransactionId[0]
  );
  const lastTransaction = _.find(
    selectedTransaction,
    (trans) =>
      trans.id === selectedTransactionId[selectedTransactionId.length - 1]
  );

  const originLatLng =
    firstTransaction && firstTransaction?.seller?.location?.split(',');
  const origin = firstTransaction
    ? {
        lat: parseFloat(originLatLng[0]),
        lng: parseFloat(originLatLng[1]),
      }
    : { lat: 0, lng: 0 };

  const destinationLatLng =
    lastTransaction && lastTransaction?.buyer?.location?.split(',');
  const destination = lastTransaction
    ? {
        lat: parseFloat(destinationLatLng[0]),
        lng: parseFloat(destinationLatLng[1]),
      }
    : { lat: 0, lng: 0 };

  const getwayPoint = () => {
    if (selectedTransaction && selectedTransaction.length >= 2) {
      let tempLocation = selectedTransaction.slice(1);
      let locationWayPoint =
        tempLocation &&
        tempLocation.map((location?: any) => {
          const latLng = location?.seller?.location?.split(',');
          const origin = {
            lat: parseFloat(latLng[0]),
            lng: parseFloat(latLng[1]),
          };
          return {
            location: new google.maps.LatLng(origin.lat, origin.lng),
          };
        });
      return locationWayPoint;
    }
  };

  const wayPoint = useMemo(() => {
    if (selectedTransaction && selectedTransaction.length >= 2) {
      let tempLocation = selectedTransaction.slice(1);

      let locationWayPoint =
        tempLocation.length > 0 &&
        tempLocation?.map((location?: any) => {
          const latLng = location?.seller?.location?.split(',');
          const origin = {
            lat: parseFloat(latLng[0]),
            lng: parseFloat(latLng[1]),
          };
          return origin;
        });

      return locationWayPoint;
    } else {
      return [];
    }
  }, [selectedTransaction]);

  useEffect(() => {
    if (mapSnapshotURL !== '') {
      linkTransaction({
        transactionIds: selectedTransactionId,
        map_snapshot: mapSnapshotURL,
        activityIds: selectedActivities,
        csrActivityIds: selectedCSRActivities,
      })
        .then((res: any) => {
          if (res?.error?.status === 409) {
            toast.error(res?.error?.data?.message);
          }
          if (res?.error?.status === 406) {
            toast.error('Internal Server Error !!');
          }
          if (res?.data?.success && res?.data?.statusCode === 200) {
            toast.success(validationMessage.linkTransactionSuccess);
            navigate('/admin/transaction/list');
          }
        })
        .catch((Err: any) => {
          toast.error('Something went wrong !!');
          console.log('Errrr', Err);
        });
    }
  }, [linkTransaction, mapSnapshotURL, navigate, selectedTransactionId]);

  useEffect(() => {
    getTransaction(selectedPartner);
  }, [selectedPartner, page]);

  useEffect(() => {
    const creditInfo = findCertificateTypeOfLinkedTransaction(
      selectedTransaction,
      selectedTransactionId
    );
    if (creditInfo && creditInfo.certificateType)
      setCreditType(certificateCategories[creditInfo.certificateType].title);
    else setCreditType('');
    if (creditInfo && creditInfo.possibleCertificateTypes)
      setPossibleCreditTypes(creditInfo.possibleCertificateTypes);
    else setPossibleCreditTypes([]);
  }, [selectedTransaction, selectedTransactionId]);

  useEffect(() => {
    loading ? disableScroll() : enableScroll();
  }, [loading]);

  const onSelect = () => {
    const creditInfo = findCertificateTypeOfLinkedTransaction(
      selectedTransaction,
      selectedTransactionId
    );
    if (creditInfo?.certificateType) setSubmitPopup(true);
    else {
      setValidationMessageText(validationMessage.transactionMismatch);
      setValidationPopup(true);
    }
  };

  useEffect(() => {
    if (showMap) {
      setTimeout(captureScreenshot, 5000);
    }
  }, [showMap]);

  const fetchMoreData = () => {
    setTimeout(() => {
      setPage((page) => page + 1);
    }, 1000);
  };

  const captureScreenshot = () => {
    var map: any = document.getElementById('map') || <></>;

    html2canvas(map, { useCORS: true }).then(function (canvas) {
      document.body.appendChild(canvas);
      const url = canvas
        .toDataURL('../assets/image/png')
        .replace('image/png', 'image/octet-stream');
      setMapSnapshotURL(url);
      canvas.remove();
      //setLoading(false);
      hideLoader();
      setShowMap(false);
    });
  };
  return (
    <>
      {loading && (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(0,0,0,0.8)',
            fontSize: '20px',
            color: '#fff',
            overflow: 'hidden',
          }}
        >
          <span>Loading....</span>
        </div>
      )}

      {discardPopup && (
        <ConfirmationPopup
          title='Discard'
          message='Are you sure you want to Discard?'
          handleCancel={() => setDiscardPopup(false)}
          cancelText='Cancel'
          submitText='Discard'
          handleSubmit={() => {
            window.location.reload();
            setDiscardPopup(false);
          }}
        />
      )}

      {submitPopup && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to Proceed?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setSubmitPopup(false)}
          handleSubmit={() => {
            //setLoading(true);
            showLoader();
            setShowMap(true);
          }}
        />
      )}

      {validationPopup && (
        <ConfirmationPopup
          title='Transaction journey is not matching.'
          message={validationMessageText}
          cancelText='Cancel'
          submitText='Ok'
          handleCancel={() => setValidationPopup(false)}
          handleSubmit={() => {
            setValidationPopup(false);
          }}
          hideCancelButton={true}
        />
      )}

      <div className='body-color'>
        <div className='body-content-main pc-request transaction-registry linking-details csr-main'>
          <div className='container'>
            <div className='section-title-main'>
              Transaction Linking Details
            </div>

            <div className='box-item-list link-details'>
              <div className='row'>
                <div className='col-md-12 left-col'>
                  <div className='panel-box-item title-select-box'>
                    <div className='panel-title'>
                      <div className='title'>Transaction Registry</div>
                      <div className='selection-type'>
                        <div className='form-group-main'>
                          <label
                          // for=""
                          >
                            Select Value Chain Partner
                          </label>
                          <select
                            className='form-control form-select'
                            name='value-chain-partner'
                            onChange={(e) => {
                              setSelectedPatner(e.target.value);
                              setPage(1);
                              setData([]);
                              setDataCount(0);
                              // setSelectedTransaction([]);
                            }}
                          >
                            <option value={''}>Select All</option>
                            {valueChainPartners.length > 0 &&
                              valueChainPartners.map((u: any, index: any) => {
                                return (
                                  <option key={index} value={u.id}>
                                    {u.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='panel-data listing-content'>
                      <InfiniteScroll
                        dataLength={page * 10}
                        next={fetchMoreData}
                        hasMore={dataCount > data.length}
                        loader={
                          <p style={{ textAlign: 'center' }}>
                            <b>Loading...</b>
                          </p>
                        }
                        height={data.length > 0 ? 360 : 150}
                        className='table-list table-data'
                      >
                        <table>
                          <thead>
                            {table.getHeaderGroups().map((headerGroup: any) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header: any) => {
                                  return (
                                    <td
                                      key={header.id}
                                      colSpan={header.colSpan}
                                    >
                                      {header.isPlaceholder ? null : (
                                        <div
                                          {...{
                                            className:
                                              header.column.getCanSort()
                                                ? 'cursor-pointer select-none'
                                                : '',
                                            onClick:
                                              header.column.getToggleSortingHandler(),
                                          }}
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: ' 🔼',
                                            desc: ' 🔽',
                                          }[
                                            header.column.getIsSorted() as string
                                          ] ?? null}
                                        </div>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            ))}
                          </thead>
                          {data.length > 0 ? (
                            <tbody>
                              {table.getRowModel().rows.map((row: any) => {
                                return (
                                  <tr key={row.id}>
                                    {row.getVisibleCells().map((cell: any) => {
                                      return (
                                        <td key={cell.id}>
                                          {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                          )}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          ) : null}
                        </table>
                        <>
                          {loading && (
                            <h3 className='center-message-item'>Loading...</h3>
                          )}
                          {data.length === 0 && !loading && (
                            <h3 className='center-message-item'>
                              {validationMessage.noRecordFoundLinking}
                            </h3>
                          )}
                        </>
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
                <div className='col-md-12 right-col bottom-data'>
                  <div className='panel-box-item '>
                    <div className='panel-title'>
                      Transactions Selected for Linking
                    </div>
                    <div className='panel-data'>
                      <div className='item-list-data'>
                        <ul>
                          {selectedTransaction.length > 0 ? (
                            selectedTransaction.map((data: any, index: any) => {
                              return (
                                <li key={index}>
                                  <div
                                    className='form-check'
                                    style={{ paddingLeft: 0 }}
                                    onClick={() => {
                                      const newArray =
                                        selectedTransaction.filter(
                                          (id: any) => {
                                            return id.id !== data.id;
                                          }
                                        );

                                      setSelectedTransaction([...newArray]);
                                    }}
                                  >
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id={`chkLink${data.transactionId}`}
                                      onChange={() => {}}
                                      checked={selectedTransactionId.includes(
                                        data.id
                                      )}
                                    />
                                    <label
                                      className='form-check-label'
                                      // for="checkbox1"
                                    >
                                      {data.transactionId}
                                    </label>
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <p> No Transaction selected. </p>
                          )}
                        </ul>
                      </div>
                      <div className='extra-note'>
                        <div className='extra-note-in'>
                          <i
                            className='fa fa-info-circle info-left-icon'
                            aria-hidden='true'
                          ></i>

                          <p> This transaction combination will generate: </p>

                          <div className='list-data'>
                            <div className='td-data'>
                              <div className='title'>Plastic credit : </div>
                              {creditType ? (
                                <div className='description'>
                                  {calculateCreditPoint(
                                    lastTransaction?.unloadedQuantity
                                  )}{' '}
                                  credits
                                </div>
                              ) : (
                                '-'
                              )}
                            </div>

                            <div className='td-data'>
                              <div className='title'>Certificate Type :</div>
                              {creditType ? (
                                <div className='description'>{creditType}</div>
                              ) : (
                                '-'
                              )}
                            </div>
                            <div className='td-data arrow-item'>
                              <div className='title'>
                                Potential Credit Types :
                              </div>
                              {creditType && (
                                <div className='description'>
                                  {`${creditType}`}
                                  {possibleCreditTypes &&
                                    possibleCreditTypes.length > 0 &&
                                    possibleCreditTypes.map(
                                      (u: any, i: any) => {
                                        return (
                                          <span key={i}>
                                            {i === 0 ? ' || OR || ' : ''} {u}
                                            {i < possibleCreditTypes.length - 1
                                              ? ' & '
                                              : ''}
                                          </span>
                                        );
                                      }
                                    )}
                                </div>
                              )}
                              {!creditType && (
                                <div className='description'>-</div>
                              )}
                            </div>
                            <div className='td-data arrow-item'>
                              <div className='title'>Transaction Journey :</div>
                              <div className='description'>
                                {transactionJourney &&
                                transactionJourney.length > 0
                                  ? transactionJourney.map(
                                      (u: any, index: any) => {
                                        return (
                                          <span key={index}>
                                            {u}
                                            <i className='fa fa-long-arrow-right'></i>
                                          </span>
                                        );
                                      }
                                    )
                                  : '-'}
                                {lastTransaction?.buyer?.category}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12 mt-5 mb-3'>
                  <div className='toggle-button-light'>
                    <div className='form-group required '>
                      <div className='toggle-button big '>
                        <div className='form-group-switch checked'>
                          <span className='left'>Attach Social Activity</span>
                          <label className='switch '>
                            <input
                              type='checkbox'
                              name='is_regular_transfered'
                              id='is_regular_transfered'
                              checked={showCSRContainer}
                              onChange={() =>
                                setShowCSRContainer(!showCSRContainer)
                              }
                            />
                            <span className='slider round'></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showCSRContainer && (
                  <div className='col-md-12'>
                    <div className='panel-box-item'>
                      {/* <div className='panel-title'>CSR Activity</div> */}
                      <div className='panel-title'>
                        <div className='right-content'>
                          <div className='toggle-button big'>
                            <div className='form-group-switch checked'>
                              <span
                                className={
                                  !selectedTableData ? 'right' : 'left'
                                }
                              >
                                Reclaimer Activity
                              </span>
                              <label className='switch '>
                                <input
                                  type='checkbox'
                                  checked={selectedTableData}
                                  onChange={(e) =>
                                    setSelectedTableData(e.target.checked)
                                  }
                                />
                                <span className='slider round'></span>
                              </label>
                              <span
                                className={selectedTableData ? 'right' : 'left'}
                              >
                                Other CSR Activity
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {selectedTableData ? (
                        <AttachCSRActivity
                          selectedActivities={selectedCSRActivities}
                          setSelectedActivities={setSelectedCSRActivities}
                        />
                      ) : (
                        <AttachCSR
                          selectedActivities={selectedActivities}
                          setSelectedActivities={setSelectedActivities}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='form-o-submit'>
              <a
                className='btn btn-primary btn-outline-primary btn-md'
                onClick={() => setDiscardPopup(true)}
              >
                Discard
              </a>
              <a className='btn btn-primary  btn-md ' onClick={onSelect}>
                Save & Submit
              </a>
            </div>
          </div>
        </div>
      </div>

      {showMap && mapSnapshotURL === '' && origin && destination ? (
        <div style={{ width: '100%', height: '100vh', marginTop: '20%' }}>
          <CertificateMap
            origin={origin}
            center={{ lat: 15.2993, lng: 74.124 }}
            destination={destination}
            waypoints={getwayPoint() ? getwayPoint() : []}
            wayPoint={wayPoint}
          />
        </div>
      ) : null}
    </>
  );
};

export default TransectionLinking;
