import React from 'react';
import { Link } from 'react-router-dom';
import { store } from '../../../../app/store';
import { RootState } from '../../../../app/types/store';
import moment from 'moment';
import { certificateType } from '../../../../shared/remake-green-utils/common';
export interface Certificate {
  isLink?: boolean;
  ownerDetails?: any;
  id?: any;
  productDetail?: any;
  qrCode?: any;
  participantData?: any;
}
export const CertificateHeader = (props: Certificate) => {
  const state: RootState = store.getState();
  const { isLink, ownerDetails, id, productDetail, qrCode, participantData } =
    props;

  return (
    <React.Fragment>
      {/* <div className="main-header container-space">
        <div className="logodata">
          <div className="left-logo">
            <a href="/">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/remake-new-logo.svg`}
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="logo-layer">
          <div className="round-logo">
            <div className="round-logo-img">
              <div className="upper-title">Blockchain based ReMake®</div>
              <div className="main-title">Plastic Credit Certificate</div>
            </div>
          </div>
          <div className="optiondata">
            <div className="left">
              <span>Plastic Credit Owner </span>
              <div className="detail-data">{ownerDetails?.ownerName}</div>
            </div>
            <div className="right icon-img">
              <span>Credit Type</span>
              <div className="detail-data">
                <div className="img-layout">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/recycle.png`}
                    alt={""}
                  />
                  {ownerDetails?.creditType}
                </div>
              </div>

            </div>
          </div>
        </div>
        {isLink && (
          <div className="back-arrow">
            <Link to={state.auth.isLoggedIn ? `/admin/transaction/plasticCreditCertification/${id}` : `/transaction/plasticCreditCertification/${id}`}>
              <i className="fa-solid fa-arrow-left" />
            </Link>
          </div>
        )}
      </div> */}
      <div className='main-header container-space'>
        <div className='header-conetent'>
          <p className='header-title'>
            {ownerDetails?.creditTypeId === certificateType.COLLECTION && (
              <p className='header-title'>ReMake Traceability Certificate</p>
            )}
            {ownerDetails?.creditTypeId === certificateType.RECYCLING && (
              <p className='header-title'>ReMake Traceability Certificate</p>
            )}
            {ownerDetails?.creditTypeId === certificateType.CIRCULAR && (
              <p className='header-title'>ReMake Traceability Certificate</p>
            )}
          </p>
          <div className='left-section'>
            <div className='certificate-type'>
              <p className='title'>
                <span>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/certificate/CC.svg`}
                    alt='cc'
                  />
                </span>{' '}
                {ownerDetails?.creditType}
              </p>
              <p className='description'>CERTIFICATION TYPE</p>
            </div>
            <div className='credit-generated'>
              <p className='title'>{productDetail?.remakePlasticCredits}</p>
              <p className='description'>CREDIT GENERATED</p>
            </div>
            <div className='total-consider-quality'>
              <p className='title'>{productDetail?.plasticWasteQuantity} KG</p>
              <p className='description'>TOTAL CONSIDERED QUANTITY</p>
            </div>
          </div>
          <div className='center-section'>
            <div className='logo-area'>
              <a href='https://remake.green/' target='_blank' rel='noreferrer'>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/certificate/header-logo.svg`}
                  alt='header-logo'
                />
              </a>
              <div
                className='issue-to-company'
                style={{ marginTop: '-10px', marginBottom: '5px' }}
              >
                <a
                  href='https://remake.green/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <u> https:/remake.green</u>
                </a>
              </div>
            </div>

            <div className='issue-to-company'>
              <p className='issue-text'>Issued to</p>
              <p className='company-name'>{ownerDetails?.ownerName}</p>
            </div>
          </div>
          <div className='right-section'>
            <img
              className='qrcode-img'
              src={qrCode?.qr_code_link}
              alt='qrcode-scanner'
            />
            <p className='qr-code'>{qrCode?.serialNo}</p>
            <p className='qrcode-id'>
              Material Credit ID#{' '}
              <span>
                {productDetail?.plastic_credit_id
                  ? productDetail?.plastic_credit_id
                  : ''}
              </span>
            </p>
            <p className='qrcode-date'>
              <p className='title'>Activity Date:</p>{' '}
              <span>
                {moment(participantData[0]?.stageDate).format('DD-MM-YYYY')}{' '}
              </span>{' '}
              to{' '}
              <span>
                {moment(
                  participantData[participantData.length - 1]?.stageDate
                ).format('DD-MM-YYYY')}{' '}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className='mobile-certificate'>
        <div className='header-conetent'>
          <h5 className='header-title'>
            {ownerDetails?.creditTypeId === certificateType.COLLECTION && (
              <p className='header-title'>ReMake Traceability Certificate</p>
            )}
            {ownerDetails?.creditTypeId === certificateType.RECYCLING && (
              <p className='header-title'>ReMake Traceability Certificate</p>
            )}
            {ownerDetails?.creditTypeId === certificateType.CIRCULAR && (
              <p className='header-title'>ReMake Traceability Certificate</p>
            )}
          </h5>
          <div className='logo-main-area'>
            <div className='logo-area'>
              <a href='#'>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/certificate/header-logo.svg`}
                  alt='header-logo'
                />
              </a>
            </div>
            <div className='issue-to-company'>
              <p className='issue-text'>Issued to</p>
              <p className='company-name'>{ownerDetails?.ownerName}</p>
            </div>
          </div>
          <div className='row mt-sm-5 mt-5'>
            <div className='col-6 col-sm-6'>
              <div className='certificate-left'>
                <div className='certificate-type'>
                  <p className='title'>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/certificate/CC.svg`}
                        alt='cc'
                      />
                    </span>{' '}
                    {ownerDetails?.creditType}
                  </p>
                  <p className='description'>CERTIFICATION TYPE</p>
                </div>
                <div className='certificate-type'>
                  <p className='title'>{productDetail?.remakePlasticCredits}</p>
                  <p className='description'>CREDIT GENERATED</p>
                </div>
                <div className='certificate-type'>
                  <p className='title'>
                    {productDetail?.plasticWasteQuantity} KG
                  </p>
                  <p className='description'>TOTAL CONSIDERED QUANTITY</p>
                </div>
              </div>
            </div>
            <div className='col-6 col-sm-6'>
              <div className='qr-code-fullwidth'>
                <img
                  className='qrcode-img'
                  src={qrCode?.qr_code_link}
                  alt='qrcode-scanner'
                />
                <p className='qr-code-text'>{qrCode?.serialNo}</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='qr-code-desc'>
                <p className='qrcode-id'>
                  Material Credit ID#{' '}
                  <span>{productDetail?.plastic_credit_id}</span>
                </p>
                {/* <p className="qrcode-date">Certificate Generated <span>{moment(productDetail?.certificate_generated_on).format("DD-MM-YYYY hh:mm A")}</span></p> */}
                <p className='qrcode-date'>
                  <p className='title'>Activity Date:</p>{' '}
                  <span>
                    {moment(participantData[0]?.stageDate).format('DD-MM-YYYY')}{' '}
                  </span>{' '}
                  to{' '}
                  <span>
                    {moment(
                      participantData[participantData.length - 1]?.stageDate
                    ).format('DD-MM-YYYY')}{' '}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* <table className="certificate-table">
            <tr>
              <th></th>
              <th>
                {ownerDetails?.creditTypeId === certificateType.COLLECTION && (
                                <p className="header-title">plastic collection certificate</p>
                                )}
                                 {ownerDetails?.creditTypeId === certificateType.RECYCLING && (
                                <p className="header-title">plastic recycling certificate</p>
                                )}
                                {ownerDetails?.creditTypeId === certificateType.CIRCULAR && (
                                <p className="header-title">circular plastic certificate</p>
                                )}
              </th>
              <th></th>
            </tr>
            <tr>
              <td>
                <div className="left-section">
                  <div className="certificate-type">
                    <p className="title"><span><img src={`${process.env.PUBLIC_URL}/assets/images/certificate/CC.svg`} alt="cc" /></span> {ownerDetails?.creditType}</p>
                    <p className="description">CERTIFICATION TYPE</p>
                  </div>
                  <div className="certificate-type">
                    <p className="title">{productDetail?.remakePlasticCredits}</p>
                    <p className="description">CREDIT GENERATED</p>
                  </div>
                  <div className="certificate-type">
                    <p className="title">{productDetail?.plasticWasteQuantity} KG</p>
                    <p className="description">TOTAL CONSIDERED QUANTITY</p>
                  </div>
                </div>
              </td>
              <td>
                <div className="center-section">
                  <div className="logo-area">
                    <a href="#">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/certificate/header-logo.svg`} alt="header-logo" />
                    </a>
                  </div>
                  <div className="issue-to-company">
                    <p className="issue-text">Issued to</p>
                    <p className="company-name">{ownerDetails?.ownerName}</p>
                  </div>
                </div>
              </td>
              <td>
                <div className="right-section">
                  <img className="qrcode-img" src={qrCode?.qr_code_link} alt="qrcode-scanner" />
                  <p className="qr-code">{qrCode?.serialNo}</p>
                  <p className="qrcode-id">Plastic Credit ID# <span>{productDetail?.plastic_credit_id}</span></p>
                  <p className="qrcode-date">Certificate Generated <span>{moment(productDetail?.certificate_generated_on).format("DD-MM-YYYY hh:mm A")}</span></p>
                </div>
              </td>
            </tr>
          </table> */}
        </div>
      </div>
    </React.Fragment>
  );
};
