import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { CertificateHeader } from "../commanPage/plasticCreditCertificateHeader";
import { RemakeCertificates } from "./RemakeCertificates";
import { Documents } from "./Documents";
import { apiUrl } from "../../../../app/api/services/apiUrl";
import { toast } from "react-toastify";
import { helper } from "../../../../app/api/api.helper";
import { CirculatoryParticipants } from "./CirculatoryParticipants";
import { useParams } from "react-router";
import { store } from "../../../../app/store";
import { RootState } from "../../../../app/types/store";
import { DocumentLogin } from "./DocumentLogin";

const PlasticCerditCertificationMoreInfo = () => {
  const state: RootState = store.getState();
  const { id } = useParams();
  const transactionId = id?.split('&')[0] || '';
  const pcId = id?.split('&')[1] || '';
  const [isBlockchain, setIsBlockchain] = useState(false)
  const [ownerDetails, setOnerDetails] = useState({})
  const [participantData, setParticipantData] = useState([])
  const [blockChain, setBlockChain] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(state.auth.isLoggedIn);
  const onLogin = () => {  
    setIsLoggedIn(true);
  }
  const [qrCode, setQrCode] = useState({});
  const [productDetail, setProductDetail] = useState({});
  const getData = async () => {
    if (id) {
      setIsLoading(true);
      let path = apiUrl.getPlasticCreditWedDetails + window.atob(transactionId) + '/' + window.atob(pcId);
      const fr = await helper.getData(path);
      const res = await fr.response;
      if (res.success) {
        setOnerDetails(res?.data?.ownerDetails)
        setParticipantData(res?.data?.circulatoryParticipants)
        setBlockChain(res?.data?.blockchainHash)
        setQrCode(res?.data?.qrCode);
        setProductDetail(res?.data?.productDetails);
      } else {
        toast.error(res.message)
      }
    }
    setIsLoading(false);
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <React.Fragment>
      <div className="back-arrow" style={{ color: 'black', top: 10 }}>
            <Link to={`/admin/transaction/plasticCreditCertification/${transactionId}&${pcId}`}>
              <i className="fa-solid fa-arrow-left" />
            </Link>
      </div>
      <div className={`certificate-page certificate-login ${isLoading ? "loading" : ""}`}>
        <div className="container-items header-bg-img">
          {<CertificateHeader isLink={true} ownerDetails={ownerDetails} id={id} productDetail={productDetail} qrCode={qrCode} participantData={participantData} />}
          <div className="certificates-page">
            <div className="content-section-wrap container-space more-info-space">
              <CirculatoryParticipants CirculatoryParticipantData={participantData} />
              <div className={`product-details ${isBlockchain ? 'toggle' : ''}`} >
                <h3 className="product-title">
                  <div className="title-data">Blockchain Hash</div>
                  <i className="fa-solid fa-circle-chevron-up toggle-data" onClick={(e) => { setIsBlockchain(!isBlockchain) }} />
                </h3>
                <div className="table-wrap" style={{ display: isBlockchain ? 'none' : 'block' }}>
                  <table cellSpacing={0} border={1}>
                    <tbody>
                      <tr>
                        <td>Blockchain Hash</td>
                        <td>
                          {blockChain && blockChain}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {<RemakeCertificates id={transactionId} />}
              {!isLoggedIn &&
              <DocumentLogin onLogin={onLogin}/>}
              {isLoggedIn &&
              <Documents id={transactionId} />
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PlasticCerditCertificationMoreInfo;
