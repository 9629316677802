import React, { useMemo } from "react";
import Map from "../../../../components/certificateMap";
export interface CertificateMap {
  locationList: any;
}
export const PlasticCerditCertificationMap = (props: CertificateMap) => {
  const { locationList } = props;

  const wayPoint = useMemo(() => {
    let tempLocation = locationList?.slice(1, locationList.length - 1);

    let locationWayPoint =
      tempLocation &&
      tempLocation.map((location?: any) => {
        const latLng = location?.location?.split(",");
        const origin = {
          lat: parseFloat(latLng[0]),
          lng: parseFloat(latLng[1]),
        };
        return origin;
      });

    return locationWayPoint;
  }, [locationList]);

  if (!locationList || locationList.length === 0) {
    return <></>;
  }
  const originLatLng = locationList && locationList[0]?.location?.split(",");
  const origin = {
    lat: parseFloat(originLatLng[0]),
    lng: parseFloat(originLatLng[1]),
  };

  const destinationLatLng =
    locationList && locationList[locationList.length - 1]?.location?.split(",");
  const destination = {
    lat: parseFloat(destinationLatLng[0]),
    lng: parseFloat(destinationLatLng[1]),
  };

  const getwayPoint = () => {
    if (locationList && locationList.length > 2) {
      let tempLocation = locationList.slice(1, locationList.length - 1);
      let locationWayPoint =
        tempLocation &&
        tempLocation.map((location?: any) => {
          const latLng = location?.location?.split(",");
          const origin = {
            lat: parseFloat(latLng[0]),
            lng: parseFloat(latLng[1]),
          };
          return { location: new google.maps.LatLng(origin.lat, origin.lng) };
        });
      return locationWayPoint;
    }
  };

  return (
    <React.Fragment>
      <Map
        origin={origin}
        destination={destination}
        waypoints={getwayPoint()}
        center={origin}
        wayPoint={wayPoint}
      />
    </React.Fragment>
  );
};
