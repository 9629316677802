import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  fileType,
  inwardTransferExcelURL,
  regularExcelSampleURL,
  routesConstant,
  transactionRegistryFileUploadSections,
} from '../../../constants/comman.constant';
import ConfirmationPopup from '../../../components/confirmationPopup';
import DatePicker from 'react-datepicker';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { toast } from 'react-toastify';
import {
  isValidExcelFile,
  isValidFile,
} from '../../../shared/remake-green-utils';
import { fileDetails } from './bulkUploadTransactionProps';
import moment from 'moment';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { Button, Form } from 'react-bootstrap';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { getFormatBytes } from '../../../utils/files/commanFilefunction';
const UploadTransactions = (props: any) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [isInwardTransfer, setIsInwardTransfer] = useState(false);
  const [fileUploadedDetails, setFileUploadedDetails] = useState(fileDetails);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchValue, setSearchValue] = useState('');
  const [bulkUploadId, setBulkUploadId] = useState('');
  const [bulkUploadList, setBulkUploadList] = useState([]);
  const [submitPopup, setSubmitPopup] = useState({ isOpen: false, data: null });

  useEffect(() => {
    getBulkUploadHistoryList(false);
  }, []);

  const onChangeIsRegular = (e: any) => {
    const checkValue = e.target.checked;
    setIsInwardTransfer(checkValue);
  };
  const handleSelectedFile = (e: any, isLetMeRetry: boolean = false) => {
    let name = e.target.name;
    let file = e.target.files[0];
    const maxFileSize = 5000000;
    if (!isLetMeRetry) {
      setBulkUploadId('');
    }
    if (file.size >= maxFileSize) {
      toast.error(translate('ERRORS.INVALID.DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidExcelFile(file.type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    validateExcelFile(file, name, fileType.ExcelImport, e, isLetMeRetry);
  };
  const validateExcelFile = async (
    file: any,
    name: string,
    fileType: string,
    e: any,
    isLetMeRetry: boolean
  ) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', fileType);
    formData.append('bulk_upload_id', !isLetMeRetry ? '' : bulkUploadId);
    let path = apiUrl.excelFileValidateForLink;
    const fr = await helper.formPost(formData, path);
    const res = await fr.response;
    if (res.success) {
      e.target.value = null;
      setBulkUploadId(res.data?.bulk_upload_id);
      setFileUploadedDetails({
        ...fileUploadedDetails,
        file: file,
        fileSize: file.size,
        fileName: file.name,
        errorCount: res.data?.errorCount,
        totalCount: res.data?.totalCount,
        startDate: res.data?.fillDetails?.startDate,
        endDate: res.data?.fillDetails?.endDate,
        materialType: res.data?.fillDetails?.materialType.join(', '),
        subMaterialType: res.data?.fillDetails?.subMaterialType.join(', '),
      });
      if (res.data?.errorCount != 0) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href =
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
          res.data?.excelBase64;
        a.download = file.name;
        a.click();
      } else {
        toast.success('File validated successfully');
      }
    } else {
      toast.error(res.message);
    }
  };
  const handleBulkUploadInsert = async () => {
    let formData = new FormData();
    formData.append('file', fileUploadedDetails.file);
    formData.append('bulk_upload_id', bulkUploadId);
    formData.append('remarks', fileUploadedDetails.remarks);
    let path = apiUrl.excelFileImportForLink;
    const fr = await helper.formPost(formData, path);
    const res = await fr.response;
    if (res.success) {
      setBulkUploadId('');
      toast.success('Records inserted successfully');
      setFileUploadedDetails(fileDetails);
      getBulkUploadHistoryList(true);
    } else {
      toast.error(res.message);
    }
  };
  const getBulkUploadHistoryList = async (reset: boolean) => {
    const parms = {
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),
      search_value: reset ? '' : searchValue,
    };
    let path = apiUrl.excelUploadHistoryList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setBulkUploadList(res.data.data);
    } else {
      toast.error(res.message);
    }
  };
  const performSearch = (e: any) => {
    e.preventDefault();
    getBulkUploadHistoryList(false);
  };
  const handeleReset = () => {
    setDateRange([null, null]);
    getBulkUploadHistoryList(true);
    setSearchValue('');
  };
  const markAsComplete = async (item: any) => {
    let path = apiUrl.excelMarkAsComplete;
    const fr = await helper.getData(path, { bulk_upload_id: item.id });
    const res = await fr.response;
    if (res.success) {
      getBulkUploadHistoryList(true);
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      {submitPopup.isOpen === true && (
        <ConfirmationPopup
          title={translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.SUBMIT')}
          message={translate(
            'BULK_UPLOAD_TRANSACTIONS.TITLES.COMPLATE_REQUEST_MESSAGE'
          )}
          cancelText={
            translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.CANCEL') || 'Cancel'
          }
          submitText={
            translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.PROCEED') || 'Proceed'
          }
          handleCancel={() => setSubmitPopup({ isOpen: false, data: null })}
          handleSubmit={() => {
            markAsComplete(submitPopup.data);
          }}
        />
      )}
      <div className='container'>
        <div className='body-content-main'>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/'>
                    {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.HOME')}
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link
                    to={routesConstant.transactionList}
                    state={{
                      pcrType: props.pcrType,
                      isGuestUser: props.isGuestUser,
                    }}
                  >
                    {translate(
                      'TRANSACTION_REGISTRY_PAGE.TITLES.TRANSACTION_REGISTRY'
                    )}
                  </Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  {translate('UPLOAD_TRANSACTION.TITLES.UPLOAD_TRANSATIONS')}
                </li>
              </ol>
            </nav>
            <div className='panel-main big-panel panel-generate admin-profile-changes admin-upload-transaction'>
              <h2 className='panel-title-main'>
                {translate('UPLOAD_TRANSACTION.TITLES.UPLOAD_TRANSATIONS')}
              </h2>

              <div className='panel-box-main'>
                <div className='panel-box'></div>
                <div className='action-panel pt-4 ps-4 pe-4 pb-0'>
                  <div className='action-panel-head d-flex justify-content-between mb-2 row'>
                    <div className='col-sm-6 col-md-6 col-lg-6'>
                      <div className='toggle-button-light'>
                        <div className='form-group required mt-3 mb-0'>
                          <div className='toggle-button big '>
                            <div
                              className={`form-group-switch ${
                                isInwardTransfer ? 'checked' : ''
                              }`}
                            >
                              <span className='left'>
                                {translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.REGULAR'
                                )}
                              </span>
                              <label className='switch '>
                                <input
                                  type='checkbox'
                                  name='is_regular_transfered'
                                  id='is_regular_transfered'
                                  checked={isInwardTransfer}
                                  onChange={onChangeIsRegular}
                                />
                                <span className='slider round' />
                              </label>
                              <span className='right'>
                                {translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.INWARD_TRANSFER'
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-6 mt-2 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0'>
                      <div className='form-o-submit mt-0'>
                        <a
                          href='javascript:void(0)'
                          className='btn btn-primary'
                          onClick={async () => {
                            let path = apiUrl.downloadSample;
                            const fr = await helper.getData(path, {
                              isInward: isInwardTransfer,
                            });
                            const res = await fr.response;
                            if (res.success) {
                              const a = document.createElement('a');
                              document.body.appendChild(a);
                              a.href =
                                'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
                                res.data;
                              a.download = isInwardTransfer
                                ? 'TRP_Inward_Sample.xlsx'
                                : 'TRP_Regular_Sample.xlsx';
                              a.click();
                            } else {
                              toast.error(res.message);
                            }
                          }}
                        >
                          {translate(
                            'BULK_UPLOAD_TRANSACTIONS.TITLES.DOWNLOAD_SAMPLE'
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='attach-document'>
                    <div className='font-base'>
                      {translate('REGISTER.TITLES.ATTACH_DOCUMENTS')}
                    </div>
                    <div className='file-drop-area text-center border-design'>
                      <span className='file-msg'>
                        {translate('REGISTER.TITLES.DRAG')} &amp;{' '}
                        {translate('REGISTER.TITLES.DROP_OR')}{' '}
                        <span>{translate('REGISTER.TITLES.BROWSE')}</span>
                        <br />
                        <p className='note'>
                          {translate('REGISTER.TITLES.SUPPORTED_FORMATS')}{' '}
                          {translate(
                            'REGISTER.TITLES.DOCUMENT_FORMATS_BULK_UPLOAD'
                          )}
                        </p>
                      </span>
                      <input
                        className='file-input'
                        type='file'
                        name='excel_import'
                        id='excel_import'
                        accept='.xlsx'
                        onChange={handleSelectedFile}
                      />
                    </div>
                  </div>
                </div>
                {bulkUploadId && (
                  <div className='main-upload-box ps-4 pe-4 mb-4'>
                    <div className='upload-box'>
                      <div className='upload-box-inner'>
                        <div className='row d-flex align-items-center'>
                          <div className='col-lg-3'>
                            <div className='file-name-area'>
                              <div className='row'>
                                <div className='col-lg-3'>
                                  <div className='file-icon'>
                                    <img src='/assets/images/MS-Excel.svg' />
                                  </div>
                                </div>
                                <div className='col-lg-9'>
                                  <p className='file-details'>
                                    {fileUploadedDetails.fileName}
                                  </p>
                                  <p className='file-size'>
                                    {getFormatBytes(
                                      fileUploadedDetails.fileSize,
                                      2
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <div className='record-deatils'>
                              <div className='row'>
                                <div className='col-lg-8'>
                                  <p className='total-count'>
                                    <span>
                                      {translate(
                                        'BULK_UPLOAD_TRANSACTIONS.TITLES.RECORD_DETAILS'
                                      )}{' '}
                                      :
                                    </span>{' '}
                                    {translate(
                                      'BULK_UPLOAD_TRANSACTIONS.TITLES.TOTAL'
                                    )}{' '}
                                    - {fileUploadedDetails.totalCount}
                                  </p>
                                </div>
                                <div className='col-lg-4'>
                                  <p className='error-list'>
                                    {translate(
                                      'BULK_UPLOAD_TRANSACTIONS.TITLES.ERROR'
                                    )}{' '}
                                    - {fileUploadedDetails.errorCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-5 d-flex justify-content-lg-end mt-2'>
                            <div className='upload-btn-wrapper'>
                              <button className='btn btn-primary btn-outline-primary btn-sm btn btn-primary'>
                                {translate(
                                  'BULK_UPLOAD_TRANSACTIONS.TITLES.LET_ME_RETRY'
                                )}
                              </button>
                              <input
                                className='file-input'
                                type='file'
                                name='excel_import_retry'
                                id='excel_import_retry'
                                accept='.xlsx'
                                onChange={(e) => {
                                  handleSelectedFile(e, true);
                                }}
                              />
                            </div>
                            <button
                              type='button'
                              className='continue-btn btn btn-primary btn-sm btn btn-primary ms-2 ms-sm-4 ms-md-4 ms-lg-4 ms-xl-4'
                              onClick={handleBulkUploadInsert}
                              disabled={
                                fileUploadedDetails.totalCount ==
                                fileUploadedDetails.errorCount
                              }
                            >
                              {translate(
                                'BULK_UPLOAD_TRANSACTIONS.TITLES.CONTINUE'
                              )}
                            </button>
                          </div>
                        </div>
                        <div className='row mt-5 inner-deatils'>
                          <div className='col-lg-4' style={{ display: 'flex' }}>
                            <p className='title-tag'>
                              {translate(
                                'BULK_UPLOAD_TRANSACTIONS.TITLES.DATE'
                              )}
                              &nbsp; :
                            </p>
                            &nbsp;
                            {fileUploadedDetails.startDate &&
                              fileUploadedDetails.endDate && (
                                <p className='title-details'>
                                  {moment(
                                    new Date(fileUploadedDetails.startDate)
                                  ).format('DD/MM/YYYY')}{' '}
                                  -{' '}
                                  {moment(
                                    new Date(fileUploadedDetails.endDate)
                                  ).format('DD/MM/YYYY')}
                                </p>
                              )}
                          </div>
                          <div className='col-lg-4' style={{ display: 'flex' }}>
                            <p className='title-tag'>
                              {translate(
                                'BULK_UPLOAD_TRANSACTIONS.TITLES.MATERIAL_TYPE'
                              )}
                              &nbsp; :
                            </p>
                            &nbsp;
                            <p className='title-details'>
                              {fileUploadedDetails.materialType
                                ? fileUploadedDetails.materialType
                                : '-'}
                            </p>
                          </div>
                          <div className='col-lg-4' style={{ display: 'flex' }}>
                            <p className='title-tag'>
                              {translate(
                                'BULK_UPLOAD_TRANSACTIONS.TITLES.MATERIAL_SUB_TYPE'
                              )}
                              &nbsp; :
                            </p>
                            &nbsp;
                            <p className='title-details'>
                              {fileUploadedDetails.subMaterialType
                                ? fileUploadedDetails.subMaterialType
                                : '-'}
                            </p>
                          </div>
                        </div>
                        <div className='row mt-2'>
                          <div className='col-lg-12'>
                            <div className='form-group  group-i-input'>
                              <label className='form-label'>
                                {translate(
                                  'BULK_UPLOAD_TRANSACTIONS.TITLES.REMARKS'
                                )}
                              </label>
                              <textarea
                                rows={3}
                                placeholder={
                                  translate(
                                    'BULK_UPLOAD_TRANSACTIONS.TITLES.ENTER_REMARKS'
                                  ) + ''
                                }
                                name='remark'
                                onChange={(e) => {
                                  setFileUploadedDetails({
                                    ...fileUploadedDetails,
                                    remarks: e.target.value,
                                  });
                                }}
                                value={fileUploadedDetails.remarks}
                                maxLength={commanValidation.maxLengthAddress}
                                className='form-control'
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className='transaction-history'>
                  <div className='panel-title panel-white'>
                    <div className='left'>
                      <div className='title'>
                        {translate(
                          'BULK_UPLOAD_TRANSACTIONS.TITLES.TRANSACTION_HISTORY'
                        )}
                      </div>
                    </div>
                    <div className='right'>
                      <div className='row top-filters'>
                        <div className='col-lg-5'>
                          <div className='date-item-picker item-in me-0'>
                            <div className='date-picker-main'>
                              <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                className='form-control'
                                placeholderText={
                                  translate(
                                    'BULK_UPLOAD_TRANSACTIONS.TITLES.SELECT_DATE_RANGE'
                                  ) + ''
                                }
                                dateFormat='dd/MM/yyyy'
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                onChange={(update: any) => {
                                  setDateRange(update);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-4'>
                          <div className='type-select item-in'>
                            <div className='item input-min-width'>
                              <div className='input-search-item form-group mb-md-0 mb-lg-0 mb-xl-0'>
                                <label
                                  // for="searchicon"
                                  className='searchicon'
                                >
                                  <i className='fa fa-search'></i>
                                </label>
                                <input
                                  className='form-control'
                                  id='searchicon'
                                  placeholder={
                                    translate(
                                      'BULK_UPLOAD_TRANSACTIONS.TITLES.SEARCH'
                                    ) + ''
                                  }
                                  value={searchValue}
                                  onChange={(e) => {
                                    setSearchValue(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      performSearch(e);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-3'>
                          <div className='search-button item-in'>
                            <Button
                              className='btn  btn-sm btn-outline-primary btn-icon'
                              type='button'
                              title='Search'
                              onClick={(e) => {
                                e.preventDefault();
                                getBulkUploadHistoryList(false);
                              }}
                            >
                              <i
                                className='fa fa-search'
                                aria-hidden='true'
                              ></i>
                            </Button>

                            <Button
                              className='btn  btn-sm  btn-outline-primary btn-icon ms-3'
                              title='Reset'
                              type='button'
                              onClick={(e) => {
                                e.preventDefault();
                                handeleReset();
                              }}
                            >
                              <i
                                className='fa fa-refresh'
                                aria-hidden='true'
                              ></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='panel-data transaction-history-inner'>
                    {bulkUploadList.length == 0 && (
                      <h3 className='center-message-item'>
                        {translate(
                          'BULK_UPLOAD_TRANSACTIONS.TITLES.NO_RECORDS'
                        )}
                      </h3>
                    )}
                    {bulkUploadList.length > 0 &&
                      bulkUploadList.map((item: any, index: number) => {
                        return (
                          <div
                            className='profile-history-main mt-4'
                            key={index}
                          >
                            <div className='profile-log-inner'>
                              <div className='log-header'>
                                <div className='row'>
                                  <div className='col-lg-6'>
                                    <b className='p-2'>{item.bulk_upload_id}</b>
                                    {translate(
                                      'BULK_UPLOAD_TRANSACTIONS.TITLES.FILE_UPLOAD'
                                    )}
                                    :{' '}
                                    <b>
                                      {moment(new Date(item.created_at)).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </b>
                                  </div>
                                  <div className='col-lg-6'>
                                    <div className='row d-flex justify-content-end'>
                                      <div className='col-lg-5'>
                                        <p className='total-count'>
                                          <span>
                                            {translate(
                                              'BULK_UPLOAD_TRANSACTIONS.TITLES.RECORD_DETAILS'
                                            )}{' '}
                                            :
                                          </span>{' '}
                                          {translate(
                                            'BULK_UPLOAD_TRANSACTIONS.TITLES.TOTAL'
                                          )}{' '}
                                          - {item.total_count}
                                        </p>
                                      </div>
                                      <div className='col-lg-3'>
                                        <p className='error-list'>
                                          {translate(
                                            'BULK_UPLOAD_TRANSACTIONS.TITLES.ERROR'
                                          )}{' '}
                                          - {item.error_count}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='table-responsive pt-4 pb-4 pe-3 ps-3'>
                                <table className='table'>
                                  <tbody>
                                    <tr>
                                      <th>
                                        {translate(
                                          'BULK_UPLOAD_TRANSACTIONS.TITLES.FILENAME'
                                        )}
                                      </th>
                                      <th>
                                        {translate(
                                          'BULK_UPLOAD_TRANSACTIONS.TITLES.MATERIAL_TYPE'
                                        )}
                                      </th>
                                      <th>
                                        {translate(
                                          'BULK_UPLOAD_TRANSACTIONS.TITLES.MATERIAL_SUB_TYPE'
                                        )}
                                      </th>
                                      <th>
                                        {translate(
                                          'BULK_UPLOAD_TRANSACTIONS.TITLES.REMARKS'
                                        )}
                                      </th>
                                      {/* <th>Uploaded by</th> */}
                                      <th>
                                        {translate(
                                          'BULK_UPLOAD_TRANSACTIONS.TITLES.DATE_RANGE'
                                        )}
                                      </th>
                                      <th>
                                        {translate(
                                          'BULK_UPLOAD_TRANSACTIONS.TITLES.ACTION'
                                        )}
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          href='javascript:void(0)'
                                          className='filename-field'
                                          data-bs-toggle='tooltip'
                                          data-bs-placement='bottom'
                                          title={item.file_name}
                                        >
                                          {item.file_name}
                                        </a>
                                      </td>
                                      <td>
                                        {item.material_type ? (
                                          <a
                                            href='javascript:void(0)'
                                            className='material-type'
                                            data-bs-toggle='tooltip'
                                            data-bs-placement='bottom'
                                            title={item.material_type}
                                          >
                                            {item.material_type}
                                          </a>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td>
                                        {item.sub_material_type ? (
                                          <a
                                            href='javascript:void(0)'
                                            className='remark'
                                            data-bs-toggle='tooltip'
                                            data-bs-placement='bottom'
                                            title={item.sub_material_type}
                                          >
                                            {item.sub_material_type}
                                          </a>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td>
                                        <a
                                          href='javascript:void(0)'
                                          className='remark'
                                          data-bs-toggle='tooltip'
                                          data-bs-placement='bottom'
                                          title={item.remarks}
                                        >
                                          {item.remarks ? item.remarks : '-'}
                                        </a>
                                      </td>
                                      {/* <td>Admin123@yopmail.com</td> */}
                                      <td>
                                        {item.date_range
                                          ? item.date_range
                                          : '-'}
                                      </td>
                                      <td>
                                        <div>
                                          {item.success_file_url && (
                                            <a
                                              href={item.success_file_url}
                                              className='success-link'
                                            >
                                              {translate(
                                                'BULK_UPLOAD_TRANSACTIONS.TITLES.SUCCESS'
                                              )}
                                            </a>
                                          )}
                                          {item.error_count > 0 && (
                                            <a
                                              href={item.error_file_url}
                                              className='error-link ms-3'
                                            >
                                              {translate(
                                                'BULK_UPLOAD_TRANSACTIONS.TITLES.ERROR'
                                              )}
                                            </a>
                                          )}
                                          {!item.mark_as_complete ? (
                                            <a
                                              href='javascript:void(0);'
                                              onClick={() =>
                                                // markAsComplete(item)
                                                setSubmitPopup({
                                                  isOpen: true,
                                                  data: item,
                                                })
                                              }
                                              className='success-link ms-3'
                                            >
                                              {translate(
                                                'BULK_UPLOAD_TRANSACTIONS.TITLES.COMPLETE'
                                              )}
                                            </a>
                                          ) : item.error_count > 0 &&
                                            item.mark_as_complete ? (
                                            <a
                                              href='javascript:void(0);'
                                              className='success ms-3'
                                            >
                                              {translate(
                                                'BULK_UPLOAD_TRANSACTIONS.TITLES.COMPLETED'
                                              )}
                                            </a>
                                          ) : null}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadTransactions;
