import React from 'react';
import {
  certificateType,
  landFillAreaSave,
  materialType,
} from '../../../../shared/remake-green-utils/common';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../app/types/store';
import { store } from '../../../../app/store';
import { Link, useParams } from 'react-router-dom';
import { apiUrl } from '../../../../app/api/services/apiUrl';
import { helper } from '../../../../app/api/api.helper';
import { toast } from 'react-toastify';
import { useReCreatePdfMutation } from '../../../../app/api/services/PCRequestManagement.service';
import oceanlogo from '../../../../svg/21.svg';
export interface ProductDetail {
  productDetail?: any;
  ownerDetails?: any;
  certificate_url: any;
  linkCertiTransaction_id: any;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const ProductDetails = (props: ProductDetail) => {
  const {
    productDetail,
    ownerDetails,
    certificate_url,
    linkCertiTransaction_id,
  } = props;
  const state: RootState = store.getState();
  const [reCreatePdf] = useReCreatePdfMutation();
  const { id } = useParams();
  const { t: translate } = useTranslation();

  const pcId = id?.split('&')[1] || '';

  return (
    <React.Fragment>
      <div className='product-details'>
        <h3 className='product-title'>
          {translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.PRODUCT_DETAILS')}
          <div className='more-info-button'>
            <Link
              to={
                state.auth.isLoggedIn
                  ? window.location.href.indexOf('admin') > -1
                    ? `/admin/transaction/plasticCreditCertificationMoreInfo/${id}`
                    : `/transaction/plasticCreditCertificationMoreInfo/${id}`
                  : `/transaction/plasticCreditCertificationMoreInfo/${id}`
              }
              className='more-info-button'
            >
              {translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.MORE_INFO')}
            </Link>
            <a
              onClick={async () => {
                const response = await reCreatePdf({
                  linkTransactionCertificateDetailIds: [
                    Number(linkCertiTransaction_id),
                  ],
                  pcId: window.atob(pcId),
                }).unwrap();
                if (response.success) {
                  let path = apiUrl.prepareFile + response?.data?.url;
                  const fr = await helper.getData(path);
                  const res = await fr.response;
                  if (res.data) {
                    window.open(
                      process.env.REACT_APP_BASE_URL_API +
                        apiUrl.downloadFile +
                        response?.data?.url
                    );
                    toast.success('Certificate Downloaded');
                  } else {
                    toast.error(res.message);
                  }
                } else {
                  toast.error(response.data.message);
                }
              }}
              className='more-info-button ms-2'
            >
              {translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.DOWNLOAD')}
            </a>
          </div>
          {/* <div
            onClick={async () => {
              let path =
                apiUrl.prepareFile + certificate_url;
              const fr = await helper.getData(path);
              const res = await fr.response;
              if (res.data) {
                window.open(
                  process.env.REACT_APP_BASE_URL_API +
                  apiUrl.downloadFile +
                  certificate_url
                );
                toast.success('Certificate Downloaded');
              } else {
                toast.error(res.message);
              }
            }}
            className="more-info-button"
          >

          </div> */}
        </h3>

        <div className='table-wrap'>
          <table cellSpacing={0} border-color='grey' border={1}>
            <tbody>
              <tr>
                <td>
                  {translate(
                    'CERTIFICATE_PAGE.PRODUCT_DETAILS.WASTE_DESCRIPTION'
                  )}
                </td>
                <td>
                  {capitalizeFirstLetter(
                    productDetail?.wasteDescription
                      ? productDetail?.wasteDescription
                      : ''
                  )}
                </td>
              </tr>
              <tr>
                <td>{translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.HSN')}</td>
                <td>{productDetail?.hsnSac}</td>
              </tr>
              <tr>
                <td>
                  {translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.WASTE_QUANTITY')}
                </td>
                <td>{productDetail?.plasticWasteQuantity}</td>
              </tr>
              <tr>
                <td>
                  {translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.REMAKE_CREDIT')}
                </td>
                <td>{productDetail?.remakePlasticCredits}</td>
              </tr>
              {ownerDetails?.creditTypeId !== certificateType.RECYCLING && (
                <tr>
                  <td>
                    {translate(
                      'CERTIFICATE_PAGE.PRODUCT_DETAILS.LANDFILL_AREA'
                    )}
                  </td>
                  <td>
                    {parseInt(productDetail.materialType) ===
                      materialType.PLASTIC && (
                      <>
                        {productDetail.landfillSaved} ft
                        <sup>3</sup>
                      </>
                    )}
                    {parseInt(productDetail.materialType) !==
                      materialType.PLASTIC && <>-</>}
                  </td>
                </tr>
              )}
              {ownerDetails?.creditTypeId !== certificateType.COLLECTION && (
                <tr>
                  <td>
                    {translate(
                      'CERTIFICATE_PAGE.PRODUCT_DETAILS.GHG_CO2_EMISSION'
                    )}
                  </td>
                  <td>
                    {parseInt(productDetail.materialType) ===
                      materialType.PLASTIC && (
                      <>{productDetail?.co2EmissionAmount}</>
                    )}
                    {parseInt(productDetail.materialType) ===
                      materialType.ALUMINIUM && (
                      <>{productDetail?.co2EmissionAmount}</>
                    )}
                    {parseInt(productDetail.materialType) !==
                      materialType.ALUMINIUM &&
                      parseInt(productDetail.materialType) !==
                        materialType.PLASTIC && <>-</>}
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  {translate(
                    'CERTIFICATE_PAGE.PRODUCT_DETAILS.BIO_DIVERSITY_IMPACT'
                  )}
                </td>
                <td>1, 3, 4, 6, 8, 11, 12, 13, 14, 15 & 17</td>
              </tr>
              <tr>
                <td>
                  {translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.SOCIAL_IMPACT')}
                </td>
                <td>
                  {productDetail?.remakePlasticCredits > 0 && (
                    <>
                      {Math.trunc(productDetail?.remakePlasticCredits) <= 1
                        ? 'Empowered ' + 1
                        : 'Empowered ' +
                          Math.trunc(productDetail?.remakePlasticCredits)}{' '}
                      &nbsp;
                      {Math.trunc(productDetail?.remakePlasticCredits) <= 1 &&
                        'Reclaimer'}
                      {Math.trunc(productDetail?.remakePlasticCredits) > 1 &&
                        'Reclaimers'}
                    </>
                  )}
                </td>
              </tr>
              {productDetail?.isOceanBound === 'true' ? (
                <tr>
                  <td>
                    {translate(
                      'CERTIFICATE_PAGE.PRODUCT_DETAILS.GEOGRAPHIC_ASPECT'
                    )}
                  </td>
                  <td>
                    {translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.OCEAN_BOUND')}
                    Ocean-bound
                    <img
                      src={oceanlogo}
                      style={{ marginLeft: 10, height: 30 }}
                    />
                  </td>
                </tr>
              ) : (
                ''
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='list-data-main'>
        <div className='left-area'>
          <ul>
            <li>{translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.FEATURE1')}</li>
            <li>{translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.FEATURE2')}</li>
            <li>{translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.FEATURE3')}</li>
          </ul>
        </div>
        <div className='right-area'>
          <ul>
            <li>{translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.FEATURE4')}</li>
            <li>{translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.FEATURE5')}</li>
            <li>
              {translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.FEATURE6')}

              <a target='_blank' href='https://remake.green/faq'>
                https://remake.green/faq
              </a>
            </li>
            <li>
              {translate('CERTIFICATE_PAGE.PRODUCT_DETAILS.FEATURE7')}
              <a target='_blank' href='https://remake.green/sdg-goal'>
                https://remake.green/sdg-goal
              </a>{' '}
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};
