import { plasticCreditApi } from "..";
import { apiUrl } from "./apiUrl";

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({
    userProfile: builder.query({
      query: (id) =>
      `${apiUrl.getUserProfile}?user_id=${id}`,
    }),
    updateUserProfile: builder.mutation<any, any>({
      query(body) {
        return {
          url: apiUrl.updateUserProfile,
          method: "POST",
          body,
        };
      },
    }),
    updateUserStatus: builder.mutation<any, any>({
      query(body) {
        return {
          url: apiUrl.updateStatus,
          method: "POST",
          body,
        };
      },
    }),
    updateUserDatabaseStatus: builder.mutation<any, any>({
      query(body) {
        return {
          url: apiUrl.updateDatabaseStatus,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { useUserProfileQuery, useUpdateUserProfileMutation,useUpdateUserStatusMutation,useUpdateUserDatabaseStatusMutation } = extendedApi;