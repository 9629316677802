import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
  } from '@reduxjs/toolkit/query';
  import { Mutex } from 'async-mutex';  
import { authLogout } from '../store/slices/auth.slice';
import { RootState } from '../types/store';
import { store } from "../store";
import _ from "lodash";
import { hideLoader, showLoader } from '../../utils/Loader';
  
  const baseUrl = `${process.env.REACT_APP_BASE_URL_API}`;
  
  // Create a new mutex
  const mutex = new Mutex();
  
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {     
      console.log("prepare headers"); 
      const state:RootState = store.getState();
      if (!_.isEmpty(state.auth.token)) {
          headers.set('authorization', `Bearer ${state.auth.token}`);
      }
      showLoader();      
      return headers;
  }
  });
  
  export const customFetchBase: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it    
    await mutex.waitForUnlock();      
    let result = await baseQuery(args, api, extraOptions);   
    hideLoader();      
    if(result.error?.status===401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
  
        try {         
            api.dispatch(authLogout());
            window.location.href = '/login';
         // }
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }    
    return result;
  };