import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import ConfirmationPopup from '../../../components/confirmationPopup';

import moment from 'moment';
import {
  useCreateReclaimerMutation,
  useUpdateReclaimerMutation,
} from '../../../app/api/services/socialWelfare';
import { routesConstant } from '../../../constants/comman.constant';
import {
  isValidFile,
  socialWelfareTab,
} from '../../../shared/remake-green-utils';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { createReclaimerValidator } from './reclaimerValidator';

const AddReclaimer = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [addReclaimer] = useCreateReclaimerMutation();
  const [editReclaimer] = useUpdateReclaimerMutation();
  const { t: translate } = useTranslation();
  const [data, setData] = useState<any>(null);
  const [reclaimerData, setReclaimerData] = useState({
    reclaimerName: '',
    gender: '',
    contactNumber: '',
    girls: 0,
    boys: 0,
    capacityPerDay: '',
    smallScrapDealer: '',
    collectionCenter: '',
    address: '',
    aadhaarNo: '',
    aadhaarPhoto: '',
    birthDate: new Date(),
    childrens: '',
  });

  const [errors, setErrors] = useState<any>({
    reclaimerName: '',
    gender: '',
    childrens: '',
    aadhaarNo: '',
    aadhaarPhoto: '',
  });

  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not
  const [selectedCollectionCenter, setSelectedCollectionCenter] = useState({
    id: null,
    name: '',
  });
  const [collectionCenterName, setCollectionCneterName] = useState<any>(null);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [collectionCenter, setCollectionCenter] = useState([]);
  const [documentFiles, setDocumentFiles] = useState<any>([]);
  const getReclaimerDetails = async (id: string) => {
    let path = apiUrl.getReclaimer + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setData(res?.data);
      setReclaimerData({
        reclaimerName: res?.data?.reclaimer?.name || '',
        gender: res?.data?.reclaimer?.gender,
        contactNumber: res?.data?.reclaimer?.contact_number,
        girls: res?.data?.reclaimer?.girls,
        boys: res?.data?.reclaimer?.boys,
        capacityPerDay: res?.data?.reclaimer?.capacity_per_day,
        smallScrapDealer: res?.data?.reclaimer?.small_scrap_dealer,
        collectionCenter: res?.data?.reclaimer?.csr_collection_center_id,
        address: res?.data?.reclaimer?.address,
        aadhaarNo: res?.data?.reclaimer?.aadhaar_no,
        aadhaarPhoto: res?.data?.reclaimer?.aadhaar_photo,
        birthDate: new Date(res?.data?.reclaimer?.birth_date),
        childrens: res?.data?.reclaimer?.childrens,
      });

      if (res?.data?.reclaimer?.csr_collection_center) {
        setCollectionCneterName({
          label: res?.data?.reclaimer?.csr_collection_center?.name,
          value: res?.data?.reclaimer?.csr_collection_center_id,
        });

        setSelectedCollectionCenter({
          id: res?.data?.reclaimer?.csr_collection_center_id,
          name: res?.data?.reclaimer?.csr_collection_center?.name,
        });
      }
    } else {
      toast.error(res.message);
    }
  };

  const getCollectionCenterDetails = async () => {
    let path = apiUrl.getCollectionCenter;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      const newArray =
        temArray &&
        temArray.map((item: any) => {
          return { value: item.id, label: item.name };
        });
      newArray && setCollectionCenter(newArray);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    if (id) {
      getReclaimerDetails(id);
    }
  }, [id]);

  useEffect(() => {
    getCollectionCenterDetails();
  }, []);

  const validateForm = (eventValue = reclaimerData, formSubmit = false) => {
    const errs: any = createReclaimerValidator(errors, eventValue);
    setErrors(errs);
    const isFormValid = Object.keys(errs).every(
      (err) => errs[err].length === 0
    );
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      setSubmitPopup(true);
    }
  };

  // useEffect(() => {
  //   if (data) {
  //     setReclaimerName(data.reclaimer.name);
  //     setBoys(data.reclaimer.boys);
  //     setGirls(data.reclaimer.girls);
  //     setGender(data.reclaimer.gender);
  //     setContactNumber(data.reclaimer.contact_number);
  //     setCapacityPerDay(data.reclaimer.capacity_per_day);
  //     setSmallScrapDealer(data.reclaimer.small_scrap_dealer);
  //     // setBirthDate({
  //     //   day: moment(data.reclaimer.birth_date).format('DD'),
  //     //   month: moment(data.reclaimer.birth_date).format('MM'),
  //     //   year: moment(data.reclaimer.birth_date).format('YYYY'),
  //     // });
  //     setAddress(data.reclaimer.address);
  //     setAadhaarNo(data.reclaimer.aadhaar_no);
  //     setCollectionCenter(data.reclaimer.csr_collection_center.name);
  //     setChildrens(data.reclaimer.childrens);
  //   }
  // }, [data]);

  const handleBirthDate = (date: any) => {
    setReclaimerData({ ...reclaimerData, birthDate: date });
    // setBirthDate(date);
  };

  const createReclaimer = async () => {
    let data = {
      name: reclaimerData.reclaimerName,
      gender: reclaimerData.gender,
      address: reclaimerData.address,
      aadhaar_number: reclaimerData.aadhaarNo,
      csr_collection_center_id: selectedCollectionCenter.id,
      csr_collection_center_name: selectedCollectionCenter.name,
      contact_number: reclaimerData.contactNumber,
      aadhaar_photo: reclaimerData.aadhaarPhoto,
      childrens:
        reclaimerData.girls > 0 || reclaimerData.boys > 0
          ? reclaimerData.girls + reclaimerData.boys + ''
          : reclaimerData.childrens,
      girls: reclaimerData.girls,
      boys: reclaimerData.boys,
      capacity_per_day: reclaimerData.capacityPerDay,
      small_scrap_dealer: reclaimerData.smallScrapDealer,
      date_of_birth: moment(reclaimerData.birthDate).format('YYYY-MM-DD'),
    };

    // const fr: any = await helper.formPost(data, path);
    const res = await addReclaimer(data).unwrap();
    if (res.success) {
      navigate(routesConstant.socialWelfare, {
        state: socialWelfareTab.RECLAIMER,
      });
      toast.success('Reclaimer Added Created Successfully.');
    } else {
      toast.error(res.message);
    }
  };

  const updateReclaimer = async () => {
    let details: any = {
      reclaimer_id: id,
      name: reclaimerData.reclaimerName,
      gender: reclaimerData.gender,
      address: reclaimerData.address,
      // aadhaar_number: reclaimerData.aadhaarNo,
      // csr_collection_center_id: selectedCollectionCenter.id,
      // csr_collection_center_name: selectedCollectionCenter.name,
      contact_number: reclaimerData.contactNumber,
      aadhaar_photo: reclaimerData.aadhaarPhoto,
      childrens:
        reclaimerData.girls > 0 || reclaimerData.boys > 0
          ? reclaimerData.girls + reclaimerData.boys + ''
          : reclaimerData.childrens,
      girls: reclaimerData.girls,
      boys: reclaimerData.boys,
      capacity_per_day: reclaimerData.capacityPerDay,
      small_scrap_dealer: reclaimerData.smallScrapDealer,
      date_of_birth: moment(reclaimerData.birthDate).format('YYYY-MM-DD'),
    };
    if (data?.reclaimer?.aadhaar_no !== reclaimerData.aadhaarNo) {
      details = {
        ...details,
        aadhaar_number: reclaimerData.aadhaarNo,
      };
    }
    if (
      data?.reclaimer?.csr_collection_center_id !== selectedCollectionCenter.id
    ) {
      details = {
        ...details,
        csr_collection_center_id: selectedCollectionCenter.id,
      };

      if (selectedCollectionCenter.id === 0) {
        details = {
          ...details,
          csr_collection_center_name: selectedCollectionCenter.name,
        };
      }
    }

    // const fr: any = await helper.formPost(data, path);
    const res = await editReclaimer(details).unwrap();
    if (res.success) {
      navigate(routesConstant.socialWelfare, {
        state: socialWelfareTab.RECLAIMER,
      });
      toast.success('Reclaimer Updated Successfully.');
    } else {
      toast.error(res.message);
    }
  };

  const handleCollectionCenter = (option: any) => {
    if (option !== null) {
      let collectionCenterData = {
        id: option.value === option.label ? 0 : option.value,
        name: option.label,
      };
      setSelectedCollectionCenter(collectionCenterData);
      setCollectionCneterName(option);
    } else {
      setCollectionCneterName(null);
      setSelectedCollectionCenter({ id: null, name: '' });
    }
  };

  const handleFileUploder = async (file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', '');

    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res: any = await fr.response;
    if (res.success) {
      toast.success(res.message);
      await getFileUploaderData(res?.data?.data);
    } else {
      toast.error(res.message);
    }
  };

  const getFileUploaderData = async (dat: any) => {
    const tDoc: any = documentFiles || [];
    tDoc.push(dat);

    setDocumentFiles([...tDoc]);
    setReclaimerData({ ...reclaimerData, aadhaarPhoto: tDoc[0].document_path });
  };

  const uploadDocument = async (e: any) => {
    let files = e?.target?.files || [];
    if (files[0] > commanValidation.maxFileLength) {
      toast.error(translate('ERRORS.INVALID.MAXIMUM_DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidFile(files[0].type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    await handleFileUploder(files[0]);
  };

  const handleForm = (e: any) => {
    setReclaimerData({ ...reclaimerData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {discardPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          message={`${translate('CSR_MODULE.TITLES.DISCARD_TEXT')}`}
          handleCancel={() => setDiscardPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          handleSubmit={() => {
            if (id) {
              setReclaimerData({
                reclaimerName: data?.reclaimer?.name,
                gender: data?.reclaimer?.gender,
                contactNumber: data?.reclaimer?.contact_number,
                girls: data?.reclaimer?.girls,
                boys: data?.reclaimer?.boys,
                capacityPerDay: data?.reclaimer?.capacity_per_day,
                smallScrapDealer: data?.reclaimer?.small_scrap_dealer,
                collectionCenter: data?.reclaimer?.csr_collection_center_id,
                address: data?.reclaimer?.address,
                aadhaarNo: data?.reclaimer?.aadhaar_no,
                aadhaarPhoto: data?.reclaimer?.aadhaar_photo,
                birthDate: new Date(data?.reclaimer?.birth_date),
                childrens: data?.reclaimer?.childrens,
              });

              if (data?.reclaimer?.csr_collection_center) {
                setCollectionCneterName({
                  label: data?.reclaimer?.csr_collection_center?.name,
                  value: data?.reclaimer?.csr_collection_center_id,
                });

                setSelectedCollectionCenter({
                  id: data?.reclaimer?.csr_collection_center_id,
                  name: data?.reclaimer?.csr_collection_center?.name,
                });
              }
            } else
              setReclaimerData({
                reclaimerName: '',
                gender: '',
                contactNumber: '',
                girls: 0,
                boys: 0,
                capacityPerDay: '',
                smallScrapDealer: '',
                collectionCenter: '',
                address: '',
                aadhaarNo: '',
                aadhaarPhoto: '',
                birthDate: new Date(),
                childrens: '',
              });

            setSelectedCollectionCenter({
              id: null,
              name: '',
            });
            setCollectionCneterName(null);
            setDocumentFiles([]);
            setDiscardPopup(false);
          }}
        />
      )}

      {submitPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          message={`${translate('CSR_MODULE.TITLES.SUBMIT_MESSAGE')}`}
          handleCancel={() => setSubmitPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.PROCEED')}`}
          handleSubmit={() => {
            id ? updateReclaimer() : createReclaimer();
            setSubmitPopup(false);
          }}
        />
      )}
      <main>
        <div className='profile-page admin-profile-changes csr-addreclaimer'>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    {translate('CSR_MODULE.TITLES.HOME')}
                  </a>
                </li>
                <li className='breadcrumb-item'>
                  <a
                    onClick={() => {
                      navigate('/admin/social-welfare', {
                        state: socialWelfareTab.RECLAIMER,
                      });
                    }}
                  >
                    {translate('CSR_MODULE.TITLES.SOCIAL_WELFARE')}
                  </a>
                </li>
                <li className='breadcrumb-item active'>{translate('CSR_MODULE.TITLES.ADD_RECLAIMER')}</li>
              </ol>
            </nav>
            <div className='section-title-main'>
              <div className='title'>{translate('CSR_MODULE.TITLES.ADD_RECLAIMER')}</div>
              {/* <div className='form-o-submit'>
                <a className='export-btn' href='/admin/generateCertificate'>
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='21'
                    height='15.273'
                    viewBox='0 0 21 15.273'
                  >
                    <path
                      id='Path_export'
                      data-name='Path / export'
                      d='M22,11.636,18.182,7.818v2.864H9.591v1.909h8.591v2.864M1,17.364V5.909A1.909,1.909,0,0,1,2.909,4H14.364a1.909,1.909,0,0,1,1.909,1.909V8.773H14.364V5.909H2.909V17.364H14.364V14.5h1.909v2.864a1.909,1.909,0,0,1-1.909,1.909H2.909A1.909,1.909,0,0,1,1,17.364Z'
                      transform='translate(-1 -4)'
                    />
                  </svg>{' '}
                  Export
                </a>
              </div> */}
            </div>
            <div className='display-combine-component'>
              <div className='display-component main-profile-head'>
                <div className='display-head'>
                  <div className='title'>{translate('CSR_MODULE.TITLES.PROFILE_DETAILS')}</div>
                  <div className='form-submit-buttons auto-width-button'>
                    <button
                      type='button'
                      className='btn btn-primary btn-outline-primary btn-sm btn btn-primary'
                      onClick={() => setDiscardPopup(true)}
                    >
                      {translate('CSR_MODULE.TITLES.DISCARD')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm btn btn-primary ms-3'
                      onClick={() => {
                        setIsSubmited(true);
                        validateForm(reclaimerData, true);
                      }}
                    >
                      {translate('CSR_MODULE.TITLES.SAVE_PROFILE')}
                    </button>
                  </div>
                </div>
                <div className='display-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label label className='form-label'>
                          Reclaimer Name
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='reclaimerName'
                          placeholder='Enter Reclaimer name'
                          className='form-control form-control'
                          value={reclaimerData.reclaimerName}
                          onChange={handleForm}
                          required
                        />
                        <span className='error'>{errors.reclaimerName}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <Form.Label className='form-label'>Gender</Form.Label>
                        <select
                          name='gender'
                          className='form-control form-select'
                          onChange={handleForm}
                          value={reclaimerData.gender}
                        >
                          <option value=''>Select Gender</option>
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                        </select>
                        <span className='error'>{errors.gender}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <Form.Label className='form-label'>
                          Contact Number
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='contactNumber'
                          placeholder='Enter Contact Number'
                          className='form-control form-control'
                          value={reclaimerData.contactNumber}
                          onChange={handleForm}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group group-i-input required'>
                        <Form.Label className='form-label'>
                          Childrens
                        </Form.Label>
                        <div className='input-group'>
                          <div className='input-right input-left-right'>
                            <select
                              name='boys'
                              className='form-control form-select'
                              value={reclaimerData.boys}
                              onChange={(e) => {
                                setReclaimerData({
                                  ...reclaimerData,
                                  boys: +e.target.value,
                                  childrens:
                                    reclaimerData.childrens == 'No' ||
                                    reclaimerData.childrens == 'NA'
                                      ? e.target.value + ''
                                      : +reclaimerData.childrens +
                                        +e.target.value +
                                        '',
                                });
                              }}
                            >
                              {[...Array(11)].map((x, i) => {
                                return <option value={i}>{i}</option>;
                              })}
                            </select>
                          </div>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              name='boy'
                              placeholder='Boy'
                            />
                            <span className='error'></span>
                          </div>
                          <div className='input-right input-left-right'>
                            <select
                              name='girls'
                              className='form-control form-select'
                              value={reclaimerData.girls}
                              onChange={(e) => {
                                setReclaimerData({
                                  ...reclaimerData,
                                  girls: +e.target.value,
                                  childrens:
                                    reclaimerData.childrens == 'No' ||
                                    reclaimerData.childrens == 'NA'
                                      ? e.target.value + ''
                                      : +reclaimerData.childrens +
                                        +e.target.value +
                                        '',
                                });
                              }}
                            >
                              {[...Array(11)].map((x, i) => {
                                return <option value={i}>{i}</option>;
                              })}
                            </select>
                          </div>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              name='girl'
                              placeholder='Girl'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group  '>
                        <Form.Label className='form-label'>
                          Capacity Per Day (Kg)
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='capacityPerDay'
                          placeholder='Enter Capacity per day'
                          className='form-control form-control'
                          value={reclaimerData.capacityPerDay}
                          onChange={handleForm}
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group  '>
                        <Form.Label className='form-label'>
                          Small Scrap Dealer
                        </Form.Label>
                        <input
                          type='text'
                          name='smallScrapDealer'
                          placeholder='Enter Small scrap dealer'
                          className='form-control form-control'
                          value={reclaimerData.smallScrapDealer}
                          onChange={handleForm}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='radio-buttons no-req'>
                        <div
                          className='form-check'
                          onClick={() =>
                            setReclaimerData({
                              ...reclaimerData,
                              childrens: 'No',
                              boys: 0,
                              girls: 0,
                            })
                          }
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            name='any_quanty_return'
                            id='yes-icon'
                            value='No'
                            checked={reclaimerData.childrens === 'No'}
                            data-gtm-form-interact-field-id='0'
                          />
                          <Form.Label
                            className='form-check-label'
                            htmlFor='yes-icon'
                          >
                            No
                          </Form.Label>
                        </div>
                        <div
                          className='form-check'
                          onClick={() =>
                            setReclaimerData({
                              ...reclaimerData,
                              childrens: 'NA',
                              boys: 0,
                              girls: 0,
                            })
                          }
                        >
                          <input
                            className='form-check-input'
                            type='radio'
                            name='any_quanty_return'
                            id='no-icon'
                            value='NA'
                            checked={reclaimerData.childrens === 'NA'}
                            data-gtm-form-interact-field-id='1'
                          />
                          <Form.Label
                            className='form-check-label'
                            htmlFor='no-icon'
                          >
                            N/A
                          </Form.Label>
                        </div>
                      </div>
                      <span className='error'>{errors.childrens}</span>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <Form.Label className='form-label'>
                          Collection Centre
                        </Form.Label>
                        {/* <select
                          name='material_type'
                          className='form-control form-select'
                        >
                          <option value={0}>Select Material Type</option>
                          {collectionCenter.length>0}
                        </select> */}
                        <CreatableSelect
                          isClearable
                          value={collectionCenterName}
                          onChange={handleCollectionCenter}
                          options={collectionCenter}
                          placeholder='Select Collection Center'
                        />
                      </div>
                    </div>
                    <div className='col-md-8'>
                      <div className='text-area-content'>
                        <div className='form-group group-i-input '>
                          <Form.Label className='form-label'>
                            Address
                          </Form.Label>
                          <textarea
                            placeholder='Address'
                            name='address'
                            className='form-control'
                            value={reclaimerData.address}
                            onChange={handleForm}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <Form.Label className='form-label'>
                          Aadhaar Card Number
                        </Form.Label>
                        <input
                          type='text'
                          placeholder='Enter Aadhaar Number'
                          name='aadhaarNo'
                          className='form-control form-control'
                          value={reclaimerData.aadhaarNo}
                          onChange={handleForm}
                        />
                        <span className='error'>{errors.aadhaarNo}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <div className='mb-3'>
                          <Form.Label htmlFor='formFile' className='form-label'>
                            Aadhaar Card Photo
                          </Form.Label>
                          {/* <label htmlFor='files'>Select Image</label> */}

                          {!reclaimerData.aadhaarPhoto && (
                            <Form.Control
                              className='file-input'
                              type='file'
                              onChange={uploadDocument}
                              title='Hello'
                              name='aadhaar_photo'
                              // id='files'
                              // style={{ visibility: 'hidden' }}
                              // disabled={id ? true : false}
                              accept='.jpg, .jpeg, .png'
                            />
                          )}
                          {reclaimerData.aadhaarPhoto && (
                            <div
                              className='upload-icons'
                              style={{ top: '0', display: 'block' }}
                            >
                              <i
                                className='fa-solid fa-trash-can'
                                onClick={() => {
                                  setDocumentFiles([]);
                                  setReclaimerData({
                                    ...reclaimerData,
                                    aadhaarPhoto: '',
                                  });
                                }}
                              ></i>
                              <i
                                className='fa fa-eye ms-2 me-2'
                                onClick={() => {
                                  window.open(reclaimerData.aadhaarPhoto);
                                }}
                                aria-hidden='true'
                              ></i>
                            </div>
                          )}
                          {reclaimerData.aadhaarPhoto && (
                            <p
                              className='upload-note'
                              style={{ margin: '0px' }}
                            >
                              <svg
                                width='800px'
                                height='800px'
                                viewBox='0 0 1024 1024'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  fill='#18A675'
                                  d='M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z'
                                />
                              </svg>{' '}
                              File successfully uploaded
                            </p>
                          )}

                          <span className='error'>{errors.aadhaarPhoto}</span>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group group-i-input required'>
                        <Form.Label className='form-label'>
                          Birthdate
                        </Form.Label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selected={reclaimerData.birthDate}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleBirthDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              required
                            />
                            {<span className='error'>{errors.birthDate}</span>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='display-component table-data-main'>
              <div className='row header-area d-flex align-items-center'>
                <div className='col-lg-3'>
                  <div className='title'>Event History</div>
                </div>
                <div className='col-lg-9'>
                  <div className='top-filters three-type row d-flex justify-content-end'>
                    <div className='date-item-picker item-in col-lg-3 me-0'>
                      <div className='date-picker-main'>
                        <div className='react-datepicker-wrapper'>
                          <div className='react-datepicker__input-container'>
                            <input
                              type='text'
                              placeholder='Select Date Range'
                              className='form-control'
                              value=''
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='item-in col-lg-3 mb-3 mb-sm-3 mb-md-3 mb-lg-0'>
                      <div className='input-search-item form-group mb-0'>
                        <input
                          className='form-control'
                          id='searchicon'
                          placeholder='Search'
                          value=''
                        />
                        <i className='fa fa-search'></i>
                      </div>
                    </div>
                    <div className='search-button item-in col-lg-5 mt-1 d-flex justify-content-end'>
                      <button
                        type='button'
                        title='Search'
                        className='btn  btn-sm btn-outline-primary btn-icon btn btn-primary'
                      >
                        <i className='fa fa-search' aria-hidden='true'></i>
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        title='Reset'
                        className='btn  btn-sm  btn-outline-primary btn-icon btn btn-primary ms-3'
                      >
                        <i className='fa fa-refresh' aria-hidden='true'></i>
                      </button>
                      <a
                        role='button'
                        href='#'
                        title='Search'
                        className='btn btn-primary btn-sm btn btn-primary ms-3'
                      >
                        <i className='fa fa-calendar' aria-hidden='true'></i>{' '}
                        Add Event
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='display-body ps-4 pe-4'>
                <div className=' transaction-table listuser-table '>
                  <div className='tab-content'>
                    <div className='listing-transaction'>
                      <div className='profile-history-main'>
                        <div className='profile-log-inner'>
                          <div className='log-header'>
                            Action Requested on: 3/20/2023
                          </div>
                          <div className='table-responsive pt-4'>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <th>Event Name</th>
                                  <th>Event ID</th>
                                  <th>Date of Event</th>
                                  <th>Organiser</th>
                                  <th>Location</th>
                                  <th>POC</th>
                                </tr>
                                <tr>
                                  <td>Waste Drive 4</td>
                                  <td>CD001</td>
                                  <td>26/1/2023 11:30 AM</td>
                                  <td>John Doe</td>
                                  <td>Delhi</td>
                                  <td>Anil Kumar</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='table-responsive pb-4'>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <th>Activity ID</th>
                                  <th>Activity Date</th>
                                  <th>Activity Photo</th>
                                  <th>Material Provided</th>
                                </tr>
                                <tr>
                                  <td>CD001</td>
                                  <td>26/1/2023 11:30 AM</td>
                                  <td>
                                    <a href='#'>
                                      <i
                                        className='fa fa-eye'
                                        aria-hidden='true'
                                      ></i>
                                    </a>
                                  </td>
                                  <td>Anil Kumar</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className='profile-log-inner mt-4'>
                          <div className='log-header'>
                            Action Requested on: 3/20/2023
                          </div>
                          <div className='table-responsive pt-4'>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <th>Event Name</th>
                                  <th>Event ID</th>
                                  <th>Date of Event</th>
                                  <th>Organiser</th>
                                  <th>Location</th>
                                  <th>POC</th>
                                </tr>
                                <tr>
                                  <td>Waste Drive 4</td>
                                  <td>CD001</td>
                                  <td>26/1/2023 11:30 AM</td>
                                  <td>John Doe</td>
                                  <td>Delhi</td>
                                  <td>Anil Kumar</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='table-responsive pb-4'>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <th>Activity ID</th>
                                  <th>Activity Date</th>
                                  <th>Activity Photo</th>
                                  <th>Material Provided</th>
                                </tr>
                                <tr>
                                  <td>CD001</td>
                                  <td>26/1/2023 11:30 AM</td>
                                  <td>
                                    <a href='#'>
                                      <i
                                        className='fa fa-eye'
                                        aria-hidden='true'
                                      ></i>
                                    </a>
                                  </td>
                                  <td>Anil Kumar</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className='profile-log-inner mt-4'>
                          <div className='log-header'>
                            Action Requested on: 3/20/2023
                          </div>
                          <div className='table-responsive pt-4'>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <th>Event Name</th>
                                  <th>Event ID</th>
                                  <th>Date of Event</th>
                                  <th>Organiser</th>
                                  <th>Location</th>
                                  <th>POC</th>
                                </tr>
                                <tr>
                                  <td>Waste Drive 4</td>
                                  <td>CD001</td>
                                  <td>26/1/2023 11:30 AM</td>
                                  <td>John Doe</td>
                                  <td>Delhi</td>
                                  <td>Anil Kumar</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className='table-responsive pb-4'>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <th>Activity ID</th>
                                  <th>Activity Date</th>
                                  <th>Activity Photo</th>
                                  <th>Material Provided</th>
                                </tr>
                                <tr>
                                  <td>CD001</td>
                                  <td>26/1/2023 11:30 AM</td>
                                  <td>
                                    <a href='#'>
                                      <i
                                        className='fa fa-eye'
                                        aria-hidden='true'
                                      ></i>
                                    </a>
                                  </td>
                                  <td>Anil Kumar</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className='listing-content'>
                        <div className='table-footer'>
                          <div className='d-flex justify-content-between d-flex align-items-center'>
                            <div className='left-item text-left'>
                              Total Records : 1 / 1
                            </div>
                            <div className='right-item'>
                              <div className='items-center pagination-main gap-2 text-center'>
                                <button className='border rounded p-1'>
                                  &lt;
                                </button>
                                <ul className='pagination pagination-sm'>
                                  <li className='page-item active'>
                                    <span className='page-link'>1</span>
                                  </li>
                                </ul>
                                <button className='border rounded p-1'>
                                  &gt;
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddReclaimer;
