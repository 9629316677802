import React from 'react';
import { useTranslation } from 'react-i18next';
import { PCRStatus } from '../../../shared/remake-green-utils/common/constants/enum';
import { EnumHelper } from '../../../shared/remake-green-utils/common/utils/enum.helper';

const ActionTakenComponent = (props: any) => {
  const { t: translate } = useTranslation();

  const { approvedBy, date, reason, status } = props.data;
  return (
    <div className='panel-box'>
      <div className='panel-title title-grey'>
        <div className='left'>
          <div className='title'>
            {translate('PROFILE.TITLES.ACTION_TAKEN')}:{' '}
            <span className='data-status'>
              {EnumHelper.GetEnumDescription(PCRStatus, status)}{' '}
              {/* {getPCRequestStatus(status)} */}
            </span>
          </div>
        </div>

        <div className='right'>
          <div className='group-details'>
            <div className='title-data'>
              {translate('PROFILE.TITLES.APPROVED_BY')}: {approvedBy}
            </div>
            <div className='title-data'>
              {translate('TRANSACTION_REGISTRY_PAGE.TITLES.DATE')}: {date}
            </div>
          </div>
        </div>
      </div>
      {/* {reason && ( */}
      <div className='panel-data'>
        <div className='form-group required input-textarea-border'>
          <label className='form-label label-info'>
            {translate('PROFILE.TITLES.REASON_OF_ACTION')}
          </label>
          <textarea className='form-control' rows={4} disabled value={reason} />
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default ActionTakenComponent;
