export const StatusTypes = {
  ACTIVE: "Active",
  TRANSFERRED: "Transferred",
  RETIRED: "Retired",
};

export const ledgerTableColumn = {
  PLASTIC_CREDIT_ID: "RMX ID",
  OWNER_NAME: "Owner Name",
  CREDIT_TYPE: "Credit Type",
  STATUS: "Status",
  MATERIAL_TYPE: "Material Type - Sub-Type",
  QUANTITY: "Quantity",
  CREDIT_UNIT: "Credit Unit",
  TIMESTAMP: "Timestamp - IST DD/MM/YYYY",
};
