export enum UserType {
  ADMIN = 1,
  REMAKE = 2,
  VALUE_CHAIN_PARTNER = 3,
  INDIVIDUAL = 4,
  COMPANY = 5,
}

export enum onBehalfRole {
  BUYER = 'buyer',
  SELLER = 'seller',
}

export enum UserProfileStatus {
  PENDING = 0,
  APPROVED = 1,
  REJECTED = 2,
}

export enum socialWelfareTab {
  ACTIVITIES = 1,
  RECLAIMER = 2,
  EVENT = 3,
}

export enum valueChainPartner {
  pending = 0,
  contacted = 1,
}
