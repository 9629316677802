import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FootprintList from './footprintList';
import { Link } from 'react-router-dom';
import QueueList from './queueList';

const DomainManagement = () => {
  const { t: translate } = useTranslation();
  const [reload, setReload] = useState(false);
  const [isQueue, setIsQueue] = useState(false);
  return (
    <>
      <div className='container'>
        <div className='body-content-main user-management'>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a href='/'>{translate('USER_MANAGEMENT.TITLES.HOME')}</a>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  {translate(
                    'FOOTPRINT_MANAGEMENT.TITLES.FOOTPRINT_MANAGEMENT'
                  )}
                </li>
              </ol>
            </nav>

            <div className='section-title-main'>
              <div className='title'>
                {translate('FOOTPRINT_MANAGEMENT.TITLES.FOOTPRINT_MANAGEMENT')}
              </div>
              {/* <div className='form-o-submit'>
                <a
                  onClick={() => setEntryPopup(true)}
                  className='btn btn-primary'
                >
                  Add Domain
                </a>
              </div> */}
            </div>

            {/* <div className='transaction-table listuser-table'>
              <div className='panel-head-title'>
                <div className='left-content'>
                  <div className='title-normal'>
                    {translate(
                      'FOOTPRINT_MANAGEMENT.TITLES.FOOTPRINT_MANAGEMENT_TEXT'
                    )}
                  </div>
                </div>
              </div>
              <FootprintList setReload={setReload} reload={reload} />
            </div> */}
            <div className='transaction-table management-screen'>
          <ul className='nav nav-tabs' role='tablist'>
            <li className='nav-item'>
              <Link
                to={'#'}
                className={`nav-link ${!isQueue && 'active'}`}
                id='registry1-tab'
                data-toggle='tab'
                role='tab'
                aria-controls='registry1'
                aria-selected='true'
                onClick={(e) => {
                  setIsQueue(false);
                }}
              >
               Assigned Footprint
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to={'#'}
                className={`nav-link ${isQueue && 'active'}`}
                id='registry2-tab'
                data-toggle='tab'
                role='tab'
                aria-controls='registry2'
                aria-selected='false'
                onClick={(e) => {
                  setIsQueue(true);
                }}
              >
                Footprint In Queue
              </Link>
            </li>
          </ul>
          {!isQueue && <FootprintList setReload={setReload} reload={reload} />}
          {isQueue && <QueueList setReload={setReload} reload={reload} />}
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DomainManagement;
