import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TransactionUnLinkedList } from './transactionUnLinkedList';
import { TransactionLinkedList } from './transactionLinkedList';
import ChartStatistics from '../../../components/chartStatistics';

const TransactionRegistryList = () => {
  const [isLinked, setIsLinked] = useState(false);

  return (
    <React.Fragment>
      {/* Add class "linked-transaction" for show drop down  */}
      <div className='container body-content-main transaction-registry linked-transaction'>
        <div className='section-title-main'>
          <div className='title'>Transaction Registry</div>
          <div className='form-o-submit'>
            <Link
              to={'/admin/transaction/create-registry'}
              className='btn btn-primary'
            >
              Register Transaction
            </Link>
            <Link
              to={'/admin/transaction/linkTransection'}
              className='btn btn-primary'
            >
              Link Transactions
            </Link>
            <Link
              to={'/admin/transaction/upload'}
              className='upload-btn'
              style={{ marginRight: '20px' }}
              data-bs-toggle='tooltip'
              data-bs-placement='bottom'
              title='Bulk Upload Button'
            >
              {/* Upload */}

              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22'
                height='22'
                viewBox='0 0 24 24'
              >
                <g
                  id='collection-upload-svgrepo-com'
                  transform='translate(-2 -3)'
                >
                  <path
                    id='Path_8273'
                    data-name='Path 8273'
                    d='M7.5,16.485V11.759H9.395V14.59H19.5V11.759h1.895v4.726Z'
                    transform='translate(1.447 2.305)'
                    fill='#18a675'
                  />
                  <path
                    id='Path_8274'
                    data-name='Path 8274'
                    d='M11.27,11.2l1.591-1.591v3.974h1.895V9.609L16.346,11.2l1.34-1.34L13.808,5.982,9.93,9.861Z'
                    transform='translate(2.087 0.785)'
                    fill='#18a675'
                  />
                  <path
                    id='Path_8275'
                    data-name='Path 8275'
                    d='M5,3H25.211V23.211H5ZM6.895,4.895H23.316V21.316H6.895Z'
                    transform='translate(0.789 0)'
                    fill='#18a675'
                    fill-rule='evenodd'
                  />
                  <path
                    id='Path_8276'
                    data-name='Path 8276'
                    d='M2,6V26.211H22.211V24.316H3.895V6Z'
                    transform='translate(0 0.789)'
                    fill='#18a675'
                  />
                </g>
              </svg>
            </Link>

            <Link
              to={'/admin/transaction/uploadtolink'}
              className='upload-btn'
              data-bs-toggle='tooltip'
              data-bs-placement='bottom'
              title='Bulk Upload Button'
            >
              {/* Upload */}

              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22'
                height='22'
                viewBox='0 0 24 24'
              >
                <g
                  id='collection-upload-svgrepo-com'
                  transform='translate(-2 -3)'
                >
                  <path
                    id='Path_8273'
                    data-name='Path 8273'
                    d='M7.5,16.485V11.759H9.395V14.59H19.5V11.759h1.895v4.726Z'
                    transform='translate(1.447 2.305)'
                    fill='#18a675'
                  />
                  <path
                    id='Path_8274'
                    data-name='Path 8274'
                    d='M11.27,11.2l1.591-1.591v3.974h1.895V9.609L16.346,11.2l1.34-1.34L13.808,5.982,9.93,9.861Z'
                    transform='translate(2.087 0.785)'
                    fill='#18a675'
                  />
                  <path
                    id='Path_8275'
                    data-name='Path 8275'
                    d='M5,3H25.211V23.211H5ZM6.895,4.895H23.316V21.316H6.895Z'
                    transform='translate(0.789 0)'
                    fill='#18a675'
                    fill-rule='evenodd'
                  />
                  <path
                    id='Path_8276'
                    data-name='Path 8276'
                    d='M2,6V26.211H22.211V24.316H3.895V6Z'
                    transform='translate(0 0.789)'
                    fill='#18a675'
                  />
                </g>
              </svg>
            </Link>
            {/* <a className="btn btn-primary btn-outline-primary">Link Transactions</a> */}
          </div>
        </div>
        <ChartStatistics />
        <div className='transaction-table management-screen'>
          <ul className='nav nav-tabs' role='tablist'>
            <li className='nav-item'>
              <Link
                to={'#'}
                className={`nav-link ${!isLinked && 'active'}`}
                id='registry1-tab'
                data-toggle='tab'
                role='tab'
                aria-controls='registry1'
                aria-selected='true'
                onClick={(e) => {
                  setIsLinked(false);
                }}
              >
                Un-linked Transactions
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to={'#'}
                className={`nav-link ${isLinked && 'active'}`}
                id='registry2-tab'
                data-toggle='tab'
                role='tab'
                aria-controls='registry2'
                aria-selected='false'
                onClick={(e) => {
                  setIsLinked(true);
                }}
              >
                Linked Transactions
              </Link>
            </li>
          </ul>
          {!isLinked && <TransactionUnLinkedList />}
          {isLinked && <TransactionLinkedList />}
        </div>
      </div>
    </React.Fragment>
  );
};
export default TransactionRegistryList;
