import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdateUserProfileMutation } from '../../../app/api/services/userProfile.service';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { store } from '../../../app/store';
import { commanLabel } from '../../../constants/comman.constant';
import { useParams } from 'react-router';
import ViewUserProfileDetail from './viewUserProfile';
import { isValidFile, UserType } from '../../../shared/remake-green-utils';
import DocumentTable from '../profile/documnet';
import { Link } from 'react-router-dom';

const ViewUserProfile = () => {
  const { t: translate } = useTranslation();
  const state = store.getState();

  const { user_id } = useParams();
  const [userProfileInfo, setUserProfileInfo] = useState<any>({});
  const [userTypeId, setUserTypeId] = useState('');
  const [deletePopup, setDeletePopup] = useState(false);
  const [documentFiles, setDocumentFiles] = useState<any>([]);
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [editMode, setEditMode] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);

  const fetchProfile = async (refresh = false) => {
    setLoading(true);
    let path = apiUrl.getUserProfile;
    const param = {
      user_id,
      page: refresh ? 1 : page,
      limit: pageLimit,
    };
    const fr = await helper.getData(path, param);
    const res = await fr.response;
    if (res.success) {
      setLoading(false);
      setUserProfileInfo(res);
      if (refresh) {
        setDocumentFiles([...res?.data?.files]);
        setPage(1);
      } else {
        setDocumentFiles([...documentFiles, ...res?.data?.files]);
      }
      setDataCount(res?.data?.total);
      setPageCount(res?.data?.total && Math.ceil(res.data.total / pageLimit));
    } else {
      setLoading(false);
      toast.error(res.message);
    }
  };
  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (userProfileInfo?.data?.total > documentFiles.length) {
      fetchProfile();
    }
  }, [page]);

  useEffect(() => {
    if (!userTypeId) {
      setUserTypeId(userProfileInfo?.data?.userDetail?.user_type_id);
    }
  }, [userProfileInfo?.data?.userDetail?.user_type_id]);

  const updateProfile = async (updatedInfo: any) => {
    const document_ids = documentFiles.map((file: any) => file.id);
    const response = await updateUserProfile({
      ...updatedInfo,
      document_ids,
      user_id,
    }).unwrap();
    if (response.success) {
      fetchProfile(true);
      toast.success(response.message);
      setEditMode(false);
    } else {
      toast.error(response.message);
    }
  };

  const handleFileUploder = async (file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', '');

    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res: any = await fr.response;
    if (res.success) {
      toast.success(res.message);
      await getFileUploaderData(res?.data?.data);
    } else {
      toast.error(res.message);
    }
  };

  const getFileUploaderData = async (dat: any) => {
    const tDoc: any = documentFiles || [];
    tDoc.push(dat);

    setDocumentFiles([...tDoc]);
  };

  const uploadDocument = async (e: any) => {
    let files = e?.target?.files || [];
    if (files[0] > commanValidation.maxFileLength) {
      toast.error(translate('ERRORS.INVALID.MAXIMUM_DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidFile(files[0].type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    await handleFileUploder(files[0]);
  };

  return (
    <React.Fragment>
      <main>
        <section className='profile-page '>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/'>
                    {translate('CREDIT_REQUEST_PAGE.TITLES.HOME')}
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/admin/userManagement'>
                    {translate('USER_MANAGEMENT.TITLES.USER_MANAGEMENT')}
                  </Link>
                </li>
                <li className='breadcrumb-item active'>
                  {translate('PROFILE.TITLES.VIEW_USER_PROFILE')}
                </li>
              </ol>
            </nav>
            <div className='display-combine-component'>
              <div className='display-component main-profile-head'>
                <ViewUserProfileDetail
                  profileDetail={userProfileInfo?.data?.userDetail}
                  updateCompanyProfile={updateProfile}
                  updateEditMode={(mo: boolean) => {
                    setRefresh(false);
                    setEditMode(mo);
                    setRefresh(true);
                  }}
                />
              </div>

              <div className='display-component table-data-main'>
                <div className='display-head'>
                  <div className='title'>Documents</div>
                  {editMode ? (
                    <div className='icons-right-add'>
                      <img src='/assets/images/plus icon-svgrepo-com.svg' />
                      <input
                        className='file-input'
                        type='file'
                        id={'document'}
                        onChange={uploadDocument}
                        accept='.jpg, .jpeg, .png, .pdf, .docx, .doc, .dot, .dotx, .xlsx, .xlsm, .xls, .xlsm'
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className='display-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='table-wrap-data'>
                        {refresh && (
                          <DocumentTable
                            editMode={editMode}
                            files={documentFiles || []}
                            onDelete={(ro: any) => {
                              setSelectedRow(ro);
                              setDeletePopup(true);
                            }}
                            pageCount={pageCount}
                            dataCount={dataCount}
                            page={page}
                            loading={loading}
                            onLoadMore={() => {
                              if (
                                userProfileInfo?.data.total >
                                documentFiles.length
                              ) {
                                setPage((page) => page + 1);
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};
export default ViewUserProfile;
