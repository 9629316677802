import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { commanLabel } from '../../../constants/comman.constant';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { validationMessage } from '../../../constants/validation.message.constant';

const AttachCSR = (props: any) => {
  const { selectedActivities, setSelectedActivities } = props;
  const { t: translate } = useTranslation();
  const columnHelper = createColumnHelper<any>();

  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [activityDateRange, setActivityDateRange] = useState([null, null]);
  const [entryDateRange, setEntryDateRange] = useState([null, null]);
  const [activityStartDate, activityEndDate] = activityDateRange;
  const [materialId, setMaterialId] = useState('');

  const [entryStartDate, entryEndDate] = entryDateRange;
  const [collectionCenterData, setCollectionCenterData] = useState([]);
  const [collectionCenterId, setCollectionCenterId] = useState('');
  const [gender, setGender] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [materialList, setMaterialList] = useState([]);

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = [
    columnHelper.accessor('-', {
      header: () => '-',
      enableSorting: false,
      cell: (props: any) => {
        let id = props?.row?.original?.id;
        return (
          <React.Fragment>
            <div className='form-check ps-0'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                id={`defaultCheck${id}`}
                checked={selectedActivities.includes(id)}
                onClick={() => {
                  if (selectedActivities.length === 0) {
                    setSelectedActivities([id]);
                  } else if (selectedActivities.includes(id)) {
                    let newArray = selectedActivities;
                    newArray = newArray.filter((u: any) => u !== id);
                    setSelectedActivities(newArray);
                  } else {
                    setSelectedActivities([...selectedActivities, id]);
                  }
                }}
              />
              <label
                className='form-check-label'
                htmlFor={`defaultCheck${id}`}
              ></label>
            </div>
          </React.Fragment>
        );
      },
    }),
    columnHelper.accessor((row) => `${row?.activity_id}`, {
      id: 'Activity ID',
      cell: (props: any) => {
        return (
          <>
            {props?.row?.original?.is_bulk_upload && (
              <div
                className='bulk-icons me-2'
                data-bs-toggle='tooltip'
                data-bs-placement='bottom'
                title={props?.row?.original?.bulk_upload_id}
              ></div>
            )}
            {props?.row?.original?.activity_id}
          </>
        );
      },
    }),

    columnHelper.accessor(
      (row) => `${row?.csr_event && row?.csr_event?.event_id}`,
      {
        id: 'Event ID',
      }
    ),

    columnHelper.accessor(
      (row) => `${row?.csr_event && row?.csr_event?.organizer}`,
      {
        id: 'Organiser',
      }
    ),
    // columnHelper.accessor(
    //   (row) =>
    //     moment(`${row?.entry_date ? row?.entry_date : '-'}`).format(
    //       'DD/MM/YYYY'
    //     ),
    //   {
    //     id: 'Date of Entry',
    //   }
    // ),
    // columnHelper.accessor(
    //   (row) =>
    //     moment(`${row?.activity_date ? row?.activity_date : '-'}`).format(
    //       'DD/MM/YYYY'
    //     ),
    //   {
    //     id: 'Date of Activity',
    //     enableSorting: false,
    //   }
    // ),
    columnHelper.accessor(
      (row) => `${row?.csr_reclaimer && row?.csr_reclaimer?.name}`,
      {
        id: 'Reclaimer Name',
      }
    ),

    columnHelper.accessor(
      (row) => `${row?.csr_reclaimer && row?.csr_reclaimer?.reclaimer_id}`,
      {
        id: `${translate('Reclaimer ID')}`,
      }
    ),
    columnHelper.accessor(
      (row) =>
        `${
          (row?.csr_reclaimer && row?.csr_reclaimer?.gender == 'male') ||
          row?.csr_reclaimer?.gender == 'Male'
            ? 'M'
            : (row?.csr_reclaimer && row?.csr_reclaimer?.gender == 'female') ||
              row?.csr_reclaimer?.gender == 'Female'
            ? 'F'
            : '-'
        }`,
      {
        id: 'Gender',
      }
    ),
    columnHelper.accessor(
      (row: any) => `${row?.csr_reclaimer && row?.csr_reclaimer?.aadhaar_no}`,
      {
        id: 'Aadhaar Card No.',
      }
    ),
    columnHelper.accessor('Material Provided', {
      cell: (props: any) => {
        let id = props?.row?.original?.id;
        return (
          <React.Fragment>
            {props?.row?.original?.csr_material
              ?.reduce((final = '', obj: any) => {
                return final + obj?.name + ', ';
              }, '')
              .slice(0, -2) || '-'}
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor('-', {
      header: () => 'Activity Photo',
      enableSorting: false,
      cell: (props: any) => {
        let id = props?.row?.original?.id;
        return (
          <React.Fragment>
            {props?.row?.original?.activity_photo && (
              <div className='icon-action'>
                <img
                  style={{ cursor: 'pointer' }}
                  src={`${process.env.PUBLIC_URL}/assets/images/eye-icon-green.svg`}
                  alt='#'
                  onClick={() =>
                    window.open(props?.row?.original?.activity_photo)
                  }
                />
              </div>
            )}
          </React.Fragment>
        );
      },
    }),
  ];

  const getData = async (reset: boolean) => {
    setIsLoading(true);
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      // is_linked: false,
      entry_start_date: reset
        ? ''
        : entryDateRange[0] === null
        ? ''
        : getStartDateFilter(entryDateRange[0]),
      entry_end_date: reset
        ? ''
        : entryDateRange[1] === null
        ? ''
        : getEndDateFilter(entryDateRange[1]),
      activity_start_date: reset
        ? ''
        : activityDateRange[0] === null
        ? ''
        : getStartDateFilter(activityDateRange[0]),
      activity_end_date: reset
        ? ''
        : activityDateRange[1] === null
        ? ''
        : getEndDateFilter(activityDateRange[1]),
      search_text: reset ? '' : searchValue,
      gender: reset ? '' : gender,

      collection_center: reset ? '' : collectionCenterId,
      is_linked: false,
      material_id: materialId,

      // material_type_id: reset ? '' : materialTypeId,
      // material_name_type_id: reset ? '' : subCategoryId,
      // utility_id: reset ? '' : utilityId,
    };
    let path = apiUrl.getActivities;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setActivityData(res.data.data);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  const getCSRMaterials = async () => {
    let path = apiUrl.getCSRMaterials;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      setMaterialList(temArray);
    } else {
      toast.error(res.message);
    }
  };

  const getCollectionCenterDetails = async () => {
    let path = apiUrl.getCollectionCenter;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setCollectionCenterData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    getData(false);
    getCollectionCenterDetails();
    getCSRMaterials();
  }, [page, pageLimit]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: activityData,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const performSearch = (e: any) => {
    e.preventDefault();
    getData(false);
  };

  const handeleReset = () => {
    setPage(1);
    setEntryDateRange([null, null]);
    setActivityDateRange([null, null]);
    setCollectionCenterId('');
    setSearchValue('');
    setMaterialId('');
    setGender('');
    getData(true);
  };

  return (
    <div className=''>
      <div className='transaction-table listuser-table'>
        <div className='transaction-table management-screen'>
          <div className='tab-content'>
            <div className='listing-transaction'>
              <form className=''>
                <div className='top-filters'>
                  <div className='date-item-picker item-in'>
                    <div className='date-picker-main'>
                      <div className='react-datepicker-wrapper'>
                        <div className='react-datepicker__input-container '>
                          <DatePicker
                            selectsRange={true}
                            startDate={activityStartDate}
                            endDate={activityEndDate}
                            value={activityStartDate ? activityStartDate : ''}
                            className='form-control'
                            placeholderText='Select Activity Date'
                            dateFormat={commanLabel.dateFormat}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            onChange={(update: any) => {
                              setActivityDateRange(update);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='date-item-picker item-in'>
                    <div className='date-picker-main'>
                      <div className='react-datepicker-wrapper'>
                        <div className='react-datepicker__input-container '>
                          <DatePicker
                            selectsRange={true}
                            startDate={entryStartDate}
                            endDate={entryEndDate}
                            value={entryStartDate ? entryStartDate : ''}
                            className='form-control'
                            placeholderText='Select Entry Date'
                            dateFormat={commanLabel.dateFormat}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            onChange={(update: any) => {
                              setEntryDateRange(update);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='type-select item-in'>
                    <select
                      id=''
                      name='type_of_material'
                      className='select-layout'
                      value={collectionCenterId}
                      onChange={(e: any) =>
                        setCollectionCenterId(e.target.value)
                      }
                    >
                      <option value=''>Collection Center</option>
                      {collectionCenterData &&
                        collectionCenterData.length > 0 &&
                        collectionCenterData.map(
                          (collectionCenter: any, index: number) => {
                            return (
                              <option value={collectionCenter.id}>
                                {collectionCenter?.name}
                              </option>
                            );
                          }
                        )}
                    </select>
                  </div>
                  <div className='type-select item-in'>
                    <select
                      id=''
                      name='type_of_material'
                      className='select-layout'
                      value={materialId}
                      onChange={(e: any) => {
                        setMaterialId(e.target.value);
                      }}
                    >
                      <option value=''>Material provided</option>
                      {materialList &&
                        materialList.length > 0 &&
                        materialList.map((material: any, index: number) => {
                          return (
                            <option value={material.id}>
                              {material?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className='type-select item-in'>
                    <select
                      id=''
                      name='type_of_material'
                      className='select-layout'
                      value={gender}
                      onChange={(e: any) => setGender(e.target.value)}
                    >
                      <option value=''>Gender</option>
                      <option value='male'>Male</option>
                      <option value='female'>Female</option>
                    </select>
                  </div>
                  <div className='type-select item-in'>
                    <div className='item input-min-width'>
                      <div className='input-search-item form-group'>
                        <label className='searchicon'>
                          <i className='fa fa-search'></i>
                        </label>
                        <input
                          className='form-control'
                          id='searchicon'
                          placeholder='Search'
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <div className='info-icons text-end'>
                          <a
                            href='#'
                            data-bs-toggle='tooltip'
                            data-bs-placement='bottom'
                            title='Enter Aadhaar Card Number / Reclaimer Id / Reclaimer Name / Event Name / Mobile Number / Location'
                          >
                            <i
                              className='fa fa-info-circle'
                              aria-hidden='true'
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='type-select item-in'>
                        <select id='' name='region' className='select-layout'>
                          <option value=''>Region</option>
                          <option value='3'>Afghanistan</option>
                          <option value='6'>Albania</option>
                          <option value='62'>Algeria</option>
                          <option value='11'>American Samoa</option>
                          <option value='1'>Andorra</option>
                        </select>
                      </div> */}
                  <div className='search-button item-in'>
                    <Button
                      role='button'
                      onClick={performSearch}
                      title='Search'
                      className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                    >
                      <i className='fa fa-search' aria-hidden='true'></i>
                    </Button>
                    <Button
                      role='button'
                      onClick={handeleReset}
                      title='Reset'
                      type='reset'
                      className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                    >
                      <i className='fa fa-refresh' aria-hidden='true'></i>
                    </Button>
                  </div>
                </div>
              </form>
              <div className='csr-btn-area mt-4 mb-4 row me-0 ms-0 me-sm-0 ms-sm-0 ms-md-0 me-md-0 me-lg-3 ms-lg-3 me-xl-3 ms-xl-3'>
                <div className='col-md-4 d-flex jusitfy-content-center'>
                  {/* <div className='type-select item-in'>
                        <select id='' name='region' className='upload-select'>
                          <option value=''>Regular Upload</option>
                          <option value='3'>Afghanistan</option>
                          <option value='6'>Albania</option>
                        </select>
                      </div> */}
                  {/* <div className='type-select item-in ms-4'>
                        <select id='' name='region' className='gender-select'>
                          <option value='3'>Male</option>
                          <option value='62'>Female</option>
                        </select>
                      </div> */}
                </div>
              </div>
              <div className='listing-content'>
                <div className='table-list'>
                  <table>
                    {/* <thead>
                          <tr>
                            <th>
                              <div className='form-check ps-0'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='defaultCheck1'
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='defaultCheck1'
                                ></label>
                              </div>
                            </th>
                            <th>
                              <div className='cursor-pointer select-none'>
                                Activity Name
                              </div>
                            </th>
                            <th>
                              <div className='cursor-pointer select-none'>
                                Date of Activity
                              </div>
                            </th>
                            <th>
                              <div className='cursor-pointer select-none'>
                                Organiser
                              </div>
                            </th>
                            <th>
                              <div className='cursor-pointer select-none'>
                                Reclaimer Name
                              </div>
                            </th>
                            <th>
                              <div className=''>Reclaimer ID</div>
                            </th>
                            <th>
                              <div className=''>Material Provided</div>
                            </th>
                            <th>
                              <div className=''>Action</div>
                            </th>
                          </tr>
                        </thead> */}
                    <thead>
                      {table.getHeaderGroups().map((headerGroup: any) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header: any) => {
                            return (
                              <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                  <div
                                    {...{
                                      className: header.column.getCanSort()
                                        ? 'cursor-pointer select-none'
                                        : '',
                                      onClick:
                                        header.column.getToggleSortingHandler(),
                                    }}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: ' 🔼',
                                      desc: ' 🔽',
                                    }[header.column.getIsSorted() as string] ??
                                      null}
                                  </div>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    {/* <tbody>
                          <tr>
                            <td>
                              <div className='form-check ps-0'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='defaultCheck2'
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='defaultCheck2'
                                ></label>
                              </div>
                            </td>
                            <td>Waste Drive 1</td>
                            <td>03/3/2023 07:00 PM</td>
                            <td>HEF Activity Group</td>
                            <td>Keshav Patel</td>
                            <td>ID12345</td>
                            <td>Groceries</td>
                            <td>
                              <div className='icon-action'>
                                <div className='icon-view icon'>
                                  <a title='View/Update' href='#'>
                                    <img
                                      src='/assets/images/file-edit.svg'
                                      alt='#'
                                    />
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check ps-0'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='defaultCheck2'
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='defaultCheck2'
                                ></label>
                              </div>
                            </td>
                            <td>Waste Drive 1</td>
                            <td>03/3/2023 07:00 PM</td>
                            <td>HEF Activity Group</td>
                            <td>Keshav Patel</td>
                            <td>ID12345</td>
                            <td>Groceries</td>
                            <td>
                              <div className='icon-action'>
                                <div className='icon-view icon'>
                                  <a title='View/Update' href='#'>
                                    <img
                                      src='/assets/images/file-edit.svg'
                                      alt='#'
                                    />
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='form-check ps-0'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='defaultCheck2'
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='defaultCheck2'
                                ></label>
                              </div>
                            </td>
                            <td>Waste Drive 1</td>
                            <td>03/3/2023 07:00 PM</td>
                            <td>HEF Activity Group</td>
                            <td>Keshav Patel</td>
                            <td>ID12345</td>
                            <td>Groceries</td>
                            <td>
                              <div className='icon-action'>
                                <div className='icon-view icon'>
                                  <a title='View/Update' href='#'>
                                    <img
                                      src='/assets/images/file-edit.svg'
                                      alt='#'
                                    />
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody> */}
                    {activityData?.length > 0 ? (
                      <tbody>
                        {table.getRowModel().rows.map((row: any) => {
                          return (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell: any) => {
                                return (
                                  <td key={cell.id}>
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <>
                        <tbody>
                          <tr>
                            <td colSpan={9}>
                              {isLoading && (
                                <h3 className='center-message-item'>
                                  Loading...
                                </h3>
                              )}
                              {activityData?.length === 0 && !isLoading && (
                                <h3 className='center-message-item'>
                                  {validationMessage.noRecordFound}
                                </h3>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
                {/* <div className='items-center pagination-main gap-2 text-center'>
                      <button className='border rounded p-1'>&lt;</button>
                      <ul className='pagination pagination-sm'>
                        <li className='page-item active'>
                          <span className='page-link'>1</span>
                        </li>
                        <li className='page-item'>
                          <a className='page-link' role='button' href='#'>
                            2
                          </a>
                        </li>
                        <li className='page-item'>
                          <a className='page-link' role='button' href='#'>
                            3
                          </a>
                        </li>
                      </ul>
                      <button className='border rounded p-1'>&gt;</button>
                    </div> */}

                {activityData?.length > 0 && pageCount > 1 && (
                  <div className='items-center pagination-main gap-2 text-center'>
                    <PaginationControl
                      page={page}
                      between={4}
                      total={totalCount}
                      limit={pageLimit}
                      changePage={(page) => {
                        setPage(page);
                      }}
                      ellipsis={2}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachCSR;
