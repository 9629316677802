import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ActivityList from './activityList';
import ReclaimerList from './reclaimerList';
import EventList from './eventList';
import Dropdown from 'react-bootstrap/Dropdown';
import AddEntryPopup from './addEntryPopup';
import {
  socialWelfareTab,
  isValidExcelFile,
} from '../../../shared/remake-green-utils';
import { helper } from '../../../app/api/api.helper';
import { fileType } from '../../../constants/comman.constant';
import { apiUrl } from '../../../app/api/services/apiUrl';
import UploadPopup from './uploadpopup';

const SocialWelfare = () => {
  const { state } = useLocation();
  const { t: translate } = useTranslation();
  const [addentryPopup, setEntryPopup] = useState(false);
  const [selectedTeb, setSelectedTeb] = useState(
    state ? state : socialWelfareTab.ACTIVITIES
  );
  const [uploadPopup, setUploadPopup] = useState(false);

  const requestTypes = [
    {
      name: 'CSR_MODULE.TITLES.ACTIVITIES',
      value: socialWelfareTab.ACTIVITIES,
    },
    {
      name: 'CSR_MODULE.TITLES.RECLAIMER_LIST',
      value: socialWelfareTab.RECLAIMER,
    },
    { name: 'CSR_MODULE.TITLES.EVENT_LIST', value: socialWelfareTab.EVENT },
  ];

  const handleSelectedFile = (e: any) => {
    let name = e.target.name;
    let file = e.target.files[0];
    const maxFileSize = 5000000;
    if (file.size >= maxFileSize) {
      toast.error(translate('ERRORS.INVALID.DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidExcelFile(file.type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    validateExcelFile(file, name, fileType.ExcelImport, e);
  };

  const validateExcelFile = async (
    file: any,
    name: string,
    fileType: string,
    e: any
  ) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', fileType);
    // formData.append('bulk_upload_id', !isLetMeRetry ? '' : bulkUploadId);
    let path = apiUrl.bulkActivityUpload;
    const fr = await helper.formPost(formData, path);
    const res = await fr.response;
    if (res.success) {
      e.target.value = null;
      if (res.data?.errorCount != 0) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href =
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
          res.data?.errorFile;
        a.download = file.name;
        a.click();
        if (res?.data?.successCount) {
          toast.success(
            `${res?.data?.successCount} Activity Uploaded successfully`
          );
        }
        toast.error(`${res?.data?.errorCount} activity not uploaded`);
      } else {
        toast.success(
          `${res?.data?.successCount} Activity Uploaded successfully`
        );
      }
    } else {
      toast.error(res.message);
    }
  };

  const onDownloadActivitySample = async () => {
    let path = apiUrl.donloadBulkActivityUploadSample;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
        res.data?.file;
      a.download = res?.data?.name;
      a.click();
    } else {
      toast.error(res.message);
    }
  };

  return (
    <>
      {addentryPopup && (
        <AddEntryPopup
          title={`${translate('CSR_MODULE.TITLES.ADD_ENTRY')}`}
          handleCancel={() => setEntryPopup(false)}
          cancelText='Cancel'
          submitText='OK'
          hideCancelButton={true}
          handleSubmit={() => {
            setEntryPopup(false);
          }}
        />
      )}
      {uploadPopup && (
        <UploadPopup
          title={`${translate('CSR_MODULE.TITLES.UPLOAD')}`}
          handleCancel={() => setUploadPopup(false)}
          handleSelectedFile={handleSelectedFile}
          handleSubmit={() => {
            setUploadPopup(false);
          }}
          onDownloadSample={() => onDownloadActivitySample()}
        />
      )}
      <div className='body-content-main pc-request transaction-registry csr-main'>
        <div className='container'>
          <div className='section-title-main'>
            <div className='title'>
              {translate('HOMEPAGE.TITLES.RECLAIMER_WELFARE')}
            </div>
            <div className='form-o-submit'>
              <a
                className='upload-btn me-4 attach-document'
                title='Bulk Upload Button'
                onClick={() => setUploadPopup(true)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='22'
                  height='22'
                  viewBox='0 0 24 24'
                >
                  <g
                    id='collection-upload-svgrepo-com'
                    transform='translate(-2 -3)'
                  >
                    <path
                      id='Path_8273'
                      data-name='Path 8273'
                      d='M7.5,16.485V11.759H9.395V14.59H19.5V11.759h1.895v4.726Z'
                      transform='translate(1.447 2.305)'
                      fill='#18a675'
                    ></path>
                    <path
                      id='Path_8274'
                      data-name='Path 8274'
                      d='M11.27,11.2l1.591-1.591v3.974h1.895V9.609L16.346,11.2l1.34-1.34L13.808,5.982,9.93,9.861Z'
                      transform='translate(2.087 0.785)'
                      fill='#18a675'
                    ></path>
                    <path
                      id='Path_8275'
                      data-name='Path 8275'
                      d='M5,3H25.211V23.211H5ZM6.895,4.895H23.316V21.316H6.895Z'
                      transform='translate(0.789 0)'
                      fill='#18a675'
                      fill-rule='evenodd'
                    ></path>
                    <path
                      id='Path_8276'
                      data-name='Path 8276'
                      d='M2,6V26.211H22.211V24.316H3.895V6Z'
                      transform='translate(0 0.789)'
                      fill='#18a675'
                    ></path>
                  </g>
                </svg>
              </a>
              <a
                className='add-entry'
                onClick={() => {
                  setEntryPopup(true);
                }}
              >
                {/* href='/admin/social-welfare/add-activity' */}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='19.991'
                  height='20'
                  viewBox='0 0 19.991 20'
                >
                  <g
                    id='plus_icon-svgrepo-com'
                    data-name='plus icon-svgrepo-com'
                    transform='translate(-0.078)'
                  >
                    <path
                      id='Path_5869'
                      data-name='Path 5869'
                      d='M17.145,2.929A10,10,0,0,0,3,17.071,10,10,0,0,0,17.145,2.929ZM15.859,15.785A8.182,8.182,0,0,1,4.288,4.215,8.182,8.182,0,0,1,15.859,15.785Z'
                      transform='translate(0 0)'
                    />
                    <path
                      id='Path_5870'
                      data-name='Path 5870'
                      d='M71.818,65.455H67.273V60.909a.909.909,0,1,0-1.818,0v4.545H60.909a.909.909,0,1,0,0,1.818h4.545v4.545a.909.909,0,0,0,1.818,0V67.273h4.545a.909.909,0,1,0,0-1.818Z'
                      transform='translate(-56.29 -56.364)'
                    />
                  </g>
                </svg>{' '}
                {translate('CSR_MODULE.TITLES.ADD_ENTRY')}
              </a>
            </div>
          </div>
          <div className='transaction-table listuser-table'>
            <div className='panel-head-title'>
              <div className='left-content'>
                <div className='title-normal'>
                  {translate('HOMEPAGE.TITLES.RECLAIMER_WELFARE')}
                </div>
              </div>
              <div className='right-content'></div>
            </div>
            <div className='transaction-table management-screen'>
              <ul className='nav nav-tabs' id='' role='tablist'>
                {requestTypes.map((data, i) => {
                  return (
                    <li className='nav-item'>
                      <a
                        className={
                          selectedTeb === data.value
                            ? 'nav-link active'
                            : 'nav-link '
                        }
                        id='pc-request1-tab'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='pc-request1'
                        aria-selected='true'
                        onClick={() => {
                          setSelectedTeb(data.value);
                        }}
                      >
                        {translate(data.name)}
                      </a>
                    </li>
                  );
                })}
                {/* <li className='nav-item'>
                <a
                  className='nav-link active'
                  id='pc-request1-tab'
                  data-toggle='tab'
                  role='tab'
                  aria-controls='pc-request1'
                  aria-selected='true'
                  href='javascript:void(0)'
                >
                  Activities
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  id='pc-request1-tab'
                  data-toggle='tab'
                  role='tab'
                  aria-controls='pc-request1'
                  aria-selected='true'
                  href='javascript:void(0)'
                >
                  Reclaimer List
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  id='pc-request1-tab'
                  data-toggle='tab'
                  role='tab'
                  aria-controls='pc-request1'
                  aria-selected='true'
                  href='javascript:void(0)'
                >
                  Event List
                </a>
              </li> */}
              </ul>
              <div className='tab-content'>
                {selectedTeb === 1 && <ActivityList />}
                {selectedTeb === 2 && <ReclaimerList />}
                {selectedTeb === 3 && <EventList />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialWelfare;
