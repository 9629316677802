import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { useGetTransactionforUploadMutation, useUpdateTransactionMutation } from '../../../app/api/services/createTransaction.service';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';

import {
  commanLabel,
  fileType,
  transactionRegistryFileUploadSections,
} from '../../../constants/comman.constant';
import {
  isValidFile,
  onBehalfRole,
} from '../../../shared/remake-green-utils/common';
import {
  additionalDocumentInfo,
  basicDetails,
  buyerAndSellerDetails,
  debitNoteForBuyer,
  errorJson,
  ewayOrLorryReceipt,
  goodRecivedNote,
  goodTransferDetails,
  outputMaterialDetails,
  loadingWeightSlipDetails,
  NexStageTransactionInfo,
  otherData,
  PreviousStageTransactionInfo,
  recyclingCertificateInfo,
  unloadingweightSlipDetails,
} from './transactionRegisterProps';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { isEmptyDeep } from '../../../utils/helper/common';

const TransactionDocUpload = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [getTransaction] = useGetTransactionforUploadMutation();
  const { t: translate } = useTranslation();

  const [basic, setBasic] = useState(basicDetails);
  const [buyerSellerDetails, setBuyerSellerDetails] = useState(
    buyerAndSellerDetails
  );
  const [loadingSlipDetails, setLoadingSlipDetails] = useState(
    loadingWeightSlipDetails
  );
  const [unLoadingSlipDetails, setUnLoadingSlipDetails] = useState(
    unloadingweightSlipDetails
  );

  const [ewayLorryReceipt, setEwayLorryReceipt] = useState(ewayOrLorryReceipt);
  const [debitNote, setDebitNotet] = useState(debitNoteForBuyer);
  const [goodNote, setGoodNote] = useState(goodRecivedNote);
  const [goodDetails, setGoodDetails] = useState(goodTransferDetails);
  const [outputDetails, setOutputDetails] = useState(outputMaterialDetails);
  const [additionalDocuments, setAdditionalDocuments] = useState([
    additionalDocumentInfo,
  ]);
  const [recyclingCertificate, setRecyclingCertificate] = useState(
    recyclingCertificateInfo
  );
  const [buyerNameInfo, setBuyerNameInfo] = useState({});
  const [sellerNameInfo, setSellerNameInfo] = useState({});
  const [onBehalfNameInfo, setOnBehalfNameInfo] = useState({});
  const [materialNameInfo, setMaterialNameInfo] = useState({});
  const [updateTransaction] = useUpdateTransactionMutation();
  const [show, setShow] = useState(false);
  const [editId, setEditId] = useState(null);
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [otherDetails, setOtherDetails] = useState(otherData);
  const [nextDetails, setNextDetails] = useState(NexStageTransactionInfo);
  const [previousDetails, setPreviousDetails] = useState(
    PreviousStageTransactionInfo
  );
  const [associatedProjectNameInfo, setAssociatedProjectNameInfo] = useState(
    {}
  );
  const [isInwardTransfer, setIsInwardTransfer] = useState(false);
  useEffect(() => {
    getData(false);
  }, []);
  useEffect(() => {
    getData(false);
  }, [page, pageLimit]);
  const getData = async (reset: boolean) => {
    const parms: any = {
      page: reset ? 1 : page,
      limit: pageLimit
    };

    const res = await getTransaction(parms).unwrap();
    if (res.success) {
      setTransactionData(res.data.allTransaction);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
    } else {

    }

  };


  const handleSelectedFile = (e: any, index: any = null, fileName?: string) => {
    let name = e.target.name;
    let file = e.target.files[0];
    const maxFileSize = 5000000;
    if (file.size >= maxFileSize) {
      toast.error(translate('ERRORS.INVALID.DOCUMENT_VALIDATION'));
      return;
    }

    switch (name) {
      case transactionRegistryFileUploadSections.invoice:
        handleFileUploder(file, name, fileType.Invoice);
        break;
      case transactionRegistryFileUploadSections.loadWeightSlip:
        handleFileUploder(file, name, fileType.LoadingSlip);
        break;
      case transactionRegistryFileUploadSections.unLoadWeightSlip:
        handleFileUploder(file, name, fileType.UnLoadingSlip);
        break;
      case transactionRegistryFileUploadSections.ewayLorryReceipt:
        handleFileUploder(file, name, fileType.EWayReceipt);
        break;
      case transactionRegistryFileUploadSections.debitNote:
        handleFileUploder(file, name, fileType.Others);
        break;
      case transactionRegistryFileUploadSections.GRN:
        handleFileUploder(file, name, fileType.Others);
        break;
      case transactionRegistryFileUploadSections.additionalDocuments:
        handleFileUploder(file, name, fileType.Others, index, fileName);
        break;
      case transactionRegistryFileUploadSections.recyclingCertificate:
        handleFileUploder(file, name, fileType.RecyclingCertificate);
        break;
      default:
    }
  };
  const handleFileUploder = async (
    file: any,
    name: string,
    fileType: string,
    index: any = null,
    fileName?: string
  ) => {
    let formData = new FormData();
    if (fileName) {
      formData.append('file', file, fileName);
    } else {
      formData.append('file', file);
    }
    formData.append('file_type', fileType);
    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res = await fr.response;
    if (res.success) {
      toast.success(res.message);
      getFileUploaderData(res?.data?.data, name, index);
    } else {
      toast.error(res.message);
    }
  };

  const getFileUploaderData = (data: any, name: string, index: any) => {
    switch (name) {
      case transactionRegistryFileUploadSections.invoice:
        setBuyerSellerDetails({
          ...buyerSellerDetails,
          invoice: data?.document_path,
          invoice_id: data?.id,
          invoice_file_name: data?.document_name,
        });
       
        break;
      case transactionRegistryFileUploadSections.loadWeightSlip:
        setLoadingSlipDetails({
          ...loadingSlipDetails,
          loading_weight_slip: data?.document_path,
          loading_weight_slip_id: data?.id,
          loading_weight_slip_file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.unLoadWeightSlip:
        setUnLoadingSlipDetails({
          ...unLoadingSlipDetails,
          unloading_weight_slip: data?.document_path,
          unloading_weight_slip_id: data?.id,
          unloading_weight_slip_file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.ewayLorryReceipt:
        setEwayLorryReceipt({
          ...ewayLorryReceipt,
          eway_or_lorry_reciept: data?.document_path,
          eway_or_lorry_reciept_id: data?.id,
          eway_or_lorry_reciept_file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.debitNote:
        setDebitNotet({
          ...debitNote,
          debit_note: data?.document_path,
          debit_note_id: data?.id,
          debit_note_file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.GRN:
        setGoodNote({
          ...goodNote,
          GRN: data?.document_path,
          GRN_ID: data?.id,
          GRN__file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.additionalDocuments:
        const splittedArray = data?.document_name.split('.');
        let document_details = {
          ...additionalDocuments[index],
          document: data?.document_path,
          document_id: data?.id,
          document_file_name: data?.document_name,
          label:splittedArray[0]
        };
        additionalDocuments[index] = document_details;
        setAdditionalDocuments([...additionalDocuments]);
       
        break;
      case transactionRegistryFileUploadSections.recyclingCertificate:
        setRecyclingCertificate({
          ...recyclingCertificate,
          recycling_certificate_document: data?.document_path,
          recycling_certificate_document_id: data?.id,
          recycling_certificate_document_file_name: data?.document_name,
        });
        break;
      default:
    }
  };
  const getUpdateData = async (trasactionData: any) => {
    setShow(true);
    setEditId(trasactionData.id)
    if (trasactionData) {
      let transactionUpdateData = trasactionData;

      let onBehalOption: any = {
        value: transactionUpdateData?.onbehalf?.id,
        label: transactionUpdateData?.onbehalf?.name,
      };

      let onBuyerOption: any = {
        value: transactionUpdateData?.buyer?.id,
        label: transactionUpdateData?.buyer?.name,
      };
      let onSellerOption: any = {
        value: transactionUpdateData?.seller?.id,
        label: transactionUpdateData?.seller?.name,
      };
      let onMaterialOption: any = {
        value: transactionUpdateData?.material_name_type_data?.id,
        label: transactionUpdateData?.material_name_type_data?.name,
      };
      const buyerSellerDate =
        transactionUpdateData?.details?.buyer_and_seller_details?.date != null
          ? new Date(
            transactionUpdateData?.details?.buyer_and_seller_details?.date
          )
          : null;
      const loadingDate =
        transactionUpdateData?.details?.loading_weight_slip_details?.date !=
          null
          ? new Date(
            transactionUpdateData?.details?.loading_weight_slip_details?.date
          )
          : null;
      const unLoadingDate =
        transactionUpdateData?.details?.unloading_weight_slip_details?.date !=
          null
          ? new Date(
            transactionUpdateData?.details?.unloading_weight_slip_details?.date
          )
          : null;
      const ewayDate =
        transactionUpdateData?.details?.eway_or_lorry_receipt?.date != null
          ? new Date(
            transactionUpdateData?.details?.eway_or_lorry_receipt?.date
          )
          : null;
          const nextDate =
          transactionUpdateData?.details?.next_stage_transaction_info
            ?.seller_invoice_date != null
            ? new Date(
                transactionUpdateData?.details?.next_stage_transaction_info?.seller_invoice_date
              )
            : null;
        const prevDate =
          transactionUpdateData?.details?.previous_stage_transaction_info
            ?.seller_invoice_date != null
            ? new Date(
                transactionUpdateData?.details?.previous_stage_transaction_info?.seller_invoice_date
              )
            : null;
            let onAssociatedProjectOption: any =
        transactionUpdateData.associated_project
          ? {
              value: transactionUpdateData?.associated_project?.id,
              label: transactionUpdateData?.associated_project?.name,
            }
          : null;
      setBasic(transactionUpdateData?.details?.basic_details);
      setBuyerSellerDetails({
        ...transactionUpdateData?.details?.buyer_and_seller_details,
        date: buyerSellerDate,
      });
      setLoadingSlipDetails({
        ...transactionUpdateData?.details?.loading_weight_slip_details,
        date: loadingDate,
      });
      setUnLoadingSlipDetails({
        ...transactionUpdateData?.details?.unloading_weight_slip_details,
        date: unLoadingDate,
      });
      setEwayLorryReceipt({
        ...transactionUpdateData?.details?.eway_or_lorry_receipt,
        date: ewayDate,
      });
      setDebitNotet(transactionUpdateData?.details?.debit_note_for_buyer);
      setGoodNote(transactionUpdateData?.details?.good_recived_note);
      setGoodDetails(transactionUpdateData?.details?.good_transfer_details);
      setOutputDetails(transactionUpdateData?.details?.output_material);
      setOtherDetails(transactionUpdateData?.details?.other_details);
      setNextDetails({
        ...transactionUpdateData?.details?.next_stage_transaction_info,
        seller_invoice_date: nextDate,
      });
      setPreviousDetails({
        ...transactionUpdateData?.details?.previous_stage_transaction_info,
        seller_invoice_date: prevDate,
      });
      setIsInwardTransfer(transactionUpdateData?.is_inward_transfer);
      setAdditionalDocuments(
        transactionUpdateData?.details?.additional_documents &&
          transactionUpdateData?.details?.additional_documents.length > 0
          ? transactionUpdateData?.details?.additional_documents
          : [additionalDocumentInfo]
      );
      setRecyclingCertificate(
        transactionUpdateData?.details?.recycling_certificate
      );
      handleOnBehalfName(onBehalOption);
      handleBuyerName(onBuyerOption);
      handleSellerName(onSellerOption);
      handlMaterialName(onMaterialOption);
      handleAssociatedProjectName(onAssociatedProjectOption);
    }
  };
  const handleAssociatedProjectName = (option: any) => {
    if (option !== null) {
      let sellerData = {
        id: option.value === option.label ? '0' : option.value,
        name: option.label,
        added_by: 1,
      };
      setAssociatedProjectNameInfo(sellerData);   
      } else {
      setAssociatedProjectNameInfo({});
    }
  };
  const handleBuyerName = (option: any) => {
    if (option !== null) {
      let buyerData = {
        id: option.value === option.label ? '0' : option.value,
        name: option.label,
        added_by: 1,
      };
      setBuyerNameInfo(buyerData);
    } else {
      setBuyerNameInfo({});

    }
  };
  const handleSellerName = (option: any) => {
    if (option !== null) {
      let sellerData = {
        id: option.value === option.label ? '0' : option.value,
        name: option.label,
        added_by: 1,
      };
      setSellerNameInfo(sellerData);
    } else {
      setSellerNameInfo({});

    }
  };

  const handleOnBehalfName = (option: any) => {
    if (option !== null) {
      let onBehalfData = {
        id: option.value === option.label ? '0' : option.value,
        name: option.label,
        added_by: 1,
      };
      setOnBehalfNameInfo(onBehalfData);
      if (basic.role === onBehalfRole.SELLER) {
        setSellerNameInfo(onBehalfData);
      } else {
        setBuyerNameInfo(onBehalfData);
      }
    } else {
      setOnBehalfNameInfo({});

    }
  };
  const handlMaterialName = (option: any) => {
    if (option !== null) {
      let materialData = {
        id: option.value === option.label ? '0' : option.value,
        name: option.label,
        added_by: 1,
        category_id: basic.material_type,
      };
      setMaterialNameInfo(materialData);


    } else {
      setMaterialNameInfo({});

    }
  };
  const onSubmit = async (id: any) => {
    let updatedAdditionalDocument = [];
    for (let i = 0; i < additionalDocuments.length; i++) {
      let checkIfEmpty = isEmptyDeep(additionalDocuments[i]);
      if (!checkIfEmpty) {
        updatedAdditionalDocument.push({ ...additionalDocuments[i], document_file_name: additionalDocuments[i].document_file_name});
      }
    }
    let postJson = {
      details: {
        basic_details: basic,
        buyer_and_seller_details: buyerSellerDetails,
        loading_weight_slip_details: loadingSlipDetails,
        unloading_weight_slip_details: unLoadingSlipDetails,
        eway_or_lorry_receipt: ewayLorryReceipt,
        debit_note_for_buyer: debitNote,
        good_recived_note: goodNote,
        other_details: otherDetails,
        next_stage_transaction_info: nextDetails,
        previous_stage_transaction_info: previousDetails,
        additional_documents: updatedAdditionalDocument,
        recycling_certificate: recyclingCertificate,
        good_transfer_details: goodDetails,
        output_material: outputDetails,
      },
      material_name_type_info: materialNameInfo,
      onbehalfof_info: onBehalfNameInfo,
      buyer_info: buyerNameInfo,
      seller_info: sellerNameInfo,
    //  accosiated_project_info: associatedProjectNameInfo,
      user_id: 1,
      action_by: 1,
      utility_id: Number(basic.utility),
      material_type_id: Number(basic.material_type),
      is_inward_transfer: isInwardTransfer,
      is_document_uploaded:true
    };
    let updateJson = {
      ...postJson,
      material_name_type_info: { ...materialNameInfo, is_active: true },
      onbehalfof_info: { ...onBehalfNameInfo, is_active: true },
      buyer_info: { ...buyerNameInfo, is_active: true },
      seller_info: { ...sellerNameInfo, is_active: true },
      // accosiated_project_info: {
      //   ...associatedProjectNameInfo,
      //   is_active: true,
      // },
      id: Number(id),
    };
    if (id) {
      const response = await updateTransaction(updateJson).unwrap();
      if (response.success) {
        toast.success(response.message);
        getData(false);
      } else {
        toast.error(response.message);
      }
    }
  };
  
  const DocumentUpload = () => {
    return (
      <>
        <div className="row mb-2 mt-2" >
          <div className='col-md-4  col-sm-6 mb-2 mt-2'>
            <div className='upload-file-main'>
              <i className='fa fa-upload' aria-hidden='true' />
              <div className='attach-document'>
                <div className='file-drop-area text-center'>
                  <span className='file-msg' />
                  {buyerSellerDetails.invoice_file_name && (
                    <p className='file-name'>
                      {buyerSellerDetails.invoice_file_name}
                    </p>
                  )}
                  {!buyerSellerDetails.invoice_file_name && (
                    <p className='file-name'>
                      Upload Invoice
                    </p>
                  )}

                  <input
                    className='file-input'
                    type='file'
                    onChange={handleSelectedFile}
                    name='invoice'
                    id='invoice'
                    accept='.jpg, .jpeg, .png, .pdf'
                  // disabled={id ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4  col-sm-6 mb-2 mt-2'>
            <div className='upload-file-main'>
              <i className='fa fa-upload' aria-hidden='true' />
              <div className='attach-document'>
                <div className='file-drop-area text-center'>
                  <span className='file-msg' />
                  {loadingSlipDetails.loading_weight_slip_file_name && (
                    <p className='file-name'>
                      {
                        loadingSlipDetails.loading_weight_slip_file_name
                      }
                    </p>
                  )}
                  {!loadingSlipDetails.loading_weight_slip_file_name && (
                    <p className='file-name'>
                      {
                        'Upload Loading Slip'
                      }
                    </p>
                  )}
                  <input
                    className='file-input'
                    type='file'
                    onChange={handleSelectedFile}
                    name='loading_weight_slip'
                    id='loading_weight_slip'
                    // disabled={id ? true : false}
                    accept='.jpg, .jpeg, .png, .pdf'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4  col-sm-6 mb-2 mt-2'>
            <div className='upload-file-main'>
              <i className='fa fa-upload' aria-hidden='true' />
              <div className='attach-document'>
                <div className='file-drop-area text-center'>
                  <span className='file-msg' />
                  {unLoadingSlipDetails.unloading_weight_slip_file_name && (
                    <p className='file-name'>
                      {
                        unLoadingSlipDetails.unloading_weight_slip_file_name
                      }
                    </p>
                  )}
                  {!unLoadingSlipDetails.unloading_weight_slip_file_name && (
                    <p className='file-name'>
                      {
                        'Upload UnLoading Slip'
                      }
                    </p>
                  )}
                  <input
                    className='file-input'
                    type='file'
                    onChange={handleSelectedFile}
                    name='unloading_weight_slip'
                    id='unloading_weight_slip'
                    // disabled={id ? true : false}
                    accept='.jpg, .jpeg, .png, .pdf'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4  col-sm-6 mb-2 mt-2'>
            <div className='upload-file-main'>
              <i className='fa fa-upload' aria-hidden='true' />
              <div className='attach-document'>
                <div className='file-drop-area text-center'>
                  <span className='file-msg' />
                  {ewayLorryReceipt.eway_or_lorry_reciept_file_name && (
                    <p className='file-name'>
                      {
                        ewayLorryReceipt.eway_or_lorry_reciept_file_name
                      }
                    </p>
                  )}
                  {!ewayLorryReceipt.eway_or_lorry_reciept_file_name && (
                    <p className='file-name'>
                      {
                        'Upload EwayLorry Receipt'
                      }
                    </p>
                  )}
                  <input
                    className='file-input'
                    type='file'
                    onChange={handleSelectedFile}
                    name='eway_or_lorry_reciept'
                    id='eway_or_lorry_reciept'
                    // disabled={id ? true : false}
                    accept='.jpg, .jpeg, .png, .pdf'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4  col-sm-6 mb-2 mt-2'>
            <div className='upload-file-main'>
              <i className='fa fa-upload' aria-hidden='true' />
              <div className='attach-document'>
                <div className='file-drop-area text-center'>
                  <span className='file-msg' />
                  {debitNote.debit_note_file_name && (
                    <p className='file-name'>
                      {debitNote.debit_note_file_name}
                    </p>
                  )}
                  {!debitNote.debit_note_file_name && (
                    <p className='file-name'>
                      Upload Debit Note
                    </p>
                  )}
                  <input
                    className='file-input'
                    type='file'
                    onChange={handleSelectedFile}
                    name='debit_note'
                    id='debit_note'
                    // disabled={id ? true : false}
                    accept='.jpg, .jpeg, .png, .pdf'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4  col-sm-6 mb-2 mt-2'>
            <div className='upload-file-main'>
              <i className='fa fa-upload' aria-hidden='true' />
              <div className='attach-document'>
                <div className='file-drop-area text-center'>
                  <span className='file-msg' />
                  {goodNote.GRN__file_name && (
                    <p className='file-name'>
                      {goodNote.GRN__file_name}
                    </p>
                  )}
                  {!goodNote.GRN__file_name && (
                    <p className='file-name'>
                      Upload Good Note
                    </p>
                  )}
                  <input
                    className='file-input'
                    type='file'
                    onChange={handleSelectedFile}
                    name='GRN'
                    id='GRN'
                    // disabled={id ? true : false}
                    accept='.jpg, .jpeg, .png, .pdf'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4  col-sm-6 mb-2 mt-2'>
            <div className='upload-file-main'>
              <i className='fa fa-upload' area-hidden='true' />
              <div className='attach-document'>
                <div className='file-drop-area text-center'>
                  <span className='file-msg' />
                  {recyclingCertificate?.recycling_certificate_document_file_name && (
                    <p className='file-name'>
                      {
                        recyclingCertificate.recycling_certificate_document_file_name
                      }
                    </p>
                  )}

                  {!recyclingCertificate?.recycling_certificate_document_file_name && (
                    <p className='file-name'>
                      {
                        'Upload recycling Certificate'
                      }
                    </p>
                  )}
                  <input
                    className='file-input'
                    type='file'
                    onChange={handleSelectedFile}
                    name='recycling_certificate'
                    id='recycling_certificate'
                    // disabled={id ? true : false}
                    accept='.jpg, .jpeg, .png, .pdf'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4  col-sm-6 mb-2 mt-2'>
            <div className='upload-file-main'>
              <i className='fa fa-upload' area-hidden='true' />
              <div className='attach-document'>
                <div className='file-drop-area text-center'>
                  <span className='file-msg' />
                  {additionalDocuments[0]?.document_file_name && (
                    <p className='file-name'>
                      {
                        additionalDocuments[0].document_file_name
                      }
                    </p>
                  )}

                  {!additionalDocuments[0]?.document_file_name && (
                    <p className='file-name'>
                      {
                        'Upload Other'
                      }
                    </p>
                  )}
                  <input
                    className='file-input'
                    type='file'
                    onChange={(e: any) => {
                      const name =
                        e?.target?.files?.[0]?.name;
                      
                      handleSelectedFile(
                        e,
                        0,
                        name
                      );
                    }}
                    name='additional_documents'
                    id='additional_documents'
                    // disabled={id ? true : false}
                    accept='.jpg, .jpeg, .png, .pdf'
                  />
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </>
    )
  }

  return (
    <React.Fragment>
      {/* Add class "linked-transaction" for show drop down  */}
      <div className='container body-content-main transaction-registry linked-transaction '>
        <div className='section-title-main'>
          <div className='title'>Transaction Document Upload</div>

        </div>
        <div className='form-o-submit'>
          <div className="row mb-5 mt-5" style={{ textAlign: 'left', borderBottom: '1px solid #7C7E8C' }}>
            <div className='col-md-2  col-sm-6 mb-2 mt-2'>
              <span> Transaction Id</span>
            </div>
            <div className='col-md-2  col-sm-6 mb-2 mt-2'>
              <span> Buyer</span>
            </div>
            <div className='col-md-2  col-sm-6 mb-2 mt-2'>
              <span>Seller</span>
            </div>
            <div className='col-md-1  col-sm-6 mb-2 mt-2'>
              <span>Type</span>
            </div>
            <div className='col-md-1  col-sm-6 mb-2 mt-2'>
              <span>Sub Type</span>
            </div>
            <div className='col-md-1  col-sm-6 mb-2 mt-2'>
              <span> Quantity</span>
            </div>
            <div className='col-md-1  col-sm-6 mb-2 mt-2'>
              <span>
                Date</span>
            </div>
            <div className='col-md-2  col-sm-6 mb-2 mt-2' style={{ display: 'flex' }}>
              Action
            </div>
          </div>


          {transactionData?.length > 0 &&
            transactionData?.map((item: any, index: number) => (
              <>
                <div className="row mb-2 mt-2" style={{ textAlign: 'left' }}>
                  <div className='col-md-2  col-sm-6'>
                    <span> {item.transaction_id}</span>
                  </div>
                  <div className='col-md-2  col-sm-6'>
                    <span> {item.buyer.name}</span>
                  </div>
                  <div className='col-md-2  col-sm-6'>
                    <span> {item.seller.name}</span>
                  </div>
                  <div className='col-md-1  col-sm-6'>
                    <span> {item.material_type_id_data.name}</span>
                  </div>
                  <div className='col-md-1  col-sm-6'>
                    <span> {item.material_name_type_data.name}</span>
                  </div>
                  <div className='col-md-1  col-sm-6'>
                    <span> {item.details.unloading_weight_slip_details.quantity} {item.details.unloading_weight_slip_details.measurement_types}</span>
                  </div>
                  <div className='col-md-1  col-sm-6'>
                    <span>
                      {moment(item.created_on).format('DD/MM/YYYY')}</span>
                  </div>
                  <div className='col-md-2  col-sm-6' style={{ display: 'flex' }}>
                    <button className='btn btn-sm save-entry ms-1 ms-sm-3 ms-md-3 ms-lg-3 ms-xl-3' onClick={() => getUpdateData(item)}>Edit </button>
                    <button className='btn btn-sm save-entry ms-1 ms-sm-3 ms-md-3 ms-lg-3 ms-xl-3' onClick={() => onSubmit(item.id)}> Save</button>
                  </div>

                  {show && editId === item.id &&
                    <DocumentUpload />

                  }

                </div>
              </>
            ))}
          {transactionData?.length > 0 && pageCount > 1 && (
            <div className='items-center pagination-main gap-2 text-center'>
              <PaginationControl
                page={page}
                between={4}
                total={totalCount}
                limit={pageLimit}
                changePage={(page) => {
                  setPage(page);
                }}
                ellipsis={2}
              />
            </div>
          )}
        </div>

      </div>
    </React.Fragment>
  );

};
export default TransactionDocUpload;