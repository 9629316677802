/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import {
  commanLabel,
  imagePath,
  routesConstant,
} from '../../../constants/comman.constant';
import { home_page_tabs } from '../../../constants/tab.constant';
import {
  certificateType,
  UserType,
} from '../../../shared/remake-green-utils/common';
import LedgerList from './ledgerList';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../app/types/store';
import { store } from '../../../app/store';
import { Dashboard } from '../../user/dashboard';
import { userType } from '../../../constants/user.constant';
import ComponentFrame from '../../../pages/admin/plasticCreditRequest/componentFrame';
import ProductDetails from '../../../pages/admin/plasticCreditRequest/productDetails';
import RequestedDetails from '../../admin/plasticCreditRequest/requestedDetails';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { toast } from 'react-toastify';
import {
  getFileFormat,
  getImage,
} from '../../../utils/files/commanFilefunction';
export const Home = () => {
  const { t: translate } = useTranslation();
  const [isReadMore, setIsReadMore] = useState<boolean>(false);
  const [currentTab, setCrrentTab] = useState(home_page_tabs.PLASTIC_CREDIT);
  const onReadMore = () => setIsReadMore(!isReadMore);
  const state: RootState = store.getState();
  const settings = {
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    autoplaySpeed: 4000,
    autoplay: true,
    focusOnSelect: false,
    pauseOnHover: false,
  };
  const isLoggedIn = state.auth.isLoggedIn;
  const userTypeId = state.auth.user?.userTypeId;
  const isReMakeUser =
    userTypeId === UserType.INDIVIDUAL ||
    userTypeId === UserType.COMPANY ||
    userTypeId === UserType.REMAKE;
  const [page] = useState(commanLabel.page);
  const [pageLimit] = useState(commanLabel.pageLimit);
  const [remakeCertificateType] = useState(commanLabel.remakeCertificateType);
  const [remakeData, setRemakeData] = useState([]);
  const logoSlider = {
    dots: false,
    infinite: remakeData.length > 6 ? true : false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    arrow: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const parms = {
      page: page,
      limit: pageLimit,
      owner_type: remakeCertificateType,
    };
    let path = apiUrl.getRemakeCertificate;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setRemakeData(res.data.files);
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      {(!isLoggedIn || !isReMakeUser) && (
        <div>
          <section className='banner-data'>
            <div className='container'>
              <div className='banner-in'>
                <h2 className='title-main'>
                  {translate(
                    'HOMEPAGE.TITLES.WANT_TO_REDUCE_YOUR_ENVIRONMENTAL_FOOTPRINT'
                  )}
                </h2>
                <h3 className='description'>
                  {translate(
                    'HOMEPAGE.TITLES.YOU_HAVE_ARRIVED_AT_THE_ONE_STOP_PLATFORM_ENABLING_PLASTIC_AND_ALL_OTHER_MATERIALS_WASTE_MANAGEMENT'
                  )}
                </h3>
              </div>
            </div>
          </section>
          {/* <ComponentFrame>
            <ProductDetails
              materialSubType="PET"
              materialType="PET"
              Region="India"
              totalCredit={2.5}
              quantity={250}
              quantityUnit={"Kg"}
              currency="USD"
              requestDate="10/10/2022"
            />
            <RequestedDetails
              requestedBy={"Dharmik Boricha"}
              requestDate="10/12/2022"
              role="Remake User"
              state={"Gujrat"}
              city="Bhavnagar"
              zipCode="364004"
              country="India"
              companyContactNumber="0278 787878"
              companyEmail="cygnet@123.com"
              companyIdNumber="#1223444"
              companyName="Cygnet Infotech"
              GSTNumber="GSTIN123456"
              billingAddress="82 Ramnagar Society Nari Bhavnagar"
              userName="Dharmik Boricha"
              userEmail="dharmik@123.com"
              userContactNumber="8866619142"
              referralCode="ACD123"
              currency="USD"
              designation="Software Developer"
            />
          </ComponentFrame> */}
          <section className='slider-main-section'>
            <div className='slider-data'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    <h2 className='title-main'>
                      {translate('HOMEPAGE.TITLES.WELCOME_TO')}{' '}
                      <span>{translate('HOMEPAGE.TITLES.REMAKE')}</span>
                    </h2>
                    <div className='slider-listing'>
                      <Slider {...settings} className='ul-list'>
                        <div className='li-list'>
                          <div className='left-icon-img'>
                            <img
                              src={imagePath.scalingCollectionRecyclingPath}
                              alt='Scaling Collection & Recycling'
                            />
                          </div>
                          <div className='icon-content'>
                            {translate(
                              'HOMEPAGE.TITLES.SCALING_COLLECTION_&_RECYCLING'
                            )}
                          </div>
                        </div>

                        <div className='li-list'>
                          <div className='left-icon-img'>
                            <img
                              src={imagePath.financialIngenuityPath}
                              alt='Financial Ingenuity backed by Technology'
                            />
                          </div>
                          <div className='icon-content'>
                            {translate('HOMEPAGE.TITLES.FINANCIAL_INGENUITY')}
                          </div>
                        </div>

                        <div className='li-list'>
                          <div className='left-icon-img'>
                            <img
                              src={`/assets/images/blockchain-svgrepo-com.svg`}
                              alt='blockchain'
                            />
                          </div>
                          <div className='icon-content'>
                            {translate('HOMEPAGE.TITLES.END_TO_END_BLOCKCHAIN')}
                          </div>
                        </div>

                        <div className='li-list'>
                          <div className='left-icon-img'>
                            <img
                              src={imagePath.economyPlayersPath}
                              alt='Circular'
                            />
                          </div>
                          <div className='icon-content'>
                            {translate('HOMEPAGE.TITLES.TRUSTWORTHY_ECOSYSTEM')}
                          </div>
                        </div>

                        <div className='li-list'>
                          <div className='left-icon-img'>
                            <img
                              src={`/assets/images/finances-statistics-svgrepo-com.png`}
                              alt='Finance statistics'
                            />
                          </div>
                          <div className='icon-content'>
                            {translate(
                              'HOMEPAGE.TITLES.CREATING_SOCIAL_UPLIFTMENT'
                            )}
                          </div>
                        </div>

                        <div className='li-list'>
                          <div className='left-icon-img'>
                            <img
                              src={imagePath.independentBodiesPath}
                              alt='Audit'
                            />
                          </div>
                          <div className='icon-content'>
                            {translate(
                              'HOMEPAGE.TITLES.AUDIT_BY_INDEPENDENT_BODIES'
                            )}
                          </div>
                        </div>
                      </Slider>

                      <div className='button-learn-more'>
                        <Link to='/faq' className='btn btn-primary'>
                          {translate('BUTTONS.LEARN_MORE')}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    <div className='point-section'>
                      <ul>
                        <li>
                          <a
                            href='#features'
                            onClick={() =>
                              setCrrentTab(home_page_tabs.PLASTIC_CREDIT)
                            }
                          >
                            <div className='left'>
                              <img
                                src={imagePath.reMakePlasticCreditPath}
                                alt={
                                  translate(
                                    'HOMEPAGE.TITLES.REMAKE_PLASTIC_CREDIT'
                                  ) + ''
                                }
                              />
                            </div>
                            <div className='right'>
                              <div className='small-text'>
                                {translate('HOMEPAGE.TITLES.BUY')}
                              </div>
                              {translate('HOMEPAGE.TITLES.REMAKE_CREDIT')}
                            </div>
                            <div className='bottom-layer'>
                              <span>
                                {' '}
                                {translate(
                                  'HOMEPAGE.TITLES.GO_PLASTIC_NEUTRAL'
                                )}
                              </span>
                              <div className='icon'>
                                <img src={imagePath.arrowLeft} />
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            href='#features'
                            onClick={() =>
                              setCrrentTab(
                                home_page_tabs.SOCIAL_VALUE_MARKETPLACE
                              )
                            }
                          >
                            <div className='left'>
                              <img
                                src={imagePath.reMakeTokensPath}
                                alt={
                                  translate(
                                    'HOMEPAGE.TITLES.SOCIAL_VALUE_MARKETPLACE'
                                  ) + ''
                                }
                              />{' '}
                            </div>
                            <div className='right'>
                              {translate('HOMEPAGE.TITLES.SOCIAL_VALUE')}
                            </div>
                            <div className='bottom-layer'>
                              <span> {translate('HOMEPAGE.TITLES.NFTS')}</span>
                              <div className='icon'>
                                <img src={imagePath.arrowLeft} />
                              </div>
                            </div>
                          </a>
                        </li>

                        <li>
                          <a
                            href='#features'
                            onClick={() =>
                              setCrrentTab(home_page_tabs.REMAKE_TOKENS)
                            }
                          >
                            <div className='left'>
                              <img
                                src={imagePath.socialValueMarketplacePath}
                                alt={
                                  translate('HOMEPAGE.TITLES.REMAKE_TOKENS') +
                                  ''
                                }
                              />
                            </div>
                            <div className='right'>
                              {translate('HOMEPAGE.TITLES.REMAKE_TOKENS')}
                            </div>
                            <div className='bottom-layer'>
                              <span>
                                {' '}
                                {translate('HOMEPAGE.TITLES.UPCOMING')}
                              </span>
                              <div className='icon'>
                                <img src={imagePath.arrowLeft} />
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <div className='slider-big-img'>
                      <Slider
                        {...{ ...settings, slidesToShow: 1, slidesToScroll: 1 }}
                      >
                        <div>
                          <img
                            src={imagePath.slidesOnePath}
                            alt='circular economy'
                          />
                        </div>
                        <div>
                          <img
                            src={imagePath.slidesTwoPath}
                            alt='Plastic credit'
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      {isLoggedIn && isReMakeUser && (
        <section className='slider-main-section'>
          <div className='slider-data'>
            <div className='container'>
              <Dashboard></Dashboard>
            </div>
          </div>
        </section>
      )}
      <section className='distinguishing-feature tab-section'>
        <div className='container'>
          <div className="tags">OUR FEATURES</div>
          <h2 className="title-main">Distinguishing  <span>Features</span></h2>
          
          <div className='feature-area'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4'>
                <div className='feature-box'>
                  <div className='box-number d-flex justify-content-center d-flex align-items-center'>
                    <img src={'/assets/images/comprehensive-coverage-icon.svg'}></img>
                  </div>
                  <h5 className='box-title'>Comprehensive Coverage</h5>
                  <p className='box-content'>ReMake program includes the management of a wide array of waste materials (including Plastic, Metals, Textile, Paper, Glass, etc.) and their respective sub-variants.</p>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4'>
                <div className='feature-box'>
                  <div className='box-number d-flex justify-content-center d-flex align-items-center'>
                    <img src={'/assets/images/landfill-icon.svg'}></img>
                  </div>
                  <h5 className='box-title'>No reward for poor management</h5>
                  <p className='box-content'>Incineration or landfill dumping of waste is not considered eligible for issuance of credits.</p>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4'>
                <div className='feature-box'>
                  <div className='box-number d-flex justify-content-center d-flex align-items-center'>
                    <img src={'/assets/images/transaction-level-verified-icon.svg'}></img>
                  </div>
                  <h5 className='box-title'>Transaction-level verification</h5>
                  <p className='box-content'>Verification and substantiation (with supporting documents enclosed along with Credit Certificate) of each and every waste management activities transaction carried out for issuance of credits (no sampling method).</p>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4'>
                <div className='feature-box'>
                  <div className='box-number d-flex justify-content-center d-flex align-items-center'>
                    <img src={'/assets/images/best-practices-icon.svg'}></img>
                  </div>
                  <h5 className='box-title'>Best practices Ensured</h5>
                  <p className='box-content'>Projects are required to ensure there involves no cash transaction in their supply chain, no child labor involved, fair wages are guaranteed, and best occupational safety norms are followed.</p>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4'>
                <div className='feature-box'>
                  <div className='box-number d-flex justify-content-center d-flex align-items-center'>
                    <img src={'/assets/images/sharing-reward-icon.svg'}></img>
                  </div>
                  <h5 className='box-title'>Sharing Reward</h5>
                  <p className='box-content'>Under the ReMake program, the fund received from the sale of credits is distributed to all the registered value chain partners of the project (collector, recycler, manufacturer, etc) involved in a particular associated transaction.</p>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4'>
                <div className='feature-box'>
                  <div className='box-number d-flex justify-content-center d-flex align-items-center'>
                    <img src={'/assets/images/social-aspect-icon-new.svg'}></img>
                  </div>
                  <h5 className='box-title'>Social Aspect Integrated</h5>
                  <p className='box-content'>Creating social value while carrying out each waste management activity is a mandated requirement under the ReMake standard.</p>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4'>
                <div className='feature-box'>
                  <div className='box-number d-flex justify-content-center d-flex align-items-center'>
                    <img src={'/assets/images/circular-credit-icon.svg'}></img>
                  </div>
                  <h5 className='box-title'>ReMake Circular Credit</h5>
                  <p className='box-content'>A third type of credit is issued where both collection and recycling activities are involved in any transaction; for bringing the waste back into the circular economy chain.</p>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4'>
                <div className='feature-box'>
                  <div className='box-number d-flex justify-content-center d-flex align-items-center'>
                    <img src={'/assets/images/climate-metric-icon.svg'}></img>
                  </div>
                  <h5 className='box-title'>Climate Metric Inclusion</h5>
                  <p className='box-content'>Calculation of GHG emission reduction achieved with the waste management activity is done using vetted studies, and the same is reflected in all credit certificates issued.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='tab-section slider-main-section' id='features'>
        <div className='container'>
          {/* <div className='tags'>
            {translate('HOMEPAGE.TITLES.OUR_FEATURES')}
          </div> */}
          <h2 className='title-main'>
            {translate('HOMEPAGE.TITLES.CHECK_OUT_THE_SOLUTION')}
            <span>{translate('HOMEPAGE.TITLES.SOLUTION')}</span>
          </h2>
          <div className='tabs-section-main'>
            <ul className='nav nav-tabs' id='myTab' role='tablist'>
              <li
                className='nav-item'
                onClick={() => setCrrentTab(home_page_tabs.PLASTIC_CREDIT)}
              >
                <a
                  className={`nav-link ${currentTab === home_page_tabs.PLASTIC_CREDIT ? 'active' : ''
                    }`}
                  id='tab-one'
                  data-toggle='tab'
                  role='tab'
                  aria-controls='tab-one-control'
                  aria-selected='true'
                >
                  <div className='item-title'>
                    <div className='left-icon'>
                      <img src={imagePath.plasticCreditPath} alt='' />
                    </div>
                    <div className='content'>
                      {translate('HOMEPAGE.TITLES.REMAKE_CREDIT')}
                    </div>
                  </div>
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() =>
                  setCrrentTab(home_page_tabs.SOCIAL_VALUE_MARKETPLACE)
                }
              >
                <a
                  className={`nav-link ${currentTab === home_page_tabs.SOCIAL_VALUE_MARKETPLACE
                    ? 'active'
                    : ''
                    }`}
                  id='tab-two'
                  data-toggle='tab'
                  role='tab'
                  aria-controls='tab-two-control'
                  aria-selected='false'
                >
                  <div className='item-title'>
                    <div className='left-icon'>
                      <img src={imagePath.socialValueMarketplacePath} alt='' />
                    </div>
                    <div className='content'>
                      {translate('HOMEPAGE.TITLES.SOCIAL_VALUE_MARKETPLACE')}
                    </div>
                  </div>
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => setCrrentTab(home_page_tabs.REMAKE_TOKENS)}
              >
                <a
                  className={`nav-link ${currentTab === home_page_tabs.REMAKE_TOKENS ? 'active' : ''
                    }`}
                  id='tab-three'
                  data-toggle='tab'
                  role='tab'
                  aria-controls='tab-three-control'
                  aria-selected='false'
                >
                  <div className='item-title'>
                    <div className='left-icon'>
                      <img src={imagePath.reMakeTokensPath} alt='' />
                    </div>
                    <div className='content'>
                      {translate('HOMEPAGE.TITLES.REMAKE_TOKENS')}
                    </div>
                  </div>
                </a>
              </li>

              <li
                className='nav-item'
                onClick={() => setCrrentTab(home_page_tabs.LEDGER)}
              >
                <a
                  className={`nav-link ${currentTab === home_page_tabs.LEDGER ? 'active' : ''
                    }`}
                  id='tab-four'
                  data-toggle='tab'
                  role='tab'
                  aria-controls='tab-four-control'
                  aria-selected='false'
                >
                  <div className='item-title'>
                    <div className='left-icon'>
                      <img src={imagePath.ledgerPath} alt='' />
                    </div>
                    <div className='content'>
                      {translate('HOMEPAGE.TITLES.LEDGER')}
                    </div>
                  </div>
                </a>
              </li>
            </ul>
            <div className='tab-content' id='myTabContent'>
              {/* Section -1 */}
              <div
                className={`tab-pane fade ${currentTab === home_page_tabs.PLASTIC_CREDIT
                  ? 'show active'
                  : ''
                  }`}
                id='tab-one-control'
                role='tabpanel'
                aria-labelledby='tab-one'
              >
                <div className='row'>
                  <div className='col-lg-5 col-md-6'>
                    <div className='view-items'>
                      <div className='layer-one'>
                        <div className='title'>
                          {translate(
                            'HOMEPAGE.TITLES.COLLECTING_AND_RECYCLING'
                          )}{' '}
                          <span>{translate('HOMEPAGE.TITLES.IMPACT')}</span>
                        </div>

                        <div className='items'>
                          <div className='item'>
                            <div>
                              <div className='img-icon'>
                                <img src={imagePath.starPath} alt='' />
                                700+{' '}
                                <div className='item-description'>
                                  {translate(
                                    'HOMEPAGE.TITLES.RECLAIMERS_SOCIALLY'
                                  )}
                                  <span>
                                    {translate('HOMEPAGE.TITLES.UPLIFTED')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='item'>
                            <div>
                              <div className='img-icon'>
                                <img src={imagePath.starPath} alt='' />
                                30,000+
                                <div className='item-description'>
                                  {translate(
                                    'HOMEPAGE.TITLES.TONNES_OF_PLASTIC_WASTE'
                                  )}
                                  <span>
                                    {translate('HOMEPAGE.TITLES.MANAGED')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='item'>
                            <div>
                              <div className='img-icon'>
                                <img src={imagePath.starPath} alt='' />
                                45,000+
                                <div className='item-description'>
                                  {translate(
                                    'HOMEPAGE.TITLES.TONNES_OF_GHG_EMISSIONS'
                                  )}
                                  <span>
                                    {translate('HOMEPAGE.TITLES.SAVED')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='layer-two'>
                        <div className='title'>
                          {translate('HOMEPAGE.TITLES.TOP_CATEGORIES')}
                        </div>

                        <div className='categories'>
                          <ul>
                            <li>
                              <div className='product'>
                                {' '}
                                <span>1. </span>LDPE
                              </div>
                              <div className='tags'>
                                {translate('HOMEPAGE.TITLES.PLASTIC')}
                              </div>
                            </li>

                            <li>
                              <div className='product'>
                                <span>2. </span>PET
                              </div>
                              <div className='tags'>
                                {translate('HOMEPAGE.TITLES.PLASTIC')}
                              </div>
                            </li>

                            <li>
                              <div className='product'>
                                {' '}
                                <span>3. </span>{' '}
                                {translate('HOMEPAGE.TITLES.ZORBA')}
                              </div>
                              <div className='tags'>
                                {translate('HOMEPAGE.TITLES.ALUMINIUM')}
                              </div>
                            </li>

                            <li>
                              <div className='product'>
                                {' '}
                                <span>4. </span>PP
                              </div>
                              <div className='tags'>
                                {translate('HOMEPAGE.TITLES.PLASTIC')}
                              </div>
                            </li>

                            <li>
                              <div className='product'>
                                {' '}
                                <span>5. </span>MLP
                              </div>
                              <div className='tags'>
                                {translate('HOMEPAGE.TITLES.PLASTIC')}
                              </div>
                            </li>
                          </ul>
                          {/* <div className="view-more"><a href="#">View More</a></div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div className='box-items'>
                      <Link
                        to={
                          state.auth.isLoggedIn &&
                            UserType.REMAKE == state.auth?.user?.role
                            ? routesConstant.remakeCreditRequest +
                            window.btoa(certificateType.COLLECTION.toString())
                            : routesConstant.creditRequest +
                            window.btoa(certificateType.COLLECTION.toString())
                        }
                        className='item'
                      >
                        <i
                          className='fa fa-info-circle'
                          title='A ReMake Collection Credit represents one metric tonne of plastic waste collected.'
                          aria-hidden='true'
                        ></i>

                        <div className='left-icon'>
                          <img src={imagePath.collectionCreditPath} alt='' />
                        </div>
                        <div className='right-item'>
                          {translate('HOMEPAGE.TITLES.BUY_COLLECTION_CREDIT')}
                        </div>

                        <div className='notification-message'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting
                        </div>
                      </Link>
                      <Link
                        to={
                          state.auth.isLoggedIn &&
                            UserType.REMAKE == state.auth?.user?.role
                            ? routesConstant.remakeCreditRequest +
                            window.btoa(certificateType.RECYCLING.toString())
                            : routesConstant.creditRequest +
                            window.btoa(certificateType.RECYCLING.toString())
                        }
                        className='item'
                      >
                        <i
                          className='fa fa-info-circle'
                          title='A ReMake Recycling Credit represents one metric tonne of plastic waste recycled.'
                          aria-hidden='true'
                        ></i>
                        <div className='left-icon'>
                          <img src={imagePath.recyclingCreditPath} alt='' />
                        </div>
                        <div className='right-item'>
                          {translate('HOMEPAGE.TITLES.BUY_RECYCLING_CREDIT')}
                        </div>

                        <div className='notification-message'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting
                        </div>
                      </Link>
                      <Link
                        to={
                          state.auth.isLoggedIn &&
                            UserType.REMAKE == state.auth?.user?.role
                            ? routesConstant.remakeCreditRequest +
                            window.btoa(certificateType.CIRCULAR.toString())
                            : routesConstant.creditRequest +
                            window.btoa(certificateType.CIRCULAR.toString())
                        }
                        className='item'
                      >
                        <i
                          className='fa fa-info-circle'
                          title='A ReMake Circular Credit represents one metric tonne of plastic waste collected & recycled.'
                          aria-hidden='true'
                        ></i>
                        <div className='left-icon'>
                          <img src={imagePath.circularCreditPath} alt='' />
                        </div>
                        <div className='right-item'>
                          {translate('HOMEPAGE.TITLES.BUY_CIRCULAR_CREDIT')}
                        </div>

                        <div className='notification-message'>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className='col-lg-4 col-md-12'>
                    <div
                      className={`description-details read-more-less ${isReadMore ? 'read-less-active' : ''
                        }`}
                    >
                      <h2 className='title'>
                        {translate('HOMEPAGE.TITLES.WHAT_IS_PLASTIC_CREDIT')}
                      </h2>
                      <p className='description'>
                        {translate('HOMEPAGE.TITLES.PLASTIC_CREDIT_DEFINE')}
                        <Link
                          to={'#'}
                          className='read-more-link'
                          onClick={onReadMore}
                        >
                          {translate('BUTTONS.READ_MORE')}
                        </Link>
                      </p>

                      <div className='read-more-content'>
                        {translate(
                          'HOMEPAGE.TITLES.PLASTIC_CREDIT_READ_MORE_CONTENT'
                        )}
                        <Link
                          to={'#'}
                          className='read-less-link'
                          onClick={onReadMore}
                        >
                          {translate('BUTTONS.READ_LESS')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Section -2  */}
              <div
                className={`tab-pane fade ${currentTab === home_page_tabs.SOCIAL_VALUE_MARKETPLACE
                  ? 'show active'
                  : ''
                  }`}
                id='tab-two-control'
                role='tabpanel'
                aria-labelledby='tab-two'
              >
                <div className='listing-point-section '>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='left-img'>
                        <img
                          src={`/assets/images/NFT-action.svg`}
                          alt='NFT-action'
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='ul-list-title'>
                        {translate('HOMEPAGE.TITLES.BENEFITS')}
                      </div>
                      <ul className='listing-icon'>
                        <li>
                          {translate(
                            'HOMEPAGE.TITLES.UPLIFTING_LIFE_OF_CIRCULAR_PARTICIPANTS'
                          )}{' '}
                        </li>
                        <li>
                          {translate(
                            'HOMEPAGE.TITLES.ACHIEVING_NET_ZERO_AND_COMPLIANCE_SDG_GOALS'
                          )}
                        </li>
                        <li>
                          {translate(
                            'HOMEPAGE.TITLES.SUSTAINABLE_FINANCIAL_MODEL_FOR_CIRCULAR_PARTICIPANTS'
                          )}
                        </li>
                        <li>
                          {translate(
                            'HOMEPAGE.TITLES.ENHANCE_VALUE_OF_WASTE_MATERIAL'
                          )}
                        </li>
                        <li>{translate('HOMEPAGE.TITLES.CLOSING_THE_LOOP')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* Section -3 */}
              <div
                className={`tab-pane fade ${currentTab === home_page_tabs.REMAKE_TOKENS
                  ? 'show active'
                  : ''
                  }`}
                id='tab-three-control'
                role='tabpanel'
                aria-labelledby='tab-three'
              >
                <div className='itd-section'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='image-wrap'>
                        <img
                          src={`/assets/images/ReMake Tokens.svg`}
                          alt='ReMake Tokens'
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='title-description'>
                        <div className='title-mid title'>
                          {translate('HOMEPAGE.TITLES.HOW_REMAKE_TOKENS_WORK')}
                        </div>
                        <div className='description'>
                          <p>
                            {translate(
                              'HOMEPAGE.TITLES.REMAKE_WORKING_CONTENT'
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Section -4 */}
              <div
                className={`tab-pane fade ${currentTab === home_page_tabs.LEDGER ? 'show active' : ''
                  }`}
                id='tab-four-control'
                role='tabpanel'
                aria-labelledby='tab-four'
              >
                {currentTab === home_page_tabs.LEDGER && (
                  <LedgerList currentTab={currentTab}></LedgerList>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='tab-section' id='logos'>
        <div className='container'>
          <div className='tags'>
            {translate('HOMEPAGE.TITLES.CERTIFICATIONS')}
          </div>
          <h2 className=''>
            {translate('HOMEPAGE.TITLES.VALUECHAIN_PARTNER_CERTIFICATE')}
          </h2>
          <Slider {...logoSlider} className='logo-slider'>
            {remakeData.length > 0 && remakeData.map((item: any, index) => {
              if (item?.document_name !== 'OBP - Recycling.pdf') {
                return (
                  <div className='slick-item'>
                    <div className="img-item">
                      <img src={getFileFormat(item?.document_type) === "PDF" ?
                        getImage(item?.document_name) : item?.document_path} alt={""} width={70} />
                    </div>
                  </div>
                );
              }
            }
            )}
          </Slider>
        </div>
      </section>
      <div className='popup-screen-data'>
        <div
          className='modal fade '
          id='modalmain'
          tabIndex={-1}
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <i className='fa fa-times-circle'></i>
              </button>
              <div className='modal-body'>
                <div className='form-data login-forms form-modal-items'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <h2 className='title-main'>
                        <span>ReMake</span> Login
                      </h2>
                      <form className='login-form'>
                        <div className='form-group'>
                          <label htmlFor=''>Enter Username</label>
                          <input
                            type='email'
                            className='form-control'
                            id=''
                            aria-describedby='emailHelp'
                            placeholder='yourmail@gmail.com'
                          />
                        </div>
                        <div className='form-group mb-0'>
                          <label htmlFor='password-data'>Enter Password</label>
                          <div className='password-input'>
                            <input
                              type='password'
                              id='password-data'
                              className='form-control mypassword'
                              placeholder='Password'
                            />
                            <div className='eye-parent'>
                              <i
                                className='fas fa-eye-slash eye'
                                tabIndex={-1}
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div className='forget-password-link font-base '>
                          <a
                            className='forget-password-popbtn'
                            href='./forget-passoword.html'
                          >
                            Forgot Password ?
                          </a>
                        </div>
                        <div className='submit-button'>
                          <button
                            type='submit'
                            className='btn btn-primary goto-req'
                          >
                            Login
                          </button>
                        </div>
                        <div className='register font-base'>
                          Doesn't have an account yet?{' '}
                          <a
                            className='goto-req'
                            href='./create-account-page.html'
                          >
                            Register Now
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className='form-data forget-password form-modal-item'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <h2 className='title-main'>Forgot Password</h2>
                      <form className='forget-password-form'>
                        <div className='form-group'>
                          <label htmlFor='username1'>Enter Username</label>
                          <input
                            type='email'
                            className='form-control '
                            id='username1'
                            aria-describedby='emailHelp'
                            placeholder='yourmail@gmail.com'
                          />
                        </div>

                        <div className='submit-button'>
                          <a
                            href='./check-email.html'
                            className='btn btn-primary send-reset-link'
                          >
                            Send reset link
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className='form-data check-mail form-modal-item'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='check-email-section'>
                        <h2 className='title-main'>Check your mail</h2>
                        <div className='description'>
                          <p>
                            We just emailed you with the instructions to reset
                            your password.
                          </p>
                        </div>
                        <div className='submit-button'>
                          <a
                            href='./login.html'
                            className='btn btn-primary backlogin-btn'
                          >
                            Back To Login
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-data requirenment-form form-modal-item'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <h2 className='title-main'>
                        Collection Credit Requirement
                      </h2>
                      <div className='form-requirenment'>
                        <div className='form-group required'>
                          <label htmlFor='plastic-data'>Type of Material</label>
                          <select className='form-control' id='plastic-data'>
                            <option>Plastic</option>
                            <option>Plastic</option>
                          </select>
                        </div>

                        <div className='form-group required'>
                          <label htmlFor='ror'>Type of Category</label>
                          <select className='form-control' id='ror'>
                            <option>test1</option>
                            <option>test1</option>
                          </select>
                        </div>

                        <div className='form-group required'>
                          <label htmlFor='roc'>Region of Collection</label>
                          <select className='form-control' id='roc' disabled>
                            <option>India</option>
                            <option>test1</option>
                          </select>
                        </div>

                        <div className='form-group required'>
                          <label htmlFor=''>Quantity</label>

                          <div className='input-group'>
                            <div className='input-left'>
                              <input type='number' className='form-control' />
                            </div>

                            <div className='input-right'>
                              <select
                                className='form-control'
                                id='quanity_item'
                              >
                                <option>Kg</option>
                                <option>Kg</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className='submit-button'>
                          <a
                            href='./homepage.html'
                            className='btn btn-primary form-submit-popup'
                          >
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-data request-submit form-modal-item'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='content-data-top'>
                        <div className='icon'>
                          <i className='fa-solid fa-circle-check'></i>
                        </div>
                        <h2 className='title-main'>
                          Request submitted successfully!
                        </h2>
                        <div className='description'>
                          You will receive an email confirmation for the same.
                        </div>
                      </div>

                      <div className='content-data-bottom'>
                        <div className='top-title'>Requirement details:</div>

                        <div className='row'>
                          <div className='col-md-4'>
                            <div className='label-title'>Type of Plastic</div>
                            <div className='label-description'>PET</div>
                          </div>
                          <div className='col-md-5'>
                            <div className='label-title'>
                              Region of Collection
                            </div>
                            <div className='label-description'>Mumbai</div>
                          </div>
                          <div className='col-md-3'>
                            <div className='label-title'>Quantity</div>
                            <div className='label-description'>10 Kg</div>
                          </div>
                        </div>

                        <div className='submit-button'>
                          <a
                            href='./homepage-logged-in.html'
                            className='btn btn-primary'
                          >
                            Login & Continue
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
