import moment from 'moment';
import React from 'react';
import { getAge } from '../../../../constants/comman.constant';
import { maskPCId, maskaadhar } from '../../../../utils/utilFunctions';
import { useTranslation } from 'react-i18next';

const OtherCSRActivityList = (props: any) => {
  const { data } = props;
  const { t: translate } = useTranslation();

  return (
    <>
      {props.hideHeader === false && (
        <div className='reclember-certificate-page'>
          <div className='container-items header-bg-img'>
            <div className='main-header container-space'>
              <div className='header-conetent'>
                {/* <div className="header-bg">
                <img src={`${process.env.PUBLIC_URL}/assets/images/certificate/header-reclaim.png`} className="full-width" />
              </div> */}
                <h5 className='header-title'>
                  {translate('CERTIFICATE_PAGE.OTHER_CSR.REMAKE_SOCIAL')}
                </h5>
                <p className='header-subject'>
                  {translate(
                    'CERTIFICATE_PAGE.OTHER_CSR.RECLAIMER_OUTREACH_PROGRAM'
                  )}
                </p>
                <div className='left-section'>
                  <div className='text-top'>
                    <p>
                      {translate(
                        'CERTIFICATE_PAGE.OTHER_CSR.GIVING_BACK_TO_SOCIETY'
                      )}
                    </p>
                  </div>
                  <div className='certificate-type'>
                    <p className='title'>{data.length}</p>
                    <p className='description'>
                      {translate(
                        'CERTIFICATE_PAGE.OTHER_CSR.RECLAIMERS_EMPOWERED'
                      )}
                    </p>
                  </div>
                  <div className='activity-date'>
                    <p className='date-title'>
                      {translate('CERTIFICATE_PAGE.OTHER_CSR.ACTIVITY_DATE')}
                    </p>
                    <p className='date-area'>
                      <span>
                        {moment(data[0]?.activity_date).format('DD/MM/YYYY')}
                      </span>{' '}
                      to{' '}
                      <span>
                        {moment(data[data.length - 1]?.activity_date).format(
                          'DD/MM/YYYY'
                        )}
                      </span>
                    </p>
                  </div>
                </div>

                <div className='center-section'>
                  <div className='logo-area'>
                    <a
                      href='https://remake.green/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/certificate/header-logo.svg`}
                        alt='header-logo'
                      />
                    </a>
                  </div>
                  <div className='remake-green-link'>
                    <a
                      href='https://remake.green/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      https:/remake.green
                    </a>
                  </div>
                </div>
                <div className='right-section'></div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='reclaimer-activity-details mt-5'>
        {props.hideHeader === false && (
          <h3 className='title-line'>
            {translate('CERTIFICATE_PAGE.OTHER_CSR.RECLAIMER_ACTIVITY_DETAILS')}
          </h3>
        )}
        {data &&
          data.map((u: any, i: any) => {
            return (
              <div className='reclaimer-box '>
                <div className='row '>
                  <div className='col-sm-6 col-md-6 col-lg-6 left-area special_border'>
                    <div className='row '>
                      <div className='col-lg-5'>
                        <div className='title-text'>
                          {translate(
                            'CERTIFICATE_PAGE.OTHER_CSR.ACTIVITY_NAME'
                          )}
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.activity_id || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>
                          {translate(
                            'CERTIFICATE_PAGE.OTHER_CSR.ACTIVITY_DATE'
                          )}
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {moment(u?.activity_date).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>
                          {translate('CERTIFICATE_PAGE.OTHER_CSR.EVENT_NAME')}
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.other_csr_event?.name || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>
                          {translate(
                            'CERTIFICATE_PAGE.OTHER_CSR.RECLAIMER_NAME'
                          )}
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_reclaimer_profile?.name || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>
                          {translate('CERTIFICATE_PAGE.OTHER_CSR.GENDER')}
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_reclaimer_profile?.gender || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>
                          {translate('CERTIFICATE_PAGE.OTHER_CSR.AGE')}
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {getAge(u?.csr_reclaimer_profile?.birth_date)}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>
                          {u?.csr_reclaimer_profile?.identification_type || '-'}
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {maskaadhar(
                            u?.csr_reclaimer_profile?.identification_value
                          ) || '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-6 col-lg-6 right-area no_right_border special_border'>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>
                          {translate(
                            'CERTIFICATE_PAGE.OTHER_CSR.EVENT_CATEGORY'
                          )}
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.other_csr_event?.event_category?.name || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>
                          {translate(
                            'CERTIFICATE_PAGE.OTHER_CSR.ACTIVITY_CATEGORY'
                          )}
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_sub_category?.name || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>
                          {translate('CERTIFICATE_PAGE.OTHER_CSR.ORGANIZER')}
                        </div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.other_csr_event?.organizer || '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='row'>
                      <p className='photo-name'>
                        {translate('CERTIFICATE_PAGE.OTHER_CSR.ACTIVITY_PHOTO')}
                      </p>
                      {u?.activity_photos &&
                        JSON.parse(u?.activity_photos).map(
                          (data: any, i: any) => {
                            return (
                              <div className='col-sm-6 col-md-6 col-lg-4'>
                                <div className='image-section'>
                                  <div
                                    className='image-box'
                                    style={{
                                      height: '200px',
                                      width: '100%',
                                      maxWidth: '300px',
                                    }}
                                  >
                                    <img
                                      style={{
                                        filter: 'blur(3px)',
                                        width: '100%',
                                        height: '100%',
                                      }}
                                      src={data}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default OtherCSRActivityList;
