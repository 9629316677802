import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { toast } from "react-toastify";
import {
  commanLabel,
  lazy_load_table_setting,
} from "../../../constants/comman.constant";
import { RootState } from "../../../app/types/store";
import { store } from "../../../app/store";
import { maskOwnerName, maskPCId } from "../../../utils/utilFunctions";
import { apiUrl } from "../../../app/api/services/apiUrl";
import { helper } from "../../../app/api/api.helper";
import { CertificateTypes } from "../../../constants/certificate.constant";
import {
  ledgerTableColumn,
  StatusTypes,
} from "../../../constants/ledger.constant";
import InfiniteScroll from "react-infinite-scroll-component";

import moment from "moment";
import { home_page_tabs } from "../../../constants/tab.constant";
import { useTranslation } from "react-i18next";
export interface tabType {
  currentTab: any;
}
const LedgerList = (props: tabType) => {
  const { t: translate } = useTranslation();
  const state: RootState = store.getState();

  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [allLedgerList, setAllLedgerList] = useState<ledgerInterface[]>([]);
  const columnHelper = createColumnHelper<any>();
  const [isSearching, setIsSearching] = useState(false);

  const [isSchroll, setIsSchroll] = useState(false);
  const [creditTypeId, setCreditTypeId] = useState("");
  const [materialTypeId, setMaterialTypeId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [creditId, setCreditId] = useState("");
  const [materialTypeData, setMaterialTypeData] = useState([]);
  const [materialSubCategory, setMaterialSubCategory] = useState<any>([]);

  const myRef: any = useRef(null);
  const executeScroll = () =>
    myRef && myRef.current && myRef.current.scrollIntoView();

  // useEffect(() => {
  //   if (!state.auth.isLoggedIn) {
  //     setAllLedgerList((allLedgerList) => [...allLedgerList]);
  //   }
  // }, [creditId]);

  const statusContainer = (status: string) => {
    switch (status) {
      case StatusTypes.ACTIVE:
        return (
          <div className="status-data">
            <div className="tag-icon">
              <i className="fa-solid fa-circle-check"></i>
              <span>{StatusTypes.ACTIVE}</span>
            </div>
          </div>
        );

      case StatusTypes.TRANSFERRED:
        return (
          <div className="status-data status-orange">
            <div className="tag-icon">
              <i className="fa fa-minus-circle" aria-hidden="true"></i>
              <span>{StatusTypes.TRANSFERRED}</span>
            </div>
          </div>
        );

      case StatusTypes.RETIRED:
        return (
          <div className="status-data status-purple">
            <div className="tag-icon round-fa-icon">
              <i className="fa fa-hourglass-half " aria-hidden="true"></i>
              <span>{StatusTypes.RETIRED}</span>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  const columns = [
    columnHelper.accessor(
      (u) =>
        !state.auth.isLoggedIn
          ? u.qr_code_serial_no &&
            window.atob(u?.qr_code_serial_no) === creditId
            ? window.atob(u?.qr_code_serial_no)
            : maskPCId(window.atob(u?.qr_code_serial_no))
          : u?.qr_code_serial_no
          ? window.atob(u?.qr_code_serial_no)
          : "-",
      {
        id: translate("HOMEPAGE.TITLES.PLASTIC_CREDIT_ID") + "",
      }
    ),
    columnHelper.accessor(
      (u) => 'XXXXXXXXXX',
      {
        id: translate("HOMEPAGE.TITLES.OWNER_NAME") + "",
      }
    ),
    // columnHelper.accessor(
    //   (u) =>
    //     !state.auth.isLoggedIn
    //       ? u.plastic_credit_request_details &&
    //         (u.plastic_credit_request_details.pc_user || u?.plastic_credit_request_details?.user) &&
    //         window.atob(u?.qr_code_serial_no) === creditId
    //         ? window.atob(u?.plastic_credit_request_details?.user?.user_profile?.user_name?u?.plastic_credit_request_details?.user?.user_profile?.user_name:u?.plastic_credit_request_details?.pc_user?.name)
    //         : "XXXXXXXXXX"
    //       : u?.plastic_credit_request_details?.user?.user_profile?.user_name|| u?.plastic_credit_request_details?.pc_user?.name
    //       ? window.atob(u?.plastic_credit_request_details?.user?.user_profile?.user_name?u?.plastic_credit_request_details?.user?.user_profile?.user_name:u?.plastic_credit_request_details?.pc_user?.name)
    //       : "-",
    //   {
    //     id: translate("HOMEPAGE.TITLES.OWNER_NAME") + "",
    //   }
    // ),

    columnHelper.accessor("-", {
      header: () => translate("HOMEPAGE.TITLES.STATUS") + "",
      enableSorting: false,
      cell: (props: any) => {
        return (
          <React.Fragment>
            {statusContainer(
              props?.cell?.row?.original?.plastic_credit_request_details
                ?.plastic_credit_status_types?.name
            )}
          </React.Fragment>
        );
      },
    }),
    columnHelper.accessor(
      (u) => u.plastic_credit_type && u.plastic_credit_type.name,
      {
        id: translate("HOMEPAGE.TITLES.CREDIT_TYPE") + "",
      }
    ),

    columnHelper.accessor(
      (u) => `${u?.linked_transaction_detail?.material_type?.name} - ${u?.linked_transaction_detail?.material_name_type?.name}`,
      {
        id: translate("HOMEPAGE.TITLES.MATERIAL_TYPE") + "",
      }
    ),
    columnHelper.accessor(
      (u) => ` ${u?.linked_transaction_detail?.unloaded_quantity} ${u?.linked_transaction_detail?.unloaded_quantity_unit}`,
      {
        id: translate("HOMEPAGE.TITLES.QUANTITY") + "",
      }
    ),
    columnHelper.accessor(
      (u) =>
        u?.linked_transaction_detail?.credit_generated && u?.linked_transaction_detail?.credit_generated.length > 3
          ? parseFloat(u?.linked_transaction_detail?.credit_generated).toFixed(3)
          : u?.linked_transaction_detail?.credit_generated,
      {
        id: translate("HOMEPAGE.TITLES.CREDIT_UNIT") + "",
      }
    ),
    columnHelper.accessor(
      (u) => `${moment(u.created_on).format(" DD/MM/YYYY hh:mm A ")}`,
      {
        id: translate("HOMEPAGE.TITLES.TIMESTAMP") + "",
      }
    ),
  ];
  /**
   * Create tanstack/react-table
   */
  const table = useReactTable({
    data: allLedgerList,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });
  /**
   *
   * @param reset reset can be use in feature
   * This Method is used to set data to table. Intialy it will load 20 data then it has infinite scroll
   *  which will call another page and
   * data will be displayed because we have set dependency of page in useEfect
   */

  const getMaterialSubCategory = async (materialId: string) => {
    let path = apiUrl.getMaterialSubCategory + materialId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let subCategory = res?.data?.allSubCategory;
      setMaterialSubCategory(subCategory);
    } else {
      toast.error(res.message);
    }
  };

  const getSettingData = async () => {
    let path = apiUrl.getAllSetting;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let allSetting = res?.data && res?.data?.jsonData;
      setMaterialTypeData(allSetting?.Material_type);
      // materialSubTypeOption(materialSubData?.data?.allSubCategory);
    } else {
      toast.error(res.message);
    }
  };

  const handeleReset = () => {
    setCreditId("");
    setCreditTypeId("");
    setMaterialTypeId("");
    setSubCategoryId("");
    setPage(1);
    setTotalRecords(0);
    getData(true);
  };

  useEffect(() => {
    getSettingData();
    getData(false);
  }, []);

  const getData = async (reset: boolean) => {
    setIsLoading(true);

    const parms = {
      page: reset ? 1 : page,
      limit: commanLabel.pageLimit,
      credit_type: reset ? "" : creditTypeId,
      material_type_id: reset ? "" : materialTypeId,
      material_name_type_id: reset ? "" : subCategoryId,
      searchText: reset ? "" : creditId,
    };
    let path = apiUrl.getLedgerList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      if (reset) {
        setAllLedgerList([...res.data.allTransaction]);
      } else {
        setAllLedgerList([...allLedgerList, ...res.data.allTransaction]);
      }
      setIsSearching(false);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setIsLoading(false);
      setTotalRecords(res.data.total);
    } else {
      toast.error(res.message);
      setIsSearching(false);
    }
    setIsLoading(false);
  };
  /** Will load mor pages */
  const fetchMoreData = () => {
    setTimeout(() => {
      setIsSchroll(true);
      setPage((page) => page + 1);
    }, 1000);
  };
  /** This method called first which will load data first time then when page change it will load data again */
  useEffect(() => {
    if (page > 1) {
      getData(false);
    }
  }, [page]);

  useEffect(() => {
    if (isSearching && allLedgerList.length === 0) {
      getData(false);
    }
  }, [isSearching, allLedgerList, pageCount]);

  useEffect(() => {
    if (props.currentTab === home_page_tabs.LEDGER) {
      document.getElementsByClassName(
        "infinite-scroll-component"
      )[0].scrollTop = 0;
    }
  }, [props.currentTab]);

  const performSearch = (e: any) => {
    e.preventDefault();
    setIsSearching(true);
    executeScroll();
    setPage(1);
    setPageCount(0);
    setTotalRecords(0);
    setAllLedgerList([]);
  };
  return (
    <div>
      <div className="table-item-simple">
        <div className="table-filter-menu">
          <div className="item">
            <select
              className="form-select"
              aria-label="Default select"
              value={creditTypeId}
              onChange={(e) => {
                setCreditTypeId(e.target.value);
              }}
            >
              <option value={""}>
                {translate("HOMEPAGE.TITLES.SELECT_CREDIT_TYPE")}
              </option>
              {CertificateTypes?.length > 0 &&
                CertificateTypes?.map((item: any, index: number) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.title}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="item">
            <select
              className="form-select"
              aria-label="Default select"
              value={materialTypeId}
              onChange={(e) => {
                if (!e.target.value) {
                  setSubCategoryId("");
                }
                setMaterialTypeId(e.target.value);
                getMaterialSubCategory(e.target.value);
              }}
            >
              <option value={""}>
                {translate("HOMEPAGE.TITLES.SELECT_MATERIAL_TYPE")}
              </option>

              {materialTypeData?.length > 0 &&
                materialTypeData?.map((item: any, index: number) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="item">
            <select
              className="form-select"
              aria-label="Default select"
              disabled={!materialTypeId}
              value={subCategoryId}
              onChange={(e) => {
                setSubCategoryId(e.target.value);
              }}
            >
              <option value="">
                {translate("HOMEPAGE.TITLES.SELECT_MATERIAL_SUB_TYPE")}
              </option>
              {materialSubCategory.length > 0 &&
                materialSubCategory.map((u: any, i: any) => {
                  return (
                    <option key={u.id} value={u.id}>
                      {u.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="item input-min-width">
            <div className="input-search-item form-group">
              <input
                className="form-control"
                placeholder={
                  translate("HOMEPAGE.TITLES.ENTER_PLASTIC_CREDIT_ID") + ""
                }
                value={creditId}
                onChange={(e) => setCreditId(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performSearch(e);
                  }
                }}
              />

              <i className="fa fa-search"></i>
            </div>
          </div>

          <div className="item button-data-search">
            <Button
              href="#"
              title="Search"
              className="btn btn-outline-primary btn-icon"
              onClick={performSearch}
            >
              <i className="fa fa-search"></i>
            </Button>

            <Button
              href="#"
              title="Reset"
              className="btn btn-outline-primary btn-icon "
              onClick={(e) => {
                e.preventDefault();
                handeleReset();
              }}
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </Button>
          </div>
        </div>
        <InfiniteScroll
          dataLength={page * 10}
          next={fetchMoreData}
          hasMore={totalRecords > allLedgerList.length}
          loader={
            <p style={{ textAlign: "center" }}>
              <b>Loading...</b>
            </p>
          }
          height={
            allLedgerList?.length
              ? lazy_load_table_setting.lazy_Loading_table_Height
              : lazy_load_table_setting.lazy_Loading_No_Data_table_Height
          }
          className="table-wrapper"
        >
          <table ref={myRef}>
            <thead>
              {table.getHeaderGroups().map((headerGroup: any) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => {
                    return (
                      <td key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: " 🔼",
                              desc: " 🔽",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row: any) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {isLoading && <h3 className="center-message-item">Loading...</h3>}
          {allLedgerList.length === 0 && !isLoading && (
            <h3 className="center-message-item">
              {commanLabel.no_record_found}
            </h3>
          )}
          {isSchroll === true &&
            allLedgerList.length !== 0 &&
            page * commanLabel.pageLimit > totalRecords &&
            !isLoading && (
              <h3 className="center-message-item">
                {commanLabel.no_more_ledger}
              </h3>
            )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default LedgerList;

export interface ledgerInterface {
  pc_user?: any;
  plastic_credit_type?: any;
  fulfilled_qty?: number;
  pc_request_details: any;
  category: any;
  sub_category: any;
  created_on: any;
}
