import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DomainList from './domainList';
import { Link } from 'react-router-dom';
import AddDomain from './addDomainPopup';

const DomainManagement = () => {
  const { t: translate } = useTranslation();
  const [addentryPopup, setEntryPopup] = useState(false);
  const [reload, setReload] = useState(false);
  return (
    <>
      {addentryPopup && (
        <AddDomain
          title={`${translate('DOMAIN_MANAGEMENT.TITLES.ADD_DOMAIN')}`}
          handleCancel={() => setEntryPopup(false)}
          cancelText='Cancel'
          submitText='OK'
          hideCancelButton={true}
          setReload={setReload}
          handleSubmit={() => {
            setEntryPopup(false);
          }}
        />
      )}
      <div className='container'>
        <div className='body-content-main user-management'>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a href='/'>{translate('USER_MANAGEMENT.TITLES.HOME')}</a>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  {translate('DOMAIN_MANAGEMENT.TITLES.DOMAIN_MANAGEMENT')}
                </li>
              </ol>
            </nav>

            <div className='section-title-main'>
              <div className='title'>
                {translate('DOMAIN_MANAGEMENT.TITLES.DOMAIN_MANAGEMENT')}
              </div>
              <div className='form-o-submit'>
                <a
                  onClick={() => setEntryPopup(true)}
                  className='btn btn-primary'
                >
                  Add Domain
                </a>
              </div>
            </div>

            <div className='transaction-table listuser-table'>
              <div className='panel-head-title'>
                <div className='left-content'>
                  <div className='title-normal'>
                    {translate(
                      'DOMAIN_MANAGEMENT.TITLES.DOMAIN_MANAGEMENT_TEXT'
                    )}
                  </div>
                </div>
              </div>
              <DomainList setReload={setReload} reload={reload} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DomainManagement;
