import {
    commonInput,
    emailPatern,
    mobilePatern,
    commonAddress
  } from "../../../../constants/comman.validation.constant";

export  const valueChainPartnerValidator =  (errors: any, formInfos: any) => {
    let tempErrors = { ...errors};
    const getExpressionError = (value : any, expPattern: any, invalidErr: any, requiredErr: any) => {
        return value ? !new RegExp(expPattern).test(value) ? invalidErr : '' : requiredErr;
    }
    tempErrors = { 
        ...tempErrors, 
        email: getExpressionError(formInfos.email, emailPatern, 'ERRORS.INVALID.EMAIL_ADDRESS', 'ERRORS.REQUIRED.EMAIL'),
        contact_number: getExpressionError(formInfos.contact_number, mobilePatern, 'ERRORS.INVALID.CONTACT_NO', 'ERRORS.REQUIRED.CONTACT_NO'),
        name : formInfos.name ? '' : 'ERRORS.REQUIRED.FULL_NAME',
        description: getExpressionError(formInfos.description, commonAddress, "ERRORS.INVALID.DESCRIPTION", ''),
        country_id: formInfos.country_id ? "" : "ERRORS.REQUIRED.COUNTRY_ID",
        state_id: formInfos.state_id ? "" : "ERRORS.REQUIRED.STATE_ID",
        city_id: formInfos.city_id ? "" : "ERRORS.REQUIRED.CITY_ID",
        zip_code: formInfos.zip_code ? "" : "ERRORS.REQUIRED.ZIP_CODE",
        password:formInfos.password ? "" : "ERRORS.REQUIRED.PASSWORD",
        address:formInfos.address ? "" : "ERRORS.INVALID.ADDRESS",
        company_name:formInfos.company_name ? "" : "ERRORS.INVALID.COMPANY_NAME",
    }
    return tempErrors;
}