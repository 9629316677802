import {
  commonInput,
  emailPatern,
  mobilePatern,
  commanValidation,
} from '../../../constants/comman.validation.constant';
import {
  validationMessage,
  RequiredError,
} from '../../../constants/validation.message.constant';
import { commanLabel } from '../../../constants/comman.constant';

export const createEventValidator = (errors: any, eventInfo: any) => {
  let tempErrors = { ...errors };

  if (eventInfo.name) {
    if (!commonInput.test(eventInfo.name)) {
      tempErrors = {
        ...tempErrors,
        eventName: validationMessage.invalidEventName,
      };
    } else {
      tempErrors = { ...tempErrors, eventName: '' };
    }
  } else tempErrors = { ...tempErrors, eventName: RequiredError.eventName };

  if (eventInfo.organizer) {
    if (!commonInput.test(eventInfo.organizer)) {
      tempErrors = {
        ...tempErrors,
        organizer: validationMessage.invalidOrganiseName,
      };
    } else {
      tempErrors = { ...tempErrors, organizer: '' };
    }
  } else
    tempErrors = {
      ...tempErrors,
      organizer: RequiredError.organiseName,
    };

  if (eventInfo.region == '') {
    tempErrors = {
      ...tempErrors,
      region: RequiredError.regionId,
    };
  } else
    tempErrors = {
      ...tempErrors,
      region: '',
    };

  // if (eventCategoryId == '') {
  //   tempErrors = {
  //     ...tempErrors,
  //     eventCategory: RequiredError.eventCategory,
  //   };
  // } else
  //   tempErrors = {
  //     ...tempErrors,
  //     eventCategory: '',
  //   };

  return tempErrors;
};
