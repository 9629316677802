import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export interface ConfirmationData {
    handleCancel: any;
    handleSubmit: any;
    title: string;
    cancelText?: string;
    submitText?: string;
    hideCancelButton?: boolean;
    hidePdf?: boolean;
}

const ConfirmPopup = (props: ConfirmationData) => {
    const [show, setShow] = useState(true);
    const handleClose = () => {
        setShow(false);
        props.handleCancel();
    };
    const handleOk = () => {
        setShow(false);
        props.handleSubmit(selectedOption, selectedBlur);
    };
    const [selectedOption, setSelectedOption] = useState(props.hidePdf?'excel':'pdf');
    const [selectedBlur, setSelectedBlur] = useState('true');
    const { t: translate } = useTranslation();

    return (
        <>
            <Modal className="modal small-popup export-popup" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="usermanagement-modal pb-3 pt-4">
                {props.hidePdf !== true &&
                    <p className="mb-2">{translate('CSR_MODULE.TITLES.EXPORT_NOTE')}</p>
                }
                {props.hidePdf === true &&
                    <p className="mb-2">{translate('CSR_MODULE.TITLES.EXPORT_TRANSACTION_NOTE')}</p>
                }
                    <div className="row">
                    {props.hidePdf !== true &&
                        <div className="col-12 col-sm-12 col-md-12">
                            <div className="form-group required mb-2">
                                <div className="radio-buttons no-req">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="material_color" id="natural-button-icon" value="true" checked={selectedBlur === 'true'}
                                            onChange={(e) => setSelectedBlur(e.target.value)} />
                                        <label className="form-check-label" htmlFor="natural-button-icon">{translate('CSR_MODULE.TITLES.YES')}</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="material_color" id="color-button-icon" value="false" checked={selectedBlur === 'false'}
                                            onChange={(e) => setSelectedBlur(e.target.value)} />
                                        <label className="form-check-label" htmlFor="color-button-icon">{translate('CSR_MODULE.TITLES.NO')}</label>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                        </div>
                        }
                        <div className="row ps-0 pe-0">
                            <div className="col-6 col-sm-6 col-md-6">

                            </div>
                            <div className="col-6 col-sm-6 col-md-6 pe-0">
                                <div className="row d-flex justify-content-end">
                                    {props.hidePdf !== true &&
                                    <div className="col-12 col-sm-5 col-md-5">
                                        <div className="form-check ps-0">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck7" value="pdf" checked={selectedOption === 'pdf'}
                                            onChange={(e) => setSelectedOption(e.target.value)} />
                                            <label className="form-check-label" htmlFor="defaultCheck7">
                                                <img src='/assets/images/export-pdf.svg' alt='#' />
                                            </label>
                                        </div>
                                    </div>
                                    }
                                    <div className="col-12 col-sm-5 col-md-5">
                                        <div className="form-check ps-0">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck8" value="excel" checked={selectedOption === 'excel' }
                                            onChange={(e) => setSelectedOption(e.target.value)} />
                                            <label className="form-check-label" htmlFor="defaultCheck8">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="31.108" height="28" viewBox="0 0 31.108 28">
                                                    <g id="MS_Excel" data-name="MS Excel" transform="translate(71 48)">
                                                        <g id="icons8-microsoft-excel-2019" transform="translate(-71 -48)">
                                                            <path id="Path_8293" data-name="Path 8293" d="M25.666,6H15.356A1.356,1.356,0,0,0,14,7.356V13H25.666Z" transform="translate(-6.223 -6)" fill="#169154" />
                                                            <path id="Path_8294" data-name="Path 8294" d="M14,33.054v5.6a1.356,1.356,0,0,0,1.356,1.356h10.31v-6.96Z" transform="translate(-6.223 -12.014)" fill="#18482a" />
                                                            <path id="Path_8295" data-name="Path 8295" d="M14,15H25.666v7H14Z" transform="translate(-6.223 -8)" fill="#0c8045" />
                                                            <path id="Path_8296" data-name="Path 8296" d="M14,24H25.666v7.038H14Z" transform="translate(-6.223 -10.002)" fill="#17472a" />
                                                            <g id="Group_15070" data-name="Group 15070" transform="translate(19.443 0)">
                                                                <path id="Path_8297" data-name="Path 8297" d="M39.309,6H29v7H40.666V7.356A1.356,1.356,0,0,0,39.309,6Z" transform="translate(-29 -6)" fill="#29c27f" />
                                                                <path id="Path_8298" data-name="Path 8298" d="M29,33.054v6.957H39.31a1.356,1.356,0,0,0,1.356-1.354v-5.6H29Z" transform="translate(-29 -12.014)" fill="#27663f" />
                                                                <path id="Path_8299" data-name="Path 8299" d="M29,15H40.666v7H29Z" transform="translate(-29 -8)" fill="#19ac65" />
                                                                <path id="Path_8300" data-name="Path 8300" d="M29,24H40.666v7.038H29Z" transform="translate(-29 -10.002)" fill="#129652" />
                                                            </g>
                                                            <path id="Path_8301" data-name="Path 8301" d="M18.247,29.554H5.307A1.308,1.308,0,0,1,4,28.247V15.307A1.308,1.308,0,0,1,5.307,14h12.94a1.308,1.308,0,0,1,1.307,1.307v12.94A1.308,1.308,0,0,1,18.247,29.554Z" transform="translate(-4 -7.778)" fill="#0c7238" />
                                                            <path id="Path_8302" data-name="Path 8302" d="M9.744,19H11.6l1.506,2.916L14.694,19h1.737l-2.388,3.889,2.443,3.889H14.656l-1.641-3.056-1.634,3.056H9.526l2.483-3.9Z" transform="translate(-5.229 -8.89)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="pb-4">
                    <div className="row ms-0 me-0">
                        <div className="col-12 ps-0 pe-0">
                            <a role="button" href="#" className="w-100 btn btn-primary btn-sm btn btn-primary" onClick={handleOk}>
                                {translate('CSR_MODULE.TITLES.DOWNLOAD')}
                            </a>
                        </div>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
};
export default ConfirmPopup;
