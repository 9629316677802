import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import Pagination from 'react-bootstrap/Pagination';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import {
  commanLabel,
  getUserType,
  UserTypes,
  valueChainPartnerContactData,
  valueChainPartnerRequestTypes,
} from '../../../constants/comman.constant';
import ConfirmationPopup from '../../../components/confirmationPopup';
import { useApproveOrRejectValueProfileMutation } from '../../../app/api/services/valueChainPartner.service';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';

import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { getValue } from '@testing-library/user-event/dist/utils';
import { validationMessage } from '../../../constants/validation.message.constant';
import { useTranslation } from 'react-i18next';
import { getNoRecordMessage } from '../../../utils/helper/common';
import { UserProfileStatus } from '../../../shared/remake-green-utils';

const ValueChainPartnerList = () => {
  const { t: translate } = useTranslation();

  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [searchedText, setSearchedText] = useState('');
  const [selectedTeb, setSelectedTeb] = useState(0);
  const [roleTypeId, setRoleTypeId] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [submitPopup, setSubmitPopup] = useState({ isOpen: false, id: null });

  const [valueChainPartnerList, setValueChainPartnerList] = useState([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [itemCount, setItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [approveOrRejectProfile] = useApproveOrRejectValueProfileMutation();
  const columnHelper = createColumnHelper<any>();
  const noRecordFoundMessage = translate('COMMONFIELDS.NO_RECORD_FOUND_FOR');
  const columns = [
    columnHelper.accessor((row) => `${row?.full_name ? row?.full_name : '-'}`, {
      id: `${translate('USER_MANAGEMENT.TITLES.FULL_NAME')}`,
    }),
    columnHelper.accessor((row) => `${row?.email ? row?.email : '-'}`, {
      id: `${translate('USER_MANAGEMENT.TITLES.EMAIL')}`,
    }),
    columnHelper.accessor(
      (row) => `${row?.company_name ? row?.company_name : '-'}`,
      {
        id: `${translate('USER_MANAGEMENT.TITLES.COMPANY_NAME')}`,
      }
    ),

    columnHelper.accessor(
      (row) => `${row?.contact_number ? row?.contact_number : '-'}`,
      {
        id: `${translate('USER_MANAGEMENT.TITLES.CONTACT_NUMBER')}`,
      }
    ),

    columnHelper.accessor(
      `${translate('USER_MANAGEMENT.TITLES.DESCRIPTION')}`,
      {
        header: () => `${translate('USER_MANAGEMENT.TITLES.DESCRIPTION')}`,
        cell: (props: any) => {
          const id = props.row.original.id;
          return (
            <React.Fragment>
              <p className='mb-0 description'>
                <a
                  // href=""
                  className='description-btn'
                  id='desc-btn-1'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='click to show full description'
                  onClick={() => {
                    let data = document.getElementById(`desc-content-${id}`);
                    if (data?.classList.contains('active')) {
                      data.classList.remove('active');
                    } else {
                      data && data?.classList?.add('active');
                    }
                  }}
                >
                  {props?.row?.original?.description}
                </a>
              </p>
            </React.Fragment>
          );
        },
      }
    ),

    columnHelper.accessor("-", {
      header: () => `${translate("USER_MANAGEMENT.TITLES.ACTION")}`,
      enableSorting: false,
      cell: (props: any) => {
        let id = props?.row?.original?.id;
        let status = props?.row?.original?.status;
        let pendingStatus = 0;
        return (
          <React.Fragment>
            <div className="icon-action">
              <div className="icon-view icon">
                
                  <Link
                    to={`/admin/view-valuechain-profile/${id}`}
                    title="View/Update"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/file-edit.svg`}
                      alt="#"
                    />
                  </Link>
                
              </div>
            </div>
          </React.Fragment>
        );
      },
    }),
  ];

  

  const getData = async (reset: boolean, status: number) => {
    setIsLoading(true);
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      status: status,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      searchText: reset ? '' : searchedText,
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),
    };
    let path = apiUrl.getValueChainPartnerList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setValueChainPartnerList(res.data.value_chain_partners);
      setItemCount(res?.data?.total);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  const getPagination = () => {
    let items = [];
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item
          activeLabel=''
          key={number}
          active={number === page}
          onClick={(e) => {
            e.preventDefault();
            setPage(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  useEffect(() => {
    getData(false, selectedTeb);
  }, [page, pageLimit]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: valueChainPartnerList,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const handleReset = () => {
    setPage(1);
    setSearchedText('');
    setRoleTypeId('');
    setDateRange([null, null]);
    getData(true, selectedTeb);
  };
  const performSearch = (e: any) => {
    e.preventDefault();
    getData(false, selectedTeb);
  };
  return (
    <div>
      {/* {submitPopup.isOpen === true && (
        <ConfirmationPopup
          title='Submit'
          message='Are you sure you want to Contact?'
          cancelText='Cancel'
          submitText='Proceed'
          handleCancel={() => setSubmitPopup({ isOpen: false, id: null })}
          handleSubmit={() => {
            approveOrRejectProfileFun(submitPopup.id);
          }}
        />
      )} */}
      <div className='value-chain-partner'>
        <ul className='nav nav-tabs' id='' role='tablist'>
          {valueChainPartnerRequestTypes.map((data, i) => {
            return (
              <li className='nav-item'>
                <a
                  className={
                    selectedTeb === data.value ? 'nav-link active' : 'nav-link '
                  }
                  id='pc-request1-tab'
                  data-toggle='tab'
                  role='tab'
                  aria-controls='pc-request1'
                  aria-selected='true'
                  onClick={() => {
                    setSelectedTeb(data.value);
                    setSearchedText('');
                    setDateRange([null, null]);
                    getData(true, data.value);
                  }}
                >
                  {data.name}
                </a>
              </li>
            );
          })}
        </ul>
        <div className='tab-content' id=''>
          <div
            className='tab-pane fade show active'
            id='vcp-request1'
            role='tabpanel'
            aria-labelledby='pc-request1-tab'
          >
            <div className='listing-transaction'>
              <div className='top-filters three-type'>
                <div className='date-item-picker item-in'>
                  <div className='date-picker-main'>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      value={startDate ? startDate : ''}
                      className='form-control'
                      placeholderText='Select Date Range'
                      dateFormat={commanLabel.dateFormat}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      onChange={(update: any) => {
                        setDateRange(update);
                      }}
                    />
                  </div>
                </div>

                <div className='type-select item-in'>
                  <div className='item input-min-width'>
                    <div className='input-search-item form-group'>
                      <label
                        // for="searchicon"
                        className='searchicon'
                      >
                        <i className='fa fa-search'></i>
                      </label>
                      <input
                        className='form-control'
                        id='searchicon'
                        placeholder='Search'
                        value={searchedText}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            performSearch(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='search-button item-in'>
                  <Button
                    className='btn  btn-sm btn-outline-primary btn-icon'
                    type='button'
                    title='Search'
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(1);
                      page === 1 && getData(false, selectedTeb);
                    }}
                  >
                    <i className='fa fa-search' aria-hidden='true'></i>
                  </Button>
                  <Button
                    className='btn  btn-sm  btn-outline-primary btn-icon'
                    title='Reset'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      handleReset();
                    }}
                  >
                    <i className='fa fa-refresh' aria-hidden='true'></i>
                  </Button>
                </div>
              </div>

              <div className='listing-content'>
                <div className='table-list'>
                  <table>
                    <thead>
                      {table.getHeaderGroups().map((headerGroup: any) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header: any) => {
                            return (
                              <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                  <div
                                    {...{
                                      className: header.column.getCanSort()
                                        ? 'cursor-pointer select-none'
                                        : '',
                                      onClick:
                                        header.column.getToggleSortingHandler(),
                                    }}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: ' 🔼',
                                      desc: ' 🔽',
                                    }[header.column.getIsSorted() as string] ??
                                      null}
                                  </div>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>

                    {valueChainPartnerList?.length > 0 ? (
                      <tbody>
                        {table.getRowModel().rows.map((row: any) => {
                          return (
                            <>
                              <tr key={row.id}>
                                {row.getVisibleCells().map((cell: any) => {
                                  return (
                                    <td
                                      key={cell.id}
                                      className={
                                        cell.column.id ===
                                        `${translate(
                                          'USER_MANAGEMENT.TITLES.FULL_NAME'
                                        )}`
                                          ? 'hide_overlapping'
                                          : ''
                                      }
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                              {row &&
                                row?.original &&
                                row?.original?.description && (
                                  <tr
                                    id={`desc-content-${row.original.id}`}
                                    className='description-row'
                                  >
                                    <td colSpan={6} className='description-td'>
                                      <p className='description-content'>
                                        {row?.original?.description}
                                      </p>
                                    </td>
                                  </tr>
                                )}
                            </>
                          );
                        })}
                      </tbody>
                    ) : (
                      <>
                        <tbody>
                          <tr>
                            <td colSpan={9}>
                              {isLoading && (
                                <h3 className='center-message-item'>
                                  {translate('USER_MANAGEMENT.TITLES.LOADING')}
                                </h3>
                              )}
                              {valueChainPartnerList?.length === 0 &&
                                !isLoading && (
                                  <h3 className='center-message-item'>
                                    {getNoRecordMessage(
                                      noRecordFoundMessage,
                                      valueChainPartnerRequestTypes,
                                      selectedTeb
                                    )}
                                  </h3>
                                )}
                            </td>
                          </tr>
                        </tbody>
                      </>
                    )}

                    <tfoot className='footer-table'>
                      <tr>
                        <td>
                          {/* {!isLoading && ( */}
                          {valueChainPartnerList?.length > 0 && (
                            <div className='left-item text-left'>
                              Total Records:{' '}
                              {`${
                                valueChainPartnerList.length +
                                commanLabel.pageLimit * (page - 1)
                              }`}{' '}
                              / {`${itemCount}`}
                            </div>
                          )}
                          {/* )} */}
                        </td>

                        {valueChainPartnerList?.length > 0 && pageCount > 1 && (
                          <td>
                            <div className='right-item'>
                              <div className='items-center pagination-main text-center'>
                                <button
                                  className='border rounded p-1'
                                  onClick={() => {
                                    table.previousPage();
                                    setPage(page - 1);
                                  }}
                                  disabled={!table.getCanPreviousPage()}
                                >
                                  <i
                                    className='fa fa-angle-left'
                                    aria-hidden='true'
                                  ></i>
                                </button>
                                <Pagination size='sm'>
                                  {getPagination()}
                                </Pagination>

                                <button
                                  className='border rounded p-1'
                                  onClick={() => {
                                    table.nextPage();
                                    setPage(page + 1);
                                  }}
                                  disabled={!table.getCanNextPage()}
                                >
                                  <i
                                    className='fa fa-angle-right'
                                    aria-hidden='true'
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValueChainPartnerList;
