export const  disableScroll = () => {
    var sheet = document.createElement("style");
    sheet.type = "text/css";
    sheet.innerHTML = "body{overflow:hidden;}";
    document.body.appendChild(sheet);
}

export const enableScroll = () => {    
    var sheet = document.createElement("style");
    sheet.type = "text/css";
    sheet.innerHTML = "body{overflow:auto;}";
    document.body.appendChild(sheet);
}