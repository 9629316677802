export const commanValidation = {
  mobileMaxLength: 9999999999,
  mobileMinLength: 999999999,
  numberPattern: '/[1-4]/g',
  kgMin: 1,
  tonneMin: 0.001,
  buyerMinKg: 1000,
  buyerMinTonne: 1,
  maxQty: 100000000,
  hsnMax: 20,
  maxLength: 50,
  maxLengthAddress: 500,
  maxLengthReference: 30,
  maxLengthPoReference: 200,
  minLengthEmail: 3,
  maxLengthCreditPoint: 100000,
  maxOtp: 100000,
  maxFileSize: 5000000,
  maxFileLength: 5,
  maxZipCode: 20,
  maxLengthReason: 1000,
};

export const commanSwitchCase = {
  email: 'email',
  mobile_no: 'mobile_no',
  name: 'name',
  material_type_id: 'material_type_id',
  material_name_type_id: 'material_name_type_id',
  region_id: 'region_id',
  quantity: 'quantity',
  all: 'all',
};

export const passwordRuleValidation = {
  lowerCase: /[a-z]/,
  upperCase: /[A-Z]/,
  number: /(?=.*?[0-9])/,
  specialCharacter: /(?=.*[@#$%^&+=])/,
  characterLength: /^.{8,50}$/,
};

export const commonInput = /^[^<>;]+$/;
export const aadhaarNumber = /^[0-9]{12}$/;

export const commonAddress = /^[^<>;]+$/;
export const emailPatern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const mobilePatern = /^[0-9]{10}$/;
export const otpPatern = /^[0-9]{6}$/;
export const passwordPatern =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[@#$%^&+=]).{8,50}$/;
