import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import React from "react";

const columnHelper = createColumnHelper<any>();
export const getTableHeader = (selectedValues: any[], onSelect: (data: any, id: any) => void) => [
  columnHelper.accessor("transactionId", {
    header: () => "Transaction ID",
    cell: (props: any) => {
      const { id } = props?.row?.original;
      const data = props?.row?.original;
      return (
        <React.Fragment>
          <div
            className="form-check"
            style={{ paddingLeft: 0 }}
            onClick={() => {
              onSelect(data, id);
              // if (selectedTransaction.length === 0) {
              //   setSelectedTransaction([{ ...data }]);
              // } else if (selectedTransaction.length > 0) {
              //   const lastItem =
              //     selectedTransaction[selectedTransaction.length - 1];

              //   if (selectedTransactionId.includes(id)) {
              //     const newArray = selectedTransaction.filter((data: any) => {
              //       return data.id !== id;
              //     });

              //     setSelectedTransaction([...newArray]);
              //   } else {
              //     if (lastItem.materialType.id === data.materialType.id) {
              //       if (
              //         lastItem.buyer.id === data.seller.id &&
              //         lastItem.buyer.category === data.seller.category
              //       ) {
              //         setSelectedTransaction([
              //           ...selectedTransaction,
              //           { ...data },
              //         ]);
              //       } else {
              //         setValidationPopup(true);
              //         setValidationMessage(validationMessageText.transaction);
              //       }
              //     } else {
              //       setValidationPopup(true);
              //       setValidationMessage(validationMessageText.material);
              //     }
              //   }
              // }
            }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="tr1"
              onChange={() => { }}
              checked={selectedValues.includes(id)}
            />
            <label
              className="form-check-label"
            // for="tr1"
            >
              {props?.row?.original.transactionId}
            </label>
          </div>
        </React.Fragment>
      );
    },
  }),

  columnHelper.accessor((u) => u.seller.name, {
    id: "Seller",
  }),
  columnHelper.accessor((u) => u.buyer.name, {
    id: "Buyer",
  }),
  columnHelper.accessor(
    (u) => `${u.unloadedQuantity.quantity} ${u.unloadedQuantity.unit}`,
    {
      id: "Unloaded Qty.",
    }
  ),
  columnHelper.accessor(
    (u) => moment(
      `${u?.invoiceDate
        ? u?.invoiceDate
        : '-'
      }`
    ).format('DD/MM/YYYY'),
    {
      id: "Invoice Date",
      enableSorting: false
    }
  ),
  columnHelper.accessor(
    (u) => `${u?.invoiceReferenceNumber}`,
    {
      id: "Reference",
      enableSorting: false
    }
  ),
  columnHelper.accessor((u) => u.materialType.name, {
    id: "Material Type",
    enableSorting: false,
  }),
];
