import {
  commonInput,
  emailPatern,
  mobilePatern,
  commanValidation,
  aadhaarNumber,
} from '../../../constants/comman.validation.constant';
import {
  validationMessage,
  RequiredError,
} from '../../../constants/validation.message.constant';
import { commanLabel } from '../../../constants/comman.constant';

export const createActivityValidator = (
  errors: any,
  eventInfo: any,
  reclaimerInfo: any,
  activityInfo: any,
  eventCategoryId: any,
  activityCategory: any
) => {
  let tempErrors = { ...errors };
  console.log('eventInfo:::', activityInfo, eventCategoryId, activityCategory);
  if (eventInfo.name) {
    if (!commonInput.test(eventInfo.name)) {
      tempErrors = {
        ...tempErrors,
        eventName: validationMessage.invalidEventName,
      };
    } else {
      tempErrors = { ...tempErrors, eventName: '' };
    }
  } else tempErrors = { ...tempErrors, eventName: RequiredError.eventName };

  if (eventInfo.organizer) {
    if (!commonInput.test(eventInfo.organizer)) {
      tempErrors = {
        ...tempErrors,
        organizer: validationMessage.invalidOrganiseName,
      };
    } else {
      tempErrors = { ...tempErrors, organizer: '' };
    }
  } else
    tempErrors = {
      ...tempErrors,
      organizer: RequiredError.organiseName,
    };

  if (eventInfo.region == '') {
    tempErrors = {
      ...tempErrors,
      region: RequiredError.regionId,
    };
  } else
    tempErrors = {
      ...tempErrors,
      region: '',
    };

  if (eventCategoryId == '') {
    tempErrors = {
      ...tempErrors,
      eventCategory: RequiredError.eventCategory,
    };
  } else
    tempErrors = {
      ...tempErrors,
      eventCategory: '',
    };

  if (activityCategory == '') {
    tempErrors = {
      ...tempErrors,
      activityCategory: RequiredError.activityCategory,
    };
  } else
    tempErrors = {
      ...tempErrors,
      activityCategory: '',
    };

  if (activityInfo.describe) {
    if (!commonInput.test(activityInfo.describe)) {
      tempErrors = {
        ...tempErrors,
        describe: validationMessage.invalidDescribe,
      };
    } else {
      tempErrors = { ...tempErrors, describe: '' };
    }
  } else
    tempErrors = {
      ...tempErrors,
      describe: RequiredError.describe,
    };

  if (activityInfo.impact_description) {
    if (!commonInput.test(activityInfo.impact_description)) {
      tempErrors = {
        ...tempErrors,
        imapctDescription: validationMessage.invalidImpactDescription,
      };
    } else {
      tempErrors = { ...tempErrors, imapctDescription: '' };
    }
  } else
    tempErrors = {
      ...tempErrors,
      imapctDescription: RequiredError.impactDescription,
    };

  if (reclaimerInfo.name) {
    if (!commonInput.test(reclaimerInfo.name)) {
      tempErrors = {
        ...tempErrors,
        reclaimerName: validationMessage.invalidReclaimerName,
      };
    } else {
      tempErrors = { ...tempErrors, reclaimerName: '' };
    }
  } else
    tempErrors = {
      ...tempErrors,
      reclaimerName: RequiredError.reclaimerName,
    };

  if (reclaimerInfo.reference_of !== '') {
    console.log('Inside Ifff :::', reclaimerInfo.reference_of);

    if (reclaimerInfo.reference_value) {
      if (!commonInput.test(reclaimerInfo.reference_value)) {
        tempErrors = {
          ...tempErrors,
          referenceValue: validationMessage.invalidReferenceOf,
        };
      } else {
        tempErrors = { ...tempErrors, referenceValue: '' };
      }
    } else
      tempErrors = {
        ...tempErrors,
        referenceValue: RequiredError.referenceValue,
      };
  }

  return tempErrors;
};
