import { plasticCreditApi } from "..";
import { apiUrl } from "./apiUrl";

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({
    getValueChainPartner: builder.query({
      query: (id) => apiUrl.getValueChainPartners,
    }),

    approveOrRejectValueProfile: builder.mutation<any, any>({
      query(body) {
        return {
          url: apiUrl.approveOrReject,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetValueChainPartnerQuery,
  useApproveOrRejectValueProfileMutation
} = extendedApi;
