import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { privacyPDF, routesConstant } from "../../../../constants/comman.constant";
export const Footer = () => {
  const { t: translate } = useTranslation();
  return (
    <footer>
      <div className="footer-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12  f-col">
              <div className="detail-item">
                <Link to={routesConstant.aboutUs} className="footer-title">
                  {translate("HOMEPAGE.TITLES.ABOUT_REMAKE")}
                </Link>
                <h5 className="description">
                  {translate("HOMEPAGE.TITLES.ABOUT_REMAKE_CONTENT")}
                </h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  f-col">
              <div className="footer-links">
                <h3 className="footer-title">{translate("HOMEPAGE.TITLES.COMPANY")}</h3>
                <div className="footer-links">
                  <ul>
                    <li>
                      <Link to={routesConstant.sdgGoals}>{translate("BUTTONS.SDG_GOALS")}</Link>
                    </li>
                    <li>
                      <a href={privacyPDF} target="_blank" rel="noreferrer">{translate("BUTTONS.PRIVACY")}</a>
                    </li>
                    <li>
                      <Link to={routesConstant.aboutUs}>{translate("BUTTONS.ABOUT_US")} </Link>
                    </li>
                    <li>
                      <Link to={routesConstant.faq}>{translate("BUTTONS.FAQ")}</Link>
                    </li>
                    {/* <li>
                      <Link to={routesConstant.comingSoon}>{translate("BUTTONS.RESOURCES")}</Link>
                    </li>

                    <li>
                      <Link to={routesConstant.comingSoon}>{translate("BUTTONS.CONTACT_US")}</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 f-col">
              <div className="contact">
                <h3 className="footer-title">{translate("BUTTONS.CONTACT_US")}</h3>

                <div className="contact-links">
                  {" "}
                  <a href="mailto:info@remake.green"><i className="fa fa-envelope" aria-hidden="true"></i>info@remake.green</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-in">
            <ul>
              <li>
                <Link to={routesConstant.comingSoon}>
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
              </li>
              <li>
                <Link to={routesConstant.comingSoon}>
                  <i className="fa-brands fa-twitter"></i>
                </Link>
              </li>
              <li>
                <Link to={routesConstant.comingSoon}>
                  <i className="fa-brands fa-instagram"></i>
                </Link>
              </li>
              <li>
                <Link to={routesConstant.comingSoon}>
                  <i className="fa-brands fa-youtube"></i>
                </Link>
              </li>
            </ul>

            <div className="copyright-tag">© 2022 — {translate("HOMEPAGE.TITLES.ALL_RIGHTS_RESERVED")}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};
