import React, { useState, useEffect } from 'react';
import 'chart.js/auto';

import { Pie, Bar } from 'react-chartjs-2';

import { Chart as ChartJS } from 'chart.js/auto';
import { apiUrl } from '../../app/api/services/apiUrl';
import { helper } from '../../app/api/api.helper';
import { toast } from 'react-toastify';
import { convertQuantityToKg } from '../../shared/remake-green-utils';

const ChartStatistics = () => {
  const [chartData, setChartData] = useState<any>(null);
  const [creditData, setCreditData] = useState<any>(null);
  const [creditCountData, setCreditCountData] = useState<any>(null);

  const [quantityData, setQuantityData] = useState<any>(null);
  const [unloadQuantityData, setUnloadQuantityData] = useState<any>(null);
  const [materialTypeCountData, setMaterialTypeCountData] = useState<any>(null);

  useEffect(() => {
    if (chartData) {
      setCreditData({
        labels: ['Collection Credit', 'Circular Credit', 'Recycle Credit'],
        datasets: [
          {
            label: 'Certificate Generated',
            data: [
              chartData?.typesOfCredit.collectionCredit,
              chartData?.typesOfCredit.circularCredit,
              chartData?.typesOfCredit.recycleCredit,
            ],
            backgroundColor: ['#50AF95', '#f3ba2f', '#2a71d0'],
            borderColor: 'white',
            borderWidth: 2,
          },
        ],
      });

      setCreditCountData({
        labels: ['Collection Credit', 'Circular Credit', 'Recycle Credit'],
        datasets: [
          {
            label: 'Credit Generated',
            data: [
              chartData?.creditCount.collectionCredit,
              chartData?.creditCount.circularCredit,
              chartData?.creditCount.recycleCredit,
            ],
            backgroundColor: ['#50AF95', '#f3ba2f', '#2a71d0'],
            borderColor: 'white',
            borderWidth: 2,
          },
        ],
      });

      setQuantityData({
        labels: [
          'Generated Certificate',
          'Linked Transactions',
          'Unlinked Transactions',
        ],
        datasets: [
          {
            label: 'Count',
            data: [
              chartData?.certificateIssued,
              chartData?.linkedtransactionQuantity,
              chartData?.unlinkedTransactionQuantity,
            ],
            backgroundColor: ['#50AF95', '#f3ba2f', '#2a71d0'],
            borderColor: 'white',
            borderWidth: 2,
          },
        ],
      });

      const newArray = chartData?.unlinkedTransactionDetails?.map(
        (u: any, i: any) => {
          return {
            quantity: +u.details.unloading_weight_slip_details.quantity,
            unit: u.details.unloading_weight_slip_details.measurement_types,
          };
        }
      );

      const linkedNewArray = chartData?.linkedTransactionDetails?.map(
        (u: any, i: any) => {
          return {
            quantity: +u.unloaded_quantity,
            unit: u.unloaded_quantity_unit,
          };
        }
      );

      let unlinkedTransactionUnloadQuantitySum = 0;

      // iterate over each item in the array
      for (let i = 0; i < newArray.length; i++) {
        unlinkedTransactionUnloadQuantitySum += convertQuantityToKg(
          newArray[i]
        );
      }

      let linkedTransactionUnloadQuantitySum = 0;

      // iterate over each item in the array
      for (let i = 0; i < linkedNewArray.length; i++) {
        linkedTransactionUnloadQuantitySum += convertQuantityToKg(
          linkedNewArray[i]
        );
      }

      setUnloadQuantityData({
        labels: [
          'UnLinked Transaction Unload Quantity (KG)',
          'Linked Transaction Unload Quantity (KG)',
        ],
        datasets: [
          {
            label: 'Quantity',
            data: [
              unlinkedTransactionUnloadQuantitySum,
              linkedTransactionUnloadQuantitySum,
            ],
            backgroundColor: ['#50AF95', '#f3ba2f', '#2a71d0'],
            borderColor: 'white',
            borderWidth: 2,
          },
        ],
      });

      setMaterialTypeCountData({
        labels: chartData.materialType?.map((data: any) => data.name),
        datasets: [
          {
            label: 'Generated Certificate',
            data: chartData.materialType?.map((data: any) => data.count),
            backgroundColor: [
              'rgba(75,192,192,1)',
              '#ecf0f1',
              '#50AF95',
              '#f3ba2f',
              '#2a71d0',
            ],
            borderColor: 'white',
            borderWidth: 2,
          },
        ],
      });
    }

    // const plasticCount;
    // const aluminiumCount;
    // const stainlessSteelCount;
  }, [chartData]);

  const getChartDetails = async () => {
    let path = apiUrl.getChartDetails;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let countries = res?.data && res?.data;
      setChartData(countries);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    getChartDetails();
  }, []);

  return (
    <>
      {/* <Chart type='line' data={chartData} /> */}
      {chartData && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              background: 'white',
              paddingTop: '20px',
            }}
          >
            <div
              style={{
                width: 350,
                height: 300,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {creditData && (
                <Pie
                  data={creditData}
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        position: 'bottom',
                        text: 'Generated Certificate details based on Credit Type',
                        align: 'center',
                        font: { size: 14 },
                        padding: {
                          top: 20,
                          bottom: 10,
                        },
                      },
                    },
                  }}
                />
              )}
              {/* <div>Generated Certificate details based on Credit Type</div> */}
            </div>

            <div
              style={{
                width: 350,
                height: 300,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {creditCountData && (
                <Pie
                  data={creditCountData}
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        position: 'bottom',
                        text: 'Generated Credit details based on Credit Type',
                        align: 'center',
                        font: { size: 14 },
                        padding: {
                          top: 20,
                          bottom: 10,
                        },
                      },
                    },
                  }}
                />
              )}
              {/* <div>Generated Certificate details based on Credit Type</div> */}
            </div>

            <div
              style={{
                width: 500,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              {quantityData && (
                <Bar
                  data={quantityData}
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        position: 'bottom',
                        text: 'Transaction Details',
                        align: 'center',
                        font: { size: 14 },
                        padding: {
                          top: 20,
                          bottom: 10,
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              background: 'white',
              paddingTop: '20px',
              marginBottom: '20px',
              paddingBottom: '20px',
            }}
          >
            <div
              style={{
                width: 550,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              {unloadQuantityData && (
                <Bar
                  data={unloadQuantityData}
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        position: 'bottom',
                        text: 'Unload Quantity Details',
                        align: 'center',
                        font: { size: 14 },
                        padding: {
                          top: 20,
                          bottom: 10,
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
            <div
              style={{
                width: 550,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              {materialTypeCountData && (
                <Bar
                  data={materialTypeCountData}
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        position: 'bottom',
                        text: 'Generated Certificate details based on Material Type',
                        align: 'center',
                        font: { size: 14 },
                        padding: {
                          top: 20,
                          bottom: 10,
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChartStatistics;
