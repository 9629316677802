export const apiUrl = {
  //Login
  login: 'authentication/signin/user',
  //Certificate List
  getCertificateList: 'plastic-credit-request-management/plastic-credit/list',
  //Trasaction
  getTrasactionUnlinkedList: 'transaction-managment/transaction/list',
  getTrasactionLinkedList:
    'transaction-managment/transaction/linkedTransactionList',
  listforducument: 'transaction-managment/transaction/listforducument',
  getTrasactionListById:
    'transaction-managment/transaction/getTransactionById?Id=',
  getTransactionsListByIds:
    'transaction-managment/transaction/transactionsDetailsById',
  getTransactionByValueChain:
    'transaction-managment/transaction/listByValueChainPartner?partnerId=',
  //Comman Api
  getAssociatedProjects:
    'transaction-managment/transaction/getAssociatedProjects',
  getValueChainPartners: 'user-managment/user/valueChainPartners',
  getUserList: 'user-profile/user/registeredUserList',
  getUserDetail: 'user-profile/user/getUserDetail',
  getAllSetting: 'get-settings/settings/list',
  getRegions: 'get-settings/setting/getRegions',
  getCountries: 'get-settings/setting/getCountries',
  getStates: 'get-settings/setting/getState?country_id=',
  getCities: 'get-settings/setting/getCities?state_id=',
  getMaterialSubCategory: 'get-settings/setting/getMaterialSubtypeById?Id=',

  //File Upload
  file_upload: 'fileUpload_controller/file/upload',
  file_delete: 'fileUpload_controller/file/delete',
  //plastic Credit Certificate Web
  getRemakeCertificate:
    'plastic-credit-request-management/remake-certificate/list',
  getPlasticCreditDetailsByID:
    'plastic-credit-request-management/plastic-credit/request/details/',
  getPlasticCreditWedDetails:
    'plastic-credit-request-management/plastic-credit/detail/',
  getCertificateImages:
    'plastic-credit-request-management/certificate-images/list',
  prepareFile:
    'plastic-credit-request-management/plastic-credit/prepare-dowload-file?url=',
  downloadFile:
    'plastic-credit-request-management/plastic-credit/dowload-file?url=',
  reCreatePdf: 'plastic-credit-request-management/plastic-credit/reCreatePdf',
  codelogin: 'plastic-credit-request-management/plastic-credit/code-login',
  // User Inquiry
  createUserInquiry: 'landing-page/inquiry/create',
  getLedgerList: 'landing-page/ledger/get',

  // Register User
  sendOtp: 'authentication/user/sendOtp',
  verifyOtp: 'authentication/user/verifyOtp',
  createRemakeUser: 'authentication/signup/user',
  // Value chain partner
  createValueChainPartner: '/user-managment/user/valueChainPartner/create',
  getValueChainPartnerList: 'user-managment/user/valueChainPartnerList',
  approveOrReject: 'user-managment/user/changeStatus',
  getValueChainProfile: 'user-managment/valuechain-profile/get',
  // User Management list
  userManagementList: 'user-managment/user/getUserList',

  // User Profile
  getUserProfile: 'user-profile/profile/get',
  updateUserProfile: '/user-profile/profile/update',
  // ReMake User
  contactUs: '/user-managment/user/inquiry/create',
  createReMakeUserInquiry: 'landing-page/inquiry/remake-create',
  updateStatus: '/user-managment/user/updateStatus',
  getUserCreditStats: 'dashboard/getUserCreditStats',
  userDatabasePendingList: 'user-profile/user/getUserDatabasePendingList',
  getUserDatabaseHistory: 'user-profile/user/getUserDatabaseHistory',
  updateDatabaseStatus: 'user-profile/user/updateDatabaseStatus',

  // PC Management
  pcRequestUpdate: 'plastic-credit-request-management/plastic-credit/update',

  // Excel Import
  excelFileImport: 'transaction-managment/transaction/excel-create',
  excelFileValidate: 'transaction-managment/transaction/excel-validate',
  excelFileImportForLink:
    'transaction-managment/transaction/excel-create-to-link',
  excelFileValidateForLink:
    'transaction-managment/transaction/excel-validate-to-link',
  excelUploadHistoryList:
    'transaction-managment/transaction/excel-history-list',
  excelMarkAsComplete: 'transaction-managment/transaction/mark-as-complete',
  downloadSample: 'transaction-managment/transaction/download-sample',

  // CSR Module
  getActivities: 'social-welfare/getActivities',
  getEvents: 'social-welfare/getEvents',
  getReclaimers: 'social-welfare/getReclaimers',
  getEvent: 'social-welfare/getEvent?id=',
  getReclaimer: 'social-welfare/getReclaimer?id=',
  createEvent: 'social-welfare/createEvent',
  updateEvent: 'social-welfare/updateEvent',
  updateReclaimer: 'social-welfare/updateReclaimer',
  createReclaimer: 'social-welfare/createReclaimer',
  createActivity: 'social-welfare/createActivity',
  getCollectionCenter: 'social-welfare/getCollectionCenter',
  getReclaimerByAadhaar: 'social-welfare/getReclaimerByAadhaar?aadhaar_number=',
  getEventForActivity: 'social-welfare/getEventForActivity?id=',
  getReclaimerForActivity: 'social-welfare/getReclaimerForActivity?id=',
  getEventList: 'social-welfare/getEventList',
  bulkActivityUpload: 'social-welfare/bulkActivityUpload',
  donloadBulkActivityUploadSample: 'social-welfare/download-sample',
  exportActivity: 'social-welfare/exportActivity',
  getCSRMaterials: 'social-welfare/getCSRMaterials',
  reclamer_pdf: 'social-welfare/export/reclamer_pdf',
  peronal_reclamer_pdf: 'social-welfare/export/peronal_reclamer_pdf',

  // CSR Activities
  getReclaimerCategory: 'csr/getReclaimerCategory',
  getEventCategory: 'csr/getCSREventCategory',
  getActivityCategory: 'csr/getCSRActivityCategory?Id=',
  getCSRReclaimerList: 'csr/getCSRReclaimers',
  getCSREventList: 'csr/getCSREvents',
  getCSRActivityList: 'csr/getCSRActivities',
  getCSREventForCSRActivity: 'csr/getCSREvent?id=',
  getCSRReclaimerForCSRActivity: 'csr/getCSRReclaimerForCSRActivity?id=',
  createCSRActivity: 'csr/createCSRActivity',
  createCSRReclaimer: 'csr/createCSRReclaimer',
  createCSREvent: 'csr/createCSREvent',
  exportexcelActivity: 'csr/export-as-excel',
  csr_reclamer_pdf: 'csr/export/csr_reclamer_pdf',
  csr_personal_pdf: 'csr/export/peronal_csr_pdf',
  // Chart Statistics
  getChartDetails: 'get-settings/setting/getChartStatistics',
  exportexcelUnlinkTransaction: 'transaction-managment/export-unlink',
  exportexcelLinkTransaction: 'transaction-managment/export-link',

  // Domain Verification
  getDomainList: 'domainVerification/getDomains',
  addDomain: 'domainVerification/createDomain',
  updateDomainStatus: 'domainVerification/updateStatus',

  //Footprint Management
  getFootPrintList:
    'plastic-credit-request-management/plastic-credit/get-footprint-list',
    getQueueList:
    'plastic-credit-request-management/plastic-credit/get-queue-list',
};
