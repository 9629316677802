import React, { useState } from 'react';
import { ProductDetail } from '../../../app/types/plasticCredit';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { toast } from 'react-toastify';

const ProductDetails = (props: any) => {
  const {
    requestDate,
    materialSubType,
    materialType,
    Region,
    totalCredit,
    quantity,
    quantityUnit,
    currency,
  } = props.data;

  return (
    <div className='panel-box'>
      <div className='panel-title title-grey'>
        <div className='left'>
          <div className='title'>Product Details</div>
        </div>

        <div className='right'>
          <div className='title-data'>Requested on: {requestDate}</div>
        </div>
      </div>
      <div className='panel-data'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='form-group required'>
              <label>Material Type</label>
              <select className='form-control form-select' id='' disabled>
                <option>{materialType}</option>
              </select>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='form-group required'>
              <label>Material Sub-Type</label>
              <input
                disabled
                type='text'
                className='form-control form-select'
                value={materialSubType}
              />
            </div>
          </div>

          <div className='col-md-4'>
            <div className='form-group required'>
              <label>Region</label>
              <select
                className='form-control form-select select-data'
                value={Region}
                disabled
              >
                <option>{Region}</option>
              </select>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='form-group required'>
              <label>Total Credit</label>
              <input
                disabled
                type='text'
                className='form-control form-select'
                value={totalCredit}
              />
            </div>
          </div>

          <div className='col-md-4'>
            <div className='form-group required group-i-input'>
              <label>Quantity</label>

              <div className='input-group'>
                <div className='input-left input-left-right'>
                  <input
                    type='number'
                    className='form-control'
                    value={quantity}
                    disabled
                  />
                </div>

                <div className='input-right input-left-right'>
                  <select
                    className='form-control'
                    id=''
                    value={quantityUnit}
                    disabled
                  >
                    <option>{quantityUnit}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='form-group required'>
              <label>Preferred Currency</label>
              <select
                className='form-control form-select select-data'
                id=''
                disabled
              >
                <option value=''>{currency}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
