import { Route } from 'react-router-dom';
import { Home } from '../../pages/admin/home';
import TransectionLinking from '../../pages/admin/transactionLinking';
import GenerateCertificate from '../../pages/admin/generateCertificate';
import PlasticCreditRequestManagement from '../../pages/admin/plasticCreditRequest';
import PlasticCreditRequestDetails from '../../pages/admin/plasticCreditRequest/plasticCreditRequestDetails';
import { Layout } from '../../pages/shared/layouts';
import { LoginLayout } from '../../pages/shared/layouts/loginLayout';
import { Login } from '../../pages/shared/login';
import { ProtectedRoute } from './protectedRoute';
import UserManagement from '../../pages/admin/userManagement';
import TransactionRegistry from '../../pages/admin/transactionRegister';
import TransactionRegistryList from '../../pages/admin/transactionRegisterList';
import PlasticCerditCertification from '../../pages/admin/plasticCreditCertificate/plasticCreditCertificate';
import { CertificateLayout } from '../../pages/shared/layouts/cetificateLayout';
import PlasticCerditCertificationMoreInfo from '../../pages/admin/plasticCreditCertificate/plasticCerditCertificateMoreInfo';
import UserProfile from '../../pages/admin/profile';
import ViewUserProfile from '../../pages/admin/viewProfile';
import ViewProfileDatabase from '../../pages/admin/viewProfileDatabase';
import UploadTransactions from '../../pages/admin/transactionRegisterList/bulkUploadTransaction';
import UploadTransactionToLink from '../../pages/admin/transactionRegisterList/bulkUploadTransactionToLink';
import SocialWelfare from '../../pages/admin/socialWelfare';
import AddEvent from '../../pages/admin/socialWelfare/addEvent';
import AddReclaimer from '../../pages/admin/socialWelfare/addReclaimer';
import AddActivity from '../../pages/admin/socialWelfare/addActivity';
import ViewEvent from '../../pages/admin/socialWelfare/viewEvent';
import ViewReclaimer from '../../pages/admin/socialWelfare/viewReclaimer';
import ViewCSRReclaimer from '../../pages/admin/CSRActivity/viewReclaimer';
import ViewCSREvent from '../../pages/admin/CSRActivity/viewEvent';
import CSRActivity from '../../pages/admin/CSRActivity';
import AddCSRActivity from '../../pages/admin/CSRActivity/addActivity';
import AddCSREvent from '../../pages/admin/CSRActivity/addEvent';
import AddCSRReclaimer from '../../pages/admin/CSRActivity/addReclaimer';
import TransactionDocUpload from '../../pages/admin/transactionRegister/transactionDocUpload';
import DomainVerification from '../../pages/admin/domainVarification';
import FootprintManagement from '../../pages/admin/footprintManagement';
import ViewValueChainProfileDetails from '../../pages/admin/viewValueChainProfile';

export const adminRoutes = (
  <Route path='/admin'>
    <Route
      path='/admin/'
      element={
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      }
    >
      <Route path='/admin/userManagement' element={<UserManagement />} />
      <Route path='/admin/domainManagement' element={<DomainVerification />} />
      <Route
        path='/admin/footprintManagement'
        element={<FootprintManagement />}
      />
      <Route
        path='/admin/transaction/create-registry'
        element={<TransactionRegistry />}
      />
      <Route
        path='/admin/transaction/update-transaction-registry/:id'
        element={<TransactionRegistry />}
      />
      <Route
        path='/admin/transaction/upload-documents'
        element={<TransactionDocUpload />}
      />
      <Route
        path='/admin/transaction/list'
        element={<TransactionRegistryList />}
      />
      <Route
        path='/admin/transaction/linkTransection'
        element={<TransectionLinking />}
      />
      <Route
        path='/admin/transaction/upload'
        element={<UploadTransactions />}
      />
      <Route
        path='/admin/transaction/uploadtolink'
        element={<UploadTransactionToLink />}
      />
      <Route
        path='/admin/generateCertificate'
        element={<GenerateCertificate />}
      />
      <Route
        path='/admin/plasticCreditRequest'
        element={<PlasticCreditRequestManagement />}
      />

      {/* <Route
        path="/admin/plasticCreditRequest/:id"
        element={<PlasticCreditRequestDetails />}
      /> */}
      <Route path='/admin/profile' element={<UserProfile />} />
      <Route
        path='/admin/view-user-profile/:user_id'
        element={<ViewUserProfile />}
      />
      <Route
        path='/admin/view-valuechain-profile/:user_id'
        element={<ViewValueChainProfileDetails />}
      />
      <Route
        path='/admin/plasticCreditRequest/:id'
        element={<PlasticCreditRequestDetails />}
      />
      <Route
        path='/admin/view-user-database/:user_id'
        element={<ViewProfileDatabase />}
      />
      <Route path='/admin/csr-activity' element={<CSRActivity />} />
      <Route
        path='/admin/csr-activity/add-activity'
        element={<AddCSRActivity />}
      />
      <Route path='/admin/csr-activity/add-event' element={<AddCSREvent />} />
      <Route
        path='/admin/csr-activity/add-reclaimer'
        element={<AddCSRReclaimer />}
      />

      <Route path='/admin/social-welfare' element={<SocialWelfare />} />
      <Route path='/admin/social-welfare/add-event' element={<AddEvent />} />
      <Route
        path='/admin/social-welfare/edit-event/:id'
        element={<AddEvent />}
      />

      <Route
        path='/admin/social-welfare/add-reclaimer'
        element={<AddReclaimer />}
      />
      <Route
        path='/admin/social-welfare/edit-reclaimer/:id'
        element={<AddReclaimer />}
      />
      <Route
        path='/admin/social-welfare/add-activity'
        element={<AddActivity />}
      />
      <Route
        path='/admin/social-welfare/add-activity/:user_id'
        element={<AddActivity />}
      />
      <Route path='/admin/social-welfare/event/:id' element={<ViewEvent />} />
      <Route
        path='/admin/social-welfare/reclaimer/:id'
        element={<ViewReclaimer />}
      />

      <Route
        path='/admin/csr-activity/reclaimer/:id'
        element={<ViewCSRReclaimer />}
      />

      <Route path='/admin/csr-activity/event/:id' element={<ViewCSREvent />} />
    </Route>

    <Route path='/admin/' element={<LoginLayout />}>
      <Route
        path='/admin/login'
        element={<Login pcId={''} transactionId={''} />}
      />
    </Route>
    <Route
      path='/admin/'
      element={
        <ProtectedRoute>
          <CertificateLayout />
        </ProtectedRoute>
      }
    >
      <Route
        path='/admin/transaction/plasticCreditCertification/:id'
        element={<PlasticCerditCertification />}
      />
      <Route
        path='/admin/transaction/plasticCreditCertificationMoreInfo/:id'
        element={<PlasticCerditCertificationMoreInfo />}
      />
    </Route>
  </Route>
);
