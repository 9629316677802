import { commanLabel } from "../../../constants/comman.constant";
import { UserType } from "../../../shared/remake-green-utils/common/constants";

export interface UserRoleProps {
  isRole: boolean;
}

// User Basic Details fields
export const userDetails = {
  user_name: "",
  user_type: UserType.COMPANY,
  secondary_email: "",
  contact_number: "",
  referal_code: "",
  country_id: "", // India location selected by default
  state_id: "",
  city_id: "",
  zip_code: "",
  designation: "",
  billing_address: "",
  company_name: "",
  company_identification_number: "",
  gst_number: "",
  preferred_currency: commanLabel.currency,
  documentIds: [],
  userId:""
};

export const errorJson = {
  user_name: "",
  user_type: "",
  secondary_email: "",
  contact_number: "",
  country_id: "",
  state_id: "",
  city_id: "",
  zip_code: "",
  billing_address: "",
  company_name: "",
  company_identification_number: "",
  gst_number: "",
  preferred_currency: "",
};

