import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserRole } from './userRole';
import { useTranslation } from 'react-i18next';
import { store } from '../../../app/store';
import {
  UserProfileStatus,
  UserType,
} from '../../../shared/remake-green-utils';
import { Col, Row } from 'react-bootstrap';
import { commanLabel } from '../../../constants/comman.constant';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { toast } from 'react-toastify';
import { userDetails } from './userRoleProps';
const Registration = () => {
  const { t: translate } = useTranslation();
  const state = store.getState();
  const userTypeId = state?.auth?.user?.userTypeId;
  const [isRole, setIsRole] = useState(false);
  const [userDetail, setuserDetail] = useState<any>();
  const user_id = state?.auth?.user?.id;
  useEffect(() => {
    setIsRole(userTypeId === UserType.INDIVIDUAL);
  }, [userTypeId]);

  const getRemakeDataByUserId = async () => {
    let path = apiUrl.getUserProfile;
    const param = {
      user_id,
      page: commanLabel.page,
      limit: commanLabel.pageLimit,
    };
    const fr = await helper.getData(path, param);
    const res = await fr.response;
    if (res.success) {
      setuserDetail(res?.data?.userDetail);
      setIsRole(res?.data?.userDetail?.user_type_id === UserType.INDIVIDUAL);
    } else {
      toast.error(res.message);
    }
  };
  useEffect(() => {
    getRemakeDataByUserId();
  }, []);
  return (
    <React.Fragment>
      <div className='body-content-space pb-0'>
        <div className='form register-page'>
          <div className='container'>
            <div className='form-data'>
              <Row className='d-flex align-items-center'>
                <Col md={7} sm={12}>
                  <h2 className='title-main'>
                    {translate('REGISTER.TITLES.CREATE')}{' '}
                    <span>{translate('COMMONFIELDS.REMAKE')} </span>{' '}
                    {translate('REGISTER.TITLES.ACCOUNT')}
                  </h2>
                </Col>
                <Col className='d-flex justify-content-md-end' md={5} sm={12}>
                  {userDetail?.user_profile?.status ===
                    UserProfileStatus.PENDING && (
                    <div className='status-action'>
                      <span className='status-box status-box-yellow'></span>
                      {translate('PROFILE.TITLES.PENDING_FOR_APPROVAL')}
                      <i
                        className='fa fa-info-circle tooltip-icon-data'
                        title='Request is pending from admin side'
                      />
                    </div>
                  )}
                  {userDetail?.user_profile?.status ===
                    UserProfileStatus.REJECTED && (
                    <>
                      <div className='status-action'>
                        <span className='status-box status-box-red'></span>
                        {translate('PROFILE.TITLES.REJECTED')}
                        <i
                          className='fa fa-info-circle tooltip-icon-data'
                          title={userDetail?.user_profile?.rejection_reason}
                        />
                      </div>
                    </>
                  )}
                </Col>
              </Row>

              <div className='tabs-form-section'>
                <ul className='nav nav-tabs' role='tablist'>
                  <li className='nav-item'>
                    <Link
                      to={'#'}
                      className={`nav-link ${!isRole && 'active'}`}
                      id='companyuser-tab'
                      data-toggle='tab'
                      role='tab'
                      aria-controls='companyuser'
                      aria-selected='true'
                      onClick={(e) => {
                        setIsRole(false);
                      }}
                    >
                      {translate('REGISTER.TITLES.COMPANY_USER')}
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to={'#'}
                      className={`nav-link ${isRole && 'active'}`}
                      id='individualuser-tab'
                      data-toggle='tab'
                      role='tab'
                      aria-controls='individualuser'
                      aria-selected='false'
                      onClick={(e) => {
                        setIsRole(true);
                      }}
                    >
                      {translate('REGISTER.TITLES.INDIVIDUAL_USER')}
                    </Link>
                  </li>
                </ul>
                <UserRole isRole={isRole} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Registration;
