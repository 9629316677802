import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../../shared/remake-green-utils';

const RequestedDetails = (props: any) => {
  const { t: translate } = useTranslation();

  const {
    requestedBy,
    requestDate,
    role,
    state,
    city,
    zipCode,
    country,
    companyContactNumber,
    companyEmail,
    companyIdNumber,
    companyName,
    GSTNumber,
    billingAddress,
    userName,
    userEmail,
    userContactNumber,
    referralCode,
    currency,
    designation,
    userType,
  } = props.data;
  return (
    <div className='panel-box'>
      <div className='panel-title title-grey'>
        <div className='left'>
          <div className='title'>
            {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.REQUESTED_BY')}:{' '}
            {requestedBy}
          </div>
        </div>
        <div className='right'>
          <div className='title-data'>
            {' '}
            {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.REQUESTED_ON')}:{' '}
            {requestDate}
          </div>
        </div>
      </div>
      <div className='panel-data'>
        <div className='row'>
          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.USER_ROLE')}
              </label>
              <input
                type='text'
                className='form-control selected'
                value={role}
                disabled
              />
            </div>
          </div>

          {userType === UserType.COMPANY && (
            <div className='col-lg-4 col-md-6'>
              <div className='form-group'>
                <label className='form-label'>
                  {' '}
                  {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.COMPANY_NAME')}
                </label>
                <input
                  type='text'
                  className='form-control selected'
                  value={companyName}
                  disabled
                />
              </div>
            </div>
          )}

          {userType === UserType.COMPANY && (
            <div className='col-lg-4 col-md-6'>
              <div className='form-group'>
                <label className='form-label'>
                  {translate(
                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.COMPANY_IDENTIFICATION_NUMBER'
                  )}
                </label>
                <input
                  type='text'
                  className='form-control selected'
                  value={companyIdNumber}
                  disabled
                />
              </div>
            </div>
          )}

          {userType === UserType.COMPANY && (
            <div className='col-lg-4 col-md-6'>
              <div className='form-group'>
                <label className='form-label'>
                  {' '}
                  {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.COMPANY_EMAIL')}
                </label>
                <input
                  type='text'
                  className='form-control selected'
                  value={companyEmail}
                  disabled
                />
              </div>
            </div>
          )}

          {userType === UserType.COMPANY && (
            <div className='col-lg-4 col-md-6'>
              <div className='form-group'>
                <label className='form-label'>
                  {' '}
                  {translate(
                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.COMPANY_CONTACT_NUMBER'
                  )}
                </label>
                <input
                  type='text'
                  className='form-control selected'
                  value={companyContactNumber}
                  disabled
                />
              </div>
            </div>
          )}

          {userType === UserType.COMPANY && (
            <div className='col-lg-4 col-md-6'>
              <div className='form-group'>
                <label className='form-label'>
                  {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.GST_VAT_NUMBER')}
                </label>
                <input
                  type='text'
                  className='form-control selected'
                  value={GSTNumber}
                  disabled
                />
              </div>
            </div>
          )}

          <div className='col-lg-8 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {' '}
                {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.BILLING_ADDRESS')}
              </label>

              <textarea
                className='form-control'
                rows={2}
                value={billingAddress}
                disabled
              ></textarea>

              <div className='invalid-feedback'></div>
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {' '}
                {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.COUNTRY')}
              </label>
              <select
                className='form-control fix-height form-select selected'
                disabled
              >
                <option value=''>{country}</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {' '}
                {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.STATE')}
              </label>
              <select
                className='form-control fix-height form-select selected'
                disabled
              >
                <option value=''>{state}</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {' '}
                {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.CITY')}
              </label>
              <select
                className='form-control fix-height form-select selected'
                disabled
              >
                <option value=''>{city}</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {' '}
                {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.ZIP_CODE')}
              </label>
              <input
                type='text'
                className='form-control selected'
                value={zipCode}
                disabled
              />
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {' '}
                {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.USER_NAME')}
              </label>
              <input
                type='text'
                className='form-control selected'
                value={userName}
                disabled
              />
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {' '}
                {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.USER_EMAIL')}
              </label>
              <input
                type='text'
                className='form-control selected'
                value={userEmail}
                disabled
              />
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label>
                {' '}
                {translate(
                  'PLASTIC_CREDIT_MANAGEMENT.TITLES.USER_CONTACT_NUMBER'
                )}
              </label>
              <div className='cell-item'>
                <span className='prefix'>+91</span>
                <input
                  type='tel'
                  className='form-control selected'
                  disabled
                  value={userContactNumber}
                />
              </div>
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {' '}
                {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.DESIGNATION')}
              </label>
              <input
                type='text'
                className='form-control selected'
                disabled
                value={designation}
              />
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {' '}
                {translate(
                  'PLASTIC_CREDIT_MANAGEMENT.TITLES.PREFERRED_CURRENCY'
                )}
              </label>
              <input
                type='text'
                className='form-control selected'
                disabled
                value={currency}
              />
            </div>
          </div>

          <div className='col-lg-4 col-md-6'>
            <div className='form-group'>
              <label className='form-label'>
                {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.REFERRAL_CODE')}
              </label>
              <input
                type='text'
                className='form-control selected'
                value={referralCode}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestedDetails;
