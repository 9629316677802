import React, { useEffect, useRef, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { toast } from "react-toastify";
import { apiUrl } from "../../../../app/api/services/apiUrl";
import { helper } from "../../../../app/api/api.helper";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { commanLabel } from "../../../../constants/comman.constant";
import {
  getFileFormat,
  getFormatBytes,
} from "../../../../utils/files/commanFilefunction";
import { Button } from "react-bootstrap";

export interface Document {
  id: any;
}
export const Documents = (props: Document) => {
  const { id } = props;
  const [isDocument, setIsDocument] = useState(false);
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [documentData, setDocumentData] = useState<any>([]);
  const [searchKey, setSearchKey] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [fileType, setFileType] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const columnHelper = createColumnHelper<any>();
  const myRef: any = useRef(null);
  const executeScroll = () =>
    myRef && myRef.current && myRef.current.scrollIntoView();

  const columns = [
    columnHelper.accessor("_", {
      header: () => "File Name",
      cell: (props: any) => {
        let fileName = props?.row?.original?.document_name;
        return (
          <React.Fragment>
            <div className="icon-img">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/type1.png`}
                alt={""}
              />
              <div title={fileName} className="file-name-title filename-tooltip">{fileName}</div>
            </div>
          </React.Fragment>
        );
      },
    }),
    columnHelper.accessor(
      (row) => `${row?.document_type && getFileFormat(row?.document_type)}`,
      {
        id: "File Format",
      }
    ),
    columnHelper.accessor(
      (row) => `${row?.size && getFormatBytes(row?.size)}`,
      {
        id: "File Size",
      }
    ),
    columnHelper.accessor(
      (row: any) => `${moment(row.created_on).format("DD/MM/YYYY hh:mm A")}`,
      {
        id: "File Dated",
      }
    ),
    columnHelper.accessor("file_type", {
      header: () => "File Type",
    }),
    columnHelper.accessor("-", {
      header: () => "Action",
      enableSorting: false,
      cell: (props: any) => {
        return (
          <React.Fragment>
            <div className="action-main">
              <a
                href={props?.row?.original?.document_path}
                target="_blank"
                rel="noopener noreferrer"
                title="View"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/eye.png`}
                  alt="#"
                />
              </a>
              {/* <Link
                to={props?.row?.original?.document_path}
                target="_blank"
                rel="noopener noreferrer"
                title="DownLoad"
                download
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/download.png`}
                  alt="#"
                />
              </Link> */}
            </div>
          </React.Fragment>
        );
      },
    }),
  ];
  const getData = async (reset: boolean) => {
    let transactionID = window.atob(id).replace("LNK", "").replace(/^0+/, "");
    setIsLoading(true);
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      linked_transaction_id: transactionID,
      searchText: reset ? "" : searchKey,
      filter_file_type: reset ? "All" : fileType,
    };
    let path = apiUrl.getCertificateImages;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      if (reset) {
        setDocumentData([...res?.data?.files]);
      } else {
        setDocumentData([...documentData, ...res?.data?.files]);
      }
      setIsSearching(false);
      setDataCount(res.data.total);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  const getSettingData = async () => {
    let path = apiUrl.getAllSetting;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let allSetting = res?.data && res?.data?.jsonData;
      setFileData(allSetting.File_Types);
    } else {
      toast.error(res.message);
    }
  };

  const fetchMoreData = () => {
    setTimeout(() => {
      setPage((page) => page + 1);
    }, 1000);
  };

  useEffect(() => {
    getSettingData();
  }, []);

  useEffect(() => {
    if (isSearching && documentData.length === 0) {
      getData(false);
    }
  }, [isSearching, documentData]);

  useEffect(() => {
    getData(false);
  }, [page]);

  const table = useReactTable({
    data: documentData,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const performSearch = (e: any) => {
    e.preventDefault();
    executeScroll();
    setIsSearching(true);
    setDocumentData([]);
  }
  return (
    <React.Fragment>
      <div className={`product-details-panel ${isDocument ? "toggle" : ""}`}>
        <h3 className="product-title">
          <div className="title-data">Documents</div>
          <div className="filter-menu">
            <div className="search-input">
              <div className="serach-box">
                <label htmlFor="gsearch">
                  <i className="fa fa-search" aria-hidden="true" />
                </label>
                <input
                  type="search"
                  id="gsearch"
                  name="gsearch"
                  placeholder="Search documents by name"
                  value={searchKey}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      performSearch(e);
                    }
                  }}
                />
              </div>
            </div>

            <div className="select-btn-wrap">
              <div className="select-type">
                <div className="select-main">
                  {/* <label htmlFor="doc-type">Select Document Type </label> */}
                  <select
                    name="doc-type"
                    id="doc-type"
                    value={fileType}
                    onChange={(e) => {
                      setFileType(e.target.value);
                    }}
                  >
                    {fileData?.length > 0 &&
                      fileData.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div className="search-button mt-md-0 mt-lg-4 mt-sm-0 mt-xl-0">
              <Button
                className="btn  btn-sm btn-primary btn-icon"
                type="button"
                title="Search"
                onClick={async (e) => {
                  e.preventDefault();
                  executeScroll();
                  setIsSearching(true);
                  setDocumentData([]);
                }}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </Button>
              <Button
                className="btn  btn-sm btn-primary btn-icon"
                type="button"
                title="Reset"
                onClick={(e) => {
                  e.preventDefault();
                  getData(true);
                }}
              >
                <i className="fa fa-refresh" aria-hidden="true"></i>{" "}
              </Button>
            </div>
          </div>
          <div className="right-icon-layout">
            <i
              className="fa-solid fa-circle-chevron-up toggle-data"
              onClick={(e) => {
                setIsDocument(!isDocument);
              }}
            />
          </div>
        </h3>
        <div
          className="table-panel"
          style={{ display: isDocument ? "none" : "block" }}
        >
          <div className="table-panel-in">
            <InfiniteScroll
              dataLength={page * 10}
              next={fetchMoreData}
              hasMore={dataCount > documentData.length}
              loader={
                <p style={{ textAlign: "center" }}>
                  <b>Loading...</b>
                </p>
              }
              height={documentData.length ? 330 : 150}
              className="table-list"
            // endMessage="No More data :::::"
            >
              <table ref={myRef}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup: any) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header: any) => {
                        return (
                          <th key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none"
                                    : "",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: " 🔼",
                                  desc: " 🔽",
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                {documentData?.length > 0 ? (
                  <tbody>
                    {table.getRowModel().rows.map((row: any) => {
                      return (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell: any) => {
                            return (
                              <td key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <>
                    <tbody>
                      <tr>
                        <td colSpan={6}>
                          {isLoading && (
                            <h3 className="center-message-item">Loading...</h3>
                          )}
                          {documentData?.length === 0 && !isLoading && (
                            <h3 className="center-message-item">
                              No Record Found...
                            </h3>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </>
                )}
              </table>
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {/* <div className="external-link">
                <a href="#">
                    {" "}
                    Click here to view the transaction-related documents
                    (Certificate Owner access only)
                </a>
            </div> */}
    </React.Fragment>
  );
};
