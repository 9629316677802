import React, { useState } from 'react';
import { contactUsValidator } from './contactUsValidator';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isEmptyDeep } from '../../../utils/helper/common';
import { useContactUsMutation } from '../../../app/api/services/transaction.service';
import { toast } from 'react-toastify';
import { commanLabel } from '../../../constants/comman.constant';

const CreateInquiry = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const [contactUs] = useContactUsMutation();
  const [formData, setFormData] = useState({
    name: '',
    contact_number: '',
    email: '',
    organisation: '',
  });

  const [errors, setErrors] = useState<any>({
    name: '',
    contact_number: '',
    email: '',
  });
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not
  const [formSubmitted, setFormSubmitted] = useState(false);
  const validateForm = (formValues = formData, formSubmit = false) => {
    const errs: any = contactUsValidator(errors, formValues);
    setErrors(errs);
    const isFormValid = isEmptyDeep(errs);
    if (formSubmit && isFormValid) {
      onsubmit();
    }
  };

  const handleChange = (e: any) => {
    if (isSubmitted) {
      validateForm({ ...formData, [e.target.name]: e.target.value });
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onsubmit = () => {
    contactUs(formData)
      .then((res: any) => {
        if (res?.data?.success && res?.data?.statusCode === 201) {
          setFormSubmitted(true);
          toast.success(res?.data?.data);
          // navigate('/');
        } else {
          toast.error(translate('TOAST.ERRORS.SOMETHING_WENT_WRONG'));
        }
      })
      .catch((err: any) => {
        toast.error(translate('TOAST.ERRORS.SOMETHING_WENT_WRONG'));
      });
  };

  return (
    <div className='body-content-space pb-0'>
      <div className='container'>
        <div className='title-description-content'>
          <div className='text-center title-main'>
            <h2>{translate('CONTACT_US.TITLES.CONTACT_US')}</h2>
          </div>
          <div className='row d-flex justify-content-center'>
            <div className='contactus-inner col-lg-5'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='form-group required'>
                    <label className='form-label'>
                      {translate('CONTACT_US.TITLES.FULL_NAME')}
                    </label>
                    <input
                      placeholder={`${translate(
                        'VALUECHAIN.TITLES.ENTER_FULL_NAME'
                      )}`}
                      type='text'
                      className='form-control'
                      name='name'
                      value={formData.name}
                      onChange={handleChange}
                      required
                      maxLength={50}
                      autoComplete='off'
                    />
                    {<span className='error'>{translate(errors.name)}</span>}
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='form-group required'>
                    <label className='form-label'>
                      {translate('CONTACT_US.TITLES.EMAIL_ADDRESS')}
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      autoComplete='off'
                      placeholder={`${translate(
                        'CONTACT_US.TITLES.ENTER_EMAIL_ADDRESS'
                      )}`}
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    {<span className='error'>{translate(errors.email)}</span>}
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='form-group'>
                    <label className='form-label'>
                      {translate('CONTACT_US.TITLES.ORGANISATION_NAME')}
                    </label>
                    <input
                      placeholder={`${translate(
                        'CONTACT_US.TITLES.ENTER_ORGANISATION_NAME'
                      )}`}
                      type='text'
                      className='form-control'
                      name='organisation'
                      value={formData.organisation}
                      onChange={handleChange}
                      required
                      maxLength={50}
                      autoComplete='off'
                    />
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='form-group'>
                    <label className='form-label'>
                      {translate('CONTACT_US.TITLES.CONTACT_NUMBER')}
                    </label>
                    <div className='cell-item'>
                      <span className='prefix'>{commanLabel.countryCode}</span>
                      <input
                        type={'number'}
                        placeholder={`${translate(
                          'CONTACT_US.TITLES.ENTER_CONTACT_NUMBER'
                        )}`}
                        className='form-control form-control'
                        name='contact_number'
                        value={formData.contact_number}
                        onChange={handleChange}
                        required
                      />
                      {
                        <span className='error'>
                          {translate(errors.contact_number)}
                        </span>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-lg-12'>
                  {!formSubmitted ? (
                    <div className='submit-button text-center'>
                      <button
                        type='submit'
                        className='btn btn-primary w-100'
                        onClick={() => {
                          setIsSubmited(true);
                          validateForm(formData, true);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : (
                    <div
                      className='alert alert-success text-center'
                      role='alert'
                    >
                      "Thanks for submit the form our team will connect with you
                      !"
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInquiry;
