import React from 'react';
import { category } from '../../../../shared/remake-green-utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { quantityUnitConstants } from '../../../../constants/comman.constant';
export interface ParticipantStage {
  participantData: any;
  totalQuantity: any;
  ownerHistory: any;
}

export const ParticipantStages = (props: ParticipantStage) => {
  const { participantData, ownerHistory } = props;
  const { t: translate } = useTranslation();
  const getGoodTransfer = (pItem: any) => {
    const mForm = pItem?.goodTransferDetails?.materialForm
      ? `${pItem?.goodTransferDetails?.materialForm}-`
      : '';
    const mTransfered = pItem?.goodTransferDetails?.materialTransferred
      ? pItem?.goodTransferDetails?.materialTransferred
      : '';
    const pForm = pItem?.goodTransferDetails?.packagingForm
      ? `(${pItem?.goodTransferDetails?.packagingForm})`
      : '';
    return mForm + mTransfered + pForm;
  };

  return (
    <React.Fragment>
      <div className='content-section-wrap container-space page-data-other'>
        <h3 className='title-line'>
          {translate(
            'CERTIFICATE_PAGE.PARTICIPANT_STAGES.CIRCULARITY_PARTICIPANTS'
          )}
        </h3>
        <div className='participants-main'>
          <div className='participants-data'>
            {participantData &&
              participantData.length > 0 &&
              participantData.map((item: any, index: number) => {
                return (
                  <div className='grid-items' key={index}>
                    {/* <div className="grid-main-title">
                                        <div className="stage-one stage-in">
                                            <div className="title-grid">Stage {index + 1}</div>
                                            <div className="title-image-wrap">
                                                {" "}
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/Group 11614.png`}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                    <div className='stage-1'>
                      <p>
                        {translate('CERTIFICATE_PAGE.PARTICIPANT_STAGES.STAGE')}{' '}
                        {index + 1}
                      </p>
                    </div>

                    <div className='grid-main'>
                      <div className='grid-top'>
                        <div className='item'>
                          <h3 className='title-detail mt-0'>
                            {translate(
                              'CERTIFICATE_PAGE.PARTICIPANT_STAGES.NAME'
                            )}
                            ,{' '}
                            {translate(
                              'CERTIFICATE_PAGE.PARTICIPANT_STAGES.ADDRESS'
                            )}{' '}
                            &amp;{' '}
                            {translate(
                              'CERTIFICATE_PAGE.PARTICIPANT_STAGES.CONTACT_DETAILS'
                            )}
                          </h3>
                          <p className='company-name'>
                            {item.nameAddressContact.name}
                          </p>
                          {item?.payment_detail?.paymentReference && (
                            <div className='address-wrap'>
                              {/* <i className='fa-solid fa-money-dot' /> */}
                              <div className='address'>
                                {translate(
                                  'CERTIFICATE_PAGE.PARTICIPANT_STAGES.PAYMENT_REFERENCE'
                                )}{' '}
                                : {item?.payment_detail?.paymentReference}
                              </div>
                            </div>
                          )}
                          {item?.nameAddressContact?.address && (
                            <div className='address-wrap'>
                              <i className='fa-solid fa-location-dot' />
                              <div className='address'>
                                {item?.nameAddressContact?.address}
                              </div>
                            </div>
                          )}

                          {item?.nameAddressContact?.phone && (
                            <div className='cell'>
                              <i className='fa-solid fa-phone' />{' '}
                              <a
                                href={'tel:' + item?.nameAddressContact?.phone}
                              >
                                {item?.nameAddressContact?.phone}
                              </a>
                            </div>
                          )}
                          {item?.transportDetails && (
                            <>
                              {/* <h3 className="title-detail tansport-detail">
                                                            Transporter Details (Stage {item?.currentStageNumber} to Stage {item?.nextStageNumber})
                                                        </h3> */}
                              <hr />
                              <div className='left-right-data'>
                                {item?.transportDetails?.name && (
                                  <div className='lr-wrap-left'>
                                    <div className='vehical-no-label'>
                                      {translate(
                                        'CERTIFICATE_PAGE.PARTICIPANT_STAGES.NAME'
                                      )}
                                    </div>
                                    <div
                                      className='vehical-name-adderess'
                                      title={item?.transportDetails?.name}
                                    >
                                      {item?.transportDetails?.name}
                                    </div>
                                  </div>
                                )}
                                {item?.transportDetails?.address && (
                                  <div className='lr-wrap-right'>
                                    <div className='vehical-no-label'>
                                      {translate(
                                        'CERTIFICATE_PAGE.PARTICIPANT_STAGES.ADDRESS'
                                      )}
                                    </div>
                                    <div
                                      className='vehical-name-adderess'
                                      title={item?.transportDetails?.address}
                                    >
                                      {item?.transportDetails?.address}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className='left-right-data'>
                                {item?.transportDetails?.vehicleNo && (
                                  <div className='lr-wrap-left'>
                                    <div className='vehical-no-label'>
                                      {translate(
                                        'CERTIFICATE_PAGE.PARTICIPANT_STAGES.VEHICLE'
                                      )}
                                    </div>
                                    <div className='vehical-no'>
                                      {item?.transportDetails?.vehicleNo}
                                    </div>
                                  </div>
                                )}
                                {item?.transportDetails?.ewayReceiptNo && (
                                  <div className='lr-wrap-right'>
                                    <div className='vehical-no-label'>
                                      {translate(
                                        'CERTIFICATE_PAGE.PARTICIPANT_STAGES.E_WAY_RECEIPT'
                                      )}
                                    </div>
                                    <div className='vehical-no'>
                                      {item?.transportDetails?.ewayReceiptNo}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        <div className='grid-circle'>
                          {/* 1 */}
                          {item.category === category.RECLAIMER && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/Reclaimer.png`}
                            />
                          )}
                          {/* 2 */}
                          {item.category === category.COLLECTOR && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/waste-collector.png`}
                            />
                          )}
                          {/* 3 */}
                          {item.category === category.SUBAGGREGATOR && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/Sub-Aggregator.png`}
                            />
                          )}

                          {/* 4 */}
                          {item.category === category.AGGREGATOR && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/Waste-Aggregator.png`}
                            />
                          )}

                          {/* 4 */}
                          {item.category === category.PROCESSOR && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/Waste-Processor.png`}
                            />
                          )}

                          {/* 5 */}
                          {item.category === category.RECYCLER && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/Waste-Recycler.png`}
                            />
                          )}
                          {/* 6 */}
                          {item.category === category.MANUFACTURER && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/Waste-Manufacturer.png`}
                            />
                          )}
                          {/* 7 */}
                          {item.category === category.FIRSTLEVELCONSUMER && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/1st-level-consumer.png`}
                            />
                          )}

                          {/* 8 */}
                          {item.category === category.SECONDLEVELCONSUMER && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/2nd-level-consumer.png`}
                            />
                          )}

                          {/* 9 */}
                          {item.category === category.WASTEPROCESSOR && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/Waste-Processor.png`}
                            />
                          )}

                          {/* 10 */}
                          {item.category === category.AGGREGATORPROCESSOR && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/Aggregator(Processor).png`}
                            />
                          )}
                          {/* 11 */}
                          {item.category === category.ENDOFLIFE && (
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/certificate/End-of-LIfe.png`}
                            />
                          )}
                        </div>
                        <div className='waste-colleactor'>
                          <p className='text'>{item.category}</p>
                        </div>
                      </div>
                      {item.currentStageNumber &&
                        item.nextStageNumber !== -1 && (
                          <div className='overlayer'>
                            <div className='stage-count'>
                              Stage {item?.currentStageNumber} to{' '}
                              {item?.nextStageNumber}
                            </div>
                            <div className='title'>
                              {translate(
                                'CERTIFICATE_PAGE.PARTICIPANT_STAGES.QUANTITY'
                              )}
                            </div>
                            <div className='description'>
                              {item.unloadedQuantity} {quantityUnitConstants.KG}
                            </div>
                            <div className='satge-date mt-2'>
                              {moment(item.stageDate).format('DD-MM-YYYY')}
                            </div>
                            <div className='description mt-2'>
                              {getGoodTransfer(item)}
                            </div>
                          </div>
                        )}
                      {item.currentStageNumber &&
                        item.nextStageNumber === -1 && (
                          <div className='total-count-area'>
                            {/* <div className="title">Total Quantity</div>
                                            <div className="description">{item.unloadedQuantity}</div> */}

                            {props.participantData[participantData?.length - 1]
                              ?.outputMaterialDetails?.materialDescription && (
                              <p style={{ fontWeight: '400' }}>
                                {translate(
                                  'CERTIFICATE_PAGE.PARTICIPANT_STAGES.OUTPUT_MATERIAL'
                                )}{' '}
                                -{' '}
                                <span>
                                  {
                                    props.participantData[
                                      participantData?.length - 1
                                    ]?.outputMaterialDetails
                                      ?.materialDescription
                                  }
                                </span>
                              </p>
                            )}
                            {props.participantData[participantData?.length - 1]
                              ?.outputMaterialDetails?.materialQuantity && (
                              <>
                                <p style={{ fontWeight: '400' }}>
                                  {translate(
                                    'CERTIFICATE_PAGE.PARTICIPANT_STAGES.OUTPUT_MATERIAL_QUANTITY'
                                  )}{' '}
                                  -{' '}
                                  <span>
                                    {
                                      props.participantData[
                                        participantData?.length - 1
                                      ]?.outputMaterialDetails?.materialQuantity
                                    }{' '}
                                    {/* {quantityUnitConstants.KG} */}
                                  </span>
                                </p>
                                <hr style={{ margin: '10px 0px' }} />
                              </>
                            )}

                            <p>
                              {translate(
                                'CERTIFICATE_PAGE.PARTICIPANT_STAGES.TOTAL_CONSIDERED_QUANTITY'
                              )}{' '}
                              - <span>{props.totalQuantity} KG</span>
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className='content-section-wrap container-space page-data-other'>
        <p className='main-title mb-4'>
          Total Credit Generated - {(props?.totalQuantity || 0) / 1000} Credits
        </p>
        <div className='inner-table-section mb-5'>
          <div className='table-wrap table-responsive'>
            <table className='credit-generate-table' cellSpacing={0}>
              <thead>
                <tr>
                  <td>Owner</td>
                  <td>Credits</td>
                  <td>Timestamp</td>
                </tr>
              </thead>
              <tbody>
                {ownerHistory.length > 0 &&
                  ownerHistory.map((item: any, key: any) => {
                    return (
                      <>
                        <tr className='even'>
                          <td>
                            {item?.plastic_credit_request_details?.pc_user
                              ? item?.plastic_credit_request_details?.pc_user
                                  .name
                              : item?.plastic_credit_request_details?.user
                                  ?.user_profile?.user_name}{' '}
                            / PC ID: {item.pc_id}
                          </td>
                          <td> {(item?.assigned_pc || 0) / 1000} Credits</td>
                          <td>
                            {moment(item.created_on).format(
                              'DD-MM-YYYY hh:mm A'
                            )}
                          </td>
                        </tr>
                        <tr className='odd'>
                          <td colSpan={3}>
                            Blockchain Hash: {item?.blockchain_hash}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr>
                  {ownerHistory && ownerHistory.length > 0 && (
                    <td colSpan={3}>
                      Reserved ReMake Registry -{' '}
                      {(ownerHistory[ownerHistory?.length - 1].available_pc ||
                        0) / 1000}{' '}
                      Credits
                    </td>
                  )}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
