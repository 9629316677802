import { Button, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserLogs } from './user-logs';
import { RootState } from '../../../../app/types/store';
import { store } from '../../../../app/store';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { routesConstant } from '../../../../constants/comman.constant';
import { NotificationPopover } from '../../../../components/notification';
export const Header = () => {
  const { t: translate } = useTranslation();
  const state: RootState = store.getState();
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isLoginPage = location.pathname === routesConstant.login;
  let adminRoute = routesConstant.login.substring(1);
  const isAdminLoginPage = location.pathname.indexOf(adminRoute) > -1;
  const onClickLogin = () => {
    if (!isAdminLoginPage) navigate(routesConstant.login);
  };
  const pathname = location.pathname;

  useEffect(() => {
    let header = document.getElementById('header');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        header?.classList?.add('fixed-header');
      } else {
        header?.classList?.remove('fixed-header');
      }
    });
  }, []);
  return (
    <header className='is-login-' id='header'>
      <div className='container'>
        <div className='header-main'>
          <div className='left'>
            {state.auth.isLoggedIn &&
              location.pathname !== routesConstant.plasticCreditRequest &&
              location.pathname !== routesConstant.remakeUserProfile &&
              !isHomePage && (
                <div className='left-back-icon'>
                  <div className='back-button'>
                    <a
                      onClick={() => navigate(-1)}
                      style={{ cursor: 'pointer' }}
                    >
                      <i
                        className='fa fa-long-arrow-left'
                        aria-hidden='true'
                      ></i>
                    </a>
                  </div>
                </div>
              )}
            <div className='logos'>
              <div className='left-one-logo'>
                <Link to={routesConstant.default}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/logo-right.png`}
                    alt='ReMake'
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className={`middle`}>
            <div className='nav-menu nav-menu-desktop'>
              <ul>
                <li>
                  <Link
                    to={routesConstant.aboutUs}
                    className={
                      pathname === routesConstant.aboutUs ? 'active' : ''
                    }
                  >
                    {translate('BUTTONS.ABOUT_US')}
                  </Link>
                </li>
                <li>
                  <Link
                    to={routesConstant.contactUs}
                    className={
                      pathname === routesConstant.contactUs ? 'active' : ''
                    }
                  >
                    {translate('BUTTONS.REMAKE_LITEPAPER')}
                  </Link>
                </li>
                <li>
                  <Link
                    to={routesConstant.sdgGoals}
                    className={
                      pathname === routesConstant.sdgGoals ? 'active' : ''
                    }
                  >
                    {translate('BUTTONS.SDG_GOALS')}
                  </Link>
                </li>
                <li>
                  <Link to={routesConstant.comingSoon} className=''>
                    {translate('BUTTONS.ZERO_TRUST_POLICY')}
                  </Link>
                </li>
                <li>
                  <Link
                    to={routesConstant.faq}
                    className={pathname === routesConstant.faq ? 'active' : ''}
                  >
                    {translate('BUTTONS.FAQS')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={`right`}>
            <div className='button-data'>
              {!state.auth.isLoggedIn && (
                <Button
                  variant='primary'
                  className='login-btn'
                  onClick={onClickLogin}
                >
                  {translate('BUTTONS.LOGIN')} / {translate('BUTTONS.REGISTER')}
                </Button>
              )}
              <NotificationPopover></NotificationPopover>
              <UserLogs />
            </div>
          </div>
        </div>

        <div id='nav-menu' className='nav-menu nav-menu-mobile'>
          <div
            className='hemburger-menu'
            onClick={() => {
              let container = document.getElementById('nav-menu');
              /** Slide down. */

              if (
                !container?.classList.contains('active-menu') &&
                container !== null
              ) {
                container?.classList.add('active-menu');
                container.style.height = 'auto';

                var height = container?.clientHeight + 'px';

                container.style.height = '0px';

                setTimeout(() => {
                  if (container !== null) {
                    container.style.height = height;
                  }
                }, 0);
              } else if (container !== null) {
                container?.classList.remove('active-menu');

                container.addEventListener(
                  'transitionend',
                  () => {
                    container!.style!.height = '0px';
                  },
                  { once: true }
                );
              }
            }}
          >
            <div className='menu'>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <ul className='nav-list' id='ul-list'>
            <li>
              <Link to={routesConstant.aboutUs}>About Us</Link>
            </li>
            <li>
              <Link to='#'>ReMake Litepaper</Link>
            </li>
            <li>
              <Link to={routesConstant.sdgGoals}>SDG Goals</Link>
            </li>
            <li>
              <Link to='#'>How? (Zero-Trust Policy)</Link>
            </li>
            <li>
              <Link to={routesConstant.faq}>FAQs</Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
