import { Navigate } from "react-router";
import { useAppDispatch } from "../../app/hooks";
import { store } from "../../app/store";
import { authLogout } from "../../app/store/slices/auth.slice";
import { RootState } from "../../app/types/store";
import { routesConstant } from "../../constants/comman.constant";
import { UserType } from "../../shared/remake-green-utils";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const dispatch = useAppDispatch();
  const state: RootState = store.getState();
  if (!state.auth.isLoggedIn || state.auth.user?.role !== UserType.ADMIN) {
    // not logged in so redirect to login page with the return url
    // dispatch(authLogout());
    // return <Navigate to="/login" />;
  }

  // authorized so return child components
  return children;
};
