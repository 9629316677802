import {
  commonInput,
  emailPatern,
  mobilePatern,
} from "../../../constants/comman.validation.constant";

export const userRoleValidator = (
  errors: any,
  userInfo: any,
  isRole: boolean
) => {
  let tempErrors = { ...errors };

  if (userInfo.user_name) {
    tempErrors = {
      ...tempErrors,
      user_name: !new RegExp(commonInput).test(userInfo.user_name)
        ? "ERRORS.INVALID.NAME"
        : "",
    };
  } else tempErrors = { ...tempErrors, user_name: "ERRORS.REQUIRED.NAME" };

  if (userInfo.secondary_email) {
    tempErrors = {
      ...tempErrors,
      secondary_email: !new RegExp(emailPatern).test(userInfo.secondary_email)
        ? "ERRORS.INVALID.EMAIL_ADDRESS"
        : "",
    };
  }
  // else tempErrors = { ...tempErrors, secondary_email: 'ERRORS.REQUIRED.EMAIL' }

  if (userInfo.contact_number) {
    tempErrors = {
      ...tempErrors,
      contact_number: !new RegExp(mobilePatern).test(userInfo.contact_number)
        ? "ERRORS.INVALID.CONTACT_NO"
        : "",
    };
  } else
    tempErrors = {
      ...tempErrors,
      contact_number: "ERRORS.REQUIRED.CONTACT_NO",
    };

  if (!isRole) {
    if (userInfo.company_name) {
      tempErrors = {
        ...tempErrors,
        company_name: !new RegExp(commonInput).test(userInfo.company_name)
          ? "ERRORS.INVALID.COMPANY_NAME"
          : "",
      };
    } else
      tempErrors = {
        ...tempErrors,
        company_name: "ERRORS.REQUIRED.COMPANY_NAME",
      };
    tempErrors = {
      ...tempErrors,
      company_identification_number: userInfo.company_identification_number
        ? ""
        : "ERRORS.REQUIRED.COMPANY_IDENTIFICATION_NUMBER",
    };
    tempErrors = {
      ...tempErrors,
      gst_number: userInfo.gst_number ? "" : "ERRORS.REQUIRED.GST_NUMBER",
    };
  } else {
    tempErrors = {
      ...tempErrors,
      company_name: "",
      company_identification_number: "",
      gst_number: "",
    };
  }
  tempErrors = {
    ...tempErrors,
    billing_address: userInfo.billing_address
      ? ""
      : "ERRORS.REQUIRED.BILLING_ADDRESS",
  };
  tempErrors = {
    ...tempErrors,
    country_id: userInfo.country_id ? "" : "ERRORS.REQUIRED.COUNTRY_ID",
  };
  tempErrors = {
    ...tempErrors,
    state_id: userInfo.state_id ? "" : "ERRORS.REQUIRED.STATE_ID",
  };
  tempErrors = {
    ...tempErrors,
    city_id: userInfo.city_id ? "" : "ERRORS.REQUIRED.CITY_ID",
  };
  tempErrors = {
    ...tempErrors,
    zip_code: userInfo.zip_code ? "" : "ERRORS.REQUIRED.ZIP_CODE",
  };

  return tempErrors;
};
