
// Generate Password fields
export const generatePasswords = {
    otp: "",
    password: "",
    confirm_password: "",
};

export const errorgeneratePassword = {
    otp: "",
    password: "",
    confirm_password: "",
};

