import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { PaginationState } from '@tanstack/react-table';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import moment from 'moment';
import {
  commanLabel,
  getAge,
  routesConstant,
} from '../../../constants/comman.constant';
import { socialWelfareTab } from '../../../shared/remake-green-utils';
import { Button } from 'react-bootstrap';
import { validationMessage } from '../../../constants/validation.message.constant';
import { useExportPersonalPdfMutation } from '../../../app/api/services/socialWelfare';
import AddActivity from '../../../components/addActivityPopup/addActivityFromReclaimer';
import GetGeoCoordinates from './getGeoCoordinatesPopup';

const ViewReclaimer = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const [data, setData] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchValue, setSearchValue] = useState('');
  const [peronalCSRPdf] = useExportPersonalPdfMutation();
  const [addentryPopup, setEntryPopup] = useState({
    preview: false,
    isShow: false,
  });
  const [reclaimerDetails, setReclaimerDetails] = useState({
    name: '',
    gender: '',
    contact_number: '',
    reclaimer_photo: '',
    reference_of: '',
    reference_value: '',
    identification_type: '',
    identification_value: '',
    nationality: '',
    date_of_birth: new Date(),
    data_fields: [],
    user_type: 4,
    reclaimer_category: '',
  });
  const getReclaimerDetails = async (reset: boolean, id: string) => {
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      // is_linked: false,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),

      search_text: reset ? '' : searchValue,
    };
    let path = apiUrl.getCSRReclaimerForCSRActivity + id;
    const fr: any = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setData(res?.data);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
  };

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    if (id) {
      getReclaimerDetails(false, id);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setReclaimerDetails({
        name: data.name,
        gender: data.gender,
        contact_number: data.contact_number,
        reclaimer_photo: data.reclaimer_photo,
        reference_of: data.reference_of,
        reference_value: data.reference_value,
        identification_type: data.identification_type,
        identification_value: data.identification_value,
        nationality: data.nationality,
        date_of_birth: new Date(data.birth_date),
        data_fields: JSON.parse(data.data_fields),
        user_type: data.user_type,
        reclaimer_category: data.reclaimer_category.name,
      });
    }
  }, [data]);

  const performSearch = (e: any) => {
    e.preventDefault();
    id && getReclaimerDetails(false, id);
  };

  const handeleReset = () => {
    setPage(1);
    setDateRange([null, null]);
    setSearchValue('');
    id && getReclaimerDetails(true, id);
  };

  const getExportedPdf = async () => {
    if (data?.other_csr_activity.length > 0) {
      const res: any = await peronalCSRPdf({
        reclaimer_id: Number(id),
      }).unwrap();
      if (res.success) {
        let path = apiUrl.prepareFile + res.data.certificateUrl;
        const fr: any = await helper.getData(path);
        const resData = await fr.response;
        if (resData.data) {
          window.open(
            process.env.REACT_APP_BASE_URL_API +
              apiUrl.downloadFile +
              res.data.certificateUrl
          );
          toast.success('Certificate Downloaded');
        } else {
          toast.error(res.message);
        }
      }
    } else {
      toast.warning('There is no activity to download');
    }
  };

  const handleCoordinatesPopup = (preview: boolean, isShow: boolean) => {
    setEntryPopup({ preview: preview, isShow: isShow });
  };
  return (
    <>
      {submitPopup && (
        <AddActivity
          title='Add Activity'
          message='Are you sure you want to Discard?'
          handleCancel={() => setSubmitPopup(!submitPopup)}
          cancelText='Cancel'
          submitText='Discard'
          handleSubmit={() => {
            if (id) {
              getReclaimerDetails(false, id);
              setSubmitPopup(false);
            }
          }}
        />
      )}
      <main>
        <div className='profile-page admin-profile-changes csr-addreclaimer'>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a
                    // href='/'
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className='breadcrumb-item'>
                  <a
                    // href=''
                    onClick={() => {
                      navigate('/admin/csr-activity', {
                        state: socialWelfareTab.RECLAIMER,
                      });
                    }}
                  >
                    CSR Activity
                  </a>
                </li>
                <li className='breadcrumb-item active'>View Profile</li>
              </ol>
            </nav>
            <div className='section-title-main'>
              <div className='title'>View Profile</div>
              {/* <div className='form-o-submit'>
                <a className='export-btn' onClick={getExportedPdf}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='21'
                    height='15.273'
                    viewBox='0 0 21 15.273'
                  >
                    <path
                      id='Path_export'
                      data-name='Path / export'
                      d='M22,11.636,18.182,7.818v2.864H9.591v1.909h8.591v2.864M1,17.364V5.909A1.909,1.909,0,0,1,2.909,4H14.364a1.909,1.909,0,0,1,1.909,1.909V8.773H14.364V5.909H2.909V17.364H14.364V14.5h1.909v2.864a1.909,1.909,0,0,1-1.909,1.909H2.909A1.909,1.909,0,0,1,1,17.364Z'
                      transform='translate(-1 -4)'
                    />
                  </svg>{' '}
                  Export
                </a>
              </div> */}
            </div>
            <div className='display-combine-component'>
              <div className='display-component main-profile-head'>
                <div className='display-head'>
                  <div className='title'>Profile Details</div>
                  {/* <div className='form-submit-buttons auto-width-button'>
                  
                    <button
                      type='button'
                      onClick={() =>
                        navigate(`${routesConstant.editReclaimer}${id}`)
                      }
                      className='btn btn-primary btn-sm btn btn-primary ms-3'
                    >
                      Edit Profile
                    </button>
                  </div> */}
                </div>
                <div className='display-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>Name</label>

                        <input
                          type='text'
                          name='name'
                          // onChange={handleReclaimerDetails}
                          value={reclaimerDetails.name}
                          placeholder=''
                          className='form-control'
                          disabled
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <label className='form-label form-label'>
                          Beneficiary
                        </label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              checked={reclaimerDetails.user_type == 4}
                              onClick={() => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  user_type: 4,
                                });
                              }}
                              name='isPOCDataProvider'
                              id='yes-icon1'
                              disabled
                              value='true'
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='yes-icon1'
                            >
                              Individual
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='isPOCDataProvider'
                              id='no-icon1'
                              checked={reclaimerDetails.user_type == 6}
                              onClick={() => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  user_type: 6,
                                });
                              }}
                              disabled
                              value='false'
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='no-icon1'
                            >
                              Group
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Beneficiary Category
                        </label>
                        <select
                          name='gender'
                          className='form-control form-select'
                          // value={reclaimerCategoryId}
                          disabled
                        >
                          <option value=''>
                            {reclaimerDetails.reclaimer_category}
                          </option>
                        </select>
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <label className='form-label form-label'>Gender</label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='gender'
                              id='yes-icon2'
                              checked={reclaimerDetails.gender == 'male'}
                              disabled
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='yes-icon2'
                            >
                              Male
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='gender'
                              id='no-icon2'
                              // value='false'
                              checked={reclaimerDetails.gender == 'female'}
                              disabled
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='no-icon2'
                            >
                              Female
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {reclaimerDetails.user_type == 4 && (
                      <div className='col-md-4'>
                        <div className='form-group date-input'>
                          <label className='form-label form-label'>
                            Birth Date
                          </label>
                          <div className='date-item-picker'>
                            <div className='date-picker-main'>
                              <input
                                type='text'
                                value={moment(
                                  reclaimerDetails.date_of_birth
                                ).format('DD-MM-YYYY')}
                                placeholder='Field Name'
                                className='form-control'
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row'>
                    {reclaimerDetails.reclaimer_photo && (
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <div className='mb-3'>
                            <label htmlFor='formFile' className='form-label'>
                              Reclaimer Photo
                            </label>
                            <div
                              className='upload-icons'
                              style={{
                                top: '0px',
                                justifyContent: 'flex-start',
                                marginTop: '10px',
                              }}
                            >
                              <i
                                className='fa fa-eye ms-2 me-2'
                                aria-hidden='true'
                                onClick={() =>
                                  window.open(reclaimerDetails.reclaimer_photo)
                                }
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row'>
                    {reclaimerDetails.data_fields.length > 0 &&
                      reclaimerDetails.data_fields.map((u: any, i: any) => {
                        return (
                          <div className='row'>
                            <div className='col-md-4'>
                              <div className='form-group required required'>
                                {i === 0 && (
                                  <label className='form-label form-label'>
                                    Data Fields
                                  </label>
                                )}
                                <input
                                  type='text'
                                  value={u.name}
                                  placeholder='Field Name'
                                  className='form-control'
                                  disabled
                                />
                                <span className='error'></span>
                              </div>
                            </div>
                            <div className='col-md-4'>
                              <div className='row'>
                                <div className='col-md-10'>
                                  <div className='form-group'>
                                    {i === 0 && (
                                      <label className='form-label form-label'></label>
                                    )}
                                    <input
                                      type='text'
                                      value={u.value}
                                      placeholder='Value'
                                      className='form-control'
                                      disabled
                                    />
                                    <span className='error'></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {reclaimerDetails.user_type == 4 && (
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <div className='mb-3 upload-section'>
                            <label
                              className='form-label form-label'
                              htmlFor='formFile'
                            >
                              Identification type
                            </label>
                            {/* <CreatableSelect
                              isClearable
                              value={identificationType}
                              onChange={handleIdentificationType}
                              options={IdentificationData}
                              placeholder={'Select Identification Type'}

                              // isDisabled={user_id !== undefined}
                            /> */}

                            <input
                              type='text'
                              name='identification_type'
                              // onChange={handleReclaimerDetails}
                              value={reclaimerDetails.identification_type}
                              placeholder=''
                              className='form-control'
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label form-label'>
                            Identification Number
                          </label>
                          <input
                            type='text'
                            name='identification_value'
                            value={reclaimerDetails.identification_value}
                            placeholder=''
                            className='form-control'
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label form-label'>
                          Reference Of
                        </label>
                        <input
                          type='text'
                          name='reference_of'
                          // onChange={handleReclaimerDetails}
                          value={reclaimerDetails.reference_of}
                          placeholder=''
                          className='form-control'
                          disabled
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label form-label'>
                          Reference Value
                        </label>
                        <input
                          type='text'
                          name='reference_value'
                          disabled
                          // onChange={handleReclaimerDetails}
                          value={reclaimerDetails.reference_value}
                          placeholder=''
                          className='form-control'
                        />
                      </div>
                    </div>
                    {reclaimerDetails.user_type == 4 && (
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label form-label'>
                            Nationality
                          </label>
                          <input
                            type='text'
                            placeholder='Nationality'
                            className='form-control'
                            name='nationality'
                            value={reclaimerDetails.nationality}
                            // onChange={handleReclaimerDetails}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {data?.other_csr_activity?.length > 0 && (
                <div
                  className='display-component table-data-main'
                  style={{ marginTop: 20, borderRadius: 20 }}
                >
                  <div
                    className='row header-area d-flex align-items-center'
                    style={{ border: 'none' }}
                  >
                    <div className='col-lg-3'>
                      <div className='title'>
                        {translate('CSR_MODULE.TITLES.ACTIVITY_LIST')}
                      </div>
                    </div>
                  </div>
                  {
                    <div
                      className='display-body ps-4 pe-4'
                      style={{ borderTop: '2px solid #3E9AB0' }}
                    >
                      <div className=' transaction-table listuser-table '>
                        <div className='tab-content'>
                          <div className='listing-transaction'>
                            <div className='profile-history-main'>
                              {data &&
                              data.other_csr_activity &&
                              data.other_csr_activity.length > 0 ? (
                                <div>
                                  {data.other_csr_activity.map(
                                    (u: any, i: any) => {
                                      return (
                                        <div
                                          className='profile-log-inner'
                                          style={{ marginTop: '10px' }}
                                        >
                                          <div className='log-header'>
                                            Activity Date :{' '}
                                            {moment(u.activity_date).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </div>
                                          <div className='table-responsive pt-4'>
                                            <table className='table'>
                                              <tbody>
                                                <tr>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.EVENT_NAME'
                                                    )}
                                                  </th>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.EVENT_ID'
                                                    )}
                                                  </th>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.DATE_OF_EVENT'
                                                    )}
                                                  </th>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.ORGANISER'
                                                    )}
                                                  </th>
                                                  <th>Event Category</th>
                                                  <th>Institute Involved</th>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    {u.other_csr_event.name}
                                                  </td>
                                                  <td>
                                                    {
                                                      u.other_csr_event
                                                        .cst_event_id
                                                    }
                                                  </td>
                                                  <td>
                                                    {moment(
                                                      u.other_csr_event
                                                        .start_date
                                                    ).format('DD/MM/YYYY')}{' '}
                                                    -{' '}
                                                    {moment(
                                                      u.other_csr_event.end_date
                                                    ).format('DD/MM/YYYY')}
                                                  </td>
                                                  <td>
                                                    {
                                                      u.other_csr_event
                                                        .organizer
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      u?.other_csr_event
                                                        ?.event_category?.name
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      u?.other_csr_event
                                                        ?.institution_involved
                                                    }
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className='table-responsive pb-4'>
                                            <table className='table'>
                                              <tbody>
                                                <tr>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.ACTIVITY_ID'
                                                    )}
                                                  </th>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.ACTIVITY_DATE'
                                                    )}
                                                  </th>
                                                  <th>
                                                    {translate(
                                                      'CSR_MODULE.TITLES.ACTIVITY_PHOTO'
                                                    )}
                                                  </th>
                                                  <th>Activity Category</th>
                                                </tr>
                                                <tr>
                                                  <td>{u.activity_id}</td>
                                                  <td>
                                                    {moment(
                                                      u.activity_date
                                                    ).format('DD/MM/YYYY')}
                                                  </td>
                                                  <td>
                                                    {u.activity_photo ? (
                                                      <a
                                                        onClick={() =>
                                                          window.open(
                                                            u?.activity_photo
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className='fa fa-eye ms-2 me-2'
                                                          aria-hidden='true'
                                                        ></i>
                                                      </a>
                                                    ) : (
                                                      '-'
                                                    )}
                                                  </td>
                                                  <td>
                                                    {u?.csr_sub_category?.name}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {isLoading && (
                                    <h3 className='center-message-item'>
                                      {translate('CSR_MODULE.TITLES.LOADING')}
                                    </h3>
                                  )}
                                  {data?.activity?.length === 0 &&
                                    !isLoading && (
                                      <h3 className='center-message-item'>
                                        {validationMessage.noRecordFound}
                                      </h3>
                                    )}
                                </div>
                              )}
                            </div>
                            {/* <div className='listing-content'>
                        <div className='table-footer'>
                          <div className='d-flex justify-content-between d-flex align-items-center'>
                            <div className='left-item text-left'>
                              Total Records : 1 / 1
                            </div>
                            <div className='right-item'>
                              <div className='items-center pagination-main gap-2 text-center'>
                                <button className='border rounded p-1'>
                                  &lt;
                                </button>
                                <ul className='pagination pagination-sm'>
                                  <li className='page-item active'>
                                    <span className='page-link'>1</span>
                                  </li>
                                </ul>
                                <button className='border rounded p-1'>
                                  &gt;
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                            {data?.other_csr_activity?.length > 0 && (
                              <div className='items-center pagination-main gap-2 text-center'>
                                <PaginationControl
                                  page={page}
                                  between={4}
                                  total={totalCount}
                                  limit={pageLimit}
                                  changePage={(page) => {
                                    setPage(page);
                                  }}
                                  ellipsis={2}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewReclaimer;
