import { otpPatern, passwordPatern } from "../../../constants/comman.validation.constant";

export const generatePasswordValidator = (errors: any, userInfo: any,) => {
    let tempErrors = { ...errors };
    if (userInfo.otp) {
        tempErrors = { ...tempErrors, otp: !new RegExp(otpPatern).test(userInfo.otp) ? 'ERRORS.INVALID.OTP' : '' }
    } else tempErrors = { ...tempErrors, otp: 'ERRORS.REQUIRED.OTP' }
    if (userInfo.password) {
        tempErrors = { ...tempErrors, password: !new RegExp(passwordPatern).test(userInfo.password) ? 'ERRORS.INVALID.PASSWORD' : '' }
    } else tempErrors = { ...tempErrors, password: 'ERRORS.REQUIRED.PASSWORD' }
    if (userInfo.confirm_password) {
        tempErrors = { ...tempErrors, confirm_password: userInfo.confirm_password !== userInfo.password ? 'ERRORS.INVALID.CONFIRMPASSWORD' : '' }
    } else tempErrors = { ...tempErrors, confirm_password: 'ERRORS.REQUIRED.CONFIRMPASSWORD' }
    return tempErrors;
}