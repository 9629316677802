import { creditComponent } from './credit-component.constant';

export const sdgGoals: string = '1, 3, 4, 6, 8, 11, 12, 13, 14, 15 & 17';
export enum certificateType {
  COLLECTION = 1,
  RECYCLING = 2,
  CIRCULAR = 3,
}
export enum materialType {
  PLASTIC = 12,
  ALUMINIUM = 35,
  CIRCULAR = 3,
}
export const certificateCategories: any = {
  [certificateType.COLLECTION]: {
    _components: [creditComponent.V1],
    get components() {
      return this._components;
    },
    set components(value) {
      this._components = value;
    },
    title: 'Collection Credit',
    otherPossibleTypes: [],
  },
  [certificateType.RECYCLING]: {
    components: [creditComponent.V2],
    title: 'Recycling Credit',
    otherPossibleTypes: [],
  },
  [certificateType.CIRCULAR]: {
    components: [creditComponent.V1, creditComponent.V2],
    title: 'Circular Credit',
    otherPossibleTypes: [certificateType.COLLECTION, certificateType.RECYCLING],
  },
};

export enum plasticCreditRequestStatus {
  REQUESTED = 1,
  APPROVED = 2,
  REJECTED = 3,
  COMPLETED = 4,
  PARTIAL = 5,
  CLOSED = 6,
}
