import axios, { AxiosError } from "axios";
import { persistor, store } from "../store";
import { RootState } from "../types/store";

export const helper = {
    getData: async (path:string, params?:any) => {
        const url = process.env.REACT_APP_BASE_URL_API + path;
        const state:RootState = store.getState();
        try
        {
        const res = await axios(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${state.auth.token}` || '',
          },
          params:{...params}
        });        
        return { response: await res.data, success: await res.data.success, message:res.data.message };
      }
      catch(error: any)
      {        
        if (error?.response?.data?.statusCode === 401) {
          await persistor.purge();   
          window.location.href = '/admin/login';
        }
        return { response: null, success: false, message:"" };
      }
      },
      formPost: async (request :any, path:string) => {
        const url = process.env.REACT_APP_BASE_URL_API + path;       
        const state:RootState = store.getState();       
        const res = await axios(url, {
          method: "POST",
          data:request,
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${state.auth.token}` || '',
          },         
        });
        return { response: await res.data, success: await res.data.success, message:res.data.message };
      },
}