import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode';
import { persistor } from '..';
import { User } from '../../types/authentication';

export const AUTH_ACTION = 'auth';
export const LOGOUT_ACTION= 'logout';

export const auth = createAsyncThunk(
  AUTH_ACTION,
  async (token:string, thunkAPI) => {
    try {           
      console.log(token);
      return {token, user: jwtDecode<User>(token,{header:false})};
    } catch (error: any) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message })
    }
  },
)
export const authLogout = createAsyncThunk(
  LOGOUT_ACTION,
  async () => { 
    console.log('logout');         
      await persistor.purge();  
      localStorage.removeItem("loader");
      localStorage.clear();     
  },
);
const initialState = {
  isLoggedIn: false,
  isFetching: false,
  user: {},
  token: ''
};
const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {    
  },
  extraReducers: (builder) => {
    builder.addCase(auth.pending, (state) => {
      state.token = '';
      state.user={};
      state.isFetching = true;
    })
    builder.addCase(auth.fulfilled, (state, { payload }) => {
      console.log("fulfilled");
      state.token = payload.token;
      state.user=payload.user;
      state.isLoggedIn = true;
      state.isFetching = false;
      console.log(state);
    })
    builder.addCase(auth.rejected, (state, action) => {
      state.user = {};
      state.token="";
      state.isLoggedIn = false;
      state.isFetching = false;
    })
  },
})
const { reducer } = authSlice;
export default reducer;
