import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { PaginationState } from '@tanstack/react-table';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import moment from 'moment';
import {
  commanLabel,
  getAge,
  routesConstant,
} from '../../../constants/comman.constant';
import { socialWelfareTab } from '../../../shared/remake-green-utils';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { validationMessage } from '../../../constants/validation.message.constant';
import AddActivity from '../../../components/addActivityPopup';

const AddEvent = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [data, setData] = useState<any>(null);
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [poc, setPOC] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPOCDataProvider, setIsPOCDataProvider] = useState(false);
  const [dataProvider, setDataProvider] = useState('');
  const [organiser, setOrganiser] = useState('');
  const [location, setLocation] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchValue, setSearchValue] = useState('');
  const [submitPopup, setSubmitPopup] = useState(false);

  const getEventDetails = async (reset: boolean, id: string) => {
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      // is_linked: false,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),

      search_text: reset ? '' : searchValue,
    };
    let path = apiUrl.getEvent + id;
    const fr: any = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setData(res?.data);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
  };

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    if (id) {
      getEventDetails(false, id);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setEventName(data.event.name);
      setPOC(data.event.on_ground_poc);
      setIsPOCDataProvider(data.event.is_on_ground_poc_data_provider);
      setLocation(data.event.location);
      setEventDate(data.event.event_date);
      setOrganiser(data.event.organizer);
      setDataProvider(data.event.data_provider);
    }
  }, [data]);

  const performSearch = (e: any) => {
    e.preventDefault();
    id && getEventDetails(false, id);
  };

  const handeleReset = () => {
    setPage(1);
    setDateRange([null, null]);
    setSearchValue('');
    id && getEventDetails(true, id);
  };

  return (
    <>
      {submitPopup && (
        <AddActivity
          title={`${translate('CSR_MODULE.TITLES.ADD_ACTIVITY')}`}
          message={`${translate('CSR_MODULE.TITLES.DISCARD_TEXT')}`}
          handleCancel={() => setSubmitPopup(!submitPopup)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          handleSubmit={() => {
            if (id) {
              getEventDetails(false, id);
              setSubmitPopup(false);
            }
            // if (id) {
            //   setEventData({
            //     eventName: data.event.name,
            //     eventDate: new Date(data.event.event_date),
            //     isPOCDataProvider: data.event.is_on_ground_poc_data_provider,
            //     dataProvider: data?.event?.data_provider,
            //     organiser: data.event.organizer,
            //     location: data.event.location,
            //     poc: data?.event?.on_ground_poc,
            //   });
            // } else
            //   setEventData({
            //     eventName: '',
            //     eventDate: new Date(),
            //     isPOCDataProvider: false,
            //     dataProvider: '',
            //     organiser: '',
            //     location: '',
            //     poc: '',
            //   });
            // setDiscardPopup(false);
          }}
        />
      )}

      <main>
        <div className='profile-page admin-profile-changes csr-addreclaimer'>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a // href='/'
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    {translate('CSR_MODULE.TITLES.HOME')}
                  </a>
                </li>
                <li className='breadcrumb-item'>
                  <a
                    onClick={() => {
                      navigate('/admin/social-welfare', {
                        state: socialWelfareTab.EVENT,
                      });
                    }}
                  >
                    {translate('CSR_MODULE.TITLES.SOCIAL_WELFARE')}
                  </a>
                </li>
                <li className='breadcrumb-item active'>{translate('CSR_MODULE.TITLES.VIEW_EVENT')}</li>
              </ol>
            </nav>
            <div className='section-title-main'>
              <div className='title'>{translate('CSR_MODULE.TITLES.VIEW_EVENT')}</div>
              {/* <div className='form-o-submit'>
                <a className='export-btn'>
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='21'
                    height='15.273'
                    viewBox='0 0 21 15.273'
                  >
                    <path
                      id='Path_export'
                      data-name='Path / export'
                      d='M22,11.636,18.182,7.818v2.864H9.591v1.909h8.591v2.864M1,17.364V5.909A1.909,1.909,0,0,1,2.909,4H14.364a1.909,1.909,0,0,1,1.909,1.909V8.773H14.364V5.909H2.909V17.364H14.364V14.5h1.909v2.864a1.909,1.909,0,0,1-1.909,1.909H2.909A1.909,1.909,0,0,1,1,17.364Z'
                      transform='translate(-1 -4)'
                    />
                  </svg>{' '}
                  {translate('CSR_MODULE.TITLES.EXPORT')}
                </a>
              </div> */}
            </div>
            <div className='display-combine-component'>
              <div className='display-component main-profile-head'>
                <div className='display-head'>
                  <div className='title'>{translate('CSR_MODULE.TITLES.EVENT_DETAILS')}</div>
                  <div className='form-submit-buttons auto-width-button'>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm btn btn-primary ms-3'
                      onClick={() =>
                        navigate(`${routesConstant.editEvent}${id}`)
                      }
                    >
                      {translate('CSR_MODULE.TITLES.EDIT_EVENT')}
                    </button>
                  </div>
                </div>
                <div className='display-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <label className='form-label'>{translate('CSR_MODULE.TITLES.EVENT_NAME')}</label>
                        <input
                          type='text'
                          className='form-control form-control'
                          value={eventName}
                          placeholder={`${translate('CSR_MODULE.TITLES.EVENT_NAME')}`}
                          disabled
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group date-input'>
                        <label className='form-label'>{translate('CSR_MODULE.TITLES.DATE_OF_EVENT')}</label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <div className='react-datepicker-wrapper'>
                              <div className='react-datepicker__input-container'>
                                <input
                                  type='text'
                                  placeholder='Select Date'
                                  className='form-control'
                                  value={moment(eventDate).format('DD/MM/YYYY')}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label'>{translate('CSR_MODULE.TITLES.ORGANISER')}</label>
                        <input
                          type='text'
                          className='form-control form-control'
                          value={organiser}
                          placeholder='Organiser'
                          disabled
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label'>
                          {translate('CSR_MODULE.TITLES.NAME_OF_POC')}
                        </label>
                        <input
                          type='text'
                          className='form-control form-control'
                          value={poc}
                          placeholder='On-Groun POC'
                          disabled
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <label className='form-label'>
                          {translate('CSR_MODULE.TITLES.IN_A_POC_DATAPROVIDER')}
                        </label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='material_color'
                              id='natural-button-icon'
                              checked={isPOCDataProvider}
                              // value={true}
                              disabled
                            />
                            <label
                              className='form-check-label'
                              htmlFor='natural-button-icon'
                            >
                              {translate('CSR_MODULE.TITLES.YES')}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='material_color'
                              id='color-button-icon'
                              checked={!isPOCDataProvider}
                              // value={false}
                              disabled
                            />
                            <label
                              className='form-check-label'
                              htmlFor='color-button-icon'
                            >
                              {translate('CSR_MODULE.TITLES.NO')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!isPOCDataProvider && (
                      <div className='col-md-4'>
                        <div className='form-group required required'>
                          <label className='form-label'>
                            {translate('CSR_MODULE.TITLES.NAME_OF_DATAPROVIDER')}
                          </label>
                          <input
                            type='text'
                            className='form-control form-control'
                            value={dataProvider}
                            placeholder={'Data Provider'}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label'>{translate('CSR_MODULE.TITLES.LOCATION')}</label>
                        <input
                          type='text'
                          className='form-control form-control'
                          value={location}
                          placeholder='Enter Location'
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='display-component table-data-main'
                style={{ marginTop: 20, borderRadius: 20 }}
              >
                <div
                  className='row header-area d-flex align-items-center'
                  style={{ border: 'none' }}
                >
                  <div className='col-lg-3'>
                    <div className='title'>{translate('CSR_MODULE.TITLES.ACTIVITY_LIST')}</div>
                  </div>
                  <div className='col-lg-9'>
                    <div className='top-filters three-type row d-flex justify-content-end'>
                      <div className='date-item-picker item-in col-lg-4 me-0'>
                        <div className='date-picker-main'>
                          <div className='react-datepicker-wrapper'>
                            <div className='react-datepicker__input-container'>
                              <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                value={startDate ? startDate : ''}
                                className='form-control'
                                placeholderText={`${translate('CSR_MODULE.TITLES.SELECT_ACTIVITY_DATE')}`}
                                dateFormat={commanLabel.dateFormat}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                onChange={(update: any) => {
                                  setDateRange(update);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='item-in col-lg-3 mb-3 mb-sm-3 mb-md-3 mb-lg-0'>
                        <div className='input-search-item form-group mb-0'>
                          <input
                            className='form-control'
                            id='searchicon'
                            placeholder={`${translate('CSR_MODULE.TITLES.SEARCH')}`}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          <i className='fa fa-search'></i>
                        </div>
                      </div>
                      <div className='search-button item-in col-lg-5 mt-1 d-flex justify-content-end'>
                        <Button
                          role='button'
                          onClick={performSearch}
                          title={`${translate('CSR_MODULE.TITLES.SEARCH')}`}
                          className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                        >
                          <i className='fa fa-search' aria-hidden='true'></i>
                        </Button>
                        <Button
                          role='button'
                          onClick={handeleReset}
                          title={`${translate('CSR_MODULE.TITLES.RESET')}`}
                          type='reset'
                          className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                        >
                          <i className='fa fa-refresh' aria-hidden='true'></i>
                        </Button>
                        <a
                          role='button'
                          // href='#'
                          // onClick={() => navigate(`${routesConstant.addEvent}`)}
                          onClick={() => setSubmitPopup(!submitPopup)}
                          title={`${translate('CSR_MODULE.TITLES.SEARCH')}`}
                          className='btn btn-primary btn-sm btn btn-primary ms-3'
                        >
                          <i className='fa fa-calendar' aria-hidden='true'></i>{' '}
                          {translate('CSR_MODULE.TITLES.ADD_ACTIVITY')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='display-body ps-4 pe-4'>
                  <div className=' transaction-table listuser-table '>
                    <div className='tab-content'>
                      <div className='listing-transaction'>
                        <div className='profile-history-main'>
                          {data && data.activity && data.activity.length > 0 ? (
                            <div>
                              {data.activity.map((u: any, i: any) => {
                                return (
                                  <div
                                    className='profile-log-inner'
                                    style={{ marginTop: '10px' }}
                                  >
                                    <div className='log-header'>
                                    {`${translate('CSR_MODULE.TITLES.ACTIVITY_DATE')}`} :{' '}
                                      {moment(u.activity_date).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </div>
                                    <div className='table-responsive pt-4'>
                                      <table className='table'>
                                        <tbody>
                                          <tr>
                                            <th>{translate('CSR_MODULE.TITLES.RECLAIMER_NAME')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.RECLAIMER_ID')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.AADHAR_CARD_NO')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.AGE')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.CONTACT_NUMBER')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.CAPACITY_PER_KG')}</th>
                                          </tr>
                                          <tr>
                                            <td>
                                              {u.csr_reclaimer.name || '-'}
                                            </td>
                                            <td>
                                              {u.csr_reclaimer.reclaimer_id}
                                            </td>
                                            <td>
                                              {' '}
                                              {u.csr_reclaimer.aadhaar_no ||
                                                '-'}
                                            </td>
                                            <td>
                                              {getAge(
                                                u.csr_reclaimer.birth_date
                                              )}
                                            </td>
                                            <td>
                                              {u.csr_reclaimer.contact_number ||
                                                '-'}
                                            </td>
                                            <td>
                                              {u.csr_reclaimer
                                                .capacity_per_day || '-'}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className='table-responsive pb-4'>
                                      <table className='table'>
                                        <tbody>
                                          <tr>
                                            <th>{translate('CSR_MODULE.TITLES.ACTIVITY_ID')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.ACTIVITY_DATE')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.ACTIVITY_PHOTO')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.AADHAR_PHOTO')}</th>
                                            <th>{translate('CSR_MODULE.TITLES.MATERIAL_PROVIDED')}</th>
                                          </tr>
                                          <tr>
                                            <td>{u.activity_id}</td>
                                            <td>
                                              {moment(u.activity_date).format(
                                                'DD/MM/YYYY'
                                              )}
                                            </td>
                                            <td>
                                              {u.activity_photo ? (
                                                <a
                                                  onClick={() =>
                                                    window.open(
                                                      u?.activity_photo
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className='fa fa-eye'
                                                    aria-hidden='true'
                                                  ></i>
                                                </a>
                                              ) : (
                                                '-'
                                              )}
                                            </td>
                                            <td>
                                              {u?.csr_reclaimer
                                                ?.aadhaar_photo ? (
                                                <a
                                                  onClick={() =>
                                                    window.open(
                                                      u?.csr_reclaimer
                                                        ?.aadhaar_photo
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className='fa fa-eye'
                                                    aria-hidden='true'
                                                  ></i>
                                                </a>
                                              ) : (
                                                '-'
                                              )}
                                            </td>
                                            <td>
                                              <div>
                                                {u?.csr_material.map(
                                                  (data: any, i: any) => {
                                                    return (
                                                      <span>
                                                        {data.name}
                                                        {i + 1 <
                                                          u?.csr_material
                                                            ?.length && ', '}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {isLoading && (
                                <h3 className='center-message-item'>
                                  {translate('CSR_MODULE.TITLES.LOADING')}
                                </h3>
                              )}
                              {data?.activity?.length === 0 && !isLoading && (
                                <h3 className='center-message-item'>
                                  {validationMessage.noRecordFound}
                                </h3>
                              )}
                            </div>
                          )}
                        </div>
                        {/* <div className='listing-content'>
                        <div className='table-footer'>
                          <div className='d-flex justify-content-between d-flex align-items-center'>
                            <div className='left-item text-left'>
                              Total Records : 1 / 1
                            </div>
                            <div className='right-item'>
                              <div className='items-center pagination-main gap-2 text-center'>
                                <button className='border rounded p-1'>
                                  &lt;
                                </button>
                                <ul className='pagination pagination-sm'>
                                  <li className='page-item active'>
                                    <span className='page-link'>1</span>
                                  </li>
                                </ul>
                                <button className='border rounded p-1'>
                                  &gt;
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                        {data?.activity?.length > 0 && (
                          <div className='items-center pagination-main gap-2 text-center'>
                            <PaginationControl
                              page={page}
                              between={4}
                              total={totalCount}
                              limit={pageLimit}
                              changePage={(page) => {
                                setPage(page);
                              }}
                              ellipsis={2}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddEvent;
