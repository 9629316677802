import moment from 'moment';
import React from 'react';
import { getAge } from '../../../../constants/comman.constant';
import { maskPCId, maskaadhar } from '../../../../utils/utilFunctions';

const CSRActivityList = (props: any) => {
  const { data } = props;
  return (
    <>
    { props.hideHeader === false &&
      <div className="reclember-certificate-page">
        <div className="container-items header-bg-img">
          <div className='main-header container-space'>
            <div className="header-conetent">
              {/* <div className="header-bg">
                <img src={`${process.env.PUBLIC_URL}/assets/images/certificate/header-reclaim.png`} className="full-width" />
              </div> */}
              <h5 className='header-title'>
                ReMake Social
              </h5>
              <p className='header-subject'>reclaimer outreach program</p>
              <div className="left-section">
                <div className="text-top">
                  <p>"Giving Back to
                    Society"
                  </p>
                </div>
                <div className="certificate-type">
                  <p className="title">{data.length}</p>
                  <p className="description">RECLAIMERS EMPOWERED</p>
                </div>
                <div className="activity-date">
                  <p className="date-title">Activity Date</p>
                  <p className="date-area"><span>{moment(data[0]?.activity_date).format('DD/MM/YYYY')}</span> to <span>{moment(data[data.length -1]?.activity_date).format('DD/MM/YYYY')}</span></p>
                </div>
              </div>

              <div className="center-section">
                <div className="logo-area">
                  <a href="https://remake.green/" target="_blank" rel="noreferrer">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/certificate/header-logo.svg`} alt="header-logo" />
                  </a>
                </div>
                <div className="remake-green-link">
                  <a href="https://remake.green/" target="_blank" rel="noreferrer">https:/remake.green</a>
                </div>
              </div>
              <div className='right-section'>
              </div>
            </div>
          </div>
        </div>
      </div>
}
      <div className='reclaimer-activity-details mt-5'>
      { props.hideHeader === false &&
        <h3 className='title-line'>Reclaimer Activity Details</h3>
      }
        {data &&
          data.map((u: any, i: any) => {
            return (
              <div className='reclaimer-box'>
                <div className='row'>
                  <div className='col-sm-6 col-md-6 col-lg-4 left-area'>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Date of Activity</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {moment(u?.activity_date).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Event Name</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_event?.name || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Reclaimer Name</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_reclaimer?.name || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Gender</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_reclaimer?.gender || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Age</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {getAge(u?.csr_reclaimer?.birth_date)}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Mobile Number</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {maskPCId(u?.csr_reclaimer?.contact_number) || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Aadhaar Number</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {maskaadhar(u?.csr_reclaimer?.aadhaar_no) || '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-6 col-lg-5 right-area'>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Permanent Address</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_reclaimer?.address || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Children</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_reclaimer?.childrens || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Capacity per Day</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_reclaimer?.capacity_per_day
                            ? u?.csr_reclaimer?.capacity_per_day + ' KG'
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Small Scrap Dealer</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_reclaimer?.small_scrap_dealer || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Collection Centre</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_reclaimer?.csr_collection_center?.name || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Location</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {' '}
                          {u?.csr_event?.location || '-'}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-5'>
                        <div className='title-text'>Material Provided</div>
                      </div>
                      <div className='col-lg-7'>
                        <div className='content-text'>
                          {u?.csr_material.map((data: any, i: any) => {
                            return (
                              <span>
                                {data.name}
                                {i + 1 < u?.csr_material?.length && ', '}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-3'>
                    <div className='image-section'>
                      {u?.csr_reclaimer?.aadhaar_photo && (
                        <div
                          className='image-box'
                          style={{
                            height: '200px',
                            width: '100%',
                            maxWidth: '300px',
                          }}
                        >
                          <img
                            src={u?.csr_reclaimer?.aadhaar_photo}
                            style={{
                              filter: 'blur(3px)',
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </div>
                      )}
                      {u?.activity_photo && (
                        <div
                          className='image-box'
                          style={{
                            height: '200px',
                            width: '100%',
                            maxWidth: '300px',
                          }}
                        >
                          <img
                            className='mt-3'
                            src={u?.activity_photo}
                            style={{
                              filter: 'blur(3px)',
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default CSRActivityList;
