import { plasticCreditApi } from "..";
import {
  LoginMutationInput,
  LoginMutationOutput,
} from "../../types/authentication";
import { ServiceResponseInterface } from "../../types/serverResponse";
import { apiUrl } from "./apiUrl";

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({

    deleteFile: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.file_delete,
        method: "DELETE",
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {useDeleteFileMutation} = extendedApi;
