import { UserProfileStatus } from '../shared/remake-green-utils';
import { PCRStatus } from '../shared/remake-green-utils/common/constants/enum';
import { CertificateType, certificateType } from './certificate.constant';

export const imagePath = {
  collectionCreditPath: '/assets/images/buy-collection-credit.svg',
  scalingCollectionRecyclingPath: '/assets/images/truck-svgrepo-com.png',
  financialIngenuityPath:
    '/assets/images/presentation-business-and-finance-svgrepo-com.png',
  economyPlayersPath: '/assets/images/connected-ecosystem-svgrepo-com.png',
  independentBodiesPath: '/assets/images/check-svgrepo-com.png',
  reMakePlasticCreditPath: '/assets/images/remake-logo-1.png',
  arrowLeft: '/assets/images/arrow-left-long-solid.svg',
  socialValueMarketplacePath: '/assets/images/Remake Logo.png',
  reMakeTokensPath: '/assets/images/social-svgrepo-com.png',
  slidesOnePath: '/assets/images/Circular economy (1).png',
  slidesTwoPath: '/assets/images/Plastic credit2 (1).png',
  plasticCreditPath: '/assets/images/remake-logo-1.png',
  ledgerPath: '/assets/images/file-input.svg',
  starPath: '/assets/images/rank.svg',
  recyclingCreditPath: '/assets/images/buy-recycling-credit.svg',
  circularCreditPath: '/assets/images/buy-circular-credit.svg',
  recycleLogoPath: '/assets/images/recycle_logo.png',
  formImgPath: '/assets/images/form-img.png',
  pdfPath: '/assets/images/pdf-icon.svg',
  jpgPath: '/assets/images/jpg-icon.svg',
  excelPath: '/assets/images/excel-logo.svg',
  docsLogo: '/assets/images/docs-logo.svg',
  deletePath: '/assets/images/delete-icon.svg',
  sharePath: '/assets/images/share.svg',
  plusIconPath: '/assets/images/plus icon-svgrepo-com.svg',
  reqindPath: '/assets/images/rewind-time-svgrepo-com.svg',
  editPath: '/assets/images/Edit.svg',
  deletePopIconPath: '/assets/images/delete-pop-icon.svg',
  eyePath: '/assets/images/eye-svgrepo-com.svg',
  downloadPath: '/assets/images/download.svg',
  profileImgPath: '/assets/images/user.svg',
  exportIcon: '/assets/images/export.svg',
};

export const commanLabel = {
  page: 1,
  pageLimit: 20,
  remakeCertificateType: 14,
  userNameLabel: 'User Full Name',
  companyNameLabel: 'Company Full Name',
  userName: 'Enter User Name',
  companyName: 'Enter Company Name',
  kgLabel: 'Kg',
  tonneLabel: 'Tonne',
  countryCode: '+91',
  no_record_found: 'No record found...',
  no_more_record_found: 'No more records found...',
  no_more_ledger: 'No more Ledger records found',
  currency: 'USD',
  submit: 'Submit',
  cancel: 'Cancel',
  discard: 'Discard',
  defaultRegionId: '104',
  register: 'Register',
  pdf: 'PDF',
  png: 'PNG',
  jpg: 'JPG',
  jpeg: 'JPEG',
  docs: 'VND OPENXMLFORMATS OFFICEDOCUMENT WORDPROCESSINGML DOCUMENT',
  docs_word: 'VND OPENXMLFORMATS OFFICEDOCUMENT WORDPROCESSINGML DOCUMENT',
  excel: 'VND OPENXMLFORMATS OFFICEDOCUMENT SPREADSHEETML SHEET',
  password: 'password',
  text: 'text',
  dateFormat: 'dd/MM/yyyy',
};

export const lazy_load_table_setting = {
  lazy_Loading_table_Height: 360,
  lazy_Loading_No_Data_table_Height: 150,
};
export const creditTypeLabel = {
  collectionLabel: 'Collection Credit Request',
  recyclingLabel: 'Recycling Credit Request',
  circularLabel: 'Circular Credit Request',
};

export const fileType = {
  Invoice: 'Invoice',
  EWayReceipt: 'E-way/Lorry Receipt',
  LoadingSlip: 'Loading Slip',
  UnLoadingSlip: 'Un-Loading Slip',
  Others: 'Others',
  RecyclingCertificate: 'Recycling Certificate',
  ExcelImport: 'Excel Import',
};

export const statusType = [
  { id: 1, name: 'Requested' },
  { id: 2, name: 'Approved' },
  { id: 3, name: 'Rejected' },
  { id: 4, name: 'Completed' },
  { id: 5, name: 'Partial' },
  { id: 6, name: 'Closed' },
];

export const preferredCurrency = [
  { value: 'usd', name: 'USD' },
  { value: 'eur', name: 'EUR' },
  { value: 'inr', name: 'INR' },
  { value: 'gbp', name: 'GBP' },
  { value: 'aud', name: 'AUD' },
];

export const measurementTypes = [{ name: 'Tonne' }, { name: 'Kg' }];

export const VolumeType = {
  KG: 'kg',
  TON: 'tonne',
};
export const BoolenType = {
  False: 'false',
  True: 'true',
};
export const ColorType = {
  Natural: 'natural',
  Colored: 'colored',
  Mixed: 'mixed',
  NA: 'N/A',
};

export const materialSubCategory = {
  PET: 'PET',
  LET: 'LET',
  LDPE: 'LDPE',
};

export const routesConstant = {
  contactUs: '/contact-us',
  aboutUs: '/about-us',
  comingSoon: '/coming-soon',
  sdgGoals: '/sdg-goal',
  faq: '/faq',
  login: '/login',
  plasticCreditRequest: '/admin/plasticCreditRequest',
  generateCertificate: '/admin/generateCertificate',
  transactionList: '/admin/transaction/list',
  default: '/',
  createRemake: '/remake-user/create-remake-account',
  profile: '/admin/profile',
  generatePassword: '/generate-password',
  passwordUpdate: '/password-update',
  remakeUserDashboard: '/remake-user/dashboard',
  valueChainPartner: '/value-chain-partner',
  signUp: '/signup',
  forgotPassword: '/forgot-password',
  verifySignupOtp: '/verify-signup-otp',
  remakeUserProfile: '/remake-user/profile',
  creditRequest: '/credit-request/',
  remakeCreditRequest: '/remake-user/credit-request/',
  plasticCreditCertification: '/admin/transaction/plasticCreditCertification/',
  plasticUserCreditCertification: '/transaction/plasticCreditCertification/',
  userManagement: '/admin/userManagement',
  remakeUserUpdatePassword: '/remake-user/update-password',
  plasticCreditDownload:
    '/plastic-credit-request-management/plastic-credit/dowload?credit_id=',
  plasticCreditManagementList: '/remake-user/plastic-credit-management/',
  remakeUserViewProfileLog: '/remake-user/profile-log',
  socialWelfare: '/admin/social-welfare',
  addEvent: '/admin/social-welfare/add-event',
  addReclaimer: '/admin/social-welfare/add-reclaimer',
  viewReclaimer: '/admin/social-welfare/reclaimer/',
  viewEvent: '/admin/social-welfare/event/',
  editEvent: '/admin/social-welfare/edit-event/',
  editReclaimer: '/admin/social-welfare/edit-reclaimer/',
  addActivity: '/admin/social-welfare/add-activity',
  CSRActivity: '/admin/csr-activity',
  addCSRActivity: '/admin/csr-activity/add-activity',
  addCSREvent: '/admin/csr-activity/add-event',
  addCSRReclaimer: '/admin/csr-activity/add-reclaimer',
  viewCSRReclaimer: '/admin/csr-activity/reclaimer/',
  viewCSREvent: '/admin/csr-activity/event/',
  domainManagement: '/admin/domainManagement',
  footprintManagement: '/admin/footprintManagement',
};

export const PCRequestType = {
  PENDING: 'pending',
  REQUESTED: 'requested',
  ALL: 'all',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  CLOSED: 'closed',
  PARTIAL: 'partial',
};

export const transactionRegistryFileUploadSections = {
  invoice: 'invoice',
  loadWeightSlip: 'loading_weight_slip',
  unLoadWeightSlip: 'unloading_weight_slip',
  ewayLorryReceipt: 'eway_or_lorry_reciept',
  debitNote: 'debit_note',
  GRN: 'GRN',
  additionalDocuments: 'additional_documents',
  recyclingCertificate: 'recycling_certificate',
  excelImport: 'excel_import',
};
export enum UserType {
  ADMIN = 1,
  REMAKE = 2,
  VALUE_CHAIN_PARTNER = 3,
  INDIVIDUAL = 4,
  COMPANY = 5,
}

export const UserTypes = [
  // { name: "Admin", value: 1 },
  // { name: "Remake User", value: 2 },
  // { name: "Value Chain Partner", value: 3 },
  { name: 'Individual', value: 4 },
  { name: 'Company', value: 5 },
];

export const ReclaimerUserTypes = [
  { name: 'Individual', value: 4 },
  { name: 'Group', value: 6 },
];

export const getUserType = (userType: any) => {
  switch (userType) {
    case UserType.ADMIN:
      return 'Admin';
    case UserType.REMAKE:
      return 'Rmake';
    case UserType.VALUE_CHAIN_PARTNER:
      return 'Value chain Partner';
    case UserType.INDIVIDUAL:
      return 'Individual';
    case UserType.COMPANY:
      return 'Company';

    default:
      break;
  }
};

export const getCreditType = (status: any) => {
  switch (status) {
    case CertificateType.COLLECTION:
      return certificateType.COLLECTION;
    case CertificateType.CIRCULAR:
      return certificateType.CIRCULAR;
    case CertificateType.RECYCLING:
      return certificateType.RECYCLING;

    default:
      break;
  }
};

export const inwardTransferExcelURL =
  '/assets/excel-sample/TRP_InwardTransfer_Sample.xlsx';
export const regularExcelSampleURL =
  '/assets/excel-sample/TRP_Regular_Sample.xlsx';
export const privacyPDF =
  'https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1677736053713-20230228_ReMake%20Privacy%20Policy_Final.pdf';

export const termsPDF =
  'https://azgemcorpblobstorage.blob.core.windows.net/remakegreen-production/1677736095296-20230228_ReMake Terms of Use_Final.pdf';
export const quantityUnitConstants = {
  TONNE: 'TONNE',
  TON: 'TON',
  KG: 'KG',
  tonne: 'Tonne',
};

export const valueChainPartnerRequestTypes = [
  { name: "Pending user request", value: UserProfileStatus.PENDING },
  { name: "Rejected user request", value: UserProfileStatus.REJECTED },
  { name: "Approved user request", value: UserProfileStatus.APPROVED },
];

export const valueChainPartnerContactData = [
  {
    name: 'New',
    value: '',
  },
  {
    name: 'Contacted',
    value: 1,
  },
];

export const domainStatus = [
  {
    name: 'Active',
    value: 1,
  },
  {
    name: 'Inactive',
    value: 0,
  },
];

export function getAge(dateString: any) {
  let today = new Date();
  let birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
