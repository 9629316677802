import _, { every, isObject } from "lodash";
import { VolumeType } from "../../constants/comman.constant";

export const calculateCreditPoint = (unloadedQuantity: volume) =>
  unloadedQuantity?.unit?.toLowerCase() === VolumeType.KG
    ? unloadedQuantity?.quantity / 1000
    : unloadedQuantity?.quantity;

export const isEmptyDeep = (obj: any): boolean => {
  if (isObject(obj)) {
    if (Object.keys(obj).length === 0) return true
    return every(_.map(obj, (v: any) => { return isEmptyDeep(v) }))
  } else if (_.isString(obj)) {
    return !obj.length
  }
  return false;
}
export const isEmptyJSON = (errs: any) => Object.keys(errs).every((err) => errs[err] === '');
export const getNoRecordMessage = (msg: string, obj: any, selectedTab: any) => {
 let selectedVal = obj.find((el: any) => el.value === selectedTab);
  return selectedVal ? (msg + selectedVal?.name[0].toUpperCase() + selectedVal?.name.slice(1).toLowerCase()) : "No record found.";
}