import { plasticCreditApi } from "..";
import { ServiceResponseInterface } from "../../types/serverResponse";
import { apiUrl } from "./apiUrl";

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({
    createInquiry: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.createUserInquiry,
        method: "POST",
        body: data,
      }),
    }),
    createReMakeUserInquiry: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.createReMakeUserInquiry,
        method: "POST",
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateInquiryMutation,
  useCreateReMakeUserInquiryMutation
} = extendedApi;
