import {
  commonInput,
  emailPatern,
  mobilePatern,
  commanValidation,
} from '../../../constants/comman.validation.constant';
import {
  validationMessage,
  RequiredError,
} from '../../../constants/validation.message.constant';
import { commanLabel } from '../../../constants/comman.constant';

export const generateCertificateValidator = (
  errors: any,
  certInfo: any,
  selectedTransaction: any,
  setSelectedTransaction: any
) => {
  let tempErrors = { ...errors };

  if (certInfo.transactionIds) {
    let newArray = [...selectedTransaction];
    for (let index = 0; index < newArray.length; index++) {
      if (
        +newArray[index].selectedQuantity > +newArray[index].remaining_quantity
      ) {
        console.log(
          'Inside Ifff',
          +newArray[index].selectedQuantity,
          +newArray[index].remaining_quantity
        );
        newArray[index].quantityError =
          'Entered quantity should not be greater than remaining quantity.';
      } else {
        newArray[index].quantityError = '';
      }
    }

    let newArray1 = newArray.filter((u: any, i: any) => {
      return u.quantityError.length > 0;
    });

    if (newArray1.length > 0) {
      tempErrors = {
        ...tempErrors,
        quantityToBeUsed: 'Please enter proper quanity.',
      };
    } else {
      tempErrors = {
        ...tempErrors,
        quantityToBeUsed: '',
      };
    }

    setSelectedTransaction(newArray);
  }

  if (certInfo.userName) {
    if (!commonInput.test(certInfo.userName)) {
      tempErrors = {
        ...tempErrors,
        userName: validationMessage.invalidUserName,
      };
    } else {
      tempErrors = { ...tempErrors, userName: '' };
    }
  } else tempErrors = { ...tempErrors, userName: RequiredError.userName };

  if (certInfo.userEmail) {
    if (!new RegExp(emailPatern).test(certInfo.userEmail)) {
      tempErrors = {
        ...tempErrors,
        userEmail: validationMessage.ENTER_A_VALID_EMAIL_ADDRESS,
      };
    } else {
      tempErrors = { ...tempErrors, userEmail: '' };
    }
  } else tempErrors = { ...tempErrors, userEmail: RequiredError.credit_email };

  if (certInfo.quantity) {
    const minValue =
      certInfo.quantityUnit === commanLabel.kgLabel
        ? commanValidation.buyerMinKg
        : commanValidation.buyerMinTonne;
    // if (minValue > certInfo.quantity) {
    //   tempErrors = {
    //     ...tempErrors,
    //     quantity:
    //       certInfo.quantityUnit === commanLabel.kgLabel
    //         ? validationMessage.kgInvalid
    //         : validationMessage.tonInvalid,
    //   };
    // } else {
    //   tempErrors = { ...tempErrors, quantity: '' };
    // }
  } else tempErrors = { ...tempErrors, quantity: RequiredError.quantity };

  tempErrors = {
    ...tempErrors,
    materialTypeId: certInfo.materialTypeId ? '' : RequiredError.materialTypeId,
  };
  tempErrors = {
    ...tempErrors,
    categoryTypeId: certInfo.materialTypeId
      ? certInfo.categoryTypeId
        ? ''
        : RequiredError.categoryTypeId
      : '',
  };
  tempErrors = {
    ...tempErrors,
    regionId: certInfo.regionId ? '' : RequiredError.regionId,
  };

  return tempErrors;
};
