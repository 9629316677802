import {
  commanValidation,
  commonInput,
  emailPatern,
  mobilePatern,
} from "../../../../constants/comman.validation.constant";

export const individualProfileValidator = (
  errors: any,
  individualProfileInfo: any
) => {
  let tempErrors = { ...errors };
  const getExpressionError = (
    value: any,
    expPattern: any,
    invalidErr: any,
    requiredErr?: any
  ) => {
    return value
      ? !new RegExp(expPattern).test(value)
        ? invalidErr
        : ""
      : requiredErr
      ? requiredErr
      : "";
  };

  tempErrors = {
    ...tempErrors,
    // profile_image: individualProfileInfo?.profile_image ? '' : 'ERRORS.REQUIRED.PROFILE_IMAGE',
    user_name: getExpressionError(
      individualProfileInfo.user_name,
      commonInput,
      "ERRORS.INVALID.USERNAME",
      "ERRORS.REQUIRED.USERNAME"
    ),
    contact_number: getExpressionError(
      individualProfileInfo.contact_number,
      mobilePatern,
      "ERRORS.INVALID.CONTACT_NO",
      "ERRORS.REQUIRED.CONTACT_NO"
    ),
    email: getExpressionError(
      individualProfileInfo.email,
      emailPatern,
      "ERRORS.INVALID.EMAIL_ADDRESS",
      "ERRORS.REQUIRED.EMAIL"
    ),
    alternate_email: getExpressionError(
      individualProfileInfo.alternate_email,
      emailPatern,
      "ERRORS.INVALID.EMAIL_ADDRESS"
    ),
    preferred_currency: individualProfileInfo.preferred_currency
      ? ""
      : "ERRORS.REQUIRED.CURRENCY",
    countryId: individualProfileInfo.countryId ? "" : "ERRORS.REQUIRED.COUNTRY",
    stateId: individualProfileInfo.stateId ? "" : "ERRORS.REQUIRED.STATE",
    cities: individualProfileInfo.cities ? "" : "ERRORS.REQUIRED.CITY",
    zip_code: individualProfileInfo.zip_code ? "" : "ERRORS.REQUIRED.ZIP_CODE",
  };

  return tempErrors;
};
