import {
  commonInput,
  emailPatern,
  mobilePatern,
  commanValidation,
  aadhaarNumber,
} from '../../../constants/comman.validation.constant';
import {
  validationMessage,
  RequiredError,
} from '../../../constants/validation.message.constant';
import { commanLabel } from '../../../constants/comman.constant';

export const createReclaimerValidator = (errors: any, reclaimerInfo: any) => {
  let tempErrors = { ...errors };
  console.log('reclaimerInfo:::', reclaimerInfo);
  if (reclaimerInfo.name) {
    if (!commonInput.test(reclaimerInfo.name)) {
      tempErrors = {
        ...tempErrors,
        reclaimerName: validationMessage.invalidProfileName,
      };
    } else {
      tempErrors = { ...tempErrors, reclaimerName: '' };
    }
  } else
    tempErrors = { ...tempErrors, reclaimerName: RequiredError.profileName };

  if (reclaimerInfo.reference_of !== '') {
    console.log('Inside Ifff :::', reclaimerInfo.reference_of);

    if (reclaimerInfo.reference_value) {
      if (!commonInput.test(reclaimerInfo.reference_value)) {
        tempErrors = {
          ...tempErrors,
          referenceValue: validationMessage.invalidReferenceOf,
        };
      } else {
        tempErrors = { ...tempErrors, referenceValue: '' };
      }
    } else
      tempErrors = {
        ...tempErrors,
        referenceValue: RequiredError.referenceValue,
      };
  }

  return tempErrors;
};
