import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GetGeoCoordinates from './getGeoCoordinatesPopup';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  isValidFile,
  socialWelfareTab,
} from '../../../shared/remake-green-utils';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import ConfirmationPopup from '../../../components/confirmationPopup';

import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { useCreateCSRActivityMutation } from '../../../app/api/services/csrActivity';
import moment from 'moment';
import { routesConstant } from '../../../constants/comman.constant';
import { createActivityValidator } from './activityValidator';

const IdentificationData = [
  { value: 'Aadhaar Card', label: 'Aadhaar Card' },
  { value: 'Citizenship Document', label: 'Citizenship Document' },
  { value: 'Voter Id', label: 'Voter Id' },
];

const AddActivity = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const [reclaimerDetails, setReclaimerDetails] = useState({
    name: '',
    gender: '',
    contact_number: '',
    reclaimer_photo: '',
    reference_of: '',
    reference_value: '',
    identification_type: '',
    identification_value: '',
    nationality: '',
    date_of_birth: new Date(),
    data_fields: [],
    user_type: 4,
  });

  const [activityDetails, setActivityDetails] = useState<{
    [key: string]: any;
  }>({
    describe: '',
    activity_photos: [],
    activity_date: new Date(),
    // materialIds: null,
    impact_description: '',
    activity_reference: '',
    documents: [],
    // imapact_action:"",
    // isOtherMaterial: false,
  });

  const [eventDetails, setEventDetails] = useState({
    name: '',
    start_date: new Date(),
    end_date: new Date(),
    organizer: '',
    participating_organisations: [],
    geo_coordinates: [],
    event_photo: '',
    sub_category: '',
    institute_involved: '',
    region: '',
  });

  const [errors, setErrors] = useState<any>({
    eventCategory: '',
    activityCategory: '',
    eventName: '',
    organizer: '',
    region: '',
    describe: '',
    imapctDescription: '',
    reclaimerName: '',
    referenceValue: '',
  });

  const [eventName, setEventName] = useState<any>(null);
  const [identificationType, setIdentificationType] = useState<any>(null);
  const [reclaimerName, setReclaimerName] = useState<any>(null);
  const [reclaimerId, setReclaimerId] = useState(0);
  const [eventList, setEventList] = useState([]);
  const [reclaimerList, setReclaimerList] = useState([]);
  const [reclaimerCategoryData, setReclaimerCategoryData] = useState([]);
  const [reclaimerCategoryId, setReclaimerCategoryId] = useState('');
  const [eventId, setEventId] = useState(0);
  const [eventCategoryData, setEventCategoryData] = useState([]);
  const [eventCategoryId, setEventCategoryId] = useState('');
  const [submitPopup, setSubmitPopup] = useState(false);
  const [isSubmitted, setIsSubmited] = useState(false);
  const [activityCategoryData, setActivityCategoryData] = useState([]);
  const [activityCategoryId, setActivityCategoryId] = useState<any>(null);
  const [activityCategoryName, setAcitivityCategoryName] = useState<any>(null);
  const [documentFiles, setDocumentFiles] = useState<any>([]);
  const [addActivity] = useCreateCSRActivityMutation();
  const [participatingOrganisations, setParticipatingOrganisations] =
    useState<any>(['']);
  const [impactAction, setImpactAction] = useState<any>([
    { name: '', value: '' },
  ]);
  const [dataField, setDataField] = useState<any>([{ name: '', value: '' }]);

  const [countries, setCountries] = useState<any>([]);

  const [addentryPopup, setEntryPopup] = useState({
    preview: false,
    isShow: false,
  });

  const handleReclaimerDetails = (e: any) => {
    setReclaimerDetails({
      ...reclaimerDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleEventDetails = (e: any) => {
    setEventDetails({
      ...eventDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleActivityDetails = (e: any) => {
    setActivityDetails({
      ...activityDetails,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = (
    reclaimerValue = reclaimerDetails,
    eventValue = eventDetails,
    activityValue = activityDetails,
    eventcategory = eventCategoryId,
    activityCategory = activityCategoryId,
    formSubmit = false
  ) => {
    let errs: any = {
      ...createActivityValidator(
        errors,
        eventDetails,
        reclaimerDetails,
        activityDetails,
        eventCategoryId,
        activityCategoryId
      ),
    };
    setErrors(errs);
    // errs = { ...createEventValidator(errors, eventValue) };
    // setErrors(errs);
    const isFormValid = Object.keys(errs).every(
      (err) => errs[err].length === 0
    );
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      setSubmitPopup(true);
    }
  };

  const getReclaimerCategoryDetails = async () => {
    let path = apiUrl.getReclaimerCategory;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setReclaimerCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const getEventList = async () => {
    let path = apiUrl.getCSREventList;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data.data;
      const newArray =
        temArray &&
        temArray.map((item: any) => {
          return {
            value: item.id,
            label: item.cst_event_id + ' - ' + item.name,
          };
        });
      newArray && setEventList(newArray);
    } else {
      toast.error(res.message);
    }
  };

  const getReclaimerList = async () => {
    let path = apiUrl.getCSRReclaimerList;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data.data;
      const newArray =
        temArray &&
        temArray.map((item: any) => {
          return {
            value: item.id,
            label: item.reclaimer_id + ' - ' + item.name,
          };
        });
      newArray && setReclaimerList(newArray);
    } else {
      toast.error(res.message);
    }
  };

  const getEventCategoryDetails = async () => {
    let path = apiUrl.getEventCategory;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setEventCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const getActivityCategoryDetails = async (eventCategoryId: string) => {
    let path = apiUrl.getActivityCategory + eventCategoryId;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let temArray = res.data;
      const newArray =
        temArray &&
        temArray.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      newArray && setActivityCategoryData(newArray);
    } else {
      toast.error(res.message);
    }
  };

  const getReclaimerDetails = async (id: string) => {
    let path = apiUrl.getCSRReclaimerForCSRActivity + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setReclaimerDetails({
        name: res?.data?.name,
        gender: res?.data?.gender,
        contact_number: res?.data?.contact_number,
        reclaimer_photo: res?.data?.reclaimer_photo,
        reference_of: res?.data?.reference_of,
        reference_value: res?.data?.reference_value,
        identification_type: res?.data?.identification_type,
        identification_value: res?.data?.identification_value,
        nationality: res?.data?.nationality,
        date_of_birth: new Date(res?.data?.birth_date),
        data_fields: res?.data?.data_fields,
        user_type: res?.data?.reclaimer_user_type?.id,
      });

      setIdentificationType({
        value: res?.data?.identification_type,
        label: res?.data?.identification_type,
      });
      setDataField(JSON.parse(res?.data?.data_fields));
      setReclaimerCategoryId(res?.data?.reclaimer_category?.id);
      // setCollectionCneterName({
      //   label: res?.data?.csr_collection_center?.name,
      //   value: res?.data?.csr_collection_center_id,
      // });
    } else {
      toast.error(res.message);
    }
  };

  const handleStartDate = (date: any) => {
    setEventDetails({ ...eventDetails, start_date: date });
  };
  const handleActivityDate = (date: any) => {
    setActivityDetails({ ...activityDetails, activity_date: date });
  };
  const handleBirthDate = (date: any) => {
    setReclaimerDetails({ ...reclaimerDetails, date_of_birth: date });
  };
  const handleEndDate = (date: any) => {
    setEventDetails({ ...eventDetails, end_date: date });
  };

  const handleEvent = (option: any) => {
    if (option !== null) {
      let event_id = option.value === option.label ? 0 : option.value;
      setEventId(event_id);
      option.value !== option.label && getEventDetails(option.value);
      setEventName(option);
      setEventDetails({ ...eventDetails, name: option.label });
    } else {
      setEventName(null);
      setEventId(0);
      setEventDetails({
        name: '',
        start_date: new Date(),
        end_date: new Date(),
        organizer: '',
        participating_organisations: [],
        geo_coordinates: [],
        event_photo: '',
        sub_category: '',
        institute_involved: '',
        region: '',
      });
    }
  };

  const handleReclaimer = (option: any) => {
    if (option !== null) {
      let event_id = option.value === option.label ? 0 : option.value;
      setReclaimerId(event_id);
      option.value !== option.label && getReclaimerDetails(option.value);
      setReclaimerName(option);
      setReclaimerDetails({ ...reclaimerDetails, name: option.label });
    } else {
      setReclaimerName(null);
      setReclaimerId(0);
      setReclaimerDetails({
        name: '',
        gender: '',
        contact_number: '',
        reclaimer_photo: '',
        reference_of: '',
        reference_value: '',
        identification_type: '',
        identification_value: '',
        nationality: '',
        date_of_birth: new Date(),
        data_fields: [],
        user_type: 4,
      });
    }
  };

  const handleActivityCategory = (option: any) => {
    if (option !== null) {
      let category_id = option.value === option.label ? 0 : option.value;
      setActivityCategoryId(category_id);
      // option.value !== option.label && getEventDetails(option.value);
      setAcitivityCategoryName(option);
      // setEventDetails({ ...eventDetails, name: option.label });
    } else {
      setAcitivityCategoryName(null);
      setActivityCategoryId(0);
    }
  };

  const handleIdentificationType = (option: any) => {
    if (option !== null) {
      // let category_id = option.value === option.label ? 0 : option.value;
      // setActivityCategoryId(category_id);
      setReclaimerDetails({
        ...reclaimerDetails,
        identification_type: option.value,
      });
      setIdentificationType(option);
      // setEventDetails({ ...eventDetails, name: option.label });
    } else {
      setIdentificationType(null);
      // setActivityCastegoryId(0);
    }
  };
  const getEventDetails = async (id: string) => {
    let path = apiUrl.getCSREventForCSRActivity + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setEventDetails({
        name: res?.data?.name,
        start_date: new Date(res?.data?.start_date),
        end_date: new Date(res?.data?.end_date),
        organizer: res?.data?.organizer,
        participating_organisations: res?.data?.participating_organisations,
        geo_coordinates: JSON.parse(res?.data?.geo_coordinates),
        event_photo: res?.data?.event_photo,
        sub_category: res?.data?.sub_category,
        institute_involved: res?.data?.institution_involved,
        region: res?.data?.region?.id,
      });

      setParticipatingOrganisations(
        JSON.parse(res?.data?.participating_organisations)
      );

      setEventCategoryId(res?.data?.event_category?.id);

      setEventName({
        label: res?.data?.cst_event_id + ' ' + res?.data?.name,
        value: res?.data?.id,
      });
    } else {
      toast.error(res.message);
    }
  };

  const getRegions = async () => {
    let path = apiUrl.getRegions;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let regions = res?.data && res?.data?.countries;
      setCountries(regions);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await getActivityCategoryDetails(eventCategoryId);
    }
    if (eventCategoryId) {
      fetchData();
    }
  }, [eventCategoryId]);

  useEffect(() => {
    getEventList();
    getReclaimerList();
    getEventCategoryDetails();
    getReclaimerCategoryDetails();
    getRegions();
  }, []);

  const getFileUploaderData = async (dat: any, name: string) => {
    const tDoc: any = documentFiles || [];
    tDoc.push(dat);

    setDocumentFiles([...tDoc]);
    name == 'activity_document'
      ? setActivityDetails({
          ...activityDetails,
          documents: [
            ...activityDetails.documents,
            { name: dat.document_name, value: dat.document_path },
          ],
        })
      : name == 'event_image'
      ? setEventDetails({
          ...eventDetails,
          event_photo: dat.document_path,
        })
      : name == 'reclaimer_image'
      ? setReclaimerDetails({
          ...reclaimerDetails,
          reclaimer_photo: dat.document_path,
        })
      : setActivityDetails({
          ...activityDetails,
          activity_photos: [
            ...activityDetails.activity_photos,
            dat.document_path,
          ],
        });
  };

  const handleFileUploder = async (file: any, name: string) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', '');

    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res: any = await fr.response;
    if (res.success) {
      toast.success(res.message);
      await getFileUploaderData(res?.data?.data, name);
    } else {
      toast.error(res.message);
    }
  };

  const uploadDocument = async (e: any) => {
    let files = e?.target?.files || [];
    if (files[0] > commanValidation.maxFileLength) {
      toast.error(translate('ERRORS.INVALID.MAXIMUM_DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidFile(files[0].type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    await handleFileUploder(files[0], e.target.name);
  };

  const handleParticipatingOrg = (index: number, event: any) => {
    let data = [...participatingOrganisations];
    data[index] = event.target.value;
    setParticipatingOrganisations(data);
  };

  const handleImpactAction = (index: number, event: any, name: any) => {
    let data = [...impactAction];
    if (name) {
      data[index].name = event.target.value;
    } else {
      data[index].value = event.target.value;
    }
    setParticipatingOrganisations(data);
  };
  const handleDataField = (index: number, event: any, name: any) => {
    let data = [...dataField];
    if (name) {
      data[index].name = event.target.value;
    } else {
      data[index].value = event.target.value;
    }
    setDataField(data);
  };

  const handleCoordinatesPopup = (preview: boolean, isShow: boolean) => {
    setEntryPopup({ preview: preview, isShow: isShow });
  };

  const createActivity = async () => {
    let data = {
      event_details: {
        ...eventDetails,
        start_date: moment(eventDetails.start_date).format('YYYY-MM-DD'),
        end_date: moment(eventDetails.end_date).format('YYYY-MM-DD'),
        organizer: eventDetails.organizer,
        participating_organisations: eventDetails.participating_organisations,
        geo_coordinates: eventDetails.geo_coordinates,
        event_photo: eventDetails.event_photo,
        institute_involved: eventDetails.institute_involved,
        regionId: eventDetails.region,
        categoryId: eventCategoryId,
      },

      reclaimer_details: {
        ...reclaimerDetails,
        date_of_birth: moment(reclaimerDetails.date_of_birth).format(
          'YYYY-MM-DD'
        ),
        gender: 'male',
        csr_reclaimer_category: reclaimerCategoryId,
        csr_reclaimer_user_type: reclaimerDetails.user_type,
      },
      activity_date: moment(activityDetails.activity_date).format('YYYY-MM-DD'),
      describe: activityDetails.describe,
      activity_reference: activityDetails.activity_reference,
      impact_description: activityDetails.impact_description,
      impact_action: impactAction,
      activity_category: activityCategoryId,
      documents: activityDetails.documents,
      activity_photos: activityDetails.activity_photos,
      csr_event_id: eventId,
      csr_reclaimer_id: reclaimerId,
      otherMaterial: activityDetails.otherMaterial,
    };

    // const fr: any = await helper.formPost(data, path);
    const res = await addActivity(data).unwrap();
    if (res.success) {
      navigate(routesConstant.CSRActivity, {
        state: socialWelfareTab.ACTIVITIES,
      });
      toast.success('Activity Created Successfully !');
    } else {
      toast.error(res.message);
    }
  };

  return (
    <>
      {submitPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.SUBMIT')}`}
          message={`${translate('CSR_MODULE.TITLES.SUBMIT_MESSAGE')}`}
          handleCancel={() => setSubmitPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.PROCEED')}`}
          handleSubmit={() => {
            createActivity();
            setSubmitPopup(false);
          }}
        />
      )}
      {addentryPopup.isShow && (
        <GetGeoCoordinates
          title={`${translate('CSR_MODULE.TITLES.GEO_COORDINATES')}`}
          handleCancel={() => handleCoordinatesPopup(false, false)}
          cancelText='Cancel'
          submitText='OK'
          preview={addentryPopup.preview}
          hideCancelButton={true}
          eventDetails={eventDetails}
          setEventDetails={setEventDetails}
          handleSubmit={() => {
            handleCoordinatesPopup(false, false);
          }}
        />
      )}
      <div className='body-content-main pc-request csr-add-entry'>
        <div className='container'>
          <nav aria-label='breadcrumb' className='breadcrumb-main'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <a>Home</a>
              </li>
              <li className='breadcrumb-item' aria-current='page'>
                ReMake CSR
              </li>
              <li className='breadcrumb-item active'>Add Entry</li>
            </ol>
          </nav>
          <div className='section-title-main'>
            <div className='title'>Add Entry</div>
          </div>
          <div className='transaction-table listuser-table csr-addreclaimer add-acitivty'>
            <div className='panel-head-title'>
              <div className='left-content'>
                <div className='title-normal'>New Entry Details</div>
              </div>
              <div className='right-content'>
                <div className='form-submit-buttons auto-width-button'>
                  {/* <button type='button' className='btn btn-sm discard'>
                    Discard
                  </button> */}
                  <button
                    type='button'
                    onClick={() => {
                      setIsSubmited(true);
                      validateForm(
                        reclaimerDetails,
                        eventDetails,
                        activityDetails,
                        eventCategoryId,
                        activityCategoryId,
                        true
                      );
                    }}
                    className='btn btn-sm save-entry ms-1 ms-sm-3 ms-md-3 ms-lg-3 ms-xl-3'
                  >
                    Save Entry
                  </button>
                </div>
              </div>
            </div>
            <div className='display-component main-profile-head'>
              <div className='activity-main'>
                <div className='activity-box'>
                  <div className='row d-flex align-items-center'>
                    <div className='col-md-6'>
                      <div className='left-area'>
                        <div className='title'>Event Details</div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='right-area text-end'>
                        <p>
                          {/* <span>Date of entry:</span> 30/1/2023 02:30 PM */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='panel-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      {/* <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Name of Event
                        </label>
                        <input
                          type='text'
                          placeholder='Event Word'
                          className='form-control'
                        />
                        <span className='error'></span>
                      </div> */}
                      <div className='form-group required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.EVENT_NAME')}
                        </Form.Label>
                        <CreatableSelect
                          isClearable
                          value={eventName}
                          onChange={handleEvent}
                          options={eventList}
                          placeholder={`${translate(
                            'CSR_MODULE.TITLES.SELECT_EVENT'
                          )}`}

                          // isDisabled={user_id !== undefined}
                        />
                        <span className='error'>{errors.eventName}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Category
                        </label>
                        <select
                          name='gender'
                          className='form-control form-select'
                          onChange={(e: any) =>
                            setEventCategoryId(e.target.value)
                          }
                          value={eventCategoryId}
                          disabled={eventId ? true : false}
                        >
                          <option value=''>Select Category</option>
                          {eventCategoryData &&
                            eventCategoryData.length > 0 &&
                            eventCategoryData.map(
                              (eventCategory: any, index: number) => {
                                return (
                                  <option value={eventCategory.id}>
                                    {eventCategory?.name}
                                  </option>
                                );
                              }
                            )}
                        </select>
                        <span className='error'>{errors.eventCategory}</span>
                      </div>
                    </div>
                    {/* <div className='col-md-4'>
                      
                      <div className='form-group required'>
                        <Form.Label className='form-label'>
                          {translate('CSR_MODULE.TITLES.EVENT_NAME')}
                        </Form.Label>
                        <CreatableSelect
                          isClearable
                          value={eventName}
                          onChange={handleEvent}
                          options={eventList}
                          placeholder={`${translate(
                            'CSR_MODULE.TITLES.SELECT_EVENT'
                          )}`}

                          isDisabled={user_id !== undefined}
                        />
                        <span className='error'>{errors.eventName}</span>
                      </div>
                    </div> */}
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Organiser
                        </label>
                        <input
                          type='text'
                          placeholder='Name of Organiser'
                          className='form-control'
                          name='organizer'
                          value={eventDetails.organizer}
                          onChange={handleEventDetails}
                          disabled={eventId ? true : false}
                        />
                        <span className='error'>{errors.organizer}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group date-input'>
                        <label className='form-label form-label'>
                          Start Date
                        </label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            {/* <div className='react-datepicker-wrapper'>
                              <div className='react-datepicker__input-container'>
                                <span
                                  role='alert'
                                  aria-live='polite'
                                  className='react-datepicker__aria-live'
                                ></span>
                                <input
                                  type='text'
                                  placeholder='Select Date'
                                  className='form-control'
                                />
                              </div>
                            </div> */}
                            <div className='date-item-picker'>
                              <div className='date-picker-main'>
                                <DatePicker
                                  selected={eventDetails.start_date}
                                  className='form-control'
                                  placeholderText={
                                    translate(
                                      'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                    ) + ''
                                  }
                                  dateFormat='dd/MM/yyyy'
                                  onChange={handleStartDate}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode='select'
                                  required
                                  disabled={eventId ? true : false}
                                />
                                {/* {
                            <span className='error'>
                              {translate(errors.loadingSlipDetails.date)}
                            </span>
                          } */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group date-input'>
                        <label className='form-label form-label'>
                          End Date
                        </label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            {/* <div className='react-datepicker-wrapper'>
                              <div className='react-datepicker__input-container'>
                                <span
                                  role='alert'
                                  aria-live='polite'
                                  className='react-datepicker__aria-live'
                                ></span>
                                <input
                                  type='text'
                                  placeholder='Select Date'
                                  className='form-control'
                                />
                              </div>
                            </div> */}
                            <DatePicker
                              selected={eventDetails.end_date}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleEndDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              required
                              disabled={eventId ? true : false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Institution Involved
                        </label>
                        <input
                          type='text'
                          placeholder='Name Of Institution'
                          className='form-control'
                          onChange={handleEventDetails}
                          name='institute_involved'
                          value={eventDetails.institute_involved}
                          disabled={eventId ? true : false}
                        />
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>Region</label>
                        <Form.Select
                          className='form-control select-data'
                          name='regionId'
                          onChange={(e) => {
                            setEventDetails({
                              ...eventDetails,
                              region: e.target.value,
                            });
                          }}
                          value={eventDetails.region}
                          disabled={eventId ? true : false}
                          required
                        >
                          <option value=''>Select Region</option>
                          {countries?.length > 0 &&
                            countries?.map((u: any, i: any) => {
                              return (
                                <option key={i} value={u.id}>
                                  {u.name}
                                </option>
                              );
                            })}
                        </Form.Select>

                        <span className='error'>{errors.region}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <div className='mb-3 upload-section'>
                          <label
                            className='form-label form-label'
                            htmlFor='formFile'
                          >
                            Event Photo/Logo
                          </label>
                          <input
                            name='event_image'
                            accept='.jpg, .jpeg, .png'
                            type='file'
                            id='event_image'
                            onChange={uploadDocument}
                            className='file-input form-control'
                            disabled={eventId ? true : false}
                          />

                          <img
                            src='/assets/images/upload-svg.svg'
                            className='upload-btn'
                          ></img>

                          {eventDetails.event_photo && (
                            <div
                              className='upload-icons'
                              style={{ left: '50px' }}
                            >
                              {eventId == 0 && (
                                <i
                                  className='fa-solid fa-trash-can'
                                  onClick={() => {
                                    setEventDetails({
                                      ...eventDetails,
                                      event_photo: '',
                                    });
                                  }}
                                ></i>
                              )}
                              <i
                                className='fa fa-eye ms-2 me-2'
                                aria-hidden='true'
                                onClick={() => {
                                  window.open(eventDetails.event_photo);
                                }}
                              ></i>
                            </div>
                          )}
                        </div>
                        <span className='error'></span>
                      </div>
                    </div>
                    <div className='col-md-4'></div>
                  </div>
                  {eventId ? (
                    <div className='row'>
                      <div className='col-md-8'>
                        <div className='row'>
                          <div className='col-md-11'>
                            <div className='form-group required'>
                              <label className='form-label form-label'>
                                Participating Organizations
                              </label>

                              {eventId ? (
                                <>
                                  {participatingOrganisations.length > 0 &&
                                    participatingOrganisations.map(
                                      (u: any, i: any) => {
                                        return (
                                          <input
                                            style={{ marginTop: '10px' }}
                                            type='text'
                                            value={u}
                                            placeholder=''
                                            disabled
                                            className='form-control'
                                          />
                                        );
                                      }
                                    )}
                                </>
                              ) : (
                                <>
                                  {participatingOrganisations.map(
                                    (u: any, i: any) => {
                                      return (
                                        <div className='col-md-4'>
                                          {i === 0 && (
                                            <label className='form-label'></label>
                                          )}
                                          <input
                                            style={{ marginTop: '10px' }}
                                            type='text'
                                            value={u}
                                            placeholder=''
                                            onChange={(e) => {
                                              handleParticipatingOrg(i, e);
                                            }}
                                            className='form-control'
                                          />

                                          {i + 1 ==
                                            participatingOrganisations.length && (
                                            <div className='col-md-1'>
                                              <button
                                                type='button'
                                                className='btn-add'
                                                onClick={() =>
                                                  setParticipatingOrganisations(
                                                    [
                                                      ...participatingOrganisations,
                                                      '',
                                                    ]
                                                  )
                                                }
                                              >
                                                +
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              )}
                              {/* <span className='error'></span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='row'>
                      <div className='col-md-8'>
                        <div className='row'>
                          {participatingOrganisations &&
                            participatingOrganisations.map((u: any, i: any) => {
                              return (
                                <>
                                  <div className='col-md-11'>
                                    <div className='form-group required required'>
                                      {i == 0 && (
                                        <label className='form-label form-label'>
                                          Participating Organizations
                                        </label>
                                      )}
                                      <input
                                        style={{ marginTop: '10px' }}
                                        type='text'
                                        value={u}
                                        placeholder=''
                                        onChange={(e) => {
                                          handleParticipatingOrg(i, e);
                                        }}
                                        className='form-control'
                                      />
                                      <span className='error'></span>
                                    </div>
                                  </div>
                                  {participatingOrganisations.length ==
                                    i + 1 && (
                                    <div className='col-md-1 mt-4'>
                                      {/* {i == 0 && (
                                      <label className='form-label form-label'></label>
                                    )} */}
                                      <button
                                        type='button'
                                        className='btn-add'
                                        onClick={() =>
                                          setParticipatingOrganisations([
                                            ...participatingOrganisations,
                                            '',
                                          ])
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div className='row'>
                    <div className='col-md-8'>
                      <div className='row'>
                        {eventId ? (
                          <>
                            {participatingOrganisations.length > 0 &&
                              participatingOrganisations.map(
                                (u: any, i: any) => {
                                  return (
                                    <div className='col-md-11'>
                                      <div className='form-group required required'>
                                        {i == 1 && (
                                          <label className='form-label form-label'>
                                            Participating Organizations
                                          </label>
                                        )}
                                        <input
                                          style={{ marginTop: '10px' }}
                                          type='text'
                                          value={u}
                                          placeholder=''
                                          disabled
                                          className='form-control'
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </>
                        ) : (
                          <>
                            {participatingOrganisations.map(
                              (u: any, i: any) => {
                                return (
                                  <div className='col-md-11'>
                                    <div className='form-group required required'>
                                      {i == 1 && (
                                        <label className='form-label form-label'>
                                          Participating Organizations
                                        </label>
                                      )}
                                      <input
                                        style={{ marginTop: '10px' }}
                                        type='text'
                                        value={u}
                                        placeholder=''
                                        onChange={(e) => {
                                          handleParticipatingOrg(i, e);
                                        }}
                                        className='form-control'
                                      />
                                    </div>
                                    {i + 1 ==
                                      participatingOrganisations.length && (
                                      <div className='col-md-1 mt-4'>
                                        <button
                                          type='button'
                                          className='btn-add'
                                          onClick={() =>
                                            setParticipatingOrganisations([
                                              ...participatingOrganisations,
                                              '',
                                            ])
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <div>
                          <label className='form-label form-label'>
                            Geo-Cordinates
                          </label>
                        </div>
                        <div className='row'>
                          {!eventId && (
                            <div className='col-md-6 mt-4'>
                              <button
                                type='button'
                                className='search-map'
                                onClick={() =>
                                  handleCoordinatesPopup(false, true)
                                }
                              >
                                Select on Map
                              </button>
                            </div>
                          )}

                          {eventDetails.geo_coordinates.length > 0 && (
                            <div className='col-md-6 mt-4'>
                              <button
                                type='button'
                                className='search-map'
                                onClick={() =>
                                  handleCoordinatesPopup(true, true)
                                }
                              >
                                View on Map
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='activity-box'>
                  <div className='row d-flex align-items-center'>
                    <div className='col-md-12'>
                      <div className='left-area'>
                        <div className='title'>Activity Details</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='panel-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Activity Type
                        </label>

                        <CreatableSelect
                          isClearable
                          value={activityCategoryName}
                          onChange={handleActivityCategory}
                          options={activityCategoryData}
                          placeholder={'Select Activity Type'}

                          // isDisabled={user_id !== undefined}
                        />
                        <span className='error'>{errors.activityCategory}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group date-input'>
                        <label className='form-label form-label'>
                          Activity Date
                        </label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <div className='date-item-picker'>
                              <div className='date-picker-main'>
                                <DatePicker
                                  selected={activityDetails.activity_date}
                                  className='form-control'
                                  placeholderText={
                                    translate(
                                      'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                    ) + ''
                                  }
                                  dateFormat='dd/MM/yyyy'
                                  onChange={handleActivityDate}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode='select'
                                  required
                                  // disabled={eventId ? true : false}
                                />
                                {/* {
                            <span className='error'>
                              {translate(errors.loadingSlipDetails.date)}
                            </span>
                          } */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label form-label'>
                          Activity Reference
                        </label>
                        <input
                          name='activity_reference'
                          onChange={handleActivityDetails}
                          type='text'
                          value={activityDetails.activity_reference}
                          placeholder='Reference'
                          className='form-control'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='text-area-content'>
                        <div className='form-group group-i-input'>
                          <label className='form-label form-label'>
                            Describe
                          </label>
                          <textarea
                            placeholder=''
                            onChange={handleActivityDetails}
                            name='describe'
                            value={activityDetails.describe}
                            className='form-control'
                          ></textarea>
                          <span className='error'>{errors.describe}</span>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='text-area-content'>
                        <div className='form-group group-i-input'>
                          <label className='form-label form-label'>
                            Impact Description
                          </label>
                          <textarea
                            placeholder=''
                            onChange={handleActivityDetails}
                            value={activityDetails.impact_description}
                            name='impact_description'
                            className='form-control'
                          ></textarea>

                          <span className='error'>
                            {errors.imapctDescription}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='row'>
                    <div className='col-md-8'>
                      <div className='row'>
                        <div className='col-md-11'>
                          <div className='form-group required required'>
                            <label className='form-label form-label'>
                              Impact Action
                            </label>
                            <input
                              type='text'
                              placeholder=''
                              className='form-control'
                            />
                            <span className='error'></span>
                          </div>
                        </div>
                        <div className='col-md-1 mt-4'>
                          <button type='button' className='btn-add'>
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className='row'>
                    {impactAction.map((u: any, i: any) => {
                      return (
                        <div className='row'>
                          <div className='col-md-4'>
                            <div className='form-group required required'>
                              {i === 0 && (
                                <label className='form-label form-label'>
                                  Impact Action
                                </label>
                              )}
                              <input
                                type='text'
                                placeholder='Unit'
                                className='form-control'
                                onChange={(e) => handleImpactAction(i, e, true)}
                              />
                              <span className='error'></span>
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div className='row'>
                              <div className='col-md-10'>
                                <div className='form-group'>
                                  {i === 0 && (
                                    <label className='form-label form-label'></label>
                                  )}
                                  <input
                                    type='text'
                                    placeholder='Value'
                                    className='form-control'
                                    onChange={(e) =>
                                      handleImpactAction(i, e, false)
                                    }
                                  />
                                  <span className='error'></span>
                                </div>
                              </div>
                              {impactAction.length === i + 1 && (
                                <div className='col-md-2 '>
                                  <button
                                    type='button'
                                    className='btn-add'
                                    onClick={() =>
                                      setImpactAction([
                                        ...impactAction,
                                        { name: '', value: '' },
                                      ])
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <div className='mb-3 upload-section'>
                          <label
                            className='form-label form-label'
                            htmlFor='formFile'
                          >
                            Activity Photo
                          </label>
                          <input
                            name='activity_image'
                            accept='.jpg, .jpeg, .png'
                            type='file'
                            id='activity_image'
                            onChange={uploadDocument}
                            className='file-input form-control'
                          />

                          <img
                            src='/assets/images/upload-svg.svg'
                            className='upload-btn'
                          ></img>
                        </div>
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='action-photos-section'>
                    <div className='row mb-3'>
                      {activityDetails.activity_photos.length > 0 &&
                        activityDetails.activity_photos.map(
                          (u: any, i: any) => {
                            return (
                              <div className='col-sm-6 col-md-6 col-lg-3 mt-2'>
                                <div className='photo-box'>
                                  <div className='photos-section'>
                                    <img src={u}></img>
                                  </div>
                                  <div className='d-flex justify-content-between'>
                                    <div className='photos-name'>
                                      {/* <p className='mb-0'>background-image.jpg</p> */}
                                    </div>
                                    <div className='action'>
                                      <ul className='action-area'>
                                        <li>
                                          <button
                                            type='button'
                                            className='delete'
                                            onClick={() => {
                                              let newArray = [
                                                ...activityDetails.activity_photos,
                                              ];

                                              newArray = newArray.filter(
                                                function (e) {
                                                  return e !== u;
                                                }
                                              );

                                              setActivityDetails({
                                                ...activityDetails,
                                                activity_photos: newArray,
                                              });
                                            }}
                                          >
                                            <svg
                                              width='14px'
                                              height='14px'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              xmlns='http://www.w3.org/2000/svg'
                                            >
                                              <path
                                                fill-rule='evenodd'
                                                clip-rule='evenodd'
                                                d='M11.4698 2.25H11.5302C12.0129 2.24999 12.421 2.24998 12.7592 2.27848C13.1166 2.30859 13.451 2.37374 13.7758 2.53412C14.0492 2.66911 14.2983 2.84863 14.5129 3.06534C14.7677 3.32282 14.9353 3.61939 15.0768 3.94892C15.2108 4.26078 15.3399 4.64793 15.4925 5.10588L15.7115 5.76283C15.7377 5.84148 15.7502 5.92141 15.7502 6H18.2222C19.2041 6 20 6.79594 20 7.77778C20 7.90051 19.9005 8 19.7778 8H3.22222C3.09949 8 3 7.90051 3 7.77778C3 6.79594 3.79594 6 4.77778 6H7.24979C7.2498 5.92141 7.26226 5.84148 7.28848 5.76283L7.50745 5.10592C7.66009 4.64796 7.78913 4.26078 7.92313 3.94892C8.06472 3.61939 8.23225 3.32282 8.48713 3.06534C8.70165 2.84863 8.95073 2.66911 9.22416 2.53412C9.54902 2.37374 9.88335 2.30859 10.2407 2.27848C10.579 2.24998 10.9871 2.24999 11.4698 2.25ZM14.079 5.60888L14.2094 6H8.79056L8.92093 5.60888C9.08566 5.11469 9.19521 4.788 9.3013 4.54107C9.40259 4.30534 9.47964 4.19487 9.55315 4.12061C9.65067 4.02211 9.76388 3.9405 9.88817 3.87915C9.98186 3.83289 10.111 3.79473 10.3667 3.77318C10.6345 3.75062 10.9791 3.75 11.5 3.75C12.0209 3.75 12.3655 3.75062 12.6333 3.77318C12.889 3.79473 13.0181 3.83289 13.1118 3.87915C13.2361 3.9405 13.3493 4.02211 13.4468 4.12061C13.5203 4.19487 13.5974 4.30534 13.6987 4.54107C13.8048 4.788 13.9143 5.11469 14.079 5.60888Z'
                                                fill='#fff'
                                              />
                                              <path
                                                d='M6.31017 21.6385C5.88874 21.2769 5.79537 20.67 5.60863 19.4562L4.08861 9.57603C4.04742 9.3083 4.02682 9.17444 4.10165 9.08722C4.17647 9 4.31191 9 4.58279 9H18.4172C18.6881 9 18.8235 9 18.8983 9.08722C18.9731 9.17444 18.9526 9.3083 18.9114 9.57603L17.3913 19.4562C17.2046 20.67 17.1112 21.2769 16.6898 21.6385C16.2684 22 15.6543 22 14.4262 22H8.57374C7.34564 22 6.7316 22 6.31017 21.6385Z'
                                                fill='#fff'
                                              />
                                            </svg>
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6 col-md-6 col-lg-6'>
                      <div className='form-group'>
                        <div className='mb-3 upload-section'>
                          <label
                            className='form-label form-label'
                            htmlFor='formFile'
                          >
                            Upload Document
                          </label>
                          <input
                            name='activity_document'
                            accept='.jpg, .jpeg, .png'
                            type='file'
                            id='activity_document'
                            multiple={true}
                            onChange={uploadDocument}
                            className='file-input form-control'
                          />

                          <img
                            src='/assets/images/upload-svg.svg'
                            className='upload-btn'
                          ></img>
                        </div>
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='upload-docment-section'>
                    <div className='row mb-3'>
                      {activityDetails &&
                        activityDetails.documents.length > 0 &&
                        activityDetails.documents.map((u: any, i: any) => {
                          return (
                            <div className='col-sm-6 col-md-6 col-lg-3 mt-2'>
                              <div className='uploaded-box'>
                                <div className='d-flex justify-content-between'>
                                  <div className='left-section'>
                                    <p className='mb-0 mt-1 filename'>
                                      {u.name}
                                    </p>
                                  </div>
                                  <div className='right-section'>
                                    <button
                                      type='button'
                                      className='delete'
                                      onClick={() => {
                                        let newArray = [
                                          ...activityDetails.documents,
                                        ];

                                        newArray = newArray.filter(function (
                                          e
                                        ) {
                                          return e.value !== u.value;
                                        });

                                        setActivityDetails({
                                          ...activityDetails,
                                          documents: newArray,
                                        });
                                      }}
                                    >
                                      <svg
                                        width='20px'
                                        height='20px'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          fill-rule='evenodd'
                                          clip-rule='evenodd'
                                          d='M11.4698 2.25H11.5302C12.0129 2.24999 12.421 2.24998 12.7592 2.27848C13.1166 2.30859 13.451 2.37374 13.7758 2.53412C14.0492 2.66911 14.2983 2.84863 14.5129 3.06534C14.7677 3.32282 14.9353 3.61939 15.0768 3.94892C15.2108 4.26078 15.3399 4.64793 15.4925 5.10588L15.7115 5.76283C15.7377 5.84148 15.7502 5.92141 15.7502 6H18.2222C19.2041 6 20 6.79594 20 7.77778C20 7.90051 19.9005 8 19.7778 8H3.22222C3.09949 8 3 7.90051 3 7.77778C3 6.79594 3.79594 6 4.77778 6H7.24979C7.2498 5.92141 7.26226 5.84148 7.28848 5.76283L7.50745 5.10592C7.66009 4.64796 7.78913 4.26078 7.92313 3.94892C8.06472 3.61939 8.23225 3.32282 8.48713 3.06534C8.70165 2.84863 8.95073 2.66911 9.22416 2.53412C9.54902 2.37374 9.88335 2.30859 10.2407 2.27848C10.579 2.24998 10.9871 2.24999 11.4698 2.25ZM14.079 5.60888L14.2094 6H8.79056L8.92093 5.60888C9.08566 5.11469 9.19521 4.788 9.3013 4.54107C9.40259 4.30534 9.47964 4.19487 9.55315 4.12061C9.65067 4.02211 9.76388 3.9405 9.88817 3.87915C9.98186 3.83289 10.111 3.79473 10.3667 3.77318C10.6345 3.75062 10.9791 3.75 11.5 3.75C12.0209 3.75 12.3655 3.75062 12.6333 3.77318C12.889 3.79473 13.0181 3.83289 13.1118 3.87915C13.2361 3.9405 13.3493 4.02211 13.4468 4.12061C13.5203 4.19487 13.5974 4.30534 13.6987 4.54107C13.8048 4.788 13.9143 5.11469 14.079 5.60888Z'
                                          fill='#fff'
                                        />
                                        <path
                                          d='M6.31017 21.6385C5.88874 21.2769 5.79537 20.67 5.60863 19.4562L4.08861 9.57603C4.04742 9.3083 4.02682 9.17444 4.10165 9.08722C4.17647 9 4.31191 9 4.58279 9H18.4172C18.6881 9 18.8235 9 18.8983 9.08722C18.9731 9.17444 18.9526 9.3083 18.9114 9.57603L17.3913 19.4562C17.2046 20.67 17.1112 21.2769 16.6898 21.6385C16.2684 22 15.6543 22 14.4262 22H8.57374C7.34564 22 6.7316 22 6.31017 21.6385Z'
                                          fill='#fff'
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className='activity-box'>
                  <div className='row d-flex align-items-center'>
                    <div className='col-md-12'>
                      <div className='left-area'>
                        <div className='title'>Profile Details</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='panel-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Reclaimer Name
                        </label>

                        <CreatableSelect
                          isClearable
                          value={reclaimerName}
                          onChange={handleReclaimer}
                          options={reclaimerList}
                          placeholder={'Select Reclaimer'}

                          // isDisabled={user_id !== undefined}
                        />
                        <span className='error'>{errors.reclaimerName}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <label className='form-label form-label'>
                          Beneficiary
                        </label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              checked={reclaimerDetails.user_type == 4}
                              onClick={() => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  user_type: 4,
                                });
                              }}
                              name='isPOCDataProvider'
                              id='yes-icon1'
                              disabled={reclaimerId ? true : false}
                              value='true'
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='yes-icon1'
                            >
                              Individual
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='isPOCDataProvider'
                              id='no-icon1'
                              checked={reclaimerDetails.user_type == 6}
                              onClick={() => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  user_type: 6,
                                });
                              }}
                              disabled={reclaimerId ? true : false}
                              value='false'
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='no-icon1'
                            >
                              Group
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Beneficiary Category
                        </label>
                        <select
                          name='gender'
                          className='form-control form-select'
                          value={reclaimerCategoryId}
                          onChange={(e: any) =>
                            setReclaimerCategoryId(e.target.value)
                          }
                        >
                          <option value=''>Select Reclaimer Category</option>
                          {reclaimerCategoryData &&
                            reclaimerCategoryData.length > 0 &&
                            reclaimerCategoryData.map(
                              (userCategory: any, index: number) => {
                                return (
                                  <option value={userCategory.id}>
                                    {userCategory?.name}
                                  </option>
                                );
                              }
                            )}
                        </select>
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    {/* <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label form-label'>Name</label>
                        <input
                          type='text'
                          placeholder='Enter Name'
                          className='form-control'
                        />
                      </div>
                    </div> */}
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <label className='form-label form-label'>Gender</label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='gender'
                              id='yes-icon2'
                              checked={reclaimerDetails.gender == 'male'}
                              onClick={() => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  gender: 'male',
                                });
                              }}
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='yes-icon2'
                            >
                              Male
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='gender'
                              id='no-icon2'
                              // value='false'
                              checked={reclaimerDetails.gender == 'female'}
                              onClick={() => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  gender: 'female',
                                });
                              }}
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='no-icon2'
                            >
                              Female
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {reclaimerDetails.user_type == 4 && (
                      <div className='col-md-4'>
                        <div className='form-group date-input'>
                          <label className='form-label form-label'>
                            Birth Date
                          </label>
                          <div className='date-item-picker'>
                            <div className='date-picker-main'>
                              <DatePicker
                                selected={reclaimerDetails.date_of_birth}
                                className='form-control'
                                placeholderText={
                                  translate(
                                    'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                  ) + ''
                                }
                                dateFormat='dd/MM/yyyy'
                                onChange={handleBirthDate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                required
                                // disabled={eventId ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <div className='mb-3 upload-section'>
                          <label
                            className='form-label form-label'
                            htmlFor='formFile'
                          >
                            Reclaimer Photo
                          </label>
                          <input
                            name='reclaimer_image'
                            accept='.jpg, .jpeg, .png'
                            type='file'
                            id='reclaimer_image'
                            onChange={uploadDocument}
                            disabled={reclaimerId ? true : false}
                            className='file-input form-control'
                          />

                          <img
                            src='/assets/images/upload-svg.svg'
                            className='upload-btn'
                          ></img>
                          {reclaimerDetails.reclaimer_photo && (
                            <div
                              className='upload-icons'
                              style={{ left: '50px' }}
                            >
                              {reclaimerId == 0 && (
                                <i
                                  className='fa-solid fa-trash-can'
                                  onClick={() => {
                                    setReclaimerDetails({
                                      ...reclaimerDetails,
                                      reclaimer_photo: '',
                                    });
                                  }}
                                ></i>
                              )}
                              <i
                                className='fa fa-eye ms-2 me-2'
                                aria-hidden='true'
                                onClick={() => {
                                  window.open(reclaimerDetails.reclaimer_photo);
                                }}
                              ></i>
                            </div>
                          )}
                        </div>
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    {reclaimerId ? (
                      <>
                        {dataField.length > 0 &&
                          dataField.map((u: any, i: any) => {
                            return (
                              <div className='row'>
                                <div className='col-md-4'>
                                  <div className='form-group required required'>
                                    {i === 0 && (
                                      <label className='form-label form-label'>
                                        Data Fields
                                      </label>
                                    )}
                                    <input
                                      type='text'
                                      value={u.name}
                                      placeholder='Field Name'
                                      className='form-control'
                                      disabled
                                    />
                                    <span className='error'></span>
                                  </div>
                                </div>
                                <div className='col-md-4'>
                                  <div className='row'>
                                    <div className='col-md-10'>
                                      <div className='form-group'>
                                        {i === 0 && (
                                          <label className='form-label form-label'></label>
                                        )}
                                        <input
                                          type='text'
                                          value={u.value}
                                          placeholder='Value'
                                          className='form-control'
                                          disabled
                                        />
                                        <span className='error'></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {dataField.map((u: any, i: any) => {
                          return (
                            <div className='row'>
                              <div className='col-md-4'>
                                <div className='form-group required required'>
                                  {i === 0 && (
                                    <label className='form-label form-label'>
                                      Data Fields
                                    </label>
                                  )}
                                  <input
                                    type='text'
                                    placeholder='Field Name'
                                    className='form-control'
                                    value={u.name}
                                    onChange={(e) =>
                                      handleDataField(i, e, true)
                                    }
                                  />
                                  <span className='error'></span>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='row'>
                                  <div className='col-md-10'>
                                    <div className='form-group'>
                                      {i === 0 && (
                                        <label className='form-label form-label'></label>
                                      )}
                                      <input
                                        type='text'
                                        placeholder='Value'
                                        value={u.value}
                                        className='form-control'
                                        onChange={(e) =>
                                          handleDataField(i, e, false)
                                        }
                                      />
                                      <span className='error'></span>
                                    </div>
                                  </div>
                                  {dataField.length === i + 1 && (
                                    <div className='col-md-2 '>
                                      {i === 0 && (
                                        <label className='form-label form-label'></label>
                                      )}
                                      <button
                                        type='button'
                                        className='btn-add'
                                        onClick={() =>
                                          setDataField([
                                            ...dataField,
                                            { name: '', value: '' },
                                          ])
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  )}
                                </div>{' '}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                  {reclaimerDetails.user_type == 4 && (
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <div className='mb-3 upload-section'>
                            <label
                              className='form-label form-label'
                              htmlFor='formFile'
                            >
                              Identification type
                            </label>
                            <CreatableSelect
                              isClearable
                              value={identificationType}
                              onChange={handleIdentificationType}
                              options={IdentificationData}
                              placeholder={'Select Identification Type'}
                              isDisabled={reclaimerId ? true : false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label form-label'>
                            Identification Number
                          </label>
                          <input
                            type='text'
                            name='identification_value'
                            onChange={handleReclaimerDetails}
                            value={reclaimerDetails.identification_value}
                            placeholder=''
                            className='form-control'
                            disabled={reclaimerId ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label form-label'>
                          Reference Of
                        </label>
                        <input
                          type='text'
                          name='reference_of'
                          onChange={handleReclaimerDetails}
                          value={reclaimerDetails.reference_of}
                          placeholder=''
                          className='form-control'
                          disabled={reclaimerId ? true : false}
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label form-label'>
                          Reference Value
                        </label>
                        <input
                          type='text'
                          name='reference_value'
                          disabled={reclaimerId ? true : false}
                          onChange={handleReclaimerDetails}
                          value={reclaimerDetails.reference_value}
                          placeholder=''
                          className='form-control'
                        />
                        <span className='error'>{errors.referenceValue}</span>
                      </div>
                    </div>
                    {reclaimerDetails.user_type == 4 && (
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label form-label'>
                            Nationality
                          </label>
                          <input
                            type='text'
                            placeholder='Nationality'
                            className='form-control'
                            name='nationality'
                            value={reclaimerDetails.nationality}
                            onChange={handleReclaimerDetails}
                            disabled={reclaimerId ? true : false}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddActivity;
