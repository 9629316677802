import { every, isObject, map, isString } from 'lodash';
import { VolumeType } from '../constants/volume-type.constant';
// import reader from 'xlsx';
export const convertQuantityToKg = (quantity: volume) => {
  let qtyInKg = quantity.quantity;
  if (quantity?.unit?.toLowerCase() !== VolumeType.KG.toLowerCase())
    qtyInKg = quantity.quantity * 1000;
  return +qtyInKg;
};

export const calculateCO2Emission = (unloadedQuantity: volume) => {
  return 1.5 * convertQuantityToKg(unloadedQuantity);
};
export const convertDataToBase64 = (data: string) => {
  return Buffer.from(data, 'utf8').toString('base64');
};
export const convertBase64ToData = (data: string) => {
  return Buffer.from(data, 'base64').toString();
};
export const capitalized = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const isValidFile = (mimeType: string) => {
  const acceptMimeTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/webp',
    'image/bmp',
    'application/pdf',
    'application/msword',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  return acceptMimeTypes.find((type) => type === mimeType);
};
export const isValidFileSize = (size: number) => {
  return size <= 5000000;
};

export const isValidExcelFile = (mimeType: string) => {
  return (
    mimeType ===
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  );
};
export const isScript = /^[^<>;]+$/;
export const textValidator = /^[^<>;]+$/;
export const address_textArea_Validator = /^[^<>;]+$/;
export const mobilePatern = /^[0-9]{10}$/;
export const emailPatern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const textValidatorMSG = 'Invalid text';
export const address_textArea_ValidatorMSG = 'Invalid text or address';
export const invalidEmailMSG = 'Invalid email';
export const invalidDesignationMSG = 'Invalid Designation';
export const landFillAreaSavedQuantity = 0.81;
export const Co2EmissionsSaved = 1.5;
export const AluminiumSaved = 3.54;
export const randomIdLength = 7;
export const ledger_prefix = 'RMX';
export const pc_request_prefix = 'PCR';
export const pdf_id_prefix = 'RMK';
export const dataCombination =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
  Math.floor(Math.random() * Date.now()).toString() +
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
  Math.floor(Math.random() * Date.now()).toString();

export const GenerateID = (length: number, prefix: string) => {
  let id = '';
  const dataCombinationLength = dataCombination.length;
  for (let i = 0; i < length; i++) {
    id += dataCombination.charAt(
      Math.floor(Math.random() * dataCombinationLength)
    );
  }
  return prefix + id;
};

export const landFillAreaSave = (quantity: number) => {
  return (quantity * landFillAreaSavedQuantity).toFixed(2);
};

export const CO2EmissionSave = (quantity: number) => {
  return quantity * Co2EmissionsSaved;
};

export const AluminiumEmissionSave = (unloadedQuantity: volume) => {
  return AluminiumSaved * convertQuantityToKg(unloadedQuantity);
};

// export const createExcelWorkbook = (arr: any) => {
//   let workBook = reader.utils.book_new();
//   const workSheet = reader.utils.json_to_sheet(arr);
//   reader.utils.book_append_sheet(workBook, workSheet, `response`);
//   let base64 = reader.write(workBook, { type: 'base64', bookType: 'xlsx' });
//   return base64;
// }
export const itterateDataFromExcel = (dataFromWorksheet: any) => {
  const jsonArray = [];
  const headerData = JSON.parse(JSON.stringify(dataFromWorksheet[0]));
  for (let i = 1; i < dataFromWorksheet.length; i++) {
    const rowData = JSON.parse(JSON.stringify(dataFromWorksheet[i]));
    if (rowData?.length > 0) {
      const jsonObj: any = {};
      for (let j = 0; j < headerData.length; j++) {
        headerData[j] = headerData[j].replace('*', '');
        if (headerData[j] !== 'Error Reason') {
          jsonObj[headerData[j]] = rowData[j] !== undefined ? rowData[j] : '';
        }
      }
      jsonArray.push(jsonObj);
    }
  }
  return jsonArray;
};
