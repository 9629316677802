import React from "react";
import { Link } from "react-router-dom";
export const ComingSoon = (props:ComingSoonProp) => {

    return (
        <div className="comming-soon-page body-content-space">
            <div className="container">
                <div className="image-grid-data">
                    <div className="row">
                        <div className="col-md-6 left-content-item">
                            <div className="left-data">
                                <h2 className="big-title">COMING SOON</h2>
                                <h3 className="title-mid">Something amazing is under development.</h3>
                                <p className="description">
                                   Meanwhile, use available features on Home Page or contact us
                                    {/* <a href="#" className="link">emailid@domain.com</a> */}
                                </p>
                                {!props.hideButton && (
                                    <div className="button-items">
                                        <Link to="/" className="btn btn-primary">
                                        Go to Homepage
                                        </Link>
                                    </div>
                                    )}
                            </div>
                        </div>
                        <div className="col-md-6 right-img-item">
                            <div className="right-image">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/comming-soon-1.svg`} alt={""}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>      
  );
};

export default ComingSoon;
export interface ComingSoonProp {
    hideButton?: boolean;
  }