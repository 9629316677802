import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import PCRequestDetails from './pcRequestDetails';
import { useNavigate } from 'react-router-dom';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';

import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation } from 'react-router-dom';
import { plasticCreditRequestStatus } from '../../../shared/remake-green-utils';

const PlasticCreditRequestDetails = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { t: translate } = useTranslation();
  const [data, setData] = useState<any>(null);

  const getPCDetails = async (id: string) => {
    let path = apiUrl.getPlasticCreditDetailsByID + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setData(res?.data);
    } else {
      toast.error(res.message);
    }
  };
  useEffect(() => {
    if (id) {
      getPCDetails(id);
    }
  }, [id]);

  const getDetailComponent = () => {
    switch (data && data.pcRequestDetails && data.pcRequestDetails.status) {
      case plasticCreditRequestStatus.REQUESTED:
        return (
          <PCRequestDetails
            id={id ? id : '1'}
            endpoint={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.APPROVE_OR_REJECT_TITLE'
            )}
            panelTitle={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.APPROVE_OR_REJECT_TITLE'
            )}
            pcStatus={data?.pcRequestDetails?.status}
            requestDetails={data}
            pcrType={data?.pcRequestDetails?.credit_type}
            isGuestUser={state.isGuestUser}
          />
        );
      case plasticCreditRequestStatus.REJECTED:
        return (
          <PCRequestDetails
            id={id ? id : '1'}
            endpoint={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CREDIT_REQUEST_STATUS'
            )}
            panelTitle={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CREDIT_REQUEST_STATUS'
            )}
            pcStatus={data?.pcRequestDetails?.status}
            requestDetails={data}
            pcrType={data?.pcRequestDetails?.credit_type}
            isGuestUser={state.isGuestUser}
          />
        );

      case plasticCreditRequestStatus.APPROVED:
        return (
          <PCRequestDetails
            id={id ? id : '1'}
            endpoint={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.LINKED_TRANSACTIONS'
            )}
            panelTitle={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.LINKED_TRANSACTIONS'
            )}
            pcStatus={data?.pcRequestDetails?.status}
            requestDetails={data}
            pcrType={data?.pcRequestDetails?.credit_type}
            isGuestUser={state.isGuestUser}
          />
        );

      case plasticCreditRequestStatus.COMPLETED:
        return (
          <PCRequestDetails
            id={id ? id : '1'}
            endpoint={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CERTIFICATE_GENERATED'
            )}
            panelTitle={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CERTIFICATE_GENERATED'
            )}
            pcStatus={data?.pcRequestDetails?.status}
            requestDetails={data}
            pcrType={data?.pcRequestDetails?.credit_type}
            isGuestUser={state.isGuestUser}
          />
        );
      case plasticCreditRequestStatus.PARTIAL:
        return (
          <PCRequestDetails
            id={id ? id : '1'}
            endpoint={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CERTIFICATE_GENERATED'
            )}
            panelTitle={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CERTIFICATE_GENERATED'
            )}
            pcStatus={data?.pcRequestDetails?.status}
            requestDetails={data}
            pcrType={data?.pcRequestDetails?.credit_type}
            isGuestUser={state.isGuestUser}
          />
        );
      case plasticCreditRequestStatus.CLOSED:
        return (
          <PCRequestDetails
            id={id ? id : '1'}
            endpoint={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CERTIFICATE_GENERATED'
            )}
            panelTitle={translate(
              'PLASTIC_CREDIT_MANAGEMENT.TITLES.CERTIFICATE_GENERATED'
            )}
            pcStatus={data?.pcRequestDetails?.status}
            requestDetails={data}
            pcrType={data?.pcRequestDetails?.credit_type}
            isGuestUser={state.isGuestUser}
          />
        );
    }
  };

  return <div>{getDetailComponent()}</div>;
};

export default PlasticCreditRequestDetails;
