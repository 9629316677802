import EventEmitter from 'events';
import { io, Socket } from 'socket.io-client';
//import { logger } from '../../../shared/remake-green-utils/logger';
import { NotificationMessage } from '../../../shared/remake-green-utils/notification/notification.message';

export class NotificationClient extends EventEmitter {
  socket: Socket | undefined;
  URL="";
  isConnected = false;
  constructor(url: string){
    super();
    this.URL=url;
  }
  startClient = () => {
    this.socket = io(this.URL,{
        transports: ['websocket']
     });
    this.socket.on('connect', () => {
      this.isConnected = true;
      this.emit("connected","Notification client connected");
      console.log("Notification client connected");
     // logger.info('Notification client connected');
      console.log(this.socket?.id); // ojIckSD2jqNzOqIrAGzL
    });
    this.socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
  };
  subscribeToEvent = (
    eventName: string,
    callback: (message: NotificationMessage) => void,
  ) => {
    console.log("subscribe to event");
    console.log(this.isConnected);
    if (!this.isConnected) return;
    this.socket?.on(eventName, callback);
  };
}
