import { Outlet, useLocation } from "react-router";
import { Footer } from "./footer";
import Header from "./header";

export const Layout = () => {
    const location = useLocation();
    const isHomePage = location.pathname.indexOf('admin') === -1;   
    return (    
        <div className={!isHomePage?"body-color":""}>
        <Header/>
        <Outlet/>   
        <Footer/>     
        </div>         
    );
}