import { plasticCreditApi } from "..";
import {
  CodeLoginMutationInput,
  LoginMutationInput,
  LoginMutationOutput,
} from "../../types/authentication";
import { ServiceResponseInterface } from "../../types/serverResponse";
import { apiUrl } from "./apiUrl";

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<
      ServiceResponseInterface<LoginMutationOutput>, LoginMutationInput>({
        query: ({ ...data }) => ({
          url: apiUrl.login,
          method: "POST",
          body: data,
        }),
      }),
      codelogin: builder.mutation<ServiceResponseInterface<any>, any>({
        query: ({ ...data }) => ({
          url: apiUrl.codelogin,
          method: "POST",
          body: data,
        }),
      }),
    sendOtp: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.sendOtp,
        method: "POST",
        body: data,
      }),
    }),
    verifyOtp: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.verifyOtp,
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.createRemakeUser,
        method: "POST",
        body: data,
      }),
    }),
  }),
 
  overrideExisting: false,
});

export const { useLoginMutation, useRegisterMutation, useSendOtpMutation, useVerifyOtpMutation, useCodeloginMutation } = extendedApi;
