import { useState, useEffect, FormEvent, useRef } from 'react';
import { Button, Form, Image, InputGroup } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import { useCodeloginMutation, useLoginMutation } from '../../../app/api/services/authentication.service';
import { useAppDispatch } from '../../../app/hooks';
import { store } from '../../../app/store';
import { auth } from '../../../app/store/slices/auth.slice';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  UserProfileStatus,
  UserType,
} from '../../../shared/remake-green-utils';
import jwtDecode from 'jwt-decode';
import {
  commanLabel,
  imagePath,
  routesConstant,
} from '../../../constants/comman.constant';
interface LoginProps {
  isLoginFromDocument?: boolean;
  pcId: string;
  transactionId: string;
  onLogin?: () => void;
}
export const Login = (props: LoginProps) => {
  const location = useLocation();
  const { t: translate } = useTranslation();
  const isAdmin = location.pathname.indexOf('admin') > -1;
  const [value, setValue] = useState({ email: '', password: '', code: '' });
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const [codelogin] = useCodeloginMutation();
  const [validated, setValidated] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [failedAttempt, setFailedAttempt] = useState(0);
  //const [captchaRef, setCaptchaRef] = useState<any>();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const state = store.getState();
  const [isCodeLogin, setIsCodeLogin] = useState(false);
  useEffect(() => {
    if (state.auth.user?.role === UserType.ADMIN) {
      navigate(routesConstant.userManagement);
    } else if (state.auth.user?.role === UserType.VALUE_CHAIN_PARTNER) {
      if (state.auth.user?.status === UserProfileStatus.PENDING) {
        navigate(routesConstant.valueChainPartner);
      } else if (state.auth.user?.status === UserProfileStatus.APPROVED) {
        navigate(routesConstant.transactionList);
      } else if (state.auth.user?.status === UserProfileStatus.REJECTED) {
        navigate(routesConstant.valueChainPartner);
      }
    } else if (
      state.auth.user?.role === UserType.REMAKE &&
      state.auth.user?.status === UserProfileStatus.PENDING
    ) {
      navigate(routesConstant.createRemake);
    } else if (
      state.auth.user?.role === UserType.REMAKE &&
      state.auth.user?.status === UserProfileStatus.APPROVED
    ) {
      navigate(routesConstant.remakeUserDashboard);
    } else if (
      state.auth.user?.role === UserType.REMAKE &&
      state.auth.user?.status === UserProfileStatus.REJECTED
    ) {
      navigate(routesConstant.createRemake);
    } else if (
      state.auth.user?.role === UserType.REMAKE &&
      !state.auth.user?.status
    ) {
      navigate(routesConstant.createRemake);
    }
  }, [navigate, state]);

  const onChange = (e: any) => {
    if (e.target.name === 'code' && e.target.value) {
      setIsCodeLogin(true);
      setValidated(false);
    } else {
      setIsCodeLogin(false);
      value.code ="";
    }
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {

    if (isCodeLogin) {
      try {
        let data = {
          code: value.code,
          pcId: window.atob(props.pcId),
          transactionId: window.atob(props.transactionId)
        };
        event.preventDefault();
        const response = await codelogin(data).unwrap();
        if (response.success) {
          if (props.isLoginFromDocument) {
            if (props.onLogin) props.onLogin();
          }
        } else {
          toast.error(
            response?.message ??
            translate('ERRORS.INVALID.INVALID_USERNAME_PASSWORD')
          );
        }
      } catch (error: any) {
        const errResponse = error?.data ?? {};
        toast.error(
          errResponse?.message ??
          translate('ERRORS.INVALID.INTERNAL_SERVER_ERROR')
        );
      }
    } else {
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      event.preventDefault();
      try {
        let data = {
          email: value.email,
          password: window.btoa(value.password),
        };
        const response = await login(data).unwrap();
        if (response.success) {
          setFailedAttempt(0);
          appDispatch(auth(response.data.token));
          let tokendata: any = jwtDecode(response.data.token);
          localStorage.setItem('userId', tokendata?.id);
          localStorage.setItem('verifyEmail', tokendata?.email);
          if (props.isLoginFromDocument) {
            if (props.onLogin) props.onLogin();
          } else {
            if (tokendata?.role === UserType.ADMIN) {
              navigate(routesConstant.plasticCreditRequest);
            } else if (tokendata?.role === UserType.VALUE_CHAIN_PARTNER) {
              if (tokendata?.status === UserProfileStatus.PENDING) {
                navigate(routesConstant.valueChainPartner);
              } else if (tokendata?.status === UserProfileStatus.APPROVED) {
                navigate(routesConstant.transactionList);
              } else if (tokendata?.status === UserProfileStatus.REJECTED) {
                navigate(routesConstant.valueChainPartner);
              }
            } else if (
              tokendata?.role === UserType.REMAKE &&
              tokendata?.status === UserProfileStatus.PENDING
            ) {
              navigate(routesConstant.createRemake);
            } else if (
              tokendata?.role === UserType.REMAKE &&
              tokendata?.status === UserProfileStatus.APPROVED
            ) {
              navigate(routesConstant.remakeUserDashboard);
            } else if (
              tokendata?.role === UserType.REMAKE &&
              tokendata?.status === UserProfileStatus.REJECTED
            ) {
              navigate(routesConstant.createRemake);
            } else if (
              tokendata?.role === UserType.REMAKE &&
              !tokendata?.status
            ) {
              navigate(routesConstant.createRemake);
            }
          }
        } else {
          setFailedAttempt(failedAttempt + 1);
          toast.error(
            response?.message ??
            translate('ERRORS.INVALID.INVALID_USERNAME_PASSWORD')
          );
        }
      } catch (error: any) {
        console.log(error);
        const errResponse = error?.data ?? {};
        setFailedAttempt(failedAttempt + 1);
        toast.error(
          errResponse?.message ??
          translate('ERRORS.INVALID.INTERNAL_SERVER_ERROR')
        );
      }
    }


  };
  const togglePassword = () => {
    if (passwordType === commanLabel.password) {
      setPasswordType(commanLabel.text);
      return;
    }
    setPasswordType(commanLabel.password);
  };
  const onCaptchaVerify = (token: string | null) => {
    setFailedAttempt(0);
    captchaRef?.current?.reset();
  };

  return (
    <div className='body-content-space pb-0'>
      <section className='form'>
        <div className='container'>
          <div className='form-data'>
            <div className='row'>
              <div className='col-md-4'>
                {props.isLoginFromDocument && (
                  <h2 className='title-main'>
                    <span>{translate('LOGINPAGE.TITLES.LOGIN')}</span>{' '}
                    {translate('LOGINPAGE.TITLES.TO_VIEW_DETAILS')}
                  </h2>
                )}
                {!props.isLoginFromDocument && (
                  <h2 className='title-main'>
                    <span>{translate('COMMONFIELDS.REMAKE')}</span>{' '}
                    {translate('LOGINPAGE.TITLES.LOGIN')}
                  </h2>
                )}
                <Form
                  className='login-form'
                  noValidate
                  validated={validated}
                  onSubmit={onSubmit}
                >
                  <Form.Group className='form-group'>
                    <Form.Label className=''>
                      {translate('LOGINPAGE.TITLES.USER_NAME')}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      name='email'
                      pattern='^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$'
                      placeholder='yourmail@gmail.com'
                      autoComplete='off'
                      aria-label='Email'
                      id='username1'
                      value={value.email}
                      onChange={onChange}
                      required
                    />
                    <Form.Control.Feedback type='invalid'>
                      {translate('ERRORS.REQUIRED.LOGIN_USER_NAME')}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='form-group'>
                    <Form.Label className=''>
                      {translate('LOGINPAGE.TITLES.PASSWORD')}
                    </Form.Label>
                    <InputGroup className='password-input'>
                      <Form.Control
                        type={passwordType}
                        name='password'
                        placeholder='Password'
                        autoComplete='off'
                        aria-label='Password'
                        value={value.password}
                        onChange={onChange}
                        required
                      />
                      <InputGroup.Text>
                        <i
                          className={`fas ${passwordType !== 'password'
                              ? 'fa-eye-slash eye'
                              : 'fa-eye eye'
                            }`}
                          onClick={togglePassword}
                        ></i>
                      </InputGroup.Text>
                      <Form.Control.Feedback type='invalid'>
                        {translate('ERRORS.REQUIRED.LOGIN_PASSWORD')}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  {props.isLoginFromDocument &&
                    <>
                      <div className="or-div">
                        <div className="line-separator"></div>
                        <div className="or-label"> OR</div>
                        <div className="line-separator"></div>
                      </div>
                      <Form.Group className='form-group'>
                        <Form.Label className=''>
                          {translate('LOGINPAGE.TITLES.DOCUMENT_CODE')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          name='code'
                          placeholder='ABCDEF'
                          autoComplete='off'
                          aria-label='Code'
                          id='code'
                          value={value.code}
                          onChange={onChange}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {translate('ERRORS.REQUIRED.LOGIN_USER_NAME')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  }

             
                  <Form.Group
                    className='mb-4'
                    style={{ display: failedAttempt >= 3 ? 'block' : 'none' }}
                  >
                    {/* {process.env.REACT_APP_RECAPTCHA_SITE_KEY} */}
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                      ref={captchaRef}
                      onChange={onCaptchaVerify}
                    />
                  </Form.Group>
                  <div className='forget-password-link font-base'>
                    <Link to={routesConstant.forgotPassword}>
                      {translate('LOGINPAGE.TITLES.FORGOT_PASSWORD')}
                    </Link>
                  </div>
                  <div className='submit-button'>
                    <Button
                      type='submit'
                      disabled={failedAttempt >= 3}
                      className='btn btn-primary'
                    >
                      {translate('LOGINPAGE.TITLES.LOGIN')}
                    </Button>
                  </div>
                  <div className='link-register'>
                    <Link to={routesConstant.signUp}>
                      {translate('LOGINPAGE.TITLES.REGISTER_REMAKE_USER')}
                    </Link>
                    <Link to={routesConstant.valueChainPartner}>
                      {translate(
                        'LOGINPAGE.TITLES.REGISTER_VALUE_CHAIN_PARTNER'
                      )}
                    </Link>
                  </div>
                </Form>
              </div>
              <div className='col-md-7  offset-md-1 right-img-form'>
                <div className='right'>
                  <Image src={imagePath.formImgPath} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
