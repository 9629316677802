import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  commanLabel,
  getCreditType,
  statusType,
} from '../../../constants/comman.constant';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import CertificatePopup from '../../../components/certificatePopup';
import Pagination from 'react-bootstrap/Pagination';
import { EnumHelper } from '../../../shared/remake-green-utils/common/utils/enum.helper';
import { useSendEmailMutation } from '../../../app/api/services/transaction.service';
import moment from 'moment';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { useGetMaterialSubTypeQuery } from '../../../app/api/services/createTransaction.service';
import {
  calculateCreditPoint,
  plasticCreditRequestStatus,
} from '../../../shared/remake-green-utils';
import {
  PCRequestStatus,
  PCRStatus,
} from '../../../shared/remake-green-utils/common/constants/enum';
import { useReCreatePdfMutation } from '../../../app/api/services/PCRequestManagement.service';
import ChartStatistics from '../../../components/chartStatistics';

const creditType = [
  {
    name: 'Collection Credit',
    value: 1,
  },
  {
    name: 'Recycling Credit',
    value: 2,
  },
  {
    name: 'Circular Credit',
    value: 3,
  },
];

const statusContainer = (status: PCRequestStatus) => {
  switch (status) {
    case PCRequestStatus.Awating:
      return (
        <div className='status-data status-awaiting'>
          <div className='tag-icon round-fa-icon'>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/Awaiting.svg`}
              alt='cc'
            />
            &nbsp;&nbsp;&nbsp;
            <span>
              {EnumHelper.GetEnumDescription(
                PCRequestStatus,
                PCRequestStatus.Awating
              )}
            </span>
          </div>
        </div>
      );

    case PCRequestStatus.Approved:
      return (
        <div className='status-data status-approved'>
          <div className='tag-icon round-fa-icon'>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/Approved.svg`}
              alt='cc'
            />
            &nbsp;&nbsp;&nbsp;
            <span>
              {EnumHelper.GetEnumDescription(
                PCRequestStatus,
                PCRequestStatus.Approved
              )}
            </span>
          </div>
        </div>
      );

    case PCRequestStatus.Rejected:
      return (
        <div className='status-data reject'>
          <div className='tag-icon round-fa-icon'>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/Reject.svg`}
              alt='cc'
            />
            &nbsp;&nbsp;&nbsp;
            <span>
              {EnumHelper.GetEnumDescription(
                PCRequestStatus,
                PCRequestStatus.Rejected
              )}
            </span>
          </div>
        </div>
      );

    case PCRequestStatus.Completed:
      return (
        <div className='status-data'>
          <div className='tag-icon'>
            <i className='fa-solid fa-circle-check' aria-hidden='true'></i>
            &nbsp;&nbsp;
            <span>
              {EnumHelper.GetEnumDescription(
                PCRequestStatus,
                PCRequestStatus.Completed
              )}
            </span>
          </div>
        </div>
      );

    case PCRequestStatus.Partial:
      return (
        <div className='status-data'>
          <div className='tag-icon'>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/Partial.svg`}
              alt='cc'
            />
            &nbsp;&nbsp;&nbsp;
            <span>
              {EnumHelper.GetEnumDescription(
                PCRequestStatus,
                PCRequestStatus.Partial
              )}
            </span>
          </div>
        </div>
      );

    case PCRequestStatus.Closed:
      return (
        <div className='status-data closed'>
          <div className='tag-icon'>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/Closed.svg`}
              alt='cc'
              height={20}
              width={20}
            />
            &nbsp;&nbsp;&nbsp;
            <span>
              {EnumHelper.GetEnumDescription(
                PCRequestStatus,
                PCRequestStatus.Closed
              )}
            </span>
          </div>
        </div>
      );

    default:
      return (
        <div className='status-data status-purple'>
          <div className='tag-icon round-fa-icon'>
            <i className='fa fa-hourglass-half ' aria-hidden='true'></i>
            <span>-</span>
          </div>
        </div>
      );
  }
};

const PlasticCreditRequestManagement = () => {
  const { t: translate } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openCertificate, setOpenCertificate] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState(
    state?.pcrType ? state?.pcrType : 3
  );
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [isLoading, setIsLoading] = useState(false);
  const [materialType, setMaterialType] = useState<any>([]);
  const [selectedTableData, setSelectedTableData] = useState(
    state?.isGuestUser ? state.isGuestUser : true
  );
  const [regions, setRegions] = useState<any>([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [certificateList, setCertificateList] = useState<any>([]);
  const [reCreatePdf] = useReCreatePdfMutation();
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const [value, setValue] = useState({
    page: 1,
    creditType: 1,
    limit: 10,
    start_date: '',
    end_date: '',
    status: [] as any,
    type_of_material: '',
    sub_type_of_material: '',
    region: '',
  });
  const [sendEmail] = useSendEmailMutation();
  const { data: materialSubData, refetch: getMaterialSubType } =
    useGetMaterialSubTypeQuery(value.type_of_material);
  const [materialSubTypeData, setMaterialSubTypeData] = useState<any[]>([]);

  const columnHelper = createColumnHelper<any>();

  // const { data: regions, refetch: getRegions } = useGetCountriesQuery({});
  // const { data: settingsData, refetch: getSettings } = useGetSettingsQuery({});
  // const { data: certificateList, refetch: getCertificatelist } =
  //   useGetCertificateListQuery(value);

  const getRegions = async () => {
    let path = apiUrl.getRegions;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let countries = res?.data && res?.data?.countries;
      setRegions(countries);
    } else {
      toast.error(res.message);
    }
  };

  const getSettingData = async () => {
    let path = apiUrl.getAllSetting;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let allSetting = res?.data && res?.data?.jsonData;
      setMaterialType(allSetting?.Material_type);
    } else {
      toast.error(res.message);
    }
  };

  const getFilteredHistory = (info: any) => {
    return info.plastic_credit_request_details_history.filter((inf: any) => {
      return inf?.link_transaction_certificate_detail?.length;
    });
  };

  const columns = [
    columnHelper.accessor('-', {
      header: () => translate('') + '',
      enableSorting: false,
      cell: (props: any) => {
        const id = props.row.original.id;
        const status = props.row.original.plastic_credit_request_status.id;
        const requestDetailsBatch =
          props.row.original.plastic_credit_request_details_history?.filter(
            (data: any) => {
              return data.link_transaction_certificate_detail.length > 0;
            }
          );
        return (
          <React.Fragment>
            {(status == PCRequestStatus.Completed ||
              status == PCRequestStatus.Closed ||
              status == PCRequestStatus.Partial) &&
              requestDetailsBatch.length > 0 && (
                <a
                  href='javascript:void(0);'
                  className='description-btn'
                  id={`desc-btn-${id}`}
                  onClick={() => {
                    let data = document.getElementById(`desc-content-${id}`);
                    if (data?.classList.contains('active')) {
                      data.classList.remove('active');
                    } else {
                      data && data?.classList?.add('active');
                    }
                    let btnData = document.getElementById(`desc-btn-${id}`);
                    if (btnData?.classList.contains('btn-active')) {
                      btnData.classList.remove('btn-active');
                    } else {
                      btnData && btnData?.classList?.add('btn-active');
                    }
                    let rowActiveData = document.getElementById(
                      `transaction-row-${id}`
                    );
                    if (rowActiveData?.classList.contains('active-row')) {
                      rowActiveData.classList.remove('active-row');
                    } else {
                      rowActiveData &&
                        rowActiveData?.classList?.add('active-row');
                    }
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/down-arrow-svg.svg`}
                  />
                </a>
              )}
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor(
      (u) =>
        u.pc_user ? u.pc_user.pc_user_type.name : u.user?.user_type?.name,
      {
        id: 'Role',
      }
    ),
    columnHelper.accessor(
      (u) => (u.pc_user ? u.pc_user.name : u.user?.user_profile?.user_name),
      {
        id: 'Requested From',
      }
    ),
    columnHelper.accessor(
      (u) =>
        `${JSON.parse(u.pc_request_details).quantity} ${
          JSON.parse(u.pc_request_details).quantityUnit
        } - ${u.category.name} ${u.sub_category.name} (${
          u.region ? `${u.region.name}` : '-'
        })`,
      {
        id: 'Qty.- Type of Material (Region)',
      }
    ),
    columnHelper.accessor(
      (u) => `${moment(u.created_on).format(' DD/MM/YYYY hh:mm A ')}`,
      {
        id: 'Date & Time',
      }
    ),
    columnHelper.accessor(
      (u) => `${u?.fulfilled_qty ? u?.fulfilled_qty : '-'}`,
      {
        id: 'Fulfilled Qty.(PC)',
        enableSorting: false,
      }
    ),

    columnHelper.accessor(
      (u) =>
        `${
          u?.fulfilled_qty
            ? (
                Number(
                  calculateCreditPoint({
                    unit: JSON.parse(u?.pc_request_details).quantityUnit,
                    quantity: JSON.parse(u?.pc_request_details).quantity,
                  })
                ) - Number(u.fulfilled_qty)
              ).toFixed(3)
            : Number(
                calculateCreditPoint({
                  unit: JSON.parse(u?.pc_request_details).quantityUnit,
                  quantity: JSON.parse(u?.pc_request_details).quantity,
                })
              )
        }`,
      {
        id: 'Pending Qty.(PC)',
        enableSorting: false,
      }
    ),
    // columnHelper.accessor((u) => `${u.plastic_credit_request_status.name}`, {
    //   id: 'Status',
    // }),

    columnHelper.accessor('status', {
      header: () =>
        translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.APPROVAL_STATUS') + '',
      enableSorting: false,
      cell: (props: any) => {
        return (
          <React.Fragment>
            {statusContainer(
              props?.cell?.row?.original?.plastic_credit_request_status?.id
            )}
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor('-', {
      header: () => 'Action',
      id: 'Action',
      enableSorting: false,
      cell: (props: any) => {
        let id = props?.row?.original?.id;
        let filterTransHistory = getFilteredHistory(props.row.original);
        let isCompleted =
          props?.row?.original?.plastic_credit_request_status?.id ===
            plasticCreditRequestStatus.COMPLETED ||
          props?.row?.original?.plastic_credit_request_status?.id ===
            plasticCreditRequestStatus.PARTIAL ||
          props?.row?.original?.plastic_credit_request_status?.id ===
            plasticCreditRequestStatus.CLOSED
            ? true
            : false;
        return (
          <React.Fragment>
            <div className='icon-action-main'>
              <div
                className={`icon-view icon ${
                  !isCompleted ? 'disable-icon-data' : ''
                }`}
              >
                <Link to={`#`} title='Send Mail'>
                  <img
                    onClick={() => {
                      if (isCompleted) {
                        sendEmail(id)
                          .then((res: any) => {
                            console.log('Res::', res);
                            if (res?.data?.data?.isSend) {
                              toast.success('Mail Sent Successfully!');
                            }
                          })
                          .catch((err) => {
                            console.log('Errr', err);
                          });
                      }
                    }}
                    src={`${process.env.PUBLIC_URL}/assets/images/send-email.svg`}
                    alt={''}
                  />
                </Link>
              </div>
              <div
                className={`icon-view icon ${
                  !isCompleted ? 'disable-icon-data' : ''
                }`}
              >
                <div
                  onClick={async () => {
                    if (isCompleted) {
                      if (
                        filterTransHistory.length > 1 ||
                        (filterTransHistory.length === 1 &&
                          filterTransHistory[0]
                            ?.link_transaction_certificate_detail.length > 1)
                      ) {
                        let ids =
                          filterTransHistory[0]?.link_transaction_certificate_detail.map(
                            (item: any) => item.id
                          );

                        const response = await reCreatePdf({
                          linkTransactionCertificateDetailIds: ids,
                          pcId: id,
                        }).unwrap();

                        if (response.success) {
                          if (
                            filterTransHistory[0]
                              ?.link_transaction_certificate_detail[0]
                              ?.certificate_url
                          ) {
                            window.open(
                              process.env.REACT_APP_BASE_URL_API +
                                `plastic-credit-request-management/plastic-credit/dowload?credit_id=${id}`
                            );
                            toast.success('Certificate Downloaded');
                          } else {
                            toast.warning(
                              'Download Certificate in progress please wait for sometime.'
                            );
                            setTimeout(() => {
                              getData(false, selectedCredit);
                            }, 1000);
                          }
                        } else {
                          toast.error(response.data.message);
                        }
                      } else if (filterTransHistory.length === 1) {
                        const response = await reCreatePdf({
                          linkTransactionCertificateDetailIds: [
                            Number(
                              filterTransHistory[0]
                                ?.link_transaction_certificate_detail[0]?.id
                            ),
                          ],
                          pcId: id,
                        }).unwrap();

                        if (response.success) {
                          //   const tempUrl = filterTransHistory[0]?.link_transaction_certificate_detail[0]?.certificate_url;
                          if (response?.data?.url) {
                            let path = apiUrl.prepareFile + response?.data?.url;
                            const fr = await helper.getData(path);
                            const res = await fr.response;
                            if (res.data) {
                              window.open(
                                process.env.REACT_APP_BASE_URL_API +
                                  apiUrl.downloadFile +
                                  response?.data?.url
                              );
                              toast.success('Certificate Downloaded');
                            } else {
                              toast.error(res.message);
                            }
                          } else {
                            toast.warning(
                              'Download Certificate in progress please wait for sometime.'
                            );
                            setTimeout(() => {
                              getData(false, selectedCredit);
                            }, 1000);
                          }
                        } else {
                          toast.error(response.data.message);
                        }
                      }
                    }
                  }}
                >
                  <Link to={`#`} title='Download'>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/download.svg`}
                      alt='#'
                    />
                  </Link>
                </div>
              </div>
              <div
                className={`icon-view icon ${
                  !isCompleted ? 'disable-icon-data' : ''
                }`}
                data-toggle='modal'
                // data-target="#pc--certificate"
                onClick={() => {
                  if (isCompleted) {
                    const filteredHistory =
                      getFilteredHistory(props?.row?.original) || [];
                    if (
                      filteredHistory.length > 1 ||
                      (filteredHistory.length === 1 &&
                        filteredHistory[0]?.link_transaction_certificate_detail
                          ?.length > 1)
                    ) {
                      setSelectedTransaction(props?.row?.original);
                      setOpenCertificate(true);
                    } else {
                      let data = filteredHistory.length
                        ? filteredHistory[0]
                            .link_transaction_certificate_detail[0]
                            ?.linked_transaction_detail
                        : {};
                      if (data?.transaction_id) {
                        window.open(
                          `/admin/transaction/plasticCreditCertification/${window.btoa(
                            data?.transaction_id
                          )}&${window.btoa(props?.row?.original?.id)}`
                        );
                      }
                    }
                  }
                }}
              >
                <Link to={`#`} title='Open'>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/share.svg`}
                    alt={''}
                  />
                </Link>
              </div>
            </div>
          </React.Fragment>
        );
      },
    }),
  ];

  const getPagination = () => {
    let items = [];
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item
          activeLabel=''
          key={number}
          active={number === page}
          onClick={(e) => {
            e.preventDefault();
            setPage(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: certificateList,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  useEffect(() => {
    handeleReset(selectedCredit);
  }, [selectedTableData]);

  const getData = async (reset: boolean, creditType: Number) => {
    setIsLoading(true);
    const parms = {
      page: reset ? 1 : page,
      limit: commanLabel.pageLimit,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),
      status: reset ? JSON.stringify([]) : JSON.stringify(value.status),
      type_of_material: reset ? '' : value.type_of_material,
      sub_type_of_material: reset ? '' : value.sub_type_of_material,
      region: reset ? '' : value.region,
      credit_type: creditType,
      isGuestUser: selectedTableData,
    };
    let path = apiUrl.getCertificateList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setCertificateList(res.data.allTransaction);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  const materialSubTypeOption = (materialSubType: any) => {
    var temArray: any[] = [];
    materialSubType &&
      materialSubType.map((item: any) => {
        temArray.push({ id: item.id, name: item.name });
      });
    setMaterialSubTypeData(temArray || []);
  };

  useEffect(() => {
    materialSubTypeOption(materialSubData?.data?.allSubCategory);
  }, [materialSubData]);
  const getMaterialSubCategory = async (materialId?: string) => {
    let path = apiUrl.getMaterialSubCategory + materialId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let subCategory = res?.data?.allSubCategory;
      console.log(subCategory);
      setMaterialSubTypeData(subCategory);
    } else {
      toast.error(res.message);
    }
  };
  const onChange = async (e: any) => {
    if (e.target.name === 'type_of_material') {
      await setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
      return;
    }
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    await getMaterialSubCategory(value.type_of_material);
  };

  useEffect(() => {
    getRegions();
    getSettingData();
  }, []);

  useEffect(() => {
    if (page > 1) {
      getData(false, selectedCredit);
    }
  }, [page]);

  const handeleReset = (creditType: Number) => {
    setValue({
      page: 1,
      creditType: 1,
      limit: 10,
      start_date: '',
      end_date: '',
      status: [],
      type_of_material: '',
      sub_type_of_material: '',
      region: '',
    });
    setPage(1);
    setDateRange([null, null]);
    getData(true, creditType);
  };

  const getNoRecordMessage = () => {
    let msg = 'No record found for ';
    const selCreditType = creditType.find(
      (crType) => crType.value === selectedCredit
    );
    return msg + selCreditType?.name.toLowerCase();
  };

  return (
    <div>
      <>
        {openCertificate && selectedTransaction !== null && (
          <CertificatePopup
            selectedTransaction={selectedTransaction}
            handleClose={setOpenCertificate}
          />
        )}
      </>
      <div className='body-content-main pc-request'>
        <div className='container'>
          <div className='section-title-main'>
            <div className='title'>
              {translate(
                'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_CREDIT_REQUEST_MANAGEMENT'
              )}
            </div>

            <div className='form-o-submit'>
              <a className='btn btn-primary' href='/admin/generateCertificate'>
                Generate Certificate
              </a>
            </div>
          </div>
          <ChartStatistics />
          <div className='transaction-table listuser-table'>
            <div className='panel-head-title'>
              <div className='left-content'>
                <div className='title-normal'>
                  {translate(
                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_CREDIT_REQUEST_MANAGEMENT'
                  )}
                </div>
              </div>

              <div className='right-content'>
                <div className='toggle-button big'>
                  <div className='form-group-switch checked'>
                    <span className={!selectedTableData ? 'right' : 'left'}>
                      {translate(
                        'PLASTIC_CREDIT_MANAGEMENT.TITLES.REMAKE_USER'
                      )}
                    </span>
                    <label className='switch '>
                      <input
                        type='checkbox'
                        checked={selectedTableData}
                        onChange={(e) => setSelectedTableData(e.target.checked)}
                      />
                      <span className='slider round'></span>
                    </label>
                    <span className={selectedTableData ? 'right' : 'left'}>
                      {translate('PLASTIC_CREDIT_MANAGEMENT.TITLES.GUEST_USER')}{' '}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='transaction-table management-screen'>
              <ul className='nav nav-tabs' id='' role='tablist'>
                {creditType.map((u, i) => {
                  return (
                    <li
                      key={i}
                      className='nav-item'
                      onClick={() => {
                        setSelectedCredit(u.value);
                        handeleReset(u.value);
                      }}
                    >
                      <Link
                        className={
                          selectedCredit === u.value
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        id='pc-request1-tab'
                        data-toggle='tab'
                        to={'#'}
                        role='tab'
                        aria-controls='pc-request1'
                        aria-selected='true'
                      >
                        {u.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <div className='tab-content' id=''>
                <div
                  className='tab-pane fade show active'
                  role='tabpanel'
                  aria-labelledby='pc-request1-tab'
                >
                  <div className='listing-transaction'>
                    <Form>
                      <div className='top-filters'>
                        <div className='date-item-picker item-in'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              value={startDate ? startDate : ''}
                              className='form-control'
                              placeholderText='Select Date Range'
                              dateFormat='dd/MM/yyyy'
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              onChange={(update: any) => {
                                setDateRange(update);
                              }}
                            />
                          </div>
                        </div>

                        <div className='type-select item-in'>
                          <select
                            id=''
                            name='status'
                            className='select-layout '
                            value={
                              value && value.status.length > 0
                                ? value.status[0]
                                : ''
                            }
                            onChange={(e) => {
                              setValue({
                                ...value,
                                status: e.target.value
                                  ? [Number(e.target.value)]
                                  : [],
                              });
                            }}
                          >
                            <option value={''}>Status</option>
                            {statusType?.length > 0 &&
                              statusType?.map((item: any, index: number) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className='type-select item-in'>
                          <select
                            id=''
                            name='type_of_material'
                            className='select-layout'
                            value={value.type_of_material}
                            onChange={onChange}
                          >
                            <option value=''>Type of Material</option>
                            {materialType &&
                              materialType.length > 0 &&
                              materialType.map((u: any, i: any) => {
                                return (
                                  <option key={i} value={u.id}>
                                    {u.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className='type-select item-in'>
                          <select
                            id=''
                            name='sub_type_of_material'
                            className='select-layout'
                            value={value.sub_type_of_material}
                            onChange={onChange}
                          >
                            <option value=''>Material Sub Type</option>
                            {materialSubTypeData &&
                              materialSubTypeData.length > 0 &&
                              materialSubTypeData.map((u: any, i: any) => {
                                return (
                                  <option key={i} value={u.id}>
                                    {u.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className='type-select item-in'>
                          <select
                            id=''
                            name='region'
                            className='select-layout'
                            onChange={onChange}
                            value={value.region}
                          >
                            <option value=''>Region</option>
                            {regions.length > 0 &&
                              regions.map((u: any, i: any) => {
                                return (
                                  <option key={i} value={u.id}>
                                    {u.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className='search-button item-in'>
                          <Button
                            href='#'
                            title='Search'
                            className='btn btn-primary btn-outline-primary btn-sm btn-icon'
                            onClick={(e) => {
                              e.preventDefault();
                              setPage(1);
                              getData(false, selectedCredit);
                            }}
                          >
                            <i className='fa fa-search' aria-hidden='true'></i>
                          </Button>

                          <Button
                            href='#'
                            title='Reset'
                            type={'reset'}
                            className='btn btn-primary btn-outline-primary btn-sm btn-icon'
                            onClick={(e) => {
                              e.preventDefault();
                              handeleReset(selectedCredit);
                            }}
                          >
                            <i className='fa fa-refresh' aria-hidden='true'></i>
                          </Button>
                        </div>
                      </div>
                    </Form>

                    <div className='listing-content'>
                      <div className='table-list'>
                        <table>
                          <thead>
                            {table.getHeaderGroups().map((headerGroup: any) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header: any) => {
                                  return (
                                    <th
                                      key={header.id}
                                      colSpan={header.colSpan}
                                    >
                                      {header.isPlaceholder ? null : (
                                        <div
                                          {...{
                                            className:
                                              header.column.getCanSort()
                                                ? 'cursor-pointer select-none'
                                                : '',
                                            onClick:
                                              header.column.getToggleSortingHandler(),
                                          }}
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: ' 🔼',
                                            desc: ' 🔽',
                                          }[
                                            header.column.getIsSorted() as string
                                          ] ?? null}
                                        </div>
                                      )}
                                    </th>
                                  );
                                })}
                              </tr>
                            ))}
                          </thead>
                          {certificateList.length > 0 ? (
                            <tbody>
                              {table.getRowModel().rows.map((row: any) => {
                                return (
                                  <>
                                    <tr
                                      key={row.id}
                                      style={{ cursor: 'pointer' }}
                                      id={`transaction-row-${row.original.id}`}
                                    >
                                      {row
                                        .getVisibleCells()
                                        .map((cell: any) => {
                                          return (
                                            <td
                                              key={cell.id}
                                              onClick={() => {
                                                if (
                                                  cell.column.id !== 'Action' &&
                                                  cell.column.id !== '-'
                                                ) {
                                                  navigate(
                                                    `/admin/plasticCreditRequest/${row.original.id}`,
                                                    {
                                                      state: {
                                                        PCRStatus:
                                                          row?.original
                                                            ?.plastic_credit_request_status
                                                            ?.id,
                                                        data: row?.original,
                                                        pcrType: selectedCredit,
                                                        isGuestUser:
                                                          selectedTableData,
                                                      },
                                                    }
                                                  );
                                                }
                                              }}
                                            >
                                              {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                              )}
                                            </td>
                                          );
                                        })}
                                    </tr>
                                    {
                                      <tr
                                        id={`desc-content-${row.original.id}`}
                                        className='description-row'
                                      >
                                        <td
                                          colSpan={9}
                                          className='description-td'
                                        >
                                          <div className='description-content'>
                                            <div className='description-content-inner'>
                                              {row?.original?.plastic_credit_request_details_history
                                                ?.filter((data: any) => {
                                                  return (
                                                    data
                                                      .link_transaction_certificate_detail
                                                      .length > 0
                                                  );
                                                })
                                                .map(
                                                  (
                                                    data: any,
                                                    index: number
                                                  ) => {
                                                    return (
                                                      <div
                                                        className='main-desc-area'
                                                        style={{
                                                          marginBottom: '20px',
                                                        }}
                                                      >
                                                        <div className='title-area'>
                                                          <div className='row'>
                                                            <div className='col-lg-8'>
                                                              <h5>
                                                                {translate(
                                                                  'PLASTIC_CREDIT_MANAGEMENT.TITLES.ALLOCATED_TRANSACTIONS'
                                                                )}{' '}
                                                                -{' '}
                                                                {translate(
                                                                  'PLASTIC_CREDIT_MANAGEMENT.TITLES.BATCH'
                                                                )}{' '}
                                                                {index + 1}
                                                              </h5>
                                                            </div>
                                                            <div className='col-lg-4 d-flex justify-content-end'>
                                                              <p>
                                                                {translate(
                                                                  'PLASTIC_CREDIT_MANAGEMENT.TITLES.DATE'
                                                                )}
                                                                :{' '}
                                                                {moment(
                                                                  data?.created_on
                                                                ).format(
                                                                  'DD/MM/YYYY'
                                                                )}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className='main-table-section'>
                                                          <div className='table-certificate'>
                                                            <div className='listing-content'>
                                                              <div className='table-list'>
                                                                <table>
                                                                  <thead>
                                                                    <tr>
                                                                      <td></td>
                                                                      <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.TRANSACTION_ID'
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.PLASTIC_WASTE_TYPE'
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.QTY'
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.TYPE_OF_CREDITS'
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.TOTAL_CREDIT'
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.SELLER'
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.BUYER'
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        <div className='title'>
                                                                          {translate(
                                                                            'PLASTIC_CREDIT_MANAGEMENT.TITLES.DOCUMENT_CODE'
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                    {(
                                                                      data?.link_transaction_certificate_detail ||
                                                                      []
                                                                    ).map(
                                                                      (
                                                                        u: any,
                                                                        i: number
                                                                      ) => {
                                                                        return (
                                                                          <tr>
                                                                            <td>
                                                                              <Link
                                                                                target='_blank'
                                                                                to={`/admin/transaction/plasticCreditCertification/${window.btoa(
                                                                                  u
                                                                                    ?.linked_transaction_detail
                                                                                    ?.transaction_id
                                                                                )}&${window.btoa(
                                                                                  row
                                                                                    .original
                                                                                    .id
                                                                                )}`}
                                                                                title={
                                                                                  translate(
                                                                                    'PLASTIC_CREDIT_MANAGEMENT.TITLES.VIEW_CERTIFICATE'
                                                                                  ) ||
                                                                                  ''
                                                                                }
                                                                              >
                                                                                <img
                                                                                  src='/assets/images/certificate-icon.svg'
                                                                                  alt='invite user'
                                                                                />
                                                                              </Link>
                                                                            </td>
                                                                            <td>
                                                                              {
                                                                                u
                                                                                  ?.linked_transaction_detail
                                                                                  ?.transaction_id
                                                                              }
                                                                            </td>
                                                                            <td>
                                                                              {
                                                                                u
                                                                                  ?.linked_transaction_detail
                                                                                  ?.material_type
                                                                                  ?.name
                                                                              }
                                                                              &nbsp;
                                                                              -
                                                                              &nbsp;
                                                                              {
                                                                                u
                                                                                  ?.linked_transaction_detail
                                                                                  ?.material_name_type
                                                                                  ?.name
                                                                              }
                                                                            </td>
                                                                            <td>
                                                                              {
                                                                                u?.assigned_pc
                                                                              }
                                                                              &nbsp;
                                                                              {
                                                                                'Kg'
                                                                              }
                                                                            </td>
                                                                            <td>
                                                                              {getCreditType(
                                                                                u
                                                                                  ?.linked_transaction_detail
                                                                                  ?.credit_type
                                                                              )}
                                                                              &nbsp;
                                                                              Credit
                                                                            </td>
                                                                            <td>
                                                                              {(u?.assigned_pc ||
                                                                                0) /
                                                                                1000}
                                                                            </td>
                                                                            <td>
                                                                              {
                                                                                u
                                                                                  ?.linked_transaction_detail
                                                                                  ?.seller
                                                                                  ?.name
                                                                              }
                                                                            </td>
                                                                            <td>
                                                                              {
                                                                                u
                                                                                  ?.linked_transaction_detail
                                                                                  ?.buyer
                                                                                  ?.name
                                                                              }
                                                                            </td>
                                                                            <td>
                                                                              {
                                                                                u?.document_code
                                                                              }
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {data.status_remark && (
                                                          <div className='remake-textarea'>
                                                            <div className='row mt-2'>
                                                              <div className='col-lg-12'>
                                                                <h5>Remarks</h5>
                                                                <textarea
                                                                  className='form-control'
                                                                  value={
                                                                    data?.status_remark
                                                                  }
                                                                  disabled
                                                                ></textarea>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    }
                                  </>
                                );
                              })}
                            </tbody>
                          ) : (
                            <>
                              <tbody>
                                <tr>
                                  <td colSpan={9}>
                                    {isLoading && (
                                      <h3 className='center-message-item'>
                                        Loading...
                                      </h3>
                                    )}
                                    {certificateList.length === 0 &&
                                      !isLoading && (
                                        <h3 className='center-message-item'>
                                          {getNoRecordMessage()}
                                        </h3>
                                      )}
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                      {certificateList.length > 0 && pageCount > 1 && (
                        <div className='items-center pagination-main gap-2 text-center'>
                          <button
                            className='border rounded p-1'
                            onClick={() => {
                              table.previousPage();
                              setPage(page - 1);
                            }}
                            disabled={!table.getCanPreviousPage()}
                          >
                            {'<'}
                          </button>
                          <Pagination size='sm'>{getPagination()}</Pagination>
                          <button
                            className='border rounded p-1'
                            onClick={() => {
                              table.nextPage();
                              setPage(page + 1);
                            }}
                            disabled={!table.getCanNextPage()}
                          >
                            {'>'}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade small-popup certificate-popup'
          id='pc--certificate'
          role='dialog'
          aria-labelledby='pc--certificateLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Plastic Credit Certificates</h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <i className='fa fa-times' aria-hidden='true'></i>
                </button>
              </div>
              <div className='modal-body'>
                <div className='head-item'>
                  <div className='left'>Plastic Credit Certificates #</div>
                  <div className='right'>
                    <a href='#'>*Click to open in new tab</a>
                  </div>
                </div>
                <div className='content-item'>
                  <ul>
                    <li>
                      <a href='#'>Plastic Credit Certificate 1</a>
                    </li>
                    <li>
                      <a href='#'>Plastic Credit Certificate 2</a>
                    </li>
                    <li>
                      <a href='#'>Plastic Credit Certificate 3</a>
                    </li>
                    <li>
                      <a href='#'>Plastic Credit Certificate 4</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlasticCreditRequestManagement;
