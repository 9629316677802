import _ from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import marker1 from '../../svg/1.svg';
import marker2 from '../../svg/2.svg';
import marker3 from '../../svg/3.svg';
import marker4 from '../../svg/4.svg';
import marker5 from '../../svg/5.svg';
import marker6 from '../../svg/6.svg';
import marker7 from '../../svg/7.svg';
import marker8 from '../../svg/8.svg';
import marker9 from '../../svg/9.svg';
import marker10 from '../../svg/10.svg';
import marker11 from '../../svg/11.svg';
import marker12 from '../../svg/12.svg';
import marker13 from '../../svg/13.svg';
import marker14 from '../../svg/14.svg';
import marker15 from '../../svg/15.svg';
import marker16 from '../../svg/16.svg';
import marker17 from '../../svg/17.svg';
import marker18 from '../../svg/18.svg';
import marker19 from '../../svg/19.svg';
import marker20 from '../../svg/20.svg';
import { useParams, useSearchParams } from 'react-router-dom';
import { GoogleMap, DirectionsRenderer, MarkerF } from '@react-google-maps/api';
import { apiUrl } from '../../app/api/services/apiUrl';
import { helper } from '../../app/api/api.helper';

function PublicMap() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTransaction, setSelectedTransactions] = useState<any>([]);
  const [center, setCenter] = useState<any>({ lat: 0, lng: 0 });
  const transactions = JSON.parse(searchParams.get('transactions') || '');
  //   const { origin, destination, waypoints, wayPoint } = props;

  // console.log('center::::', center);
  // const center1: any = { lat: 21.7645, lng: 72.1519 };
  // const origin: any = { lat: 21.7645, lng: 72.1519 };
  // const destination: any = { lat: 23.0225, lng: 72.5714 };
  // const waypoints: any = () => {
  //   if (selectedTransaction && selectedTransaction.length >= 2) {
  //     let tempLocation = selectedTransaction.slice(1);
  //     let locationWayPoint =
  //       tempLocation &&
  //       tempLocation.map((location?: any) => {
  //         const latLng = location?.seller?.location?.split(',');
  //         const origin = {
  //           lat: parseFloat(latLng[0]),
  //           lng: parseFloat(latLng[1]),
  //         };
  //         return {
  //           location: new google.maps.LatLng(origin.lat, origin.lng),
  //         };
  //       });
  //     return locationWayPoint;
  //   }
  // };
  // const wayPoint: any = [{ lat: 22.5688, lng: 71.8019 }];

  // const transactionJourney = useMemo(() => {
  //   const data = selectedTransaction.map((u: any) => {
  //     return u.seller.category;
  //   });
  //   return data;
  // }, [selectedTransaction]);

  const selectedTransactionId = useMemo(() => {
    const trans_id = selectedTransaction.map((u: any) => {
      return u.id;
    });
    return trans_id;
  }, [selectedTransaction]);

  const firstTransaction = _.find(
    selectedTransaction,
    (trans) => trans.id === selectedTransactionId[0]
  );
  const lastTransaction = _.find(
    selectedTransaction,
    (trans) =>
      trans.id === selectedTransactionId[selectedTransactionId.length - 1]
  );

  const originLatLng =
    firstTransaction && firstTransaction?.seller?.location?.split(',');
  const origin = firstTransaction
    ? {
        lat: parseFloat(originLatLng[0]),
        lng: parseFloat(originLatLng[1]),
      }
    : { lat: 0, lng: 0 };

  const destinationLatLng =
    lastTransaction && lastTransaction?.buyer?.location?.split(',');
  const destination = lastTransaction
    ? {
        lat: parseFloat(destinationLatLng[0]),
        lng: parseFloat(destinationLatLng[1]),
      }
    : { lat: 0, lng: 0 };

  const getwayPoint = () => {
    if (selectedTransaction && selectedTransaction.length >= 2) {
      let tempLocation = selectedTransaction.slice(1);
      let locationWayPoint =
        tempLocation &&
        tempLocation.map((location?: any) => {
          const latLng = location?.seller?.location?.split(',');
          const origin = {
            lat: parseFloat(latLng[0]),
            lng: parseFloat(latLng[1]),
          };
          return {
            location: new google.maps.LatLng(origin.lat, origin.lng),
          };
        });
      return locationWayPoint;
    }
  };

  const wayPoints = getwayPoint();
  const wayPoint = useMemo(() => {
    if (selectedTransaction && selectedTransaction.length >= 2) {
      let tempLocation = selectedTransaction.slice(1);

      let locationWayPoint =
        tempLocation.length > 0 &&
        tempLocation?.map((location?: any) => {
          const latLng = location?.seller?.location?.split(',');
          const origin = {
            lat: parseFloat(latLng[0]),
            lng: parseFloat(latLng[1]),
          };
          return origin;
        });

      return locationWayPoint;
    } else {
      return [];
    }
  }, [selectedTransaction]);

  const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    scaleControl: false,
    scrollwheel: false,
  };

  const getDataTrasactionById = async () => {
    let path = apiUrl.getTransactionsListByIds;

    const param = {
      ids: JSON.stringify(transactions),
    };
    const fr = await helper.getData(path, param);
    const res = await fr.response;
    if (res.success) {
      const trasactionData = res.data;
      setSelectedTransactions(trasactionData);
      setCenter({
        lat: +trasactionData[0].seller.location.split(',')[0],
        lng: +trasactionData[0].seller.location.split(',')[1],
      });
      // console.log('transactionData:::::', trasactionData);
    } else {
    }
  };

  const [directionsResponse, setDirectionsResponse] = useState<any>(null);
  useEffect(() => {
    wayPoints && calculateRoute();
  }, [wayPoints]);

  useEffect(() => {
    if (transactions) {
      getDataTrasactionById();
    }
  }, []);
  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();
    // console.log('Calling this function ::', wayPoints, wayPoint);
    const result = await directionsService.route({
      origin: origin,
      destination: destination,
      waypoints: wayPoints ? wayPoints : [],
      travelMode: google.maps.TravelMode.DRIVING,
    });
    // console.log('result::::', result);
    setDirectionsResponse(result);
  }

  const markerIcon = (index: number) => {
    if (index === 1) {
      return marker1;
    } else if (index === 2) {
      return marker2;
    } else if (index === 3) {
      return marker3;
    } else if (index === 4) {
      return marker4;
    } else if (index === 5) {
      return marker5;
    } else if (index === 6) {
      return marker6;
    } else if (index === 7) {
      return marker7;
    } else if (index === 8) {
      return marker8;
    } else if (index === 9) {
      return marker9;
    } else if (index === 10) {
      return marker10;
    } else if (index === 11) {
      return marker11;
    } else if (index === 12) {
      return marker12;
    } else if (index === 13) {
      return marker13;
    } else if (index === 14) {
      return marker14;
    } else if (index === 15) {
      return marker15;
    } else if (index === 16) {
      return marker16;
    } else if (index === 17) {
      return marker17;
    } else if (index === 18) {
      return marker18;
    } else if (index === 19) {
      return marker19;
    } else if (index === 20) {
      return marker20;
    } else {
      return marker1;
    }
  };
  // console.log('directionsResponse::', wayPoint);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          visibility: '-moz-initial',
        }}
      >
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '70vh' }}
          zoom={5}
          options={defaultMapOptions}
          center={center}
          id='map'
        >
          {/* {directionsResponse && ( */}
          <>
            <DirectionsRenderer
              options={{
                suppressMarkers: true,
              }}
              directions={directionsResponse}
            />
          </>
          {/* )} */}

          <MarkerF
            position={origin}
            icon={{
              url: markerIcon(1),
              anchor: new google.maps.Point(10, 30),
            }}
            // icon="../../svg/5.svg"
          />
          {/* <OriginIcon /> */}
          {/* <MarkerF
            position={destination}
            icon={{
              url: markerIcon(wayPoint.length + 2),
              anchor: new google.maps.Point(10, 30),
            }}
          /> */}
          {wayPoint &&
            wayPoint.length > 0 &&
            wayPoint.map((u: any, i: number) => {
              return (
                <MarkerF
                  key={i}
                  position={u}
                  icon={{
                    url: markerIcon(i + 2),
                    anchor: new google.maps.Point(10, 30),
                  }}
                />
              );
            })}
        </GoogleMap>
      </div>
    </>
  );
}
export default PublicMap;
