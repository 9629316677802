import _ from 'lodash';
import moment from 'moment';


// eslint-disable-next-line no-restricted-properties
const getNow = (_arg: string) => moment(_.now()).toDate();
const memoizedGetNow = _.memoize(getNow);
// eslint-disable-next-line no-restricted-properties
export const getHourlyMemoizedNow = () => memoizedGetNow(moment(_.now()).format('DD.MM.YYYY HH'));
export const getMemorizedEndOfMonth = () => moment(getHourlyMemoizedNow()).endOf('month').toDate();
export const dateFormatWithTime = 'DD/MM/YYYY hh:mm A';
export const datePickerDateFormat = 'dd/MM/yyyy';

