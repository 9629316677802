import React, { useState, useEffect, HTMLProps } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import {
  commanLabel,
  routesConstant,
} from '../../../constants/comman.constant';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { validationMessage } from '../../../constants/validation.message.constant';
import {
  useExportActivityMutation,
  useExportActivityPdfMutation,
  useExportSocialWelfareMutation,
  useExportcsrPdfMutation,
} from '../../../app/api/services/socialWelfare';
import { imagePath } from '../../../constants/comman.constant';
import ExportPopup from '../socialWelfare/exportPopup';
// import ViewActivity from './viewActivity';

const ActivityList = () => {
  const { t: translate } = useTranslation();
  const columnHelper = createColumnHelper<any>();
  const navigate = useNavigate();

  const [exportPopup, setExportPopup] = useState(false);
  const [viewActivityPopup, setViewActivityPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [exportwithBlur, setExportwithBlur] = useState(true);
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [activityDateRange, setActivityDateRange] = useState([null, null]);
  const [entryDateRange, setEntryDateRange] = useState([null, null]);
  const [activityStartDate, activityEndDate] = activityDateRange;

  const [exportSocialWelfare] = useExportSocialWelfareMutation();
  const [rowSelection, setRowSelection] = React.useState({});
  const [gender, setGender] = useState('');
  const [exportActivityPdf] = useExportcsrPdfMutation();
  const [searchValue, setSearchValue] = useState('');
  const [reclaimerCategoryId, setReclaimerCategoryId] = useState('');
  const [reclaimerCategoryData, setReclaimerCategoryData] = useState([]);
  const [eventCategoryData, setEventCategoryData] = useState([]);
  const [activityCategoryData, setActivityCategoryData] = useState([]);
  const [eventCategoryId, setEventCategoryId] = useState('');
  const [activityCategoryId, setActivityCategoryId] = useState('');

  const [sorting, setSorting] = React.useState<SortingState>([]);

  function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type='checkbox'
        ref={ref}
        className={className + ' cursor-pointer'}
        {...rest}
      />
    );
  }

  const columns = [
    columnHelper.accessor('-', {
      id: 'select',
      enableSorting: false,
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className='px-1'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor((row) => `${row?.activity_id}`, {
      id: `${translate('CSR_MODULE.TITLES.ACTIVITY_ID')}`,
      cell: (props: any) => {
        return (
          <>
            {props?.row?.original?.is_bulk_upload && (
              <div
                className='bulk-icons me-2'
                data-bs-toggle='tooltip'
                data-bs-placement='bottom'
                // title={props?.row?.original?.bulk_upload_id}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 24 24'
                >
                  <g
                    id='collection-upload-svgrepo-com'
                    transform='translate(-2 -3)'
                  >
                    <path
                      id='Path_8273'
                      data-name='Path 8273'
                      d='M7.5,16.485V11.759H9.395V14.59H19.5V11.759h1.895v4.726Z'
                      transform='translate(1.447 2.305)'
                      fill='#18a675'
                    />
                    <path
                      id='Path_8274'
                      data-name='Path 8274'
                      d='M11.27,11.2l1.591-1.591v3.974h1.895V9.609L16.346,11.2l1.34-1.34L13.808,5.982,9.93,9.861Z'
                      transform='translate(2.087 0.785)'
                      fill='#18a675'
                    />
                    <path
                      id='Path_8275'
                      data-name='Path 8275'
                      d='M5,3H25.211V23.211H5ZM6.895,4.895H23.316V21.316H6.895Z'
                      transform='translate(0.789 0)'
                      fill='#18a675'
                      fill-rule='evenodd'
                    />
                    <path
                      id='Path_8276'
                      data-name='Path 8276'
                      d='M2,6V26.211H22.211V24.316H3.895V6Z'
                      transform='translate(0 0.789)'
                      fill='#18a675'
                    />
                  </g>
                </svg>
              </div>
            )}
            {props?.row?.original?.activity_id}
          </>
        );
      },
    }),
    columnHelper.accessor(
      (row) => `${row?.other_csr_event && row?.other_csr_event?.name}`,
      {
        id: `${translate('CSR_MODULE.TITLES.EVENT_NAME')}`,
      }
    ),
    columnHelper.accessor(
      (row) => `${row?.other_csr_event && row?.other_csr_event?.cst_event_id}`,
      {
        id: `${translate('CSR_MODULE.TITLES.EVENT_ID')}`,
      }
    ),
    // columnHelper.accessor(
    //   (row) =>
    //     moment(`${row?.entry_date ? row?.entry_date : '-'}`).format(
    //       'DD/MM/YYYY'
    //     ),
    //   {
    //     id: `${translate('CSR_MODULE.TITLES.DATE_OF_ENTRY')}`,
    //   }
    // ),
    columnHelper.accessor(
      (row) =>
        moment(`${row?.activity_date ? row?.activity_date : '-'}`).format(
          'DD/MM/YYYY'
        ),
      {
        id: `${translate('CSR_MODULE.TITLES.DATE_OF_ACTIVITY')}`,
        enableSorting: false,
      }
    ),
    columnHelper.accessor(
      (row) =>
        `${row?.csr_reclaimer_profile && row?.csr_reclaimer_profile?.name}`,
      {
        id: `${translate('CSR_MODULE.TITLES.BENEFICIARY_NAME')}`,
      }
    ),

    columnHelper.accessor(
      (row) =>
        `${
          (row?.csr_reclaimer_profile &&
            row?.csr_reclaimer_profile?.gender == 'male') ||
          row?.csr_reclaimer_profile?.gender == 'Male'
            ? 'M'
            : (row?.csr_reclaimer_profile &&
                row?.csr_reclaimer_profile?.gender == 'female') ||
              row?.csr_reclaimer_profile?.gender == 'Female'
            ? 'F'
            : '-'
        }`,
      {
        id: `${translate('CSR_MODULE.TITLES.GENDER')}`,
      }
    ),
    // columnHelper.accessor('Material Provided', {
    //   cell: (props: any) => {
    //     let id = props?.row?.original?.id;
    //     return (
    //       <React.Fragment>
    //         {props?.row?.original?.csr_material
    //           ?.reduce((final = '', obj: any) => {
    //             return final + obj?.name + ', ';
    //           }, '')
    //           .slice(0, -2) || '-'}
    //       </React.Fragment>
    //     );
    //   },
    // }),
    columnHelper.accessor(
      (row: any) =>
        `${row?.other_csr_event && row?.other_csr_event?.event_category?.name}`,
      {
        id: `${translate('CSR_MODULE.TITLES.EVENT_CATEGORY')}`,
      }
    ),
    columnHelper.accessor(
      (row: any) => `${row?.csr_sub_category && row?.csr_sub_category?.name}`,
      {
        id: `${translate('CSR_MODULE.TITLES.ACTIVITY_CATEGORY')}`,
      }
    ),

    // columnHelper.accessor('-', {
    //   header: () => `${translate('CSR_MODULE.TITLES.LINKED')}`,
    //   enableSorting: false,
    //   cell: (props: any) => {
    //     let isGenerated = props?.row?.original?.isLinked;
    //     let transactionId: string =
    //       props?.row?.original?.linked_transaction_detail?.transaction_id;
    //     let id =
    //       props?.row?.original?.linked_transaction_detail
    //         ?.link_transaction_certificate_detail[0]?.pc_id;
    //     return (
    //       <React.Fragment>
    //         <div className='icon-status'>
    //           {isGenerated ? (
    //             <div className='icon-view icon' title={transactionId}>
    //               <a
    //                 onClick={() =>
    //                   window.open(
    //                     `/admin/transaction/plasticCreditCertification/${window.btoa(
    //                       transactionId
    //                     )}&${window.btoa(id)}`
    //                   )
    //                 }
    //                 target='_blank'
    //                 title={transactionId}
    //               >
    //                 <i className='fa fa-check' aria-hidden='true'></i>
    //               </a>
    //             </div>
    //           ) : (
    //             <div className='icon-view icon'>
    //               <Link to={'#'} title='Awaiting'>
    //                 {' '}
    //                 <img
    //                   src={`${process.env.PUBLIC_URL}/assets/images/pending-status.png`}
    //                   alt='#'
    //                 />
    //               </Link>
    //             </div>
    //           )}
    //         </div>
    //       </React.Fragment>
    //     );
    //   },
    // }),

    // columnHelper.accessor('-', {
    //   header: () => `${translate('CSR_MODULE.TITLES.ACTIVITY_PHOTO')}`,
    //   enableSorting: false,
    //   cell: (props: any) => {
    //     let id = props?.row?.original?.id;
    //     return (
    //       <React.Fragment>
    //         {props?.row?.original?.activity_photo && (
    //           <div className='icon-action'>
    //             <img
    //               style={{ cursor: 'pointer' }}
    //               src={`${process.env.PUBLIC_URL}/assets/images/eye-icon-green.svg`}
    //               alt='#'
    //               onClick={() =>
    //                 window.open(props?.row?.original?.activity_photo)
    //               }
    //             />
    //           </div>
    //         )}
    //       </React.Fragment>
    //     );
    //   },
    // }),
  ];

  const getReclaimerCategoryDetails = async () => {
    let path = apiUrl.getReclaimerCategory;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setReclaimerCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const getEventCategoryDetails = async () => {
    let path = apiUrl.getEventCategory;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setEventCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const getActivityCategoryDetails = async (eventCategoryId: string) => {
    let path = apiUrl.getActivityCategory + eventCategoryId;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setActivityCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const getData = async (reset: boolean) => {
    setIsLoading(true);
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      // is_linked: false,
      entry_start_date: reset
        ? ''
        : entryDateRange[0] === null
        ? ''
        : getStartDateFilter(entryDateRange[0]),
      entry_end_date: reset
        ? ''
        : entryDateRange[1] === null
        ? ''
        : getEndDateFilter(entryDateRange[1]),
      activity_start_date: reset
        ? ''
        : activityDateRange[0] === null
        ? ''
        : getStartDateFilter(activityDateRange[0]),
      activity_end_date: reset
        ? ''
        : activityDateRange[1] === null
        ? ''
        : getEndDateFilter(activityDateRange[1]),
      search_text: reset ? '' : searchValue,
      gender: reset ? '' : gender,
      // material_id: materialId,
      reclaimer_category_id: reset ? '' : reclaimerCategoryId,
      activity_category_id: reset ? '' : eventCategoryId,
      activity_sub_category_id: reset ? '' : activityCategoryId,
    };
    let path = apiUrl.getCSRActivityList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setActivityData(res.data.data);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  // const getCSRMaterials = async () => {
  //   let path = apiUrl.getCSRMaterials;
  //   const fr: any = await helper.getData(path);
  //   const res = await fr.response;
  //   if (res.success) {
  //     let temArray = res.data;
  //     setMaterialList(temArray);
  //   } else {
  //     toast.error(res.message);
  //   }
  // };

  // const getCollectionCenterDetails = async () => {
  //   let path = apiUrl.getCollectionCenter;
  //   const fr: any = await helper.getData(path);
  //   const res = await fr.response;
  //   if (res.success) {
  //     setCollectionCenterData(res.data);
  //   } else {
  //     toast.error(res.message);
  //   }
  // };

  useEffect(() => {
    getData(false);
    // getCollectionCenterDetails();
    getReclaimerCategoryDetails();
    // getCSRMaterials();
    getEventCategoryDetails();
  }, [page, pageLimit]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: activityData,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const performSearch = (e: any) => {
    e.preventDefault();
    getData(false);
  };

  const handeleReset = () => {
    setPage(1);
    setEntryDateRange([null, null]);
    setActivityDateRange([null, null]);
    // setCollectionCenterId('');
    setSearchValue('');
    setGender('');
    getData(true);
    setReclaimerCategoryId('');
    setEventCategoryId('');
    setActivityCategoryId('');
  };

  const getExportedExcel = async () => {
    const rowKeys = Object.keys(rowSelection);
    const actKeys: any = [];
    rowKeys.forEach((aa: string) => {
      if (rowSelection[aa as keyof typeof rowSelection]) {
        const actRow: any = activityData[aa as keyof typeof rowSelection];
        actKeys.push(actRow?.id);
      }
    });
    const res = await exportSocialWelfare({
      ids: actKeys,
    }).unwrap();
    if (res.success) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
        res.data?.file;
      a.download = res.data.name;
      a.click();
    }
  };
  const getExportedPdf = async () => {
    const rowKeys = Object.keys(rowSelection);
    const actKeys: any = [];
    rowKeys.forEach((aa: string) => {
      if (rowSelection[aa as keyof typeof rowSelection]) {
        const actRow: any = activityData[aa as keyof typeof rowSelection];
        actKeys.push(actRow?.id);
      }
    });
    const res = await exportActivityPdf({
      transactionIds: actKeys,
      show_image: exportwithBlur,
    }).unwrap();
    if (res.success) {
      let path = apiUrl.prepareFile + res.data.certificateUrl;
      const fr = await helper.getData(path);
      const resData = await fr.response;
      if (resData.data) {
        window.open(
          process.env.REACT_APP_BASE_URL_API +
            apiUrl.downloadFile +
            res.data.certificateUrl
        );
        toast.success('Certificate Downloaded');
      } else {
        toast.error(res.message);
      }
    }
  };

  return (
    <>
      {exportPopup && (
        <ExportPopup
          title={`${translate('CSR_MODULE.TITLES.EXPORT')}`}
          handleCancel={() => setExportPopup(false)}
          cancelText='Cancel'
          submitText='OK'
          hideCancelButton={true}
          handleSubmit={(e: any, addBlur: any) => {
            setExportPopup(false);
            if (addBlur === 'false') {
              setExportwithBlur(false);
            } else {
              setExportwithBlur(true);
            }
            if (e === 'pdf') {
              getExportedPdf();
            }
            if (e === 'excel') {
              getExportedExcel();
            }
          }}
        />
      )}

      {/* {viewActivityPopup && (
        <ViewActivity
          title={`${translate('CSR_MODULE.TITLES.RECLAIMER_ACTIVITY_DETAILS')}`}
          handleCancel={() => setViewActivityPopup(false)}
          cancelText='Cancel'
          submitText='OK'
          hideCancelButton={true}
          data={selectedRow}
          handleSubmit={() => {
            setViewActivityPopup(false);
          }}
        />
      )} */}

      <div
        className='tab-pane fade show active'
        role='tabpanel'
        aria-labelledby='pc-request1-tab'
      >
        <div className='listing-transaction'>
          <form className=''>
            <div className='top-filters'>
              <div className='date-item-picker item-in'>
                <div className='date-picker-main'>
                  <div className='react-datepicker-wrapper'>
                    <div className='react-datepicker__input-container '>
                      <DatePicker
                        selectsRange={true}
                        startDate={activityStartDate}
                        endDate={activityEndDate}
                        value={activityStartDate ? activityStartDate : ''}
                        className='form-control'
                        placeholderText={`${translate(
                          'CSR_MODULE.TITLES.SELECT_ACTIVITY_DATE'
                        )}`}
                        dateFormat={commanLabel.dateFormat}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        onChange={(update: any) => {
                          setActivityDateRange(update);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='date-item-picker item-in'>
                <div className='date-picker-main'>
                  <div className='react-datepicker-wrapper'>
                    <div className='react-datepicker__input-container '>
                      <DatePicker
                        selectsRange={true}
                        startDate={entryStartDate}
                        endDate={entryEndDate}
                        value={entryStartDate ? entryStartDate : ''}
                        className='form-control'
                        placeholderText={`${translate(
                          'CSR_MODULE.TITLES.SELECT_ENTRY_DATE'
                        )}`}
                        dateFormat={commanLabel.dateFormat}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        onChange={(update: any) => {
                          setEntryDateRange(update);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className='type-select item-in'>
                <select
                  id=''
                  name='type_of_material'
                  className='select-layout'
                  value={reclaimerCategoryId}
                  onChange={(e: any) => setReclaimerCategoryId(e.target.value)}
                >
                  <option value=''>
                    {translate('CSR_MODULE.TITLES.USER_CATEGORY')}
                  </option>
                  {reclaimerCategoryData &&
                    reclaimerCategoryData.length > 0 &&
                    reclaimerCategoryData.map(
                      (userCategory: any, index: number) => {
                        return (
                          <option value={userCategory.id}>
                            {userCategory?.name}
                          </option>
                        );
                      }
                    )}
                </select>
              </div>
              <div className='type-select item-in'>
                <select
                  id=''
                  name='type_of_material'
                  className='select-layout'
                  value={eventCategoryId}
                  onChange={async (e: any) => {
                    setEventCategoryId(e.target.value);
                    await getActivityCategoryDetails(e.target.value);
                  }}
                >
                  <option value=''>
                    {translate('CSR_MODULE.TITLES.EVENT_CATEGORY')}
                  </option>
                  {eventCategoryData &&
                    eventCategoryData.length > 0 &&
                    eventCategoryData.map(
                      (eventCategory: any, index: number) => {
                        return (
                          <option value={eventCategory.id}>
                            {eventCategory?.name}
                          </option>
                        );
                      }
                    )}
                </select>
              </div>
              <div className='type-select item-in'>
                <select
                  id=''
                  name='type_of_material'
                  className='select-layout'
                  value={activityCategoryId}
                  onChange={(e: any) => setActivityCategoryId(e.target.value)}
                >
                  <option value=''>
                    {translate('CSR_MODULE.TITLES.ACTIVITY_CATEGORY')}
                  </option>
                  {activityCategoryData &&
                    activityCategoryData.length > 0 &&
                    activityCategoryData.map(
                      (activtyCategory: any, index: number) => {
                        return (
                          <option value={activtyCategory.id}>
                            {activtyCategory?.name}
                          </option>
                        );
                      }
                    )}
                </select>
              </div>
              {/* <div className='type-select item-in'>
                <select
                  id=''
                  name='type_of_material'
                  className='select-layout'
                  value={collectionCenterId}
                  onChange={(e: any) => setCollectionCenterId(e.target.value)}
                >
                  <option value=''>
                    {translate('CSR_MODULE.TITLES.COLLECTION_CENTER')}
                  </option>
                  {collectionCenterData &&
                    collectionCenterData.length > 0 &&
                    collectionCenterData.map(
                      (collectionCenter: any, index: number) => {
                        return (
                          <option value={collectionCenter.id}>
                            {collectionCenter?.name}
                          </option>
                        );
                      }
                    )}
                </select>
              </div> */}

              {/* <div className='type-select item-in'>
                <select
                  id=''
                  name='type_of_material'
                  className='select-layout'
                  value={gender}
                  onChange={(e: any) => setGender(e.target.value)}
                >
                  <option value=''>
                    {translate('CSR_MODULE.TITLES.GENDER')}
                  </option>
                  <option value='male'>
                    {translate('CSR_MODULE.TITLES.MALE')}
                  </option>
                  <option value='female'>
                    {translate('CSR_MODULE.TITLES.FEMALE')}
                  </option>
                </select>
              </div> */}
              <div className='type-select item-in'>
                <div className='item input-min-width'>
                  <div className='input-search-item form-group'>
                    <label className='searchicon'>
                      <i className='fa fa-search'></i>
                    </label>
                    <input
                      className='form-control'
                      id='searchicon'
                      placeholder={`${translate('CSR_MODULE.TITLES.SEARCH')}`}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <div className='info-icons text-end'>
                      <a
                        href='#'
                        data-bs-toggle='tooltip'
                        data-bs-placement='bottom'
                        title={`${translate('CSR_MODULE.TITLES.SEARCH_TITLE')}`}
                      >
                        <i className='fa fa-info-circle' aria-hidden='true'></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='search-button item-in'>
                <Button
                  role='button'
                  onClick={performSearch}
                  title={`${translate('CSR_MODULE.TITLES.SEARCH')}`}
                  className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                >
                  <i className='fa fa-search' aria-hidden='true'></i>
                </Button>
                <Button
                  role='button'
                  onClick={handeleReset}
                  title={`${translate('CSR_MODULE.TITLES.RESET')}`}
                  type='reset'
                  className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                >
                  <i className='fa fa-refresh' aria-hidden='true'></i>
                </Button>
              </div>
            </div>
          </form>
          <div className='csr-btn-area mt-4 mb-4 row me-3 ms-3'>
            <div className='col-md-4 d-flex jusitfy-content-center'></div>
            <div className='col-md-8 d-flex justify-content-end mt-5 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0'>
              <div
                className='form-submit-buttons auto-width-button'
                onClick={() => {
                  setExportPopup(true);
                }}
              >
                <a className='export-btn'>
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='21'
                    height='15.273'
                    viewBox='0 0 21 15.273'
                  >
                    <path
                      id='Path_export'
                      data-name='Path / export'
                      d='M22,11.636,18.182,7.818v2.864H9.591v1.909h8.591v2.864M1,17.364V5.909A1.909,1.909,0,0,1,2.909,4H14.364a1.909,1.909,0,0,1,1.909,1.909V8.773H14.364V5.909H2.909V17.364H14.364V14.5h1.909v2.864a1.909,1.909,0,0,1-1.909,1.909H2.909A1.909,1.909,0,0,1,1,17.364Z'
                      transform='translate(-1 -4)'
                    />
                  </svg>
                  {translate('CSR_MODULE.TITLES.EXPORT')}
                </a>
              </div>
            </div>
          </div>
          <div className='listing-content'>
            <div className='table-list'>
              <table>
                <thead>
                  {table.getHeaderGroups().map((headerGroup: any) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header: any) => {
                        return (
                          <th key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>

                {activityData?.length > 0 ? (
                  <tbody>
                    {table.getRowModel().rows.map((row: any) => {
                      return (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell: any) => {
                            return (
                              <td
                                key={cell.id}
                                style={
                                  [
                                    'Event Name',
                                    'Event ID',
                                    'Reclaimer Name',
                                    'Reclaimer ID',
                                  ].includes(cell.column.id)
                                    ? { cursor: 'pointer', padding: '18px 5px' }
                                    : { padding: '18px 5px', cursor: 'pointer' }
                                }
                                onClick={() => {
                                  setSelectedRow([row.original]);
                                  if (
                                    cell.column.id === 'Event Name' ||
                                    cell.column.id === 'Event ID'
                                  ) {
                                    navigate(
                                      `${routesConstant.viewCSREvent}${row.original.csr_event_id}`
                                    );
                                  } else if (
                                    cell.column.id === 'Reclaimer Name' ||
                                    cell.column.id === 'Reclaimer ID'
                                  ) {
                                    navigate(
                                      `${routesConstant.viewCSRReclaimer}${row.original.csr_reclaimer_id}`
                                    );
                                  } else if (cell.column.id === 'Activity ID') {
                                    setViewActivityPopup(true);
                                  }
                                }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <>
                    <tbody>
                      <tr>
                        <td colSpan={12}>
                          {isLoading && (
                            <h3 className='center-message-item'>
                              {translate('CSR_MODULE.TITLES.LOADING')}
                            </h3>
                          )}
                          {activityData?.length === 0 && !isLoading && (
                            <h3 className='center-message-item'>
                              {validationMessage.noRecordFound}
                            </h3>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </>
                )}
              </table>
            </div>
            {activityData?.length > 0 && pageCount > 1 && (
              <div className='items-center pagination-main gap-2 text-center'>
                <PaginationControl
                  page={page}
                  between={4}
                  total={totalCount}
                  limit={pageLimit}
                  changePage={(page) => {
                    setPage(page);
                  }}
                  ellipsis={2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityList;
