export const showLoader = () => {
    console.log("show loader");
    var loader= parseInt(localStorage.getItem("loader")?? '0');             
    localStorage.setItem("loader",`${loader+1}`);
    window.dispatchEvent(new Event("storage"));
}
export const hideLoader = () => {
    console.log("hide loader");
    var loader= parseInt(localStorage.getItem("loader")?? '1');             
    localStorage.setItem("loader",`${loader-1}`);
    window.dispatchEvent(new Event("storage"));
}