export const category = {
  RECLAIMER: 'Reclaimer',
  COLLECTOR: 'Collector',
  SUBAGGREGATOR: 'Sub-Aggregator',
  AGGREGATOR: 'Aggregator',
  PROCESSOR: 'Processor',
  RECYCLER: 'Recycler',
  MANUFACTURER: 'Manufacturer',
  FIRSTLEVELCONSUMER: '1st Level Consumer',
  SECONDLEVELCONSUMER: '2nd Level Consumer',
  VALUE1: 'Value 1',
  VALUE2: 'Value 2',
  PLASTIC: 'Plastic',
};
