import React from "react";
import { Link } from "react-router-dom";
export const Page404 = () => {

    return (
        <div className="body-content-space for-zero-for">
            <div className="container">
                <div className="image-grid-data">
                    <div className="row">
                        <div className="col-md-6 left-content-item">
                            <div className="left-data">
                                <h3 className="title-mid">Sorry, Page not found!</h3>
                                <p className="description">
                                    The page you requested could not be found
                                </p>
                                <div className="button-items">
                                    <Link to="/" className="btn btn-primary">Go to Homepage</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 right-img-item">
                            <div className="right-image">
                                <img src={`${process.env.PUBLIC_URL}/assets//images/404-page.svg`} alt="404-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Page404;