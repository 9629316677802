import {
  commonInput,
  emailPatern,
  mobilePatern,
  commanValidation,
  aadhaarNumber,
} from '../../../constants/comman.validation.constant';
import {
  validationMessage,
  RequiredError,
} from '../../../constants/validation.message.constant';
import { commanLabel } from '../../../constants/comman.constant';

export const createActivityValidator = (
  errors: any,
  eventInfo: any,
  reclaimerInfo: any,
  activityInfo: any
) => {
  let tempErrors = { ...errors };

  if (eventInfo.eventName) {
    if (!commonInput.test(eventInfo.eventName)) {
      tempErrors = {
        ...tempErrors,
        eventName: validationMessage.invalidEventName,
      };
    } else {
      tempErrors = { ...tempErrors, eventName: '' };
    }
  } else tempErrors = { ...tempErrors, eventName: RequiredError.eventName };

  if (eventInfo.poc) {
    if (!commonInput.test(eventInfo.poc)) {
      tempErrors = {
        ...tempErrors,
        poc: validationMessage.invalidPOCName,
      };
    } else {
      tempErrors = { ...tempErrors, poc: '' };
    }
  } else tempErrors = { ...tempErrors, poc: RequiredError.POCName };

  if (!eventInfo.isPOCDataProvider) {
    if (eventInfo.dataProvider) {
      if (!commonInput.test(eventInfo.dataProvider)) {
        tempErrors = {
          ...tempErrors,
          dataProvider: validationMessage.invalidDataProviderName,
        };
      } else {
        tempErrors = { ...tempErrors, dataProvider: '' };
      }
    } else
      tempErrors = {
        ...tempErrors,
        dataProvider: RequiredError.dataProviderName,
      };
  } else {
    tempErrors = { ...tempErrors, dataProvider: '' };
  }

  if (eventInfo.organiser) {
    if (!commonInput.test(eventInfo.organiser)) {
      tempErrors = {
        ...tempErrors,
        organiser: validationMessage.invalidOrganiseName,
      };
    } else {
      tempErrors = { ...tempErrors, organiser: '' };
    }
  } else
    tempErrors = {
      ...tempErrors,
      organiser: RequiredError.organiseName,
    };

  if (!eventInfo.eventDate) {
    tempErrors = {
      ...tempErrors,
      eventDate: RequiredError.eventDate,
    };
  }

  if (eventInfo.location) {
    if (!commonInput.test(eventInfo.location)) {
      tempErrors = {
        ...tempErrors,
        location: validationMessage.invalidLocationName,
      };
    } else {
      tempErrors = { ...tempErrors, location: '' };
    }
  } else
    tempErrors = {
      ...tempErrors,
      location: RequiredError.locationName,
    };

  if (reclaimerInfo.reclaimerName) {
    if (!commonInput.test(reclaimerInfo.reclaimerName)) {
      tempErrors = {
        ...tempErrors,
        reclaimerName: validationMessage.invalidReclaimerName,
      };
    } else {
      tempErrors = { ...tempErrors, reclaimerName: '' };
    }
  } else
    tempErrors = {
      ...tempErrors,
      reclaimerName: RequiredError.reclaimerName,
    };

  if (reclaimerInfo.gender === '') {
    tempErrors = { ...tempErrors, gender: RequiredError.gender };
  } else tempErrors = { ...tempErrors, gender: '' };

  if (reclaimerInfo.childrens === '') {
    tempErrors = {
      ...tempErrors,
      childrens: RequiredError.childrens,
    };
  } else tempErrors = { ...tempErrors, childrens: '' };

  // if (reclaimerInfo.aadhaarPhoto === '') {
  //   tempErrors = {
  //     ...tempErrors,
  //     aadhaarPhoto: RequiredError.aadhaarPhoto,
  //   };
  // } else tempErrors = { ...tempErrors, aadhaarPhoto: '' };

  // if (activityInfo.activity_photo === '') {
  //   tempErrors = {
  //     ...tempErrors,
  //     activityPhoto: RequiredError.activityPhoto,
  //   };
  // } else tempErrors = { ...tempErrors, activityPhoto: '' };
  if (reclaimerInfo.aadhaarNo) {
    if (!aadhaarNumber.test(reclaimerInfo.aadhaarNo)) {
      tempErrors = {
        ...tempErrors,
        aadhaarNo: validationMessage.invalidAadhaarNumber,
      };
    } else {
      tempErrors = { ...tempErrors, aadhaarNo: '' };
    }
  } else tempErrors = { ...tempErrors, aadhaarNo: RequiredError.aadhaarNumber };

  if (activityInfo.isOtherMaterial) {
    if (activityInfo.otherMaterial) {
      if (!commonInput.test(activityInfo.otherMaterial)) {
        tempErrors = {
          ...tempErrors,
          otherMaterial: validationMessage.invalidMaterialName,
        };
      } else {
        tempErrors = { ...tempErrors, otherMaterial: '' };
      }
    } else
      tempErrors = {
        ...tempErrors,
        otherMaterial: RequiredError.materialName,
      };
  } else {
    tempErrors = { ...tempErrors, otherMaterial: '' };
  }

  return tempErrors;
};
