import React from "react"

export const Aboutus = () => {
    return (
        <div className="body-content-space pb-0">
            <div className="container">
                <div className="title-description-content">
                    <h2 className="text-center title-main">About Us</h2>
                    <div className="description-details-data">
                        <p>
                            Plastic waste management has been a pressing issue for regulators and the larger community globally. Mankind has never faced such an issue with waste management as it is facing with plastics. The issue has been so ubiquitous earth-wide, that a global treaty had to be developed around it.
                        </p>
                        <p>
                            Around 1 million tonnes of plastic waste are generated globally every day and it is estimated that by 2050, 12 billion tonnes of plastic waste will be generated. Almost half of the plastic generated are meant for single-use only in form of packaging materials. Globally, only 15% of the total plastic waste is recycled of the total generated and that too varies on the type of plastic. Around 3 billion people lack access to waste management system. This accumulated unmanaged waste has not just created hill size piles in landfills but has also entered our marine environment.
                        </p>
                        <p>
                            The foremost and main challenge that is present is of collection of plastic scrap and subsequently the sorting of it. The present solutions have their own limitations and are not enough to achieve scalability.
                            With the commitment to provide a scalable and accountable solution for managing plastic waste, Gemini developed its ReMake Plastic Credit Program, designed to facilitate businesses and citizens to bid their parts in managing plastic waste with utmost convenience.
                        </p>
                        <p>
                            ReMake Plastic Credit Program is an ESG initiative backed by a technology-enabled financing mechanism to substantially accelerate the collection and segregation of waste in developing economies. It is a transparent and trustworthy marketplace for consumers and recyclers to directly fund waste collection, thereby enabling financing and social upliftment of frontline benefactors - Reclaimers.
                        </p>
                        <p>
                            Endeavoring to include a large array of diverse stakeholders, including financial investors, Gemini’s ReMake Plastic Credit Program, in the subsequent stages, would also be offering plastic credits converted to Fungible &amp; Non-fungible tokens (NFTs), and swappable crypto that can be traded on top exchanges. The platform is developable to subsequently facilitate the generation of credits for the collection &amp; recycling of all other kinds of waste materials.
                        </p>
                        <p>
                            We look forward to the joining of all the collectors and recyclers in this ReMake platform and partnering to take global waste management a leap ahead,  while also creating social value!
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}