import {
    commanValidation,
    commonInput,
    emailPatern,
    mobilePatern,
  } from "../../../../constants/comman.validation.constant";

export const ViewUserProfileDetailValidator = (errors: any, companyProfileInfo: any) => {
    let tempErrors = { ...errors };
    const getExpressionError = (value : any, expPattern: any, invalidErr: any, requiredErr: any) => {
        return value ? !new RegExp(expPattern).test(value) ? invalidErr : '' : requiredErr;
    }
    tempErrors = { 
        ...tempErrors, 
        rejection_reason: companyProfileInfo.rejection_reason ? '' : 'ERRORS.REQUIRED.REJECTION_REASON',
    }
    return tempErrors;
}
