import { AnyAction, combineReducers, Reducer } from "@reduxjs/toolkit";
import { plasticCreditApi } from "../api";
import { RootState } from "../types/store";
import authReducer from "./slices/auth.slice";
import SettingReducer from "./slices/setting.slice";

const appReducer = combineReducers({
  [plasticCreditApi.reducerPath]: plasticCreditApi.reducer,
  auth: authReducer,
  setting: SettingReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {  
  // when a logout action is dispatched it will reset redux state
  if (action.type === "logout/fulfilled") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
