export interface UserRoleProps {
    isRole: boolean;
}

// User Details fields
export const singUpUsers = {
    otp: "",
    password: "",
    confirm_password: "",
};

export const errorsingUpUser = {
    otp: "",
    password: "",
    confirm_password: "",
};

