import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { disableScrollForTypeNumber } from '../../../utils/utilFunctions';
import { validationMessage } from '../../../constants/validation.message.constant';
import { commanValidation } from '../../../constants/comman.validation.constant';

const QuantityComponent = (transactionData: any) => {
  const [useEntireQuantity, setUseEntireQuantity] = useState(true);

  useEffect(() => {
    if (useEntireQuantity) {
      let newArray = [...transactionData.selectedTransaction];
      newArray[transactionData.i].selectedQuantity =
        newArray[transactionData.i].remaining_quantity;
      transactionData.setSelectedTransaction(newArray);
    } else {
      let newArray = [...transactionData.selectedTransaction];
      newArray[transactionData.i].selectedQuantity = 0;
      transactionData.setSelectedTransaction(newArray);
    }
  }, [useEntireQuantity]);
  return (
    <div
      className='input-left  input-left-right'
      style={{ textAlign: 'right' }}
    >
      <div>
        <input
          className='form-check-input'
          type='checkbox'
          id='defaultCheck7'
          value={useEntireQuantity ? 1 : 0}
          checked={useEntireQuantity}
          onChange={(e: any) => {
            setUseEntireQuantity(!useEntireQuantity);
          }}
        />
        <label className='form-check-label' htmlFor='defaultCheck7'></label>
        <span> Use entire quantity. </span>
      </div>
      <Form.Control
        type='number'
        className='form-control'
        placeholder='Quantity to be used'
        style={{ textAlign: 'right' }}
        value={transactionData?.data?.selectedQuantity}
        name='quantityToBeUsed'
        // step={commanValidation.tonneMin}
        min={1}
        pattern={commanValidation.numberPattern}
        max={commanValidation.maxQty}
        onChange={(e) => {
          let newArray = [...transactionData.selectedTransaction];

          newArray[transactionData.i].selectedQuantity = e.target.value;

          transactionData.setSelectedTransaction(newArray);
        }}
        onFocus={disableScrollForTypeNumber}
        required
        disabled={useEntireQuantity}
      />
      {<span className='error'>{transactionData?.data?.quantityError}</span>}
    </div>
  );
};

export default QuantityComponent;
