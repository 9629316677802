import _ from 'lodash';
import moment from 'moment';
import { getHourlyMemoizedNow } from '../../constants/date';

export const getWeekDateRange = (dateOrTimestamp: Date | number | moment.Moment = getHourlyMemoizedNow()) => ({
  maxDate: moment(dateOrTimestamp).endOf('isoWeek').toDate(),
  minDate: moment(dateOrTimestamp).startOf('isoWeek').toDate(),
});

export const getDayDateRange = (dateOrTimestamp: Date | number | moment.Moment = getHourlyMemoizedNow()) => ({
  maxDate: moment(dateOrTimestamp).endOf('day').toDate(),
  minDate: moment(dateOrTimestamp).startOf('day').toDate(),
});

export const getMonthDateRange = ({
  dateOrTimestamp = getHourlyMemoizedNow(),
  monthDuration = 1,
}: {
  dateOrTimestamp?: Date | number | moment.Moment;
  monthDuration?: number;
}) => ({
  maxDate: moment(dateOrTimestamp)
    .add(monthDuration - 1, 'month')
    .endOf('month')
    .toDate(),
  minDate: moment(dateOrTimestamp).startOf('month').toDate(),
});

export const getMonthDateRangeFromMonthYear = ({
  month,
  year,
}: {
  month: number;
  year: number;
}) => ({
  maxDate: moment(moment(`${month}/${year}`, 'MM/YYYY'))
    .endOf('month')
    .toDate(),
  minDate: moment(moment(`${month}/${year}`, 'MM/YYYY'))
    .startOf('month')
    .toDate(),
});

export const isBetweenDateRange = (date: Date, minDate: Date, maxDate: Date): boolean => {
  if (!date || !minDate || !maxDate) return false;
  return moment(date).isBetween(minDate, maxDate, undefined, '[]');
};

export const getStartDateFilter = (date: Date) => {
  if (!date) return false;
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export const getEndDateFilter = (date: Date) => {
  if (!date) return false;
  return moment(date).subtract(1, "seconds").add(1,'d').format("YYYY-MM-DD HH:mm:ss");
};