export enum UserStatus {
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 0,
}

export enum OtpStatus {
  VERIFYOTP = 0,
  RESETOTP = 1,
}
export enum PCRequestStatus {
  Awating = 1,
  AwatingDescription = 'Awaiting',
  Approved = 2,
  ApprovedDescription = 'Approved',
  Rejected = 3,
  RejectedDescription = 'Rejected',
  Completed = 4,
  CompletedDescription = 'Completed',
  Partial = 5,
  PartialDescription = 'Partial',
  Closed = 6,
  ClosedDescription = 'Closed',
}

export enum PCRequestStatusRemake {
  Awating = 1,
  AwatingDescription = 'Awaiting Approval',
  Approved = 2,
  ApprovedDescription = 'Approved',
  Rejected = 3,
  RejectedDescription = 'Rejected',
  Completed = 4,
  CompletedDescription = 'Credit Allocated',
  Partial = 5,
  PartialDescription = 'Partial',
  Closed = 6,
  ClosedDescription = 'Closed',
}

export enum PCRStatus {
  Awating = 1,
  AwatingDescription = 'Requested',
  Approved = 2,
  ApprovedDescription = 'Approved',
  Rejected = 3,
  RejectedDescription = 'Rejected',
  Completed = 4,
  CompletedDescription = 'Completed',
  Partial = 5,
  PartialDescription = 'Partial',
  Closed = 6,
  ClosedDescription = 'Closed',
}

export enum UserTypeStatus {
  Individual = 4,
  IndividualDescription = 'Individual',
  Company = 5,
  CompanyDescription = 'Company',
}
