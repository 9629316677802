import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export interface ConfirmationData {
  handleCancel: any;
  handleSubmit: any;
  message: string;
  title: string;
  cancelText?: string;
  submitText?: string;
  hideCancelButton?: boolean;
}

const ConfirmPopup = (props: ConfirmationData) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    props.handleCancel();
  };
  const handleOk = () => {
    setShow(false);
    props.handleSubmit();
  };

  return (
    <>
      <Modal className="modal small-popup" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="usermanagement-modal">
          <h6>{props.message}</h6>
        </Modal.Body>
        <Modal.Footer>
          {!props.hideCancelButton && (
            <Button
              onClick={handleClose}
              variant="primary"
              type="button"
              className="btn btn-secondary btn-danger"
            >
              {props.cancelText ? props.cancelText : "Cancel"}
            </Button>
          )}
          <Button
            onClick={handleOk}
            className="btn btn-primary"
          >
            {props.submitText ? props.submitText : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ConfirmPopup;
