import { plasticCreditApi } from '..';
import { ServiceResponseInterface } from '../../types/serverResponse';
import { apiUrl } from './apiUrl';

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({
    addDomain: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.addDomain,
        method: 'POST',
        body: data,
      }),
    }),
    updateDomainStatus: builder.mutation<any, any>({
      query(body) {
        return {
          url: apiUrl.updateDomainStatus,
          method: 'POST',
          body,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useAddDomainMutation, useUpdateDomainStatusMutation } =
  extendedApi;
