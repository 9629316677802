import { GeneratePassword } from "../generatePassword";

export const UpdatePassword = () => {

    return (
        <>
            <GeneratePassword 
                updatePassword={true} />
        </>
    );
};
