import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routesConstant } from '../../../constants/comman.constant';
// import ExportPopup from './exportPopup';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { commanLabel } from '../../../constants/comman.constant';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { validationMessage } from '../../../constants/validation.message.constant';

const EventList = () => {
  const { t: translate } = useTranslation();
  const columnHelper = createColumnHelper<any>();
  const navigate = useNavigate();
  const [exportPopup, setExportPopup] = useState(false);
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [eventDateRange, setEventDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [eventStartDate, eventEndDate] = eventDateRange;
  const [searchValue, setSearchValue] = useState('');
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [eventCategoryData, setEventCategoryData] = useState([]);

  const columns = [
    columnHelper.accessor((row) => `${row?.event_id}`, {
      id: `${translate('CSR_MODULE.TITLES.EVENT_ID')}`,
      cell: (props: any) => {
        return (
          <>
            {/* {props?.row?.original?.is_bulk_upload && (
              <div
                className='bulk-icons me-2'
                data-bs-toggle='tooltip'
                data-bs-placement='bottom'
                title={props?.row?.original?.bulk_upload_id}
              ></div>
            )} */}
            {props?.row?.original?.cst_event_id}
          </>
        );
      },
    }),
    columnHelper.accessor((row) => `${row && row?.name}`, {
      id: `${translate('CSR_MODULE.TITLES.EVENT_NAME')}`,
    }),
    // columnHelper.accessor((row) => `${getAge(row.birth_date)}`, {
    //   id: 'Age',
    // }),

    columnHelper.accessor(
      (row: any) =>
        moment(`${row?.start_date ? row?.start_date : '-'}`).format(
          'DD/MM/YYYY'
        ),
      {
        id: `${translate('CSR_MODULE.TITLES.DATE_OF_EVENT')}`,
        cell: (props: any) => {
          console.log('props::::', props);
          return (
            <>
              {/* {props?.row?.original?.is_bulk_upload && (
              <div
                className='bulk-icons me-2'
                data-bs-toggle='tooltip'
                data-bs-placement='bottom'
                title={props?.row?.original?.bulk_upload_id}
              ></div>
            )} */}
              {moment(
                `${
                  props?.row?.original?.start_date
                    ? props?.row?.original?.start_date
                    : '-'
                }`
              ).format('DD/MM/YYYY')}{' '}
              -{' '}
              {moment(
                `${
                  props?.row?.original?.end_date
                    ? props?.row?.original?.end_date
                    : '-'
                }`
              ).format('DD/MM/YYYY')}
            </>
          );
        },
      }
    ),

    columnHelper.accessor((row: any) => `${row && row?.event_category?.name}`, {
      id: `${translate('CSR_MODULE.TITLES.CATEGORY')}`,
    }),

    columnHelper.accessor((row: any) => `${row && row?.organizer}`, {
      id: `${translate('CSR_MODULE.TITLES.ORGANISER')}`,
    }),

    columnHelper.accessor((row: any) => `${row && row?.institution_involved}`, {
      id: `${translate('CSR_MODULE.TITLES.INSTITUTION_INVOLVED')}`,
    }),
  ];

  const getEventCategoryDetails = async () => {
    let path = apiUrl.getEventCategory;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setEventCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  const getData = async (reset: boolean) => {
    setIsLoading(true);
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      // is_linked: false,

      event_start_date: reset
        ? ''
        : eventDateRange[0] === null
        ? ''
        : getStartDateFilter(eventDateRange[0]),
      event_end_date: reset
        ? ''
        : eventDateRange[1] === null
        ? ''
        : getEndDateFilter(eventDateRange[1]),

      search_text: reset ? '' : searchValue,
      // material_type_id: reset ? '' : materialTypeId,
      // material_name_type_id: reset ? '' : subCategoryId,
      category_id: reset ? '' : categoryId,
    };
    let path = apiUrl.getCSREventList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setEventData(res.data.data);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData(false);
    getEventCategoryDetails();
  }, [page, pageLimit]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: eventData,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const performSearch = (e: any) => {
    e.preventDefault();
    getData(false);
  };

  const handeleReset = () => {
    setPage(1);
    setDateRange([null, null]);
    setEventDateRange([null, null]);
    setSearchValue('');
    setCategoryId('');
    getData(true);
  };

  return (
    <>
      {/* {exportPopup && (
        <ExportPopup
          title={`${translate('CSR_MODULE.TITLES.EXPORT')}`}
          handleCancel={() => setExportPopup(false)}
          cancelText='Cancel'
          submitText='OK'
          hideCancelButton={true}
          handleSubmit={() => {
            setExportPopup(false);
          }}
        />
      )} */}
      <div>
        <div
          className='tab-pane fade show active'
          role='tabpanel'
          aria-labelledby='pc-request1-tab'
        >
          <div className='listing-transaction'>
            <form className=''>
              <div className='top-filters'>
                <div className='date-item-picker item-in'>
                  <div className='date-picker-main'>
                    <div className='react-datepicker-wrapper'>
                      <div className='react-datepicker__input-container '>
                        <DatePicker
                          selectsRange={true}
                          startDate={eventStartDate}
                          endDate={eventEndDate}
                          value={eventStartDate ? eventStartDate : ''}
                          className='form-control'
                          placeholderText={`${translate(
                            'CSR_MODULE.TITLES.SELECT_EVENT_DATE'
                          )}`}
                          dateFormat={commanLabel.dateFormat}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          onChange={(update: any) => {
                            setEventDateRange(update);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='date-item-picker item-in'>
                  <div className='date-picker-main'>
                    <div className='react-datepicker-wrapper'>
                      <div className='react-datepicker__input-container '>
                        <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          value={startDate ? startDate : ''}
                          className='form-control'
                          placeholderText={`${translate(
                            'CSR_MODULE.TITLES.SELECT_ENTRY_DATE'
                          )}`}
                          dateFormat={commanLabel.dateFormat}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          onChange={(update: any) => {
                            setDateRange(update);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className='type-select item-in'>
                  <select
                    id=''
                    name='type_of_material'
                    className='select-layout'
                    value={categoryId}
                    onChange={(e: any) => setCategoryId(e.target.value)}
                  >
                    <option value=''>
                      {translate('CSR_MODULE.TITLES.CATEGORY')}
                    </option>
                    {eventCategoryData &&
                      eventCategoryData.length > 0 &&
                      eventCategoryData.map(
                        (eventCategory: any, index: number) => {
                          return (
                            <option value={eventCategory.id}>
                              {eventCategory?.name}
                            </option>
                          );
                        }
                      )}
                  </select>
                </div>

                <div className='type-select item-in'>
                  <div className='item input-min-width'>
                    <div className='input-search-item form-group'>
                      <label className='searchicon'>
                        <i className='fa fa-search'></i>
                      </label>
                      <input
                        className='form-control'
                        id='searchicon'
                        placeholder='Search'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <div className='info-icons text-end'>
                        <a
                          href='#'
                          data-bs-toggle='tooltip'
                          data-bs-placement='bottom'
                          title={`${translate(
                            'CSR_MODULE.TITLES.EVENT_SEARCH_TEXT'
                          )}`}
                        >
                          <i
                            className='fa fa-info-circle'
                            aria-hidden='true'
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='search-button item-in'>
                  <Button
                    role='button'
                    onClick={performSearch}
                    title={`${translate('CSR_MODULE.TITLES.SEARCH')}`}
                    className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                  >
                    <i className='fa fa-search' aria-hidden='true'></i>
                  </Button>
                  <Button
                    role='button'
                    onClick={handeleReset}
                    title={`${translate('CSR_MODULE.TITLES.RESET')}`}
                    type='reset'
                    className='btn btn-primary btn-outline-primary btn-sm btn-icon btn btn-primary'
                  >
                    <i className='fa fa-refresh' aria-hidden='true'></i>
                  </Button>
                </div>
              </div>
            </form>
            <div className='csr-btn-area mt-4 mb-4 row me-0 ms-0 me-sm-0 ms-sm-0 me-md-0 ms-md-0 me-lg-3 ms-lg-3 me-xl-3 ms-xl-3'>
              <div className='col-md-4 d-flex jusitfy-content-center'></div>
              <div className='col-md-8 d-flex justify-content-end mt-5 mt-sm-5 mt-md-0 mt-lg-0 mt-xl-0'>
                <div className='form-submit-buttons auto-width-button'>
                  <Link to={routesConstant.addCSREvent}>
                    <a
                      role='button'
                      href='#'
                      title={`${translate('CSR_MODULE.TITLES.SEARCH')}`}
                      className='btn btn-primary btn-outline-primary btn-md btn btn-primary me-4'
                    >
                      <i className='fa fa-calendar me-1' aria-hidden='true'></i>{' '}
                      {translate('CSR_MODULE.TITLES.ADD_EVENT')}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className='listing-content'>
              <div className='table-list'>
                <table>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup: any) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header: any) => {
                          return (
                            <th key={header.id} colSpan={header.colSpan}>
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none'
                                      : '',
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: ' 🔼',
                                    desc: ' 🔽',
                                  }[header.column.getIsSorted() as string] ??
                                    null}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>

                  {eventData?.length > 0 ? (
                    <tbody>
                      {table.getRowModel().rows.map((row: any) => {
                        return (
                          <tr
                            key={row.id}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              navigate(
                                `${routesConstant.viewCSREvent}${row.original.id}`
                              );
                            }}
                          >
                            {row.getVisibleCells().map((cell: any) => {
                              return (
                                <td key={cell.id}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <>
                      <tbody>
                        <tr>
                          <td colSpan={9}>
                            {isLoading && (
                              <h3 className='center-message-item'>
                                {translate('CSR_MODULE.TITLES.LOADING')}
                              </h3>
                            )}
                            {eventData?.length === 0 && !isLoading && (
                              <h3 className='center-message-item'>
                                {
                                  validationMessage.noRecordFoundUnlinkedTransaction
                                }
                              </h3>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}
                </table>
              </div>
              {eventData?.length > 0 && pageCount > 1 && (
                <div className='items-center pagination-main gap-2 text-center'>
                  <PaginationControl
                    page={page}
                    between={4}
                    total={totalCount}
                    limit={pageLimit}
                    changePage={(page) => {
                      setPage(page);
                    }}
                    ellipsis={2}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventList;
