import React, { useEffect, useState, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import axios from "axios";

const addressTypes = [
  "locality",
  "administrative_area_level_2",
  "administrative_area_level_1",
  "postal_code",
  "country",
] as const;

export type AddressType = typeof addressTypes[number];

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
export interface LocationPickerData {
  title: string;
  setMarkerLocation: any;
  markerLocation: any;
  show: boolean;
  setShow: any;
  locationText: string;
  setLocationText: any;
}

const ConfirmPopup = (props: LocationPickerData) => {
  const [isSearch, setIsSearch] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState(null);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ requestOptions: {}, debounce: 300 });

  const handleSelect = (suggestion: any) => {
    // console.log(suggestion);

    setValue(suggestion.description, false);
    props.setLocationText(suggestion.description);
    // Get latitude and longitude via utility functions
    getGeocode({ address: suggestion.description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        props.setMarkerLocation({ lat: lat, lng: lng });
        clearSuggestions();
        setValue("");
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const updateAddressData = (
    addressData: AddressComponent[],
    formattedAddress: string
  ) => {
    props.setLocationText(formattedAddress);
  };

  const updateAddress = useCallback(() => {
    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          props.markerLocation.lat +
          "," +
          props.markerLocation.lng +
          "&key=" +
          process.env.REACT_APP_GOOGLE_MAP
      )
      .then((response) => {
        const addressData = response.data["results"][0]["address_components"];
        updateAddressData(
          addressData,
          searchedLocation != null
            ? searchedLocation
            : response.data["results"][0]["formatted_address"]
        );
      })
      .catch((err) => console.log(err));
  }, [props.markerLocation]);

  const renderSuggestions = () => {
    return (
      <div
        style={{ maxHeight: "250px", minHeight: "200px", overflowY: "auto" }}
      >
        {data.map((suggestion, index) => {
          const {
            structured_formatting: { main_text, secondary_text },
          } = suggestion;
          return (
            <div
              key={index}
              onClick={() => handleSelect(suggestion)}
              style={{
                // display: "flex",
                // flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "2%",
                paddingBottom: "3%",
                borderBottom: "1px solid #c4c4c4",
                // fontWeight: 300,
                fontSize: "0.75rem",
                height: "auto",
                padding: "10px 10px ",
              }}
            >
              <span>{main_text}</span>
              <br />
              <span style={{ fontWeight: 300, opacity: 300 }}>
                {secondary_text}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const handleClose = () => {
    // props.setMarkerLocation({ lat: 0, lng: 0 });
    props.setShow(false);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    updateAddress();
  }, [props.markerLocation]);
  return (
    <>
      <Modal
        className="modal small-popup"
        show={props.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Location</Modal.Title>
        </Modal.Header>
        <Modal.Body className="usermanagement-modal">
          {props.markerLocation.lat === 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: "1px solid #9a9a9a",
                  borderRadius: "20px",
                  height: "2.675rem",
                  marginTop: "1rem",
                  marginBottom: "2%",
                }}
              >
                <input
                  type="text"
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                  placeholder="Search Location"
                  onFocus={() => setIsSearch(true)}
                  ref={input => input && input.focus()}
                  style={{
                    width: "70%",
                    border: "transparent",
                    borderRadius: "20px",
                    outline: "transparent",
                    fontSize: "1rem",
                    fontWeight: "400",
                    paddingLeft: "20px",
                  }}
                />
              </div>
              <div>{renderSuggestions()}</div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "50vh" }}
                center={props.markerLocation}
                zoom={15}
              >
                <MarkerF
                  position={props.markerLocation}
                  onDragEnd={(e) => {
                    e.latLng &&
                      props.setMarkerLocation({
                        lat: e.latLng?.lat(),
                        lng: e.latLng?.lng(),
                      });
                  }}
                  draggable
                />
              </GoogleMap>
            </div>
          )}
        </Modal.Body>
        {props.markerLocation.lat !== 0 && (
          <Modal.Footer>
            <div className="location-modal-item">
              <Button
                onClick={() => props.setMarkerLocation({ lat: 0, lng: 0 })}
              >
                Search Different Location
              </Button>
              <Button onClick={handleClose}>Select this location</Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
export default ConfirmPopup;
