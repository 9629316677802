import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { PlasticCerditCertificationMap } from './PlasticCreditCertificateMap';
import { ProductDetails } from './productDetails';
import { CertificateHeader } from '../commanPage/plasticCreditCertificateHeader';
import { apiUrl } from '../../../../app/api/services/apiUrl';
import { helper } from '../../../../app/api/api.helper';
import { toast } from 'react-toastify';
import { ParticipantStages } from './participantStages';
import { store } from '../../../../app/store';
import { RootState } from '../../../../app/types/store';
import CSRActivityList from './csrActivityList';
import OtherCSRActivityList from './otherCsrActivityList';

const PlasticCerditCertification = () => {
  const { id } = useParams();
  const transactionId = id?.split('&')[0] || '';
  const pcId = id?.split('&')[1] || '';
  const [ownerDetails, setOnerDetails] = useState({});
  const [productDetail, setProductDetail] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [participantData, setParticipantData] = useState([]);
  const [ownerHistory, setOwnerHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQrCode] = useState({});
  const [totalQuantity, setTotalQuantity] = useState({});
  const [certificate_url, setCertificate] = useState({});
  const [csrActivity, setCSRActivity] = useState([]);
  const [othercsrActivity, setOtherCSRActivity] = useState([]);
  const [linkCertiTransaction_id, setLinkCertiTransaction_id] = useState();
  const getData = async () => {
    if (transactionId) {
      setIsLoading(true);
      let path =
        apiUrl.getPlasticCreditWedDetails +
        window.atob(transactionId) +
        '/' +
        window.atob(pcId);
      const fr = await helper.getData(path);
      const res = await fr.response;
      if (res.success) {
        setOnerDetails(res?.data?.ownerDetails);
        setProductDetail(res?.data?.productDetails);
        setLocationList(res?.data?.locationList);
        setParticipantData(res?.data?.circulatoryParticipants);
        setQrCode(res?.data?.qrCode);
        setTotalQuantity(res?.data?.totalQuantity);
        setCertificate(res?.data?.certificate_url);
        setOwnerHistory(res?.data?.ownerHistory);
        setCSRActivity(res?.data?.csr_activity);
        setOtherCSRActivity(res?.data?.other_csr_activity)
        const filteredCertificate: any = res?.data?.ownerHistory.find(
          (certiRow: any) =>
            Number(certiRow?.pc_id) === Number(window.atob(pcId))
        );
        setLinkCertiTransaction_id(filteredCertificate?.id);
      } else {
        toast.error(res.message);
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if(!pcId) {
      if(id  === 'TE5LMDAwMDc3') {
        window.location.href = window.location.href+'&OQ==';
      } else if(id === 'TE5LMDAwMDYy') {
        window.location.href = window.location.href+'&OA==';
      } else if(id === 'TE5LMDAwMDYx') {
        window.location.href = window.location.href+'&Nw==';
      } else if(id === 'TE5LMDAwMDYw' ) {
        window.location.href = window.location.href+'&Ng==';
      } else if(id === 'TE5LMDAwMDA2') {
        window.location.href = window.location.href+'&NQ==';
      } else if(id === 'TE5LMDAwMDAx') {
        window.location.href = window.location.href+'&MQ==';
      }
    }
  }, []);
  return (
    <React.Fragment>
      <div
        className={`certificate-page certificate-home certificate-body ${
          isLoading ? 'loading' : ''
        }`}
      >
        <div className='container-items header-bg-img'>
          {
            <CertificateHeader
              ownerDetails={ownerDetails}
              productDetail={productDetail}
              qrCode={qrCode}
              participantData={participantData}
            />
          }
          <div className='front-page'>
            <div className='content-section-wrap container-space'>
              <div className='mapsection'>
                <PlasticCerditCertificationMap locationList={locationList} />
              </div>
              <ProductDetails
                productDetail={productDetail}
                ownerDetails={ownerDetails}
                certificate_url={certificate_url}
                linkCertiTransaction_id={linkCertiTransaction_id}
              />
            </div>
            {
              <ParticipantStages
                participantData={participantData}
                totalQuantity={totalQuantity}
                ownerHistory={ownerHistory}
              />
            }

            {csrActivity.length > 0 && <CSRActivityList data={csrActivity} hideHeader={false}  />}

            {othercsrActivity.length > 0 && <OtherCSRActivityList data={othercsrActivity} hideHeader={false}  />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PlasticCerditCertification;
