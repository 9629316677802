export function maskNumber(card: string) {
  let hideNum = [];
  for (let i = 0; i < card.length; i++) {
    if (i < card.length - 7) {
      hideNum.push(card[i]);
    } else {
      hideNum.push("*");
    }
  }
  return hideNum.join("");
}

export const maskOwnerName = (name: string) => {
  return name.charAt(0) + "XXXXXXXX" + name.slice(-1);
};

export const maskPCId = (id: string) => {
  return id.slice(0, 3) + "XXXXXXX";
};
export const maskaadhar = (id: string) => {
  return id.slice(0, 3) + "XXXXXXXXX";
};

export const disableScrollForTypeNumber = (e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })