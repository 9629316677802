import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { apiUrl } from '../../../../app/api/services/apiUrl';
import { helper } from '../../../../app/api/api.helper';
import { imagePath, commanLabel } from '../../../../constants/comman.constant';
import {
  getFormatBytes,
  getFileFormat,
} from '../../../../utils/files/commanFilefunction';

const DocumentTable = ({
  profileDetail,
  files,
  onDelete,
  editMode,
  onLoadMore,
  loading,
  pageCount,
  dataCount,
  page,
}: any) => {
  const { t: translate } = useTranslation();
  const {
    user_profile_history_user_profile_history_user_idTouser:
      profileHistory = [],
  }: any = profileDetail?.user_profile || {};
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const columnHelper = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor(`${translate('PROFILE.TITLES.FILENAME')}`, {
      header: () => `${translate('PROFILE.TITLES.FILENAME')}`,
      cell: (props: any) => {
        return (
          <React.Fragment>
            <div className='file-type-container'>
              <div>
                {getFileFormat(props?.row?.original?.document_type) ===
                  commanLabel.pdf && (
                  <img className='file-logo' src={imagePath.pdfPath} alt='' />
                )}
                {getFileFormat(props?.row?.original?.document_type) ===
                  commanLabel.png && (
                  <img className='file-logo' src={imagePath.jpgPath} alt='' />
                )}
                {getFileFormat(props?.row?.original?.document_type) ===
                  commanLabel.jpg && (
                  <img className='file-logo' src={imagePath.jpgPath} alt='' />
                )}
                {getFileFormat(props?.row?.original?.document_type) ===
                  commanLabel.jpeg && (
                  <img className='file-logo' src={imagePath.jpgPath} alt='' />
                )}
                {getFileFormat(props?.row?.original?.document_type) ===
                  commanLabel.excel && (
                  <img className='file-logo' src={imagePath.excelPath} alt='' />
                )}
                {getFileFormat(props?.row?.original?.document_type) ===
                  commanLabel.docs && (
                  <img className='file-logo' src={imagePath.docsLogo} alt='' />
                )}
              </div>

              <div className='file-type'>
                {props?.row?.original?.document_name}
                {isPendingVisible(props?.row?.original) ? (
                  <div style={{ marginLeft: 8, display: 'inline-block' }}>
                    <img
                      src={imagePath.reqindPath}
                      title='Pending'
                      className='pending-item'
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </React.Fragment>
        );
      },
    }),

    columnHelper.accessor('document_type', {
      header: () => translate('PROFILE.TITLES.TYPE'),
    }),

    columnHelper.accessor('size', {
      header: () => translate('PROFILE.TITLES.SIZE'),
    }),

    columnHelper.accessor('-', {
      header: () => 'Action',
      enableSorting: false,
      cell: (props: any) => {
        let rowInfo = props?.row?.original;

        return (
          <React.Fragment>
            <div className='icon-action-main icon-ul'>
              <div className='icon-view icon list'>
                <Link to={`#`} title='View'>
                  <img
                    onClick={() => {
                      window.open(rowInfo?.document_path);
                    }}
                    src={`${process.env.PUBLIC_URL}${imagePath.eyePath}`}
                    alt={''}
                  />
                </Link>
              </div>
              <div className='icon-view icon list'>
                <div
                  onClick={async () => {
                    if (rowInfo?.document_path) {
                      let path = apiUrl.prepareFile + rowInfo?.document_path;
                      const fr = await helper.getData(path);
                      const res = await fr.response;
                      if (res.data) {
                        window.open(
                          process.env.REACT_APP_BASE_URL_API +
                            apiUrl.downloadFile +
                            rowInfo?.document_path
                        );
                        toast.success(
                          translate('PROFILE.TITLES.DOCUMNENT_DOWNLOADED')
                        );
                      } else {
                        toast.error(res.message);
                      }
                    } else {
                      toast.error(translate('PROFILE.TITLES.TECHNICAL_ERROR'));
                    }
                  }}
                >
                  <Link to={`#`} title='Download'>
                    <img
                      src={`${process.env.PUBLIC_URL}${imagePath.downloadPath}`}
                      alt='#'
                    />
                  </Link>
                </div>
              </div>
              {editMode ? (
                <div
                  className='icon-view icon list'
                  data-toggle='modal'
                  // data-target="#pc--certificate"
                  onClick={() => {
                    onDelete(rowInfo);
                  }}
                >
                  <Link to={`#`} title='Open'>
                    <img
                      src={`${process.env.PUBLIC_URL}${imagePath.deletePath}`}
                      alt={''}
                    />
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>
          </React.Fragment>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: files.map((file: any) => {
      return { ...file, size: getFormatBytes(file.size) };
    }),
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });
  const isPendingVisible = (row: any) => {
    let visible = false;
    if (profileHistory.length) {
      const docIds = profileHistory[0]?.details?.document_ids || [];
      visible = docIds.length ? docIds?.includes(row?.id) : false;
    }
    return visible;
  };
  return (
    <React.Fragment>
      <div className='table-certificate'>
        <div className='listing-content'>
          <InfiniteScroll
            dataLength={page * 10}
            next={onLoadMore}
            hasMore={dataCount > files.length}
            loader={
              <p style={{ textAlign: 'center' }}>
                <b>Loading...</b>
              </p>
            }
            className='table-list'
          >
            <table width='100%' className='table-data'>
              {table.getHeaderGroups().map((headerGroup: any) => (
                <thead>
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header: any) => {
                      return (
                        <td key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: (
                                  <i
                                    className='fa fa-sort-desc'
                                    aria-hidden='true'
                                  ></i>
                                ),
                                desc: (
                                  <i
                                    className='fa fa-sort-asc'
                                    aria-hidden='true'
                                  ></i>
                                ),
                              }[header.column.getIsSorted() as string] ?? (
                                <i
                                  className='fa fa-sort'
                                  aria-hidden='true'
                                ></i>
                              )}
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </thead>
              ))}

              <>
                {table.getRowModel().rows.map((row: any) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell: any) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            </table>

            <>
              {loading && <h3 className='center-message-item'>Loading...</h3>}
              {!loading && files && files.length === 0 && (
                <h3 className='center-message-item'>{translate(
                                          'COMMONFIELDS.NO_RECORD_FOUND'
                                        )}</h3>
              )}
            </>
          </InfiniteScroll>
        </div>
      </div>
    </React.Fragment>
  );
};
export default DocumentTable;
