import "./App.css";
import { MainRoutes } from "./router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import i18n from "./translations/i18n";
import { notificationClient } from "./utils/helper/notification";
export const App = () => {
  const { t: translate } = useTranslation();
  notificationClient.startClient();
  // react-i18next - below code will change the language i.e call this function if you want to change language
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="App">
      
      {/* react-i18next */}
      {/* <button onClick={() => changeLanguage("en")}>en</button>
      <button onClick={() => changeLanguage("hi")}>hindi</button>  */}
 

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <MainRoutes />
    </div>
  );
};

export default App;
