import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import TRANSLATIONS_Hindi from "./translations_hindi.json";
import TRANSLATIONS_EN from "./translations.json";

// const TRANSLATIONS_EN = 
// const TRANSLATIONS_Hindi = 

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      lng: 'en',
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      hi: {
        translation: TRANSLATIONS_Hindi,
      },
    },
  });

export default i18n;