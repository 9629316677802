import { plasticCreditApi } from '..';
import { ServiceResponseInterface } from '../../types/serverResponse';
import { apiUrl } from './apiUrl';

const extendedApi = plasticCreditApi.injectEndpoints({
  endpoints: (builder) => ({
    createTransaction: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: 'transaction-managment/transaction/create',
        method: 'POST',
        body: data,
      }),
    }),
    updateTransaction: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: 'transaction-managment/transaction/update',
        method: 'POST',
        body: data,
      }),
    }),
    getTransaction: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.getTrasactionUnlinkedList,
        method: 'POST',
        body: data,
      }),
    }),
    getTransactionforUpload: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.listforducument,
        method: 'POST',
        body: data,
      }),
    }),
    getMaterialSubType: builder.query({
      query: (id) => `get-settings/setting/getMaterialSubtypeById?Id=${id}`,
    }),
    deleteTrasaction: builder.mutation({
      query: (id) => ({
        url: `transaction-managment/transaction/deleteTransactionById?Id=${id}`,
        method: 'DELETE',
      }),
    }),
    exportUnlinkTransaction: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.exportexcelUnlinkTransaction,
        method: 'POST',
        body: data,
      }),
    }),
    linkedExport: builder.mutation<ServiceResponseInterface<any>, any>({
      query: ({ ...data }) => ({
        url: apiUrl.exportexcelLinkTransaction,
        method: 'POST',
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateTransactionMutation,
  useGetTransactionMutation,
  useGetMaterialSubTypeQuery,
  useUpdateTransactionMutation,
  useDeleteTrasactionMutation,
  useExportUnlinkTransactionMutation,
  useLinkedExportMutation,
  useGetTransactionforUploadMutation
} = extendedApi;
