import React, { useState, useEffect } from 'react';

import DatePicker from 'react-datepicker';
import { Button, Form } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import Pagination from 'react-bootstrap/Pagination';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import { commanLabel, getCreditType } from '../../../constants/comman.constant';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';

import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { UserProfileStatus } from '../../../shared/remake-green-utils';
import { useTranslation } from 'react-i18next';
import { getNoRecordMessage } from '../../../utils/helper/common';
import ConfirmationPopup from '../../../components/confirmationPopup';

const requestTypes = [
  { name: 'Pending user request', value: UserProfileStatus.PENDING },
  { name: 'Rejected user request', value: UserProfileStatus.REJECTED },
  { name: 'Approved user request', value: UserProfileStatus.APPROVED },
];

export interface QueueprintListData {
  setReload?: any;
  reload?: boolean;
}

const QueueList = (props: QueueprintListData) => {
  const { t: translate } = useTranslation();
  const { state } = useLocation();
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [searchedText, setSearchedText] = useState('');
  const [selectedTeb, setSelectedTeb] = useState(
    state ? state : UserProfileStatus.PENDING
  );
  const [pageCount, setPageCount] = useState(0);
  const [footprintList, setFootprintList] = useState<any>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [itemCount, setItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const noRecordFoundMessage = translate('COMMONFIELDS.NO_RECORD_FOUND_FOR');
  const [submitPopup, setSubmitPopup] = useState({
    isOpen: false,
    id: null,
    status: false,
  });

  const columnHelper = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor((row) => `${row?.user_name ? row?.user_name : '-'}`, {
      id: `${translate('FOOTPRINT_MANAGEMENT.TITLES.USER_NAME')}`,
    }),

    columnHelper.accessor(
      (row) =>
        ` ${
          row?.domain_details?.domain_id ? row?.domain_details?.domain_id : '-'
        }`,
      {
        id: `${translate('FOOTPRINT_MANAGEMENT.TITLES.DOMAIN_ID')}`,
      }
    ),
    columnHelper.accessor(
      (row) => ` ${row?.domain_details?.url ? row?.domain_details?.url : '-'}`,
      {
        id: `${translate('FOOTPRINT_MANAGEMENT.TITLES.URL')}`,
      }
    ),

    columnHelper.accessor((row) => `${row?.email ? row?.email : '-'}`, {
      id: `${translate('DOMAIN_MANAGEMENT.TITLES.EMAIL')}`,
    }),

    columnHelper.accessor(
      (row) =>
        `${row?.quantity ? `${row.quantity} ${row.quantity_unit}` : '-'}`,
      {
        id: `${translate('FOOTPRINT_MANAGEMENT.TITLES.QUANTITY')}`,
      }
    ),

    columnHelper.accessor((row) => `${getCreditType(row.credit_type)} Credit`, {
      id: `${translate('FOOTPRINT_MANAGEMENT.TITLES.CREDIT_TYPE')}`,
    }),

    columnHelper.accessor(
      (row) => moment(row.requested_on).format('DD/MM/YYYY'),
      {
        id: `${translate('FOOTPRINT_MANAGEMENT.TITLES.REQUEST_DATE')}`,
      }
    ),
  ];

  const getData = async (reset: boolean) => {
    setIsLoading(true);
    const parms = {
      page: reset ? 1 : page,
      limit: pageLimit,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      search_text: reset ? '' : searchedText,
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),
    };
    let path = apiUrl.getQueueList;
    const fr = await helper.getData(path, parms);
    const res = await fr.response;
    if (res.success) {
      setFootprintList(res.data.data);
      setItemCount(res?.data?.total);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
    props.setReload(false);
  };

  useEffect(() => {
    if (props.reload === true) getData(true);
  }, [props.reload]);

  const getPagination = () => {
    let items = [];
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item
          activeLabel=''
          key={number}
          active={number === page}
          onClick={(e) => {
            e.preventDefault();
            setPage(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  useEffect(() => {
    getData(false);
  }, [page, pageLimit]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: footprintList,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const handleReset = () => {
    setPage(1);
    setSearchedText('');
    // setRoleTypeId('');
    setDateRange([null, null]);
    getData(true);
  };

  const performSearch = (e: any) => {
    e.preventDefault();
    getData(false);
  };
  return (
    <>
      <div className='remake-user '>
        <div className='tab-content' id=''>
          <div
            className='tab-pane fade show active'
            role='tabpanel'
            aria-labelledby='pc-request1-tab'
          >
            <div className='listing-transaction'>
              <div className='top-filters'>
                <div className='date-item-picker item-in'>
                  <div className='date-picker-main'>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      value={startDate ? startDate : ''}
                      className='form-control'
                      placeholderText='Select request date range'
                      dateFormat='dd/MM/yyyy'
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      onChange={(update: any) => {
                        setDateRange(update);
                      }}
                    />
                  </div>
                </div>

                <div className='type-select item-in'>
                  <div className='item input-min-width'>
                    <div className='input-search-item form-group'>
                      <label
                        // for="searchicon"
                        className='searchicon'
                      >
                        
                      </label>
                      <input
                        className='form-control'
                        id='searchicon'
                        placeholder='Search'
                        value={searchedText}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            performSearch(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='search-button item-in'>
                  <Button
                    className='btn  btn-sm btn-outline-primary btn-icon'
                    type='button'
                    title='Search'
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(1);
                      page === 1 && getData(false);
                    }}
                  >
                    <i className='fa fa-search' aria-hidden='true'></i>
                  </Button>
                  <Button
                    className='btn  btn-sm  btn-outline-primary btn-icon'
                    title='Reset'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      handleReset();
                    }}
                  >
                    <i className='fa fa-refresh' aria-hidden='true'></i>
                  </Button>
                </div>
              </div>

              <div className='listing-content'>
                <div className='table-list'>
                  <table>
                    <thead>
                      {table.getHeaderGroups().map((headerGroup: any) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header: any) => {
                            return (
                              <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                  <div
                                    {...{
                                      className: header.column.getCanSort()
                                        ? 'cursor-pointer select-none'
                                        : '',
                                      onClick:
                                        header.column.getToggleSortingHandler(),
                                    }}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: ' 🔼',
                                      desc: ' 🔽',
                                    }[header.column.getIsSorted() as string] ??
                                      null}
                                  </div>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    {footprintList?.length > 0 ? (
                      <tbody>
                        {table.getRowModel().rows.map((row: any) => {
                          return (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell: any) => {
                                return (
                                  <td key={cell.id}>
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <>
                        <tbody>
                          <tr>
                            <td colSpan={9}>
                              {isLoading && (
                                <h3 className='center-message-item'>
                                  Loading...
                                </h3>
                              )}
                              {footprintList?.length === 0 && !isLoading && (
                                <h3 className='center-message-item'>
                                  {getNoRecordMessage(
                                    noRecordFoundMessage,
                                    requestTypes,
                                    selectedTeb
                                  )}
                                </h3>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </>
                    )}

                    <tfoot className='footer-table'>
                      <tr>
                        <td>
                          {footprintList?.length > 0 && (
                            <div className='left-item text-left'>
                              Total Records:{' '}
                              {`${
                                footprintList.length +
                                commanLabel.pageLimit * (page - 1)
                              }`}{' '}
                              / {`${itemCount}`}
                            </div>
                          )}
                        </td>
                        {footprintList?.length > 0 && pageCount > 1 && (
                          <td>
                            <div className='right-item'>
                              <div className='items-center pagination-main text-center'>
                                <button
                                  className='border rounded p-1'
                                  onClick={() => {
                                    table.previousPage();
                                    setPage(page - 1);
                                  }}
                                  disabled={!table.getCanPreviousPage()}
                                >
                                  <i
                                    className='fa fa-angle-left'
                                    aria-hidden='true'
                                  ></i>
                                </button>
                                <Pagination size='sm'>
                                  {getPagination()}
                                </Pagination>

                                <button
                                  className='border rounded p-1'
                                  onClick={() => {
                                    table.nextPage();
                                    setPage(page + 1);
                                  }}
                                  disabled={!table.getCanNextPage()}
                                >
                                  <i
                                    className='fa fa-angle-right'
                                    aria-hidden='true'
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueueList;
