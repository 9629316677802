import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import _ from 'lodash';

const SortModal = (props: any) => {
    const [tempOptions, setTempOptions] = useState(_.cloneDeep(props?.options|| []));

    const onCheckboxSelection = (e: any) => {
        const fIndex = tempOptions.findIndex((opt: any) => opt.key === e.target.name);
        const opts = [...tempOptions];
        opts[fIndex].checked = e.target.checked;
        if (!e.target.checked) {
            opts[fIndex].order = '';
        } else {
            opts[fIndex].order = 'ASC'
        }
        setTempOptions(opts);
    }

    const onOrderChange = (e: any) => {
        const fIndex = tempOptions.findIndex((opt: any) => opt.key === e.target.name);
        const opts = [...tempOptions];
        if (opts[fIndex].checked) {
            opts[fIndex].order = opts[fIndex].order === 'ASC' ? 'DESC' : 'ASC';
            setTempOptions(opts);
        }
    }

    const handleOk = () => {
        props?.handleSubmit(tempOptions);
    }

    return <Modal className="modal small-popup" show={props?.show || false} onHide={props?.handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{props?.title || 'Apply sort'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="usermanagement-modal">
                    {(tempOptions || []).map((option: any) => {
                        return <div style={{ display: 'flex', paddingBlock: 8 }}>
                                    <div className="d-flex" style={{ minWidth: 200 }}>
                                        <div>
                                            <input 
                                                type="checkbox" 
                                                name={option?.key} 
                                                checked={option?.checked} 
                                                onChange={(e) => onCheckboxSelection(e)} 
                                            />
                                        </div>
                                        <div className="px-2">{option.label}</div>
                                    </div>
                                    
                                    <div style={{ paddingInline: 20 }}>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            name={option?.key}
                                            id='yes-icon'
                                            style={{ display: 'inline-block' }}
                                            checked={option?.order === 'ASC'}
                                            onChange={onOrderChange}
                                        />
                                        {' '}
                                        ASC
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            name={option?.key}
                                            id='yes-icon'
                                            style={{ display: 'inline-block', marginLeft: 20 }}
                                            checked={option?.order === 'DESC'}
                                            onChange={onOrderChange}
                                        />
                                        {' '}
                                        DESC
                                    </div>
                              </div>
                    })}
                </Modal.Body>
                    
            
                <Modal.Footer>
                        <Button
                            onClick={props?.handleClose}
                            variant="primary"
                            type="button"
                            className="btn btn-secondary btn-danger"
                            >
                                 {props.cancelText ? props.cancelText : "Cancel"}
                        </Button>
                        <Button
                            onClick={handleOk}
                            className="btn btn-primary"
                        >
                                 {props.submitText ? props.submitText : "Apply"}
                    </Button>
                </Modal.Footer>
  </Modal>
}

export default SortModal;
