/* eslint-disable no-lone-blocks */
import axios from 'axios';
import { useState, useEffect }  from 'react';

export default function GlobalLoader() {
  const {loading,isRTKLoading} = useAxiosLoader();    
  useEffect(() => {    
    const showLoader = loading || isRTKLoading;
    { showLoader && 
    document.body.classList.add('loading')
    }
    { !showLoader && 
      document.body.classList.remove('loading')
      }
  }, [loading, isRTKLoading])
  return (
    <></>
  );
}
const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);
  const [rtkCounter, setRTKCounter] = useState(0);
  
  useEffect(() => {
    const inc = (mod: number) => setCounter(c => c + mod);        
    const handleRequest = (config: any) => (inc(1), config);
    const handleResponse = (response: any) => (inc(-1), response);
    const handleError = (error: any) => (inc(-1), Promise.reject(error));
  
    // add request interceptors
    const reqInterceptor = axios.interceptors.request.use(handleRequest, handleError);
    // add response interceptors
    const resInterceptor = axios.interceptors.response.use(handleResponse, handleError);
    window.addEventListener('storage', () => {      
      setRTKCounter(parseInt(window.localStorage.getItem('loader')??'0'));      
  });   
    return () => {
      // remove all intercepts when done
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    };
  }, []);
  
  return {
    loading : counter > 0,
    isRTKLoading: rtkCounter>0,
  };
};