import React, { HTMLProps, useEffect, useState } from 'react';
import { Button, Form,Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import DatePicker from 'react-datepicker';
import {
  useDeleteTrasactionMutation,
  useExportUnlinkTransactionMutation,
  useGetTransactionMutation,
} from '../../../app/api/services/createTransaction.service';
import { toast } from 'react-toastify';
import { commanLabel } from '../../../constants/comman.constant';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import ConfirmationPopup from '../../../components/confirmationPopup';
import { useTranslation } from 'react-i18next';
// import Pagination from 'react-bootstrap/Pagination';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import {
  getEndDateFilter,
  getStartDateFilter,
} from '../../../utils/Date/getDateRange';
import { validationMessage } from '../../../constants/validation.message.constant';
import SortModal from '../../shared/sortModal';
import ExportPopup from '../socialWelfare/exportPopup';
import { store } from '../../../app/store';
import { userType } from '../../../constants/user.constant';
import { RootState } from '../../../app/types/store';



export const TransactionUnLinkedList = () => {
  const [page, setPage] = useState(commanLabel.page);
  const [pageLimit, setPageLimit] = useState(commanLabel.pageLimit);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [deleteTrasaction] = useDeleteTrasactionMutation();
  const [getTransaction] = useGetTransactionMutation();
  const [exportAsExcel] = useExportUnlinkTransactionMutation();
  const [utilityData, setUtillityData] = useState([]);
  const [materialTypeData, setMaterialTypeData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [utilityId, setUtillityId] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const [materialTypeId, setMaterialTypeId] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [trasactionID, setTransactionID] = useState(0);
  const columnHelper = createColumnHelper<any>();
  const { t: translate } = useTranslation();
  const [subCategoryId, setSubCategoryId] = useState('');
  const [materialSubCategory, setMaterialSubCategory] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  const [showSortModal, setShowSortModal] = useState(false);
  const [rowSelection, setRowSelection] = React.useState({});
  const [exportPopup, setExportPopup] = useState(false);
  const [open, setOpen] = React.useState(false);
  const state: RootState = store.getState();
  const [sorterValue, setSorterValue] = useState([
    { label: 'Transaction Id', key: 'transaction_id' },
    { label: 'Transaction Created Date', key: 'created_on' , checked: true, 'order':'DESC' },
    { label: 'Seller', key: 'seller' },
    { label: 'Buyer', key: 'buyer' },
    { label: 'Invoice Date', key: 'invoiceDate' },
    { label: 'Material Type', key: 'materialType' },
    { label: 'Material Sub Type', key: 'materialSubType' },
    // {label: 'Lot/Batch Reference', key: 'lotBatchReference' },
    // {label: 'Unloaded Qty', key: 'unloadedQty' },
  ]);

  const columns = [
    columnHelper.accessor('-', {
      id: 'select',
      enableSorting: false,
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className='px-1'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor((row) => `${row?.transaction_id}`, {
      id: 'ID',
      cell: (props: any) => {
        return (
          <>
            {props?.row?.original?.is_bulk_upload && (
              <div
                className='bulk-icons me-2'
                data-bs-toggle='tooltip'
                data-bs-placement='bottom'
                title={props?.row?.original?.bulk_upload_id}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 24 24'
                >
                  <g
                    id='collection-upload-svgrepo-com'
                    transform='translate(-2 -3)'
                  >
                    <path
                      id='Path_8273'
                      data-name='Path 8273'
                      d='M7.5,16.485V11.759H9.395V14.59H19.5V11.759h1.895v4.726Z'
                      transform='translate(1.447 2.305)'
                      fill='#18a675'
                    />
                    <path
                      id='Path_8274'
                      data-name='Path 8274'
                      d='M11.27,11.2l1.591-1.591v3.974h1.895V9.609L16.346,11.2l1.34-1.34L13.808,5.982,9.93,9.861Z'
                      transform='translate(2.087 0.785)'
                      fill='#18a675'
                    />
                    <path
                      id='Path_8275'
                      data-name='Path 8275'
                      d='M5,3H25.211V23.211H5ZM6.895,4.895H23.316V21.316H6.895Z'
                      transform='translate(0.789 0)'
                      fill='#18a675'
                      fill-rule='evenodd'
                    />
                    <path
                      id='Path_8276'
                      data-name='Path 8276'
                      d='M2,6V26.211H22.211V24.316H3.895V6Z'
                      transform='translate(0 0.789)'
                      fill='#18a675'
                    />
                  </g>
                </svg>
              </div>
            )}
            {props?.row?.original?.transaction_id}
          </>
        );
      },
    }),
    columnHelper.accessor(
      (row) =>
        moment(`${row?.created_on ? row?.created_on : '-'}`).format(
          'DD/MM/YYYY'
        ),
      {
        id: 'Transaction Created Date',
      }
    ),
    columnHelper.accessor(
      (row) =>
        `${row?.seller && row?.seller?.name} (${getCatgory(
          row?.details?.buyer_and_seller_details?.seller_category
        )})`,
      {
        id: 'Seller',
      }
    ),
    columnHelper.accessor(
      (row) =>
        `${row?.buyer && row?.buyer?.name} (${getCatgory(
          row?.details?.buyer_and_seller_details?.buyer_category
        )})`,
      {
        id: 'Buyer',
      }
    ),
    columnHelper.accessor(
      (row) =>
        moment(
          `${
            row?.details?.buyer_and_seller_details?.date
              ? row?.details?.buyer_and_seller_details?.date
              : '-'
          }`
        ).format('DD/MM/YYYY'),
      {
        id: 'Invoice Date',
        enableSorting: false,
      }
    ),
    columnHelper.accessor(
      (row) =>
        `${row?.material_type_id_data && row?.material_type_id_data?.name}`,
      {
        id: 'Material Type',
      }
    ),
    columnHelper.accessor(
      (row) =>
        `${row?.material_name_type_data && row?.material_name_type_data?.name}`,
      {
        id: `${translate(
          'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_SUB_TYPE'
        )}`,
      }
    ),
    columnHelper.accessor(
      (row) =>
        `${
          row?.associated_project && row?.associated_project?.name
            ? row?.associated_project && row?.associated_project?.name
            : 'Undefined'
        }`,
      {
        id: `${translate(
          'TRANSACTION_REGISTRY_PAGE.TITLES.ASSOCIATED_PROJECT'
        )}`,
      }
    ),
    columnHelper.accessor(
      (row) => `${row?.details?.basic_details?.lot_batch_preference || '-'}`,
      {
        id: `${translate(
          'TRANSACTION_REGISTRY_PAGE.TITLES.LOT_BATCH_PREFERENCE'
        )}`,
      }
    ),
    columnHelper.accessor(
      (row: any) =>
        `${row.details.unloading_weight_slip_details.quantity} ${row.details.unloading_weight_slip_details.measurement_types}`,
      {
        id: 'Unloaded Qty.',
      }
    ),

    columnHelper.accessor('-', {
      header: () => 'Action',
      enableSorting: false,
      cell: (props: any) => {
        let id = props?.row?.original?.id;
        return (
          <React.Fragment>
            <div className='icon-action'>
              <div className='icon-view icon'>
                <Link
                  to={`/admin/transaction/update-transaction-registry/${id}`}
                  title='View/Update'
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/task-view.svg`}
                    alt='#'
                  />
                </Link>
              </div>
              <div className='icon-delete icon'>
                <Link
                  to={'#'}
                  title='Delete'
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(true);
                    setTransactionID(id);
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/delete-icon.svg`}
                    alt='#'
                  />
                </Link>
              </div>
            </div>
          </React.Fragment>
        );
      },
    }),
  ];

  const getData = async (reset: boolean, sortInfo = sorterValue) => {
    let sortArray: { key: any; order: any }[] = [];
    if (sortInfo.length) {
      sortInfo.forEach((opt: any) => {
        if (opt.checked) {
          sortArray.push({ key: opt.key, order: opt.order });
        }
      });
    }
    setIsLoading(true);
    const parms: any = {
      page: reset ? 1 : page,
      limit: pageLimit,
      is_linked: false,
      start_date: reset
        ? ''
        : dateRange[0] === null
        ? ''
        : getStartDateFilter(dateRange[0]),
      end_date: reset
        ? ''
        : dateRange[1] === null
        ? ''
        : getEndDateFilter(dateRange[1]),
      search_value: reset ? '' : searchValue,
      material_type_id: reset ? '' : materialTypeId,
      material_name_type_id: reset ? '' : subCategoryId,
      utility_id: reset ? '' : utilityId,
      sorterValue: reset ? JSON.stringify([{ key: 'created_on', order: 'ASC' }]) : JSON.stringify(sortArray),
    };
    if (transactionType) {
      parms['is_bulk_upload'] = transactionType === 'bulk' ? true : false;
    }
    if(userType.VALUE_CHAIN_PARTNER == state.auth?.user?.userTypeId) {
      parms['partnerId'] = state.auth?.user?.value_chain_id;
    }
    // let path = apiUrl.getTrasactionUnlinkedList;
    // const fr = await helper.formPost(parms, path);
    const res = await getTransaction(parms).unwrap();
    if (res.success) {
      setTransactionData(res.data.allTransaction);
      setPageCount(res.data.total && Math.ceil(res.data.total / pageLimit));
      setTotalCount(res.data.total);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  // const getPagination = () => {
  //   let items = [];
  //   for (let number = 1; number <= pageCount; number++) {
  //     items.push(
  //       <Pagination.Item
  //         activeLabel=''
  //         key={number}
  //         active={number === page}
  //         onClick={(e) => {
  //           e.preventDefault();
  //           setPage(number);
  //         }}
  //       >
  //         {number}
  //       </Pagination.Item>
  //     );
  //   }
  //   return items;
  // };

  const getSettingData = async () => {
    let path = apiUrl.getAllSetting;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let allSetting = res?.data && res?.data?.jsonData;
      setUtillityData(allSetting?.Utility);
      setMaterialTypeData(allSetting?.Material_type);
      setCategoryData(allSetting?.Category);
    } else {
      toast.error(res.message);
    }
  };

  const getCatgory = (catId: any) => {
    if (catId) {
      let tempCatArray: any = categoryData ? [...categoryData] : [];
      let categoryObject = tempCatArray.find(
        (val: any) => val.id === Number(catId)
      );
      return categoryObject && categoryObject?.name;
    }
  };

  const deleteTrasactionData = async (id: any) => {
    const response = await deleteTrasaction(id).unwrap();
    if (response.success) {
      toast.success(response.message);
      getData(false);
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    getSettingData();
    getData(false);
  }, [page, pageLimit]);

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: transactionData,
    pageCount: pageCount,
    columns,
    state: {
      sorting,
      pagination,
      rowSelection
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const handeleReset = () => {
    setUtillityId('');
    setMaterialTypeId('');
    setPage(1);
    setDateRange([null, null]);
    getData(true);
    setSubCategoryId('');
  };
  const getMaterialSubCategory = async (materialId: string) => {
    let path = apiUrl.getMaterialSubCategory + materialId;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let subCategory = res?.data?.allSubCategory;
      setMaterialSubCategory(subCategory);
    } else {
      toast.error(res.message);
    }
  };
  const performSearch = (e: any) => {
    e.preventDefault();
    getData(false);
  };

  function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);
    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type='checkbox'
        ref={ref}
        className={className + ' cursor-pointer'}
        {...rest}
      />
    );
  }

  const getExportedExcel = async () => {
    const rowKeys = Object.keys(rowSelection);
    const actKeys: any = [];
    rowKeys.forEach((aa: string) => {
      if (rowSelection[aa as keyof typeof rowSelection]) {
        const actRow: any = transactionData[aa as keyof typeof rowSelection];
        actKeys.push(actRow?.id);
      }
    });
    const res = await exportAsExcel({
      ids: actKeys,
    }).unwrap();
    if (res.success) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
        res.data?.file;
      a.download = res.data.name;
      a.click();
    }
  };
  const handleClose = () => {
    setExportPopup(false);
};
 
  return (
    <React.Fragment>
      <div className='tab-content'>
        <div
          className='tab-pane fade show active'
          id='registry1'
          role='tabpanel'
          aria-labelledby='registry1-tab'
        >
          <div className='listing-transaction'>
            <Form>
              <div className='top-filters'>
                <div className='date-item-picker item-in'>
                  <div className='date-picker-main'>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      value={startDate ? startDate : ''}
                      className='form-control'
                      placeholderText='Select Date Range'
                      dateFormat='dd/MM/yyyy'
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      onChange={(update: any) => {
                        setDateRange(update);
                      }}
                    />
                  </div>
                </div>
                <div className='type-select item-in'>
                  <select
                    className='select-layout'
                    name='materialTypeId'
                    value={materialTypeId}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setSubCategoryId('');
                      }
                      setMaterialTypeId(e.target.value);
                      getMaterialSubCategory(e.target.value);
                    }}
                  >
                    <option value={''}>Select Material Type</option>
                    {materialTypeData?.length > 0 &&
                      materialTypeData?.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className='item item-in'>
                  <select
                    className='form-select'
                    aria-label='Default select'
                    disabled={!materialTypeId}
                    value={subCategoryId}
                    onChange={(e) => {
                      setSubCategoryId(e.target.value);
                    }}
                  >
                    <option value=''>
                      {translate('HOMEPAGE.TITLES.SELECT_MATERIAL_SUB_TYPE')}
                    </option>
                    {materialSubCategory.length > 0 &&
                      materialSubCategory.map((u: any, i: any) => {
                        return (
                          <option key={u.id} value={u.id}>
                            {u.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className='item item-in'>
                  <select
                    className='select-layout'
                    name='utilityId'
                    value={utilityId}
                    onChange={(e) => {
                      setUtillityId(e.target.value);
                    }}
                  >
                    <option value={''}>Select Utility</option>
                    {utilityData?.length > 0 &&
                      utilityData?.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className='item item-in'>
                  <select
                    className='select-layout'
                    name='utilityId'
                    value={transactionType}
                    onChange={(e) => {
                      setTransactionType(e.target.value);
                    }}
                  >
                    <option value={''}>Select Transaction Type</option>
                    <option value='bulk'>Bulk</option>
                    <option value='non-bulk'>Manual</option>
                  </select>
                </div>
                {/* <div className='col-lg-4'> */}
                <div className='type-select item-in'>
                  <div className='item input-min-width'>
                    <div className='input-search-item '>
                      {/* <label
                        // for="searchicon"
                        className='searchicon'
                      >
                        <i className='fa fa-search'></i>
                      </label> */}
                      <input
                        className='form-control'
                        id='searchicon'
                        placeholder={
                          translate('BULK_UPLOAD_TRANSACTIONS.TITLES.SEARCH') +
                          ''
                        }
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            performSearch(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className='search-button item-in'>
                  <Button
                    className='btn  btn-sm btn-outline-primary btn-icon'
                    type='button'
                    title='Search'
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSortModal(true);
                    }}
                  >
                    <i className='fa fa-sort' aria-hidden='true'></i>
                  </Button>
                  <Button
                    className='btn  btn-sm btn-outline-primary btn-icon'
                    type='button'
                    title='Search'
                    onClick={(e) => {
                      e.preventDefault();
                      getData(false);
                    }}
                  >
                    <i className='fa fa-search' aria-hidden='true'></i>
                  </Button>

                  <Button
                    className='btn  btn-sm  btn-outline-primary btn-icon'
                    title='Reset'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      handeleReset();
                    }}
                  >
                    <i className='fa fa-refresh' aria-hidden='true'></i>
                  </Button>
                  <div
                className='btn  btn-sm btn-outline-primary btn-icon'
                onClick={(e) => {
                  e.preventDefault();
                  if( Object.keys(rowSelection).length !== 0 ){
                    setOpen(true)
                  } else {
                    toast.error(translate('ERRORS.INVALID.INVALID_SELECTION'));
                  }
                 
                }}
              >
               <i className='fa fa-file-export' aria-hidden='true'></i>
              </div>

                </div>
              </div>
            </Form>
            <div className='listing-content'>
              <div className='table-list'>
                <table>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup: any) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header: any) => {
                          return (
                            <th key={header.id} colSpan={header.colSpan}>
                              {header.isPlaceholder ? null : (
                                <div>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  {transactionData?.length > 0 ? (
                    <tbody>
                      {table.getRowModel().rows.map((row: any) => {
                        return (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell: any) => {
                              return (
                                <td key={cell.id}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <>
                      <tbody>
                        <tr>
                          <td colSpan={11}>
                            {isLoading && (
                              <h3 className='center-message-item'>
                                Loading...
                              </h3>
                            )}
                            {transactionData?.length === 0 && !isLoading && (
                              <h3 className='center-message-item'>
                                {
                                  validationMessage.noRecordFoundUnlinkedTransaction
                                }
                              </h3>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}
                </table>

                <div className='h-2 mt-2' />
              </div>
              {transactionData?.length > 0 && pageCount > 1 && (
                // <div className='items-center pagination-main gap-2 text-center'>
                //   <button
                //     className='border rounded p-1'
                //     onClick={() => {
                //       table.previousPage();
                //       setPage(page - 1);
                //     }}
                //     disabled={!table.getCanPreviousPage()}
                //   >
                //     {'<'}
                //   </button>
                //   <Pagination size='sm'>{getPagination()}</Pagination>
                //   <button
                //     className='border rounded p-1'
                //     onClick={() => {
                //       table.nextPage();
                //       setPage(page + 1);
                //     }}
                //     disabled={!table.getCanNextPage()}
                //   >
                //     {'>'}
                //   </button>
                // </div>
                <div className='items-center pagination-main gap-2 text-center'>
                  <PaginationControl
                    page={page}
                    between={4}
                    total={totalCount}
                    limit={pageLimit}
                    changePage={(page) => {
                      setPage(page);
                    }}
                    ellipsis={2}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {isOpen && (
          <ConfirmationPopup
            title='Delete'
            message='Are you sure you want to Delete?'
            handleCancel={() => setIsOpen(false)}
            cancelText='Cancel'
            submitText='Delete'
            handleSubmit={() => {
              deleteTrasactionData(trasactionID);
              setIsOpen(false);
            }}
          />
        )}
        {showSortModal ? (
          <SortModal
            show={showSortModal}
            options={[...sorterValue]}
            handleSubmit={(arr: any) => {
              getData(false, arr);
              setShowSortModal(false);
              setSorterValue(arr);
            }}
            handleClose={() => setShowSortModal(false)}
          />
        ) : (
          ''
        )}

{open && (
          <ExportPopup
           title={`${translate('CSR_MODULE.TITLES.EXPORT')}`}
           handleCancel={() => setOpen(false)}
           cancelText='Cancel'
           submitText='OK'
           hidePdf={true}
           hideCancelButton={true}
           handleSubmit={(e: any, addBlur: any) => {
             setExportPopup(false);
             setOpen(false);
             if (e === 'excel') {
               getExportedExcel();
             }
           }}
         />
  )}
      </div>
      
    </React.Fragment>
  );
  
};
