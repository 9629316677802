import _ from "lodash";

export const getFormatBytes = (bytes: any, decimals = 2) => {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getFileFormat = (filename: any) => {
    let fileExt = filename.split('/').pop()
    return _.upperCase(fileExt)
};

export const getImage = (name: any) => {
    if (name === 'OBP - Collection.pdf') {
        return `${process.env.PUBLIC_URL}/assets/images/OBP - Collection & Recycling.png`;
    } else if (name === 'OBP - Recycling.pdf') {
        return `${process.env.PUBLIC_URL}/assets/images/OBP - Collection & Recycling.png`;
    } else if (name === 'GRS.pdf') {
        return `${process.env.PUBLIC_URL}/assets/images/GRS.jpg`;
    } else if (name === 'ISO 9001-2015.pdf') {
        return `${process.env.PUBLIC_URL}/assets/images/ISO 9001-2015.jpg`;
    } else if (name === 'ISO 45001-2018.pdf') {
        return `${process.env.PUBLIC_URL}/assets/images/ISO 45001-2018.jpg`;
    } else if (name === 'ISO 14001-2015.pdf') {
        return `${process.env.PUBLIC_URL}/assets/images/ISO 14001-2015.jpg`;
    } else if(name === 'ISCC-PLUS-Certificate.pdf'){
        return `${process.env.PUBLIC_URL}/assets/images/ISCC-PLUS-Certificate.jpg`;
    } else if(name === 'Recycled-Claim-Standard.pdf'){
        return `${process.env.PUBLIC_URL}/assets/images/Recycled-Claim-Standard.png`;
    } else {
        return `${process.env.PUBLIC_URL}/assets/images/certificate-two.png`;
    }
}