import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { helper } from '../../../app/api/api.helper';
import { apiUrl } from '../../../app/api/services/apiUrl';
import ConfirmationPopup from '../../../components/confirmationPopup';
import moment from 'moment';
import {
  useCreateReclaimerMutation,
  useUpdateReclaimerMutation,
} from '../../../app/api/services/socialWelfare';
import { routesConstant } from '../../../constants/comman.constant';
import {
  isValidFile,
  socialWelfareTab,
} from '../../../shared/remake-green-utils';
import { commanValidation } from '../../../constants/comman.validation.constant';
import { useCreateCSRReclaimerMutation } from '../../../app/api/services/csrActivity';
import { createReclaimerValidator } from './reclaimerValidator';

const IdentificationData = [
  { value: 'Aadhaar Card', label: 'Aadhaar Card' },
  { value: 'Citizenship Document', label: 'Citizenship Document' },
  { value: 'Voter Id', label: 'Voter Id' },
];

const AddReclaimer = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [addReclaimer] = useCreateCSRReclaimerMutation();
  const [editReclaimer] = useUpdateReclaimerMutation();
  const { t: translate } = useTranslation();
  const [data, setData] = useState<any>(null);
  const [reclaimerCategoryData, setReclaimerCategoryData] = useState([]);
  const [reclaimerCategoryId, setReclaimerCategoryId] = useState('');
  const [dataField, setDataField] = useState<any>([{ name: '', value: '' }]);
  const [identificationType, setIdentificationType] = useState<any>(null);

  const [reclaimerDetails, setReclaimerDetails] = useState({
    name: '',
    gender: '',
    contact_number: '',
    reclaimer_photo: '',
    reference_of: '',
    reference_value: '',
    identification_type: '',
    identification_value: '',
    nationality: '',
    date_of_birth: new Date(),
    data_fields: [],
    user_type: 4,
  });

  const handleReclaimerDetails = (e: any) => {
    setReclaimerDetails({
      ...reclaimerDetails,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = (eventValue = reclaimerDetails, formSubmit = false) => {
    const errs: any = createReclaimerValidator(errors, eventValue);
    setErrors(errs);
    const isFormValid = Object.keys(errs).every(
      (err) => errs[err].length === 0
    );
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      setSubmitPopup(true);
    }
  };

  const handleDataField = (index: number, event: any, name: any) => {
    let data = [...dataField];
    if (name) {
      data[index].name = event.target.value;
    } else {
      data[index].value = event.target.value;
    }
    setDataField(data);
  };

  const [errors, setErrors] = useState<any>({
    reclaimerName: '',
    referenceValue: '',
  });

  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not
  const [selectedCollectionCenter, setSelectedCollectionCenter] = useState({
    id: null,
    name: '',
  });
  const [collectionCenterName, setCollectionCneterName] = useState<any>(null);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [discardPopup, setDiscardPopup] = useState(false);
  const [collectionCenter, setCollectionCenter] = useState([]);
  const [documentFiles, setDocumentFiles] = useState<any>([]);
  const getReclaimerDetails = async (id: string) => {
    let path = apiUrl.getCSRReclaimerForCSRActivity + id;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setData(res?.data);
      setReclaimerDetails({
        name: res?.data?.name,
        gender: res?.data?.gender,
        contact_number: res?.data?.contact_number,
        reclaimer_photo: res?.data?.reclaimer_photo,
        reference_of: res?.data?.reference_of,
        reference_value: res?.data?.reference_value,
        identification_type: res?.data?.identification_type,
        identification_value: res?.data?.identification_value,
        nationality: res?.data?.nationality,
        date_of_birth: new Date(res?.data?.birth_date),
        data_fields: res?.data?.data_fields,
        user_type: res?.data?.reclaimer_user_type?.id,
      });

      if (res?.data?.reclaimer?.csr_collection_center) {
        setCollectionCneterName({
          label: res?.data?.reclaimer?.csr_collection_center?.name,
          value: res?.data?.reclaimer?.csr_collection_center_id,
        });

        setSelectedCollectionCenter({
          id: res?.data?.reclaimer?.csr_collection_center_id,
          name: res?.data?.reclaimer?.csr_collection_center?.name,
        });
      }
    } else {
      toast.error(res.message);
    }
  };

  const getReclaimerCategoryDetails = async () => {
    let path = apiUrl.getReclaimerCategory;
    const fr: any = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      setReclaimerCategoryData(res.data);
    } else {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    if (id) {
      getReclaimerDetails(id);
    }
  }, [id]);

  useEffect(() => {
    // getCollectionCenterDetails();
    getReclaimerCategoryDetails();
  }, []);

  const handleIdentificationType = (option: any) => {
    if (option !== null) {
      // let category_id = option.value === option.label ? 0 : option.value;
      // setActivityCategoryId(category_id);
      setReclaimerDetails({
        ...reclaimerDetails,
        identification_type: option.value,
      });
      setIdentificationType(option);
      // setEventDetails({ ...eventDetails, name: option.label });
    } else {
      setIdentificationType(null);
      // setActivityCastegoryId(0);
    }
  };

  // const validateForm = (eventValue = reclaimerDetails, formSubmit = false) => {
  //   const errs: any = createReclaimerValidator(errors, eventValue);
  //   setErrors(errs);
  //   const isFormValid = Object.keys(errs).every(
  //     (err) => errs[err].length === 0
  //   );
  //   if (formSubmit && !isFormValid) {
  //     toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
  //     return;
  //   }
  //   if (formSubmit && isFormValid) {
  //     setSubmitPopup(true);
  //   }
  // };

  // useEffect(() => {
  //   if (data) {
  //     setReclaimerName(data.reclaimer.name);
  //     setBoys(data.reclaimer.boys);
  //     setGirls(data.reclaimer.girls);
  //     setGender(data.reclaimer.gender);
  //     setContactNumber(data.reclaimer.contact_number);
  //     setCapacityPerDay(data.reclaimer.capacity_per_day);
  //     setSmallScrapDealer(data.reclaimer.small_scrap_dealer);
  //     // setBirthDate({
  //     //   day: moment(data.reclaimer.birth_date).format('DD'),
  //     //   month: moment(data.reclaimer.birth_date).format('MM'),
  //     //   year: moment(data.reclaimer.birth_date).format('YYYY'),
  //     // });
  //     setAddress(data.reclaimer.address);
  //     setAadhaarNo(data.reclaimer.aadhaar_no);
  //     setCollectionCenter(data.reclaimer.csr_collection_center.name);
  //     setChildrens(data.reclaimer.childrens);
  //   }
  // }, [data]);

  const handleBirthDate = (date: any) => {
    setReclaimerDetails({ ...reclaimerDetails, date_of_birth: date });
    // setBirthDate(date);
  };

  const createReclaimer = async () => {
    let data = {
      name: reclaimerDetails.name,
      gender: 'male',
      csr_reclaimer_category: reclaimerCategoryId,
      csr_reclaimer_user_type: reclaimerDetails.user_type,
      // contact_number: '9099999999',
      reclaimer_photo: reclaimerDetails.reclaimer_photo,
      reference_of: reclaimerDetails.reference_of,
      reference_value: reclaimerDetails.reference_value,
      identification_type: reclaimerDetails.identification_type,
      identification_value: reclaimerDetails.identification_value,
      nationality: reclaimerDetails.nationality,
      date_of_birth: moment(reclaimerDetails.date_of_birth).format(
        'YYYY-MM-DD'
      ),
      data_fields: dataField,
    };

    // const fr: any = await helper.formPost(data, path);
    const res = await addReclaimer(data).unwrap();
    if (res.success) {
      navigate(routesConstant.CSRActivity, {
        state: socialWelfareTab.RECLAIMER,
      });
      toast.success('Reclaimer Added Created Successfully.');
    } else {
      toast.error(res.message);
    }
  };

  // const updateReclaimer = async () => {
  //   let details: any = {
  //     reclaimer_id: id,
  //     name: reclaimerDetails.reclaimerName,
  //     gender: reclaimerDetails.gender,
  //     address: reclaimerDetails.address,
  //     // aadhaar_number: reclaimerDetails.aadhaarNo,
  //     // csr_collection_center_id: selectedCollectionCenter.id,
  //     // csr_collection_center_name: selectedCollectionCenter.name,
  //     contact_number: reclaimerDetails.contactNumber,
  //     aadhaar_photo: reclaimerDetails.aadhaarPhoto,
  //     childrens:
  //       reclaimerDetails.girls > 0 || reclaimerDetails.boys > 0
  //         ? reclaimerDetails.girls + reclaimerDetails.boys + ''
  //         : reclaimerDetails.childrens,
  //     girls: reclaimerDetails.girls,
  //     boys: reclaimerDetails.boys,
  //     capacity_per_day: reclaimerDetails.capacityPerDay,
  //     small_scrap_dealer: reclaimerDetails.smallScrapDealer,
  //     date_of_birth: moment(reclaimerDetails.birthDate).format('YYYY-MM-DD'),
  //   };
  //   if (data?.reclaimer?.aadhaar_no !== reclaimerDetails.aadhaarNo) {
  //     details = {
  //       ...details,
  //       aadhaar_number: reclaimerDetails.aadhaarNo,
  //     };
  //   }
  //   if (
  //     data?.reclaimer?.csr_collection_center_id !== selectedCollectionCenter.id
  //   ) {
  //     details = {
  //       ...details,
  //       csr_collection_center_id: selectedCollectionCenter.id,
  //     };

  //     if (selectedCollectionCenter.id === 0) {
  //       details = {
  //         ...details,
  //         csr_collection_center_name: selectedCollectionCenter.name,
  //       };
  //     }
  //   }

  //   // const fr: any = await helper.formPost(data, path);
  //   const res = await editReclaimer(details).unwrap();
  //   if (res.success) {
  //     navigate(routesConstant.socialWelfare, {
  //       state: socialWelfareTab.RECLAIMER,
  //     });
  //     toast.success('Reclaimer Updated Successfully.');
  //   } else {
  //     toast.error(res.message);
  //   }
  // };

  const handleCollectionCenter = (option: any) => {
    if (option !== null) {
      let collectionCenterData = {
        id: option.value === option.label ? 0 : option.value,
        name: option.label,
      };
      setSelectedCollectionCenter(collectionCenterData);
      setCollectionCneterName(option);
    } else {
      setCollectionCneterName(null);
      setSelectedCollectionCenter({ id: null, name: '' });
    }
  };

  const handleFileUploder = async (file: any) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', '');

    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res: any = await fr.response;
    if (res.success) {
      toast.success(res.message);
      await getFileUploaderData(res?.data?.data);
    } else {
      toast.error(res.message);
    }
  };

  const getFileUploaderData = async (dat: any) => {
    const tDoc: any = documentFiles || [];
    tDoc.push(dat);

    setDocumentFiles([...tDoc]);
    setReclaimerDetails({
      ...reclaimerDetails,
      reclaimer_photo: tDoc[0].document_path,
    });
  };

  const uploadDocument = async (e: any) => {
    let files = e?.target?.files || [];
    if (files[0] > commanValidation.maxFileLength) {
      toast.error(translate('ERRORS.INVALID.MAXIMUM_DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidFile(files[0].type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    await handleFileUploder(files[0]);
  };

  const handleForm = (e: any) => {
    setReclaimerDetails({
      ...reclaimerDetails,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {discardPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          message={`${translate('CSR_MODULE.TITLES.DISCARD_TEXT')}`}
          handleCancel={() => setDiscardPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          handleSubmit={() => {
            if (id) {
            } else
              setReclaimerDetails({
                name: '',
                gender: '',
                contact_number: '',
                reclaimer_photo: '',
                reference_of: '',
                reference_value: '',
                identification_type: '',
                identification_value: '',
                nationality: '',
                date_of_birth: new Date(),
                data_fields: [],
                user_type: 4,
              });

            // setSelectedCollectionCenter({
            //   id: null,
            //   name: '',
            // });
            setCollectionCneterName(null);
            setDocumentFiles([]);
            setDiscardPopup(false);
          }}
        />
      )}

      {submitPopup && (
        <ConfirmationPopup
          title={`${translate('CSR_MODULE.TITLES.DISCARD')}`}
          message={`${translate('CSR_MODULE.TITLES.SUBMIT_MESSAGE')}`}
          handleCancel={() => setSubmitPopup(false)}
          cancelText={`${translate('CSR_MODULE.TITLES.CANCEL')}`}
          submitText={`${translate('CSR_MODULE.TITLES.PROCEED')}`}
          handleSubmit={() => {
            // id ? updateReclaimer() :
            createReclaimer();
            setSubmitPopup(false);
          }}
        />
      )}
      <main>
        <div className='profile-page admin-profile-changes csr-addreclaimer csr-add-entry '>
          <div className='container'>
            <nav aria-label='breadcrumb' className='breadcrumb-main'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    {translate('CSR_MODULE.TITLES.HOME')}
                  </a>
                </li>
                <li className='breadcrumb-item'>
                  <a
                    onClick={() => {
                      navigate('/admin/csr-activity', {
                        state: socialWelfareTab.RECLAIMER,
                      });
                    }}
                  >
                    CSR Activity
                  </a>
                </li>
                <li className='breadcrumb-item active'>
                  {translate('CSR_MODULE.TITLES.ADD_RECLAIMER')}
                </li>
              </ol>
            </nav>
            <div className='section-title-main'>
              <div className='title'>
                {translate('CSR_MODULE.TITLES.ADD_RECLAIMER')}
              </div>
            </div>
            <div className='display-combine-component'>
              <div className='display-component main-profile-head'>
                <div className='display-head'>
                  <div className='title'>
                    {translate('CSR_MODULE.TITLES.PROFILE_DETAILS')}
                  </div>
                  <div className='form-submit-buttons auto-width-button'>
                    <button
                      type='button'
                      className='btn btn-primary btn-outline-primary btn-sm btn btn-primary'
                      onClick={() => setDiscardPopup(true)}
                    >
                      {translate('CSR_MODULE.TITLES.DISCARD')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm btn btn-primary ms-3'
                      onClick={() => {
                        setIsSubmited(true);
                        validateForm(reclaimerDetails, true);
                        // validateForm(reclaimerDetails, true);
                      }}
                    >
                      {translate('CSR_MODULE.TITLES.SAVE_PROFILE')}
                    </button>
                  </div>
                </div>
                <div className='display-body'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>Name</label>

                        <input
                          type='text'
                          name='name'
                          onChange={handleReclaimerDetails}
                          value={reclaimerDetails.name}
                          placeholder='Enter Name'
                          className='form-control'
                          // disabled={reclaimerId ? true : false}
                        />
                        <span className='error'>{errors.reclaimerName}</span>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <label className='form-label form-label'>
                          Beneficiary
                        </label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              checked={reclaimerDetails.user_type == 4}
                              onClick={() => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  user_type: 4,
                                });
                              }}
                              name='isPOCDataProvider'
                              id='yes-icon1'
                              // disabled={reclaimerId ? true : false}
                              value='true'
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='yes-icon1'
                            >
                              Individual
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='isPOCDataProvider'
                              id='no-icon1'
                              checked={reclaimerDetails.user_type == 6}
                              onClick={() => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  user_type: 6,
                                });
                              }}
                              // disabled={reclaimerId ? true : false}
                              value='false'
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='no-icon1'
                            >
                              Group
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group required required'>
                        <label className='form-label form-label'>
                          Beneficiary Category
                        </label>
                        <select
                          name='gender'
                          className='form-control form-select'
                          value={reclaimerCategoryId}
                          onChange={(e: any) =>
                            setReclaimerCategoryId(e.target.value)
                          }
                        >
                          <option value=''>Select Reclaimer Category</option>
                          {reclaimerCategoryData &&
                            reclaimerCategoryData.length > 0 &&
                            reclaimerCategoryData.map(
                              (userCategory: any, index: number) => {
                                return (
                                  <option value={userCategory.id}>
                                    {userCategory?.name}
                                  </option>
                                );
                              }
                            )}
                        </select>
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    {/* <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label form-label'>Name</label>
                        <input
                          type='text'
                          placeholder='Enter Name'
                          className='form-control'
                        />
                      </div>
                    </div> */}
                    <div className='col-md-4'>
                      <div className='form-group required'>
                        <label className='form-label form-label'>Gender</label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='gender'
                              id='yes-icon2'
                              checked={reclaimerDetails.gender == 'male'}
                              onClick={() => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  gender: 'male',
                                });
                              }}
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='yes-icon2'
                            >
                              Male
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='gender'
                              id='no-icon2'
                              // value='false'
                              checked={reclaimerDetails.gender == 'female'}
                              onClick={() => {
                                setReclaimerDetails({
                                  ...reclaimerDetails,
                                  gender: 'female',
                                });
                              }}
                            />
                            <label
                              className='form-check-label form-label'
                              htmlFor='no-icon2'
                            >
                              Female
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {reclaimerDetails.user_type == 4 && (
                      <div className='col-md-4'>
                        <div className='form-group date-input'>
                          <label className='form-label form-label'>
                            Birth Date
                          </label>
                          <div className='date-item-picker'>
                            <div className='date-picker-main'>
                              <DatePicker
                                selected={reclaimerDetails.date_of_birth}
                                className='form-control'
                                placeholderText={
                                  translate(
                                    'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                  ) + ''
                                }
                                dateFormat='dd/MM/yyyy'
                                onChange={handleBirthDate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                required
                                // disabled={eventId ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <div className='mb-3 upload-section'>
                          <label
                            className='form-label form-label'
                            htmlFor='formFile'
                          >
                            Profile Photo
                          </label>
                          <input
                            name='reclaimer_image'
                            accept='.jpg, .jpeg, .png'
                            type='file'
                            id='reclaimer_image'
                            onChange={uploadDocument}
                            // disabled={reclaimerId ? true : false}
                            className='file-input form-control'
                          />

                          <img
                            src='/assets/images/upload-svg.svg'
                            className='upload-btn'
                          ></img>
                          {reclaimerDetails.reclaimer_photo && (
                            <div
                              className='upload-icons'
                              style={{ left: '50px' }}
                            >
                              <i
                                className='fa-solid fa-trash-can'
                                onClick={() => {
                                  setReclaimerDetails({
                                    ...reclaimerDetails,
                                    reclaimer_photo: '',
                                  });
                                }}
                              ></i>
                              <i
                                className='fa fa-eye ms-2 me-2'
                                aria-hidden='true'
                                onClick={() => {
                                  window.open(reclaimerDetails.reclaimer_photo);
                                }}
                              ></i>
                            </div>
                          )}
                        </div>
                        <span className='error'></span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <>
                      {dataField.map((u: any, i: any) => {
                        return (
                          <div className='row'>
                            <div className='col-md-4'>
                              <div className='form-group required required'>
                                {i === 0 && (
                                  <label className='form-label form-label'>
                                    Data Fields
                                  </label>
                                )}
                                <input
                                  type='text'
                                  placeholder='Field Name'
                                  className='form-control'
                                  value={u.name}
                                  onChange={(e) => handleDataField(i, e, true)}
                                />
                                <span className='error'></span>
                              </div>
                            </div>
                            <div className='col-md-4'>
                              <div className='row'>
                                <div className='col-md-10'>
                                  <div className='form-group'>
                                    {i === 0 && (
                                      <label className='form-label form-label'></label>
                                    )}
                                    <input
                                      type='text'
                                      placeholder='Value'
                                      value={u.value}
                                      className='form-control'
                                      onChange={(e) =>
                                        handleDataField(i, e, false)
                                      }
                                    />
                                    <span className='error'></span>
                                  </div>
                                </div>
                                {dataField.length === i + 1 && (
                                  <div className='col-md-2 '>
                                    {i === 0 && (
                                      <label className='form-label'></label>
                                    )}
                                    <button
                                      type='button'
                                      className='btn-add'
                                      onClick={() =>
                                        setDataField([
                                          ...dataField,
                                          { name: '', value: '' },
                                        ])
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              </div>{' '}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div>
                  {reclaimerDetails.user_type == 4 && (
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <div className='mb-3 upload-section'>
                            <label
                              className='form-label form-label'
                              htmlFor='formFile'
                            >
                              Identification type
                            </label>
                            <CreatableSelect
                              isClearable
                              value={identificationType}
                              onChange={handleIdentificationType}
                              options={IdentificationData}
                              placeholder={'Select Identification Type'}

                              // isDisabled={user_id !== undefined}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label form-label'>
                            Identification Number
                          </label>
                          <input
                            type='text'
                            name='identification_value'
                            onChange={handleReclaimerDetails}
                            value={reclaimerDetails.identification_value}
                            placeholder=''
                            className='form-control'
                            // disabled={reclaimerId ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label form-label'>
                          Reference Of
                        </label>
                        <input
                          type='text'
                          name='reference_of'
                          onChange={handleReclaimerDetails}
                          value={reclaimerDetails.reference_of}
                          placeholder=''
                          className='form-control'
                          // disabled={reclaimerId ? true : false}
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label className='form-label form-label'>
                          Reference Value
                        </label>
                        <input
                          type='text'
                          name='reference_value'
                          // disabled={reclaimerId ? true : false}
                          onChange={handleReclaimerDetails}
                          value={reclaimerDetails.reference_value}
                          placeholder=''
                          className='form-control'
                        />
                        <span className='error'>{errors.referenceValue}</span>
                      </div>
                    </div>
                    {reclaimerDetails.user_type == 4 && (
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label form-label'>
                            Nationality
                          </label>
                          <input
                            type='text'
                            placeholder='Nationality'
                            className='form-control'
                            name='nationality'
                            value={reclaimerDetails.nationality}
                            onChange={handleReclaimerDetails}
                            // disabled={reclaimerId ? true : false}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddReclaimer;
