import { useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { commanValidation } from "../../../constants/comman.validation.constant";
import { verifyEmailValidator } from "./signupValidator";
import { useTranslation } from "react-i18next";
import { useSendOtpMutation } from "../../../app/api/services/authentication.service";
import { OtpStatus } from "../../../shared/remake-green-utils/common/constants/enum";
import { imagePath, routesConstant } from "../../../constants/comman.constant";
export const Signup = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not
  const [email, setEmail] = useState("");
  const [errorsEmail, setErrorsEmail] = useState("");
  const [validated] = useState(false);
  const [sendOtp] = useSendOtpMutation();

  const handleChange = (e: any) => {
    if (isSubmitted) {
      validateForm(e.target.value);
    }
    setEmail(e.target.value);
  };

  const validateForm = (email: any, formSubmit = false) => {
    const errs: any = verifyEmailValidator(errorsEmail, email);
    setErrorsEmail(errs);
    const isFormValid = errs.length > 0 ? false : true;
    if (formSubmit && isFormValid) {
      onSubmit();
    }
    if (formSubmit && !isFormValid) {
      toast.error(translate("ERRORS.REQUIRED.MANDATORY"));
    }
  };

  const onSubmit = async () => {
    const postJson = { email: email, otp_reason: OtpStatus.VERIFYOTP };
    try {
      const response = await sendOtp(postJson).unwrap();
      if (response.success) {
        toast.success(response.data.message);
        localStorage.setItem("verifyEmail", email);
        navigate(routesConstant.verifySignupOtp);
      } else {
        toast.error(response.message);
      }
    } catch (err: any) {
      toast.error(err?.data?.data?.message);
    }
  };

  return (
    <>
      <div className="body-content-space pb-0">
        <section className="form">
          <div className="container">
            <div className="form-data">
              <div className="row">
                <div className="col-md-4">
                  <h2 className="title-main">
                    <span>{translate("COMMONFIELDS.REMAKE")}</span>{" "}
                    {translate("REGISTER.TITLES.SIGN_UP")}
                  </h2>
                  <Form
                    className="signup-form"
                    noValidate
                    validated={validated}
                  >
                    <div className="form-group required">
                      <Form.Label className="form-label label-info ">
                        {translate("COMMONFIELDS.EMAIL")}
                        <i
                          className="fa fa-info-circle tooltip-icon-data"
                          title="Would be registered for credit generation"
                        />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder={translate("COMMONFIELDS.YOURMAIL") + ""}
                        autoComplete="off"
                        aria-label="Email"
                        id="username1"
                        value={email}
                        maxLength={commanValidation.maxLength}
                        minLength={commanValidation.minLengthEmail}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                      />
                      {<span className="error">{translate(errorsEmail)}</span>}
                    </div>
                    <div className="submit-button">
                      <Button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          setIsSubmited(true);
                          validateForm(email, true);
                        }}
                      >
                        {translate("COMMONFIELDS.VERIFY_EMAIL")}
                      </Button>
                    </div>
                  </Form>
                </div>
                <div className="col-md-7  offset-md-1 right-img-form">
                  <div className="right">
                    <Image src={imagePath.formImgPath} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
