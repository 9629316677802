import { commanLabel } from "../../../constants/comman.constant";
import { UserType } from "../../../shared/remake-green-utils/common/constants";

// User Basic Details fields
export const userDetails = {
  credit_type: "",
  user_type: UserType.COMPANY,
  name: "",
  email: "",
  secondary_email: "",
  mobile_no: "",
  material_type_id: "",
  material_name_type_id: "",
  region_id: commanLabel.defaultRegionId, // India location selected by default
  country_id: "",
  state_id: "",
  city_id: "",
  zip_code: "",
  quantity: "",
  quantity_unit: commanLabel.tonneLabel,
  credit_value: "",
  billing_address: "",
  company_name: "",
  company_identification_number: "",
  gst_number: "",
  preferred_currency: commanLabel.currency,
};

export const errorJson = {
  name: "",
  email: "",
  mobile_no: "",
  company_name: "",
  company_identification_number: "",
  gst_number: "",
  billing_address: "",
  material_type_id: "",
  material_name_type_id: "",
  region_id: "",
  country_id: "",
  state_id: "",
  city_id: "",
  zip_code: "",
  credit_value: "",
  quantity: "",
  secondary_email: ""
};
